import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const StyledCard = styled(Card)(() => ({
  margin: 'auto',
  textAlign: 'center',
  maxWidth: 500,
  minWidth: 275,
  marginTop: '1rem',
}));

const NotFound: FunctionComponent = () => {
  return (
    <StyledCard>
      <CardContent>
        <CardHeader title={`Sivua '${location.pathname}' ei löydy.`} />
      </CardContent>
    </StyledCard>
  );
};

export default NotFound;
