import React from 'react';
import { MenuItem } from '@mui/material';
import {
  GeographicalAreaAreaTypeEnum,
  PricingModelWithAreasDistancePricingBasisEnum,
  PricingModelWithAreasPricingUnitEnum,
} from '../../../api';

export const pricingUnitMenuItems = [
  <MenuItem key={PricingModelWithAreasPricingUnitEnum.WeightKg} value={PricingModelWithAreasPricingUnitEnum.WeightKg}>
    Paino / Kg
  </MenuItem>,
  <MenuItem
    key={PricingModelWithAreasPricingUnitEnum.WorkingHours}
    value={PricingModelWithAreasPricingUnitEnum.WorkingHours}
  >
    Työtunnit
  </MenuItem>,
  <MenuItem
    key={PricingModelWithAreasPricingUnitEnum.CustomCategory}
    value={PricingModelWithAreasPricingUnitEnum.CustomCategory}
  >
    Kategoria
  </MenuItem>,
];

export const distancePricingBasisMenuItems = [
  <MenuItem
    key={PricingModelWithAreasDistancePricingBasisEnum.DistanceKm}
    value={PricingModelWithAreasDistancePricingBasisEnum.DistanceKm}
  >
    Km
  </MenuItem>,
  <MenuItem
    key={PricingModelWithAreasDistancePricingBasisEnum.FixedArea}
    value={PricingModelWithAreasDistancePricingBasisEnum.FixedArea}
  >
    Alue
  </MenuItem>,
];

export const areaTypeMenuItems = [
  <MenuItem key={GeographicalAreaAreaTypeEnum.PostalCode} value={GeographicalAreaAreaTypeEnum.PostalCode}>
    Postinumero
  </MenuItem>,
  <MenuItem key={GeographicalAreaAreaTypeEnum.City} value={GeographicalAreaAreaTypeEnum.City}>
    Kaupunki
  </MenuItem>,
];
