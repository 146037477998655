/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  OrganizationPhotoResponseBody,
  OrganizationPhotoResponseBodyFromJSON,
  OrganizationPhotoResponseBodyToJSON,
  OrganizationPhotosResponseBody,
  OrganizationPhotosResponseBodyFromJSON,
  OrganizationPhotosResponseBodyToJSON,
  OrganizationShipmentPostBody,
  OrganizationShipmentPostBodyFromJSON,
  OrganizationShipmentPostBodyToJSON,
  OrganizationShipmentPutBody,
  OrganizationShipmentPutBodyFromJSON,
  OrganizationShipmentPutBodyToJSON,
  OrganizationShipmentResponseBody,
  OrganizationShipmentResponseBodyFromJSON,
  OrganizationShipmentResponseBodyToJSON,
  OrganizationShipmentRowProductsResponseBody,
  OrganizationShipmentRowProductsResponseBodyFromJSON,
  OrganizationShipmentRowProductsResponseBodyToJSON,
  OrganizationShipmentRowsResponseBody,
  OrganizationShipmentRowsResponseBodyFromJSON,
  OrganizationShipmentRowsResponseBodyToJSON,
  OrganizationShipmentsResponseBody,
  OrganizationShipmentsResponseBodyFromJSON,
  OrganizationShipmentsResponseBodyToJSON,
  ShipmentAdditionalServicePostBody,
  ShipmentAdditionalServicePostBodyFromJSON,
  ShipmentAdditionalServicePostBodyToJSON,
  ShipmentAdditionalServicePutBody,
  ShipmentAdditionalServicePutBodyFromJSON,
  ShipmentAdditionalServicePutBodyToJSON,
  ShipmentAdditionalServiceResponseBody,
  ShipmentAdditionalServiceResponseBodyFromJSON,
  ShipmentAdditionalServiceResponseBodyToJSON,
  ShipmentAdditionalServicesResponseBody,
  ShipmentAdditionalServicesResponseBodyFromJSON,
  ShipmentAdditionalServicesResponseBodyToJSON,
} from '../models';

export interface OrganizationShipmentsApiCreateOrganizationShipmentRequest {
  organizationId: string;
  organizationShipmentPostBody: OrganizationShipmentPostBody;
}

export interface OrganizationShipmentsApiCreateShipmentAdditionalServiceRequest {
  organizationId: string;
  shipmentId: number;
  shipmentAdditionalServicePostBody: ShipmentAdditionalServicePostBody;
}

export interface OrganizationShipmentsApiDeleteOrganizationShipmentRequest {
  organizationId: string;
  shipmentId: number;
}

export interface OrganizationShipmentsApiDeleteShipmentAdditionalServiceRequest {
  organizationId: string;
  shipmentId: number;
  shipmentAdditionalServiceId: number;
}

export interface OrganizationShipmentsApiGetOrganizationShipmentRequest {
  organizationId: string;
  shipmentId: number;
}

export interface OrganizationShipmentsApiGetOrganizationShipmentAdditionalServiceRequest {
  organizationId: string;
  shipmentId: number;
  shipmentAdditionalServiceId: number;
}

export interface OrganizationShipmentsApiGetOrganizationShipmentAdditionalServicesRequest {
  organizationId: string;
  shipmentId: number;
}

export interface OrganizationShipmentsApiGetOrganizationShipmentPhotosRequest {
  organizationId: string;
  shipmentId: number;
}

export interface OrganizationShipmentsApiGetOrganizationShipmentRowProductsRequest {
  organizationId: string;
  shipmentId: number;
  shipmentRowId: number;
}

export interface OrganizationShipmentsApiGetOrganizationShipmentRowsRequest {
  organizationId: string;
  shipmentId: number;
}

export interface OrganizationShipmentsApiGetOrganizationShipmentsRequest {
  organizationId: string;
  next?: number;
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
  filterOutOtherCarriers?: boolean;
  getShipmentsByDriveDate?: boolean;
}

export interface OrganizationShipmentsApiPostOrganizationShipmentPhotoRequest {
  organizationId: string;
  shipmentId: number;
  description: string;
  timestamp: Date;
  shipmentRowId?: number;
  photo?: Blob;
}

export interface OrganizationShipmentsApiUpdateOrganizationShipmentRequest {
  organizationId: string;
  shipmentId: number;
  organizationShipmentPutBody: OrganizationShipmentPutBody;
}

export interface OrganizationShipmentsApiUpdateShipmentAdditionalServiceRequest {
  organizationId: string;
  shipmentId: number;
  shipmentAdditionalServiceId: number;
  shipmentAdditionalServicePutBody: ShipmentAdditionalServicePutBody;
}

/**
 *
 */
export class OrganizationShipmentsApi extends runtime.BaseAPI {
  /**
   * Create new shipment.
   */
  async createOrganizationShipmentRaw(
    requestParameters: OrganizationShipmentsApiCreateOrganizationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationShipmentResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrganizationShipment.',
      );
    }

    if (
      requestParameters.organizationShipmentPostBody === null ||
      requestParameters.organizationShipmentPostBody === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationShipmentPostBody',
        'Required parameter requestParameters.organizationShipmentPostBody was null or undefined when calling createOrganizationShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationShipmentPostBodyToJSON(requestParameters.organizationShipmentPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationShipmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create new shipment.
   */
  async createOrganizationShipment(
    requestParameters: OrganizationShipmentsApiCreateOrganizationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationShipmentResponseBody> {
    const response = await this.createOrganizationShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create new ShipmentAdditionalService.
   */
  async createShipmentAdditionalServiceRaw(
    requestParameters: OrganizationShipmentsApiCreateShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentAdditionalServiceResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createShipmentAdditionalService.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling createShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServicePostBody === null ||
      requestParameters.shipmentAdditionalServicePostBody === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServicePostBody',
        'Required parameter requestParameters.shipmentAdditionalServicePostBody was null or undefined when calling createShipmentAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/additional_services`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ShipmentAdditionalServicePostBodyToJSON(requestParameters.shipmentAdditionalServicePostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShipmentAdditionalServiceResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Create new ShipmentAdditionalService.
   */
  async createShipmentAdditionalService(
    requestParameters: OrganizationShipmentsApiCreateShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentAdditionalServiceResponseBody> {
    const response = await this.createShipmentAdditionalServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a shipment by id.
   */
  async deleteOrganizationShipmentRaw(
    requestParameters: OrganizationShipmentsApiDeleteOrganizationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteOrganizationShipment.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling deleteOrganizationShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a shipment by id.
   */
  async deleteOrganizationShipment(
    requestParameters: OrganizationShipmentsApiDeleteOrganizationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteOrganizationShipmentRaw(requestParameters, initOverrides);
  }

  /**
   * Delete ShipmentAdditionalService.
   */
  async deleteShipmentAdditionalServiceRaw(
    requestParameters: OrganizationShipmentsApiDeleteShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteShipmentAdditionalService.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling deleteShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServiceId === null ||
      requestParameters.shipmentAdditionalServiceId === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServiceId',
        'Required parameter requestParameters.shipmentAdditionalServiceId was null or undefined when calling deleteShipmentAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/additional_services/{shipment_additional_service_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(
            `{${'shipment_additional_service_id'}}`,
            encodeURIComponent(String(requestParameters.shipmentAdditionalServiceId)),
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete ShipmentAdditionalService.
   */
  async deleteShipmentAdditionalService(
    requestParameters: OrganizationShipmentsApiDeleteShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteShipmentAdditionalServiceRaw(requestParameters, initOverrides);
  }

  /**
   * Get a shipment by id.
   */
  async getOrganizationShipmentRaw(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationShipmentResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationShipment.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getOrganizationShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationShipmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a shipment by id.
   */
  async getOrganizationShipment(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationShipmentResponseBody> {
    const response = await this.getOrganizationShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentAdditionalService of a Shipment.
   */
  async getOrganizationShipmentAdditionalServiceRaw(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentAdditionalServiceResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationShipmentAdditionalService.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getOrganizationShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServiceId === null ||
      requestParameters.shipmentAdditionalServiceId === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServiceId',
        'Required parameter requestParameters.shipmentAdditionalServiceId was null or undefined when calling getOrganizationShipmentAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/additional_services/{shipment_additional_service_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(
            `{${'shipment_additional_service_id'}}`,
            encodeURIComponent(String(requestParameters.shipmentAdditionalServiceId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShipmentAdditionalServiceResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get ShipmentAdditionalService of a Shipment.
   */
  async getOrganizationShipmentAdditionalService(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentAdditionalServiceResponseBody> {
    const response = await this.getOrganizationShipmentAdditionalServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentAdditionalServices of a Shipment.
   */
  async getOrganizationShipmentAdditionalServicesRaw(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentAdditionalServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentAdditionalServicesResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationShipmentAdditionalServices.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getOrganizationShipmentAdditionalServices.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/additional_services`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShipmentAdditionalServicesResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get ShipmentAdditionalServices of a Shipment.
   */
  async getOrganizationShipmentAdditionalServices(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentAdditionalServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentAdditionalServicesResponseBody> {
    const response = await this.getOrganizationShipmentAdditionalServicesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get list of photo metadata resources of a Shipment.
   */
  async getOrganizationShipmentPhotosRaw(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationPhotosResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationShipmentPhotos.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getOrganizationShipmentPhotos.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/photos`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationPhotosResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get list of photo metadata resources of a Shipment.
   */
  async getOrganizationShipmentPhotos(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationPhotosResponseBody> {
    const response = await this.getOrganizationShipmentPhotosRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentRowProducts of a ShipmentRow.
   */
  async getOrganizationShipmentRowProductsRaw(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentRowProductsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationShipmentRowProductsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationShipmentRowProducts.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getOrganizationShipmentRowProducts.',
      );
    }

    if (requestParameters.shipmentRowId === null || requestParameters.shipmentRowId === undefined) {
      throw new runtime.RequiredError(
        'shipmentRowId',
        'Required parameter requestParameters.shipmentRowId was null or undefined when calling getOrganizationShipmentRowProducts.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/rows/{shipment_row_id}/shipment_row_products`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(`{${'shipment_row_id'}}`, encodeURIComponent(String(requestParameters.shipmentRowId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrganizationShipmentRowProductsResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get ShipmentRowProducts of a ShipmentRow.
   */
  async getOrganizationShipmentRowProducts(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentRowProductsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationShipmentRowProductsResponseBody> {
    const response = await this.getOrganizationShipmentRowProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentRows of a Shipment.
   */
  async getOrganizationShipmentRowsRaw(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationShipmentRowsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationShipmentRows.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getOrganizationShipmentRows.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/rows`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrganizationShipmentRowsResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get ShipmentRows of a Shipment.
   */
  async getOrganizationShipmentRows(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationShipmentRowsResponseBody> {
    const response = await this.getOrganizationShipmentRowsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all shipments of organization.
   */
  async getOrganizationShipmentsRaw(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationShipmentsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationShipments.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    if (requestParameters.filterOutOtherCarriers !== undefined) {
      queryParameters['filter_out_other_carriers'] = requestParameters.filterOutOtherCarriers;
    }

    if (requestParameters.getShipmentsByDriveDate !== undefined) {
      queryParameters['get_shipments_by_drive_date'] = requestParameters.getShipmentsByDriveDate;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationShipmentsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all shipments of organization.
   */
  async getOrganizationShipments(
    requestParameters: OrganizationShipmentsApiGetOrganizationShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationShipmentsResponseBody> {
    const response = await this.getOrganizationShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Post a photo related to a shipment
   */
  async postOrganizationShipmentPhotoRaw(
    requestParameters: OrganizationShipmentsApiPostOrganizationShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationPhotoResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling postOrganizationShipmentPhoto.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling postOrganizationShipmentPhoto.',
      );
    }

    if (requestParameters.description === null || requestParameters.description === undefined) {
      throw new runtime.RequiredError(
        'description',
        'Required parameter requestParameters.description was null or undefined when calling postOrganizationShipmentPhoto.',
      );
    }

    if (requestParameters.timestamp === null || requestParameters.timestamp === undefined) {
      throw new runtime.RequiredError(
        'timestamp',
        'Required parameter requestParameters.timestamp was null or undefined when calling postOrganizationShipmentPhoto.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.description !== undefined) {
      queryParameters['description'] = requestParameters.description;
    }

    if (requestParameters.timestamp !== undefined) {
      queryParameters['timestamp'] = (requestParameters.timestamp as any).toISOString();
    }

    if (requestParameters.shipmentRowId !== undefined) {
      queryParameters['shipment_row_id'] = requestParameters.shipmentRowId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.photo !== undefined) {
      formParams.append('photo', requestParameters.photo as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/photos`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationPhotoResponseBodyFromJSON(jsonValue));
  }

  /**
   * Post a photo related to a shipment
   */
  async postOrganizationShipmentPhoto(
    requestParameters: OrganizationShipmentsApiPostOrganizationShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationPhotoResponseBody> {
    const response = await this.postOrganizationShipmentPhotoRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update a shipment by id.
   */
  async updateOrganizationShipmentRaw(
    requestParameters: OrganizationShipmentsApiUpdateOrganizationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationShipmentResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateOrganizationShipment.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling updateOrganizationShipment.',
      );
    }

    if (
      requestParameters.organizationShipmentPutBody === null ||
      requestParameters.organizationShipmentPutBody === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationShipmentPutBody',
        'Required parameter requestParameters.organizationShipmentPutBody was null or undefined when calling updateOrganizationShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationShipmentPutBodyToJSON(requestParameters.organizationShipmentPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationShipmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Update a shipment by id.
   */
  async updateOrganizationShipment(
    requestParameters: OrganizationShipmentsApiUpdateOrganizationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationShipmentResponseBody> {
    const response = await this.updateOrganizationShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update ShipmentAdditionalService.
   */
  async updateShipmentAdditionalServiceRaw(
    requestParameters: OrganizationShipmentsApiUpdateShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentAdditionalServiceResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateShipmentAdditionalService.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling updateShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServiceId === null ||
      requestParameters.shipmentAdditionalServiceId === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServiceId',
        'Required parameter requestParameters.shipmentAdditionalServiceId was null or undefined when calling updateShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServicePutBody === null ||
      requestParameters.shipmentAdditionalServicePutBody === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServicePutBody',
        'Required parameter requestParameters.shipmentAdditionalServicePutBody was null or undefined when calling updateShipmentAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/additional_services/{shipment_additional_service_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(
            `{${'shipment_additional_service_id'}}`,
            encodeURIComponent(String(requestParameters.shipmentAdditionalServiceId)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ShipmentAdditionalServicePutBodyToJSON(requestParameters.shipmentAdditionalServicePutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShipmentAdditionalServiceResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Update ShipmentAdditionalService.
   */
  async updateShipmentAdditionalService(
    requestParameters: OrganizationShipmentsApiUpdateShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentAdditionalServiceResponseBody> {
    const response = await this.updateShipmentAdditionalServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
