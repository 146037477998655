/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShipmentUpdateEvent,
  ShipmentUpdateEventFromJSON,
  ShipmentUpdateEventFromJSONTyped,
  ShipmentUpdateEventToJSON,
} from './ShipmentUpdateEvent';

/**
 *
 * @export
 * @interface ShipmentUpdateHistoryResponseBody
 */
export interface ShipmentUpdateHistoryResponseBody {
  /**
   *
   * @type {Array<ShipmentUpdateEvent>}
   * @memberof ShipmentUpdateHistoryResponseBody
   */
  data: Array<ShipmentUpdateEvent>;
}

export function ShipmentUpdateHistoryResponseBodyFromJSON(json: any): ShipmentUpdateHistoryResponseBody {
  return ShipmentUpdateHistoryResponseBodyFromJSONTyped(json, false);
}

export function ShipmentUpdateHistoryResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShipmentUpdateHistoryResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(ShipmentUpdateEventFromJSON),
  };
}

export function ShipmentUpdateHistoryResponseBodyToJSON(value?: ShipmentUpdateHistoryResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(ShipmentUpdateEventToJSON),
  };
}
