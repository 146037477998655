import React from 'react';
import { DateTimePickerProps, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { dateTimeFormat } from '../../formatters';
import { convertToJsDateWithZeroSecondsOrNull, TimeClockView } from './StandardTimePicker';
import { mobileActionBar } from './StandardDatePicker';
import { isPwa, isTouchDevive } from '../../utils';

export const StandardDateTimePicker: React.FC<DateTimePickerProps<any>> = (props) => {
  return (
    <DesktopDateTimePicker
      {...props}
      format={dateTimeFormat}
      value={props.value instanceof Date ? DateTime.fromJSDate(props.value) : props.value}
      viewRenderers={{ hours: TimeClockView, minutes: TimeClockView }}
      slotProps={{
        actionBar: mobileActionBar,
        textField: {
          ...props.slotProps?.textField,
          size: 'small',
          variant: 'standard',
          error: (props.slotProps?.textField as any).error,
          helperText: (props.slotProps?.textField as any).helperText,
          inputProps: {
            placeholder: '',
          },
        },
        tabs: {
          hidden: isPwa() || isTouchDevive(),
        },
      }}
      onChange={(time, context) => {
        const formattedTime = convertToJsDateWithZeroSecondsOrNull(time);
        props.onChange && props.onChange(formattedTime, context);
      }}
    />
  );
};
