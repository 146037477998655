/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  DistancesResponseBody,
  DistancesResponseBodyFromJSON,
  DistancesResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  SearchDistancesPostBody,
  SearchDistancesPostBodyFromJSON,
  SearchDistancesPostBodyToJSON,
  SearchOrganizationShipmentsPostBody,
  SearchOrganizationShipmentsPostBodyFromJSON,
  SearchOrganizationShipmentsPostBodyToJSON,
  SearchShipmentRowsResponseBody,
  SearchShipmentRowsResponseBodyFromJSON,
  SearchShipmentRowsResponseBodyToJSON,
  SearchShipmentsPostBody,
  SearchShipmentsPostBodyFromJSON,
  SearchShipmentsPostBodyToJSON,
  ShipmentsResponseBody,
  ShipmentsResponseBodyFromJSON,
  ShipmentsResponseBodyToJSON,
} from '../models';

export interface SearchApiSearchDistancesRequest {
  searchDistancesPostBody: SearchDistancesPostBody;
  next?: number;
}

export interface SearchApiSearchOrganizationShipmentsRequest {
  organizationId: string;
  searchOrganizationShipmentsPostBody: SearchOrganizationShipmentsPostBody;
  next?: number;
}

export interface SearchApiSearchShipmentRowsRequest {
  serialNumber: string;
  state?: SearchShipmentRowsStateEnum;
}

export interface SearchApiSearchShipmentsRequest {
  searchShipmentsPostBody: SearchShipmentsPostBody;
  next?: number;
  filterOutOtherCarriers?: boolean;
}

/**
 *
 */
export class SearchApi extends runtime.BaseAPI {
  /**
   * Get all Distances or filter them by their properties.
   */
  async searchDistancesRaw(
    requestParameters: SearchApiSearchDistancesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DistancesResponseBody>> {
    if (requestParameters.searchDistancesPostBody === null || requestParameters.searchDistancesPostBody === undefined) {
      throw new runtime.RequiredError(
        'searchDistancesPostBody',
        'Required parameter requestParameters.searchDistancesPostBody was null or undefined when calling searchDistances.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/search/distances`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SearchDistancesPostBodyToJSON(requestParameters.searchDistancesPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DistancesResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all Distances or filter them by their properties.
   */
  async searchDistances(
    requestParameters: SearchApiSearchDistancesRequest,
    initOverrides?: RequestInit,
  ): Promise<DistancesResponseBody> {
    const response = await this.searchDistancesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all Shipments of organization or filter them by their properties.
   */
  async searchOrganizationShipmentsRaw(
    requestParameters: SearchApiSearchOrganizationShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling searchOrganizationShipments.',
      );
    }

    if (
      requestParameters.searchOrganizationShipmentsPostBody === null ||
      requestParameters.searchOrganizationShipmentsPostBody === undefined
    ) {
      throw new runtime.RequiredError(
        'searchOrganizationShipmentsPostBody',
        'Required parameter requestParameters.searchOrganizationShipmentsPostBody was null or undefined when calling searchOrganizationShipments.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/search/organizations/{organization_id}/shipments`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SearchOrganizationShipmentsPostBodyToJSON(requestParameters.searchOrganizationShipmentsPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all Shipments of organization or filter them by their properties.
   */
  async searchOrganizationShipments(
    requestParameters: SearchApiSearchOrganizationShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentsResponseBody> {
    const response = await this.searchOrganizationShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all ShipmentRows or filter them by their properties.
   */
  async searchShipmentRowsRaw(
    requestParameters: SearchApiSearchShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<SearchShipmentRowsResponseBody>> {
    if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
      throw new runtime.RequiredError(
        'serialNumber',
        'Required parameter requestParameters.serialNumber was null or undefined when calling searchShipmentRows.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.serialNumber !== undefined) {
      queryParameters['serial_number'] = requestParameters.serialNumber;
    }

    if (requestParameters.state !== undefined) {
      queryParameters['state'] = requestParameters.state;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/search/shipment_rows`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchShipmentRowsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all ShipmentRows or filter them by their properties.
   */
  async searchShipmentRows(
    requestParameters: SearchApiSearchShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<SearchShipmentRowsResponseBody> {
    const response = await this.searchShipmentRowsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all Shipments or filter them by their properties.
   */
  async searchShipmentsRaw(
    requestParameters: SearchApiSearchShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentsResponseBody>> {
    if (requestParameters.searchShipmentsPostBody === null || requestParameters.searchShipmentsPostBody === undefined) {
      throw new runtime.RequiredError(
        'searchShipmentsPostBody',
        'Required parameter requestParameters.searchShipmentsPostBody was null or undefined when calling searchShipments.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.filterOutOtherCarriers !== undefined) {
      queryParameters['filter_out_other_carriers'] = requestParameters.filterOutOtherCarriers;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/search/shipments`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SearchShipmentsPostBodyToJSON(requestParameters.searchShipmentsPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all Shipments or filter them by their properties.
   */
  async searchShipments(
    requestParameters: SearchApiSearchShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentsResponseBody> {
    const response = await this.searchShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum SearchShipmentRowsStateEnum {
  ReadyForPickup = 'ready_for_pickup',
  PickedUp = 'picked_up',
  Delivered = 'delivered',
}
