import {
  Collapse,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { api, ShipmentUpdateEvent } from '../../../api';
import { FullWidthButton } from '../../../components/StyledComponents/FullWidthButton';
import { formatDateTime } from '../../../formatters';

interface UpdateInfoProps {
  shipmentId: number | null | undefined;
  isLoading: boolean;
}

export const DenseTableCell = styled(TableCell)({
  padding: '0.25rem',
  paddingRight: '1rem',
});

export const HeaderTableCell = styled(DenseTableCell)({
  fontWeight: 'bold',
});

export const EmptyRowCell = styled(DenseTableCell)({
  textAlign: 'center',
});

export const UpdateHistory: React.FC<UpdateInfoProps> = ({ shipmentId, isLoading }) => {
  const [showAllUpdates, setShowAllUpdates] = useState(false);
  const [updateHistory, setUpdateHistory] = useState<ShipmentUpdateEvent[]>([{}]);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const loadShipmentUpdateHistory = async (shipmentId: number) => {
    const shipmentUpdates = (await api.shipments.getShipmentUpdateHistory({ shipmentId: shipmentId })).data;
    setUpdateHistory(shipmentUpdates);
  };

  useEffect(() => {
    if (shipmentId && showAllUpdates) {
      loadShipmentUpdateHistory(shipmentId);
    }
  }, [showAllUpdates, isLoading]);

  return (
    <div className="shipment-update-history-table">
      <Collapse in={showAllUpdates}>
        {showAllUpdates ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <HeaderTableCell>Aikaleima</HeaderTableCell>
                  <HeaderTableCell>Tapahtuma</HeaderTableCell>
                  <HeaderTableCell>Selite</HeaderTableCell>
                  <HeaderTableCell>Käyttäjä</HeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {updateHistory.length > 0 ? (
                  updateHistory.map((updateEvent: ShipmentUpdateEvent, index: number) => {
                    return (
                      <TableRow key={index}>
                        <DenseTableCell>
                          {updateEvent.created_at ? formatDateTime(updateEvent.created_at) : ''}
                        </DenseTableCell>
                        <DenseTableCell>{updateEvent.event ?? ''}</DenseTableCell>
                        <DenseTableCell>{updateEvent.description ?? ''}</DenseTableCell>
                        <DenseTableCell>{updateEvent.username ?? ''}</DenseTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <EmptyRowCell colSpan={4}>Ei tuloksia.</EmptyRowCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Collapse>
      <Tooltip
        title={showAllUpdates ? 'Piilota muokkaushistoria' : 'Näytä muokkaushistoria'}
        aria-label={'show-shipment-update-history-tooltip'}
        open={isTooltipVisible}
        onOpen={() => {
          setIsTooltipVisible(true);
        }}
        onClose={() => setIsTooltipVisible(false)}
      >
        <FullWidthButton
          variant="text"
          id="show-shipment-update-history-button"
          onClick={() => {
            setIsTooltipVisible(false);
            setShowAllUpdates(!showAllUpdates);
          }}
        >
          {!showAllUpdates ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </FullWidthButton>
      </Tooltip>
    </div>
  );
};
