import { debounce, groupBy, orderBy, sortBy } from 'lodash';
import React, { Dispatch } from 'react';
import {
  api,
  apiV2,
  CarrierCar,
  Driver,
  getAllPages,
  CarrierLoad,
  Client,
  Shipment,
  ShipmentsPatchBody,
  ShipmentStateEnum,
  CarrierLoadPostBodyStateEnum,
  getData,
} from '../../api';
import { TableState } from 'react-table';
import { getViewSettings, updateViewSettings } from '../settings';
import { orderShipments } from '../../utils';
import { dateFormat } from '../../formatters';
import { DateTime } from 'luxon';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { DateRange, getDefaultDateRange, SetDateRangeAction } from '../../utils/dateRangeUtils';
import { CarrierUser } from '../../reducers/authReducer';
import { convertToUTCNoon, todayNoon } from '../../components/DateAndTimePickers/StandardDatePicker';

export interface CoordinationShipment extends Shipment {
  organizationName: string;
  load: CoordinationLoad | null;
}

export interface CoordinationLoad extends CarrierLoad {
  organizationName: string;
  driver: Driver | null;
  car: CarrierCar | null;
}

export interface ClientWithGroup extends Client {
  group?: string;
}

export type EmptyLoad = Pick<CarrierLoad, 'organization_id' | 'car_id' | 'driver_id' | 'trailer_id'>;

type UnassignedShipmentOrder = { id: string; value: string[] };

export interface CoordinationViewSettings {
  onlyShipmentsWithDuplicaDeliveryAddress: boolean;
  onlyShipmentsWithoutLoads: boolean;
  onlyShipmentsWithPreciseDelivery: boolean;
  onlyUndeliveredShipments: boolean;
  hideCancelledShipments: boolean;
  showStateColors: boolean;
  unassignedShipmentFilter: string;
  unassignedShipmentOrder: UnassignedShipmentOrder;
  selectedClients: ClientWithGroup[];
  selectedGroup: string;
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
  organizationGroups: ClientWithGroup[];
  emptyLoads: EmptyLoad[];
}

export interface State {
  shipments: CoordinationShipment[];
  clients: Client[];
  loads: CoordinationLoad[];
  loadsByDriver: { driver: Driver; loads: CoordinationLoad[] }[];
  shipmentsByLoad: Record<CarrierLoad['id'], CoordinationShipment[]>;
  drivers: Driver[];
  cars: CarrierCar[];
  notification: NotificationType;
  dateRange: DateRange;
  isLoading: boolean;
  loadId: CarrierLoad['id'] | '';
  load: CarrierLoad | null;
  loadOrganizationName: string;
  isLoadLoading: boolean;
  loadIdErrorText: string;
  unassignedShipments: CoordinationShipment[];
  selectedShipments: { [key: number]: boolean };
  isLoadModalOpen: boolean;
  presetLoadFields: Partial<CarrierLoad>;
  viewSettings: {
    onlyShipmentsWithDuplicaDeliveryAddress: boolean;
    onlyShipmentsWithoutLoads: boolean;
    onlyShipmentsWithPreciseDelivery: boolean;
    onlyUndeliveredShipments: boolean;
    hideCancelledShipments: boolean;
    unassignedShipmentFilter: string;
    unassignedShipmentOrder: UnassignedShipmentOrder;
    showStateColors: boolean;
    selectedGroup: string;
    selectedClients: ClientWithGroup[];
    organizationGroups: ClientWithGroup[];
    filters: TableState['filters'];
    sortBy: TableState['sortBy'];
    emptyLoads: EmptyLoad[];
  };
}

export const getInitialState = (): State => {
  return {
    shipments: [],
    clients: [],
    loads: [],
    drivers: [],
    cars: [],
    loadsByDriver: [],
    shipmentsByLoad: {},
    notification: {
      message: null,
    },
    dateRange: getDefaultDateRange(),
    isLoading: true,
    loadId: '',
    load: null,
    loadOrganizationName: '',
    isLoadLoading: false,
    loadIdErrorText: '',
    unassignedShipments: [],
    selectedShipments: {},
    isLoadModalOpen: false,
    presetLoadFields: {},
    viewSettings: {
      onlyShipmentsWithDuplicaDeliveryAddress: false,
      onlyShipmentsWithoutLoads: true,
      onlyShipmentsWithPreciseDelivery: false,
      onlyUndeliveredShipments: false,
      hideCancelledShipments: true,
      showStateColors: false,
      unassignedShipmentFilter: '',
      unassignedShipmentOrder: { id: '', value: [] },
      selectedClients: [],
      selectedGroup: '',
      organizationGroups: [],
      filters: [],
      sortBy: [],
      emptyLoads: [],
    },
  };
};

export type Action =
  | {
      type: 'INITIALIZE';
      payload: {
        shipments: Shipment[];
        loads: CarrierLoad[];
        clients: Client[];
        drivers: Driver[];
        cars: CarrierCar[];
        settings: CoordinationViewSettings;
      };
    }
  | SetMessageAction
  | SetDateRangeAction
  | SetLoadingAction
  | { type: 'SET_LOAD_ID'; payload: CarrierLoad['id'] | null }
  | { type: 'SET_LOAD_LOADING'; payload: boolean }
  | { type: 'SET_LOAD'; payload: CarrierLoad | null }
  | { type: 'SET_IS_LOAD_MODAL_OPEN'; payload: { isLoadModalOpen: boolean; presetLoadFields?: Partial<CarrierLoad> } }
  | { type: 'SET_LOAD_ERROR'; payload: string }
  | { type: 'SET_SHIPMENTS_BY_LOAD'; payload: Record<CarrierLoad['id'], CoordinationShipment[]> }
  | { type: 'SELECT_SHIPMENT'; payload: { shipmentId: Shipment['id']; value: boolean } }
  | { type: 'CLEAR_SELECTED_SHIPMENTS' }
  | { type: 'SELECT_ALL_SHIPMENTS' }
  | {
      type: 'SET_VIEW_SETTINGS';
      payload: {
        onlyShipmentsWithDuplicaDeliveryAddress?: boolean;
        onlyShipmentsWithPreciseDelivery?: boolean;
        onlyShipmentsWithoutLoads?: boolean;
        hideCancelledShipments?: boolean;
        showStateColors?: boolean;
        onlyUndeliveredShipments?: boolean;
        unassignedShipmentFilter: string;
        unassignedShipmentOrder: UnassignedShipmentOrder;
        selectedClients: ClientWithGroup[];
        selectedGroup: string;
        organizationGroups: ClientWithGroup[];
        emptyLoads: EmptyLoad[];
      };
    }
  | {
      type: 'SET_TABLE_SETTINGS';
      payload: TableState<any>;
    };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      const loads: CoordinationLoad[] = action.payload.loads.map((load) => {
        return {
          ...load,
          organizationName: action.payload.clients.find((client) => client.id === load.organization_id)?.name ?? '',
          driver: action.payload.drivers.find((driver) => driver.id === load?.driver_id) ?? null,
          car: action.payload.cars.find((car) => car.id === load?.car_id) ?? null,
        };
      });
      const shipments: CoordinationShipment[] = action.payload.shipments.map((shipment) => {
        const load = loads.find((load) => load.id === shipment.load_id) ?? null;
        return {
          ...shipment,
          organizationName: action.payload.clients.find((client) => client.id === shipment.organization_id)?.name ?? '',
          load: load,
          trailerLicenceplate: action.payload.cars.find((car) => car.id === load?.trailer_id)?.licence_plate,
        };
      });
      const groupedDriverLoads = groupBy(loads, 'driver_id');
      const sortedDrivers = (
        Object.keys(groupedDriverLoads)
          .map((driverId) => action.payload.drivers.find((driver) => driver.id === Number(driverId)))
          .filter((driver) => driver !== undefined) as Driver[]
      ).sort((driverA, driverB) => {
        return `${driverA.last_name} ${driverA.first_name}`.localeCompare(`${driverB.last_name} ${driverB.first_name}`);
      });
      const loadsByDriver = sortedDrivers.map((driver) => ({ driver, loads: groupedDriverLoads[driver.id] }));
      const shipmentsByLoad = groupBy(shipments, 'load_id');
      for (const loadId in shipmentsByLoad) {
        shipmentsByLoad[loadId] = sortBy(shipmentsByLoad[loadId], 'order_in_load');
      }
      const unassignedShipments = orderBy(
        shipments.filter(
          (shipment) =>
            shipment.load_id === null &&
            (shipment.state === ShipmentStateEnum.Noudettavissa || shipment.state === ShipmentStateEnum.EiVarastossa),
        ),
        state.viewSettings.unassignedShipmentOrder.value,
      );
      return {
        ...state,
        shipments,
        unassignedShipments,
        clients: action.payload.clients,
        loads,
        drivers: action.payload.drivers,
        cars: action.payload.cars,
        loadsByDriver,
        shipmentsByLoad,
        viewSettings: {
          onlyShipmentsWithDuplicaDeliveryAddress:
            action.payload.settings.onlyShipmentsWithDuplicaDeliveryAddress ?? false,
          onlyShipmentsWithPreciseDelivery: action.payload.settings.onlyShipmentsWithPreciseDelivery ?? false,
          onlyShipmentsWithoutLoads: action.payload.settings.onlyShipmentsWithoutLoads ?? true,
          hideCancelledShipments: action.payload.settings.hideCancelledShipments ?? true,
          showStateColors: action.payload.settings.showStateColors ?? false,
          onlyUndeliveredShipments: action.payload.settings.onlyUndeliveredShipments ?? false,
          unassignedShipmentFilter: action.payload.settings.unassignedShipmentFilter ?? '',
          unassignedShipmentOrder: action.payload.settings.unassignedShipmentOrder ?? {
            id: 'agreed_delivery_window_ends_at',
            value: ['agreed_delivery_window_ends_at', 'delivery_postal_code'],
          },
          selectedClients: action.payload.settings.selectedClients ?? action.payload.clients,
          selectedGroup: action.payload.settings.selectedGroup ?? '',
          filters: action.payload.settings.filters ?? [],
          sortBy: action.payload.settings.sortBy ?? [],
          organizationGroups: action.payload.settings.organizationGroups ?? [],
          emptyLoads: action.payload.settings.emptyLoads ?? [],
        },
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_LOAD_ID':
      return {
        ...state,
        loadId: action.payload ?? '',
      };
    case 'SET_LOAD_LOADING':
      return {
        ...state,
        isLoadLoading: action.payload,
      };
    case 'SET_LOAD':
      return {
        ...state,
        load: action.payload,
        loadOrganizationName: state.clients.find((client) => client.id === action.payload?.organization_id)?.name ?? '',
      };
    case 'SET_LOAD_ERROR':
      return {
        ...state,
        loadIdErrorText: action.payload,
      };
    case 'SET_VIEW_SETTINGS':
      updateViewSettings('coordination', action.payload);
      return {
        ...state,
        viewSettings: { ...state.viewSettings, ...action.payload },
      };
    case 'SET_TABLE_SETTINGS':
      const settings = {
        ...state.viewSettings,
        filters: action.payload.filters,
        sortBy: action.payload.sortBy,
        hiddenColumns: action.payload.hiddenColumns,
      };
      updateViewSettings('coordination', settings);
      const newState = {
        ...state,
        viewSettings: settings,
      };
      return newState;
    case 'SET_SHIPMENTS_BY_LOAD':
      return {
        ...state,
        shipmentsByLoad: action.payload,
      };
    case 'SELECT_SHIPMENT':
      return {
        ...state,
        selectedShipments: { ...state.selectedShipments, [action.payload.shipmentId]: action.payload.value },
      };
    case 'CLEAR_SELECTED_SHIPMENTS':
      return {
        ...state,
        selectedShipments: {},
      };
    case 'SELECT_ALL_SHIPMENTS':
      return {
        ...state,
        selectedShipments: state.shipments.reduce((acc, shipment) => {
          acc[shipment.id] = true;
          return acc;
        }, state.selectedShipments),
      };
    case 'SET_IS_LOAD_MODAL_OPEN':
      return {
        ...state,
        isLoadModalOpen: action.payload.isLoadModalOpen,
        presetLoadFields: action.payload.presetLoadFields ?? {},
      };
  }
};

export const getLoad = debounce(
  async (carrierUser: CarrierUser, loadId: CarrierLoad['id'] | null, dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch({ type: 'SET_LOAD_ERROR', payload: '' });
      dispatch({ type: 'SET_LOAD_LOADING', payload: true });
      if (loadId === null) {
        dispatch({ type: 'SET_LOAD', payload: null });
        dispatch({ type: 'SET_LOAD_LOADING', payload: false });
        return;
      }
      const response = await apiV2.carrier.getCarrierLoad({ carrierId: carrierUser.carrier_id, loadId: loadId });
      dispatch({ type: 'SET_LOAD', payload: response.data });
    } catch (err) {
      switch ((err as any).status) {
        case 404: {
          dispatch({ type: 'SET_LOAD_ERROR', payload: 'Kuormaa ei löytynyt!' });
          break;
        }
        default: {
          dispatch({ type: 'SET_LOAD_ERROR', payload: 'Kuorman tarkistus epäonnistui!' });
          break;
        }
      }
      dispatch({ type: 'SET_LOAD', payload: null });
    }
    dispatch({ type: 'SET_LOAD_LOADING', payload: false });
  },
  1000,
);

export const getSelectedShipmentIds = (
  shipmentsSelectionState: Record<CoordinationShipment['id'], boolean>,
): Shipment['id'][] => {
  return Object.keys(shipmentsSelectionState)
    .map(Number)
    .filter((shipmentId) => shipmentsSelectionState[shipmentId]);
};

export const patchShipments = async (
  loadId: CarrierLoad['id'] | null,
  shipmentIds: Shipment['id'][],
): Promise<void> => {
  const shipmentPatchBodies: ShipmentsPatchBody[] = shipmentIds.map((shipmentId: number, index: number) => ({
    id: shipmentId,
    load_id: loadId,
    order_in_load: loadId !== null ? Number(index) + 1 : null,
  }));
  await api.shipments.patchShipments({ shipmentsPatchBody: shipmentPatchBodies });
};

export const addShipmentsToLoad = async (
  carrierUser: CarrierUser,
  shipmentsSelectionState: Record<CoordinationShipment['id'], boolean>,
  state: State,
  dispatch: Dispatch<Action>,
): Promise<void> => {
  try {
    if (state.loadId === '') {
      return;
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    try {
      await apiV2.carrier.getCarrierLoad({
        carrierId: carrierUser.carrier_id,
        loadId: state.loadId,
      });
    } catch (err) {
      if ((err as any) === 404) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: 'Kuormaa ei löytynyt!',
          },
        });
        dispatch({
          type: 'SET_LOADING',
          payload: false,
        });
        return;
      }
      throw err;
    }
    const selectedShipmentIds = getSelectedShipmentIds(shipmentsSelectionState);
    await patchShipments(
      state.loadId,
      orderShipments(state.shipmentsByLoad[state.loadId])
        .map((shipment) => {
          return shipment.id;
        })
        .concat(selectedShipmentIds),
    );
    await load(carrierUser, state, dispatch);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: `Lisätty ${selectedShipmentIds.length} toimitus${
          selectedShipmentIds.length > 1 ? 'ta' : ''
        } kuormaan ${state.loadId}`,
      },
    });
  } catch (err) {
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Kuorman päivitys epäonnistui!',
      },
    });
    console.error(err);
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

export const createEmptyLoads = async (
  carrierUser: CarrierUser,
  state: State,
  dispatch: Dispatch<Action>,
  date: DateTime,
) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    await Promise.all(
      state.viewSettings.emptyLoads.map((load) => {
        return apiV2.carrier.postCarrierLoad({
          carrierId: carrierUser.carrier_id,
          carrierLoadPostBody: {
            ...load,
            state: CarrierLoadPostBodyStateEnum.Uusi,
            drive_date: convertToUTCNoon(date),
            picked_up_at: null,
            driver_started_at: null,
            driver_ended_at: null,
            note: null,
          },
        });
      }),
    );
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Tyhjät kuormat luotu onnistuneesti!',
      },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Tyhjien kuormien luonti epäonnistui!',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

export const maxPreciseDeliveryWindowDurationMs = 2 * 60 * 60 * 1000;

export const load = async (
  carrierUser: CarrierUser,
  state: State,
  dispatch: React.Dispatch<Action>,
  abortController?: AbortController,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const settings = getViewSettings<CoordinationViewSettings>('coordination');
    const [shipments, loads, drivers, cars] = await Promise.all([
      getAllPages(
        api.shipments.getShipments.bind(api.shipments),
        {
          agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
          agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
          filterOutOtherCarriers: true,
        },
        abortController,
      ),
      getAllPages(
        apiV2.carrier.getCarrierLoads.bind(apiV2.carrier),
        {
          carrierId: carrierUser.carrier_id,
          driveDateRangeStartsAt: convertToUTCNoon(state.dateRange.start),
          driveDateRangeEndsAt: convertToUTCNoon(state.dateRange.end),
        },
        abortController,
      ),
      getAllPages(api.drivers.getDrivers.bind(api.drivers), {}, abortController),
      getData(apiV2.carrier.getCarrierCars.bind(apiV2.carrier), { carrierId: carrierUser.carrier_id }, abortController),
    ]);
    dispatch({
      type: 'INITIALIZE',
      payload: {
        shipments,
        loads,
        drivers,
        cars: cars.filter((x) => x.is_active),
        clients: carrierUser.clients,
        settings,
      },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa toimituksia',
        severity: 'error',
      },
    });
  } finally {
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  }
};

export const createDeliveryTransports = async (
  carrierUser: CarrierUser,
  state: State,
  dispatch: React.Dispatch<Action>,
  tableRef?: React.MutableRefObject<any>,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const selectedShipments = state.shipments.filter((shipment) => state.selectedShipments[shipment.id]);

    for (const shipment of selectedShipments) {
      await api.shipments.postShipmentDeliveryTransport({ shipmentId: shipment.id });
    }

    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Jakelutoimitukset luotu!',
      },
    });
    tableRef && tableRef.current?.deselectAllRows();
    dispatch({ type: 'CLEAR_SELECTED_SHIPMENTS' });
    load(carrierUser, state, dispatch);
  } finally {
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  }
};

export const addSelectedShipmentsToLoad = async (
  carrierUser: CarrierUser,
  loadId: CarrierLoad['id'] | null,
  state: State,
  dispatch: Dispatch<Action>,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const numberOfMovedShipments = state.shipments.filter(
      (shipment) => state.selectedShipments[shipment.id] && shipment.load_id !== loadId,
    ).length;
    const numberOfUnaffectedShipments =
      Object.values(state.selectedShipments).filter((value) => value).length - numberOfMovedShipments;
    const shipmentsOfNewLoad = orderShipments(
      loadId !== null
        ? orderShipments(state.shipmentsByLoad[loadId] ?? []).concat(
            state.shipments
              .filter((shipment) => state.selectedShipments[shipment.id] && shipment.load_id !== loadId)
              .map((shipment) => ({
                ...shipment,
                load_id: loadId,
                order_in_load: Infinity,
              })),
          )
        : state.shipments
            .filter((shipment) => state.selectedShipments[shipment.id])
            .map((shipment) => {
              shipment.load_id = null;
              return shipment;
            }),
    );
    const shipmentPatchBodies: ShipmentsPatchBody[] = shipmentsOfNewLoad.map(({ id, load_id, order_in_load }) => ({
      id,
      load_id,
      order_in_load,
    }));
    await api.shipments.patchShipments({ shipmentsPatchBody: shipmentPatchBodies });
    await load(carrierUser, state, dispatch);
    dispatch({
      type: 'CLEAR_SELECTED_SHIPMENTS',
    });
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message:
          (numberOfMovedShipments > 0
            ? loadId === null
              ? `Siirretty ${numberOfMovedShipments} toimitus${numberOfMovedShipments > 1 ? 'ta' : ''} pois kuormista. `
              : `Lisätty ${numberOfMovedShipments} toimitus${
                  numberOfMovedShipments > 1 ? 'ta' : ''
                } kuormaan ${loadId}. `
            : '') +
          (numberOfUnaffectedShipments > 0
            ? `${numberOfUnaffectedShipments} toimitus${numberOfUnaffectedShipments > 1 ? 'ta' : ''} pysyi ennallaan.`
            : ''),
      },
    });
  } catch (err) {
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Kuorman päivitys epäonnistui!',
        severity: 'error',
      },
    });
    console.error(err);
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

export const getDriveDateFromSelectedDateRange = (dateRange: DateRange | null): Date => {
  if (
    dateRange &&
    dateRange.start.startOf('day').toFormat(dateFormat) === dateRange.end.startOf('day').toFormat(dateFormat)
  ) {
    return convertToUTCNoon(dateRange.start);
  } else {
    return todayNoon;
  }
};
