import React from 'react';
import { Paper, PaperProps, styled } from '@mui/material';
import { Loading } from './Loading';
import Notification, { SnackbarPropsWithSeverity } from './Notification';

interface MainProps extends PaperProps {
  isInitialized?: boolean;
  isLoading?: boolean;
  notificationProps?: SnackbarPropsWithSeverity;
}

const MainPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.table.main}`,
  margin: '1rem',
  padding: '1rem',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    padding: '0.5rem 0.5rem',
  },
}));

const Main: React.FC<MainProps> = ({ children, isInitialized, isLoading, notificationProps, ...props }) => {
  return (
    <>
      {isInitialized === true || isInitialized === undefined ? (
        <MainPaper elevation={0} {...props}>
          <main>{children}</main>
        </MainPaper>
      ) : null}
      <Loading isLoading={isLoading ?? false} />
      <Notification {...notificationProps} />
    </>
  );
};

export default Main;
