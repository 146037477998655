/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A product row in a Shipment in a post request
 * @export
 * @interface ShipmentRowPostPutBody
 */
export interface ShipmentRowPostPutBody {
  /**
   *
   * @type {number}
   * @memberof ShipmentRowPostPutBody
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowPostPutBody
   */
  shipment_id?: number | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentRowPostPutBody
   */
  parcel_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentRowPostPutBody
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowPostPutBody
   */
  quantity?: number | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentRowPostPutBody
   */
  unit?: string | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowPostPutBody
   */
  weight_per_piece_kg?: number | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowPostPutBody
   */
  total_weight_kg?: number | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowPostPutBody
   */
  volume_m3?: number | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentRowPostPutBody
   */
  serial_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentRowPostPutBody
   */
  parcel_type?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentRowPostPutBody
   */
  state?: ShipmentRowPostPutBodyStateEnum;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowPostPutBody
   */
  longest_side_length_mm?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ShipmentRowPostPutBodyStateEnum {
  ReadyForPickup = 'ready_for_pickup',
  PickedUp = 'picked_up',
  Delivered = 'delivered',
  Lost = 'lost',
  Failed = 'failed',
}

export function ShipmentRowPostPutBodyFromJSON(json: any): ShipmentRowPostPutBody {
  return ShipmentRowPostPutBodyFromJSONTyped(json, false);
}

export function ShipmentRowPostPutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentRowPostPutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    shipment_id: !exists(json, 'shipment_id') ? undefined : json['shipment_id'],
    parcel_id: !exists(json, 'parcel_id') ? undefined : json['parcel_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    quantity: !exists(json, 'quantity') ? undefined : json['quantity'],
    unit: !exists(json, 'unit') ? undefined : json['unit'],
    weight_per_piece_kg: !exists(json, 'weight_per_piece_kg') ? undefined : json['weight_per_piece_kg'],
    total_weight_kg: !exists(json, 'total_weight_kg') ? undefined : json['total_weight_kg'],
    volume_m3: !exists(json, 'volume_m3') ? undefined : json['volume_m3'],
    serial_number: !exists(json, 'serial_number') ? undefined : json['serial_number'],
    parcel_type: !exists(json, 'parcel_type') ? undefined : json['parcel_type'],
    state: !exists(json, 'state') ? undefined : json['state'],
    longest_side_length_mm: !exists(json, 'longest_side_length_mm') ? undefined : json['longest_side_length_mm'],
  };
}

export function ShipmentRowPostPutBodyToJSON(value?: ShipmentRowPostPutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    shipment_id: value.shipment_id,
    parcel_id: value.parcel_id,
    name: value.name,
    quantity: value.quantity,
    unit: value.unit,
    weight_per_piece_kg: value.weight_per_piece_kg,
    total_weight_kg: value.total_weight_kg,
    volume_m3: value.volume_m3,
    serial_number: value.serial_number,
    parcel_type: value.parcel_type,
    state: value.state,
    longest_side_length_mm: value.longest_side_length_mm,
  };
}
