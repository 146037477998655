import { styled, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { api, ShipmentRow } from '../api';
import { Loading } from './Loading';
import { orderBy } from 'lodash';
import { CoordinationShipment } from '../views/Coordination/coordination.state';

const FreePositionLoadingSpinner = styled(Loading)({
  position: 'unset',
});

const StyledTableBody = styled(TableBody)({
  paddingTop: '0.5rem',
});

const StyledTableRow = styled(TableRow)({
  width: '100%',
});

const StyledTableCell = styled(TableCell)({
  fontSize: '0.6rem',
  padding: 0,
  paddingRight: '0.25rem',
  width: 'inherit',
});

const DimensionStyledTableCell = styled(StyledTableCell)({
  textAlign: 'right',
  whiteSpace: 'nowrap',
  paddingLeft: '0.25rem',
});

interface ShipmentRowInfoProps {
  shipmentRows: ShipmentRow[];
  loading: boolean;
}

export const ShipmentRowInfo: React.FC<ShipmentRowInfoProps> = ({ shipmentRows, loading }) => {
  if (loading) {
    return <FreePositionLoadingSpinner isLoading={loading} loadingMessage="Ladataan toimitusrivejä..." />;
  }
  if (!loading && shipmentRows.length === 0) {
    return <span>Ei toimitusrivejä.</span>;
  }

  return (
    <Table className="shipmentrow-table">
      <StyledTableBody>
        {orderBy(shipmentRows, ['parcel_type', 'total_weight_kg'], ['asc', 'desc']).map((shipmentRow, index) => {
          return (
            <StyledTableRow key={index}>
              <StyledTableCell>{shipmentRow.name}</StyledTableCell>
              <DimensionStyledTableCell>{`${shipmentRow.quantity} kpl`}</DimensionStyledTableCell>
              <DimensionStyledTableCell>{`${shipmentRow.total_weight_kg} kg`}</DimensionStyledTableCell>
              <DimensionStyledTableCell>{`${shipmentRow.volume_m3 ?? 0} m³`}</DimensionStyledTableCell>
              <DimensionStyledTableCell>{`${shipmentRow.longest_side_length_mm ?? 0} mm`}</DimensionStyledTableCell>
            </StyledTableRow>
          );
        })}
      </StyledTableBody>
    </Table>
  );
};

interface ShipmentRowInfoTableAsyncProps {
  shipment: CoordinationShipment;
}

export const ShipmentRowInfoTableAsync: React.FC<ShipmentRowInfoTableAsyncProps> = ({ shipment }) => {
  const [loading, setLoading] = useState(true);
  const [shipmentRows, setShipmentRows] = useState<ShipmentRow[]>([]);

  const load = async () => {
    setLoading(true);
    const shipmentRows = (
      await api.shipments.getShipmentRows({
        shipmentId: shipment.id,
      })
    ).data;
    setShipmentRows(shipmentRows);
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, [shipment.id]);

  return <ShipmentRowInfo shipmentRows={shipmentRows} loading={loading} />;
};
