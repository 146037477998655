/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssignmentPutBody
 */
export interface AssignmentPutBody {
  /**
   *
   * @type {number}
   * @memberof AssignmentPutBody
   */
  readonly id?: number;
  /**
   *
   * @type {string}
   * @memberof AssignmentPutBody
   */
  readonly organization_id?: string;
  /**
   *
   * @type {number}
   * @memberof AssignmentPutBody
   */
  assignment_specific_id: number;
  /**
   *
   * @type {string}
   * @memberof AssignmentPutBody
   */
  description: string | null;
}

export function AssignmentPutBodyFromJSON(json: any): AssignmentPutBody {
  return AssignmentPutBodyFromJSONTyped(json, false);
}

export function AssignmentPutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignmentPutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    organization_id: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    assignment_specific_id: json['assignment_specific_id'],
    description: json['description'],
  };
}

export function AssignmentPutBodyToJSON(value?: AssignmentPutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assignment_specific_id: value.assignment_specific_id,
    description: value.description,
  };
}
