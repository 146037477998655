/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Office, OfficeFromJSON, OfficeFromJSONTyped, OfficeToJSON } from './Office';

/**
 *
 * @export
 * @interface OfficeResponseBody1
 */
export interface OfficeResponseBody1 {
  /**
   *
   * @type {Office}
   * @memberof OfficeResponseBody1
   */
  data?: Office;
}

export function OfficeResponseBody1FromJSON(json: any): OfficeResponseBody1 {
  return OfficeResponseBody1FromJSONTyped(json, false);
}

export function OfficeResponseBody1FromJSONTyped(json: any, ignoreDiscriminator: boolean): OfficeResponseBody1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, 'data') ? undefined : OfficeFromJSON(json['data']),
  };
}

export function OfficeResponseBody1ToJSON(value?: OfficeResponseBody1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: OfficeToJSON(value.data),
  };
}
