import { debounce } from 'lodash';
import { Dispatch } from 'react';
import {
  Driver,
  DriverCollectiveAgreementEnum,
  DriverGroup,
  DriverPostPutBody,
  DriverPostPutBodyCollectiveAgreementEnum,
  DriverPostPutBodyProfessionEnum,
  DriverProfessionEnum,
  User,
} from '../../api';
import { validateFields } from './employeeValidation';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';

export interface Field<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value: Value;
}

type ServerFieldNames = keyof Pick<
  Driver,
  | 'id'
  | 'first_name'
  | 'last_name'
  | 'is_at_service'
  | 'personal_phone_number'
  | 'company_phone_number'
  | 'employee_number'
  | 'profession'
  | 'license'
  | 'legacy_driver_app_id'
  | 'hired_by'
  | 'address'
  | 'postal_code'
  | 'city'
  | 'proactive_driving_expire_date'
  | 'adr_expire_date'
  | 'work_safety_expire_date'
  | 'professional_qualification'
  | 'collective_agreement'
>;

export type ModifiedEmployeeDriverGroup = DriverGroup & { isModified?: boolean };
export type EmployeeDriverGroups = {
  memberOf: Array<ModifiedEmployeeDriverGroup>;
  notMemberOf: Array<ModifiedEmployeeDriverGroup>;
};

export type FieldName = ServerFieldNames;

export interface State {
  fields: {
    [P in ServerFieldNames]: Field<Driver[P] | ''>;
  };
  originalEmployee?: Driver;
  isLoading: boolean;
  loadingMessage?: string;
  notification: NotificationType;
  isValid: boolean;
  driverGroups: EmployeeDriverGroups;
  employees: Driver[];
  users: User[];
}

export type Action =
  | {
      type: 'VALIDATE_FIELD';
      payload: {
        fieldName: keyof State['fields'];
      };
    }
  | {
      type: 'VALIDATE_FIELDS';
    }
  | {
      type: 'SET_FIELD_VALUE';
      payload: {
        fieldName: keyof State['fields'];
        value: FieldValue;
      };
    }
  | SetMessageAction
  | SetLoadingAction
  | {
      type: 'INITIALIZE_EMPLOYEE';
      payload: {
        employee: Driver;
      };
    }
  | {
      type: 'SET_EMPLOYEES';
      payload: {
        employees: Driver[];
      };
    }
  | {
      type: 'SET_USERS';
      payload: {
        users: User[];
      };
    }
  | {
      type: 'SET_DRIVER_GROUPS';
      payload: {
        driverGroups: EmployeeDriverGroups;
      };
    };

const areAllFieldsValid = (state: Pick<State, 'fields'>) => {
  return !Object.values(state.fields).some((field) => field.hasError);
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD_VALUE': {
      const fields = {
        ...state.fields,
      };
      fields[action.payload.fieldName].value = action.payload.value;
      return {
        ...state,
        fields,
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'VALIDATE_FIELDS': {
      const validatedState = validateFields(state);
      return {
        ...state,
        ...validatedState,
        isValid: areAllFieldsValid(validatedState),
      };
    }
    case 'INITIALIZE_EMPLOYEE': {
      const fields = {
        ...state.fields,
      };
      fields.id.value = action.payload.employee.id ?? '';
      fields.first_name.value = action.payload.employee.first_name ?? '';
      fields.last_name.value = action.payload.employee.last_name ?? '';
      fields.is_at_service.value = action.payload.employee.is_at_service ?? false;
      fields.personal_phone_number.value = action.payload.employee.personal_phone_number ?? '';
      fields.company_phone_number.value = action.payload.employee.company_phone_number ?? '';
      fields.employee_number.value = action.payload.employee.employee_number ?? '';
      fields.profession.value = action.payload.employee.profession ?? 'kuski';
      fields.license.value = action.payload.employee.license ?? '';
      fields.legacy_driver_app_id.value = action.payload.employee.legacy_driver_app_id ?? '';
      fields.hired_by.value = action.payload.employee.hired_by ?? '';
      fields.address.value = action.payload.employee.address ?? '';
      fields.postal_code.value = action.payload.employee.postal_code ?? '';
      fields.city.value = action.payload.employee.city ?? '';
      fields.proactive_driving_expire_date.value = action.payload.employee.proactive_driving_expire_date ?? null;
      fields.adr_expire_date.value = action.payload.employee.adr_expire_date ?? null;
      fields.work_safety_expire_date.value = action.payload.employee.work_safety_expire_date ?? null;
      fields.professional_qualification.value = action.payload.employee.professional_qualification ?? null;
      fields.collective_agreement.value = action.payload.employee.collective_agreement ?? '';
      return {
        ...state,
        fields,
        originalEmployee: action.payload.employee,
        isValid: false,
      };
    }
    case 'SET_EMPLOYEES': {
      return {
        ...state,
        employees: action.payload.employees,
      };
    }
    case 'SET_USERS': {
      return {
        ...state,
        users: action.payload.users,
      };
    }
    case 'SET_DRIVER_GROUPS': {
      return {
        ...state,
        driverGroups: action.payload.driverGroups,
      };
    }
    default: {
      throw new Error(`Unhandled action ${JSON.stringify(action)}`);
    }
  }
};

type FieldValue = string | number | boolean | Date | null;

export const debouncedValidateFieldsDispatch = debounce((dispatch: Dispatch<Action>) => {
  dispatch({
    type: 'VALIDATE_FIELDS',
  });
}, 500);

export const updateFieldValue = (fieldName: FieldName, value: FieldValue, dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_FIELD_VALUE',
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
  debouncedValidateFieldsDispatch(dispatch);
};

export const getEmployeePostPutBodyFromState = (state: State): DriverPostPutBody => {
  return {
    first_name: state.fields.first_name.value || null,
    last_name: state.fields.last_name.value || null,
    is_at_service: state.fields.is_at_service.value || false,
    personal_phone_number: state.fields.personal_phone_number.value || null,
    company_phone_number: state.fields.company_phone_number.value || null,
    employee_number: state.fields.employee_number.value || '',
    profession:
      (state.fields.profession.value as DriverPostPutBodyProfessionEnum) || DriverPostPutBodyProfessionEnum.Kuski,
    license: state.fields.license.value || null,
    legacy_driver_app_id: state.fields.legacy_driver_app_id.value ?? '',
    hired_by: state.fields.hired_by.value || null,
    address: state.fields.address.value || null,
    postal_code: state.fields.postal_code.value || null,
    city: state.fields.city.value || null,
    proactive_driving_expire_date: state.fields.proactive_driving_expire_date.value || null,
    adr_expire_date: state.fields.adr_expire_date.value || null,
    work_safety_expire_date: state.fields.work_safety_expire_date.value || null,
    professional_qualification: state.fields.professional_qualification.value || null,
    collective_agreement:
      (state.fields.collective_agreement.value as DriverPostPutBodyCollectiveAgreementEnum) ||
      DriverPostPutBodyCollectiveAgreementEnum.Empty,
  };
};

export const getInitialState = (): State => {
  return {
    fields: {
      id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      first_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      last_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      is_at_service: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: true,
      },
      personal_phone_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      company_phone_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      employee_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      profession: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: DriverProfessionEnum.Kuski,
      },
      license: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      legacy_driver_app_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      hired_by: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      address: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      postal_code: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      city: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      proactive_driving_expire_date: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      adr_expire_date: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      work_safety_expire_date: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      professional_qualification: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      collective_agreement: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: DriverCollectiveAgreementEnum.Empty,
      },
    },
    isLoading: false,
    notification: {
      message: null,
    },
    isValid: false,
    driverGroups: { memberOf: [], notMemberOf: [] },
    employees: [],
    users: [],
  };
};
