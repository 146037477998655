/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SummaryResponseBodyShipments,
  SummaryResponseBodyShipmentsFromJSON,
  SummaryResponseBodyShipmentsFromJSONTyped,
  SummaryResponseBodyShipmentsToJSON,
} from './SummaryResponseBodyShipments';

/**
 * Summary of daily resources.
 * @export
 * @interface SummaryResponseBody
 */
export interface SummaryResponseBody {
  /**
   *
   * @type {Array<SummaryResponseBodyShipments>}
   * @memberof SummaryResponseBody
   */
  shipments?: Array<SummaryResponseBodyShipments>;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBody
   */
  loadCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBody
   */
  organizationCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBody
   */
  driverCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBody
   */
  carCount?: number;
}

export function SummaryResponseBodyFromJSON(json: any): SummaryResponseBody {
  return SummaryResponseBodyFromJSONTyped(json, false);
}

export function SummaryResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    shipments: !exists(json, 'shipments')
      ? undefined
      : (json['shipments'] as Array<any>).map(SummaryResponseBodyShipmentsFromJSON),
    loadCount: !exists(json, 'loadCount') ? undefined : json['loadCount'],
    organizationCount: !exists(json, 'organizationCount') ? undefined : json['organizationCount'],
    driverCount: !exists(json, 'driverCount') ? undefined : json['driverCount'],
    carCount: !exists(json, 'carCount') ? undefined : json['carCount'],
  };
}

export function SummaryResponseBodyToJSON(value?: SummaryResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    shipments:
      value.shipments === undefined
        ? undefined
        : (value.shipments as Array<any>).map(SummaryResponseBodyShipmentsToJSON),
    loadCount: value.loadCount,
    organizationCount: value.organizationCount,
    driverCount: value.driverCount,
    carCount: value.carCount,
  };
}
