/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PriceBasisAdditionalServices,
  PriceBasisAdditionalServicesFromJSON,
  PriceBasisAdditionalServicesFromJSONTyped,
  PriceBasisAdditionalServicesToJSON,
} from './PriceBasisAdditionalServices';

/**
 * Detailed information on how the price is formed. Can also include information about a failed pricing
 * @export
 * @interface PriceBasis
 */
export interface PriceBasis {
  /**
   *
   * @type {number}
   * @memberof PriceBasis
   */
  express_delivery?: number;
  /**
   *
   * @type {number}
   * @memberof PriceBasis
   */
  price_ratio_for_combined_shipment?: number;
  /**
   *
   * @type {number}
   * @memberof PriceBasis
   */
  basic_price?: number;
  /**
   *
   * @type {string}
   * @memberof PriceBasis
   */
  pricing_unit?: string;
  /**
   *
   * @type {string}
   * @memberof PriceBasis
   */
  pricing_unit_value?: string;
  /**
   *
   * @type {string}
   * @memberof PriceBasis
   */
  distance_pricing_basis?: string;
  /**
   *
   * @type {string}
   * @memberof PriceBasis
   */
  distance_value?: string;
  /**
   *
   * @type {number}
   * @memberof PriceBasis
   */
  working_hours?: number;
  /**
   *
   * @type {number}
   * @memberof PriceBasis
   */
  price_per_hour?: number;
  /**
   *
   * @type {number}
   * @memberof PriceBasis
   */
  combination_vehicle_price_per_hour?: number;
  /**
   *
   * @type {string}
   * @memberof PriceBasis
   */
  car_type?: string;
  /**
   *
   * @type {Array<PriceBasisAdditionalServices>}
   * @memberof PriceBasis
   */
  additional_services?: Array<PriceBasisAdditionalServices>;
  /**
   *
   * @type {string}
   * @memberof PriceBasis
   */
  error_message?: string;
}

export function PriceBasisFromJSON(json: any): PriceBasis {
  return PriceBasisFromJSONTyped(json, false);
}

export function PriceBasisFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceBasis {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    express_delivery: !exists(json, 'express_delivery') ? undefined : json['express_delivery'],
    price_ratio_for_combined_shipment: !exists(json, 'price_ratio_for_combined_shipment')
      ? undefined
      : json['price_ratio_for_combined_shipment'],
    basic_price: !exists(json, 'basic_price') ? undefined : json['basic_price'],
    pricing_unit: !exists(json, 'pricing_unit') ? undefined : json['pricing_unit'],
    pricing_unit_value: !exists(json, 'pricing_unit_value') ? undefined : json['pricing_unit_value'],
    distance_pricing_basis: !exists(json, 'distance_pricing_basis') ? undefined : json['distance_pricing_basis'],
    distance_value: !exists(json, 'distance_value') ? undefined : json['distance_value'],
    working_hours: !exists(json, 'working_hours') ? undefined : json['working_hours'],
    price_per_hour: !exists(json, 'price_per_hour') ? undefined : json['price_per_hour'],
    combination_vehicle_price_per_hour: !exists(json, 'combination_vehicle_price_per_hour')
      ? undefined
      : json['combination_vehicle_price_per_hour'],
    car_type: !exists(json, 'car_type') ? undefined : json['car_type'],
    additional_services: !exists(json, 'additional_services')
      ? undefined
      : (json['additional_services'] as Array<any>).map(PriceBasisAdditionalServicesFromJSON),
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
  };
}

export function PriceBasisToJSON(value?: PriceBasis | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    express_delivery: value.express_delivery,
    price_ratio_for_combined_shipment: value.price_ratio_for_combined_shipment,
    basic_price: value.basic_price,
    pricing_unit: value.pricing_unit,
    pricing_unit_value: value.pricing_unit_value,
    distance_pricing_basis: value.distance_pricing_basis,
    distance_value: value.distance_value,
    working_hours: value.working_hours,
    price_per_hour: value.price_per_hour,
    combination_vehicle_price_per_hour: value.combination_vehicle_price_per_hour,
    car_type: value.car_type,
    additional_services:
      value.additional_services === undefined
        ? undefined
        : (value.additional_services as Array<any>).map(PriceBasisAdditionalServicesToJSON),
    error_message: value.error_message,
  };
}
