import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Driver } from '../api';
import theme from '../theme';

export interface DriverPickerProps {
  className?: string;
  drivers: Driver[];
  value: Driver | null;
  onChange: (car: Driver | null) => void;
  disabled?: boolean;
  required?: boolean;
  size?: 'small' | 'medium';
  style?: any;
  variant?: 'standard' | 'filled' | 'outlined';
  error?: boolean;
  helperText?: React.ReactNode;
}

export const DriverPicker: React.FC<DriverPickerProps> = ({
  className,
  disabled,
  drivers,
  value,
  onChange,
  required,
  size,
  style,
  variant,
  error,
  helperText,
}) => {
  const isDriverInactive = !Boolean(drivers.find((driver) => driver.id === value?.id) || !value) && drivers.length > 0;
  return (
    <Autocomplete
      disabled={disabled}
      style={style}
      fullWidth
      className={'driver-picker ' + className}
      options={drivers.sort((driver1, driver2) =>
        `${driver1.last_name} ${driver1.first_name}`.toLowerCase() >
        `${driver2.last_name} ${driver2.first_name}`.toLowerCase()
          ? 1
          : -1,
      )}
      autoHighlight
      getOptionLabel={(driver) => `${driver.last_name} ${driver.first_name}`}
      renderOption={(props, driver) => <li {...props}>{`${driver.last_name} ${driver.first_name}`}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          variant={variant}
          helperText={helperText}
          error={error}
          label={isDriverInactive ? 'Valittu kuljettaja ei ole aktiivinen' : 'Kuljettaja'}
          InputLabelProps={{
            style: { color: isDriverInactive ? theme.palette.error.main : undefined },
          }}
          required={required}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      value={value}
      onChange={(_event, driver) => onChange(driver)}
    />
  );
};
