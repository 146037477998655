import React, { useEffect, useState } from 'react';
import { PeriPihaLoaderResponse } from './PeriPiha.loader';
import { useLoaderDataAs } from '../hooks/routerHooksTyped';
import { DateTime } from 'luxon';
import {
  Box,
  Card,
  Divider,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  List,
  ListItem,
  ListItemProps,
  MenuItem,
  styled,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { groupBy, sortBy } from 'lodash';
import theme from '../theme';
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, useDraggable, useDroppable } from '@dnd-kit/core';
import { api, Office, OrganizationScheduleShipment, ShipmentStateEnum } from '../api';
import { ArrowForward, AssignmentReturnSharp, Info } from '@mui/icons-material';
import { EmptySelectMenuItem } from '../components/EmptySelectMenuItem';
import { dateFormatShort, formatWeekDay } from '../formatters';
import {
  TooltipContainer,
  TooltipContainerAddress,
  TooltipContainerInner,
  TooltipDivider,
} from './Coordination/DriverCoordination';

type TimeBlock = {
  start: number;
  end: number;
  title: string;
  contains: (hour: number | null) => boolean;
};

type ScheduleShipment = OrganizationScheduleShipment & {
  isReturnShipment: boolean;
};

type ScheduleShipmentDay = { date: string; shipments: Array<ScheduleShipment> };

const createTimeBlock = (
  start: number,
  end: number,
  title?: string,
  containsFunc?: (hour: number | null) => boolean,
): TimeBlock => {
  const actualTitle = title ?? `${start}-${end + 1}`;
  return {
    start,
    end,
    title: actualTitle,
    contains: containsFunc ?? ((hour: number | null) => hour !== null && hour >= start && hour < end),
  };
};

const StyledDay = styled(Card)({
  display: 'inline-block',
  width: '300px',
  marginRight: '10px',
  height: '90%',
  verticalAlign: 'top',
  whiteSpace: 'normal',
});

const StyledDayHeader = styled('h2')({
  margin: '0.5rem',
  textTransform: 'capitalize',
});

const StyledHourHeader = styled('h4')({
  marginBottom: 0,
  marginTop: 0,
});

const StyledDayList = styled('div')({
  margin: '0.5rem',
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'auto',
});

// This looks suspicious, but it follows the original code
const getWeight = (shipment: ScheduleShipment) => {
  return shipment.isReturnShipment ? shipment.length_ldm * 1000 : shipment.weight_kg;
};

const calculateWeights = (shipments: Array<ScheduleShipment>) => {
  let returnWeight = 0;
  let pickupWeight = 0;
  for (const shipment of shipments) {
    const weight = getWeight(shipment);
    if (shipment.isReturnShipment) {
      returnWeight += weight;
    } else {
      pickupWeight += weight;
    }
  }
  return { returnWeight, pickupWeight };
};

const ShipmentListItem = styled(ListItem)(
  (
    props: ListItemProps & {
      state: OrganizationScheduleShipment['state'];
    },
  ) => ({
    fontSize: '0.8rem',
    padding: 0,
    cursor: 'grab',
    ...(props.state === ShipmentStateEnum.Noudettu && {
      backgroundColor: theme.palette.pickedUp,
    }),
    ...(props.state === ShipmentStateEnum.Toimitettu && {
      backgroundColor: theme.palette.delivered,
    }),
    ...(props.state === ShipmentStateEnum.Noudettavissa && {
      backgroundColor: theme.palette.readyForPickup,
    }),
  }),
);

const SingleShipment: React.FC<{ dragged?: boolean; shipment: ScheduleShipment | undefined }> = ({
  shipment,
  dragged,
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: (dragged ? 'dragged-shipment' : 'shipment-') + shipment?.id,
  });
  if (shipment === undefined) return null;
  const isOtherShipmentText = shipment.pickup_office_organization_specific_id === 'MUU' ? '(M) ' : '';
  const address = shipment.isReturnShipment
    ? `${shipment.pickup_city}, ${shipment.pickup_name}, ${shipment.reference_number}`
    : `${shipment.delivery_city ?? shipment.delivery_postal_code}, ${shipment.delivery_name}, ${
        shipment.reference_number
      }`;

  const weightText =
    shipment.weight_kg > 0
      ? shipment.weight_kg < 1000
        ? Math.round(shipment.weight_kg) + 'kg '
        : Math.round(shipment.weight_kg / 1000) + 't '
      : '';
  const lengthText = shipment.length_ldm > 0 ? shipment.length_ldm.toFixed(2) + 'm' : '';
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <ShipmentListItem
      state={shipment.state}
      ref={setNodeRef}
      data-shipment-id={shipment.id}
      sx={{ ...style, color: shipment.isReturnShipment ? theme.palette.secondary.dark : undefined }}
      {...listeners}
      {...attributes}
    >
      <div style={{ flexGrow: 1 }}>
        {isOtherShipmentText} {address}
      </div>
      <div>
        {lengthText} {weightText}
      </div>
      <Tooltip
        title={
          <TooltipContainer>
            <TooltipContainerInner>
              <span>{shipment.id}</span>
              <span>{shipment.state}</span>
              {shipment.reference_number && <span>{shipment.reference_number}</span>}
            </TooltipContainerInner>
            <TooltipDivider />
            <TooltipContainerInner>
              <span>{shipment.weight_kg} kg</span>
              <span>{shipment.volume_m3} m³</span>
              <span>{shipment.length_ldm} lvm</span>
            </TooltipContainerInner>
            <TooltipDivider />
            <TooltipContainerInner sx={{ minWidth: 0 }}>
              <TooltipContainerAddress>
                <span>{shipment.pickup_name}</span>
                <span>{shipment.pickup_address}</span>
                <span>
                  {shipment.pickup_postal_code} {shipment.pickup_city}
                </span>
                <span>{shipment.pickup_phone_number}</span>
              </TooltipContainerAddress>
              <TooltipContainerAddress sx={{ flexGrow: 1, justifyContent: 'center', margin: '0.75rem' }}>
                <ArrowForward />
              </TooltipContainerAddress>
              <TooltipContainerAddress>
                <span>{shipment.delivery_name}</span>
                <span>{shipment.delivery_address}</span>
                <span>
                  {shipment.delivery_postal_code} {shipment.delivery_city}
                </span>
                <span>{shipment.delivery_phone_number}</span>
              </TooltipContainerAddress>
            </TooltipContainerInner>
          </TooltipContainer>
        }
      >
        {shipment.isReturnShipment ? <AssignmentReturnSharp /> : <Info />}
      </Tooltip>
    </ShipmentListItem>
  );
};

const HourOfShipments: React.FC<{
  date: string;
  hour: string;
  title: string;
  shipments: Array<ScheduleShipment> | undefined;
}> = ({ date, hour, title, shipments }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: `${date}--${hour}`,
  });
  const { returnWeight, pickupWeight } = shipments ? calculateWeights(shipments) : { returnWeight: 0, pickupWeight: 0 };
  const hightlight = isOver ? { backgroundColor: theme.palette.highlight.light } : {};
  return (
    <div ref={setNodeRef} data-date={date} data-hour={hour} style={hightlight}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledHourHeader>{title}</StyledHourHeader>
        {pickupWeight > 0 && <StyledHourHeader>N {pickupWeight} kg </StyledHourHeader>}
        {returnWeight > 0 && <StyledHourHeader>P {returnWeight} kg</StyledHourHeader>}
      </Box>
      <List sx={{ padding: 0 }}>
        {(shipments ?? []).map((s) => (
          <SingleShipment key={s.id} shipment={s} />
        ))}
      </List>
      <Divider sx={{ backgroundColor: theme.palette.common.black }} />
    </div>
  );
};
const DayOfShipments: React.FC<{ date: string; shipments: Array<ScheduleShipment> }> = ({ date, shipments }) => {
  const { returnWeight, pickupWeight } = calculateWeights(shipments);

  const timeBlocks = [
    createTimeBlock(7, 8),
    createTimeBlock(9, 10),
    createTimeBlock(11, 12),
    createTimeBlock(13, 14),
    createTimeBlock(15, 16),
    createTimeBlock(17, 21),
    createTimeBlock(99, 99, 'Ajattomat', (hour) => !hour || hour >= 22 || hour < 7),
  ];

  const maxReturn = 60;
  const maxPickup = 80;

  const pickupProgress = Math.min(Math.round(pickupWeight / 1000) / (maxPickup / 100), 100);
  const returnProgress = Math.min(Math.round(returnWeight / 1000) / (maxReturn / 100), 100);

  const groupedByBlock = groupBy(shipments, (s) => {
    const block = timeBlocks.find((tb) => tb.contains(s.planned_unload_hour)) ?? timeBlocks[timeBlocks.length - 1];
    return block.title;
  });

  // DateTime uses ISO standard, so Monday is 1 and Sunday is 7
  if (shipments.length === 0 && DateTime.fromISO(date).weekday > 5) {
    return null;
  }

  const getColorByProgress = (progress: number) => {
    if (progress >= 80) {
      return 'error';
    } else if (progress >= 50) {
      return 'warning';
    } else {
      return 'success';
    }
  };

  return (
    <StyledDay>
      <StyledDayHeader>
        {formatWeekDay(DateTime.fromISO(date))} {DateTime.fromISO(date).toFormat(dateFormatShort)}
      </StyledDayHeader>
      <div style={{ marginBottom: '3rem' }}>
        Noudot {pickupWeight} kg
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={pickupProgress} color={getColorByProgress(pickupProgress)} />
        </Box>
        Palautukset {returnWeight} kg
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant="determinate"
            value={returnProgress}
            color={getColorByProgress(returnProgress)}
          ></LinearProgress>
        </Box>
      </div>
      <Box
        sx={{
          '& div:last-child': {
            marginTop: '3rem',
          },
        }}
      >
        {timeBlocks.map((block: TimeBlock) => {
          return (
            <HourOfShipments
              date={date}
              hour={block.start.toString()}
              title={block.title}
              shipments={groupedByBlock[block.title]}
              key={`${date}-${block.start}`}
            />
          );
        })}
      </Box>
    </StyledDay>
  );
};

const Settings: React.FC<{
  office: Office | undefined;
  setOffice: (office: Office | undefined) => void;
  offices: Array<Office>;
  showAll: boolean;
  setShowAll: (showAll: boolean) => void;
}> = ({ office, setOffice, offices, showAll, setShowAll }) => {
  return (
    <FormGroup sx={{ flexDirection: 'row', gap: '10px', margin: '0.5rem' }}>
      <FormControlLabel
        control={
          <Switch
            checked={showAll}
            onChange={() => {
              setShowAll(!showAll);
            }}
          />
        }
        label="Näytä kaikki"
      />
      <TextField
        sx={{ minWidth: '12rem' }}
        size="small"
        select
        label="Toimipiste"
        variant="outlined"
        value={office?.id ?? ''}
        onChange={(event) => {
          if (typeof event.target.value === 'string') {
            setOffice(undefined);
            return;
          }

          if (typeof event.target.value === 'object') {
            setOffice(event.target.value);
            return;
          }
          const idValue = event.target.value; //parseInt(event.target.value);
          const selectedOffice = offices.find((o) => o.id === idValue);
          setOffice(selectedOffice);
        }}
      >
        <EmptySelectMenuItem value="" />
        {offices.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            {o.name}
          </MenuItem>
        ))}
      </TextField>
    </FormGroup>
  );
};

export const isReturnShipment = (shipment: OrganizationScheduleShipment, office: Office | undefined) => {
  return shipment.delivery_address === office?.address;
};

const PeriPiha: React.FC = () => {
  const [shipmentDays, setShipmentDays] = useState<Array<ScheduleShipmentDay>>([]);
  const [draggedShipment, setDraggedShipment] = useState<undefined | ScheduleShipment>(undefined);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [office, setOffice] = useState<Office | undefined>(undefined);
  const [allShipments, setAllShipment] = useState<Array<ScheduleShipmentDay>>([]);

  const loaderData = useLoaderDataAs<PeriPihaLoaderResponse>();

  useEffect(() => {
    const shipmentsData = (loaderData?.data ?? []).map((day) => {
      const shipments = day.shipments.map((s) => {
        return {
          ...s,
          isReturnShipment: isReturnShipment(s, office),
        };
      });
      return { date: day.date, shipments };
    });

    setShipmentDays(shipmentsData);
    setAllShipment(shipmentsData);

    if (loaderData.offices.length > 0) {
      setOffice(loaderData.offices[0]);
    }
  }, [loaderData]);

  const isDeliveredOrPickedUReturnShipment = (shipment: OrganizationScheduleShipment, office: Office | undefined) =>
    (shipment.state === ShipmentStateEnum.Noudettu && !isReturnShipment(shipment, office)) ||
    shipment.state === ShipmentStateEnum.Toimitettu;

  const isOfficeShipment = (office: Office) => {
    return (shipment: ScheduleShipment) =>
      (office.address === shipment.pickup_address && office.postal_code === shipment.pickup_postal_code) ||
      (office.address === shipment.delivery_address && office.postal_code === shipment.delivery_postal_code);
  };

  useEffect(() => {
    const updateShipmentDay = (day: ScheduleShipmentDay): ScheduleShipmentDay => {
      return {
        date: day.date,
        shipments: day.shipments
          .filter(() => (office ? isOfficeShipment(office) : true))
          .filter((s) => (showAll ? true : !isDeliveredOrPickedUReturnShipment(s, office)))
          .map((s) => {
            return { ...s, isReturnShipment: isReturnShipment(s, office) };
          }),
      };
    };
    setShipmentDays(allShipments.map(updateShipmentDay));
  }, [office, showAll]);

  const handleDragStart = (event: DragStartEvent) => {
    const shipment = shipmentDays.flatMap((x) => x.shipments).find((s) => 'shipment-' + s.id === event.active.id);
    setDraggedShipment(shipment);
  };

  const handleDragEnd = (param: DragEndEvent) => {
    if (draggedShipment === undefined || param.over === null) {
      return;
    }
    const parentOfShipment = shipmentDays.find(
      (x) => undefined !== x.shipments.find((s) => s.id === draggedShipment.id),
    );
    const newParent = shipmentDays.find((x) => x.date === String(param.over!.id).split('--')[0]);

    if (!parentOfShipment || !newParent) {
      return;
    }
    const oldDate = parentOfShipment.date;
    const newDate = newParent.date;
    const newHour = parseInt(String(param.over.id).split('--')[1]);
    const oldHour = parseInt(String(param.active.id).split('--')[1]);
    if (oldDate === newDate && newHour === oldHour) {
      return;
    }
    const updatedShipment = {
      ...draggedShipment,
      planned_unload_hour: newHour,
    };

    setShipmentDays((prev) => {
      const withoutDragged = [
        ...prev.filter((x) => x.date !== parentOfShipment.date),
        {
          date: parentOfShipment.date,
          shipments: parentOfShipment.shipments.filter((s) => s.id !== draggedShipment.id),
        },
      ];

      const newParentShipments = withoutDragged.find((d) => d.date === newParent.date);
      if (!newParentShipments) {
        // Should never happen, but to make compiler happy
        return prev;
      }

      return [
        ...withoutDragged.filter((x) => x.date !== newParent.date),
        {
          date: newParent.date,
          shipments: [...newParentShipments.shipments, updatedShipment as ScheduleShipment],
        },
      ];
    });

    setDraggedShipment(undefined);

    const hourToSend = oldHour === newHour ? undefined : newHour;
    // Make sure that the date is not changed if time zones do funny things
    const dayToSend =
      newDate === oldDate ? undefined : DateTime.fromFormat(newDate, 'yyyy-MM-dd').plus({ hours: 12 }).toJSDate();

    api.organizationScheduleShipments.patchOrganizationScheduleShipments({
      organizationId: draggedShipment.organization_id,
      shipmentId: draggedShipment.id,
      patchOrganizationScheduleShipmentPatchBody: {
        planned_unload_hour: hourToSend ?? null,
        delivery_window_date: isReturnShipment(draggedShipment, office) ? dayToSend : undefined,
        pickup_window_date: isReturnShipment(draggedShipment, office) ? undefined : dayToSend,
      },
    });
  };

  return (
    <>
      <Settings
        office={office}
        offices={loaderData.offices}
        setOffice={setOffice}
        showAll={showAll}
        setShowAll={setShowAll}
      ></Settings>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <StyledDayList>
          {sortBy(shipmentDays, 'date').map((dayOfShipment) => {
            return (
              <DayOfShipments date={dayOfShipment.date} shipments={dayOfShipment.shipments} key={dayOfShipment.date} />
            );
          })}
        </StyledDayList>
        <DragOverlay>
          {draggedShipment ? <SingleShipment dragged={true} shipment={draggedShipment} /> : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default PeriPiha;
