import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  FormControl,
} from '@mui/material';
import React, { Dispatch } from 'react';
import { State, Action, updateFieldValue, FieldName } from '../car.state';
import { CarTypeEnum } from '../../../api';
import { Loading } from '../../../components/Loading';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { typeMenuItems } from './MenuItems';
import { FieldSetContainer } from '../../../components/StyledComponents/FieldSetContainer';
import { getJSDateOrNull, StandardDatePicker } from '../../../components/DateAndTimePickers/StandardDatePicker';
import { EmptySelectMenuItem } from '../../../components/EmptySelectMenuItem';

interface InputProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  disabled?: boolean;
  showId?: boolean;
}

interface DispatchableInputProps extends InputProps {
  dispatch: Dispatch<Action>;
}

export const AllCarFields: React.FC<DispatchableInputProps> = ({ dispatch, state, showId }) => {
  return (
    <FieldSetContainer>
      <FieldSet id="car-fields">
        <legend>Perustiedot</legend>
        {showId ? <Id state={state} dispatch={dispatch} disabled={true} /> : null}
        <IsActiveCheckbox state={state} dispatch={dispatch} />
        <IsCoordinationCheckbox state={state} dispatch={dispatch} />
        <LicencePlate state={state} dispatch={dispatch} />
        <Manufacturer state={state} dispatch={dispatch} />
        <Model state={state} dispatch={dispatch} />
        <Imei state={state} dispatch={dispatch} />
        <VinNumber state={state} dispatch={dispatch} />
        <FirstRegisterationDate state={state} dispatch={dispatch} />
      </FieldSet>
      <FieldSet id="car-fields">
        <legend>Lisätiedot</legend>
        <Type state={state} dispatch={dispatch}>
          {typeMenuItems}
        </Type>
        <IsAdrCheckbox state={state} dispatch={dispatch} />
        <DefaultDriver state={state} dispatch={dispatch} />
        <DefaultTrailer state={state} dispatch={dispatch} />
        <Identifier state={state} dispatch={dispatch} />
        <Number state={state} dispatch={dispatch} />
        <TransportLicence state={state} dispatch={dispatch} />
        <TransportLicenceDate state={state} dispatch={dispatch} />
        <Tyres state={state} dispatch={dispatch} />
        <Hoist state={state} dispatch={dispatch} />
        <FuelCard state={state} dispatch={dispatch} />
        <FuelId state={state} dispatch={dispatch} />
      </FieldSet>
      <FieldSet id="car-fields">
        <legend>Katsastustiedot</legend>
        <VehicleInspectionDate state={state} dispatch={dispatch} />
        <HoistInspectionDate state={state} dispatch={dispatch} />
      </FieldSet>
      <FieldSet id="car-fields">
        <legend>Mitat</legend>
        <UnladenWeight state={state} dispatch={dispatch} />
        <GrossWeight state={state} dispatch={dispatch} />
        <Height state={state} dispatch={dispatch} />
        <PlatformDetails state={state} dispatch={dispatch} />
      </FieldSet>
      <FieldSet id="notes-fields-container">
        <legend>Vapaa kenttä</legend>
        <Notes state={state} dispatch={dispatch} />
      </FieldSet>
    </FieldSetContainer>
  );
};

const Id: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <FormControl error={state.fields.id.hasError}>
      <InputLabel htmlFor="id">Id</InputLabel>
      <Input
        disabled={disabled}
        id="id"
        name="id"
        type="number"
        value={state.fields.id.value ?? ''}
        onChange={(event) =>
          updateFieldValue('id', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
      />
      {state.fields.id.feedback ? (
        <FormHelperText className="id-helper-text">{state.fields.id.feedback}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const IsActiveCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_active_checkbox"
          name="is_active"
          checked={state.fields.is_active.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_active.value, dispatch)
          }
        />
      }
      label="Aktiivinen"
    />
  );
};

const IsCoordinationCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_coordination_checkbox"
          name="is_coordination"
          checked={state.fields.is_coordination.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_coordination.value, dispatch)
          }
        />
      }
      label="Ajojärjestelyssä käytössä"
    />
  );
};

const LicencePlate: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.licence_plate.required}
      name="licence_plate"
      error={state.fields.licence_plate.hasError}
      helperText={state.fields.licence_plate.feedback}
      className="licence_plate"
      label="Rekisterinumero"
      value={state.fields.licence_plate.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const DefaultDriver: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  const driverMenuItems = state.drivers
    .map((driver) => ({
      name: `${driver.last_name} ${driver.first_name}`,
      id: driver.id,
    }))
    .sort((driver1, driver2) => (driver1.name.toLowerCase() > driver2.name.toLowerCase() ? 1 : -1))
    .map((driver) => <MenuItem key={driver.id} value={driver.id}>{`${driver.name}`}</MenuItem>);
  driverMenuItems.unshift(<EmptySelectMenuItem value="" key={'empty-menu-item'} />);
  if (driverMenuItems.length <= 1) {
    return <Loading isLoading={state.isLoading}></Loading>;
  } else {
    return (
      <FormControl>
        <InputLabel id="driver_select_label">Vakiokuski</InputLabel>
        <Select
          className="default_driver_id"
          id="default_driver_id"
          name="default_driver_id"
          labelId="default_driver_id"
          disabled={disabled}
          value={state.fields.default_driver_id.value ?? ''}
          defaultValue={null}
          onChange={(event) => {
            updateFieldValue('default_driver_id', event.target.value as number, dispatch);
          }}
        >
          {driverMenuItems}
        </Select>
      </FormControl>
    );
  }
};

const DefaultTrailer: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  const trailerMenuItems = state.cars
    .filter((car) => car.type === CarTypeEnum.Pervaunu)
    .map((car) => <MenuItem key={car.id} value={car.id}>{`${car.licence_plate}`}</MenuItem>);
  trailerMenuItems.unshift(<EmptySelectMenuItem value="" key={'empty-menu-item'} />);
  return (
    <FormControl>
      <InputLabel id="driver_select_label">Vakioperävaunu</InputLabel>
      <Select
        className="default_trailer_id"
        id="default_trailer_id"
        name="default_trailer_id"
        labelId="default_trailer_id"
        disabled={disabled}
        value={state.fields.default_trailer_id.value ?? ''}
        defaultValue={null}
        onChange={(event) => {
          updateFieldValue('default_trailer_id', event.target.value as number, dispatch);
        }}
      >
        {trailerMenuItems}
      </Select>
    </FormControl>
  );
};

const Identifier: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.car_identifier.required}
      name="car_identifier"
      error={state.fields.car_identifier.hasError}
      helperText={state.fields.car_identifier.feedback}
      className="car_identifier"
      label="Tunnus"
      value={state.fields.car_identifier.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const Number: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <FormControl error={state.fields.car_number.hasError}>
      <InputLabel htmlFor="car_number">Numero</InputLabel>
      <Input
        disabled={disabled}
        id="car_number"
        name="car_number"
        type="number"
        value={state.fields.car_number.value ?? ''}
        onChange={(event) =>
          updateFieldValue('car_number', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
      />
      {state.fields.car_number.feedback ? (
        <FormHelperText className="car_number-helper-text">{state.fields.car_number.feedback}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const Manufacturer: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.manufacturer.required}
      name="manufacturer"
      error={state.fields.manufacturer.hasError}
      helperText={state.fields.manufacturer.feedback}
      className="manufacturer"
      label="Merkki"
      value={state.fields.manufacturer.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const Model: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.model.required}
      name="model"
      error={state.fields.model.hasError}
      helperText={state.fields.model.feedback}
      className="model"
      label="Malli"
      value={state.fields.model.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

interface StateSelectProps extends DispatchableInputProps {
  children: React.ReactElement[];
}

const Type: React.FC<StateSelectProps> = ({ state, dispatch, disabled, children }) => {
  return (
    <FormControl>
      <InputLabel shrink id="type">
        Tyyppi
      </InputLabel>
      <Select
        className="car_type"
        id="type"
        name="type"
        labelId="type"
        disabled={disabled}
        value={state.fields.type.value}
        onChange={(event) => updateFieldValue('type', event.target.value as CarTypeEnum, dispatch)}
      >
        {children}
      </Select>
    </FormControl>
  );
};

const Imei: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.imei.required}
      name="imei"
      error={state.fields.imei.hasError}
      helperText={state.fields.imei.feedback}
      className="imei"
      label="Imei"
      value={state.fields.imei.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const VinNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.vin_number.required}
      name="vin_number"
      error={state.fields.vin_number.hasError}
      helperText={state.fields.vin_number.feedback}
      className="vin_number"
      label="Valmistenumero"
      value={state.fields.vin_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const FirstRegisterationDate: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StandardDatePicker
      disabled={disabled}
      label="Ensirekisteröintipäivä"
      value={state.fields.first_registeration_date.value}
      className="first-registeration-date"
      slotProps={{
        textField: {
          name: 'first_registeration_date',
          required: state.fields.first_registeration_date.required,
          error: state.fields.first_registeration_date.hasError,
          helperText: state.fields.first_registeration_date.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('first_registeration_date', getJSDateOrNull(date), dispatch)}
    />
  );
};

const VehicleInspectionDate: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StandardDatePicker
      disabled={disabled}
      label="Katsastuspvm"
      value={state.fields.vehicle_inspection_date.value}
      className="vehicle-inspection-date"
      slotProps={{
        textField: {
          name: 'vehicle_inspection_date',
          required: state.fields.vehicle_inspection_date.required,
          error: state.fields.vehicle_inspection_date.hasError,
          helperText: state.fields.vehicle_inspection_date.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('vehicle_inspection_date', getJSDateOrNull(date), dispatch)}
    />
  );
};

const HoistInspectionDate: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StandardDatePicker
      disabled={disabled}
      label="Nosturikatsastuspvm"
      value={state.fields.hoist_inspection_date.value}
      className="hoist-inspection-date"
      slotProps={{
        textField: {
          name: 'hoist_inspection_date',
          required: state.fields.hoist_inspection_date.required,
          error: state.fields.hoist_inspection_date.hasError,
          helperText: state.fields.hoist_inspection_date.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('hoist_inspection_date', getJSDateOrNull(date), dispatch)}
    />
  );
};

const TransportLicence: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.transport_licence.required}
      name="transport_licence"
      error={state.fields.transport_licence.hasError}
      helperText={state.fields.transport_licence.feedback}
      className="transport_licence"
      label="Liikennelupa"
      value={state.fields.transport_licence.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const TransportLicenceDate: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StandardDatePicker
      disabled={disabled}
      label="Liikennelupapvm"
      value={state.fields.transport_licence_date.value}
      className="transport-licence-date"
      slotProps={{
        textField: {
          name: 'transport_licence_date',
          required: state.fields.transport_licence_date.required,
          error: state.fields.transport_licence_date.hasError,
          helperText: state.fields.transport_licence_date.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('transport_licence_date', getJSDateOrNull(date), dispatch)}
    />
  );
};

const Tyres: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.tyres.required}
      name="tyres"
      error={state.fields.tyres.hasError}
      helperText={state.fields.tyres.feedback}
      className="tyres"
      label="Renkaat"
      value={state.fields.tyres.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const Hoist: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.hoist.required}
      name="hoist"
      error={state.fields.hoist.hasError}
      helperText={state.fields.hoist.feedback}
      className="hoist"
      label="Nosturi"
      value={state.fields.hoist.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const PlatformDetails: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.platform_details.required}
      name="platform_details"
      error={state.fields.platform_details.hasError}
      helperText={state.fields.platform_details.feedback}
      className="platform_details"
      label="Lavan tiedot"
      value={state.fields.platform_details.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const IsAdrCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_adr_checkbox"
          name="is_adr"
          checked={state.fields.is_adr.value || false}
          onChange={(event) => updateFieldValue(event.target.name as FieldName, !state.fields.is_adr.value, dispatch)}
        />
      }
      label="Adr lupa"
    />
  );
};

const UnladenWeight: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <FormControl error={state.fields.unladen_weight.hasError}>
      <InputLabel htmlFor="unladen_weight">Omapaino</InputLabel>
      <Input
        disabled={disabled}
        id="unladen_weight"
        name="unladen_weight"
        type="number"
        value={state.fields.unladen_weight.value ?? ''}
        onChange={(event) =>
          updateFieldValue('unladen_weight', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
        startAdornment={<InputAdornment position="start">kg</InputAdornment>}
      />
      {state.fields.unladen_weight.feedback ? (
        <FormHelperText className="unladen_weight-helper-text">{state.fields.unladen_weight.feedback}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const GrossWeight: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <FormControl error={state.fields.gross_weight.hasError}>
      <InputLabel htmlFor="gross_weight">Kokonaispaino</InputLabel>
      <Input
        disabled={disabled}
        id="gross_weight"
        name="gross_weight"
        type="number"
        value={state.fields.gross_weight.value ?? ''}
        onChange={(event) =>
          updateFieldValue('gross_weight', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
        startAdornment={<InputAdornment position="start">kg</InputAdornment>}
      />
      {state.fields.gross_weight.feedback ? (
        <FormHelperText className="gross_weight-helper-text">{state.fields.gross_weight.feedback}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const Height: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <FormControl error={state.fields.height.hasError}>
      <InputLabel htmlFor="height">Korkeus</InputLabel>
      <Input
        disabled={disabled}
        id="height"
        name="height"
        type="number"
        value={state.fields.height.value ?? ''}
        onChange={(event) =>
          updateFieldValue('height', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
        startAdornment={<InputAdornment position="start">m</InputAdornment>}
      />
      {state.fields.height.feedback ? (
        <FormHelperText className="height-helper-text">{state.fields.height.feedback}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const FuelCard: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.fuel_card.required}
      name="fuel_card"
      error={state.fields.fuel_card.hasError}
      helperText={state.fields.fuel_card.feedback}
      className="fuel_card"
      label="Polttoainekortti"
      value={state.fields.fuel_card.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const FuelId: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.fuel_id.required}
      name="fuel_id"
      error={state.fields.fuel_id.hasError}
      helperText={state.fields.fuel_id.feedback}
      className="fuel_id"
      label="Polttoaineid"
      value={state.fields.fuel_id.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const Notes: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      multiline={true}
      disabled={disabled}
      required={state.fields.note.required}
      name="note"
      error={state.fields.note.hasError}
      helperText={state.fields.note.feedback}
      className="note"
      label="Lisätietoja"
      value={state.fields.note.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};
