import { hourlyWorkReason, legacyEtaisyysField, price, workHours } from '../../validation';

export type ValidationResult = {
  hasError: boolean;
  feedback?: string;
};

const getValidationResult = (joiSchema: any, value: any): ValidationResult => {
  const result = joiSchema.validate(value);
  const validationResult: ValidationResult = {
    hasError: result.error !== undefined,
    feedback: result.error?.message,
  };
  return validationResult;
};

export const validateField = (fieldName: string, value: any, required: boolean): ValidationResult => {
  let validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  switch (fieldName) {
    case 'hourly_work_reason': {
      validationResult = getValidationResult(hourlyWorkReason, value);
      break;
    }
    case 'hourly_work_hours': {
      validationResult = getValidationResult(workHours, value);
      break;
    }
    case 'price': {
      validationResult = getValidationResult(price, value);
      break;
    }
    case 'legacy_etaisyys_field': {
      validationResult = getValidationResult(legacyEtaisyysField, value);
      break;
    }
  }

  if (required && (value === undefined || value === null || value === '')) {
    validationResult.hasError = true;
    validationResult.feedback = 'Kenttä on pakollinen';
  }
  return validationResult;
};
