/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ConflictErrorResponse,
  ConflictErrorResponseFromJSON,
  ConflictErrorResponseToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  PostIntegrationShipmentRequestBody,
  PostIntegrationShipmentRequestBodyFromJSON,
  PostIntegrationShipmentRequestBodyToJSON,
  ShipmentIntegrationResponseBody,
  ShipmentIntegrationResponseBodyFromJSON,
  ShipmentIntegrationResponseBodyToJSON,
} from '../models';

export interface IntegrationApiPostIntegrationKrautaShipmentRequest {
  store?: string;
  file?: Blob;
}

export interface IntegrationApiPostIntegrationKrautaYpShipmentRequest {
  postIntegrationShipmentRequestBody: PostIntegrationShipmentRequestBody;
}

export interface IntegrationApiPostIntegrationPeriShipmentRequest {
  other: boolean;
  file?: Blob;
}

export interface IntegrationApiPostIntegrationShipmentRequest {
  file?: Blob;
}

export interface IntegrationApiPostIntegrationTiborShipmentRequest {
  file?: Blob;
}

/**
 *
 */
export class IntegrationApi extends runtime.BaseAPI {
  /**
   * Upload K-rauta order pdf file to be processed
   */
  async postIntegrationKrautaShipmentRaw(
    requestParameters: IntegrationApiPostIntegrationKrautaShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentIntegrationResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.store !== undefined) {
      queryParameters['store'] = requestParameters.store;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/integrations/shipments/k-rauta`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentIntegrationResponseBodyFromJSON(jsonValue));
  }

  /**
   * Upload K-rauta order pdf file to be processed
   */
  async postIntegrationKrautaShipment(
    requestParameters: IntegrationApiPostIntegrationKrautaShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentIntegrationResponseBody> {
    const response = await this.postIntegrationKrautaShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Creates shipment from given json file
   */
  async postIntegrationKrautaYpShipmentRaw(
    requestParameters: IntegrationApiPostIntegrationKrautaYpShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentIntegrationResponseBody>> {
    if (
      requestParameters.postIntegrationShipmentRequestBody === null ||
      requestParameters.postIntegrationShipmentRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'postIntegrationShipmentRequestBody',
        'Required parameter requestParameters.postIntegrationShipmentRequestBody was null or undefined when calling postIntegrationKrautaYpShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/integrations/shipments/k-rauta-yp`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PostIntegrationShipmentRequestBodyToJSON(requestParameters.postIntegrationShipmentRequestBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentIntegrationResponseBodyFromJSON(jsonValue));
  }

  /**
   * Creates shipment from given json file
   */
  async postIntegrationKrautaYpShipment(
    requestParameters: IntegrationApiPostIntegrationKrautaYpShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentIntegrationResponseBody> {
    const response = await this.postIntegrationKrautaYpShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Upload peri order pdf file to be processed
   */
  async postIntegrationPeriShipmentRaw(
    requestParameters: IntegrationApiPostIntegrationPeriShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentIntegrationResponseBody>> {
    if (requestParameters.other === null || requestParameters.other === undefined) {
      throw new runtime.RequiredError(
        'other',
        'Required parameter requestParameters.other was null or undefined when calling postIntegrationPeriShipment.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.other !== undefined) {
      queryParameters['other'] = requestParameters.other;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/integrations/shipments/peri`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentIntegrationResponseBodyFromJSON(jsonValue));
  }

  /**
   * Upload peri order pdf file to be processed
   */
  async postIntegrationPeriShipment(
    requestParameters: IntegrationApiPostIntegrationPeriShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentIntegrationResponseBody> {
    const response = await this.postIntegrationPeriShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Upload shipment integration file to be processed
   */
  async postIntegrationShipmentRaw(
    requestParameters: IntegrationApiPostIntegrationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentIntegrationResponseBody>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/integrations/shipments`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentIntegrationResponseBodyFromJSON(jsonValue));
  }

  /**
   * Upload shipment integration file to be processed
   */
  async postIntegrationShipment(
    requestParameters: IntegrationApiPostIntegrationShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentIntegrationResponseBody> {
    const response = await this.postIntegrationShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Upload Tibnor shipment file (TransportPlanBooking or TransportOrder) to be processed
   */
  async postIntegrationTiborShipmentRaw(
    requestParameters: IntegrationApiPostIntegrationTiborShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentIntegrationResponseBody>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/integrations/shipments/tibnor`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentIntegrationResponseBodyFromJSON(jsonValue));
  }

  /**
   * Upload Tibnor shipment file (TransportPlanBooking or TransportOrder) to be processed
   */
  async postIntegrationTiborShipment(
    requestParameters: IntegrationApiPostIntegrationTiborShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentIntegrationResponseBody> {
    const response = await this.postIntegrationTiborShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
