import { Checkbox, CheckboxProps, FormControlLabel, styled, Tooltip } from '@mui/material';
import React from 'react';

export interface FilterCheckboxProps extends CheckboxProps {
  label: string;
  tooltip?: string;
}

const StyledFormControlLabel = styled(FormControlLabel)({
  margin: '-0.25rem',
});

export const FilterCheckbox: React.FC<FilterCheckboxProps> = ({ label, tooltip, ...checkboxProps }) => (
  <Tooltip title={tooltip ?? ''} aria-label={tooltip ?? ''}>
    <StyledFormControlLabel
      control={<Checkbox size="small" color="primary" {...checkboxProps} />}
      label={label ?? ''}
    />
  </Tooltip>
);
