import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Checkbox, Chip, MenuItem, styled } from '@mui/material';
import { Office } from '../api';
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material';

export interface OfficePickerProps {
  className?: string;
  offices: Office[];
  value: Office[];
  onChange: (office: Office[]) => void;
  disabled?: boolean;
  required?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
}

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;
const indeterminateIcon = <IndeterminateCheckBox fontSize="small" />;

const GroupItems = styled('ul')({
  paddingLeft: '0.5rem',
});

export const OfficePicker: React.FC<OfficePickerProps> = ({
  className,
  disabled,
  offices,
  value,
  onChange,
  required,
  variant,
}) => {
  // emptyOffice is used to filter shipments without billing_office
  const emptyOffice = {
    id: -1,
    organization_id: '',
    organization_specific_office_id: '',
    name: 'EI KUSTANNUSPAIKKAA',
    address: '',
    postal_code: '',
    city: '',
    billing_enabled: true,
    phone_number: '',
    contact_person_name: '',
    default_pricing_model: null,
    is_other_carrier: false,
  };
  const sortedOffices = offices.sort((office1, office2) =>
    (office1.name ?? '').toLowerCase() > (office2.name ?? '').toLowerCase() ? 1 : -1,
  );

  return (
    <Autocomplete
      size="small"
      className={'office-picker ' + className}
      disabled={disabled}
      multiple
      id="office-picker"
      options={offices.length === 0 ? sortedOffices : [emptyOffice, ...sortedOffices]}
      getOptionLabel={(office) => office.name ?? String(office.id)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      renderOption={(props, office, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: '0.5rem' }} checked={selected} />
          {office.name}
        </li>
      )}
      onChange={(_event, offices) => onChange(offices)}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          key={params.id}
          required={required}
          label="Kustannuspaikat"
          variant={variant ?? 'standard'}
        />
      )}
      limitTags={2}
      renderTags={(value, getTagProps) => {
        return value.map((office, index) => (
          <Chip variant="outlined" label={office.name} {...getTagProps({ index })} />
        ));
      }}
      groupBy={() => 'Valitse kaikki'}
      renderGroup={(params) => (
        <div key={params.key}>
          <MenuItem
            onClick={() => {
              value.length === 0 ? onChange([...offices, emptyOffice]) : onChange([]);
            }}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: '0.5rem' }}
              checked={value.length > 0}
              indeterminate={value.length > 0 && value.length < offices.length + 1}
              indeterminateIcon={indeterminateIcon}
            />
            Valitse kaikki
          </MenuItem>
          <GroupItems>{params.children}</GroupItems>
        </div>
      )}
    />
  );
};
