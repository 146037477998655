import React, { BaseSyntheticEvent, Dispatch } from 'react';
import { Action, CarPricingRowField, debouncedValidateFieldsDispatch, State } from '../pricingModel.state';
import { FormControl, FormHelperText, Input, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { CarPricingRowCarTypeEnum } from '../../../api';
import { DenseTableCell, HeaderTableCell } from '../../organizations/components/StyledComponents';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { PriceMultiplierMenu } from './PriceMultiplierMenu';
import { formatCarType } from '../../../formatters';

interface PricingTableProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export const CarPricingTable: React.FC<PricingTableProps> = ({ state, dispatch }) => {
  return (
    <FieldSet>
      <legend>Hintataulu</legend>
      <PriceMultiplierMenu isWorkHoursBasedPricing={true} state={state} dispatch={dispatch} />
      <Table className="car-pricing-table">
        <TableHead>
          <TableRow className="header-row">
            <HeaderTableCell>Autotyyppi</HeaderTableCell>
            <HeaderTableCell>Hinta</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(Object.keys(CarPricingRowCarTypeEnum) as (keyof typeof CarPricingRowCarTypeEnum)[])
            .sort((a, b) => a.localeCompare(b))
            .map((key, index) => {
              const carPricingRowField = state.carPricingRowFields.find(
                (carPricingRowField) => carPricingRowField.carPricingRow.car_type === CarPricingRowCarTypeEnum[key],
              );
              return (
                <TableRow key={index} className="car-pricing-row">
                  <DenseTableCell>{formatCarType(CarPricingRowCarTypeEnum[key])}</DenseTableCell>
                  <DenseTableCell>
                    <FormControl error={carPricingRowField?.hasError}>
                      <Input
                        className="car-pricing-row-price"
                        name="car-pricing-row-price"
                        margin="dense"
                        type="number"
                        disabled={false}
                        error={carPricingRowField?.hasError}
                        value={carPricingRowField?.value ?? ''}
                        //disable changing value with mouse scroll
                        onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
                        onChange={(e) => {
                          dispatch({
                            type: 'UPDATE_CAR_PRICING_ROW_VALUE',
                            payload: {
                              carPricingRowField: carPricingRowField as CarPricingRowField,
                              value: e.target.value ? Number(e.target.value) : undefined,
                            },
                          });
                          debouncedValidateFieldsDispatch(dispatch);
                        }}
                      />
                      {carPricingRowField?.feedback ? (
                        <FormHelperText className="car-pricing-row-price-helper-text">
                          {carPricingRowField?.feedback}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </DenseTableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </FieldSet>
  );
};

export default CarPricingTable;
