import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { FormHelperText, Link, styled } from '@mui/material';
import { Car, Driver, Load, Shipment } from '../api';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../theme';
import { Warning } from '@mui/icons-material';

export interface TrailerPickerProps {
  className?: string;
  currentLoad: Load;
  loads: Load[];
  loadShipments: Shipment[];
  drivers: Driver[];
  cars: Car[];
  value: Car | null;
  onChange: (car: Car | null) => void;
  disabled?: boolean;
}

export const WarningIcon = styled(Warning)({
  color: theme.palette.warning.main,
  verticalAlign: 'bottom',
});

export const TrailerPicker: React.FC<TrailerPickerProps> = ({
  className,
  disabled,
  currentLoad,
  loads,
  loadShipments,
  drivers,
  cars,
  value,
  onChange,
}) => {
  const loadsWithSameTrailer = loads.filter((load) => load.trailer_id === value?.id && load.id !== currentLoad.id);

  const driverNames = drivers.map((driver) => ({
    name: `${driver.last_name} ${driver.first_name}`,
    id: driver.id,
  }));

  const loadsWithSameTrailerAndDriverName = loadsWithSameTrailer.map((load) => {
    return {
      ...load,
      driverName: driverNames.find((driver) => driver.id === load.driver_id)?.name,
    };
  });

  const shipmentsWithTrailerRequirement = loadShipments.filter((shipment) => shipment.requires_combination_vehicle);

  return (
    <>
      <Autocomplete
        disabled={disabled}
        fullWidth
        className={'trailer-picker ' + className}
        options={cars.sort((car1, car2) =>
          (car1.licence_plate ?? '').toLowerCase() > (car2.licence_plate ?? '').toLowerCase() ? 1 : -1,
        )}
        autoHighlight
        getOptionLabel={(car) => car.licence_plate ?? String(car.id)}
        renderOption={(props, car) => <li {...props}>{car.licence_plate}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Perävaunu"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        value={value}
        onChange={(_event, car) => onChange(car)}
      />
      {loadsWithSameTrailerAndDriverName && loadsWithSameTrailerAndDriverName.length === 1 ? (
        <FormHelperText className="trailer-already-in-use-helper-text">
          <WarningIcon />
          Perävaunu on jo käytössä kuljettajalla {loadsWithSameTrailerAndDriverName[0].driverName}, kuormassa{' '}
          <Link component={RouterLink} to={{ pathname: `/loads/${loadsWithSameTrailerAndDriverName[0].id}` }}>
            {loadsWithSameTrailerAndDriverName[0].id}
          </Link>
          .
        </FormHelperText>
      ) : loadsWithSameTrailerAndDriverName && loadsWithSameTrailerAndDriverName.length > 1 ? (
        <FormHelperText className="trailer-already-in-use-helper-text">
          <WarningIcon />
          Perävaunu on jo käytössä kuljettajilla{' '}
          {loadsWithSameTrailerAndDriverName.map((load) => load.driverName) + ', '}
          kuormissa{' '}
          {loadsWithSameTrailerAndDriverName.map((load, index) => (
            <span key={load.id}>
              <Link component={RouterLink} to={{ pathname: `/loads/${load.id}` }}>
                {load.id}
              </Link>
              <span>{index < loadsWithSameTrailerAndDriverName.length - 1 ? ', ' : '.'}</span>
            </span>
          ))}
        </FormHelperText>
      ) : null}
      {shipmentsWithTrailerRequirement && shipmentsWithTrailerRequirement.length === 1 ? (
        <FormHelperText className="trailer-requirement-helper-text">
          <WarningIcon />
          Toimitus{' '}
          <Link component={RouterLink} to={{ pathname: `/shipments/${shipmentsWithTrailerRequirement[0].id}` }}>
            {shipmentsWithTrailerRequirement[0].id}
          </Link>{' '}
          vaatii yhdistelmäajoneuvon.
        </FormHelperText>
      ) : shipmentsWithTrailerRequirement && shipmentsWithTrailerRequirement.length > 1 ? (
        <FormHelperText className="trailer-requirement-helper-text">
          <WarningIcon />
          Toimitukset{' '}
          {shipmentsWithTrailerRequirement.map((shipment, index) => (
            <span key={shipment.id}>
              <Link component={RouterLink} to={{ pathname: `/shipments/${shipment.id}` }}>
                {shipment.id}
              </Link>
              <span>{index < shipmentsWithTrailerRequirement.length - 1 ? ', ' : ' '}</span>
            </span>
          ))}
          vaativat yhdistelmäajoneuvon.
        </FormHelperText>
      ) : null}
    </>
  );
};
