import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Button, Link } from '@mui/material';
import { Column, Row } from 'react-table';
import { initialState, load, reducer, UsersViewSettings } from './users.state';
import Main from '../../components/Main';
import { ReactTable } from '../../components/ReactTable';
import { getViewSettings } from '../settings';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const VIEW_ID = 'users' as const;

const Users: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const viewSettings = React.useMemo(() => getViewSettings<UsersViewSettings>(VIEW_ID), []);

  useEffect(() => {
    if (currentUser) {
      load(dispatch, currentUser);
    }
  }, [currentUser]);

  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        id: 'username',
        Header: 'Käyttäjätunnus',
        accessor: 'username',
        Cell: ({ row }: { row: Row }) => (
          <Link component={RouterLink} to={{ pathname: `/users/${row.values.username}` }}>
            {row.values.username}
          </Link>
        ),
      },
      {
        Header: 'Asiakas',
        accessor: 'organizationName',
      },
      {
        Header: 'Etunimi',
        accessor: 'first_name',
      },
      {
        Header: 'Sukunimi',
        accessor: 'last_name',
      },
    ],
    [],
  );

  const data = React.useMemo(() => state.users, [state.users]);

  return (
    <Main>
      <Button
        disabled={state.isLoading}
        id="create-new-user-button"
        onClick={() => {
          navigate('/users/new');
        }}
      >
        Luo uusi käyttäjä
      </Button>
      <ReactTable
        columns={columns}
        data={data}
        header="Käyttäjät"
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä käyttäjiä."
        initialState={{
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'username',
              asc: true,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        onStateChange={(state) => {
          dispatch({
            type: 'SET_VIEW_SETTINGS',
            payload: { filters: state.filters, sortBy: state.sortBy, hiddenColumns: state.hiddenColumns },
          });
        }}
        columnSelector={true}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default Users;
