import {
  CustomPricingCategory,
  OrganizationShipmentPostBody,
  OrganizationShipmentPostBodyStateEnum,
  OrganizationShipmentPutBody,
  OrganizationShipmentPutBodyStateEnum,
  ShipmentPostBody,
  ShipmentPutBody,
  ShipmentRowStateEnum,
  ShipmentStateEnum,
  UpdateDistanceAndItsRelatedShipmentsPatchBody,
  User,
} from '../../../api';
import { canAccessPricing, ifDateExistGetSameDateAtMidday } from '../../../utils';
import { State } from '../types/shipment.types';
import { getAgreedDeliveryWindow, getPickupWindow } from '../shipment.state';
import { ShipmentRowWithIsNewAndUpdated } from '../components/NewShipmentRowTable';

export const getOrganizationShipmentPostBodyFromState = (
  state: State,
  rowsReadyForPickup: boolean,
): OrganizationShipmentPostBody => {
  const pickupWindow = getPickupWindow(state.fields);
  const agreedDeliveryWindow = getAgreedDeliveryWindow(state.fields);
  return {
    agreed_delivery_window_starts_at: agreedDeliveryWindow.startsAt.toJSDate(),
    agreed_delivery_window_ends_at: agreedDeliveryWindow.endsAt.toJSDate(),
    billing_office_organization_specific_id: state.fields.billing_office_organization_specific_id.value || '',
    delivery_office_organization_specific_id: state.fields.delivery_office_organization_specific_id.value || null,
    delivery_name: state.fields.delivery_name.value,
    delivery_address: state.fields.delivery_address.value,
    delivery_city: state.fields.delivery_city.value,
    delivery_phone_number: state.fields.delivery_phone_number.value || null,
    delivery_postal_code: state.fields.delivery_postal_code.value,
    length_ldm: state.fields.length_ldm.value || 0,
    note: state.fields.note.value,
    orderer: state.fields.orderer.value || null,
    orderer_phone_number: state.fields.orderer_phone_number.value || null,
    reference_number: state.fields.reference_number.value,
    job_number: state.fields.job_number.value,
    pickup_office_organization_specific_id: state.fields.pickup_office_organization_specific_id.value || null,
    pickup_name: state.fields.pickup_name.value,
    pickup_address: state.fields.pickup_address.value,
    pickup_city: state.fields.pickup_city.value,
    pickup_phone_number: state.fields.pickup_phone_number.value || null,
    pickup_phone_number_secondary: state.fields.pickup_phone_number_secondary.value || null,
    pickup_postal_code: state.fields.pickup_postal_code.value,
    pickup_window_starts_at: pickupWindow?.startsAt.toJSDate() ?? null,
    pickup_window_ends_at: pickupWindow?.endsAt.toJSDate() ?? null,
    weight_kg: state.fields.weight_kg.value || 0,
    volume_m3: state.fields.volume_m3.value || 0,
    state: state.fields.state.value as unknown as OrganizationShipmentPostBodyStateEnum,
    pricing_model: state.fields.pricing_model.value || null,
    custom_pricing_category_id: state.fields.custom_pricing_category_id.value || null,
    other_contract_number: state.fields.other_contract_number.value || null,
    is_adr_delivery: state.fields.is_adr_delivery.value || false,
    delivery_phone_number_secondary: state.fields.delivery_phone_number_secondary.value || null,
    billing_reference_number: state.fields.billing_reference_number.value || null,
    requires_combination_vehicle: state.fields.requires_combination_vehicle.value || false,
    requires_hoist: state.fields.requires_hoist.value || false,
    is_express_delivery: state.fields.is_express_delivery.value || false,
    rows: getFormattedRows(state.rows, rowsReadyForPickup) as OrganizationShipmentPutBody['rows'],
  };
};
export const getShipmentCustomPricingCategoryFromState = (state: State): CustomPricingCategory | null => {
  const currentPricingModel = state.pricingModels.find(
    (pricingModel) => pricingModel.id === state.fields.pricing_model.value,
  );
  const customPricingCategories = currentPricingModel?.custom_pricing_categories;
  return (
    customPricingCategories?.find(
      (customPricingCategory) => customPricingCategory.id === state.fields.custom_pricing_category_id.value,
    ) ?? null
  );
};
export const getShipmentPostBodyFromState = (
  state: State,
  currentUser: User,
  rowsReadyForPickup: boolean,
): ShipmentPostBody => {
  return {
    ...getOrganizationShipmentPostBodyFromState(state, rowsReadyForPickup),
    hourly_work_hours: state.fields.hourly_work_hours.value || 0,
    hourly_work_reason: state.fields.hourly_work_reason.value,
    job_number: state.fields.job_number.value,
    load_id: state.fields.load_id.value || null,
    order_in_load: state.originalShipment?.order_in_load ?? null,
    organization_id: state.fields.organization_id.value,
    other_contract_number: state.fields.other_contract_number.value || null,
    recipient: state.originalShipment?.recipient ?? null,
    state: state.fields.state.value as unknown as ShipmentStateEnum,
    wait_reason: state.fields.wait_reason.value,
    weight_kg: state.fields.weight_kg.value || 0,
    wait_hours: state.fields.wait_hours.value || 0,
    ...(canAccessPricing(currentUser) && {
      has_contract_price: state.fields.has_contract_price.value || false,
      legacy_price_basis: state.fields.legacy_price_basis.value,
      price: state.fields.price.value || 0,
      has_additional_hourly_pricing: state.fields.has_additional_hourly_pricing.value || false,
      legacy_etaisyys_field: state.fields.legacy_etaisyys_field.value || null,
      has_distance_been_fixed: state.fields.has_distance_been_fixed.value || false,
    }),
    additional_services: state.additionalServiceFields.map((field) => ({
      additional_service_id: field.additionalService.id,
      shipment_additional_service_id: field.shipmentAdditionalServiceId,
      amount: field.value || 0,
    })),
  };
};
const getFormattedRows = (rows: ShipmentRowWithIsNewAndUpdated[], readyForPickup: boolean) => {
  return rows.map((row) => {
    row.serial_number = row.serial_number ?? null;
    row.parcel_id = row.parcel_id ?? null;
    row.quantity = row.quantity ?? null;
    row.weight_per_piece_kg = row.weight_per_piece_kg ?? null;
    row.state = readyForPickup ? ShipmentRowStateEnum.ReadyForPickup : row.state;
    if (row.isNew) {
      const { id, ...rowWithOutId } = row;
      return rowWithOutId;
    } else {
      return row;
    }
  });
};
export const getInitialState = (currentUser: User | undefined): State => {
  return {
    fields: {
      organization_id: {
        required: currentUser?.is_multi_organization ?? false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      billing_office_organization_specific_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      delivery_date: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      delivery_time_window_start: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      delivery_time_window_end: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      delivery_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      delivery_address: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      delivery_city: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      delivery_phone_number: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      delivery_postal_code: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      job_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      note: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      orderer: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      orderer_phone_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      reference_number: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      delivery_office_organization_specific_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      pickup_office_organization_specific_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      pickup_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      pickup_address: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      pickup_city: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      pickup_phone_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      pickup_phone_number_secondary: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      pickup_postal_code: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      pickup_date: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      pickup_time: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      state: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: ShipmentStateEnum.Noudettavissa,
        access: 'hidden',
      },
      length_ldm: {
        required: false,
        hasError: false,
        feedback: '',
        value: '',
        access: 'hidden',
      },
      chargeable_weight_kg: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: 0,
        access: 'hidden',
      },
      weight_kg: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      price: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: 0,
        access: 'hidden',
      },
      legacy_price_basis: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      has_contract_price: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
        access: 'hidden',
      },
      load_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      is_express_delivery: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
        access: 'hidden',
      },
      hourly_work_hours: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      hourly_work_reason: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      requires_combination_vehicle: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
        access: 'hidden',
      },
      volume_m3: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      wait_reason: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      has_additional_hourly_pricing: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
        access: 'hidden',
      },
      legacy_etaisyys_field: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      customer_distribution_area: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      pricing_model: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      custom_pricing_category_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      delivery_status_url_identifier: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      other_contract_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      is_adr_delivery: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
        access: 'hidden',
      },
      ordered_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      arrived_to_pickup_location_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      picked_up_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      arrived_to_delivery_location_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      delivered_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      billed_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
        access: 'hidden',
      },
      wait_hours: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      delivery_phone_number_secondary: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      has_distance_been_fixed: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
        access: 'hidden',
      },
      billing_reference_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
        access: 'hidden',
      },
      requires_hoist: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
        access: 'hidden',
      },
      sizes: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
        access: 'hidden',
      },
    },
    rows: [],
    photos: [],
    originalShipment: undefined,
    isLoading: true,
    notification: {
      message: null,
    },
    isValid: false,
    canSave: false,
    canSaveInitial: false,
    initialized: false,
    organizationOffices: [],
    updated_at: null,
    updated_by: '',
    organizations: [],
    relatedShipments: [],
    isRelatedShipmentsLoading: false,
    organizationAdditionalServices: [],
    shipmentAdditionalServices: [],
    additionalServiceFields: [],
    pricingModels: [],
    isAddPhotoDialogOpen: false,
    currentUser: undefined,
  };
};
export const getOrganizationShipmentPutBodyFromState = (state: State): OrganizationShipmentPutBody => {
  const body = getOrganizationShipmentPostBodyFromState(state, false);
  return {
    ...body,
    state: body.state as unknown as OrganizationShipmentPutBodyStateEnum,
    rows: body.rows as OrganizationShipmentPutBody['rows'],
  };
};
export const getShipmentPutBodyFromState = (state: State, currentUser: User): ShipmentPutBody => {
  return {
    ...getShipmentPostBodyFromState(state, currentUser, false),
    ordered_at: state.fields.ordered_at.value || null,
    arrived_to_pickup_location_at: state.fields.arrived_to_pickup_location_at.value || null,
    picked_up_at: state.fields.picked_up_at.value || null,
    arrived_to_delivery_location_at: state.fields.arrived_to_delivery_location_at.value || null,
    delivered_at: state.fields.delivered_at.value || null,
    billed_at: ifDateExistGetSameDateAtMidday(state.fields.billed_at.value),
  };
};
export const getUpdateDistanceAndItsRelatedShipmentsPutBodyFromState = (
  state: State,
): UpdateDistanceAndItsRelatedShipmentsPatchBody => {
  return {
    legacy_etaisyys_field: state.fields.legacy_etaisyys_field.value || null,
    address1: state.fields.pickup_address.value || '',
    city1: state.fields.pickup_city.value || '',
    address2: state.fields.delivery_address.value || '',
    city2: state.fields.delivery_city.value || '',
  };
};
