/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SingleShipmentPatchBodyStateEnum {
  Noudettavissa = 'noudettavissa',
  Noutokohteessa = 'noutokohteessa',
  Noudettu = 'noudettu',
  Toimituskohteessa = 'toimituskohteessa',
  Toimitettu = 'toimitettu',
  Peruttu = 'peruttu',
  Odottaa = 'odottaa',
  ToimitetaanSeuraavaksi = 'toimitetaan_seuraavaksi',
}

export function SingleShipmentPatchBodyStateEnumFromJSON(json: any): SingleShipmentPatchBodyStateEnum {
  return SingleShipmentPatchBodyStateEnumFromJSONTyped(json, false);
}

export function SingleShipmentPatchBodyStateEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SingleShipmentPatchBodyStateEnum {
  return json as SingleShipmentPatchBodyStateEnum;
}

export function SingleShipmentPatchBodyStateEnumToJSON(value?: SingleShipmentPatchBodyStateEnum | null): any {
  return value as any;
}
