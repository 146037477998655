import { useSelector } from 'react-redux';
import { Organization } from '../api';
import { RootState } from '../store';
import { CarrierUser, CurrentUser } from '../reducers/authReducer';

export const useCurrentUser: () => CurrentUser | CarrierUser | undefined = () =>
  useSelector((store: RootState) => store.auth.user);

export const useCurrentUserOrganization: () => Organization | undefined = () =>
  useSelector((store: RootState) => store.auth.organization);
