import { Button, styled, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ColumnInstance, Row, TableRowProps } from 'react-table';
import { api, ShipmentRow } from '../api';
import { ShipmentRowProductsModal } from './ShipmentRowProductsModal';
import { Loading } from './Loading';
import { orderBy } from 'lodash';
import { formatDateTime, formatShipmentRowState } from '../formatters';
import { SubdirectoryArrowRight } from '@mui/icons-material';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { canAccessCoordination } from '../utils';

const FreePositionLoadingSpinner = styled(Loading)({
  position: 'unset',
});

interface ShipmentRowSubRowsProps {
  rowProps: TableRowProps;
  visibleColumns: ColumnInstance[];
  shipmentRows: ShipmentRow[];
  loading: boolean;
  hideSizeInformation?: boolean;
}

const getStateTimestamp = (shipmentRow: ShipmentRow): string => {
  const state = shipmentRow.state;
  switch (state) {
    case 'ready_for_pickup': {
      return '';
    }
    case 'picked_up': {
      return shipmentRow.picked_up_at ? formatDateTime(shipmentRow.picked_up_at) : '';
    }
    case 'lost': {
      return shipmentRow.lost_at ? formatDateTime(shipmentRow.lost_at) : '';
    }
    case 'failed': {
      return shipmentRow.failed_at ? formatDateTime(shipmentRow.failed_at) : '';
    }
    case 'delivered': {
      return shipmentRow.delivered_at ? formatDateTime(shipmentRow.delivered_at) : '';
    }
    default: {
      return '';
    }
  }
};

export const ShipmentRowSubRows: React.FC<ShipmentRowSubRowsProps> = ({
  rowProps,
  visibleColumns,
  shipmentRows,
  loading,
  hideSizeInformation,
}) => {
  const [modalShipmentRow, setModalShipmentRow] = useState<ShipmentRow | undefined>(undefined);
  if (loading) {
    return (
      <tr>
        <TableCell />
        <TableCell colSpan={visibleColumns.length - 1}>
          <FreePositionLoadingSpinner isLoading={loading} loadingMessage="Ladataan toimitusrivejä..." />
        </TableCell>
      </tr>
    );
  } else if (!loading && shipmentRows.length === 0) {
    return (
      <tr>
        <TableCell />
        <TableCell colSpan={visibleColumns.length - 1}>Ei toimitusrivejä.</TableCell>
      </tr>
    );
  } else {
    return (
      <>
        {orderBy(shipmentRows, ['parcel_type', 'total_weight_kg'], ['asc', 'desc']).map((shipmentRow, index) => {
          return (
            <tr {...rowProps} key={`${rowProps.key}-expanded-${index}`}>
              <TableCell style={{ textAlign: 'right' }}>
                <SubdirectoryArrowRight fontSize="small" color="disabled" />
              </TableCell>
              <TableCell>{shipmentRow.parcel_id ?? ''}</TableCell>
              <TableCell>{shipmentRow.parcel_type ?? ''}</TableCell>
              <TableCell>{shipmentRow.name}</TableCell>
              {hideSizeInformation ? null : (
                <>
                  <TableCell>{`${shipmentRow.quantity} kpl`}</TableCell>
                  <TableCell>{`${shipmentRow.total_weight_kg} kg`}</TableCell>
                  <TableCell>{`${shipmentRow.volume_m3 ?? 0} m³`}</TableCell>
                  <TableCell>{`${shipmentRow.longest_side_length_mm ?? 0} mm`}</TableCell>
                </>
              )}
              <TableCell>
                <div>{shipmentRow.state ? formatShipmentRowState(shipmentRow.state) : ''}</div>
                <div>{getStateTimestamp(shipmentRow)}</div>
              </TableCell>
              <TableCell colSpan={visibleColumns.length - 1} sx={{ flexGrow: 1 }}>
                <Button
                  variant="text"
                  className="show-shipment-row-products-button"
                  onClick={() => setModalShipmentRow(shipmentRow)}
                >
                  Näytä lähetysluettelo
                </Button>
              </TableCell>
            </tr>
          );
        })}
        {modalShipmentRow && (
          <ShipmentRowProductsModal
            shipmentRow={modalShipmentRow}
            open={modalShipmentRow !== undefined}
            onClose={() => setModalShipmentRow(undefined)}
          />
        )}
      </>
    );
  }
};

interface SubRowAsyncProps {
  row: Row;
  rowProps: TableRowProps;
  visibleColumns: ColumnInstance[];
  hideSizeInformation?: boolean;
}

export const ShipmentRowSubRowAsync: React.FC<SubRowAsyncProps> = ({
  row,
  rowProps,
  visibleColumns,
  hideSizeInformation,
}) => {
  const [loading, setLoading] = useState(true);
  const [shipmentRows, setShipmentRows] = useState<ShipmentRow[]>([]);
  const currentUser = useCurrentUser();
  const hasCoordinationAccess = canAccessCoordination(currentUser);

  const load = async () => {
    setLoading(true);
    const shipmentRows = hasCoordinationAccess
      ? (
          await api.shipments.getShipmentRows({
            shipmentId: Number(row.id),
          })
        ).data
      : (
          await api.organizationShipments.getOrganizationShipmentRows({
            organizationId: currentUser?.organization_id ?? '',
            shipmentId: Number(row.id),
          })
        ).data;
    setShipmentRows(shipmentRows as ShipmentRow[]);
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, [row.id, (row.original as ShipmentRow).state]);

  return (
    <ShipmentRowSubRows
      rowProps={rowProps}
      visibleColumns={visibleColumns}
      shipmentRows={shipmentRows}
      loading={loading}
      hideSizeInformation={hideSizeInformation}
    />
  );
};
