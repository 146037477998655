import React, { Dispatch, useEffect, useState } from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
import { api, apiV2, getAllPages, Shipment, ShipmentsPatchBody, ShipmentStateEnum } from '../../api';
import { ReactTable } from '../../components/ReactTable';
import Notification, { getSnackbarPropsFromState, tryAgainMessage } from '../../components/Notification';
import { Link } from '@mui/material';
import { AutoCompleteFilter, DateColumnFilter, SelectColumnFilter } from '../../components/TableFilters';
import { Accessor, CellValue, Row } from 'react-table';
import { DateRangePicker } from '../../components/DateRangePicker';
import { AddressLink } from '../../components/map';
import { getViewSettings } from '../settings';
import {
  DeliveryWindow,
  getPreciseDeliveryTimeDifferenceInMinutes,
  isPreciseDelivery,
} from '../../components/DeliveryWindow';
import { dateFormatUnderscore, formatDateTime } from '../../formatters';
import { FilterCheckbox } from '../../components/FilterCheckbox';
import { FilterContainer } from '../../components/StyledComponents/FilterContainer';
import { Action, ReportShipment, ShipmentReportViewSettings, State } from './reports.state';
import { EditableCell, EditableItem, EditMenu } from '../../components/EditableCell/EditableCell';
import { StyledToolbar } from '../../components/StyledComponents/StyledToolbar';
import { canAccessCoordination } from '../../utils';
import { getDateRangePickerPropsFromState } from '../../utils/dateRangeUtils';
import { CarrierUser } from '../../reducers/authReducer';
import { convertToUTCNoon } from '../../components/DateAndTimePickers/StandardDatePicker';

const VIEW_ID = 'shipment_report' as const;

type PartialShipment = Partial<Shipment> & Pick<Shipment, 'id'>;

const fetchShipments = async (
  currentUser: CarrierUser,
  state: State,
  dispatch: Dispatch<Action>,
  abortController?: AbortController,
) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [shipments, loads, offices] = canAccessCoordination(currentUser)
      ? await Promise.all([
          getAllPages(
            api.shipments.getShipments.bind(api.shipments),
            {
              agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
              agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
              filterOutOtherCarriers: true,
              getShipmentsByDriveDate: true,
            },
            abortController,
          ),
          getAllPages(
            apiV2.carrier.getCarrierLoads.bind(apiV2.carrier),
            {
              carrierId: currentUser.carrier_id,
              driveDateRangeStartsAt: convertToUTCNoon(state.dateRange.start),
              driveDateRangeEndsAt: convertToUTCNoon(state.dateRange.end),
            },
            abortController,
          ),
          api.offices.getOffices(),
        ])
      : await Promise.all([
          getAllPages(
            api.organizationShipments.getOrganizationShipments.bind(api.organizationShipments),
            {
              organizationId: currentUser.organization_id,
              agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
              agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
              filterOutOtherCarriers: true,
              getShipmentsByDriveDate: true,
            },
            abortController,
          ),
          getAllPages(
            api.organizationLoads.getOrganizationLoads.bind(api.organizationLoads),
            {
              organizationId: currentUser.organization_id,
              driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
              driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
            },
            abortController,
          ),
          api.organizationOffices.getOrganizationOffices({ organizationId: currentUser.organization_id }),
        ]);
    const loadIds = loads.map((load) => load.id);
    const [drivers] = await Promise.all([
      canAccessCoordination(currentUser)
        ? getAllPages(api.drivers.getDrivers.bind(api.drivers), {}, abortController)
        : loadIds.length > 0
          ? getAllPages(
              api.organizationDrivers.getOrganizationDrivers.bind(api.organizationDrivers),
              {
                organizationId: currentUser.organization_id,
                loadIds: loadIds,
                driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
                driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
              },
              abortController,
            )
          : [],
    ]);
    dispatch({
      type: 'INITIALIZE',
      payload: {
        shipments: shipments.map((shipment) => {
          return {
            ...shipment,
            billingOfficeName:
              offices.data.find(
                (office) =>
                  office.organization_specific_office_id === shipment.billing_office_organization_specific_id &&
                  office.organization_id === shipment.organization_id,
              )?.name ?? '',
          };
        }),
        clients: currentUser.clients,
        drivers,
        loads,
      },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe raportin laskennassa',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const filterShipments = (shipments: ReportShipment[], hideCancelledShipments: boolean) => {
  return hideCancelledShipments
    ? shipments.filter((shipment) => (hideCancelledShipments ? shipment.state !== ShipmentStateEnum.Peruttu : true))
    : shipments;
};

const patchShipments = async (
  editedData: EditableItem[],
  state: State,
  carrierUser: CarrierUser,
  isEditing: any,
  setIsEditing: any,
  dispatch: Dispatch<Action>,
): Promise<void> => {
  try {
    const formattedShipments: PartialShipment[] = editedData.map((item, index) => {
      const keys = Object.keys(item);
      const formattedShipment: PartialShipment = { id: 0 };
      keys.map((key) => {
        return Object.assign(formattedShipment, { [key]: editedData[index][key].value });
      });
      return formattedShipment;
    });
    await api.shipments.patchShipments({ shipmentsPatchBody: formattedShipments as ShipmentsPatchBody[] });
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Toimitukset tallennettu onnistuneesti',
      },
    });
    if (carrierUser?.organization_id) {
      await fetchShipments(carrierUser, state, dispatch);
    }
    setIsEditing(!isEditing);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
  }
};

export interface ShipmentReportProps {
  state: State;
  dispatch: Dispatch<Action>;
}

export const ShipmentReport: React.FC<ShipmentReportProps> = ({ state, dispatch }) => {
  const carrierUser = useOutletContext<CarrierUser>();
  const [isEditing, setIsEditing] = useState(false);
  const [editedShipments, setEditedShipments] = useState<EditableItem[]>([]);
  const [canSave, setCanSave] = useState(false);
  const viewSettings = React.useMemo(() => getViewSettings<ShipmentReportViewSettings>(VIEW_ID), []);

  useEffect(() => {
    if (carrierUser) {
      const abortController = new AbortController();
      fetchShipments(carrierUser, state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [carrierUser, state.dateRange]);

  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'Id',
        accessor: 'id',
        Cell: ({ row }: { row: Row }) => (
          <Link component={RouterLink} to={{ pathname: `/shipments/${row.values.id}` }}>
            {row.values.id}
          </Link>
        ),
      },
      {
        id: 'reference_number',
        Header: 'Asiakasviite',
        accessor: 'reference_number',
      },
      {
        id: 'client',
        Header: 'Asiakas',
        accessor: 'organizationName',
        Filter: AutoCompleteFilter,
      },
      {
        id: 'billingOfficeName',
        Header: 'Kustannuspaikka',
        accessor: 'billingOfficeName',
        Filter: AutoCompleteFilter,
      },
      {
        id: 'billing_reference_number',
        Header: 'Laskutusviite',
        accessor: 'billing_reference_number',
      },
      {
        Header: 'Tila',
        accessor: 'state',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Nouto',
        columns: [
          {
            Header: 'Nimi',
            accessor: 'pickup_name',
          },
          {
            Header: 'Osoite',
            accessor: 'pickup_address',
            Cell: (shipment: CellValue) => {
              return (
                <AddressLink
                  className="pickup-address-link"
                  title={shipment.cell.row.original.pickup_address}
                  address={shipment.cell.row.original.pickup_address}
                  postalCode={shipment.cell.row.original.pickup_postal_code}
                  city={shipment.cell.row.original.pickup_city}
                />
              );
            },
          },
          {
            Header: 'Kaupunki',
            accessor: 'pickup_city',
          },
          {
            Header: 'Noudettu',
            accessor: 'picked_up_at',
            Cell: (shipment: CellValue) => {
              return shipment.cell.row.original.picked_up_at
                ? formatDateTime(shipment.cell.row.original.picked_up_at)
                : null;
            },
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
        ],
      },
      {
        Header: 'Toimitus',
        columns: [
          {
            Header: 'Nimi',
            accessor: 'delivery_name',
          },
          {
            Header: 'Osoite',
            accessor: 'delivery_address',
            Cell: (shipment: CellValue) => {
              return (
                <AddressLink
                  className="delivery-address-link"
                  title={shipment.cell.row.original.delivery_address}
                  address={shipment.cell.row.original.delivery_address}
                  postalCode={shipment.cell.row.original.delivery_postal_code}
                  city={shipment.cell.row.original.delivery_city}
                />
              );
            },
          },
          {
            Header: 'Postinumero',
            accessor: 'delivery_postal_code',
          },
          {
            Header: 'Kaupunki',
            accessor: 'delivery_city',
          },
          {
            Header: 'Sovittu toimitusaika',
            accessor: 'agreed_delivery_window_starts_at',
            Cell: (shipment: CellValue) => {
              return (
                <DeliveryWindow
                  startsAt={shipment.cell.row.original.agreed_delivery_window_starts_at}
                  endsAt={shipment.cell.row.original.agreed_delivery_window_ends_at}
                />
              );
            },
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
          {
            Header: 'Toimitettu',
            accessor: 'delivered_at',
            Cell: (shipment: CellValue) => {
              return shipment.cell.row.original.delivered_at
                ? formatDateTime(shipment.cell.row.original.delivered_at)
                : null;
            },
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
          {
            Header: 'Täsmä',
            accessor: ((shipment: CellValue) =>
              isPreciseDelivery(shipment.agreed_delivery_window_starts_at, shipment.agreed_delivery_window_ends_at)
                ? 'Kyllä'
                : '') as Accessor<any>,
            Filter: AutoCompleteFilter,
          },
          {
            Header: 'Täsmätarkkuus (min)',
            accessor: ((shipment: CellValue) =>
              getPreciseDeliveryTimeDifferenceInMinutes(
                shipment.agreed_delivery_window_starts_at,
                shipment.agreed_delivery_window_ends_at,
                shipment.delivered_at,
              )) as Accessor<any>,
          },
        ],
      },
      {
        Header: 'Paino (kg)',
        accessor: 'weight_kg',
      },
      {
        Header: 'Tilavuus (m3)',
        accessor: 'volume_m3',
      },
      {
        Header: 'Lavametrit',
        accessor: 'length_ldm',
      },
      {
        Header: 'Rahdituspaino (kg)',
        accessor: 'chargeable_weight_kg',
      },
      {
        Header: 'Hinta',
        accessor: 'price',
        Cell: (props: CellValue) => {
          return isEditing ? (
            EditableCell({
              ...props,
              type: 'number',
              editedData: editedShipments,
              setEditedData: setEditedShipments,
              setCanSave: setCanSave,
            })
          ) : (
            <span>{props.value}</span>
          );
        },
      },
      {
        Header: 'Hintaperuste',
        accessor: 'price_basis_str',
      },
      {
        id: 'has_contract_price',
        Header: 'Sopimushinta',
        accessor: ((shipment: CellValue) =>
          shipment.has_contract_price === true
            ? 'Kyllä'
            : shipment.has_contract_price === false
              ? 'Ei'
              : '') as Accessor<any>,
      },
      {
        id: 'pricing_failed',
        Header: 'Epäonnistunut hinnoittelu',
        accessor: ((pricingFailed: CellValue) =>
          pricingFailed.pricing_failed === true
            ? 'Kyllä'
            : pricingFailed.pricing_failed === false
              ? 'Ei'
              : '') as Accessor<any>,
      },
      {
        Header: 'Tuntityö',
        accessor: 'hourly_work_hours',
        Cell: (props: CellValue) => {
          return isEditing ? (
            EditableCell({
              ...props,
              type: 'number',
              required: true,
              editedData: editedShipments,
              setEditedData: setEditedShipments,
              setCanSave: setCanSave,
            })
          ) : (
            <span>{props.value}</span>
          );
        },
      },
      {
        Header: 'Tuntisyy',
        accessor: 'hourly_work_reason',
        Cell: (props: CellValue) => {
          return isEditing ? (
            EditableCell({
              ...props,
              editedData: editedShipments,
              setEditedData: setEditedShipments,
              setCanSave: setCanSave,
            })
          ) : (
            <span>{props.value}</span>
          );
        },
      },
      {
        id: 'has_additional_hourly_pricing',
        Header: 'Tuntilisä',
        accessor: ((shipment: CellValue) =>
          shipment.has_additional_hourly_pricing === true
            ? 'Kyllä'
            : shipment.has_additional_hourly_pricing === false
              ? 'Ei'
              : '') as Accessor<any>,
      },
      {
        Header: 'Odotustunnit',
        accessor: 'wait_hours',
      },
      {
        Header: 'Odotussyy',
        accessor: 'wait_reason',
      },
      {
        Header: 'Etäisyys',
        accessor: 'legacy_etaisyys_field',
      },
      {
        Header: 'Kuljettaja',
        accessor: (shipment: CellValue) => shipment.driverName,
        Filter: AutoCompleteFilter,
      },
      {
        id: 'requires_combination_vehicle',
        Header: 'Perävaunu',
        accessor: ((shipment: CellValue) =>
          shipment.requires_combination_vehicle === true
            ? 'Kyllä'
            : shipment.requires_combination_vehicle === false
              ? 'Ei'
              : '') as Accessor<any>,
      },
      {
        Header: 'Lisätietoja',
        accessor: 'note',
      },
    ],
    [isEditing],
  );
  const data = React.useMemo(
    () => filterShipments(state.shipments, state.shipmentReportViewSettings.hideCancelledShipments),
    [state.shipments, state.shipmentReportViewSettings.hideCancelledShipments],
  );
  return (
    <>
      <StyledToolbar>
        {canAccessCoordination(carrierUser) ? (
          <EditMenu
            isLoading={state.isLoading}
            dataLength={state.shipments.length}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            saveData={() => patchShipments(editedShipments, state, carrierUser, isEditing, setIsEditing, dispatch)}
            canSave={canSave}
            setCanSave={setCanSave}
            editedData={editedShipments}
            setEditedData={setEditedShipments}
          />
        ) : null}
        <FilterContainer>
          <FilterCheckbox
            disabled={state.isLoading}
            label={'Piilota perutut toimitukset'}
            checked={state.shipmentReportViewSettings.hideCancelledShipments}
            onChange={(_e) => {
              dispatch({
                type: 'SET_VIEW_SETTINGS',
                payload: {
                  viewId: VIEW_ID,
                  shipmentReportViewSettings: {
                    ...state.shipmentReportViewSettings,
                    hideCancelledShipments: !state.shipmentReportViewSettings.hideCancelledShipments,
                  },
                },
              });
            }}
            name="hide_cancelled_shipments"
            className="hide-cancelled-shipments"
          />
        </FilterContainer>
      </StyledToolbar>
      <ReactTable
        columns={columns}
        data={data}
        header="Toimitusraportti"
        dateRangePicker={<DateRangePicker {...getDateRangePickerPropsFromState(state, dispatch)}></DateRangePicker>}
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä toimituksia."
        columnSelector
        exportName={`Toimitusraportti_${state.dateRange?.start.toFormat(
          dateFormatUnderscore,
        )}_${state.dateRange?.end.toFormat(dateFormatUnderscore)}`}
        initialState={{
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'id',
              desc: true,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        onStateChange={(tableState) => {
          dispatch({
            type: 'SET_VIEW_SETTINGS',
            payload: {
              viewId: VIEW_ID,
              shipmentReportViewSettings: { ...state.shipmentReportViewSettings, ...tableState },
            },
          });
        }}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};
