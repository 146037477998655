/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Client, ClientFromJSON, ClientFromJSONTyped, ClientToJSON } from './Client';

/**
 *
 * @export
 * @interface CarrierClient
 */
export interface CarrierClient {
  /**
   *
   * @type {number}
   * @memberof CarrierClient
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof CarrierClient
   */
  is_active: boolean;
  /**
   *
   * @type {string}
   * @memberof CarrierClient
   */
  client_id: string;
  /**
   *
   * @type {string}
   * @memberof CarrierClient
   */
  client_name: string;
  /**
   *
   * @type {number}
   * @memberof CarrierClient
   */
  carrier_id: number;
  /**
   *
   * @type {string}
   * @memberof CarrierClient
   */
  carrier_name: string;
  /**
   *
   * @type {Date}
   * @memberof CarrierClient
   */
  created_at: Date;
  /**
   *
   * @type {string}
   * @memberof CarrierClient
   */
  created_by: string;
  /**
   *
   * @type {Date}
   * @memberof CarrierClient
   */
  updated_at: Date;
  /**
   *
   * @type {string}
   * @memberof CarrierClient
   */
  updated_by: string;
  /**
   *
   * @type {Client}
   * @memberof CarrierClient
   */
  client?: Client;
}

export function CarrierClientFromJSON(json: any): CarrierClient {
  return CarrierClientFromJSONTyped(json, false);
}

export function CarrierClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierClient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    is_active: json['is_active'],
    client_id: json['client_id'],
    client_name: json['client_name'],
    carrier_id: json['carrier_id'],
    carrier_name: json['carrier_name'],
    created_at: new Date(json['created_at']),
    created_by: json['created_by'],
    updated_at: new Date(json['updated_at']),
    updated_by: json['updated_by'],
    client: !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
  };
}

export function CarrierClientToJSON(value?: CarrierClient | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    is_active: value.is_active,
    client_id: value.client_id,
    client_name: value.client_name,
    carrier_id: value.carrier_id,
    carrier_name: value.carrier_name,
    created_at: value.created_at.toISOString(),
    created_by: value.created_by,
    updated_at: value.updated_at.toISOString(),
    updated_by: value.updated_by,
    client: ClientToJSON(value.client),
  };
}
