/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  OrganizationProductRequestBody,
  OrganizationProductRequestBodyFromJSON,
  OrganizationProductRequestBodyToJSON,
  PaginatedProductsResponseBody,
  PaginatedProductsResponseBodyFromJSON,
  PaginatedProductsResponseBodyToJSON,
  ProductResponseBody,
  ProductResponseBodyFromJSON,
  ProductResponseBodyToJSON,
} from '../models';

export interface OrganizationProductsApiCreateOrganizationProductRequest {
  organizationId: string;
  organizationProductRequestBody: OrganizationProductRequestBody;
}

export interface OrganizationProductsApiDeleteOrganizationProductRequest {
  organizationId: string;
  productId: number;
}

export interface OrganizationProductsApiGetOrganizationProductRequest {
  organizationId: string;
  productId: number;
}

export interface OrganizationProductsApiGetOrganizationProductsRequest {
  organizationId: string;
  next?: number;
}

export interface OrganizationProductsApiUpdateOrganizationProductRequest {
  organizationId: string;
  productId: number;
  organizationProductRequestBody: OrganizationProductRequestBody;
}

/**
 *
 */
export class OrganizationProductsApi extends runtime.BaseAPI {
  /**
   * Create new product.
   */
  async createOrganizationProductRaw(
    requestParameters: OrganizationProductsApiCreateOrganizationProductRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ProductResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrganizationProduct.',
      );
    }

    if (
      requestParameters.organizationProductRequestBody === null ||
      requestParameters.organizationProductRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationProductRequestBody',
        'Required parameter requestParameters.organizationProductRequestBody was null or undefined when calling createOrganizationProduct.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/products`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationProductRequestBodyToJSON(requestParameters.organizationProductRequestBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create new product.
   */
  async createOrganizationProduct(
    requestParameters: OrganizationProductsApiCreateOrganizationProductRequest,
    initOverrides?: RequestInit,
  ): Promise<ProductResponseBody> {
    const response = await this.createOrganizationProductRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a product by id.
   */
  async deleteOrganizationProductRaw(
    requestParameters: OrganizationProductsApiDeleteOrganizationProductRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteOrganizationProduct.',
      );
    }

    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling deleteOrganizationProduct.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/products/{product_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'product_id'}}`, encodeURIComponent(String(requestParameters.productId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a product by id.
   */
  async deleteOrganizationProduct(
    requestParameters: OrganizationProductsApiDeleteOrganizationProductRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteOrganizationProductRaw(requestParameters, initOverrides);
  }

  /**
   * Get a product by id.
   */
  async getOrganizationProductRaw(
    requestParameters: OrganizationProductsApiGetOrganizationProductRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ProductResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationProduct.',
      );
    }

    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling getOrganizationProduct.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/products/{product_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'product_id'}}`, encodeURIComponent(String(requestParameters.productId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a product by id.
   */
  async getOrganizationProduct(
    requestParameters: OrganizationProductsApiGetOrganizationProductRequest,
    initOverrides?: RequestInit,
  ): Promise<ProductResponseBody> {
    const response = await this.getOrganizationProductRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all products of organization.
   */
  async getOrganizationProductsRaw(
    requestParameters: OrganizationProductsApiGetOrganizationProductsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PaginatedProductsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationProducts.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/products`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedProductsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all products of organization.
   */
  async getOrganizationProducts(
    requestParameters: OrganizationProductsApiGetOrganizationProductsRequest,
    initOverrides?: RequestInit,
  ): Promise<PaginatedProductsResponseBody> {
    const response = await this.getOrganizationProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update a product by id.
   */
  async updateOrganizationProductRaw(
    requestParameters: OrganizationProductsApiUpdateOrganizationProductRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ProductResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateOrganizationProduct.',
      );
    }

    if (requestParameters.productId === null || requestParameters.productId === undefined) {
      throw new runtime.RequiredError(
        'productId',
        'Required parameter requestParameters.productId was null or undefined when calling updateOrganizationProduct.',
      );
    }

    if (
      requestParameters.organizationProductRequestBody === null ||
      requestParameters.organizationProductRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationProductRequestBody',
        'Required parameter requestParameters.organizationProductRequestBody was null or undefined when calling updateOrganizationProduct.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/products/{product_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'product_id'}}`, encodeURIComponent(String(requestParameters.productId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationProductRequestBodyToJSON(requestParameters.organizationProductRequestBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductResponseBodyFromJSON(jsonValue));
  }

  /**
   * Update a product by id.
   */
  async updateOrganizationProduct(
    requestParameters: OrganizationProductsApiUpdateOrganizationProductRequest,
    initOverrides?: RequestInit,
  ): Promise<ProductResponseBody> {
    const response = await this.updateOrganizationProductRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
