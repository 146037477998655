import React from 'react';
import { Assignment, AssignmentPutBody } from '../../../api';
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { Delete } from '../../../components/icons';
import { Add } from '@mui/icons-material';
import { assignmentDescription, assignmentSpecificId } from '../../../validation';
import { DenseTableCell, EmptyTableCell, HeaderTableCell, StyledButton } from './StyledComponents';
import { maxBy } from 'lodash';

interface AssignmentsTableProps {
  allAssignments: Assignment[];
  assignments: AssignmentPutBody[];
  onAssignmentsUpdate?: (assignments: AssignmentPutBody[]) => void;
}

const getDisallowedAssignmentSpecificIds = (allAssignments: Assignment[], assignments: AssignmentPutBody[]) => {
  const newDuplicateAssignments: number[] = assignments
    .map((assignment) => assignment.assignment_specific_id)
    .filter((e, i, a) => a.indexOf(e) !== i);
  const disallowedAssignmentSpecificIds = allAssignments
    .filter(
      (oldAssignment) =>
        oldAssignment.assignment_specific_id === assignments[assignments.length - 1].assignment_specific_id &&
        assignments[assignments.length - 1].id !== oldAssignment.id,
    )
    .map((assignment) => assignment.assignment_specific_id);
  return disallowedAssignmentSpecificIds.concat(newDuplicateAssignments);
};

export const validateAssignments = (assignments: AssignmentPutBody[], allAssignments: Assignment[]): boolean => {
  for (const assignment of assignments) {
    const isDisabled = Boolean(
      assignmentDescription.validate(assignment.description).error ||
        assignmentSpecificId
          .disallow(...getDisallowedAssignmentSpecificIds(allAssignments, assignments))
          .validate(assignment.assignment_specific_id).error,
    );
    if (isDisabled) {
      return isDisabled;
    }
  }
  return false;
};

export const AssignmentsTable: React.FC<AssignmentsTableProps> = ({
  allAssignments,
  assignments,
  onAssignmentsUpdate,
}) => {
  return (
    <FieldSet>
      <legend>Työtehtävät</legend>
      <Table className="assignments_table">
        <TableHead>
          <TableRow>
            <HeaderTableCell>Tunnus</HeaderTableCell>
            <HeaderTableCell>Kuvaus</HeaderTableCell>
            {/* Empty cell for actionbuttons */}
            <HeaderTableCell></HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignments.length === 0 ? (
            <TableRow>
              <EmptyTableCell colSpan={3}>Ei työtehtäviä</EmptyTableCell>
            </TableRow>
          ) : (
            assignments
              .sort((a, b) => {
                return a.id === undefined ? 1 : b.id === undefined ? -1 : (a.id || -1) > (b.id || -1) ? 1 : -1;
              })
              .map((assignment, index) => {
                return (
                  <TableRow key={index}>
                    <DenseTableCell>
                      <FormControl
                        error={Boolean(
                          assignmentSpecificId
                            .disallow(...getDisallowedAssignmentSpecificIds(allAssignments, assignments))
                            .validate(assignment.assignment_specific_id).error,
                        )}
                      >
                        <Input
                          id="assignment_assignment_specific_id"
                          name="assignment_assignment_specific_id"
                          type="number"
                          value={assignment.assignment_specific_id ?? ''}
                          onChange={(e) => {
                            const newArr = [...assignments];
                            newArr[index].assignment_specific_id =
                              e.target.value !== '' ? Number(e.target.value) : ('' as any);
                            onAssignmentsUpdate && onAssignmentsUpdate(newArr);
                          }}
                        />
                        {assignmentSpecificId
                          .disallow(...getDisallowedAssignmentSpecificIds(allAssignments, assignments))
                          .validate(assignment.assignment_specific_id).error?.message ? (
                          <FormHelperText>
                            {
                              assignmentSpecificId
                                .disallow(...getDisallowedAssignmentSpecificIds(allAssignments, assignments))
                                .validate(assignment.assignment_specific_id).error?.message
                            }
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </DenseTableCell>
                    <DenseTableCell>
                      <TextField
                        error={Boolean(assignmentDescription.validate(assignment.description).error)}
                        helperText={assignmentDescription.validate(assignment.description).error?.message}
                        type="text"
                        name="assignment_name"
                        required={true}
                        onChange={(e) => {
                          const newArr = [...assignments];
                          newArr[index].description = e.target.value;
                          onAssignmentsUpdate && onAssignmentsUpdate(newArr);
                        }}
                        value={assignment.description ?? ''}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      {!assignment.id ? (
                        <IconButton
                          className="delete_assignment_button"
                          onClick={() => {
                            const newArr = [...assignments];
                            newArr.splice(index, 1);
                            onAssignmentsUpdate && onAssignmentsUpdate(newArr);
                          }}
                          size="large"
                        >
                          <Delete />
                        </IconButton>
                      ) : null}
                    </DenseTableCell>
                  </TableRow>
                );
              })
          )}
        </TableBody>
      </Table>
      <StyledButton
        className="add_assignment_button"
        disabled={validateAssignments(assignments, allAssignments)}
        onClick={() => {
          const newArr = [...assignments];
          let nextAvailableIdFromAssignments =
            (maxBy(assignments, 'assignment_specific_id')?.assignment_specific_id ?? 0) + 1;
          while (
            allAssignments.some((assignment) => assignment.assignment_specific_id === nextAvailableIdFromAssignments)
          ) {
            nextAvailableIdFromAssignments += 1;
          }
          const nextAvailableIdFromAllAssignments =
            (maxBy(allAssignments, 'assignment_specific_id')?.assignment_specific_id ?? 0) + 1;
          const nextAvailableIdFromAllAssignmentsRoundedToTen = Math.ceil(nextAvailableIdFromAllAssignments / 10) * 10;
          newArr.push({
            assignment_specific_id:
              assignments.length === 0 && allAssignments.length !== 0
                ? nextAvailableIdFromAllAssignmentsRoundedToTen
                : nextAvailableIdFromAssignments,
            description: '',
          });
          onAssignmentsUpdate && onAssignmentsUpdate(newArr);
        }}
        startIcon={<Add />}
      >
        Lisää Työtehtävä
      </StyledButton>
    </FieldSet>
  );
};

export default AssignmentsTable;
