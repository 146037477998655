import { Dialog, DialogContent, DialogTitle, styled, TextField } from '@mui/material';
import theme from '../../../theme';
import React, { useRef, useState } from 'react';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { ShipmentsApiPostShipmentPhotoRequest, User } from '../../../../../backend/src/common/api';
import { api } from '../../../api';
import { SaveButton } from '../../../components/SaveButton';
import { canAccessCoordination, isPwaOrDriverOnly } from '../../../utils';

interface ShipmentAddFileDialogProps {
  open: boolean;
  shipmentId: number;
  loadId?: number | null;
  currentUser: User | undefined;
  onSuccess: () => void;
  onClose: () => void;
  onLoading?: () => void;
  onLoadingDone?: () => void;
  showMessage: (message: string) => void;
}

const StyledFileInput = styled('input')(() => ({
  width: '100%',
}));

export const ShipmentAddFileDialog: React.FC<ShipmentAddFileDialogProps> = ({
  open,
  shipmentId,
  loadId,
  currentUser,
  onSuccess,
  onClose,
  onLoading,
  onLoadingDone,
  showMessage,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileDescriptionRef = useRef<HTMLInputElement | null>(null);
  const closeDialog = () => {
    setFile(null);
    setDescription(null);
    onClose();
  };

  const uploadFile = async () => {
    try {
      onLoading && onLoading();
      if (file === null) {
        return;
      }
      const request: ShipmentsApiPostShipmentPhotoRequest = {
        shipmentId: shipmentId,
        timestamp: new Date(),
        description: description || file.name || '',
        photo: file,
      };
      let postShipmentPhotoApiCall;

      if (canAccessCoordination(currentUser)) {
        postShipmentPhotoApiCall = api.shipments.postShipmentPhoto(request);
      } else if (isPwaOrDriverOnly(currentUser) && currentUser?.username && loadId) {
        postShipmentPhotoApiCall = api.drivers.postDriverLoadShipmentPhotos({
          ...request,
          username: currentUser?.username,
          loadId: loadId,
        });
      } else if (currentUser?.organization_id) {
        postShipmentPhotoApiCall = api.organizationShipments.postOrganizationShipmentPhoto({
          ...request,
          organizationId: currentUser?.organization_id,
        });
      } else {
        throw new Error('Organization id not found');
      }
      await postShipmentPhotoApiCall;
      showMessage('Tiedosto tallennettu!');
      onSuccess();
      closeDialog();
    } catch (err) {
      showMessage('Tiedoston tallennus epäonnistui. Yritä uudelleen');
      console.error(err);
    } finally {
      onLoadingDone && onLoadingDone();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => closeDialog()}
      style={{
        margin: theme.spacing(2),
      }}
      maxWidth="xl"
    >
      <DialogTitle>Lisää uusi tiedosto</DialogTitle>
      <DialogContent>
        <FieldSet>
          <StyledFileInput
            id={'add-file-input'}
            type={'file'}
            accept=".jpg,.jpeg,.png,.gif,.pdf,.docx,.xlsx,.pptx"
            ref={fileInputRef}
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                setFile(e.target.files[0]);
              } else if (file) {
                setFile(null);
              }
            }}
          />
          <TextField
            id={'add-file-description'}
            helperText={'Lisättävän tiedoston kuvaus'}
            label={'Kuvaus'}
            ref={fileDescriptionRef}
            onChange={(e) => setDescription(e.target.value)}
          />
          <SaveButton
            sx={{ width: '100%' }}
            disabled={!file}
            id="add-shipment-file-button"
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event.stopPropagation();
              uploadFile().then(() => {
                if (file === null && fileInputRef?.current?.value) {
                  fileInputRef.current.value = '';
                  setFile(null);
                  if (fileDescriptionRef?.current?.value) {
                    fileDescriptionRef.current.value = '';
                    setDescription('');
                  }
                }
              });
            }}
          >
            Tallenna tiedosto
          </SaveButton>
        </FieldSet>
      </DialogContent>
    </Dialog>
  );
};
