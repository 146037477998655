import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { Dispatch, useEffect, useState } from 'react';
import { Add, Cancel, Close, Delete, Edit, Save } from '@mui/icons-material';
import { Action, ClientWithGroup, State, createEmptyLoads, load } from '../coordination.state';
import { groupBy, orderBy, uniqBy } from 'lodash';
import { groupName } from '../../../validation';
import { getselectedClients } from '../DriverCoordination';
import { FilterCheckbox } from '../../../components/FilterCheckbox';
import { EmptyLoadModal } from './EmptyLoadModal';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import theme from '../../../theme';
import { CarrierUser } from '../../../reducers/authReducer';

interface SettingsModalProps {
  carrierUser: CarrierUser;
  open: boolean;
  onClose: () => void;
  state: State;
  dispatch: Dispatch<Action>;
}

interface SharedProps {
  selectedClients: ClientWithGroup[];
  setSelectedClients: (client: ClientWithGroup[]) => void;
  isEditing: string | null;
}

interface StateAndDispatchProps {
  state: State;
  dispatch: Dispatch<Action>;
}

interface DialogHeaderProps extends SharedProps, StateAndDispatchProps {}

const setOrganizationGroups = (
  group: string,
  state: State,
  selectedClients: ClientWithGroup[],
  setSelectedClients: (client: ClientWithGroup[]) => void,
  dispatch: Dispatch<Action>,
) => {
  const currentOrgGroups = state.viewSettings.organizationGroups.filter(
    (org) => !selectedClients.map((o) => o.id).includes(org.id),
  );
  const orgGroups = group
    ? [
        ...currentOrgGroups,
        ...selectedClients.map((o) => {
          return { ...o, group: group };
        }),
      ]
    : currentOrgGroups;
  dispatch({
    type: 'SET_VIEW_SETTINGS',
    payload: {
      ...state.viewSettings,
      organizationGroups: orgGroups as ClientWithGroup[],
    },
  });
  setSelectedClients([]);
};

const DialogHeader: React.FC<DialogHeaderProps> = ({
  isEditing,
  selectedClients,
  setSelectedClients,
  dispatch,
  state,
}) => {
  return (
    <>
      <Typography sx={{ marginBottom: '0.5rem' }} variant="h3">
        Asiakasryhmät
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          data-cy="create-new-group-button"
          disabled={isEditing !== null || selectedClients.length === 0}
          sx={{ width: '100%', marginTop: '0.5rem', marginBottom: '0.5rem' }}
          onClick={() => {
            let groupName = '';
            if (selectedClients.length === 1) {
              groupName = selectedClients[0].name;
            } else {
              const next = uniqBy(state.viewSettings.organizationGroups, 'group').length + 1;
              groupName = `Mukautettu ryhmä ${next}`;
            }
            setOrganizationGroups(groupName, state, selectedClients, setSelectedClients, dispatch);
          }}
        >
          Luo valituista uusi ryhmä
        </Button>
      </Box>
    </>
  );
};

interface GroupHeaderProps extends SharedProps, StateAndDispatchProps {
  index: number;
  group: string;
  setIsEditing: (number: string | null) => void;
  isDeleting: string | null;
  setIsDeleting: (number: string | null) => void;
}

const GroupHeader: React.FC<GroupHeaderProps> = ({
  group,
  index,
  isEditing,
  setIsEditing,
  isDeleting,
  setIsDeleting,
  selectedClients,
  setSelectedClients,
  dispatch,
  state,
}) => {
  const [value, setValue] = useState(group);
  const invalidGroupNames = uniqBy(state.viewSettings.organizationGroups, 'group')
    .map((org) => org.group)
    .concat('Muut')
    .filter((g) => g !== group);
  const error = groupName.disallow(...invalidGroupNames).validate(value).error;

  useEffect(() => {
    setValue(group);
    if (!value) {
      dispatch({
        type: 'SET_VIEW_SETTINGS',
        payload: {
          ...state.viewSettings,
          selectedClients: getselectedClients(state, group),
        },
      });
    }
  }, [group]);

  return (
    <Box key={index}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isEditing === group ? (
          <TextField
            data-cy="group-textfield"
            name="group_name"
            error={Boolean(error)}
            helperText={error?.message}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          />
        ) : (
          <Typography variant="h4">{group ? group : 'Muut'}</Typography>
        )}
        {isEditing === group ? (
          <>
            <IconButton
              data-cy={`${group}-confirm-save-button`}
              color="success"
              disabled={Boolean(error)}
              onClick={() => {
                setOrganizationGroups(
                  value,
                  state,
                  state.viewSettings.organizationGroups.filter((org) => org.group === group),
                  setSelectedClients,
                  dispatch,
                );
                setIsEditing(null);
              }}
            >
              <Save />
            </IconButton>
            <IconButton
              onClick={() => {
                setValue(group);
                setIsEditing(null);
              }}
            >
              <Cancel />
            </IconButton>
          </>
        ) : isDeleting === group ? (
          <>
            <IconButton
              data-cy={`${group}-confirm-delete-button`}
              color="error"
              disabled={Boolean(error)}
              onClick={() => {
                setOrganizationGroups(
                  '',
                  state,
                  state.viewSettings.organizationGroups.filter((org) => org.group === group),
                  setSelectedClients,
                  dispatch,
                );
                setIsDeleting(null);
              }}
            >
              <Delete />
            </IconButton>
            <IconButton
              onClick={() => {
                setValue(group);
                setIsDeleting(null);
              }}
            >
              <Cancel />
            </IconButton>
          </>
        ) : group ? (
          <>
            <IconButton
              data-cy={`${group}-edit-button`}
              disabled={(isEditing !== null && isEditing !== group) || !group}
              onClick={() => {
                setIsEditing(group ?? null);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              data-cy={`${group}-delete-button`}
              disabled={(isEditing !== null && isEditing !== group) || !group}
              onClick={() => {
                setIsDeleting(group ?? null);
              }}
            >
              <Delete />
            </IconButton>
          </>
        ) : null}
        <IconButton
          color="success"
          disabled={isEditing !== null || selectedClients.length === 0}
          onClick={() => {
            setOrganizationGroups(group, state, selectedClients, setSelectedClients, dispatch);
          }}
        >
          <Add />
        </IconButton>
      </Box>
    </Box>
  );
};

interface OrganizationListItemProps extends SharedProps {
  client: ClientWithGroup;
}

const OrganizationListItem: React.FC<OrganizationListItemProps> = ({
  client,
  selectedClients,
  setSelectedClients,
  isEditing,
}) => {
  return (
    <ListItem key={client.name} disablePadding>
      <ListItemButton
        data-cy={`${client.id}-list-item-button`}
        disabled={Boolean(isEditing)}
        sx={{ height: '1.5rem' }}
        role={undefined}
        onClick={() => {
          if (selectedClients.find((org) => org.id === client.id)) {
            setSelectedClients([...selectedClients.filter((org) => org.id !== client.id)]);
          } else {
            setSelectedClients([...selectedClients, client]);
          }
        }}
        dense
      >
        <ListItemIcon>
          <Checkbox
            size="small"
            edge="start"
            checked={Boolean(selectedClients.find((org) => org.id === client.id))}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': client.name }}
          />
        </ListItemIcon>
        <ListItemText id={client.name} primary={client.name} />
      </ListItemButton>
    </ListItem>
  );
};

export const DriverSettingsModal = ({ carrierUser, open, onClose, state, dispatch }: SettingsModalProps) => {
  const [selectedClients, setSelectedClients] = useState<ClientWithGroup[]>([]);
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState<string | null>(null);
  const restOfOrganizations = state.clients
    .filter((org) => org.id && !state.viewSettings.organizationGroups.map((o) => o.id).includes(org.id))
    .map((o) => {
      return { ...o, group: '' };
    });
  const [emptyLoadDate, setEmptyLoadDate] = useState<DateTime | null>(DateTime.local());
  const [confirmCreateNewEmptyLoads, setConfirmCreateNewEmptyLoads] = React.useState(false);
  const [openEmptyLoads, setOpenEmptyLoads] = React.useState(false);
  const handleOpenEmptyLoads = () => setOpenEmptyLoads(true);
  const handleCloseEmptyLoads = () => setOpenEmptyLoads(false);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
      open={open}
      onClose={() => {
        setSelectedClients([]);
        setIsDeleting(null);
        setIsEditing(null);
        onClose();
      }}
    >
      <DialogTitle textAlign="center">Asetukset</DialogTitle>
      <IconButton
        sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
        data-cy="close-settings"
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ flex: 1 }}>
          <DialogHeader
            state={state}
            dispatch={dispatch}
            setSelectedClients={setSelectedClients}
            selectedClients={selectedClients}
            isEditing={isEditing}
          />
          {Object.entries(
            groupBy(orderBy(state.viewSettings.organizationGroups, 'group').concat(restOfOrganizations), 'group'),
          ).map(([group, client], index) => {
            return (
              <Box key={index}>
                <GroupHeader
                  group={group}
                  setIsEditing={setIsEditing}
                  isEditing={isEditing}
                  setIsDeleting={setIsDeleting}
                  isDeleting={isDeleting}
                  state={state}
                  index={index}
                  selectedClients={selectedClients}
                  setSelectedClients={setSelectedClients}
                  dispatch={dispatch}
                />
                <List key={Math.random()} dense>
                  {client.map((client) => {
                    return (
                      <OrganizationListItem
                        key={client.name}
                        client={client}
                        selectedClients={selectedClients}
                        setSelectedClients={setSelectedClients}
                        isEditing={isEditing}
                      />
                    );
                  })}
                </List>
              </Box>
            );
          })}
        </Box>
        <Divider orientation="vertical" flexItem sx={{ marginLeft: '2rem', marginRight: '2rem' }} />
        <Box>
          <Typography sx={{ marginBottom: '0.5rem' }} variant="h3">
            Suodattimet
          </Typography>
          <FilterCheckbox
            disabled={state.isLoading}
            label={'Piilota toimitetut toimitukset'}
            checked={state.viewSettings.onlyUndeliveredShipments}
            onChange={(_e) => {
              dispatch({
                type: 'SET_VIEW_SETTINGS',
                payload: {
                  ...state.viewSettings,
                  onlyUndeliveredShipments: !state.viewSettings.onlyUndeliveredShipments,
                },
              });
            }}
            name="only_undelivered_shipments"
            className="only-undelivered-shipments"
            tooltip="Näytä vain toimitukset, joita ei ole vielä toimitettu."
          />
        </Box>
        <Divider orientation="vertical" flexItem sx={{ marginLeft: '2rem', marginRight: '2rem' }} />
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ marginBottom: '0.5rem' }} variant="h3">
            Tyhjät kuormat
          </Typography>
          <DatePicker sx={{ width: '100%' }} value={emptyLoadDate} onChange={(date) => setEmptyLoadDate(date)} />
          {confirmCreateNewEmptyLoads ? (
            <>
              <Button
                color="error"
                data-cy="cancel-create-new-empty-loads-button"
                disabled={state.viewSettings.emptyLoads.length < 1 || !emptyLoadDate || !emptyLoadDate.isValid}
                sx={{ width: '100%', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                onClick={() => setConfirmCreateNewEmptyLoads(false)}
              >
                Peruuta
              </Button>
              <Button
                color="success"
                data-cy="confirm-create-new-empty-loads-button"
                disabled={state.viewSettings.emptyLoads.length < 1 || !emptyLoadDate || !emptyLoadDate.isValid}
                sx={{ width: '100%', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                onClick={async () => {
                  if (!emptyLoadDate) {
                    return;
                  }
                  await createEmptyLoads(carrierUser, state, dispatch, emptyLoadDate);
                  setConfirmCreateNewEmptyLoads(false);
                  const abortController = new AbortController();
                  await load(carrierUser, state, dispatch, abortController);
                  return () => abortController.abort();
                }}
              >
                Luo tyhjät kuormat
              </Button>
            </>
          ) : (
            <Button
              data-cy="create-new-empty-loads-button"
              disabled={state.viewSettings.emptyLoads.length < 1 || !emptyLoadDate || !emptyLoadDate.isValid}
              sx={{ width: '100%', marginTop: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => setConfirmCreateNewEmptyLoads(true)}
            >
              Luo tyhjät kuormat
            </Button>
          )}
          <Divider />
          <Button
            data-cy="create-new-empty-load-button"
            sx={{ width: '100%', marginTop: '0.5rem', marginBottom: '0.5rem' }}
            onClick={handleOpenEmptyLoads}
          >
            Luo uusi tyhjä kuorma
          </Button>
          <List data-cy="empty-loads-list">
            {Object.entries(groupBy(orderBy(state.viewSettings.emptyLoads, 'organization_id'), 'organization_id')).map(
              ([org, loads], index) => {
                const organizationName = state.clients.find((o) => o.id === org)?.name;
                return (
                  <Box key={index}>
                    <Typography variant="h4">{organizationName ?? org}</Typography>

                    <List dense>
                      {loads.map((load, index) => {
                        const car = state.cars.find((d) => d.id === load.car_id);
                        const carLicencePlate = car ? car.licence_plate : '';

                        const driver = state.drivers.find((d) => d.id === load.driver_id);
                        const driverName = driver ? ` - ${driver.last_name} ${driver.first_name}` : '';

                        const trailer = state.cars.find((d) => d.id === load.trailer_id);
                        const trailerLicencePlate = trailer ? ` - ${trailer.licence_plate}` : '';

                        return (
                          <ListItem key={index} sx={{ whiteSpace: 'nowrap' }}>
                            {carLicencePlate}
                            {driverName}
                            {trailerLicencePlate}
                            <IconButton
                              data-cy="empty-load-delete-button"
                              color="error"
                              onClick={() => {
                                dispatch({
                                  type: 'SET_VIEW_SETTINGS',
                                  payload: {
                                    ...state.viewSettings,
                                    emptyLoads: state.viewSettings.emptyLoads.filter((l) => l !== load),
                                  },
                                });
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                );
              },
            )}
          </List>
          <EmptyLoadModal
            open={openEmptyLoads}
            onClose={handleCloseEmptyLoads}
            state={state}
            dispatch={dispatch}
            cars={state.cars}
            drivers={state.drivers}
            clients={state.clients}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
