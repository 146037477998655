/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Driver, DriverFromJSON, DriverFromJSONTyped, DriverToJSON } from './Driver';

/**
 * A group of drivers.
 * @export
 * @interface DriverGroup
 */
export interface DriverGroup {
  /**
   *
   * @type {string}
   * @memberof DriverGroup
   */
  readonly id: string;
  /**
   *
   * @type {string}
   * @memberof DriverGroup
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DriverGroup
   */
  description?: string;
  /**
   *
   * @type {Date}
   * @memberof DriverGroup
   */
  updated_at?: Date | null;
  /**
   *
   * @type {string}
   * @memberof DriverGroup
   */
  updated_by?: string;
  /**
   *
   * @type {Date}
   * @memberof DriverGroup
   */
  created_at?: Date | null;
  /**
   *
   * @type {string}
   * @memberof DriverGroup
   */
  created_by?: string;
  /**
   *
   * @type {Array<Driver>}
   * @memberof DriverGroup
   */
  drivers: Array<Driver> | null;
}

export function DriverGroupFromJSON(json: any): DriverGroup {
  return DriverGroupFromJSONTyped(json, false);
}

export function DriverGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    updated_at: !exists(json, 'updated_at')
      ? undefined
      : json['updated_at'] === null
        ? null
        : new Date(json['updated_at']),
    updated_by: !exists(json, 'updated_by') ? undefined : json['updated_by'],
    created_at: !exists(json, 'created_at')
      ? undefined
      : json['created_at'] === null
        ? null
        : new Date(json['created_at']),
    created_by: !exists(json, 'created_by') ? undefined : json['created_by'],
    drivers: json['drivers'] === null ? null : (json['drivers'] as Array<any>).map(DriverFromJSON),
  };
}

export function DriverGroupToJSON(value?: DriverGroup | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    updated_at:
      value.updated_at === undefined ? undefined : value.updated_at === null ? null : value.updated_at.toISOString(),
    updated_by: value.updated_by,
    created_at:
      value.created_at === undefined ? undefined : value.created_at === null ? null : value.created_at.toISOString(),
    created_by: value.created_by,
    drivers: value.drivers === null ? null : (value.drivers as Array<any>).map(DriverToJSON),
  };
}
