import { PaletteColor, SimplePaletteColorOptions, createTheme } from '@mui/material/styles';
import { fiFI } from '@mui/material/locale';

declare module '@mui/material/styles' {
  interface Palette {
    highlight: Palette['primary'];
    table: Palette['primary'];
    pickedUp: PaletteColor['main'];
    readyForPickup: PaletteColor['main'];
    notInStock: PaletteColor['main'];
    delivered: PaletteColor['main'];
    cancelled: PaletteColor['main'];
    holiday: PaletteColor['main'];
    dayOff: PaletteColor['main'];
    sickLeave: PaletteColor['main'];
  }
  interface PaletteOptions {
    highlight: PaletteOptions['primary'];
    table: PaletteOptions['primary'];
    pickedUp: SimplePaletteColorOptions['main'];
    readyForPickup: SimplePaletteColorOptions['main'];
    notInStock: SimplePaletteColorOptions['main'];
    delivered: SimplePaletteColorOptions['main'];
    cancelled: SimplePaletteColorOptions['main'];
    holiday: SimplePaletteColorOptions['main'];
    dayOff: SimplePaletteColorOptions['main'];
    sickLeave: SimplePaletteColorOptions['main'];
  }
}

export default createTheme(
  {
    components: {
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiButton: {
        defaultProps: { variant: 'contained', color: 'primary', disableElevation: true },
        variants: [
          {
            props: { variant: 'text' },
            style: {
              color: '#000000',
            },
          },
          {
            props: { color: 'success' },
            style: {
              color: '#FFFFFF',
            },
          },
        ],
      },
      MuiCard: {
        defaultProps: { variant: 'outlined' },
      },
      MuiInputAdornment: {
        styleOverrides: { root: { marginRight: '0.25rem' } },
      },
      MuiTooltip: {
        defaultProps: { disableInteractive: true, arrow: true },
        styleOverrides: {
          arrow: {
            color: '#424240',
          },
          tooltip: {
            padding: '1rem',
            backgroundColor: '#424240',
            fontSize: 14,
            minWidth: 'min-content',
          },
        },
      },
      MuiAlert: {
        styleOverrides: { root: { paddingTop: 0, paddingBottom: 0, marginBottom: '0.5rem' } },
      },
      MuiPopover: {
        //Helps Mui popover positioning when using mobile devices
        defaultProps: {
          autoFocus: false,
        },
      },
      MuiMenu: {
        //Helps Mui menu and menuitem positioning when using mobile devices
        defaultProps: {
          autoFocus: false,
          disableAutoFocusItem: true,
          disableEnforceFocus: true,
          disableAutoFocus: true,
        },
      },
    },
    typography: {
      h1: {
        fontSize: '2rem',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '1.75rem',
      },
      h3: {
        fontSize: '1.5rem',
      },
      h4: {
        fontSize: '1.25rem',
      },
      h5: {
        fontSize: '1rem',
      },
    },
    palette: {
      background: {
        default: '#F8F8F8',
      },
      primary: {
        main: '#148CAF',
        light: '#E5F6FD',
      },
      secondary: {
        main: '#115E83',
        light: '#E0E0E0',
      },
      highlight: {
        main: '#58B6D1',
        light: '#D0ECF5',
        dark: '#CCCCCC',
      },
      table: {
        main: '#E6E6E6',
        light: '#FAFAFA',
        dark: '#4D4D4D',
      },
      pickedUp: '#FFFF00',
      readyForPickup: '#E7E7E7',
      notInStock: '#222222',
      delivered: '#D2FFC7',
      cancelled: '#EF5350',
      holiday: '#75D1FF',
      dayOff: '#D2FFC7',
      sickLeave: '#FFFF00',
      text: {
        disabled: 'rgba(0, 0, 0, 0.6)',
      },
    },
  },
  fiFI,
);
