import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  styled,
} from '@mui/material';
import { Add, Refresh } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CellValue, Column, ColumnInstance, Row, TableRowProps } from 'react-table';
import { api, getAllPages, Organization, Shipment, ShipmentStateEnum } from '../../../api';
import { Loading } from '../../../components/Loading';
import { ReactTable } from '../../../components/ReactTable';
import { ShipmentRowSubRowAsync } from '../../../components/ShipmentRowSubRow';
import { DateColumnFilter, SelectColumnFilter } from '../../../components/TableFilters';
import theme from '../../../theme';
import { DeliveryWindow } from '../../../components/DeliveryWindow';

interface ShipmentsModalProps {
  open: boolean;
  onClose: (shipments: Shipment[]) => void;
  organization: Organization;
  shipmentBlacklist: Shipment[];
}

export const StyledDialog = styled(Dialog)(() => ({
  margin: theme.spacing(2),
}));

export const ShipmentsModal: React.FC<ShipmentsModalProps> = ({ open, onClose, organization, shipmentBlacklist }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [selectedShipments, setSelectedShipments] = useState<Record<Shipment['id'], boolean>>([]);
  const load = async () => {
    setIsLoading(true);
    const shipmentsResponse = await getAllPages(api.search.searchShipments.bind(api.search), {
      searchShipmentsPostBody: {
        organization_id: organization.id,
        load_id: null,
        state: ShipmentStateEnum.Noudettavissa,
      },
      filterOutOtherCarriers: true,
    });
    setShipments(shipmentsResponse);
    setIsLoading(false);
  };
  useEffect(() => {
    load();
  }, [organization]);
  const renderRowSubComponent = React.useCallback(
    (row: Row, rowProps: TableRowProps, visibleColumns: ColumnInstance[]) => (
      <ShipmentRowSubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
    ),
    [],
  );
  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        Header: 'Valitse',
        disableFilters: true,
        Cell: ({ row, toggleRowSelected }) => {
          return (
            <Checkbox
              size="small"
              checked={(row as any).isSelected}
              onChange={() => {
                toggleRowSelected(row.id);
              }}
              onClick={(event) => event.stopPropagation()} // Prevents SubRows from opening
              name="selected"
              color="primary"
            />
          );
        },
      },
      {
        id: 'id',
        Header: 'Id',
        accessor: 'id',
        Cell: ({ row }) => (
          <Link component={RouterLink} to={{ pathname: `/shipments/${row.values.id}` }}>
            {row.values.id}
          </Link>
        ),
      },
      {
        id: 'reference_number',
        Header: 'Asiakasviite',
        accessor: 'reference_number',
      },
      {
        Header: 'Tila',
        accessor: 'state',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Noutopaikka',
        accessor: 'pickup_name',
      },
      {
        Header: 'Toimitus',
        columns: [
          {
            Header: 'Nimi',
            accessor: 'delivery_name',
          },
          {
            Header: 'Osoite',
            accessor: 'delivery_address',
          },
          {
            Header: 'Postinumero',
            accessor: 'delivery_postal_code',
          },
          {
            Header: 'Kaupunki',
            accessor: 'delivery_city',
          },
          {
            Header: 'Toimitusaika',
            id: 'agreed_delivery_window',
            accessor: 'agreed_delivery_window_ends_at',
            Cell: (shipment: CellValue) => {
              return (
                <DeliveryWindow
                  startsAt={shipment.cell.row.original.agreed_delivery_window_starts_at}
                  endsAt={shipment.cell.row.original.agreed_delivery_window_ends_at}
                />
              );
            },
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
        ],
      },
      {
        Header: 'Paino (kg)',
        accessor: 'weight_kg',
      },
      {
        Header: 'Tilavuus (m3)',
        accessor: 'volume_m3',
      },
      {
        Header: 'Lavametrit',
        accessor: 'length_ldm',
      },
      {
        Header: 'Etäisyys',
        accessor: 'legacy_etaisyys_field',
      },
      {
        Header: 'Lisätietoja',
        accessor: 'note',
      },
    ],
    [],
  );
  const data = React.useMemo(
    () => shipments.filter((shipment) => !shipmentBlacklist.some((blShipment) => blShipment.id === shipment.id)),
    [shipments, shipmentBlacklist],
  );
  return (
    <StyledDialog
      className="shipments-modal"
      aria-labelledby="shipments-modal-title"
      open={open}
      onClose={() => onClose([])}
      closeAfterTransition
      fullScreen
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle id="shipments-modal-title">Lisää toimituksia</DialogTitle>
        <DialogActions>
          <IconButton onClick={() => load()}>
            <Refresh />
          </IconButton>
          <Button
            className="modal-ok-button"
            onClick={async () => {
              onClose(shipments.filter((shipment) => selectedShipments[shipment.id]));
            }}
            disabled={isLoading || !Object.values(selectedShipments).some((isSelected) => isSelected)}
            startIcon={<Add />}
          >
            Lisää valitut
          </Button>
          <Button color="error" onClick={() => onClose([])} disabled={isLoading}>
            Peruuta
          </Button>
        </DialogActions>
      </Box>
      <Loading isLoading={isLoading} />
      <DialogContent>
        <form noValidate autoComplete="off">
          <Loading isLoading={isLoading} />
          <ReactTable
            columns={columns}
            data={data}
            header={`${organization.name} kuormattomat toimitukset`}
            isLoading={isLoading}
            selectedRows={selectedShipments}
            setSelectedRows={setSelectedShipments}
            emptyText="Ei näytettäviä toimituksia."
            initialState={{
              sortBy: [
                {
                  id: 'agreed_delivery_window',
                  desc: false,
                },
              ],
            }}
            renderRowSubComponent={renderRowSubComponent}
          />
        </form>
      </DialogContent>
    </StyledDialog>
  );
};
