import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import React, { Dispatch } from 'react';
import { Action, DriverWithWorkHoursAndTimeOffs, State, updateFieldValue } from './workHours.state';
import { StringTimePicker, getTimeStringOrNull } from '../../components/DateAndTimePickers/StandardTimePicker';
import { StandardDatePicker, getJSDateOrNull } from '../../components/DateAndTimePickers/StandardDatePicker';
import { OrganizationPicker } from '../../components/OrganizationPicker';
import { Assignment, Organization } from '../../api';

interface CreateModalProps {
  onClose: () => void;
  onSubmit: (values: any) => void;
  open: boolean;
  fields: State['fields'];
  dispatch: Dispatch<Action>;
  organizations: Organization[];
  assignments: Assignment[];
  drivers: DriverWithWorkHoursAndTimeOffs[];
  isValid: boolean;
}

export const CreateNewWorkHourModal = ({
  open,
  onClose,
  onSubmit,
  fields,
  dispatch,
  organizations,
  assignments,
  drivers,
  isValid,
}: CreateModalProps) => {
  const handleSubmit = () => {
    onSubmit(fields);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Luo uusi työtunti</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            <Autocomplete
              data-cy="employee-number-input"
              fullWidth
              className={'driver-picker'}
              options={drivers.filter((d) => d.employee_number)}
              autoHighlight
              getOptionLabel={(driver) => driver.employee_name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, driver) => <li {...props}>{driver.employee_name}</li>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={fields.employee_number.hasError}
                  helperText={fields.employee_number.feedback}
                  label="Työntekijä"
                  required={fields.employee_number.required}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
              value={drivers.find((driver) => driver.employee_number === fields.employee_number.value) ?? null}
              onChange={(_event, driver) =>
                updateFieldValue('employee_number', driver?.employee_number ?? null, dispatch)
              }
            />
            <StandardDatePicker
              label="Päivämäärä"
              value={fields.date.value}
              slotProps={{
                textField: {
                  required: fields.date.required,
                  id: 'date',
                  name: 'date',
                  error: fields.date.hasError,
                  helperText: fields.date.feedback,
                } as any,
              }}
              onChange={(date) => updateFieldValue('date', getJSDateOrNull(date), dispatch)}
            />
            <StringTimePicker
              data-cy="starts-at-input"
              ampm={false}
              label="Aloitus"
              value={fields.starts_at.value}
              slotProps={{
                textField: {
                  required: fields.starts_at.required,
                  id: 'starts_at',
                  name: 'starts_at',
                  error: fields.starts_at.hasError,
                  helperText: fields.starts_at.feedback,
                },
              }}
              onChange={(time) => {
                updateFieldValue('starts_at', getTimeStringOrNull(time), dispatch);
              }}
            />
            <StringTimePicker
              data-cy="ends-at-input"
              ampm={false}
              label="Lopetus"
              value={fields.ends_at.value}
              slotProps={{
                textField: {
                  required: fields.ends_at.required,
                  id: 'ends_at',
                  name: 'ends_at',
                  error: fields.ends_at.hasError,
                  helperText: fields.ends_at.feedback,
                },
              }}
              onChange={(time) => {
                updateFieldValue('ends_at', getTimeStringOrNull(time), dispatch);
              }}
            />
            <OrganizationPicker
              dataCy="organization-id-input"
              organizations={organizations}
              value={organizations.find((org) => org.id === fields.organization_id.value) ?? null}
              onChange={(org) => {
                if (org?.id !== fields.organization_id.value) {
                  updateFieldValue('assignment_id', null, dispatch);
                }
                updateFieldValue('organization_id', org?.id ?? null, dispatch);
              }}
              required={fields.organization_id.required}
              error={fields.organization_id.hasError}
              helperText={fields.organization_id.feedback}
            />
            <Autocomplete
              data-cy="assignment-id-input"
              fullWidth
              className={'assignment-picker'}
              options={
                assignments.filter(
                  (assignment) => assignment.organization_id === fields.organization_id.value ?? null,
                ) ?? null
              }
              autoHighlight
              getOptionLabel={(assignment) => `${assignment.assignment_specific_id} - ${assignment.description}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, assignment) => (
                <li {...props}>{`${assignment.assignment_specific_id} - ${assignment.description}`}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={fields.assignment_id.hasError}
                  helperText={fields.assignment_id.feedback}
                  label="Työtehtävä"
                  required={fields.assignment_id.required}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
              value={
                assignments.find((assignment) => assignment.assignment_specific_id === fields.assignment_id.value) ??
                null
              }
              onChange={(_event, assignment) =>
                updateFieldValue('assignment_id', assignment?.assignment_specific_id ?? null, dispatch)
              }
            />
            <TextField
              data-cy="note-input"
              id="note"
              label={'Selite'}
              name={'note'}
              helperText={fields.note.feedback}
              error={fields.note.hasError}
              onChange={(event) => updateFieldValue('note', event.target.value, dispatch)}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button data-cy="cancel-create-new-work-hour-button" onClick={onClose}>
          Peruuta
        </Button>
        <Button
          data-cy="confirm-create-new-work-hour-button"
          disabled={!isValid}
          color="secondary"
          onClick={handleSubmit}
          variant="contained"
        >
          Luo uusi työtunti
        </Button>
      </DialogActions>
    </Dialog>
  );
};
