/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Load, LoadFromJSON, LoadFromJSONTyped, LoadToJSON } from './Load';

/**
 *
 * @export
 * @interface LoadResponseBody
 */
export interface LoadResponseBody {
  /**
   *
   * @type {Load}
   * @memberof LoadResponseBody
   */
  data: Load;
}

export function LoadResponseBodyFromJSON(json: any): LoadResponseBody {
  return LoadResponseBodyFromJSONTyped(json, false);
}

export function LoadResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: LoadFromJSON(json['data']),
  };
}

export function LoadResponseBodyToJSON(value?: LoadResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: LoadToJSON(value.data),
  };
}
