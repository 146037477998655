/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { FieldAccess, FieldAccessFromJSON, FieldAccessFromJSONTyped, FieldAccessToJSON } from './FieldAccess';
import { PriceAccess, PriceAccessFromJSON, PriceAccessFromJSONTyped, PriceAccessToJSON } from './PriceAccess';

/**
 *
 * @export
 * @interface ShipmentEditRights
 */
export interface ShipmentEditRights {
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  id: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  organization_id: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  billing_office_organization_specific_id: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  load_id: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  state: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  wait_reason: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  orderer: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  orderer_phone_number: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  job_number: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  other_contract_number: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_office_organization_specific_id: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_name: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_address: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_postal_code: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_phone_number: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_phone_number_secondary: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_city: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_office_organization_specific_id: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_name: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_address: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_postal_code: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_phone_number: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_city: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  note: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  length_ldm: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  weight_kg: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  chargeable_weight_kg: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  volume_m3: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivered_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  order_in_load: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_window_starts_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_window_ends_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  recipient: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  reference_number: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  agreed_delivery_window_starts_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  agreed_delivery_window_ends_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_window_starts_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_window_ends_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  ordered_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  updated_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  updated_by: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  picked_up_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  arrived_to_pickup_location_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  arrived_to_delivery_location_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  hourly_work_hours: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  hourly_work_reason: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  is_express_delivery: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  price: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  legacy_price_basis: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  has_contract_price: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  requires_combination_vehicle: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  billed_at: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  has_additional_hourly_pricing: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  legacy_etaisyys_field: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  longest_shipment_row_side_length_mm: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  customer_distribution_area: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pricing_model: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pricing_failed: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_status_url_identifier: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  is_adr_delivery: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  wait_hours: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_phone_number_secondary: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  custom_pricing_category_id: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  unloaded_with_customer_permission: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  has_distance_been_fixed: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  billing_reference_number: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  requires_hoist: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  is_line_haul_transport: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  price_basis: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  price_basis_str: FieldAccess;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentEditRights
   */
  canSave: boolean;
  /**
   *
   * @type {Date}
   * @memberof ShipmentEditRights
   */
  minimumDeliveryDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof ShipmentEditRights
   */
  minimumPickupTime?: Date;
  /**
   *
   * @type {PriceAccess}
   * @memberof ShipmentEditRights
   */
  priceAccess: PriceAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_time: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  pickup_date: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_time_window_start: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_time_window_end: FieldAccess;
  /**
   *
   * @type {FieldAccess}
   * @memberof ShipmentEditRights
   */
  delivery_date: FieldAccess;
}

export function ShipmentEditRightsFromJSON(json: any): ShipmentEditRights {
  return ShipmentEditRightsFromJSONTyped(json, false);
}

export function ShipmentEditRightsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentEditRights {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: FieldAccessFromJSON(json['id']),
    organization_id: FieldAccessFromJSON(json['organization_id']),
    billing_office_organization_specific_id: FieldAccessFromJSON(json['billing_office_organization_specific_id']),
    load_id: FieldAccessFromJSON(json['load_id']),
    state: FieldAccessFromJSON(json['state']),
    wait_reason: FieldAccessFromJSON(json['wait_reason']),
    orderer: FieldAccessFromJSON(json['orderer']),
    orderer_phone_number: FieldAccessFromJSON(json['orderer_phone_number']),
    job_number: FieldAccessFromJSON(json['job_number']),
    other_contract_number: FieldAccessFromJSON(json['other_contract_number']),
    pickup_office_organization_specific_id: FieldAccessFromJSON(json['pickup_office_organization_specific_id']),
    pickup_name: FieldAccessFromJSON(json['pickup_name']),
    pickup_address: FieldAccessFromJSON(json['pickup_address']),
    pickup_postal_code: FieldAccessFromJSON(json['pickup_postal_code']),
    pickup_phone_number: FieldAccessFromJSON(json['pickup_phone_number']),
    pickup_phone_number_secondary: FieldAccessFromJSON(json['pickup_phone_number_secondary']),
    pickup_city: FieldAccessFromJSON(json['pickup_city']),
    delivery_office_organization_specific_id: FieldAccessFromJSON(json['delivery_office_organization_specific_id']),
    delivery_name: FieldAccessFromJSON(json['delivery_name']),
    delivery_address: FieldAccessFromJSON(json['delivery_address']),
    delivery_postal_code: FieldAccessFromJSON(json['delivery_postal_code']),
    delivery_phone_number: FieldAccessFromJSON(json['delivery_phone_number']),
    delivery_city: FieldAccessFromJSON(json['delivery_city']),
    note: FieldAccessFromJSON(json['note']),
    length_ldm: FieldAccessFromJSON(json['length_ldm']),
    weight_kg: FieldAccessFromJSON(json['weight_kg']),
    chargeable_weight_kg: FieldAccessFromJSON(json['chargeable_weight_kg']),
    volume_m3: FieldAccessFromJSON(json['volume_m3']),
    delivered_at: FieldAccessFromJSON(json['delivered_at']),
    order_in_load: FieldAccessFromJSON(json['order_in_load']),
    delivery_window_starts_at: FieldAccessFromJSON(json['delivery_window_starts_at']),
    delivery_window_ends_at: FieldAccessFromJSON(json['delivery_window_ends_at']),
    recipient: FieldAccessFromJSON(json['recipient']),
    reference_number: FieldAccessFromJSON(json['reference_number']),
    agreed_delivery_window_starts_at: FieldAccessFromJSON(json['agreed_delivery_window_starts_at']),
    agreed_delivery_window_ends_at: FieldAccessFromJSON(json['agreed_delivery_window_ends_at']),
    pickup_window_starts_at: FieldAccessFromJSON(json['pickup_window_starts_at']),
    pickup_window_ends_at: FieldAccessFromJSON(json['pickup_window_ends_at']),
    ordered_at: FieldAccessFromJSON(json['ordered_at']),
    updated_at: FieldAccessFromJSON(json['updated_at']),
    updated_by: FieldAccessFromJSON(json['updated_by']),
    picked_up_at: FieldAccessFromJSON(json['picked_up_at']),
    arrived_to_pickup_location_at: FieldAccessFromJSON(json['arrived_to_pickup_location_at']),
    arrived_to_delivery_location_at: FieldAccessFromJSON(json['arrived_to_delivery_location_at']),
    hourly_work_hours: FieldAccessFromJSON(json['hourly_work_hours']),
    hourly_work_reason: FieldAccessFromJSON(json['hourly_work_reason']),
    is_express_delivery: FieldAccessFromJSON(json['is_express_delivery']),
    price: FieldAccessFromJSON(json['price']),
    legacy_price_basis: FieldAccessFromJSON(json['legacy_price_basis']),
    has_contract_price: FieldAccessFromJSON(json['has_contract_price']),
    requires_combination_vehicle: FieldAccessFromJSON(json['requires_combination_vehicle']),
    billed_at: FieldAccessFromJSON(json['billed_at']),
    has_additional_hourly_pricing: FieldAccessFromJSON(json['has_additional_hourly_pricing']),
    legacy_etaisyys_field: FieldAccessFromJSON(json['legacy_etaisyys_field']),
    longest_shipment_row_side_length_mm: FieldAccessFromJSON(json['longest_shipment_row_side_length_mm']),
    customer_distribution_area: FieldAccessFromJSON(json['customer_distribution_area']),
    pricing_model: FieldAccessFromJSON(json['pricing_model']),
    pricing_failed: FieldAccessFromJSON(json['pricing_failed']),
    delivery_status_url_identifier: FieldAccessFromJSON(json['delivery_status_url_identifier']),
    is_adr_delivery: FieldAccessFromJSON(json['is_adr_delivery']),
    wait_hours: FieldAccessFromJSON(json['wait_hours']),
    delivery_phone_number_secondary: FieldAccessFromJSON(json['delivery_phone_number_secondary']),
    custom_pricing_category_id: FieldAccessFromJSON(json['custom_pricing_category_id']),
    unloaded_with_customer_permission: FieldAccessFromJSON(json['unloaded_with_customer_permission']),
    has_distance_been_fixed: FieldAccessFromJSON(json['has_distance_been_fixed']),
    billing_reference_number: FieldAccessFromJSON(json['billing_reference_number']),
    requires_hoist: FieldAccessFromJSON(json['requires_hoist']),
    is_line_haul_transport: FieldAccessFromJSON(json['is_line_haul_transport']),
    price_basis: FieldAccessFromJSON(json['price_basis']),
    price_basis_str: FieldAccessFromJSON(json['price_basis_str']),
    canSave: json['canSave'],
    minimumDeliveryDate: !exists(json, 'minimumDeliveryDate') ? undefined : new Date(json['minimumDeliveryDate']),
    minimumPickupTime: !exists(json, 'minimumPickupTime') ? undefined : new Date(json['minimumPickupTime']),
    priceAccess: PriceAccessFromJSON(json['priceAccess']),
    pickup_time: FieldAccessFromJSON(json['pickup_time']),
    pickup_date: FieldAccessFromJSON(json['pickup_date']),
    delivery_time_window_start: FieldAccessFromJSON(json['delivery_time_window_start']),
    delivery_time_window_end: FieldAccessFromJSON(json['delivery_time_window_end']),
    delivery_date: FieldAccessFromJSON(json['delivery_date']),
  };
}

export function ShipmentEditRightsToJSON(value?: ShipmentEditRights | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: FieldAccessToJSON(value.id),
    organization_id: FieldAccessToJSON(value.organization_id),
    billing_office_organization_specific_id: FieldAccessToJSON(value.billing_office_organization_specific_id),
    load_id: FieldAccessToJSON(value.load_id),
    state: FieldAccessToJSON(value.state),
    wait_reason: FieldAccessToJSON(value.wait_reason),
    orderer: FieldAccessToJSON(value.orderer),
    orderer_phone_number: FieldAccessToJSON(value.orderer_phone_number),
    job_number: FieldAccessToJSON(value.job_number),
    other_contract_number: FieldAccessToJSON(value.other_contract_number),
    pickup_office_organization_specific_id: FieldAccessToJSON(value.pickup_office_organization_specific_id),
    pickup_name: FieldAccessToJSON(value.pickup_name),
    pickup_address: FieldAccessToJSON(value.pickup_address),
    pickup_postal_code: FieldAccessToJSON(value.pickup_postal_code),
    pickup_phone_number: FieldAccessToJSON(value.pickup_phone_number),
    pickup_phone_number_secondary: FieldAccessToJSON(value.pickup_phone_number_secondary),
    pickup_city: FieldAccessToJSON(value.pickup_city),
    delivery_office_organization_specific_id: FieldAccessToJSON(value.delivery_office_organization_specific_id),
    delivery_name: FieldAccessToJSON(value.delivery_name),
    delivery_address: FieldAccessToJSON(value.delivery_address),
    delivery_postal_code: FieldAccessToJSON(value.delivery_postal_code),
    delivery_phone_number: FieldAccessToJSON(value.delivery_phone_number),
    delivery_city: FieldAccessToJSON(value.delivery_city),
    note: FieldAccessToJSON(value.note),
    length_ldm: FieldAccessToJSON(value.length_ldm),
    weight_kg: FieldAccessToJSON(value.weight_kg),
    chargeable_weight_kg: FieldAccessToJSON(value.chargeable_weight_kg),
    volume_m3: FieldAccessToJSON(value.volume_m3),
    delivered_at: FieldAccessToJSON(value.delivered_at),
    order_in_load: FieldAccessToJSON(value.order_in_load),
    delivery_window_starts_at: FieldAccessToJSON(value.delivery_window_starts_at),
    delivery_window_ends_at: FieldAccessToJSON(value.delivery_window_ends_at),
    recipient: FieldAccessToJSON(value.recipient),
    reference_number: FieldAccessToJSON(value.reference_number),
    agreed_delivery_window_starts_at: FieldAccessToJSON(value.agreed_delivery_window_starts_at),
    agreed_delivery_window_ends_at: FieldAccessToJSON(value.agreed_delivery_window_ends_at),
    pickup_window_starts_at: FieldAccessToJSON(value.pickup_window_starts_at),
    pickup_window_ends_at: FieldAccessToJSON(value.pickup_window_ends_at),
    ordered_at: FieldAccessToJSON(value.ordered_at),
    updated_at: FieldAccessToJSON(value.updated_at),
    updated_by: FieldAccessToJSON(value.updated_by),
    picked_up_at: FieldAccessToJSON(value.picked_up_at),
    arrived_to_pickup_location_at: FieldAccessToJSON(value.arrived_to_pickup_location_at),
    arrived_to_delivery_location_at: FieldAccessToJSON(value.arrived_to_delivery_location_at),
    hourly_work_hours: FieldAccessToJSON(value.hourly_work_hours),
    hourly_work_reason: FieldAccessToJSON(value.hourly_work_reason),
    is_express_delivery: FieldAccessToJSON(value.is_express_delivery),
    price: FieldAccessToJSON(value.price),
    legacy_price_basis: FieldAccessToJSON(value.legacy_price_basis),
    has_contract_price: FieldAccessToJSON(value.has_contract_price),
    requires_combination_vehicle: FieldAccessToJSON(value.requires_combination_vehicle),
    billed_at: FieldAccessToJSON(value.billed_at),
    has_additional_hourly_pricing: FieldAccessToJSON(value.has_additional_hourly_pricing),
    legacy_etaisyys_field: FieldAccessToJSON(value.legacy_etaisyys_field),
    longest_shipment_row_side_length_mm: FieldAccessToJSON(value.longest_shipment_row_side_length_mm),
    customer_distribution_area: FieldAccessToJSON(value.customer_distribution_area),
    pricing_model: FieldAccessToJSON(value.pricing_model),
    pricing_failed: FieldAccessToJSON(value.pricing_failed),
    delivery_status_url_identifier: FieldAccessToJSON(value.delivery_status_url_identifier),
    is_adr_delivery: FieldAccessToJSON(value.is_adr_delivery),
    wait_hours: FieldAccessToJSON(value.wait_hours),
    delivery_phone_number_secondary: FieldAccessToJSON(value.delivery_phone_number_secondary),
    custom_pricing_category_id: FieldAccessToJSON(value.custom_pricing_category_id),
    unloaded_with_customer_permission: FieldAccessToJSON(value.unloaded_with_customer_permission),
    has_distance_been_fixed: FieldAccessToJSON(value.has_distance_been_fixed),
    billing_reference_number: FieldAccessToJSON(value.billing_reference_number),
    requires_hoist: FieldAccessToJSON(value.requires_hoist),
    is_line_haul_transport: FieldAccessToJSON(value.is_line_haul_transport),
    price_basis: FieldAccessToJSON(value.price_basis),
    price_basis_str: FieldAccessToJSON(value.price_basis_str),
    canSave: value.canSave,
    minimumDeliveryDate:
      value.minimumDeliveryDate === undefined ? undefined : value.minimumDeliveryDate.toISOString().substr(0, 10),
    minimumPickupTime: value.minimumPickupTime === undefined ? undefined : value.minimumPickupTime.toISOString(),
    priceAccess: PriceAccessToJSON(value.priceAccess),
    pickup_time: FieldAccessToJSON(value.pickup_time),
    pickup_date: FieldAccessToJSON(value.pickup_date),
    delivery_time_window_start: FieldAccessToJSON(value.delivery_time_window_start),
    delivery_time_window_end: FieldAccessToJSON(value.delivery_time_window_end),
    delivery_date: FieldAccessToJSON(value.delivery_date),
  };
}
