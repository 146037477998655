import React, { Dispatch, useEffect, useState } from 'react';
import {
  Action,
  addSelectedShipmentsToLoad,
  CoordinationLoad,
  CoordinationShipment,
  createDeliveryTransports,
  getDriveDateFromSelectedDateRange,
  load,
  patchShipments,
  State,
} from './coordination.state';
import {
  Badge,
  Box,
  Button,
  Card,
  CardProps,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemProps,
  Menu,
  MenuItem,
  Paper,
  styled,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { DateRangePicker } from '../../components/DateRangePicker';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Loading } from '../../components/Loading';
import { ShipmentCancelled, ShipmentDelivered, ShipmentPickedUp } from '../../components/icons';
import { debounce, groupBy, orderBy } from 'lodash';
import { DeliveryWindow } from '../../components/DeliveryWindow';
import { Car, Driver, Load, ShipmentStateEnum } from '../../api';
import { formatTime, Organizations } from '../../formatters';
import {
  AddCircle,
  ArrowForward,
  CheckOutlined,
  Info,
  PlaylistRemove,
  PrecisionManufacturing,
  PriorityHigh,
  RvHookup,
  Settings,
  Sort,
  Warning,
} from '@mui/icons-material';
import theme from '../../theme';
import { AddressLink } from '../../components/map';
import { LoadModal } from '../../components/LoadModal';
import { getShipmentsOfLoadAfterKeyPress, getShipmentStateUtils, isTouchDevive, orderShipments } from '../../utils';
import { ShipmentRowInfoTableAsync } from '../../components/ShipmentRowInfoTable';
import { DriverSettingsModal } from './components/DriverSettingsModal';
import { EmptySelectMenuItem } from '../../components/EmptySelectMenuItem';
import { ButtonContainer } from '../../components/StyledComponents/ButtonContainer';
import { getDateRangePickerPropsFromState } from '../../utils/dateRangeUtils';

interface DriverCoordinationProps {
  state: State;
  dispatch: Dispatch<Action>;
}

const highlightBorder = `2px solid ${theme.palette.highlight.main}`;

interface CreateLoadButtonProps {
  driver: Driver;
  car?: Car;
  state: State;
  dispatch: Dispatch<Action>;
}

const CoordinationContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
});

const StyledUnassignedShipmentsList = styled(List)({
  overflowY: 'scroll',
  minWidth: '25rem',
  background: theme.palette.background.default,
  maxHeight: 'calc(100vh - 6rem)',
  [theme.breakpoints.down('md')]: {
    maxHeight: '100vh',
    minWidth: '14rem',
  },
});

const DriverList = styled(List)({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-end',
  padding: 0,
});

const LoadList = styled(List)({
  padding: '0.25rem',
  overflowY: 'auto',
  flexGrow: 1,
  height: 'calc(100% - 2.5rem)',
});

const ShipmentList = styled(List)({
  padding: 0,
  width: '100%',
});

const StyledCreateLoadButton = styled(Button)({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const CreateLoadButton: React.FC<CreateLoadButtonProps> = ({ driver, car, state, dispatch }) => {
  return (
    <StyledCreateLoadButton
      variant="outlined"
      size="small"
      startIcon={<AddCircle style={{ color: theme.palette.success.main }} />}
      className="create-load-button"
      disabled={state.isLoading}
      style={{ height: '1.25rem' }}
      onClick={() =>
        dispatch({
          type: 'SET_IS_LOAD_MODAL_OPEN',
          payload: {
            isLoadModalOpen: true,
            presetLoadFields: {
              driver_id: driver.id,
              car_id: car?.id,
              drive_date: getDriveDateFromSelectedDateRange(state.dateRange),
            },
          },
        })
      }
    >
      Uusi kuorma
    </StyledCreateLoadButton>
  );
};

interface UnassignedShipmentListItemProps extends React.HTMLAttributes<HTMLElement> {
  shipment: CoordinationShipment;
  state: State;
  dispatch: Dispatch<Action>;
}

const MetricInfoText = styled('span')({
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    whiteSpace: 'unset',
  },
});

const StyledCard = styled(Card)((props: CardProps & { selected: number }) => ({
  padding: '0.25rem',
  display: 'flex',
  flexFlow: 'column nowrap',
  cursor: 'pointer',
  width: '100%',
  border: '2px solid lightgray',
  '&:hover': {
    border: highlightBorder,
  },
  ...(props.selected && {
    border: highlightBorder,
    color: theme.palette.notInStock,
    backgroundColor: theme.palette.highlight.light,
  }),
}));

const UnassignedShipmentContainer = styled('div')(
  (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { notinstock: number }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    ...(props.notinstock && {
      backgroundColor: theme.palette.notInStock,
      color: theme.palette.common.white,
    }),
  }),
);

const UnassignedShipmentListItem: React.FC<UnassignedShipmentListItemProps> = ({ shipment, state, dispatch }) => {
  return (
    <ListItem
      data-cy={`unassigned-shipment-list-item-${shipment.id}`}
      key={`shipment-${shipment.id}`}
      style={{ fontSize: '0.85rem', padding: '0.25rem', maxWidth: '30rem' }}
    >
      <StyledCard
        selected={state.selectedShipments[shipment.id] ? 1 : 0}
        onClick={() =>
          !state.isLoading &&
          dispatch({
            type: 'SELECT_SHIPMENT',
            payload: {
              shipmentId: shipment.id,
              value: !state.selectedShipments[shipment.id],
            },
          })
        }
      >
        <UnassignedShipmentContainer notinstock={shipment.state === ShipmentStateEnum.EiVarastossa ? 1 : 0}>
          <span style={{ fontWeight: 'bold' }}>{shipment.organizationName}</span>
          {shipment.is_adr_delivery ? (
            <div style={{ color: theme.palette.warning.main, display: 'flex', alignItems: 'center' }}>
              <Warning style={{ height: '0.8rem', width: '0.8rem', marginRight: '0.2rem' }} />
              <span>ADR</span>
            </div>
          ) : null}
          {shipment.requires_hoist ? (
            <div style={{ color: theme.palette.warning.main, display: 'flex', alignItems: 'center' }}>
              <PrecisionManufacturing style={{ height: '0.8rem', width: '0.8rem', marginRight: '0.2rem' }} />
              <span>Nosturi</span>
            </div>
          ) : null}
          {shipment.requires_combination_vehicle ? (
            <div style={{ color: theme.palette.warning.main, display: 'flex', alignItems: 'center' }}>
              <RvHookup style={{ height: '0.8rem', width: '0.8rem', marginRight: '0.2rem' }} />
              <span>Perävaunu</span>
            </div>
          ) : null}
          {shipment.longest_shipment_row_side_length_mm && shipment.longest_shipment_row_side_length_mm >= 12000 ? (
            <div style={{ color: theme.palette.warning.main, display: 'flex', alignItems: 'center' }}>
              <PriorityHigh style={{ height: '0.8rem', width: '0.8rem', marginRight: '0.2rem' }} />
              <span>{shipment.longest_shipment_row_side_length_mm}mm</span>
            </div>
          ) : null}
          <DeliveryWindow
            startsAt={shipment.agreed_delivery_window_starts_at}
            endsAt={shipment.agreed_delivery_window_ends_at}
          />
        </UnassignedShipmentContainer>
        <div
          style={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'row wrap', marginBottom: '0.25rem' }}
        >
          <MetricInfoText>{shipment.weight_kg} kg</MetricInfoText>
          <MetricInfoText>{shipment.volume_m3} m³</MetricInfoText>
          <MetricInfoText>{shipment.length_ldm} lvm</MetricInfoText>
          <Link component={RouterLink} to={{ pathname: `/shipments/${shipment.id}` }}>
            {shipment.id}
          </Link>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>
            {shipment.pickup_name}
            <br />
            <AddressLink
              className="pickup-address-link"
              title={shipment.pickup_address ?? ''}
              address={shipment.pickup_address}
              postalCode={shipment.pickup_postal_code}
              city={shipment.pickup_city}
            />
            {shipment.pickup_postal_code} {shipment.pickup_city}
          </span>
          <ArrowForward />
          <span>
            {shipment.delivery_name}
            <br />
            <AddressLink
              className="pickup-address-link"
              title={shipment.delivery_address ?? ''}
              address={shipment.delivery_address}
              postalCode={shipment.delivery_postal_code}
              city={shipment.delivery_city}
            />
            {shipment.delivery_postal_code} {shipment.delivery_city}
          </span>
        </div>
        {state.selectedShipments[shipment.id] && (
          <>
            <strong className="unassigned-shipment-note">{shipment.note}</strong>
            <ShipmentRowInfoTableAsync shipment={shipment} />
          </>
        )}
      </StyledCard>
    </ListItem>
  );
};

interface DriverListItemProps extends React.HTMLAttributes<HTMLElement> {
  driver: Driver;
  loads: CoordinationLoad[];
  cars: Car[];
  state: State;
  dispatch: Dispatch<Action>;
}

const DriverListContainer = styled(Paper)({
  width: '100%',
  height: '20rem',
  display: 'flex',
  flexFlow: 'column nowrap',
  paddingBottom: '0.25rem',
});

const DriverListHeader = styled(Paper)({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  padding: '0.5rem',
});

const TrailerIcon = styled(RvHookup)({ color: theme.palette.warning.dark });

const StyledDriverListItem = styled('li')({
  width: '13.5rem',
  [theme.breakpoints.down('md')]: {
    width: '10rem',
  },
  padding: '0.5rem',
  overflowY: 'auto',
});

const DriverListItem: React.FC<DriverListItemProps> = ({ driver, loads, cars, children, state, dispatch }) => {
  const loadsUniqueTrailers = loads.map((load) => load.trailer_id).filter((id) => !!id);
  const trailersLicencePlates = cars
    .filter((car) => loadsUniqueTrailers.includes(car.id))
    .map((trailer) => trailer.licence_plate)
    .join(', ');
  return (
    <StyledDriverListItem key={driver.id} id={`driver-${driver.id}-list-item`}>
      <DriverListContainer elevation={3}>
        <DriverListHeader>
          <Typography component="span" variant="body2" style={{ fontWeight: 'bold' }}>
            {`${driver.last_name} ${driver.first_name}`}
          </Typography>
          <Typography component="span" variant="body2" style={{ fontWeight: 'bold' }}>
            {loads[0].car?.licence_plate ?? '-'}
          </Typography>
          {trailersLicencePlates ? (
            <Tooltip className="trailer-icon-tooltip" title={trailersLicencePlates} aria-label={trailersLicencePlates}>
              <TrailerIcon className="trailer-icon" fontSize={'small'} />
            </Tooltip>
          ) : null}
        </DriverListHeader>
        <Divider />
        <CreateLoadButton driver={driver} car={loads[0].car ?? undefined} state={state} dispatch={dispatch} />
        {children}
      </DriverListContainer>
    </StyledDriverListItem>
  );
};

interface AddToLoadButton {
  load: Load;
  state: State;
  dispatch: Dispatch<Action>;
}

const AddToLoadButton: React.FC<AddToLoadButton> = ({ load, state, dispatch }) => {
  const disabled = !Object.values(state.selectedShipments).some((value) => value) || state.isLoading;
  return disabled ? (
    <IconButton className="add-to-load-button" size="small" disabled={true}>
      <AddCircle style={{ height: '1rem', width: '1rem' }} />
    </IconButton>
  ) : (
    <Tooltip
      title={!disabled ? (load ? 'Lisää kuormaan' : 'Poista kuormista') : false}
      aria-label={!disabled ? 'Lisää kuormaan' : ''}
    >
      <IconButton
        className="add-to-load-button"
        size="small"
        onClick={() => addSelectedShipmentsToLoad(load.id, state, dispatch)}
      >
        <AddCircle style={{ height: '1rem', width: '1rem', color: theme.palette.success.main }} />
      </IconButton>
    </Tooltip>
  );
};

interface LoadListItemProps extends React.HTMLAttributes<HTMLElement> {
  load: CoordinationLoad;
  state: State;
  dispatch: Dispatch<Action>;
}

const LoadListItem: React.FC<LoadListItemProps> = ({ load, children, state, dispatch }) => {
  return (
    <ListItem
      key={load.id}
      className="load-list-item"
      style={{ padding: 0, display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-start' }}
    >
      <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
        <AddToLoadButton load={load} state={state} dispatch={dispatch} />
        <Typography component="span" variant="body2">
          <b>{`${load.organizationName}`} </b>
          <LoadTooltip load={load}>
            <Link component={RouterLink} to={{ pathname: `/loads/${load.id}` }}>
              {load.id}
            </Link>
          </LoadTooltip>
        </Typography>
        {load.state === 'jarjestelty' && <CheckOutlined color="success" />}
      </div>
      {children}
    </ListItem>
  );
};

interface ShipmentTooltipProps extends Partial<TooltipProps> {
  children: React.ReactElement<any, any>;
  shipment: CoordinationShipment;
  enterTouchDelay?: number;
}

export const TooltipContainer = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
});

export const TooltipContainerInner = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const TooltipContainerAddress = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  marginLeft: 'auto',
  marginRight: 'auto',
  flexBasis: 'fit-content',
});

export const TooltipDivider = styled(Divider)({
  marginTop: '0.75rem',
  marginBottom: '0.75rem',
  backgroundColor: theme.palette.common.white,
});

const ShipmentTooltip: React.FC<ShipmentTooltipProps> = ({ shipment, children, enterTouchDelay }) => {
  return (
    <Tooltip
      enterTouchDelay={enterTouchDelay}
      disableInteractive={false}
      slotProps={{
        popper: {
          onClick: (e) => {
            //this prevents current shipment selection from tooltip
            e.stopPropagation();
          },
        },
      }}
      placement="left"
      aria-label={`Shipment ${shipment.id} description tooltip`}
      title={
        <TooltipContainer className="shipment-tooltip">
          <TooltipContainerInner>
            <span>{shipment.organizationName}</span>
            <span>{shipment.reference_number}</span>
          </TooltipContainerInner>
          <TooltipContainerInner>
            <DeliveryWindow
              startsAt={shipment.agreed_delivery_window_starts_at}
              endsAt={shipment.agreed_delivery_window_ends_at}
            />
            <span>{shipment.id}</span>
          </TooltipContainerInner>
          <TooltipDivider />
          <TooltipContainerInner>
            <span>{shipment.weight_kg} kg</span>
            <span>{shipment.volume_m3} m³</span>
            <span>{shipment.length_ldm} lvm</span>
          </TooltipContainerInner>
          <TooltipDivider />
          <TooltipContainerInner sx={{ minWidth: 0 }}>
            <TooltipContainerAddress>
              <span>{shipment.pickup_name}</span>
              <span>
                <AddressLink
                  sx={{ color: theme.palette.common.white, whiteSpace: 'normal' }}
                  title={shipment.pickup_address ?? ''}
                  address={shipment.pickup_address}
                  postalCode={shipment.pickup_postal_code}
                  city={shipment.pickup_city}
                />
              </span>
              <span>
                {shipment.pickup_postal_code} {shipment.pickup_city}
              </span>
            </TooltipContainerAddress>
            <TooltipContainerAddress sx={{ flexGrow: 1, justifyContent: 'center', margin: '0.75rem' }}>
              <ArrowForward />
            </TooltipContainerAddress>
            <TooltipContainerAddress>
              <span>{shipment.delivery_name}</span>
              <span>
                <AddressLink
                  sx={{ color: theme.palette.common.white, whiteSpace: 'normal' }}
                  title={shipment.delivery_address ?? ''}
                  address={shipment.delivery_address}
                  postalCode={shipment.delivery_postal_code}
                  city={shipment.delivery_city}
                />
              </span>
              <span>
                {shipment.delivery_postal_code} {shipment.delivery_city}
              </span>
            </TooltipContainerAddress>
          </TooltipContainerInner>
          {shipment.note ? (
            <Box>
              <TooltipDivider />
              <span style={{ whiteSpace: 'normal' }}>{shipment.note}</span>
            </Box>
          ) : null}
          {shipment.is_adr_delivery || shipment.requires_hoist || shipment.requires_combination_vehicle ? (
            <>
              <TooltipDivider />
              <TooltipContainerInner>
                {shipment.is_adr_delivery ? (
                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <Warning fontSize="small" />
                    <span style={{ whiteSpace: 'normal' }}>ADR-kuljetus</span>
                  </Box>
                ) : null}
                {shipment.requires_hoist ? (
                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <PrecisionManufacturing fontSize="small" /> Nosturi tilattu
                    <span style={{ whiteSpace: 'normal' }}></span>
                  </Box>
                ) : null}
                {shipment.requires_combination_vehicle ? (
                  <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <RvHookup fontSize="small" /> Perävaunu
                    <span style={{ whiteSpace: 'normal' }}></span>
                  </Box>
                ) : null}
              </TooltipContainerInner>
            </>
          ) : null}
        </TooltipContainer>
      }
    >
      {children}
    </Tooltip>
  );
};

interface LoadTooltipProps {
  children: React.ReactElement<any, any>;
  load: Load;
}

const LoadTooltip: React.FC<LoadTooltipProps> = ({ load, children }) => {
  return (
    <Tooltip
      placement="left"
      aria-label={`Load ${load.id} description tooltip`}
      title={
        <TooltipContainer className="shipment-tooltip">
          <span>{load.weight_kg} kg</span>
          <span>{load.volume_m3} m³</span>
          <span>{load.length_ldm} lvm</span>
        </TooltipContainer>
      }
    >
      {children}
    </Tooltip>
  );
};

interface ShipmentListItem extends React.HTMLAttributes<HTMLElement> {
  shipment: CoordinationShipment;
  state: State;
  dispatch: Dispatch<Action>;
}

export const updateLoadOrder = debounce(
  async (
    loadId: Load['id'],
    loadShipmentIds: CoordinationShipment['id'][],
    state: State,
    dispatch: Dispatch<Action>,
  ): Promise<void> => {
    try {
      dispatch({
        type: 'SET_LOADING',
        payload: true,
      });
      await patchShipments(loadId, loadShipmentIds);
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: 'Kuormajärjestys päivitetty.',
        },
      });
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: 'Kuorman päivitys epäonnistui!',
          severity: 'error',
        },
      });
    }
    load(state, dispatch);
  },
  1000,
);

const ShipmentContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
});

const StyledShipmentListItem = styled(ListItemButton)((
  props: ListItemProps & {
    selectedshipment: number;
    shipmentstate: ShipmentStateEnum;
  },
) => {
  const stateUtils = getShipmentStateUtils(props.shipmentstate);
  return {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    padding: '0 0.2rem',
    backgroundColor: stateUtils.backgroundColor,
    color: stateUtils.color,
    cursor: 'pointer',
    border: '2px solid transparent',
    fontSize: '0.85rem',
    '&:hover': {
      outline: '0px solid transparent',
      border: highlightBorder,
      backgroundColor: stateUtils.backgroundColor,
    },
    ...(props.selectedshipment && {
      border: highlightBorder,
      color: theme.palette.notInStock,
      backgroundColor: theme.palette.highlight.light,
      '&:hover': {
        backgroundColor: theme.palette.highlight.light,
      },
    }),
  };
});

const getShipmentStateIcon = (state: ShipmentStateEnum) => {
  switch (state) {
    case ShipmentStateEnum.Noudettu:
      return <ShipmentPickedUp />;
    case ShipmentStateEnum.Toimitettu:
      return <ShipmentDelivered />;
    case ShipmentStateEnum.Peruttu:
      return <ShipmentCancelled />;
    default:
      return null;
  }
};

const ShipmentListItem: React.FC<ShipmentListItem> = ({ shipment, state, dispatch }) => {
  return (
    <StyledShipmentListItem
      disableRipple={true}
      autoFocus={
        state.selectedShipments[shipment.id] &&
        Object.values(state.selectedShipments).filter((item) => item).length === 1
      }
      onKeyDown={async (e) => {
        e.preventDefault();
        if (
          !shipment.load_id ||
          !state.selectedShipments[shipment.id] ||
          Object.values(state.selectedShipments).filter((item) => item).length !== 1 ||
          state.isLoading ||
          (e.key !== 'ArrowUp' && e.key !== 'ArrowDown')
        ) {
          return;
        }
        const shipmentsOfLoad = getShipmentsOfLoadAfterKeyPress(state.shipmentsByLoad[shipment.load_id], shipment, e);
        if (shipmentsOfLoad) {
          dispatch({
            type: 'SET_SHIPMENTS_BY_LOAD',
            payload: { ...state.shipmentsByLoad, [shipment.load_id]: shipmentsOfLoad },
          });
          updateLoadOrder(
            shipment.load_id,
            shipmentsOfLoad.map((shipment) => shipment.id),
            state,
            dispatch,
          );
        }
      }}
      tabIndex={0}
      id={`shipment-${shipment.id}-list-item`}
      key={shipment.id}
      onClick={() => {
        !state.isLoading &&
          dispatch({
            type: 'SELECT_SHIPMENT',
            payload: {
              shipmentId: shipment.id,
              value: !state.selectedShipments[shipment.id],
            },
          });
      }}
      selectedshipment={state.selectedShipments[shipment.id] ? 1 : 0}
      shipmentstate={shipment.state}
    >
      <ShipmentContainer>
        <span style={{ fontSize: '0.25rem' }}>{getShipmentStateIcon(shipment.state)}</span>
        <ShipmentTooltip shipment={shipment}>
          <Link
            component={RouterLink}
            to={{ pathname: `/shipments/${shipment.id}` }}
            className={`shipment-${shipment.id}-link`}
          >
            {shipment.id}
          </Link>
        </ShipmentTooltip>
        {shipment.is_adr_delivery ? (
          <Warning
            style={{ color: theme.palette.warning.main, height: '0.8rem', width: '0.8rem', marginRight: '0.2rem' }}
          />
        ) : null}
        {shipment.requires_hoist ? (
          <PrecisionManufacturing
            style={{ color: theme.palette.warning.main, height: '0.8rem', width: '0.8rem', marginRight: '0.2rem' }}
          />
        ) : null}
        {shipment.requires_combination_vehicle ? (
          <RvHookup
            style={{ color: theme.palette.warning.main, height: '0.8rem', width: '0.8rem', marginRight: '0.2rem' }}
          />
        ) : null}
      </ShipmentContainer>
      {shipment.state === ShipmentStateEnum.Noudettu && shipment.picked_up_at !== null ? (
        <span>{formatTime(shipment.picked_up_at)}</span>
      ) : shipment.state === ShipmentStateEnum.Toimitettu && shipment.delivered_at !== null ? (
        <span>{formatTime(shipment.delivered_at)}</span>
      ) : (
        <DeliveryWindow
          startsAt={shipment.agreed_delivery_window_starts_at}
          endsAt={shipment.agreed_delivery_window_ends_at}
          onlyTime={true}
        />
      )}
      {isTouchDevive() && (
        <ShipmentTooltip shipment={shipment} enterTouchDelay={0}>
          <Info fontSize={'small'} onClick={(e) => e.stopPropagation()} />
        </ShipmentTooltip>
      )}
    </StyledShipmentListItem>
  );
};

const getFilteredLoadsByDriver = (
  filteredLoadsByDriver: { driver: Driver; loads: CoordinationLoad[] }[],
  state: State,
) => {
  return filteredLoadsByDriver
    .map((loadsOfDriver) => {
      return {
        driver: loadsOfDriver.driver,
        loads: loadsOfDriver.loads.filter((load) =>
          state.viewSettings.selectedOrganizations
            .map((org) => org.id)
            .some(
              (orgId) =>
                load.organization_id === orgId ||
                state.shipmentsByLoad[load.id]?.map((s) => s.organization_id).includes(orgId),
            ),
        ),
      };
    })
    .filter((loadOfDriver) => loadOfDriver.loads.length > 0);
};

const getSelectedOrganizationMenuItems = (state: State) => {
  if (Object.keys(groupBy(state.viewSettings.organizationGroups, 'group')).length > 0) {
    return Object.keys(groupBy(state.viewSettings.organizationGroups, 'group'))
      .concat(['Muut'])
      .map((g) => (
        <MenuItem key={g} value={g} data-cy={`${g}-menu-item`}>
          {g}
        </MenuItem>
      ));
  } else {
    const shipmentOrgIds = state.shipments.map((s) => s.organization_id);
    return state.organizations
      .filter((o) => shipmentOrgIds.includes(o.id))
      .map((o) => (
        <MenuItem key={o.id} value={o.id} data-cy={`${o.id}-menu-item`}>
          {o.name}
        </MenuItem>
      ));
  }
};

export const getSelectedOrganizations = (state: State, value: string) => {
  if (value === 'Muut') {
    return state.organizations.filter(
      (org) => org.id && !state.viewSettings.organizationGroups.map((o) => o.id).includes(org.id),
    );
  } else if (value && state.viewSettings.organizationGroups.length > 0) {
    return state.viewSettings.organizationGroups.filter((org) => org.group === value);
  } else if (value) {
    return state.organizations.filter((org) => org.id === value);
  } else {
    return state.organizations;
  }
};

export const DriverCoordination: React.FC<DriverCoordinationProps> = ({ state, dispatch }) => {
  const [unassignedShipmentFilter, setUnassignedShipmentFilter] = useState<string>('');
  const [openSettings, setOpenSettings] = React.useState(false);
  const handleOpenSettings = () => setOpenSettings(true);
  const handleCloseSettings = () => setOpenSettings(false);

  const [sortMenuAnchorEl, setSortMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const sortMenuOpen = Boolean(sortMenuAnchorEl);
  const handleSortMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSortMenuAnchorEl(event.currentTarget);
  };
  const handleSortMenuClose = () => {
    setSortMenuAnchorEl(null);
  };

  useEffect(() => {
    if (state.dateRange) {
      const abortController = new AbortController();
      load(state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [state.dateRange]);

  return (
    <>
      <Loading isLoading={state.isLoading} />
      <CoordinationContainer>
        <Box
          sx={{
            position: 'sticky',
            top: '0.25rem',
            height: 'fit-content',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '0.25rem',
            }}
          >
            <TextField
              sx={{ flexGrow: 1 }}
              variant="outlined"
              size="small"
              className="unassigned-shipment-filter"
              label="Kuormattomat"
              value={state.viewSettings.unassignedShipmentFilter}
              onChange={(e) => {
                setUnassignedShipmentFilter(e.target.value);
                dispatch({
                  type: 'SET_VIEW_SETTINGS',
                  payload: {
                    ...state.viewSettings,
                    unassignedShipmentFilter: e.target.value,
                  },
                });
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <Tooltip
                title={
                  state.viewSettings.unassignedShipmentOrder.id === 'agreed_delivery_window_ends_at'
                    ? 'Toimitusaika, postinumero'
                    : 'Noutopaikka, toimituspaikka'
                }
              >
                <IconButton
                  onClick={handleSortMenuOpen}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={sortMenuOpen ? 'unassigned-shipment-sort-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={sortMenuOpen ? 'true' : undefined}
                >
                  <Sort />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={sortMenuAnchorEl}
              id="filter-menu"
              open={sortMenuOpen}
              onClose={handleSortMenuClose}
              onClick={handleSortMenuClose}
            >
              <MenuItem
                onClick={() => {
                  dispatch({
                    type: 'SET_VIEW_SETTINGS',
                    payload: {
                      ...state.viewSettings,
                      unassignedShipmentOrder: {
                        id: 'agreed_delivery_window_ends_at',
                        value: ['agreed_delivery_window_ends_at', 'delivery_postal_code'],
                      },
                    },
                  });
                  handleSortMenuClose();
                }}
              >
                Toimitusaika, postinumero
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch({
                    type: 'SET_VIEW_SETTINGS',
                    payload: {
                      ...state.viewSettings,
                      unassignedShipmentOrder: {
                        id: 'pickup_city',
                        value: ['pickup_city', 'delivery_city'],
                      },
                    },
                  });
                  handleSortMenuClose();
                }}
              >
                Noutopaikka, toimituspaikka
              </MenuItem>
            </Menu>
            <Tooltip title={'Tyhjennä valitut toimitukset'}>
              {/*span is a wrapper for disabled tooltip */}
              <span>
                <IconButton
                  disabled={!Object.values(state.selectedShipments).some(Boolean)}
                  onClick={() =>
                    dispatch({
                      type: 'CLEAR_SELECTED_SHIPMENTS',
                    })
                  }
                >
                  <PlaylistRemove />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <StyledUnassignedShipmentsList className="unassigned-shipments">
            {orderBy(state.unassignedShipments, state.viewSettings.unassignedShipmentOrder.value)
              .filter((shipment) =>
                state.viewSettings.selectedOrganizations
                  ? state.viewSettings.selectedOrganizations
                      .map((org) => org.id)
                      .includes(shipment.organization_id ?? '')
                  : true,
              )
              .filter((shipment) => {
                return (
                  [
                    shipment.organizationName,
                    String(shipment.agreed_delivery_window_starts_at),
                    String(shipment.agreed_delivery_window_ends_at),
                    String(shipment.weight_kg),
                    String(shipment.volume_m3),
                    String(shipment.length_ldm),
                    String(shipment.id),
                    shipment.delivery_address,
                    shipment.delivery_city,
                    shipment.delivery_postal_code,
                    shipment.delivery_name,
                    shipment.pickup_address,
                    shipment.pickup_city,
                    shipment.pickup_postal_code,
                    shipment.pickup_name,
                  ]
                    .reduce((memo: string, field) => {
                      return memo + ' ' + (field?.toLocaleLowerCase() ?? '');
                    }, '')
                    .indexOf(unassignedShipmentFilter.toLocaleLowerCase()) !== -1
                );
              })
              .map((shipment) => (
                <UnassignedShipmentListItem
                  key={`unassigned-${shipment.id}`}
                  shipment={shipment}
                  state={state}
                  dispatch={dispatch}
                />
              ))}
          </StyledUnassignedShipmentsList>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginBottom: '0.5rem',
            }}
          >
            <IconButton onClick={handleOpenSettings}>
              <Badge
                data-cy="settings"
                color="primary"
                badgeContent={state.viewSettings.onlyUndeliveredShipments ? '1' : null}
              >
                <Settings />
              </Badge>
            </IconButton>
            <DriverSettingsModal open={openSettings} onClose={handleCloseSettings} state={state} dispatch={dispatch} />
            <TextField
              data-cy="organizations-picker"
              className="organizations-picker"
              sx={{ marginRight: '0.25rem', width: '12rem' }}
              select
              size="small"
              variant="outlined"
              label="Asiakas"
              disabled={state.isLoading}
              value={state.viewSettings.selectedGroup}
              onChange={async (event) => {
                try {
                  dispatch({
                    type: 'CLEAR_SELECTED_SHIPMENTS',
                  });
                  await load(state, dispatch);
                } finally {
                  dispatch({
                    type: 'SET_VIEW_SETTINGS',
                    payload: {
                      ...state.viewSettings,
                      selectedGroup: event.target.value,
                      selectedOrganizations: getSelectedOrganizations(state, event.target.value),
                    },
                  });
                }
              }}
            >
              <EmptySelectMenuItem value={''} data-cy="empty-menu-item" />
              {getSelectedOrganizationMenuItems(state)}
            </TextField>
            <DateRangePicker {...getDateRangePickerPropsFromState(state, dispatch)} />
          </Box>
          <ButtonContainer sx={{ justifyContent: 'flex-end' }}>
            <Button
              id="create-delivery-transfers-button"
              onClick={() => {
                createDeliveryTransports(state, dispatch);
              }}
              disabled={
                state.isLoading ||
                !Object.values(state.selectedShipments).some((value) => value) ||
                !state.shipments
                  .filter((shipment) => state.selectedShipments[shipment.id])
                  .every((x) => x.organization_id === Organizations.Krautayp)
              }
            >
              Luo jakelutoimitukset valituille
            </Button>
            <Button
              className="create-new-load-button"
              onClick={() => {
                const selectedShipmentsArray = state.shipments.filter(
                  (shipment) => state.selectedShipments[shipment.id],
                );
                for (let i = 0; i < selectedShipmentsArray.length; i++) {
                  selectedShipmentsArray[i].order_in_load = i + 1;
                }
                dispatch({
                  type: 'SET_IS_LOAD_MODAL_OPEN',
                  payload: {
                    isLoadModalOpen: true,
                    presetLoadFields: {
                      drive_date: getDriveDateFromSelectedDateRange(state.dateRange),
                    },
                  },
                });
              }}
              disabled={state.isLoading}
            >
              {!Object.values(state.selectedShipments).some((value) => value)
                ? 'Luo uusi tyhjä kuorma'
                : 'Luo valituista uusi kuorma'}
            </Button>
            <Button
              color="error"
              className="remove-from-loads-button"
              disabled={!Object.values(state.selectedShipments).some((value) => value) || state.isLoading}
              onClick={() => addSelectedShipmentsToLoad(null, state, dispatch)}
            >
              Poista valitut kuormista
            </Button>
          </ButtonContainer>
          <DriverList className="driver-list">
            {getFilteredLoadsByDriver(state.loadsByDriver, state).map((driverAndLoads) => (
              <DriverListItem
                key={driverAndLoads.driver.id}
                driver={driverAndLoads.driver}
                loads={driverAndLoads.loads}
                cars={state.cars}
                state={state}
                dispatch={dispatch}
              >
                <LoadList className="load-list">
                  {driverAndLoads.loads.map((load) => (
                    <LoadListItem key={load.id} load={load} state={state} dispatch={dispatch}>
                      {state.shipmentsByLoad[load.id]?.length > 0 ? (
                        <ShipmentList className="shipment-list">
                          {state.shipmentsByLoad[load.id]
                            .filter((shipment) =>
                              state.viewSettings.onlyUndeliveredShipments
                                ? shipment.state !== ShipmentStateEnum.Toimitettu
                                : true,
                            )
                            .map((shipment) => {
                              return (
                                <ShipmentListItem
                                  key={shipment.id}
                                  shipment={shipment}
                                  state={state}
                                  dispatch={dispatch}
                                />
                              );
                            })}
                        </ShipmentList>
                      ) : (
                        <Typography component="span" variant="body2">
                          Ei toimituksia.
                        </Typography>
                      )}
                    </LoadListItem>
                  ))}
                </LoadList>
              </DriverListItem>
            ))}
          </DriverList>
        </Box>
      </CoordinationContainer>
      <LoadModal
        open={state.isLoadModalOpen}
        creationShipments={orderShipments(state.shipments.filter((shipment) => state.selectedShipments[shipment.id]))}
        cars={state.cars}
        drivers={state.drivers}
        organizations={state.organizations}
        presetFields={state.presetLoadFields}
        onClose={(didCreateNewLoad) => {
          if (didCreateNewLoad) {
            dispatch({
              type: 'SET_MESSAGE',
              payload: {
                message: 'Kuorma tallennettu!',
              },
            });
            dispatch({
              type: 'CLEAR_SELECTED_SHIPMENTS',
            });
            load(state, dispatch);
          }
          dispatch({ type: 'SET_IS_LOAD_MODAL_OPEN', payload: { isLoadModalOpen: false } });
        }}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};
