/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Shipment, ShipmentFromJSON, ShipmentFromJSONTyped, ShipmentToJSON } from './Shipment';

/**
 *
 * @export
 * @interface LoadShipmentsOptimizedRouteResponseBody
 */
export interface LoadShipmentsOptimizedRouteResponseBody {
  /**
   *
   * @type {Array<Shipment>}
   * @memberof LoadShipmentsOptimizedRouteResponseBody
   */
  data: Array<Shipment>;
  /**
   *
   * @type {number}
   * @memberof LoadShipmentsOptimizedRouteResponseBody
   */
  next?: number | null;
}

export function LoadShipmentsOptimizedRouteResponseBodyFromJSON(json: any): LoadShipmentsOptimizedRouteResponseBody {
  return LoadShipmentsOptimizedRouteResponseBodyFromJSONTyped(json, false);
}

export function LoadShipmentsOptimizedRouteResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LoadShipmentsOptimizedRouteResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(ShipmentFromJSON),
    next: !exists(json, 'next') ? undefined : json['next'],
  };
}

export function LoadShipmentsOptimizedRouteResponseBodyToJSON(
  value?: LoadShipmentsOptimizedRouteResponseBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(ShipmentToJSON),
    next: value.next,
  };
}
