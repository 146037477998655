import { Button, ButtonProps, Tooltip } from '@mui/material';
import React from 'react';

export interface TooltipButtonProps extends ButtonProps {
  tooltip?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isTooltipVisible?: boolean;
  onTooltipOpen?: () => void;
  onTooltipClose?: () => void;
}

export const TooltipButton: React.FC<TooltipButtonProps> = ({
  tooltip,
  isTooltipVisible,
  onClick,
  onTooltipOpen,
  onTooltipClose,
  className,
  children,
  ...buttonProps
}) => (
  <Tooltip
    title={tooltip ?? ''}
    aria-label={tooltip ?? ''}
    open={isTooltipVisible}
    onOpen={onTooltipOpen}
    onClose={onTooltipClose}
  >
    <span>
      {/* This span makes tooltip work with disabled button. Disabled elements do not send events, so we need this wrapper */}
      <Button className={className} onClick={onClick} {...buttonProps}>
        {children}
      </Button>
    </span>
  </Tooltip>
);
