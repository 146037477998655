import React from 'react';
import { Paper, styled } from '@mui/material';

const MainPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  margin: '1rem',
  padding: '1rem',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    padding: '0.5rem 0.5rem',
  },
}));

const Main: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children, className }) => {
  return (
    <MainPaper className={className}>
      <main>{children}</main>
    </MainPaper>
  );
};

export default Main;
