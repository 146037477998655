import React, { Dispatch, useEffect } from 'react';
import { ReactTable } from '../../components/ReactTable';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { AutoCompleteFilter, NoFilter } from '../../components/TableFilters';
import { Row, TableState, UseTableInstanceProps } from 'react-table';
import { DateRangePicker } from '../../components/DateRangePicker';
import { getViewSettings } from '../settings';
import { Action, ShipmentAggregatesReportData, State } from './reports.state';
import { api, ShipmentReportAggregateFieldEnum, ShipmentReportAggregateTypeEnum } from '../../api';
import { ShipmentReportPostBodyGroupByEnum } from '../../../../backend/src/common/api';
import { dateFormatUnderscore, formatFloat } from '../../formatters';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { getDateRangePickerPropsFromState } from '../../utils/dateRangeUtils';

const VIEW_ID = 'shipment_aggregates_report' as const;

interface ShipmentReportViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
  hideCancelledShipments: boolean;
}

const fetchData = async (state: State, dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [organizationsResponse, shipmentReportResponse] = await Promise.all([
      api.organizations.getOrganizations({}),
      api.reports.getShipmentReport({
        shipmentReportPostBody: {
          agreed_delivery_window_date_range_starts_at: state.dateRange.start.toJSDate(),
          agreed_delivery_window_date_range_ends_at: state.dateRange.end.toJSDate(),
          filters: [],
          group_by: [ShipmentReportPostBodyGroupByEnum.OrganizationId],
          aggregates: [
            {
              type: ShipmentReportAggregateTypeEnum.Count,
            },
            {
              type: ShipmentReportAggregateTypeEnum.Sum,
              field: ShipmentReportAggregateFieldEnum.WeightKg,
            },
            {
              type: ShipmentReportAggregateTypeEnum.Sum,
              field: ShipmentReportAggregateFieldEnum.VolumeM3,
            },
            {
              type: ShipmentReportAggregateTypeEnum.Sum,
              field: ShipmentReportAggregateFieldEnum.LengthLdm,
            },
            {
              type: ShipmentReportAggregateTypeEnum.Sum,
              field: ShipmentReportAggregateFieldEnum.ChargeableWeightKg,
            },
            {
              type: ShipmentReportAggregateTypeEnum.Sum,
              field: ShipmentReportAggregateFieldEnum.Price,
            },
            {
              type: ShipmentReportAggregateTypeEnum.Sum,
              field: ShipmentReportAggregateFieldEnum.HourlyWorkHours,
            },
          ],
        },
      }),
    ]);
    dispatch({
      type: 'INITIALIZE',
      payload: {
        organizations: organizationsResponse?.data ?? [],
        shipmentAggregatesReportData: shipmentReportResponse.data as ShipmentAggregatesReportData[],
      },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe raportin laskennassa',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

interface ShipmentAggregatesReportProps {
  state: State;
  dispatch: Dispatch<Action>;
}

const sumFooter =
  (field: keyof ShipmentAggregatesReportData) =>
  (info: UseTableInstanceProps<ShipmentAggregatesReportData>): string =>
    formatFloat(info.rows.reduce((acc: number, row: Row<ShipmentAggregatesReportData>) => acc + row.values[field], 0));

export const ShipmentAggregatesReport: React.FC<ShipmentAggregatesReportProps> = ({ state, dispatch }) => {
  const currentUser = useCurrentUser();

  const viewSettings = React.useMemo(() => getViewSettings<ShipmentReportViewSettings>(VIEW_ID), []);

  useEffect(() => {
    if (currentUser?.organization_id) {
      fetchData(state, dispatch);
    }
  }, [currentUser, state.dateRange]);
  const data = React.useMemo(() => state.shipmentAggregatesReportData, [state.shipmentAggregatesReportData]);
  const columns = React.useMemo(
    () => [
      {
        id: 'organization',
        Header: 'Asiakas',
        accessor: 'organizationName',
        Filter: AutoCompleteFilter,
        Footer: 'Yhteensä',
      },
      {
        Header: 'Toimitukset',
        accessor: 'count',
        filterable: false,
        Filter: NoFilter,
        // Footer expects a string so we cast the number as one. If we would pass number 0 in here, it would not render
        // I guess they check for string's existence with truthiness and zero is obviously falsy
        Footer: sumFooter('count'),
      },
      {
        Header: 'Paino (kg)',
        accessor: 'sum_weight_kg',
        filterable: false,
        Filter: NoFilter,
        Footer: sumFooter('sum_weight_kg'),
      },
      {
        Header: 'Tilavuus (m3)',
        accessor: 'sum_volume_m3',
        filterable: false,
        Filter: NoFilter,
        Footer: sumFooter('sum_volume_m3'),
      },
      {
        Header: 'Lavametrit',
        accessor: 'sum_length_ldm',
        filterable: false,
        Filter: NoFilter,
        Footer: sumFooter('sum_length_ldm'),
      },
      {
        Header: 'Rahdituspaino (kg)',
        accessor: 'sum_chargeable_weight_kg',
        filterable: false,
        Filter: NoFilter,
        Footer: sumFooter('sum_chargeable_weight_kg'),
      },
      {
        Header: 'Hinta',
        accessor: 'sum_price',
        filterable: false,
        Filter: NoFilter,
        Footer: sumFooter('sum_price'),
      },
      {
        Header: 'Työtunnit',
        accessor: 'sum_hourly_work_hours',
        filterable: false,
        Filter: NoFilter,
        Footer: sumFooter('sum_hourly_work_hours'),
      },
    ],
    [state.shipmentAggregatesReportData],
  );
  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        header="Asiakasraportti"
        dateRangePicker={<DateRangePicker {...getDateRangePickerPropsFromState(state, dispatch)}></DateRangePicker>}
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä toimituksia."
        columnSelector
        exportName={`Asiakasraportti_${state.dateRange?.start.toFormat(
          dateFormatUnderscore,
        )}_${state.dateRange?.end.toFormat(dateFormatUnderscore)}`}
        getRowId={(row) => row.organization_id} // Override the default behaviour of reading row.id
        initialState={{
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'organization',
              desc: false,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        onStateChange={(tableState) => {
          dispatch({
            type: 'SET_VIEW_SETTINGS',
            payload: {
              viewId: VIEW_ID,
              shipmentAggregatesReportViewSettings: { ...state.shipmentAggregatesReportViewSettings, ...tableState },
            },
          });
        }}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};
