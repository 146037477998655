/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Product, ProductFromJSON, ProductFromJSONTyped, ProductToJSON } from './Product';

/**
 *
 * @export
 * @interface ProductResponseBody
 */
export interface ProductResponseBody {
  /**
   *
   * @type {Product}
   * @memberof ProductResponseBody
   */
  data?: Product;
}

export function ProductResponseBodyFromJSON(json: any): ProductResponseBody {
  return ProductResponseBodyFromJSONTyped(json, false);
}

export function ProductResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, 'data') ? undefined : ProductFromJSON(json['data']),
  };
}

export function ProductResponseBodyToJSON(value?: ProductResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ProductToJSON(value.data),
  };
}
