/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Load, LoadFromJSON, LoadFromJSONTyped, LoadToJSON } from './Load';
import {
  SearchShipmentRowsResponseBodyDriver,
  SearchShipmentRowsResponseBodyDriverFromJSON,
  SearchShipmentRowsResponseBodyDriverFromJSONTyped,
  SearchShipmentRowsResponseBodyDriverToJSON,
} from './SearchShipmentRowsResponseBodyDriver';
import { Shipment, ShipmentFromJSON, ShipmentFromJSONTyped, ShipmentToJSON } from './Shipment';
import { ShipmentRow, ShipmentRowFromJSON, ShipmentRowFromJSONTyped, ShipmentRowToJSON } from './ShipmentRow';

/**
 *
 * @export
 * @interface SearchShipmentRowsResponseBodyData
 */
export interface SearchShipmentRowsResponseBodyData {
  /**
   *
   * @type {ShipmentRow}
   * @memberof SearchShipmentRowsResponseBodyData
   */
  shipment_row: ShipmentRow;
  /**
   *
   * @type {Shipment}
   * @memberof SearchShipmentRowsResponseBodyData
   */
  shipment?: Shipment;
  /**
   *
   * @type {Load}
   * @memberof SearchShipmentRowsResponseBodyData
   */
  load?: Load;
  /**
   *
   * @type {SearchShipmentRowsResponseBodyDriver}
   * @memberof SearchShipmentRowsResponseBodyData
   */
  driver?: SearchShipmentRowsResponseBodyDriver;
}

export function SearchShipmentRowsResponseBodyDataFromJSON(json: any): SearchShipmentRowsResponseBodyData {
  return SearchShipmentRowsResponseBodyDataFromJSONTyped(json, false);
}

export function SearchShipmentRowsResponseBodyDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SearchShipmentRowsResponseBodyData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    shipment_row: ShipmentRowFromJSON(json['shipment_row']),
    shipment: !exists(json, 'shipment') ? undefined : ShipmentFromJSON(json['shipment']),
    load: !exists(json, 'load') ? undefined : LoadFromJSON(json['load']),
    driver: !exists(json, 'driver') ? undefined : SearchShipmentRowsResponseBodyDriverFromJSON(json['driver']),
  };
}

export function SearchShipmentRowsResponseBodyDataToJSON(value?: SearchShipmentRowsResponseBodyData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    shipment_row: ShipmentRowToJSON(value.shipment_row),
    shipment: ShipmentToJSON(value.shipment),
    load: LoadToJSON(value.load),
    driver: SearchShipmentRowsResponseBodyDriverToJSON(value.driver),
  };
}
