/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { PriceBasis, PriceBasisFromJSON, PriceBasisFromJSONTyped, PriceBasisToJSON } from './PriceBasis';
import {
  ShipmentStateEnum,
  ShipmentStateEnumFromJSON,
  ShipmentStateEnumFromJSONTyped,
  ShipmentStateEnumToJSON,
} from './ShipmentStateEnum';

/**
 * A Shipment serialized for an organization with limited information.
 * @export
 * @interface OrganizationShipment
 */
export interface OrganizationShipment {
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  readonly organization_id: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  billing_office_organization_specific_id: string;
  /**
   *
   * @type {ShipmentStateEnum}
   * @memberof OrganizationShipment
   */
  state: ShipmentStateEnum | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  orderer: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  orderer_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  job_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  other_contract_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  pickup_office_organization_specific_id: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  pickup_name: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  pickup_address: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  pickup_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  pickup_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  pickup_phone_number_secondary: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  pickup_city: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  delivery_office_organization_specific_id: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  delivery_name: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  delivery_address: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  delivery_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  delivery_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  delivery_city: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  note: string | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  length_ldm: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  weight_kg: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  chargeable_weight_kg: number | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  volume_m3: number;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  delivered_at: Date | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  order_in_load: number | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  delivery_window_starts_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  delivery_window_ends_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  recipient: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  reference_number: string | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  agreed_delivery_window_starts_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  agreed_delivery_window_ends_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  pickup_window_starts_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  pickup_window_ends_at: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipment
   */
  is_express_delivery: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipment
   */
  requires_combination_vehicle: boolean;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  readonly load_id: number | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  billed_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  readonly updated_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  readonly updated_by: string | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  ordered_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  picked_up_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  arrived_to_pickup_location_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipment
   */
  arrived_to_delivery_location_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  readonly customer_distribution_area?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  pricing_model: number | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipment
   */
  is_adr_delivery: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  delivery_phone_number_secondary: string | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  custom_pricing_category_id: number | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipment
   */
  unloaded_with_customer_permission: boolean;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  price?: number | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  legacy_price_basis?: string | null;
  /**
   *
   * @type {PriceBasis}
   * @memberof OrganizationShipment
   */
  price_basis?: PriceBasis;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  price_basis_str?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipment
   */
  has_contract_price?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipment
   */
  pricing_failed?: boolean;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  legacy_etaisyys_field?: number | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipment
   */
  hourly_work_hours?: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipment
   */
  billing_reference_number: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipment
   */
  requires_hoist: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipment
   */
  is_line_haul_transport: boolean;
}

export function OrganizationShipmentFromJSON(json: any): OrganizationShipment {
  return OrganizationShipmentFromJSONTyped(json, false);
}

export function OrganizationShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationShipment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    organization_id: json['organization_id'],
    billing_office_organization_specific_id: json['billing_office_organization_specific_id'],
    state: ShipmentStateEnumFromJSON(json['state']),
    orderer: json['orderer'],
    orderer_phone_number: json['orderer_phone_number'],
    job_number: json['job_number'],
    other_contract_number: json['other_contract_number'],
    pickup_office_organization_specific_id: json['pickup_office_organization_specific_id'],
    pickup_name: json['pickup_name'],
    pickup_address: json['pickup_address'],
    pickup_postal_code: json['pickup_postal_code'],
    pickup_phone_number: json['pickup_phone_number'],
    pickup_phone_number_secondary: json['pickup_phone_number_secondary'],
    pickup_city: json['pickup_city'],
    delivery_office_organization_specific_id: json['delivery_office_organization_specific_id'],
    delivery_name: json['delivery_name'],
    delivery_address: json['delivery_address'],
    delivery_postal_code: json['delivery_postal_code'],
    delivery_phone_number: json['delivery_phone_number'],
    delivery_city: json['delivery_city'],
    note: json['note'],
    length_ldm: json['length_ldm'],
    weight_kg: json['weight_kg'],
    chargeable_weight_kg: json['chargeable_weight_kg'],
    volume_m3: json['volume_m3'],
    delivered_at: json['delivered_at'] === null ? null : new Date(json['delivered_at']),
    order_in_load: json['order_in_load'],
    delivery_window_starts_at:
      json['delivery_window_starts_at'] === null ? null : new Date(json['delivery_window_starts_at']),
    delivery_window_ends_at:
      json['delivery_window_ends_at'] === null ? null : new Date(json['delivery_window_ends_at']),
    recipient: json['recipient'],
    reference_number: json['reference_number'],
    agreed_delivery_window_starts_at:
      json['agreed_delivery_window_starts_at'] === null ? null : new Date(json['agreed_delivery_window_starts_at']),
    agreed_delivery_window_ends_at:
      json['agreed_delivery_window_ends_at'] === null ? null : new Date(json['agreed_delivery_window_ends_at']),
    pickup_window_starts_at:
      json['pickup_window_starts_at'] === null ? null : new Date(json['pickup_window_starts_at']),
    pickup_window_ends_at: json['pickup_window_ends_at'] === null ? null : new Date(json['pickup_window_ends_at']),
    is_express_delivery: json['is_express_delivery'],
    requires_combination_vehicle: json['requires_combination_vehicle'],
    load_id: json['load_id'],
    billed_at: json['billed_at'] === null ? null : new Date(json['billed_at']),
    updated_at: json['updated_at'] === null ? null : new Date(json['updated_at']),
    updated_by: json['updated_by'],
    ordered_at: json['ordered_at'] === null ? null : new Date(json['ordered_at']),
    picked_up_at: json['picked_up_at'] === null ? null : new Date(json['picked_up_at']),
    arrived_to_pickup_location_at:
      json['arrived_to_pickup_location_at'] === null ? null : new Date(json['arrived_to_pickup_location_at']),
    arrived_to_delivery_location_at:
      json['arrived_to_delivery_location_at'] === null ? null : new Date(json['arrived_to_delivery_location_at']),
    customer_distribution_area: !exists(json, 'customer_distribution_area')
      ? undefined
      : json['customer_distribution_area'],
    pricing_model: json['pricing_model'],
    is_adr_delivery: json['is_adr_delivery'],
    delivery_phone_number_secondary: json['delivery_phone_number_secondary'],
    custom_pricing_category_id: json['custom_pricing_category_id'],
    unloaded_with_customer_permission: json['unloaded_with_customer_permission'],
    price: !exists(json, 'price') ? undefined : json['price'],
    legacy_price_basis: !exists(json, 'legacy_price_basis') ? undefined : json['legacy_price_basis'],
    price_basis: !exists(json, 'price_basis') ? undefined : PriceBasisFromJSON(json['price_basis']),
    price_basis_str: !exists(json, 'price_basis_str') ? undefined : json['price_basis_str'],
    has_contract_price: !exists(json, 'has_contract_price') ? undefined : json['has_contract_price'],
    pricing_failed: !exists(json, 'pricing_failed') ? undefined : json['pricing_failed'],
    legacy_etaisyys_field: !exists(json, 'legacy_etaisyys_field') ? undefined : json['legacy_etaisyys_field'],
    hourly_work_hours: !exists(json, 'hourly_work_hours') ? undefined : json['hourly_work_hours'],
    billing_reference_number: json['billing_reference_number'],
    requires_hoist: json['requires_hoist'],
    is_line_haul_transport: json['is_line_haul_transport'],
  };
}

export function OrganizationShipmentToJSON(value?: OrganizationShipment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    billing_office_organization_specific_id: value.billing_office_organization_specific_id,
    state: ShipmentStateEnumToJSON(value.state),
    orderer: value.orderer,
    orderer_phone_number: value.orderer_phone_number,
    job_number: value.job_number,
    other_contract_number: value.other_contract_number,
    pickup_office_organization_specific_id: value.pickup_office_organization_specific_id,
    pickup_name: value.pickup_name,
    pickup_address: value.pickup_address,
    pickup_postal_code: value.pickup_postal_code,
    pickup_phone_number: value.pickup_phone_number,
    pickup_phone_number_secondary: value.pickup_phone_number_secondary,
    pickup_city: value.pickup_city,
    delivery_office_organization_specific_id: value.delivery_office_organization_specific_id,
    delivery_name: value.delivery_name,
    delivery_address: value.delivery_address,
    delivery_postal_code: value.delivery_postal_code,
    delivery_phone_number: value.delivery_phone_number,
    delivery_city: value.delivery_city,
    note: value.note,
    length_ldm: value.length_ldm,
    weight_kg: value.weight_kg,
    chargeable_weight_kg: value.chargeable_weight_kg,
    volume_m3: value.volume_m3,
    delivered_at: value.delivered_at === null ? null : value.delivered_at.toISOString(),
    order_in_load: value.order_in_load,
    delivery_window_starts_at:
      value.delivery_window_starts_at === null ? null : value.delivery_window_starts_at.toISOString(),
    delivery_window_ends_at:
      value.delivery_window_ends_at === null ? null : value.delivery_window_ends_at.toISOString(),
    recipient: value.recipient,
    reference_number: value.reference_number,
    agreed_delivery_window_starts_at:
      value.agreed_delivery_window_starts_at === null ? null : value.agreed_delivery_window_starts_at.toISOString(),
    agreed_delivery_window_ends_at:
      value.agreed_delivery_window_ends_at === null ? null : value.agreed_delivery_window_ends_at.toISOString(),
    pickup_window_starts_at:
      value.pickup_window_starts_at === null ? null : value.pickup_window_starts_at.toISOString(),
    pickup_window_ends_at: value.pickup_window_ends_at === null ? null : value.pickup_window_ends_at.toISOString(),
    is_express_delivery: value.is_express_delivery,
    requires_combination_vehicle: value.requires_combination_vehicle,
    billed_at: value.billed_at === null ? null : value.billed_at.toISOString().substr(0, 10),
    ordered_at: value.ordered_at === null ? null : value.ordered_at.toISOString(),
    picked_up_at: value.picked_up_at === null ? null : value.picked_up_at.toISOString(),
    arrived_to_pickup_location_at:
      value.arrived_to_pickup_location_at === null ? null : value.arrived_to_pickup_location_at.toISOString(),
    arrived_to_delivery_location_at:
      value.arrived_to_delivery_location_at === null ? null : value.arrived_to_delivery_location_at.toISOString(),
    pricing_model: value.pricing_model,
    is_adr_delivery: value.is_adr_delivery,
    delivery_phone_number_secondary: value.delivery_phone_number_secondary,
    custom_pricing_category_id: value.custom_pricing_category_id,
    unloaded_with_customer_permission: value.unloaded_with_customer_permission,
    price: value.price,
    legacy_price_basis: value.legacy_price_basis,
    price_basis: PriceBasisToJSON(value.price_basis),
    price_basis_str: value.price_basis_str,
    has_contract_price: value.has_contract_price,
    pricing_failed: value.pricing_failed,
    legacy_etaisyys_field: value.legacy_etaisyys_field,
    hourly_work_hours: value.hourly_work_hours,
    billing_reference_number: value.billing_reference_number,
    requires_hoist: value.requires_hoist,
    is_line_haul_transport: value.is_line_haul_transport,
  };
}
