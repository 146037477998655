import React, { Dispatch, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import { Action, GeographicalAreaField, getInitialState, PricingAreaField, reducer, State } from './pricingModel.state';
import {
  api,
  GeographicalAreaPostBody,
  GeographicalAreaPostBodyAreaTypeEnum,
  PricingArea,
  PricingModelWithAreasDistancePricingBasisEnum,
  PricingModelWithAreasPostBody,
  PricingModelWithAreasPostBodyDistancePricingBasisEnum,
  PricingModelWithAreasPostBodyPricingUnitEnum,
} from '../../api';
import { useNavigate } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AllPricingModelFields, PricingAndGeographicalAreaSection } from './components/inputs';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { StyledForm } from '../../components/StyledComponents/StyledForm';

const getGeographicalAreas = (
  pricingAreaId: PricingArea['id'],
  pricingAreaFields: PricingAreaField[],
  geographicalAreaFields: GeographicalAreaField[],
) => {
  const pricingAreaGeographicalAreaFields: GeographicalAreaField[] = geographicalAreaFields.filter(
    (geographicalAreaField) => geographicalAreaField.geographicalArea.pricing_area === pricingAreaId,
  );
  const updatedAreaType = pricingAreaFields.find(
    (pricingAreaField) => pricingAreaField.pricingArea.id === pricingAreaId,
  )?.areaType;
  const updatedGeographicalAreas = pricingAreaGeographicalAreaFields.map((geographicalAreaField) => {
    const updatedGeographicalArea: GeographicalAreaPostBody = {
      area_type: updatedAreaType as unknown as GeographicalAreaPostBodyAreaTypeEnum,
      name: geographicalAreaField.value,
    };
    return updatedGeographicalArea;
  });
  return updatedGeographicalAreas;
};
const getPricingModelWithAreasPostBodyFromState = (state: State): PricingModelWithAreasPostBody => {
  const updatedPricingAreas = state.pricingAreaFields.map((pricingArea) => {
    return {
      name: pricingArea.value,
      pricing_model: pricingArea.pricingArea.pricing_model,
      geographical_areas: getGeographicalAreas(
        pricingArea.pricingArea.id,
        state.pricingAreaFields,
        state.geographicalAreaFields,
      ),
    };
  });
  return {
    name: state.fields.name.value,
    hourly_price: state.fields.hourly_price.value || 0,
    hourly_price_of_combined_vehicle: state.fields.hourly_price_of_combined_vehicle.value || 0,
    express_delivery_price: state.fields.express_delivery_price.value || 0,
    default_pricing_area: state.fields.default_pricing_area.value || null,
    default_custom_pricing_category: state.fields.default_custom_pricing_category.value || null,
    pricing_unit: state.fields.pricing_unit.value as unknown as PricingModelWithAreasPostBodyPricingUnitEnum,
    distance_pricing_basis: state.fields.distance_pricing_basis
      .value as unknown as PricingModelWithAreasPostBodyDistancePricingBasisEnum,
    legacy_combined_pricing_for_same_day_deliveries_in_same_address:
      state.fields.legacy_combined_pricing_for_same_day_deliveries_in_same_address.value || false,
    legacy_chargeable_weight_flooring: state.fields.legacy_chargeable_weight_flooring.value || false,
    legacy_ignore_basic_price_if_hours_exist_and_not_exception:
      state.fields.legacy_ignore_basic_price_if_hours_exist_and_not_exception.value || false,
    legacy_ignore_working_hours: state.fields.legacy_ignore_working_hours.value || false,
    pricing_areas: updatedPricingAreas,
    pricing_rows: [],
    car_pricing_rows: [],
  };
};
const saveNewPricingModelWithAreas = async (
  state: State,
  dispatch: Dispatch<Action>,
  navigate: ReturnType<typeof useNavigate>,
) => {
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });

  try {
    const pricingModelResponse = await api.pricing.createPricingModel({
      pricingModelWithAreasPostBody: getPricingModelWithAreasPostBodyFromState(state),
    });
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Hinnoittelumalli tallennettu!' } });
    setTimeout(() => {
      dispatch({ type: 'SET_LOADING', payload: false });

      navigate(`/pricing_models/${pricingModelResponse.data.id}`);
    }, 1000);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

const CreatePricingModel: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const navigate = useNavigate();

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title="Uusi hinnoittelumalli">
            <SaveButton
              disabled={!state.isValid || state.isLoading}
              id="save-pricing-model-button"
              tooltip={!state.isValid ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                saveNewPricingModelWithAreas(state, dispatch, navigate);
                event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
              }}
            >
              Luo uusi hinnoittelumalli
            </SaveButton>
          </Header>
        </HeaderContainer>
        <AllPricingModelFields state={state} dispatch={dispatch} showId={false} />
      </StyledForm>
      {state.fields.distance_pricing_basis.value === PricingModelWithAreasDistancePricingBasisEnum.FixedArea ? (
        <PricingAndGeographicalAreaSection state={state} dispatch={dispatch} disabled={false} />
      ) : null}
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default CreatePricingModel;
