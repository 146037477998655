import { Dispatch } from 'react';
import { api, Distance, getAllPages } from '../../api';
import { TableState } from 'react-table';
import { updateViewSettings } from '../settings';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';

export interface DistancesListViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
}

export interface State {
  distances: Distance[];
  notification: NotificationType;
  isLoading: boolean;
  viewSettings: DistancesListViewSettings;
}

export const initialState: State = {
  distances: [],
  notification: {
    message: null,
  },
  isLoading: true,
  viewSettings: {
    filters: [],
    sortBy: [],
    hiddenColumns: [],
  },
};

export type Action =
  | { type: 'SET_DISTANCES'; payload: Distance[] }
  | SetMessageAction
  | SetLoadingAction
  | { type: 'SET_VIEW_SETTINGS'; payload: DistancesListViewSettings };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_DISTANCES':
      return {
        ...state,
        distances: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_VIEW_SETTINGS':
      updateViewSettings('distances_list', action.payload);
      return {
        ...state,
        viewSettings: action.payload,
      };
  }
};

export const load = async (state: State, dispatch: Dispatch<Action>): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const distancesResponse = await getAllPages(
      api.distances.getDistancesWithFailedCalculation.bind(api.distances),
      {},
    );
    dispatch({
      type: 'SET_DISTANCES',
      payload: distancesResponse,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa etäisyyksiä',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};
