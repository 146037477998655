/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ShipmentPricingPostBody
 */
export interface ShipmentPricingPostBody {
  /**
   *
   * @type {Date}
   * @memberof ShipmentPricingPostBody
   */
  date: Date;
  /**
   *
   * @type {string}
   * @memberof ShipmentPricingPostBody
   */
  organization_id: string;
}

export function ShipmentPricingPostBodyFromJSON(json: any): ShipmentPricingPostBody {
  return ShipmentPricingPostBodyFromJSONTyped(json, false);
}

export function ShipmentPricingPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentPricingPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: new Date(json['date']),
    organization_id: json['organization_id'],
  };
}

export function ShipmentPricingPostBodyToJSON(value?: ShipmentPricingPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date.toISOString().substr(0, 10),
    organization_id: value.organization_id,
  };
}
