/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A single work hours.
 * @export
 * @interface WorkHour
 */
export interface WorkHour {
  /**
   *
   * @type {number}
   * @memberof WorkHour
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof WorkHour
   */
  employee_number: string | null;
  /**
   *
   * @type {Date}
   * @memberof WorkHour
   */
  date: Date | null;
  /**
   *
   * @type {string}
   * @memberof WorkHour
   */
  starts_at: string | null;
  /**
   *
   * @type {string}
   * @memberof WorkHour
   */
  ends_at: string | null;
  /**
   *
   * @type {number}
   * @memberof WorkHour
   */
  net_hours: number | null;
  /**
   *
   * @type {number}
   * @memberof WorkHour
   */
  evening_hours: number | null;
  /**
   *
   * @type {number}
   * @memberof WorkHour
   */
  night_hours: number | null;
  /**
   *
   * @type {number}
   * @memberof WorkHour
   */
  assignment_id: number;
  /**
   *
   * @type {string}
   * @memberof WorkHour
   */
  note: string | null;
  /**
   *
   * @type {Date}
   * @memberof WorkHour
   */
  received_at: Date | null;
}

export function WorkHourFromJSON(json: any): WorkHour {
  return WorkHourFromJSONTyped(json, false);
}

export function WorkHourFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkHour {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    employee_number: json['employee_number'],
    date: json['date'] === null ? null : new Date(json['date']),
    starts_at: json['starts_at'],
    ends_at: json['ends_at'],
    net_hours: json['net_hours'],
    evening_hours: json['evening_hours'],
    night_hours: json['night_hours'],
    assignment_id: json['assignment_id'],
    note: json['note'],
    received_at: json['received_at'] === null ? null : new Date(json['received_at']),
  };
}

export function WorkHourToJSON(value?: WorkHour | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employee_number: value.employee_number,
    date: value.date === null ? null : value.date.toISOString(),
    starts_at: value.starts_at,
    ends_at: value.ends_at,
    net_hours: value.net_hours,
    evening_hours: value.evening_hours,
    night_hours: value.night_hours,
    assignment_id: value.assignment_id,
    note: value.note,
    received_at: value.received_at === null ? null : value.received_at.toISOString(),
  };
}
