/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { TimeOff, TimeOffFromJSON, TimeOffFromJSONTyped, TimeOffToJSON } from './TimeOff';
import { WorkHour, WorkHourFromJSON, WorkHourFromJSONTyped, WorkHourToJSON } from './WorkHour';

/**
 * A collection of work hours.
 * @export
 * @interface DriverWithWorkHoursAndTimeOffs
 */
export interface DriverWithWorkHoursAndTimeOffs {
  /**
   *
   * @type {number}
   * @memberof DriverWithWorkHoursAndTimeOffs
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof DriverWithWorkHoursAndTimeOffs
   */
  employee_number: string | null;
  /**
   *
   * @type {string}
   * @memberof DriverWithWorkHoursAndTimeOffs
   */
  readonly employee_name: string;
  /**
   *
   * @type {string}
   * @memberof DriverWithWorkHoursAndTimeOffs
   */
  profession?: DriverWithWorkHoursAndTimeOffsProfessionEnum;
  /**
   *
   * @type {Array<WorkHour>}
   * @memberof DriverWithWorkHoursAndTimeOffs
   */
  work_hours: Array<WorkHour>;
  /**
   *
   * @type {Array<TimeOff>}
   * @memberof DriverWithWorkHoursAndTimeOffs
   */
  time_offs: Array<TimeOff>;
}

/**
 * @export
 * @enum {string}
 */
export enum DriverWithWorkHoursAndTimeOffsProfessionEnum {
  Kuski = 'kuski',
  Appari = 'appari',
  Muu = 'muu',
  Alihankkija = 'alihankkija',
  Asentaja = 'asentaja',
  Toimihenkilo = 'toimihenkilo',
}

export function DriverWithWorkHoursAndTimeOffsFromJSON(json: any): DriverWithWorkHoursAndTimeOffs {
  return DriverWithWorkHoursAndTimeOffsFromJSONTyped(json, false);
}

export function DriverWithWorkHoursAndTimeOffsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DriverWithWorkHoursAndTimeOffs {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    employee_number: json['employee_number'],
    employee_name: json['employee_name'],
    profession: !exists(json, 'profession') ? undefined : json['profession'],
    work_hours: (json['work_hours'] as Array<any>).map(WorkHourFromJSON),
    time_offs: (json['time_offs'] as Array<any>).map(TimeOffFromJSON),
  };
}

export function DriverWithWorkHoursAndTimeOffsToJSON(value?: DriverWithWorkHoursAndTimeOffs | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employee_number: value.employee_number,
    profession: value.profession,
    work_hours: (value.work_hours as Array<any>).map(WorkHourToJSON),
    time_offs: (value.time_offs as Array<any>).map(TimeOffToJSON),
  };
}
