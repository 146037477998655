/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PhotoFileResponseBody
 */
export interface PhotoFileResponseBody {
  /**
   *
   * @type {Blob}
   * @memberof PhotoFileResponseBody
   */
  photo?: Blob;
}

export function PhotoFileResponseBodyFromJSON(json: any): PhotoFileResponseBody {
  return PhotoFileResponseBodyFromJSONTyped(json, false);
}

export function PhotoFileResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoFileResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    photo: !exists(json, 'photo') ? undefined : json['photo'],
  };
}

export function PhotoFileResponseBodyToJSON(value?: PhotoFileResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    photo: value.photo,
  };
}
