import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material';
import { Link as RouterLink, useOutletContext, useParams } from 'react-router-dom';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from '../../components/Notification';
import { SaveButton } from '../../components/SaveButton';
import React, { useEffect, useState } from 'react';
import {
  api,
  apiV2,
  CarrierCar,
  CarrierLoadPutBodyStateEnum,
  Driver,
  getAllPages,
  CarrierLoad,
  LoadStateEnum,
  Client,
  Shipment,
  ShipmentRowPostPutBodyStateEnum,
  ShipmentsPatchBody,
  ShipmentStateEnum,
  CarrierLoadStateEnum,
  getData,
  CarType,
} from '../../api';
import { DateTime } from 'luxon';
import { Loading } from '../../components/Loading';
import Main from '../../components/Main';
import { Header } from '../../components/Header';
import { LoadShipmentsTable } from '../../components/LoadShipmentsTable';
import { CarPicker } from '../../components/CarPicker';
import { DriverPicker } from '../../components/DriverPicker';
import { FieldSet } from '../../components/StyledComponents/FieldSet';
import { orderShipments, reverseShipments, roundToTwoDecimals } from '../../utils';
import { ShipmentsModal } from './components/ShipmentsModal';
import { isEqual, orderBy, uniqBy } from 'lodash';
import { Add, Map } from '@mui/icons-material';
import { Address, getFormattedAddresses, RouteMap } from '../../components/map';
import { FilterCheckbox } from '../../components/FilterCheckbox';
import { TooltipButton } from '../../components/TooltipButton';
import { TrailerPicker, WarningIcon } from '../../components/TrailerPicker';
import { getPreciseDeliveryShipments } from '../../components/DeliveryWindow';
import { dateNotRequired } from '../../validation';
import { FieldSetContainer } from '../../components/StyledComponents/FieldSetContainer';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { ButtonContainer } from '../../components/StyledComponents/ButtonContainer';
import {
  convertToUTCNoon,
  StandardDatePicker,
  todayNoon,
} from '../../components/DateAndTimePickers/StandardDatePicker';
import { StandardTimePicker } from '../../components/DateAndTimePickers/StandardTimePicker';
import { CarrierUser } from '../../reducers/authReducer';
import { ClientPicker } from '../../components/ClientPicker';

export const TopRow = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
});

const OPTIMIZE_SHIPMENTS_MAX_AMOUNT = 25;

type EditLoadParams = {
  loadId?: string;
};

export function getLoadSize(
  shipments: Shipment[],
): Pick<CarrierLoad, 'chargeable_weight_kg' | 'weight_kg' | 'volume_m3' | 'length_ldm'> {
  return shipments.reduce(
    (memo, shipment) => ({
      chargeable_weight_kg: roundToTwoDecimals(memo.chargeable_weight_kg + (shipment.chargeable_weight_kg ?? 0)),
      weight_kg: roundToTwoDecimals(memo.weight_kg + shipment.weight_kg),
      volume_m3: roundToTwoDecimals(memo.volume_m3 + shipment.volume_m3),
      length_ldm: roundToTwoDecimals(memo.length_ldm + shipment.length_ldm),
    }),
    {
      chargeable_weight_kg: 0,
      weight_kg: 0,
      volume_m3: 0,
      length_ldm: 0,
    },
  );
}

function getNormalizedAddress(
  address: string | null,
  city: string | null,
  postalCode: string | null,
  type: 'pickup' | 'delivery',
) {
  return {
    type,
    address: address?.toLocaleLowerCase().trim() ?? undefined,
    city: city?.toLocaleLowerCase().trim() ?? undefined,
    postalCode: postalCode?.toLocaleLowerCase().trim() ?? undefined,
  };
}

/**
 * Builds an array of addresses to be shown on map.
 * Joins all consecutive identical pickup addresses together, but does not touch delivery addresses.
 */
function getRoute(shipments: Shipment[], hidePickups: boolean): Address[] {
  const shipmentsInLoadOrder = orderBy(shipments, 'order_in_load', 'asc');
  let route: (Address & { type: 'pickup' | 'delivery' })[] = [];
  // Get all addresses into one array in their initial order
  for (const shipment of shipmentsInLoadOrder) {
    route.push(
      getNormalizedAddress(shipment.pickup_address, shipment.pickup_city, shipment.pickup_postal_code, 'pickup'),
    );
    route.push(
      getNormalizedAddress(
        shipment.delivery_address,
        shipment.delivery_city,
        shipment.delivery_postal_code,
        'delivery',
      ),
    );
  }
  route = route.filter((address, index) => {
    if (address.type === 'delivery') {
      return true;
    }
    if (address.type === 'pickup' && index > 0 && hidePickups) {
      return false;
    }
    const previousPickupAddress = route
      .slice(0, index)
      .filter((address) => address.type === 'pickup')
      .slice(-1)[0];
    return !isEqual(previousPickupAddress, address);
  });
  return route;
}

export const EditLoad: React.FC = () => {
  const carrierUser = useOutletContext<CarrierUser>();
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationType>({ message: null });
  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };
  const { loadId } = useParams<EditLoadParams>();
  const parsedLoadId = parseInt(loadId || '');
  const [load, setLoad] = useState<Omit<CarrierLoad, 'id' | 'carrier_id'>>({
    state: CarrierLoadStateEnum.Uusi,
    drive_date: null,
    driver_id: 0,
    car_id: null,
    organization_id: null,
    picked_up_at: null,
    driver_started_at: null,
    driver_ended_at: null,
    chargeable_weight_kg: 0,
    weight_kg: 0,
    volume_m3: 0,
    length_ldm: 0,
    note: null,
    trailer_id: null,
  });
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [originalShipments, setOriginalShipments] = useState<Shipment[]>([]);
  const [loads, setLoads] = useState<CarrierLoad[]>([]);
  const [cars, setCars] = useState<CarrierCar[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState<string | undefined>(undefined);
  const [route, setRoute] = useState<Address[]>([]);
  const [hidePickups, setHidePickups] = useState(false);
  const [isShipmentsModalOpen, setIsShipmentsModalOpen] = useState(false);
  const [isShowMapButtonVisible, setIsShowMapButtonVisible] = useState(false);
  const [isShowLargeMapButtonVisible, setIsShowLargeMapButtonVisible] = useState(false);
  const [isReverseShipmentsButtonTooltipVisible, setIsReverseShipmentsButtonTooltipVisible] = useState(false);
  const [isOptimizeRouteButtonTooltipVisible, setIsOptimizeRouteButtonTooltipVisible] = useState(false);
  const [isAddShipmentsButtonVisible, setIsAddShipmentsButtonVisible] = useState(false);
  const [openUnloadAllShipmentsOfLoadDialog, setOpenUnloadAllShipmentsOfLoadDialog] = React.useState(false);

  const handleClickUnloadAllShipmentsOfLoadDialogOpen = () => {
    setOpenUnloadAllShipmentsOfLoadDialog(true);
  };
  const handleUnloadAllShipmentsOfLoadDialogClose = () => {
    setOpenUnloadAllShipmentsOfLoadDialog(false);
  };

  const unloadAllShipmentsOfLoad = async (shipments: Shipment[]) => {
    const shipmentPatchBodyPromises = shipments.map(async ({ id }) => {
      const shipmentRows = (await api.shipments.getShipmentRows({ shipmentId: id })).data;
      return {
        id,
        state: ShipmentStateEnum.Noudettavissa,
        rows: shipmentRows.map(({ id }) => {
          return {
            id,
            state: ShipmentRowPostPutBodyStateEnum.ReadyForPickup,
          };
        }),
      };
    });
    const shipmentPatchBodies = await Promise.all(shipmentPatchBodyPromises);
    await api.shipments.patchShipments({ shipmentsPatchBody: shipmentPatchBodies });
  };

  async function saveLoad() {
    const pickedUpTime = load.picked_up_at ? DateTime.fromJSDate(load.picked_up_at) : null;
    await apiV2.carrier.putCarrierLoad({
      carrierId: carrierUser.carrier_id,
      loadId: parsedLoadId,
      carrierLoadPutBody: {
        ...load,
        picked_up_at:
          load.drive_date && pickedUpTime
            ? DateTime.fromJSDate(load.drive_date)
                .set({
                  hour: pickedUpTime.hour,
                  minute: pickedUpTime.minute,
                  second: 0,
                  millisecond: 0,
                })
                .toJSDate()
            : null,
        state: load.state as unknown as CarrierLoadPutBodyStateEnum,
      },
    });
  }

  async function patchShipments() {
    const shipmentsToRemove = originalShipments.filter(
      (originalShipment) => !shipments.some((shipment) => shipment.id === originalShipment.id),
    );
    const shipmentsToAddPatchBody: ShipmentsPatchBody[] = shipments.map(({ id, order_in_load }) => ({
      id,
      load_id: parsedLoadId,
      order_in_load,
    }));
    const shipmentsToRemovePatchBody: ShipmentsPatchBody[] = shipmentsToRemove.map(({ id }) => ({
      id,
      load_id: null,
      order_in_load: null,
    }));
    const shipmentsToUpdate = shipmentsToAddPatchBody.concat(shipmentsToRemovePatchBody);
    await api.shipments.patchShipments({ shipmentsPatchBody: shipmentsToUpdate });
  }

  async function loadView() {
    setIsLoading(true);
    try {
      const [loadShipmentsResponse, loadResponse, carsResponse, driversResponse, keysResponse] = await Promise.all([
        api.loads.getLoadShipments({
          loadId: parsedLoadId,
        }),
        apiV2.carrier.getCarrierLoad({
          carrierId: carrierUser.carrier_id,
          loadId: parsedLoadId,
        }),
        getData(apiV2.carrier.getCarrierCars.bind(apiV2.carrier), { carrierId: carrierUser.carrier_id }),
        getAllPages(api.drivers.getDrivers.bind(api.drivers), {}),
        api.keys.getAPIKeys(),
      ]);
      setLoad(loadResponse.data);
      setShipments(loadShipmentsResponse.data);
      setOriginalShipments(loadShipmentsResponse.data);
      setCars(carsResponse.filter((x) => x.is_active));
      setDrivers(driversResponse);
      setClients(carrierUser.clients);
      setGoogleMapsApiKey(keysResponse.google_maps_api_key);
    } catch (err) {
      setNotification({ message: 'Kuorman lataus epäonnistui!', severity: 'error' });
    }
    setIsLoading(false);
  }

  const refreshLoads = async () => {
    const driveDateNoonOrTodayNoon = load.drive_date
      ? convertToUTCNoon(DateTime.fromJSDate(load.drive_date))
      : todayNoon;
    const loadsResponse = await apiV2.carrier.getCarrierLoads({
      carrierId: carrierUser.carrier_id,
      driveDateRangeStartsAt: driveDateNoonOrTodayNoon,
      driveDateRangeEndsAt: driveDateNoonOrTodayNoon,
    });
    setLoads(loadsResponse.data);
  };

  useEffect(() => {
    setLoad({ ...load, ...getLoadSize(shipments) });
    setRoute(getRoute(shipments, hidePickups));
    if (load.drive_date && DateTime.fromJSDate(load.drive_date).isValid) {
      refreshLoads();
    }
  }, [shipments, load.driver_id, hidePickups, load.drive_date]);

  useEffect(() => {
    loadView();
  }, []);

  const isDriveDateValid = load.drive_date
    ? DateTime.fromJSDate(load.drive_date).isValid && !Boolean(dateNotRequired.validate(load.drive_date).error)
    : true;

  const canSave =
    load.organization_id &&
    load.driver_id &&
    load.car_id &&
    load.drive_date &&
    isDriveDateValid &&
    (load.picked_up_at ? DateTime.fromJSDate(load.picked_up_at).isValid : true);

  const onShipmentUpdate = (shipments: Shipment[]) => {
    setShipments(shipments);
  };

  const optimizeRoute = async () => {
    let shipmentsInOptimalRoute;
    try {
      shipmentsInOptimalRoute = await api.loads.getLoadOptimizedRoute({
        loadId: parsedLoadId,
      });
      setShipments(
        shipmentsInOptimalRoute.data.map((shipment: Shipment, index: number) => {
          return (shipment = { ...shipment, order_in_load: index + 1 });
        }),
      );
    } catch (err) {
      setNotification({ message: 'Kuormaa ei optimoitu, tarkista osoitteet!', severity: 'error' });
      console.error(err);
    }
  };

  const client = clients.find((client) => client.id === load.organization_id);

  const isOptimizationDisabled = !isEqual(
    shipments.map((shipment) => shipment.id).sort(),
    originalShipments.map((shipment) => shipment.id).sort(),
  );

  const shipmentsWithPreciseDelivery = getPreciseDeliveryShipments(shipments);

  return (
    <Main>
      <Loading isLoading={isLoading} />
      <Header title={`Kuorma ${parsedLoadId}`}>
        <SaveButton
          onClick={async () => {
            setIsLoading(true);
            try {
              await saveLoad();
              await patchShipments();
              await loadView();
              setNotification({ message: 'Kuorma tallennettu!' });
            } catch (err) {
              setNotification({ message: 'Kuorman tallennus epäonnistui! Yritä uudelleen', severity: 'error' });
            }
            setIsLoading(false);
          }}
          disabled={isLoading || !canSave}
          tooltip={!canSave ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
        >
          Tallenna kuorma
        </SaveButton>
      </Header>
      <Loading isLoading={isLoading} />
      <StyledForm noValidate autoComplete="off">
        <Loading isLoading={isLoading} />
        <FieldSetContainer>
          <FieldSet>
            <legend>Perustiedot</legend>
            <TopRow>
              <StandardDatePicker
                disabled={isLoading}
                label="Ajopäivä"
                slotProps={{
                  textField: {
                    name: 'drive_date',
                    error: !isDriveDateValid,
                    helperText: dateNotRequired.validate(load.drive_date).error?.message,
                    required: true,
                  },
                }}
                onChange={(date) => setLoad({ ...load, drive_date: date })}
                value={load.drive_date}
              />
              <StandardTimePicker
                disabled={isLoading}
                ampm={false}
                label="Lähtöaika"
                value={load.picked_up_at}
                slotProps={{
                  textField: {
                    name: 'picked_up_at',
                    required: false,
                  },
                }}
                onChange={(time) => {
                  setLoad({
                    ...load,
                    picked_up_at: time,
                  });
                }}
              />
              <FormControl>
                <InputLabel shrink id="state_label">
                  Tila
                </InputLabel>
                <Select
                  data-cy="state-picker"
                  labelId="state_label"
                  disabled={isLoading}
                  value={load.state}
                  onChange={(event) =>
                    setLoad({
                      ...load,
                      state: event.target.value as CarrierLoadStateEnum,
                    })
                  }
                >
                  <MenuItem key={LoadStateEnum.Uusi} value={LoadStateEnum.Uusi}>
                    Uusi
                  </MenuItem>
                  <MenuItem key={LoadStateEnum.Jarjestelty} value={LoadStateEnum.Jarjestelty}>
                    Järjestelty
                  </MenuItem>
                  <MenuItem key={LoadStateEnum.Noudettu} value={LoadStateEnum.Noudettu}>
                    Noudettu
                  </MenuItem>
                  <MenuItem key={LoadStateEnum.Toimitettu} value={LoadStateEnum.Toimitettu}>
                    Toimitettu
                  </MenuItem>
                  <MenuItem key={LoadStateEnum.Laskutettavissa} value={LoadStateEnum.Laskutettavissa}>
                    Laskutettavissa
                  </MenuItem>
                  <MenuItem key={LoadStateEnum.Laskutettu} value={LoadStateEnum.Laskutettu}>
                    Laskutettu
                  </MenuItem>
                </Select>
              </FormControl>
            </TopRow>
            <ClientPicker
              disabled={isLoading}
              clients={clients}
              value={clients.find((client) => client.id === load.organization_id) ?? null}
              onChange={(client) => setLoad({ ...load, organization_id: client !== null ? client.id : null })}
              required
            />
            <CarPicker
              disabled={isLoading}
              cars={cars.filter((car) => car.type !== CarType.Pervaunu)}
              value={cars.find((car) => car.id === load.car_id) ?? null}
              onChange={(car) =>
                setLoad({
                  ...load,
                  car_id: car !== null ? car.id : null,
                  driver_id: car !== null ? car.default_driver_id : null,
                  trailer_id: car !== null ? car.default_trailer_id : null,
                })
              }
            />
            <DriverPicker
              disabled={isLoading}
              drivers={drivers}
              value={drivers.find((driver) => driver.id === load.driver_id) ?? null}
              onChange={(driver) => setLoad({ ...load, driver_id: driver !== null ? driver.id : null })}
              required={true}
            />
            <TrailerPicker
              disabled={isLoading}
              currentLoad={load as CarrierLoad}
              loads={loads}
              loadShipments={shipments}
              drivers={drivers}
              cars={cars.filter((car) => car.type === CarType.Pervaunu)}
              value={cars.find((trailer) => trailer.id === load.trailer_id) ?? null}
              onChange={(trailer) =>
                setLoad({
                  ...load,
                  trailer_id: trailer !== null ? trailer.id : null,
                })
              }
            />
            {shipmentsWithPreciseDelivery.length > 0 ? (
              <FormHelperText data-cy="precise-delivery-helper-text">
                <WarningIcon />{' '}
                {shipmentsWithPreciseDelivery.length === 1
                  ? 'Kuormassa on täsmätoimitus. '
                  : 'Kuormassa on täsmätoimituksia. '}
                (
                {shipmentsWithPreciseDelivery.map((shipment, index) => (
                  <span key={shipment.id}>
                    <Link component={RouterLink} to={{ pathname: `/shipments/${shipment.id}` }}>
                      {shipment.id}
                    </Link>
                    <span>{index < shipmentsWithPreciseDelivery.length - 1 ? ', ' : ''}</span>
                  </span>
                ))}
                )
              </FormHelperText>
            ) : null}
          </FieldSet>
          <FieldSet>
            <legend>Koko</legend>
            <FormControl>
              <InputLabel htmlFor="weight_kg">Paino</InputLabel>
              <Input
                disabled={true}
                id="weight_kg"
                name="weight_kg"
                type="number"
                startAdornment={<InputAdornment position="start">kg</InputAdornment>}
                value={load.weight_kg}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="chargeable_weight_kg">Rahdituspaino</InputLabel>
              <Input
                disabled={true}
                id="chargeable_weight_kg"
                name="chargeable_weight_kg"
                type="number"
                startAdornment={<InputAdornment position="start">kg</InputAdornment>}
                value={load.chargeable_weight_kg}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="volume_m3">Tilavuus</InputLabel>
              <Input
                disabled={true}
                id="volume_m3"
                name="volume_m3"
                type="number"
                startAdornment={<InputAdornment position="start">m³</InputAdornment>}
                value={load.volume_m3}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="length_ldm">Lavametrit</InputLabel>
              <Input
                disabled={true}
                id="length_ldm"
                name="length_ldm"
                type="number"
                startAdornment={<InputAdornment position="start">lvm</InputAdornment>}
                value={load.length_ldm}
              />
            </FormControl>
          </FieldSet>
          <FieldSet>
            <legend>Lisätietoja</legend>
            <TextField
              multiline={true}
              disabled={isLoading}
              required={false}
              name="note"
              label="Lisätietoja"
              value={load.note || ''}
              onChange={(event) => {
                setLoad({
                  ...load,
                  note: event.target.value !== '' ? event.target.value : null,
                });
              }}
            />
          </FieldSet>
          {isMapVisible && googleMapsApiKey && (
            <RouteMap addresses={route} onClose={() => setIsMapVisible(false)} apiKey={googleMapsApiKey} />
          )}
        </FieldSetContainer>
        <ButtonContainer sx={{ padding: '0.25rem' }}>
          <TooltipButton
            data-cy="add-shipments-button"
            disabled={load.organization_id === null || isLoading}
            onClick={() => {
              setIsShipmentsModalOpen(true);
            }}
            startIcon={<Add />}
            isTooltipVisible={isAddShipmentsButtonVisible}
            tooltip={'Valitse asiakas'}
            onTooltipOpen={() => {
              if (load.organization_id === null) {
                setIsAddShipmentsButtonVisible(true);
              }
            }}
            onTooltipClose={() => setIsAddShipmentsButtonVisible(false)}
          >
            Lisää toimituksia
          </TooltipButton>
          {googleMapsApiKey && (
            <>
              <TooltipButton
                data-cy="show-route-button"
                disabled={isLoading || shipments.length === 0}
                onClick={() => setIsMapVisible(!isMapVisible)}
                startIcon={<Map />}
                isTooltipVisible={isShowMapButtonVisible}
                tooltip={'Ei käytettävissä tyhjän kuorman kanssa'}
                onTooltipOpen={() => {
                  if (shipments.length === 0) {
                    setIsShowMapButtonVisible(true);
                  }
                }}
                onTooltipClose={() => setIsShowMapButtonVisible(false)}
              >
                {isMapVisible ? 'Piilota reitti' : 'Näytä reitti'}
              </TooltipButton>
              <TooltipButton
                data-cy="optimize-route-button"
                disabled={
                  isLoading ||
                  isOptimizationDisabled ||
                  shipments.length < 2 ||
                  shipments.length > OPTIMIZE_SHIPMENTS_MAX_AMOUNT
                }
                onClick={async () => {
                  if (!isMapVisible) {
                    setIsMapVisible(!isMapVisible);
                  }
                  optimizeRoute();
                }}
                isTooltipVisible={isOptimizeRouteButtonTooltipVisible}
                tooltip={
                  isOptimizationDisabled
                    ? 'Ei käytössä tallentamattoman kuorman kanssa'
                    : shipments.length < 2
                      ? 'Kuormassa oltava vähintään kaksi toimitusta'
                      : shipments.length > OPTIMIZE_SHIPMENTS_MAX_AMOUNT
                        ? `Yli ${OPTIMIZE_SHIPMENTS_MAX_AMOUNT} toimituksen kuormia ei voida toistaiseksi optimoida`
                        : ''
                }
                onTooltipOpen={() => {
                  if (
                    isOptimizationDisabled ||
                    shipments.length < 2 ||
                    shipments.length > OPTIMIZE_SHIPMENTS_MAX_AMOUNT
                  ) {
                    setIsOptimizeRouteButtonTooltipVisible(true);
                  }
                }}
                onTooltipClose={() => setIsOptimizeRouteButtonTooltipVisible(false)}
              >
                Optimoi järjestys
              </TooltipButton>
            </>
          )}
          <TooltipButton
            data-cy="reverse-route-button"
            disabled={isLoading || shipments.length < 2}
            onClick={() => {
              setShipments(reverseShipments(shipments));
            }}
            isTooltipVisible={isReverseShipmentsButtonTooltipVisible}
            tooltip={'Kuormassa oltava vähintään kaksi toimitusta'}
            onTooltipOpen={() => {
              if (shipments.length < 2) {
                setIsReverseShipmentsButtonTooltipVisible(true);
              }
            }}
            onTooltipClose={() => setIsReverseShipmentsButtonTooltipVisible(false)}
          >
            Käännä järjestys
          </TooltipButton>
          <TooltipButton
            data-cy="show-large-map-button"
            disabled={isLoading || shipments.length === 0}
            onClick={() => {
              window.open(`https://www.google.com/maps/dir/${encodeURI(getFormattedAddresses(route))}`, '_blank');
            }}
            isTooltipVisible={isShowLargeMapButtonVisible}
            tooltip={'Ei käytettävissä tyhjän kuorman kanssa'}
            onTooltipOpen={() => {
              if (shipments.length === 0) {
                setIsShowLargeMapButtonVisible(true);
              }
            }}
            onTooltipClose={() => setIsShowLargeMapButtonVisible(false)}
          >
            Näytä iso kartta
          </TooltipButton>
          {isMapVisible ? (
            <FilterCheckbox
              label={'Piilota noudot'}
              checked={hidePickups}
              onChange={() => {
                setHidePickups(!hidePickups);
              }}
              name="hide_pickups"
              tooltip="Piilota noudot kartalla."
            />
          ) : null}
          <Button
            data-cy="unload-all-shipments-and-shipment-rows-button"
            disabled={isLoading || shipments.length === 0}
            color="warning"
            onClick={handleClickUnloadAllShipmentsOfLoadDialogOpen}
          >
            Palauta toimitukset noudettavissa tilaan
          </Button>
          <Dialog
            open={openUnloadAllShipmentsOfLoadDialog}
            onClose={handleUnloadAllShipmentsOfLoadDialogClose}
            aria-labelledby="alert-dialog-unload-all-shipments-and-shipment-rows-label"
            aria-describedby="alert-dialog-unload-all-shipments-and-shipment-rows-description"
          >
            <DialogTitle id="alert-dialog-unload-all-shipments-and-shipment-rows-title">
              {'Oletko varma että haluat palauttaa kaikki kuorman toimitukset noudettavissa tilaan?'}
            </DialogTitle>
            <DialogActions>
              <Button
                data-cy="unload-all-shipments-and-shipment-rows-cancel-button"
                onClick={handleUnloadAllShipmentsOfLoadDialogClose}
              >
                Peruuta
              </Button>
              <Button
                data-cy="unload-all-shipments-and-shipment-rows-confirm-button"
                color="warning"
                onClick={async () => {
                  await unloadAllShipmentsOfLoad(shipments);
                  await loadView();
                  handleUnloadAllShipmentsOfLoadDialogClose();
                }}
                autoFocus
              >
                Palauta
              </Button>
            </DialogActions>
          </Dialog>
        </ButtonContainer>
        <LoadShipmentsTable shipments={shipments} onShipmentUpdate={onShipmentUpdate} isLoading={isLoading} />
      </StyledForm>
      {client && (
        <ShipmentsModal
          open={isShipmentsModalOpen}
          client={client}
          shipmentBlacklist={shipments}
          onClose={(newShipments) => {
            setIsShipmentsModalOpen(false);
            setShipments(orderShipments(uniqBy(shipments.concat(newShipments), (s) => s.id)));
          }}
        />
      )}
      <Notification {...snackbarProps} />
    </Main>
  );
};
