import React, { FunctionComponent, useEffect, useMemo, useReducer } from 'react';
import { api, Driver, getAllPages, Load, Organization } from '../../api';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Box, Link, Paper, TableContainer } from '@mui/material';
import { formatDate, formatFloat } from '../../formatters';
import { MRT_Table, MRT_TableOptions, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import MRT from '../../components/MaterialReactTable/MRT';
import { Link as RouterLink } from 'react-router-dom';
import { OrganizationPicker } from '../../components/OrganizationPicker';
import { DriverPicker } from '../../components/DriverPicker';
import { MRT_Localization_FI } from 'material-react-table/locales/fi';
import { Action, getInitialState, reducer, ShipmentWithOrganizationName, State } from './jobs.state';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const VIEW_ID = 'jobs' as const;

const filterShipments = (
  shipments: ShipmentWithOrganizationName[],
  selectedOrganization: Organization | null,
  selectedDriver: Driver | null,
  loads: Load[],
) => {
  const driverLoadIds = loads.filter((load) => load.driver_id === selectedDriver?.id).map((l) => l.id);
  return selectedOrganization || selectedDriver
    ? shipments.filter(
        (shipment) =>
          (selectedOrganization ? shipment.organization_id === selectedOrganization.id : true) &&
          (selectedDriver ? driverLoadIds.includes(shipment?.load_id ?? 0) : true),
      )
    : shipments;
};

const load = async (state: State, dispatch: React.Dispatch<Action>, abortController: AbortController) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [shipments, organizationsResponse, assignmentsResponse, loads, drivers, driversWithWorkHours] =
      await Promise.all([
        getAllPages(
          api.shipments.getShipments.bind(api.shipments),
          {
            agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
            agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
          },
          abortController,
        ),
        api.organizations.getOrganizations({}),
        api.assignments.getAssignments(),
        getAllPages(
          api.loads.getLoads.bind(api.loads),
          {
            driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
            driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
          },
          abortController,
        ),
        getAllPages(api.drivers.getDrivers.bind(api.drivers), {}, abortController),
        getAllPages(
          api.drivers.getDriversWithWorkHoursAndTimeOffs.bind(api.drivers),
          {
            agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
            agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
          },
          abortController,
        ),
      ]);
    dispatch({
      type: 'INITIALIZE',
      payload: {
        shipments,
        organizations: organizationsResponse.data ?? [],
        assignments: assignmentsResponse.data ?? [],
        loads,
        drivers,
        driversWithWorkHours,
      },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa keikkoja',
        severity: 'error',
      },
    });
  } finally {
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  }
};

const Jobs: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser?.organization_id) {
      const abortController = new AbortController();
      load(state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [currentUser, state.dateRange]);

  const columnHelper = createMRTColumnHelper<ShipmentWithOrganizationName>();
  const smallColumnSize = 120;
  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) =>
          `${row.agreed_delivery_window_starts_at && formatDate(row.agreed_delivery_window_starts_at)} - ${
            row.organizationName
          } - ${row.driverName}`,
        {
          id: 'Keikka',
          header: 'Keikka',
          aggregationFn: 'sum',
        },
      ),
      columnHelper.accessor('id', {
        header: 'Toimitus',
        aggregationFn: 'count',
        AggregatedCell: ({ cell }) => <div>{cell.getValue()} kpl</div>,
        Cell: ({ renderedCellValue }) => (
          <Link component={RouterLink} to={{ pathname: `/shipments/${renderedCellValue}` }}>
            {renderedCellValue}
          </Link>
        ),
        filterFn: 'includesString',
      }),
      columnHelper.accessor('reference_number', {
        header: 'Asiakasviite',
      }),
      columnHelper.accessor('weight_kg', {
        header: 'Paino',
      }),
      columnHelper.accessor('hourly_work_hours', {
        header: 'Tunnit',
        aggregationFn: 'sum',
        AggregatedCell: ({ cell, row }) => (
          <div>
            {`${formatFloat(cell.getValue<number>())}h (Keikka: ${formatFloat(
              row.original.sumDriverDailyWorkHours ?? 0,
            )}h)`}
          </div>
        ),
      }),
      columnHelper.accessor('price', {
        header: 'Hinta',
        aggregationFn: 'sum',
        AggregatedCell: ({ cell }) => <div>{formatFloat(cell.getValue<number>())}€</div>,
      }),
    ],
    [],
  );

  const idColumn = columnHelper.accessor('id', {
    header: 'Kpl',
    aggregationFn: 'count',
    AggregatedCell: ({ cell }) => <div>{cell.getValue()} kpl</div>,
    Cell: ({ renderedCellValue }) => (
      <Link component={RouterLink} to={{ pathname: `/shipments/${renderedCellValue}` }}>
        {renderedCellValue}
      </Link>
    ),
    filterFn: 'includesString',
    size: smallColumnSize,
  });

  const priceColumn = columnHelper.accessor('price', {
    header: 'Hinta',
    aggregationFn: 'sum',
    AggregatedCell: ({ cell }) => <div>{formatFloat(cell.getValue<number>())}</div>,
    size: smallColumnSize,
  });

  const organizationColumns = useMemo(
    () => [
      columnHelper.accessor('organizationName', {
        header: 'Asiakas',
      }),
      idColumn,
      priceColumn,
      columnHelper.accessor('sumOrganizationWorkHours', {
        header: 'Tunnit',
        AggregatedCell: ({ cell }) => <div>{formatFloat(cell.getValue<number>())}</div>,
        size: smallColumnSize,
      }),
      columnHelper.display({
        header: '€ / h',
        AggregatedCell: ({ row }) => {
          const sumOrganizationWorkHours = row.original.sumOrganizationWorkHours ?? 0;
          const aggregatedPrices = (row.getGroupingValue('price') as number) ?? 0;
          const pricePerHour =
            sumOrganizationWorkHours && aggregatedPrices ? aggregatedPrices / sumOrganizationWorkHours : 0;
          return (
            <>
              <Box>{formatFloat(pricePerHour)}</Box>
            </>
          );
        },
        size: smallColumnSize,
      }),
    ],
    [],
  );

  const dailyColumns = useMemo(
    () => [
      columnHelper.accessor(
        (row) =>
          row.agreed_delivery_window_starts_at
            ? formatDate(row.agreed_delivery_window_starts_at)
            : row.agreed_delivery_window_starts_at,
        {
          id: 'agreed_delivery_window_starts_at',
          header: 'Päivämäärä',
          filterFn: 'dateFilterFn',
          filterVariant: 'date',
        },
      ),
      idColumn,
      priceColumn,
      columnHelper.accessor('sumDailyWorkHours', {
        header: 'Tunnit',
        AggregatedCell: ({ row }) => <div>{formatFloat(row.original.sumDailyWorkHours ?? 0)}</div>,
        size: smallColumnSize,
      }),
      columnHelper.display({
        header: '€ / h',
        AggregatedCell: ({ row }) => {
          const sumDailyWorkHours = row.original.sumDailyWorkHours ?? 0;
          const aggregatedPrices = (row.getGroupingValue('price') as number) ?? 0;
          const pricePerHour = sumDailyWorkHours && aggregatedPrices ? aggregatedPrices / sumDailyWorkHours : 0;
          return (
            <>
              <Box>{formatFloat(pricePerHour)}</Box>
            </>
          );
        },
        size: smallColumnSize,
      }),
    ],
    [],
  );

  const data = React.useMemo(
    () => filterShipments(state.shipments, state.selectedOrganization, state.selectedDriver, state.loads),
    [state.shipments, state.selectedOrganization, state.selectedDriver],
  );

  const tableOptions: MRT_TableOptions<ShipmentWithOrganizationName> = {
    data,
    columns,
    initialState: {
      expanded: true,
      grouping: ['Keikka'],
      sorting: [{ id: 'Keikka', desc: false }],
      pagination: { pageIndex: 0, pageSize: 500 },
    },
  };

  const sharedTableOptions: Omit<MRT_TableOptions<ShipmentWithOrganizationName>, 'columns'> = {
    data,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnDragging: false,
    enableGrouping: true,
    localization: MRT_Localization_FI,
  };

  const organizationTable = useMaterialReactTable({
    ...sharedTableOptions,
    columns: organizationColumns,
    initialState: {
      density: 'compact',
      grouping: ['organizationName'],
      sorting: [{ id: 'organizationName', desc: false }],
    },
  });

  const dailyTable = useMaterialReactTable({
    ...sharedTableOptions,
    columns: dailyColumns,
    initialState: {
      density: 'compact',
      grouping: ['agreed_delivery_window_starts_at'],
      sorting: [{ id: 'agreed_delivery_window_starts_at', desc: false }],
    },
  });

  const summaryTableStyles = {
    margin: '1rem',
    marginLeft: '0.5rem',
    '.MuiTableCell-root': {
      backgroundColor: 'white',
    },
  };

  return (
    <>
      <MRT
        isLoading={state.isLoading}
        viewId={VIEW_ID}
        header={'Keikat'}
        dispatch={dispatch}
        billingSeasonRange={true}
        customActions={() => (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <OrganizationPicker
              disabled={state.isLoading}
              organizations={state.organizations}
              value={
                state.organizations.find((organization) => organization.id === state.selectedOrganization?.id) ?? null
              }
              onChange={(organization) =>
                dispatch({
                  type: 'SET_SELECTED_ORGANIZATION',
                  payload: organization,
                })
              }
              style={{ width: '14rem', marginRight: '0.5rem' }}
              variant="outlined"
              size="small"
            />
            <DriverPicker
              disabled={state.isLoading}
              drivers={state.drivers}
              value={state.drivers.find((driver) => driver.id === state.selectedDriver?.id) ?? null}
              onChange={(driver) =>
                dispatch({
                  type: 'SET_SELECTED_DRIVER',
                  payload: driver,
                })
              }
              style={{ width: '14rem' }}
              variant="outlined"
              size="small"
              required={false}
            />
          </Box>
        )}
        {...tableOptions}
      />
      <Box sx={{ display: 'flex' }}>
        <TableContainer component={Paper} elevation={3} sx={summaryTableStyles} data-cy="organization-table">
          <MRT_Table table={organizationTable} />
        </TableContainer>
        <TableContainer component={Paper} elevation={3} sx={summaryTableStyles} data-cy="daily-table">
          <MRT_Table table={dailyTable} />
        </TableContainer>
      </Box>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default Jobs;
