import { Link, styled, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ColumnInstance, Row, TableRowProps } from 'react-table';
import { api, OrganizationShipment, Shipment, User } from '../api';
import { Loading } from './Loading';
import { orderBy } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { DeliveryWindow } from './DeliveryWindow';
import { formatDateTime } from '../formatters';
import { SubdirectoryArrowRight } from '@mui/icons-material';
import { canAccessCoordination } from '../utils';

const FreePositionLoadingSpinner = styled(Loading)({
  position: 'unset',
});

type ShipmentOrOrganizationShipment = Shipment | OrganizationShipment;

interface ShipmentSubRowsProps {
  rowProps: TableRowProps;
  visibleColumns: ColumnInstance[];
  shipments: ShipmentOrOrganizationShipment[];
  loading: boolean;
}

const getStateTimestamp = (shipment: ShipmentOrOrganizationShipment): string => {
  const state = shipment.state;
  switch (state) {
    case 'noudettu': {
      return shipment.picked_up_at ? formatDateTime(shipment.picked_up_at) : '';
    }
    case 'toimitettu': {
      return shipment.delivered_at ? formatDateTime(shipment.delivered_at) : '';
    }
    default: {
      return '';
    }
  }
};

export const ShipmentSubRows: React.FC<ShipmentSubRowsProps> = ({ rowProps, visibleColumns, shipments, loading }) => {
  if (loading) {
    return (
      <tr>
        <TableCell />
        <TableCell colSpan={visibleColumns.length - 1}>
          <FreePositionLoadingSpinner isLoading={loading} loadingMessage="Ladataan toimituksia..." />
        </TableCell>
      </tr>
    );
  } else if (!loading && shipments.length === 0) {
    return (
      <tr>
        <TableCell />
        <TableCell colSpan={visibleColumns.length - 1}>Ei toimituksia.</TableCell>
      </tr>
    );
  } else {
    return (
      <>
        {orderBy(shipments, ['delivered_at', 'order_in_load', 'id'], ['asc', 'asc', 'desc']).map((shipment, index) => {
          return (
            <tr {...rowProps} key={`${rowProps.key}-expanded-${index}`}>
              <TableCell style={{ textAlign: 'right' }}>
                <SubdirectoryArrowRight fontSize="small" color="disabled" />
              </TableCell>
              <TableCell>
                <Link component={RouterLink} to={{ pathname: `/shipments/${shipment.id}` }}>
                  {shipment.id ?? ''} / {shipment.reference_number ?? ''}
                </Link>
              </TableCell>
              <TableCell>{shipment.pickup_address ?? ''}</TableCell>
              <TableCell>{shipment.pickup_city ?? ''}</TableCell>
              <TableCell>{shipment.delivery_address ?? ''}</TableCell>
              <TableCell>{shipment.delivery_city ?? ''}</TableCell>
              <TableCell>
                Sovittu toimitusaika{' '}
                <DeliveryWindow
                  startsAt={shipment.agreed_delivery_window_starts_at}
                  endsAt={shipment.agreed_delivery_window_ends_at}
                  onlyTime={true}
                />
              </TableCell>
              <TableCell>
                <div>{shipment.state ? shipment.state : ''}</div>
                <div>{getStateTimestamp(shipment)}</div>
              </TableCell>
              <TableCell colSpan={visibleColumns.length - 1}>{shipment.note ?? ''}</TableCell>
            </tr>
          );
        })}
      </>
    );
  }
};

interface SubRowAsyncProps {
  row: Row;
  rowProps: TableRowProps;
  visibleColumns: ColumnInstance[];
  currentUser?: User;
}

export const ShipmentSubRowAsync: React.FC<SubRowAsyncProps> = ({ row, rowProps, visibleColumns, currentUser }) => {
  const [loading, setLoading] = useState(true);
  const [shipments, setShipments] = useState<ShipmentOrOrganizationShipment[]>([]);

  const load = async () => {
    setLoading(true);
    const shipments = canAccessCoordination(currentUser)
      ? (
          await api.loads.getLoadShipments({
            loadId: Number(row.id),
          })
        ).data
      : (
          await api.organizationLoads.getOrganizationLoadShipments({
            loadId: Number(row.id),
            organizationId: currentUser?.organization_id ?? '',
          })
        ).data;
    setShipments(shipments);
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser) {
      load();
    }
  }, [row.id, currentUser]);

  return (
    <ShipmentSubRows rowProps={rowProps} visibleColumns={visibleColumns} shipments={shipments} loading={loading} />
  );
};
