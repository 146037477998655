/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  DriverDriverGroupsPostBody,
  DriverDriverGroupsPostBodyFromJSON,
  DriverDriverGroupsPostBodyToJSON,
  DriverGroupsDeleteBody,
  DriverGroupsDeleteBodyFromJSON,
  DriverGroupsDeleteBodyToJSON,
  DriverGroupsResponseBody,
  DriverGroupsResponseBodyFromJSON,
  DriverGroupsResponseBodyToJSON,
  EmployeeSmsPostBody,
  EmployeeSmsPostBodyFromJSON,
  EmployeeSmsPostBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  SalaryInfoResponseBody,
  SalaryInfoResponseBodyFromJSON,
  SalaryInfoResponseBodyToJSON,
} from '../models';

export interface EmployeeResourcesApiDeleteDriverGroupsRequest {
  driverId: number;
  driverGroupsDeleteBody: DriverGroupsDeleteBody;
}

export interface EmployeeResourcesApiGetDriverGroupsRequest {
  driverId: number;
}

export interface EmployeeResourcesApiGetEmployeeSalaryInfoByIdRequest {
  employeeNumber: string;
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
}

export interface EmployeeResourcesApiGetEmployeeSalaryInfoExcelRequest {
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
}

export interface EmployeeResourcesApiPostDriverGroupsRequest {
  driverId: number;
  driverDriverGroupsPostBody: DriverDriverGroupsPostBody;
}

export interface EmployeeResourcesApiPostEmployeeSmsRequest {
  employeeSmsPostBody: EmployeeSmsPostBody;
}

/**
 *
 */
export class EmployeeResourcesApi extends runtime.BaseAPI {
  /**
   * Remove driver from given group
   */
  async deleteDriverGroupsRaw(
    requestParameters: EmployeeResourcesApiDeleteDriverGroupsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
      throw new runtime.RequiredError(
        'driverId',
        'Required parameter requestParameters.driverId was null or undefined when calling deleteDriverGroups.',
      );
    }

    if (requestParameters.driverGroupsDeleteBody === null || requestParameters.driverGroupsDeleteBody === undefined) {
      throw new runtime.RequiredError(
        'driverGroupsDeleteBody',
        'Required parameter requestParameters.driverGroupsDeleteBody was null or undefined when calling deleteDriverGroups.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/employees/drivers/{driver_id}/groups`.replace(
          `{${'driver_id'}}`,
          encodeURIComponent(String(requestParameters.driverId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: DriverGroupsDeleteBodyToJSON(requestParameters.driverGroupsDeleteBody),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove driver from given group
   */
  async deleteDriverGroups(
    requestParameters: EmployeeResourcesApiDeleteDriverGroupsRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteDriverGroupsRaw(requestParameters, initOverrides);
  }

  /**
   * Get all driver groups with current driver member status
   */
  async getDriverGroupsRaw(
    requestParameters: EmployeeResourcesApiGetDriverGroupsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverGroupsResponseBody>> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
      throw new runtime.RequiredError(
        'driverId',
        'Required parameter requestParameters.driverId was null or undefined when calling getDriverGroups.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/employees/drivers/{driver_id}/groups`.replace(
          `{${'driver_id'}}`,
          encodeURIComponent(String(requestParameters.driverId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverGroupsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all driver groups with current driver member status
   */
  async getDriverGroups(
    requestParameters: EmployeeResourcesApiGetDriverGroupsRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverGroupsResponseBody> {
    const response = await this.getDriverGroupsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get employee salary info
   */
  async getEmployeeSalaryInfoByIdRaw(
    requestParameters: EmployeeResourcesApiGetEmployeeSalaryInfoByIdRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<SalaryInfoResponseBody>> {
    if (requestParameters.employeeNumber === null || requestParameters.employeeNumber === undefined) {
      throw new runtime.RequiredError(
        'employeeNumber',
        'Required parameter requestParameters.employeeNumber was null or undefined when calling getEmployeeSalaryInfoById.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/employees/salary_info/{employee_number}`.replace(
          `{${'employee_number'}}`,
          encodeURIComponent(String(requestParameters.employeeNumber)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SalaryInfoResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get employee salary info
   */
  async getEmployeeSalaryInfoById(
    requestParameters: EmployeeResourcesApiGetEmployeeSalaryInfoByIdRequest,
    initOverrides?: RequestInit,
  ): Promise<SalaryInfoResponseBody> {
    const response = await this.getEmployeeSalaryInfoByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all salary info in excel format
   */
  async getEmployeeSalaryInfoExcelRaw(
    requestParameters: EmployeeResourcesApiGetEmployeeSalaryInfoExcelRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/employees/salary_info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Get all salary info in excel format
   */
  async getEmployeeSalaryInfoExcel(
    requestParameters: EmployeeResourcesApiGetEmployeeSalaryInfoExcelRequest,
    initOverrides?: RequestInit,
  ): Promise<Blob> {
    const response = await this.getEmployeeSalaryInfoExcelRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Add driver to given group
   */
  async postDriverGroupsRaw(
    requestParameters: EmployeeResourcesApiPostDriverGroupsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
      throw new runtime.RequiredError(
        'driverId',
        'Required parameter requestParameters.driverId was null or undefined when calling postDriverGroups.',
      );
    }

    if (
      requestParameters.driverDriverGroupsPostBody === null ||
      requestParameters.driverDriverGroupsPostBody === undefined
    ) {
      throw new runtime.RequiredError(
        'driverDriverGroupsPostBody',
        'Required parameter requestParameters.driverDriverGroupsPostBody was null or undefined when calling postDriverGroups.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/employees/drivers/{driver_id}/groups`.replace(
          `{${'driver_id'}}`,
          encodeURIComponent(String(requestParameters.driverId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DriverDriverGroupsPostBodyToJSON(requestParameters.driverDriverGroupsPostBody),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Add driver to given group
   */
  async postDriverGroups(
    requestParameters: EmployeeResourcesApiPostDriverGroupsRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.postDriverGroupsRaw(requestParameters, initOverrides);
  }

  /**
   * Send SMS to employee
   */
  async postEmployeeSmsRaw(
    requestParameters: EmployeeResourcesApiPostEmployeeSmsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.employeeSmsPostBody === null || requestParameters.employeeSmsPostBody === undefined) {
      throw new runtime.RequiredError(
        'employeeSmsPostBody',
        'Required parameter requestParameters.employeeSmsPostBody was null or undefined when calling postEmployeeSms.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/employees/sms`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EmployeeSmsPostBodyToJSON(requestParameters.employeeSmsPostBody),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send SMS to employee
   */
  async postEmployeeSms(
    requestParameters: EmployeeResourcesApiPostEmployeeSmsRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.postEmployeeSmsRaw(requestParameters, initOverrides);
  }
}
