/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ShipmentReportPostBody,
  ShipmentReportPostBodyFromJSON,
  ShipmentReportPostBodyToJSON,
  ShipmentReportResponseBody,
  ShipmentReportResponseBodyFromJSON,
  ShipmentReportResponseBodyToJSON,
} from '../models';

export interface ReportsApiGetShipmentReportRequest {
  shipmentReportPostBody: ShipmentReportPostBody;
}

/**
 *
 */
export class ReportsApi extends runtime.BaseAPI {
  /**
   * Get a report which contains shipment aggregates in requested time buckets.
   */
  async getShipmentReportRaw(
    requestParameters: ReportsApiGetShipmentReportRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentReportResponseBody>> {
    if (requestParameters.shipmentReportPostBody === null || requestParameters.shipmentReportPostBody === undefined) {
      throw new runtime.RequiredError(
        'shipmentReportPostBody',
        'Required parameter requestParameters.shipmentReportPostBody was null or undefined when calling getShipmentReport.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/reports/shipments`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ShipmentReportPostBodyToJSON(requestParameters.shipmentReportPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentReportResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a report which contains shipment aggregates in requested time buckets.
   */
  async getShipmentReport(
    requestParameters: ReportsApiGetShipmentReportRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentReportResponseBody> {
    const response = await this.getShipmentReportRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
