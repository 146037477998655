import Joi from 'joi';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { DateTime } from 'luxon';

const stringMinMessage = 'Minimipituus {#limit} merkkiä';
const stringMaxMessage = 'Maksimipituus {#limit} merkkiä';
const numberBaseMessage = 'Arvon pitää olla numero';
const numberMinMessage = 'Minimiarvo {#limit}';
const numberMaxMessage = 'Maksimiarvo {#limit}';
const numberIntegerMessage = 'Arvon pitää olla kokonaisluku';
const requiredMessage = 'Kenttä on pakollinen';

const commonMessages = {
  'string.min': stringMinMessage,
  'string.max': stringMaxMessage,
  'number.min': numberMinMessage,
  'number.max': numberMaxMessage,
  'number.base': numberBaseMessage,
  'number.integer': numberIntegerMessage,
  'number.unsafe': numberMaxMessage,
  'string.empty': requiredMessage,
  'any.required': requiredMessage,
  'any.empty': requiredMessage,
};

export const office = Joi.string().allow('').max(10).messages(commonMessages);
export const postalCode = Joi.string()
  .allow('')
  .regex(/^\d\d\d\d\d$/)
  .messages({ ...commonMessages, 'string.pattern.base': 'Virheellinen postinumero' });
export const phoneNumber = Joi.string()
  .custom((value, helpers) => {
    const isValid = isValidPhoneNumber(value, 'FI');
    if (!isValid) {
      return helpers.error('any.invalid');
    }
    return value;
  })
  .allow('')
  .messages({ 'any.invalid': 'Virheellinen puhelinnumero' });
export const city = Joi.string().max(20).messages(commonMessages);
export const name = Joi.string().allow('').max(50).messages(commonMessages);
export const address = Joi.string().max(50).messages(commonMessages);
export const jobNumber = Joi.string().allow('').max(20).messages(commonMessages);
export const serialNumber = Joi.string().allow(null).allow('').max(40).messages(commonMessages);
export const parcelId = Joi.string().allow(null).allow('').max(20).messages(commonMessages);
export const shipmentRowName = Joi.string().required().max(50).messages(commonMessages);
export const shipmentAdditionalServiceQuantity = Joi.number().min(0).max(1000).messages(commonMessages);
export const quantity = Joi.number()
  .required()
  .integer()
  .min(1)
  .messages({ ...commonMessages, 'number.base': 'Kenttä on pakollinen' });
export const quantityForCoordinators = Joi.number().allow(null).allow('').integer().min(1).messages(commonMessages);
export const weightKg = Joi.number()
  .required()
  .min(0)
  .max(9999999999)
  .messages({
    ...commonMessages,
    'number.min': 'Arvon pitää olla suurempi kuin 0',
    'number.base': 'Kenttä on pakollinen',
  });
export const weightKgAllowNull = weightKg.allow(null).allow('').min(0).messages(commonMessages);
export const weightKgForCoordinators = Joi.number().allow(null).allow('').min(0).messages(commonMessages);
export const lengthLdm = Joi.number().allow('').min(0).max(99).messages(commonMessages);
export const legacyEtaisyysField = Joi.number().allow('').min(0).max(2000).messages(commonMessages);
export const volumeM3 = Joi.number().allow(null).allow('').min(0).max(999).messages(commonMessages);
export const referenceNumber = Joi.string().allow('').max(20).messages(commonMessages);
export const billingReferenceNumber = Joi.string().allow('').max(40).messages(commonMessages);
export const hourlyWorkReason = Joi.string().allow('').max(40).messages(commonMessages);
export const workHours = Joi.number().allow('').min(0).max(1000).messages(commonMessages);
export const price = Joi.number().allow('').min(-10000).max(10000).messages(commonMessages);
export const organizationId = Joi.string()
  .required()
  .max(10)
  .messages({ ...commonMessages, 'any.invalid': 'Id on jo käytössä, valitse toinen' });
export const organizationName = Joi.string().allow('').max(50).messages(commonMessages);
export const additionalServiceName = Joi.string().required().max(50).messages(commonMessages);
export const additionalServicePrices = Joi.number().min(0).max(999).messages(commonMessages);
export const officeOrganizationSpecificId = Joi.string().required().max(10).messages(commonMessages);
export const officeNameAndAddress = Joi.string().required().max(50).messages(commonMessages);
export const officeContactPerson = Joi.string().allow('').max(50).messages(commonMessages);
export const officeCity = Joi.string().required().max(20).messages(commonMessages);
export const assignmentDescription = Joi.string().required().max(50).messages(commonMessages);
export const assignmentSpecificId = Joi.number()
  .integer()
  .min(0)
  .max(99999)
  .messages({ ...commonMessages, 'any.invalid': 'Tunnus on jo käytössä, valitse toinen' });
export const carIdAndWeight = Joi.number()
  .allow('')
  .allow(null)
  .integer()
  .unsafe()
  .min(0)
  .max(99999999999)
  .messages(commonMessages);
export const licencePlate = Joi.string()
  .allow('')
  .max(7)
  .messages({ ...commonMessages, 'any.invalid': 'Rekisterinumero on jo käytössä, valitse toinen' });
export const carIdentifier = Joi.string().allow('').max(7).messages(commonMessages);
export const carNumber = Joi.number()
  .allow('')
  .integer()
  .unsafe()
  .allow(null)
  .min(0)
  .max(9999)
  .messages(commonMessages);
export const carManufacturerVinTransportLicence = Joi.string().allow('').max(20).messages(commonMessages);
export const carModel = Joi.string().allow('').max(30).messages(commonMessages);
export const carImei = Joi.string().allow('').max(15).messages(commonMessages);
export const carTyresHoistPlatformDetails = Joi.string().allow('').max(50).messages(commonMessages);
export const carHeight = Joi.number().allow('').allow(null).unsafe().min(0).max(10).messages(commonMessages);
export const carFuelCardAndId = Joi.string().allow('').max(4).messages(commonMessages);
export const userUsername = Joi.string()
  .allow('')
  .max(20)
  .messages({ ...commonMessages, 'any.invalid': 'Käyttäjätunnus on jo käytössä, valitse toinen' });
export const userTextFields = Joi.string().allow('').max(20).messages(commonMessages);
export const userEmail = Joi.string()
  .email({ tlds: { allow: false } })
  .allow('')
  .max(50)
  .messages({
    ...commonMessages,
    'any.invalid': 'Sähköposti on jo käytössä, valitse toinen',
    'string.email': 'Virheellinen sähköposti',
  });
export const userPassword = Joi.string()
  .allow('')
  .messages({ ...commonMessages, 'any.only': 'Salasanat eivät täsmää' });
export const pricingModelName = Joi.string().required().max(50).messages(commonMessages);
export const pricingModelPrices = Joi.number().required().min(0).max(999).messages(commonMessages);
export const pricingModelDefaultPricingArea = Joi.number()
  .allow('')
  .allow(null)
  .min(0)
  .max(99999)
  .messages(commonMessages);
export const pricingModelPostalCode = Joi.string()
  .regex(/^\d\d\d\d\d$/)
  .messages({ ...commonMessages, 'string.pattern.base': 'Virheellinen postinumero' });
export const hinnastoAlueAndKoko = Joi.number().integer().min(1).max(99).messages(commonMessages);
export const pricingRowPrice = Joi.number().required().min(0).max(9999).messages(commonMessages);
export const carPricingRowPrice = Joi.number().allow('').min(0).max(9999).messages(commonMessages);
export const pricingAreaName = Joi.string().max(50).messages(commonMessages);
export const employeeNumber = Joi.string()
  .allow('')
  .max(6)
  .messages({ ...commonMessages, 'any.invalid': 'Työntekijänumero on jo käytössä, valitse toinen' });
export const employeePostalCode = Joi.string()
  .allow('')
  .regex(/^\d\d\d\d\d$/)
  .messages({ ...commonMessages, 'string.pattern.base': 'Virheellinen postinumero' });
export const pricingModelArea = Joi.number()
  .required()
  .integer()
  .min(1)
  .max(99999)
  .messages({ ...commonMessages, 'any.invalid': 'Arvo on jo käytössä, valitse toinen' });
export const carServiceTitleAndDescription = Joi.string().required().messages(commonMessages);
const minMaxDate = Joi.date()
  .min('1949-12-31T22:00:00.000Z')
  .max('2100-01-01T21:59:59.999Z')
  .messages({
    ...commonMessages,
    'date.base': 'Virheellinen päivämäärä',
    'date.min': 'Aikaisin sallitu päivämäärä 1.1.1950',
    'date.max': 'Myöhäisin sallittu päivämäärä 1.1.2100',
  });
export const dateNotRequired = minMaxDate.allow(null);
export const dateRequired = minMaxDate.required();
export const timeRequired = minMaxDate.required().messages({
  ...commonMessages,
  'date.base': 'Virheellinen aika',
});
export const dateWithMinAndMaxRequired = (minDate: DateTime, maxDate: DateTime) =>
  Joi.date()
    .min(minDate.toJSDate())
    .max(maxDate.toJSDate())
    .messages({
      ...commonMessages,
      'date.base': 'Virheellinen päivämäärä',
      'date.min': 'Tuntikirjaus palkkakauden ulkopuolella',
      'date.max': 'Tuntikirjaus palkkakauden ulkopuolella',
    });
export const groupName = Joi.string()
  .required()
  .max(20)
  .messages({ ...commonMessages, 'any.invalid': 'Ryhmän nimi on jo käytössä, valitse toinen' });
export const assignmentIdRequired = Joi.number()
  .integer()
  .messages({ ...commonMessages, 'number.base': 'Kenttä on pakollinen' })
  .required();
export const columnLayoutName = Joi.string().required().max(36).messages(commonMessages);
export const typeRequired = Joi.string().messages(commonMessages).required();
