/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A geographical area that belongs to some larger PricingArea.
 * @export
 * @interface GeographicalAreaPostBody
 */
export interface GeographicalAreaPostBody {
  /**
   *
   * @type {number}
   * @memberof GeographicalAreaPostBody
   */
  pricing_area?: number;
  /**
   *
   * @type {string}
   * @memberof GeographicalAreaPostBody
   */
  area_type: GeographicalAreaPostBodyAreaTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GeographicalAreaPostBody
   */
  name: string;
}

/**
 * @export
 * @enum {string}
 */
export enum GeographicalAreaPostBodyAreaTypeEnum {
  PostalCode = 'postal_code',
  City = 'city',
}

export function GeographicalAreaPostBodyFromJSON(json: any): GeographicalAreaPostBody {
  return GeographicalAreaPostBodyFromJSONTyped(json, false);
}

export function GeographicalAreaPostBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GeographicalAreaPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pricing_area: !exists(json, 'pricing_area') ? undefined : json['pricing_area'],
    area_type: json['area_type'],
    name: json['name'],
  };
}

export function GeographicalAreaPostBodyToJSON(value?: GeographicalAreaPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pricing_area: value.pricing_area,
    area_type: value.area_type,
    name: value.name,
  };
}
