import React, { useEffect, useState } from 'react';
import { api, AviShipment, AviShipmentProducts, AviShipmentRows } from '../../api';
import {
  Box,
  Card,
  CardContent,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { HeaderTableCell } from '../shipments/components/UpdateHistory';
import theme from '../../theme';
import TimecapLogo from '../../../static/img/timecaplogo.png';
import OnninenLogo from '../../../static/img/onninen.png';
import FesconLogo from '../../../static/img/fescon.png';
import PeriLogo from '../../../static/img/peri.png';
import Krautalogo from '../../../static/img/krauta.png';
import { DateTime } from 'luxon';
import { Organizations, dateFormat, timeFormat } from '../../formatters';
import { Loading } from '../../components/Loading';
import { Address, MapIFrameLocation } from '../../components/map';

const StyledTableRow = styled(TableRow)({
  '&:last-child td': {
    borderBottom: 0,
  },
});

const spacing = { xs: 1, sm: 2 };

const customerServicePhoneNumber = '+358 10 205 8000';

const Header: React.FC<{ header: string; bold?: boolean }> = ({ header, bold }) => {
  return (
    <Typography
      variant="h4"
      gutterBottom
      sx={{ color: theme.palette.primary.dark, fontWeight: bold ? 'bold' : undefined }}
    >
      {header}
    </Typography>
  );
};

const CustomerService: React.FC = () => {
  return (
    <Stack data-cy="customer_service" spacing={spacing} margin={spacing}>
      <Box sx={{ textAlign: 'center' }}>
        <Header header="Pyytämäänne avisointitietoa ei ole saatavilla." bold={true} />
      </Box>
      <Card>
        <CardContent>
          <Typography>Timecap Oy</Typography>
          <Typography>Asiakaspalvelu</Typography>
          <Link href={`tel:${customerServicePhoneNumber}`}>{customerServicePhoneNumber}</Link>
          <Typography>asiakaspalvelu@timecap.fi</Typography>
        </CardContent>
      </Card>
      <Footer />
    </Stack>
  );
};

const Logo: React.FC<{ shipment: AviShipment | null }> = ({ shipment }) => {
  const getOrganizationLogo = (organizationId: string | undefined) => {
    switch (organizationId) {
      case Organizations.Onninen: {
        return { img: OnninenLogo, alt: Organizations.Onninen };
      }
      case Organizations.Peri: {
        return { img: PeriLogo, alt: Organizations.Peri };
      }
      case Organizations.Fescon: {
        return { img: FesconLogo, alt: Organizations.Fescon };
      }
      case Organizations.Krautayp: {
        return { img: Krautalogo, alt: Organizations.Krautayp };
      }
      default: {
        return { img: TimecapLogo, alt: Organizations.Timecap };
      }
    }
  };
  const organizationLogo = getOrganizationLogo(shipment?.organization_id);

  return (
    <Box data-cy="logo" sx={{ textAlign: 'center' }}>
      <Box>
        <img alt={organizationLogo.alt} src={organizationLogo.img} width={'200rem'} />
      </Box>
    </Box>
  );
};

const DeliveryInfo: React.FC<{ shipment: AviShipment | null }> = ({ shipment }) => {
  const deliveryInfo = shipment?.delivered_at ?? shipment?.agreed_delivery_window_starts_at;
  if (!deliveryInfo) {
    return <></>;
  }
  const formattedDate = DateTime.fromJSDate(deliveryInfo).toFormat(dateFormat);
  const formattedTime = DateTime.fromJSDate(deliveryInfo).toFormat(timeFormat);
  const headerText = shipment?.delivered_at
    ? `Seuraavat tavarat on toimitettu Teille ${formattedDate} klo ${formattedTime}.`
    : `Seuraavat tavarat toimitetaan Teille ${formattedDate}`;

  return (
    <Box data-cy="delivery_info" sx={{ textAlign: 'center' }}>
      <Header header={headerText} bold={true} />
      {shipment?.recipient ? <Header header={`Tavaran vastaanottaja: ${shipment?.recipient}`} /> : null}
    </Box>
  );
};

const PickUp: React.FC<{ shipment: AviShipment | null }> = ({ shipment }) => {
  return (
    <Card data-cy="pick_up" variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Header header={'Lähettäjä'} />
        <Typography>{shipment?.pickup_name}</Typography>
        <Typography>{shipment?.pickup_address}</Typography>
        <Typography>
          {shipment?.pickup_postal_code} {shipment?.pickup_city}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Delivery: React.FC<{ shipment: AviShipment | null }> = ({ shipment }) => {
  return (
    <Card data-cy="delivery" variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Header header={'Vastaanottaja'} />
        <Typography>{shipment?.delivery_name}</Typography>
        <Typography>{shipment?.delivery_address}</Typography>
        <Typography>
          {shipment?.delivery_postal_code} {shipment?.delivery_city}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Car: React.FC<{ shipment: AviShipment | null }> = ({ shipment }) => {
  const customerService =
    shipment?.organization_id === Organizations.Onninen ? '+358 10 205 8100' : customerServicePhoneNumber;
  return (
    <Card data-cy="car" variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Header header={'Rahdinkuljettaja'} />
        <Typography>Timecap Oy</Typography>
        {shipment?.licence_plate ? <Typography>Auto: {shipment?.licence_plate}</Typography> : null}
        <Typography>Asiakaspalvelu</Typography>
        <Link href={`tel:${customerService}`}>{customerService}</Link>
      </CardContent>
    </Card>
  );
};

const Map: React.FC<{ shipment: AviShipment | null }> = ({ shipment }) => {
  if (
    !shipment?.delivery_address ||
    !shipment.delivery_city ||
    !shipment.delivery_postal_code ||
    !shipment.google_maps_api_key
  ) {
    return;
  }
  const formattedAddress: Address = {
    address: shipment?.delivery_address,
    city: shipment?.delivery_city,
    postalCode: shipment?.delivery_postal_code,
  };

  return (
    <Card data-cy="map" style={{ display: 'flex', width: '100%', height: '14rem' }}>
      <MapIFrameLocation address={formattedAddress} apiKey={shipment.google_maps_api_key} />
    </Card>
  );
};

const ShipmentRows: React.FC<{ rows: AviShipmentRows[] }> = ({ rows }) => {
  const hasShipmentRows = rows.length > 0;
  if (!hasShipmentRows) {
    return <></>;
  }
  return (
    <TableContainer data-cy="shipment_rows" component={Card} variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Header header={'Kollit'} />
        <Table size="small">
          <TableHead>
            <TableRow data-cy="shipment_rows_head">
              <HeaderTableCell>Selite</HeaderTableCell>
              <HeaderTableCell align="right">Määrä</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <StyledTableRow data-cy="shipment_rows_body" key={index}>
                  <TableCell sx={{ paddingLeft: '0.25rem' }}>{row.parcel_type ?? '-'}</TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </TableContainer>
  );
};

const ShipmentRowProducts: React.FC<{ products: AviShipmentProducts[] }> = ({ products }) => {
  const hasShipmentRowProducts = products.length > 0;
  if (!hasShipmentRowProducts) {
    return <></>;
  }
  return (
    <TableContainer data-cy="shipment_row_products" component={Card} variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Header header={'Tuotteet'} />
        <Table size="small">
          <TableHead data-cy="shipment_row_products_head">
            <TableRow>
              <HeaderTableCell>Selite</HeaderTableCell>
              <HeaderTableCell align="right">Määrä</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => {
              return (
                <StyledTableRow key={index} data-cy="shipment_row_products_body">
                  <TableCell sx={{ paddingLeft: '0.25rem' }}>{product.name ?? '-'}</TableCell>
                  <TableCell align="right">{product.quantity}</TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </TableContainer>
  );
};

const Footer: React.FC = () => {
  return (
    <Box data-cy="footer" sx={{ textAlign: 'center', lineHeight: 0, paddingTop: '1rem' }}>
      <Box>
        <Link href="https://www.timecap.fi">
          <img alt="Timecap Logo" src={TimecapLogo} width={'200rem'} />
        </Link>
      </Box>
      <Box>
        <Typography variant="caption">© Timecap Oy {DateTime.local().year}.</Typography>
      </Box>
    </Box>
  );
};

type AviShipmentParams = {
  aviUrl?: string;
};

const Avi: React.FC = () => {
  const { aviUrl } = useParams<AviShipmentParams>();
  const [shipment, setShipment] = useState<AviShipment | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const loadShipment = async () => {
    try {
      if (aviUrl) {
        const shipment = await api.avi.getShipmentByAviUrl({ aviUrl: aviUrl });
        setShipment(shipment.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadShipment();
  }, []);

  if (loading) {
    return <Loading isLoading={loading} />;
  }

  if (!shipment || !aviUrl) {
    return <CustomerService />;
  }

  return (
    <Stack spacing={spacing} margin={spacing}>
      <Logo shipment={shipment} />
      <DeliveryInfo shipment={shipment} />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={spacing}>
        <PickUp shipment={shipment} />
        <Delivery shipment={shipment} />
        <Car shipment={shipment} />
      </Stack>
      <Stack spacing={spacing}>
        <Map shipment={shipment} />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={spacing}>
        <ShipmentRows rows={shipment?.rows ?? []} />
        <ShipmentRowProducts products={shipment?.products ?? []} />
      </Stack>
      <Footer />
    </Stack>
  );
};

export default Avi;
