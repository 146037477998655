import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { dateFormat } from '../formatters';
import { ArrowForward, DateRange, Refresh } from '@mui/icons-material';
import theme from '../theme';
import { DateCalendar, DateField, PickersDay, PickersDayProps } from '@mui/x-date-pickers';

const MainContainer = styled('div')({
  alignItems: 'right',
  display: 'flex',
  justifyContent: 'flex-end',
  borderRadius: '4px',
  border: '1px solid #C4C4C4',
  padding: '4px 6px 2px 7px',
  '&:hover': { border: '1px solid black' },
});

const StyledDialog = styled(Dialog)({
  margin: 'auto',
  width: 'fit-content',
  display: 'flex',
  flexFlow: 'column nowrap',
});

const StyledDialogContent = styled(DialogContent)({
  justifyContent: 'center',
  display: 'flex',
  flexFlow: 'row wrap',
  padding: 0,
});

export const DatePickerContainer = styled('div')({
  marginTop: '1rem',
  textAlign: 'center',
  display: 'flex',
  flexFlow: 'column wrap',
});

export const HighLightedDay = styled('div')({
  backgroundColor: theme.palette.secondary.light,
});

export const HighLightedDayStart = styled('div')({
  backgroundColor: theme.palette.secondary.light,
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%',
});

export const HighLightedDayEnd = styled('div')({
  backgroundColor: theme.palette.secondary.light,
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%',
});

export interface DateRangePickerProps {
  startDate: DateTime | null;
  endDate: DateTime | null;
  disabled?: boolean;
  onValue: (startDate: DateTime, endDate: DateTime) => void;
}

export const renderDay = (
  props: PickersDayProps<DateTime> & { dialogstartdate?: DateTime | null; dialogenddate?: DateTime | null },
) => {
  const { day, ...other } = props;
  const dayComponent = <PickersDay {...other} day={day} />;
  const datePickerDay = props.day;
  const start = props.dialogstartdate;
  const end = props.dialogenddate;
  if (start && start < datePickerDay && end && end > datePickerDay.plus({ days: 1 })) {
    return <HighLightedDay>{dayComponent}</HighLightedDay>;
  } else if (
    start &&
    start.toFormat(dateFormat) === datePickerDay.toFormat(dateFormat) &&
    end &&
    end.toFormat(dateFormat) !== datePickerDay.toFormat(dateFormat)
  ) {
    return <HighLightedDayStart>{dayComponent}</HighLightedDayStart>;
  } else if (
    start &&
    start.toFormat(dateFormat) !== datePickerDay.toFormat(dateFormat) &&
    end &&
    end.toFormat(dateFormat) === datePickerDay.toFormat(dateFormat)
  ) {
    return <HighLightedDayEnd>{dayComponent}</HighLightedDayEnd>;
  } else {
    return dayComponent;
  }
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ onValue, startDate, endDate, disabled }) => {
  const [openDateRangeDialog, setOpenDateRangeDialog] = React.useState(false);
  const [dateRangePreset, setDateRangePreset] = React.useState<string>('');
  const [dialogStartDate, setDialogStartDate] = React.useState<DateTime | null>(startDate);
  const [dialogEndDate, setDialogEndDate] = React.useState<DateTime | null>(endDate);
  const [openDateRangeQuickMenu, setOpenDateRangeQuickMenu] = React.useState(false);
  const [highlightDateRangePicker, setHighlightDateRangePicker] = useState(false);

  useEffect(() => {
    setDialogStartDate(startDate);
    setDialogEndDate(endDate);
    setHighlightDateRangePicker(false);
  }, [startDate, endDate]);

  const handleKeyboardDateRangeChange = (startDate: DateTime | null, endDate: DateTime | null) => {
    if (
      startDate === undefined ||
      startDate === null ||
      !startDate.isValid ||
      endDate === undefined ||
      endDate === null ||
      !endDate.isValid ||
      startDate > endDate
    ) {
      return;
    }
    setDialogStartDate(startDate);
    setDialogEndDate(endDate);
    setDateRangePreset('');
    onValue(startDate.startOf('day'), endDate.endOf('day'));
  };

  const handleDateRangeChangePreset = (preset: string) => {
    setDateRangePreset(preset);
    let startDate: DateTime | undefined;
    let endDate: DateTime | undefined;
    switch (preset) {
      case 'yesterday': {
        startDate = DateTime.local().minus({ days: 1 });
        endDate = startDate;
        break;
      }
      case 'today': {
        startDate = DateTime.local();
        endDate = startDate;
        break;
      }
      case 'tomorrow': {
        startDate = DateTime.local().plus({ days: 1 });
        endDate = startDate;
        break;
      }
      case 'last30Days': {
        startDate = DateTime.local().minus({ days: 29 });
        endDate = DateTime.local();
        break;
      }
      case 'lastMonth': {
        startDate = DateTime.local().minus({ months: 1 }).startOf('month');
        endDate = DateTime.local().minus({ months: 1 }).endOf('month');
        break;
      }
      case 'currentBillingSeason': {
        if (DateTime.local().day <= 15) {
          startDate = DateTime.local().startOf('month');
          endDate = DateTime.local().set({ day: 15 });
        } else {
          startDate = DateTime.local().set({ day: 16 });
          endDate = DateTime.local().endOf('month');
        }
        break;
      }
      case 'lastBillingSeason': {
        if (DateTime.local().day > 15) {
          startDate = DateTime.local().startOf('month');
          endDate = DateTime.local().set({ day: 15 });
        } else {
          startDate = DateTime.local().minus({ months: 1 }).set({ day: 16 });
          endDate = DateTime.local().minus({ months: 1 }).endOf('month');
        }
        break;
      }
    }
    if (startDate && endDate) {
      setDialogStartDate(startDate);
      setDialogEndDate(endDate);
      onValue(startDate.startOf('day'), endDate.endOf('day'));
    }
  };

  const handleDialogDateRangeChange = (startDate: DateTime | null, endDate: DateTime | null) => {
    setDialogStartDate(startDate);
    setDialogEndDate(endDate);
  };

  const openDialog = () => {
    setOpenDateRangeDialog(true);
  };

  const closeDialog = () => {
    setOpenDateRangeDialog(false);
  };

  const openQuickMenu = () => {
    setOpenDateRangeQuickMenu(true);
  };

  const closeQuickMenu = () => {
    setOpenDateRangeQuickMenu(false);
  };

  const handleDialogDateRangeOk = () => {
    if (dialogStartDate !== null && dialogEndDate !== null) {
      closeDialog();
      onValue(dialogStartDate.startOf('day'), dialogEndDate.endOf('day'));
      setDateRangePreset('');
    }
  };

  return (
    <MainContainer
      style={
        highlightDateRangePicker
          ? {
              border: `2px solid ${theme.palette.primary.main}`,
              padding: '3px 5px 1px 6px',
            }
          : undefined
      }
      className="date-range-picker"
      onFocus={() => {
        setHighlightDateRangePicker(true);
      }}
      onBlur={() => setHighlightDateRangePicker(false)}
    >
      <IconButton
        sx={{ marginRight: '1rem', paddingTop: 0, paddingBottom: 0 }}
        size="small"
        onClick={() => handleKeyboardDateRangeChange(startDate, endDate)}
      >
        <Refresh />
      </IconButton>
      <DateField
        style={{ width: '6rem' }}
        format={dateFormat}
        maxDate={endDate ?? undefined}
        value={startDate}
        onChange={(newStartDate) => {
          handleKeyboardDateRangeChange(newStartDate as DateTime, endDate);
        }}
        variant="standard"
        slotProps={{
          textField: {
            name: 'date_range_start_picker',
            InputProps: { disableUnderline: true },
          },
        }}
      />
      <ArrowForward style={{ margin: '0.25rem 1rem 0.25rem 0.25rem', color: 'rgba(0, 0, 0, 0.54)' }} />
      <DateField
        style={{ width: '6rem' }}
        format={dateFormat}
        minDate={startDate ?? undefined}
        value={endDate}
        onChange={(newEndDate) => {
          handleKeyboardDateRangeChange(startDate, newEndDate);
        }}
        variant="standard"
        slotProps={{
          textField: {
            name: 'date_range_end_picker',
            InputProps: { disableUnderline: true },
          },
        }}
      />
      <IconButton
        className="date-range-select-button"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: '0.25rem',
          paddingLeft: '0.25rem',
          marginRight: '1rem',
        }}
        onClick={openDialog}
      >
        <DateRange />
      </IconButton>
      <FormControl>
        <Select
          className="date-range-quick-menu-button"
          style={{ minWidth: '10.5rem' }}
          disabled={disabled}
          autoWidth={false}
          value={dateRangePreset}
          displayEmpty
          onChange={(event) => {
            handleDateRangeChangePreset(event.target.value as string);
            closeQuickMenu();
          }}
          variant="standard"
          open={openDateRangeQuickMenu}
          onOpen={openQuickMenu}
          onClose={closeQuickMenu}
          disableUnderline
        >
          <DateCalendar
            sx={{ display: 'inline', float: 'right' }}
            onChange={(newDate) => {
              handleKeyboardDateRangeChange(DateTime.fromISO(newDate as string), DateTime.fromISO(newDate as string));
              closeQuickMenu();
            }}
          />
          <MenuItem sx={{ minWidth: '11rem' }} value={''}>
            Pikavalinta
          </MenuItem>
          <MenuItem value={'yesterday'}>Eilen</MenuItem>
          <MenuItem value={'today'}>Tänään</MenuItem>
          <MenuItem value={'tomorrow'}>Huomenna</MenuItem>
          <Divider />
          <MenuItem value={'last30Days'}>Viimeiset 30 päivää</MenuItem>
          <MenuItem value={'lastMonth'}>Viime kuukausi</MenuItem>
          <Divider />
          <MenuItem value={'currentBillingSeason'}>Kuluva laskutuskausi</MenuItem>
          <MenuItem value={'lastBillingSeason'}>Viime laskutuskausi</MenuItem>
        </Select>
      </FormControl>
      <StyledDialog
        open={openDateRangeDialog}
        onClose={closeDialog}
        aria-labelledby="date_range_picker_dialog"
        maxWidth="md"
        fullWidth
      >
        <StyledDialogContent>
          <DatePickerContainer>
            <Typography>Aloituspvm</Typography>
            <DateCalendar
              disabled={disabled}
              maxDate={dialogEndDate ?? undefined}
              value={dialogStartDate}
              onChange={(newStartDate) => handleDialogDateRangeChange(newStartDate, dialogEndDate)}
              slots={{
                day: renderDay,
              }}
              slotProps={{
                day: {
                  dialogstartdate: dialogStartDate,
                  dialogenddate: dialogEndDate,
                } as any,
              }}
            />
          </DatePickerContainer>
          <DatePickerContainer>
            <Typography>Lopetuspvm</Typography>
            <DateCalendar
              disabled={disabled}
              minDate={dialogStartDate ?? undefined}
              value={dialogEndDate}
              onChange={(newEndDate) => handleDialogDateRangeChange(dialogStartDate, newEndDate)}
              slots={{
                day: renderDay,
              }}
              slotProps={{
                day: {
                  dialogstartdate: dialogStartDate,
                  dialogenddate: dialogEndDate,
                } as any,
              }}
            />
          </DatePickerContainer>
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={closeDialog} variant="text" className="date-range-picker-cancel-button">
            Peruuta
          </Button>
          <Button onClick={handleDialogDateRangeOk} variant="text" className="date-range-picker-ok-button">
            Ok
          </Button>
        </DialogActions>
      </StyledDialog>
    </MainContainer>
  );
};
