import { Checkbox, FormControlLabel, InputLabel, styled, TextField } from '@mui/material';
import React, { Dispatch } from 'react';
import { Action, FieldName, State, updateFieldValue } from '../organization.state';
import { getTimeStringOrNull, StringTimePicker } from '../../../components/DateAndTimePickers/StandardTimePicker';

interface InputProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  disabled?: boolean;
}

interface DispatchableInputProps extends InputProps {
  dispatch: Dispatch<Action>;
}

export const Id: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.id.required}
      name="id"
      error={state.fields.id.hasError}
      helperText={state.fields.id.feedback}
      className="id"
      label="Id"
      value={state.fields.id.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const Name: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.name.required}
      name="name"
      error={state.fields.name.hasError}
      helperText={state.fields.name.feedback}
      className="name"
      label="Nimi"
      value={state.fields.name.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const BusinessId: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.business_id.required}
      name="business_id"
      error={state.fields.business_id.hasError}
      helperText={state.fields.business_id.feedback}
      className="business_id"
      label="Y-tunnus"
      value={state.fields.business_id.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};
export const IsActiveCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_active_checkbox"
          name="is_active"
          checked={state.fields.is_active.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_active.value, dispatch)
          }
        />
      }
      label="Aktiivinen"
    />
  );
};

export const SmsCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="send_delivery_notification_sms_checkbox"
          name="send_delivery_notification_sms"
          checked={state.fields.send_delivery_notification_sms.value || false}
          onChange={(event) =>
            updateFieldValue(
              event.target.name as FieldName,
              !state.fields.send_delivery_notification_sms.value,
              dispatch,
            )
          }
        />
      }
      label="Tekstiviesti"
    />
  );
};

const DeliveryTimePickerContainer = styled('div')({
  display: 'flex',
  width: '100%',
  flexFlow: 'row wrap',
  '& > *': {
    flex: 1,
  },
});

export const AgreedDeliveryTimePicker: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <>
      <InputLabel style={{ fontSize: '0.8rem' }}>Vakio toimitusaikaikkuna</InputLabel>
      <DeliveryTimePickerContainer>
        <StringTimePicker
          disabled={disabled}
          ampm={false}
          label="Aikaisintaan"
          value={state.fields.default_agreed_delivery_window_time_starts_at.value}
          slotProps={{
            textField: {
              required: state.fields.default_agreed_delivery_window_time_starts_at.required,
              id: 'default_agreed_delivery_window_time_starts_at',
              name: 'default_agreed_delivery_window_time_starts_at',
              error: state.fields.default_agreed_delivery_window_time_starts_at.hasError,
              helperText: state.fields.default_agreed_delivery_window_time_starts_at.feedback,
            },
          }}
          onChange={(time) => {
            updateFieldValue('default_agreed_delivery_window_time_starts_at', getTimeStringOrNull(time), dispatch);
          }}
        />
        <StringTimePicker
          disabled={disabled}
          ampm={false}
          label="Viimeistään"
          value={state.fields.default_agreed_delivery_window_time_ends_at.value}
          slotProps={{
            textField: {
              required: state.fields.default_agreed_delivery_window_time_ends_at.required,
              id: 'default_agreed_delivery_window_time_ends_at',
              name: 'default_agreed_delivery_window_time_ends_at',
              error: state.fields.default_agreed_delivery_window_time_ends_at.hasError,
              helperText: state.fields.default_agreed_delivery_window_time_ends_at.feedback,
            },
          }}
          onChange={(time) => {
            updateFieldValue('default_agreed_delivery_window_time_ends_at', getTimeStringOrNull(time), dispatch);
          }}
        />
      </DeliveryTimePickerContainer>
    </>
  );
};
