/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShipmentAdditionalService,
  ShipmentAdditionalServiceFromJSON,
  ShipmentAdditionalServiceFromJSONTyped,
  ShipmentAdditionalServiceToJSON,
} from './ShipmentAdditionalService';

/**
 *
 * @export
 * @interface ShipmentAdditionalServiceResponseBody
 */
export interface ShipmentAdditionalServiceResponseBody {
  /**
   *
   * @type {ShipmentAdditionalService}
   * @memberof ShipmentAdditionalServiceResponseBody
   */
  data: ShipmentAdditionalService;
}

export function ShipmentAdditionalServiceResponseBodyFromJSON(json: any): ShipmentAdditionalServiceResponseBody {
  return ShipmentAdditionalServiceResponseBodyFromJSONTyped(json, false);
}

export function ShipmentAdditionalServiceResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShipmentAdditionalServiceResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: ShipmentAdditionalServiceFromJSON(json['data']),
  };
}

export function ShipmentAdditionalServiceResponseBodyToJSON(value?: ShipmentAdditionalServiceResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ShipmentAdditionalServiceToJSON(value.data),
  };
}
