/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRows
 */
export interface ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRows {
  /**
   *
   * @type {number}
   * @memberof ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRows
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRows
   */
  shipment_id: number;
  /**
   *
   * @type {string}
   * @memberof ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRows
   */
  state: ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRowsStateEnum;
  /**
   *
   * @type {string}
   * @memberof ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRows
   */
  joint_package_id?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRowsStateEnum {
  ReadyForPickup = 'ready_for_pickup',
  PickedUp = 'picked_up',
  Delivered = 'delivered',
  Lost = 'lost',
  Failed = 'failed',
}

export function ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRowsFromJSON(
  json: any,
): ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRows {
  return ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRowsFromJSONTyped(json, false);
}

export function ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRowsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRows {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    shipment_id: json['shipment_id'],
    state: json['state'],
    joint_package_id: !exists(json, 'joint_package_id') ? undefined : json['joint_package_id'],
  };
}

export function ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRowsToJSON(
  value?: ApiV1DriversUsernameLoadsLoadIdShipmentsRowsRows | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    shipment_id: value.shipment_id,
    state: value.state,
    joint_package_id: value.joint_package_id,
  };
}
