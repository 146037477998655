/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Photo, PhotoFromJSON, PhotoFromJSONTyped, PhotoToJSON } from './Photo';

/**
 *
 * @export
 * @interface PhotosResponseBody
 */
export interface PhotosResponseBody {
  /**
   *
   * @type {Array<Photo>}
   * @memberof PhotosResponseBody
   */
  data: Array<Photo>;
}

export function PhotosResponseBodyFromJSON(json: any): PhotosResponseBody {
  return PhotosResponseBodyFromJSONTyped(json, false);
}

export function PhotosResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotosResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(PhotoFromJSON),
  };
}

export function PhotosResponseBodyToJSON(value?: PhotosResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(PhotoToJSON),
  };
}
