/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OptionalCarrierDriver
 */
export interface OptionalCarrierDriver {
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierDriver
   */
  full_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierDriver
   */
  phone_number?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OptionalCarrierDriver
   */
  is_at_service?: boolean;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierDriver
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierDriver
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierDriver
   */
  profession?: OptionalCarrierDriverProfessionEnum;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierDriver
   */
  license?: string | null;
  /**
   *
   * @type {Date}
   * @memberof OptionalCarrierDriver
   */
  proactive_driving_expire_date?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OptionalCarrierDriver
   */
  adr_expire_date?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OptionalCarrierDriver
   */
  work_safety_expire_date?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OptionalCarrierDriver
   */
  professional_qualification?: Date | null;
}

/**
 * @export
 * @enum {string}
 */
export enum OptionalCarrierDriverProfessionEnum {
  Kuski = 'kuski',
  Appari = 'appari',
  Muu = 'muu',
  Alihankkija = 'alihankkija',
  Asentaja = 'asentaja',
  Toimihenkilo = 'toimihenkilo',
}

export function OptionalCarrierDriverFromJSON(json: any): OptionalCarrierDriver {
  return OptionalCarrierDriverFromJSONTyped(json, false);
}

export function OptionalCarrierDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalCarrierDriver {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    full_name: !exists(json, 'full_name') ? undefined : json['full_name'],
    phone_number: !exists(json, 'phone_number') ? undefined : json['phone_number'],
    is_at_service: !exists(json, 'is_at_service') ? undefined : json['is_at_service'],
    first_name: !exists(json, 'first_name') ? undefined : json['first_name'],
    last_name: !exists(json, 'last_name') ? undefined : json['last_name'],
    profession: !exists(json, 'profession') ? undefined : json['profession'],
    license: !exists(json, 'license') ? undefined : json['license'],
    proactive_driving_expire_date: !exists(json, 'proactive_driving_expire_date')
      ? undefined
      : json['proactive_driving_expire_date'] === null
        ? null
        : new Date(json['proactive_driving_expire_date']),
    adr_expire_date: !exists(json, 'adr_expire_date')
      ? undefined
      : json['adr_expire_date'] === null
        ? null
        : new Date(json['adr_expire_date']),
    work_safety_expire_date: !exists(json, 'work_safety_expire_date')
      ? undefined
      : json['work_safety_expire_date'] === null
        ? null
        : new Date(json['work_safety_expire_date']),
    professional_qualification: !exists(json, 'professional_qualification')
      ? undefined
      : json['professional_qualification'] === null
        ? null
        : new Date(json['professional_qualification']),
  };
}

export function OptionalCarrierDriverToJSON(value?: OptionalCarrierDriver | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    full_name: value.full_name,
    phone_number: value.phone_number,
    is_at_service: value.is_at_service,
    first_name: value.first_name,
    last_name: value.last_name,
    profession: value.profession,
    license: value.license,
    proactive_driving_expire_date:
      value.proactive_driving_expire_date === undefined
        ? undefined
        : value.proactive_driving_expire_date === null
          ? null
          : value.proactive_driving_expire_date.toISOString(),
    adr_expire_date:
      value.adr_expire_date === undefined
        ? undefined
        : value.adr_expire_date === null
          ? null
          : value.adr_expire_date.toISOString(),
    work_safety_expire_date:
      value.work_safety_expire_date === undefined
        ? undefined
        : value.work_safety_expire_date === null
          ? null
          : value.work_safety_expire_date.toISOString(),
    professional_qualification:
      value.professional_qualification === undefined
        ? undefined
        : value.professional_qualification === null
          ? null
          : value.professional_qualification.toISOString(),
  };
}
