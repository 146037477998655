import { debounce } from 'lodash';
import { Dispatch } from 'react';
import {
  CarPricingRow,
  CarPricingRowCarTypeEnum,
  GeographicalArea,
  GeographicalAreaAreaTypeEnum,
  PricingArea,
  PricingModelWithAreas,
  PricingModelWithAreasDistancePricingBasisEnum,
  PricingModelWithAreasPricingUnitEnum,
  PricingRow,
} from '../../api';
import { pricingModelArea } from '../../validation';
import { validateFields } from './pricingModelValidation';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';

export interface Field<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value: Value;
}

export interface PricingRowFieldValue<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value?: Value;
}

type ServerFieldNames = keyof Pick<
  PricingModelWithAreas,
  | 'id'
  | 'name'
  | 'hourly_price'
  | 'hourly_price_of_combined_vehicle'
  | 'express_delivery_price'
  | 'default_pricing_area'
  | 'default_custom_pricing_category'
  | 'pricing_unit'
  | 'distance_pricing_basis'
  | 'legacy_combined_pricing_for_same_day_deliveries_in_same_address'
  | 'legacy_chargeable_weight_flooring'
  | 'legacy_ignore_basic_price_if_hours_exist_and_not_exception'
  | 'legacy_ignore_working_hours'
>;

export type FieldName = ServerFieldNames;

export interface GeographicalAreaField extends Field<string | ''> {
  geographicalArea: GeographicalArea;
  isNew: boolean;
}

export interface PricingAreaField extends Field<string | ''> {
  pricingArea: Omit<PricingArea, 'geographical_areas'>;
  areaType: GeographicalAreaAreaTypeEnum;
  isNew: boolean;
}

export interface PricingRowField extends PricingRowFieldValue<number | ''> {
  pricingRow: PricingRow;
  pricingDistanceKm?: number | null;
  pricingCustomCategoryId?: number | null;
  pricingWeightKg?: number | null;
  pricingAreaId?: number | null;
}

export interface CarPricingRowField extends PricingRowFieldValue<number | ''> {
  carPricingRow: CarPricingRow;
  isNew: boolean;
}

export interface State {
  fields: {
    [P in ServerFieldNames]: Field<PricingModelWithAreas[P]>;
  };
  originalPricingModel?: PricingModelWithAreas;
  isLoading: boolean;
  loadingMessage?: string;
  notification: NotificationType;
  isValid: boolean;
  pricingAreas: PricingModelWithAreas['pricing_areas'];
  geographicalAreaFields: GeographicalAreaField[];
  pricingAreaFields: PricingAreaField[];
  pricingRowFields: PricingRowField[];
  carPricingRowFields: CarPricingRowField[];
}

export type Action =
  | {
      type: 'VALIDATE_FIELD';
      payload: {
        fieldName: keyof State['fields'];
      };
    }
  | {
      type: 'VALIDATE_FIELDS';
    }
  | {
      type: 'SET_FIELD_VALUE';
      payload: {
        fieldName: keyof State['fields'];
        value: FieldValue;
      };
    }
  | SetMessageAction
  | SetLoadingAction
  | {
      type: 'INITIALIZE_PRICING_MODEL';
      payload: {
        pricingModel: PricingModelWithAreas;
        isNewPricingModel?: boolean;
      };
    }
  | {
      type: 'ADD_PRICING_AREA_FIELD';
      payload: {
        pricingAreaField: PricingAreaField;
      };
    }
  | {
      type: 'UPDATE_PRICING_AREA_FIELD';
      payload: {
        pricingAreaField: PricingAreaField;
        areaType: GeographicalArea['area_type'];
        value: string;
      };
    }
  | {
      type: 'DELETE_PRICING_AREA_FIELD';
      payload: {
        pricingAreaField: PricingAreaField;
      };
    }
  | {
      type: 'ADD_GEOGRAPHICAL_AREA_FIELD';
      payload: {
        pricingAreaField: PricingAreaField;
      };
    }
  | {
      type: 'UPDATE_GEOGRAPHICAL_AREA_FIELD';
      payload: {
        geographicalAreaField: GeographicalAreaField;
        value: string;
      };
    }
  | {
      type: 'DELETE_GEOGRAPHICAL_AREA_FIELD';
      payload: {
        geographicalAreaField: GeographicalAreaField;
      };
    }
  | {
      type: 'UPDATE_PRICING_ROW_VALUE';
      payload: {
        pricingRowField: PricingRowField;
        value?: number;
      };
    }
  | {
      type: 'UPDATE_PRICING_ROW_DISTANCE_KM';
      payload: {
        pricingRowField: PricingRowField;
        pricingDistanceKm?: number;
      };
    }
  | {
      type: 'UPDATE_PRICING_ROW_WEIGHT_KG';
      payload: {
        pricingRowField: PricingRowField;
        pricingWeightKg?: number;
      };
    }
  | {
      type: 'UPDATE_CAR_PRICING_ROW_VALUE';
      payload: {
        carPricingRowField: CarPricingRowField;
        value?: number;
      };
    };

const isDuplicatePricingRows = (pricingRows: PricingRowField[]): boolean => {
  const oldUniqueDistanceKms = pricingRows.map((a) => a.pricingRow.distance_km).filter((e, i, a) => a.indexOf(e) === i);
  const newUniqueDistanceKms = pricingRows.map((a) => a.pricingDistanceKm).filter((e, i, a) => a.indexOf(e) === i);
  const oldUniqueWeights = pricingRows.map((a) => a.pricingRow.weight_kg).filter((e, i, a) => a.indexOf(e) === i);
  const newUniqueWeights = pricingRows.map((a) => a.pricingWeightKg).filter((e, i, a) => a.indexOf(e) === i);
  return (
    oldUniqueDistanceKms.length === newUniqueDistanceKms.length && oldUniqueWeights.length === newUniqueWeights.length
  );
};

const areAllFieldsValid = (
  state: Pick<
    State,
    'fields' | 'geographicalAreaFields' | 'pricingAreaFields' | 'pricingRowFields' | 'carPricingRowFields'
  >,
  isWeightKgBasedPricing: boolean,
  isDistanceKmBasedPricing: boolean,
) => {
  return (
    !Object.values(state.fields).some((field) => field.hasError) &&
    !state.geographicalAreaFields.some((field) => field.hasError) &&
    !state.pricingAreaFields.some((field) => field.hasError) &&
    !state.pricingRowFields.some((field) => field.hasError) &&
    !state.carPricingRowFields.some((field) => field.hasError) &&
    isDuplicatePricingRows(state.pricingRowFields) &&
    (isDistanceKmBasedPricing
      ? !state.pricingRowFields.some(
          (pricingRowField) => pricingModelArea.validate(pricingRowField.pricingDistanceKm).error,
        )
      : true) &&
    (isWeightKgBasedPricing
      ? !state.pricingRowFields.some(
          (pricingRowField) => pricingModelArea.validate(pricingRowField.pricingWeightKg).error,
        )
      : true)
  );
};

export const createGeographicalAreaFields = (pricingAreas: PricingArea[]): GeographicalAreaField[] => {
  const geographicalAreaFields: GeographicalAreaField[] = [];
  pricingAreas.forEach((pricingArea) => {
    pricingArea.geographical_areas.forEach((geographicalArea) => {
      geographicalAreaFields.push({
        required: true,
        hasError: false,
        feedback: undefined,
        value: geographicalArea.name ?? '',
        geographicalArea: geographicalArea,
        isNew: false,
      });
    });
  });
  return geographicalAreaFields;
};

export const createPricingAreaFields = (pricingAreas: PricingArea[]): PricingAreaField[] => {
  const pricingAreaFields: PricingAreaField[] = [];
  const defaultAreaType = pricingAreas
    .map(
      (pricingArea) => pricingArea.geographical_areas.find((geographicalArea) => geographicalArea.area_type)?.area_type,
    )
    .filter((item) => item !== undefined)[0] as GeographicalAreaAreaTypeEnum;
  pricingAreas.forEach((pricingArea) => {
    const { geographical_areas, ...pricingAreaWithoutGeographicalAreas } = pricingArea;
    const pricingAreaAreaType = geographical_areas.find((geographicalArea) => geographicalArea.area_type)?.area_type;
    pricingAreaFields.push({
      required: true,
      hasError: false,
      feedback: undefined,
      value: pricingArea.name ?? '',
      pricingArea: pricingAreaWithoutGeographicalAreas,
      isNew: false,
      areaType: pricingAreaAreaType ? pricingAreaAreaType : defaultAreaType,
    });
  });
  return pricingAreaFields;
};

export const createPricingRowFields = (pricingRows: PricingRow[]): PricingRowField[] => {
  const pricingRowFields: PricingRowField[] = [];
  pricingRows.forEach((pricingRow) => {
    pricingRowFields.push({
      required: true,
      hasError: false,
      feedback: undefined,
      value: pricingRow.price ?? '',
      pricingRow: pricingRow,
      pricingDistanceKm: pricingRow.distance_km ? pricingRow.distance_km : null,
      pricingAreaId: pricingRow.pricing_area_id ? pricingRow.pricing_area_id : null,
      pricingCustomCategoryId: pricingRow.custom_pricing_category_id ? pricingRow.custom_pricing_category_id : null,
      pricingWeightKg: pricingRow.weight_kg,
    });
  });
  return pricingRowFields;
};

export const createCarPricingRowFields = (
  carPricingRows: CarPricingRow[],
  pricingModelId: PricingModelWithAreas['id'],
): CarPricingRowField[] => {
  const carPricingRowFields: CarPricingRowField[] = [];
  (Object.keys(CarPricingRowCarTypeEnum) as (keyof typeof CarPricingRowCarTypeEnum)[]).map((key, index) => {
    const tempId = Date.now() + index;
    const carPricingRow = carPricingRows.find(
      (carPricingRow) => carPricingRow.car_type === CarPricingRowCarTypeEnum[key],
    );
    carPricingRowFields.push({
      required: false,
      hasError: false,
      feedback: undefined,
      value: carPricingRow?.price ?? '',
      carPricingRow: carPricingRow ?? {
        id: tempId,
        price: 0,
        car_pricing_table_id: pricingModelId,
        car_type: CarPricingRowCarTypeEnum[key],
      },
      isNew: !carPricingRow,
    });
  });
  return carPricingRowFields;
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD_VALUE': {
      const fields = {
        ...state.fields,
      };
      fields[action.payload.fieldName].value = action.payload.value;
      return {
        ...state,
        fields,
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'VALIDATE_FIELDS': {
      const validatedState = validateFields(state);
      const isWeightKgBasedPricing =
        state.originalPricingModel?.pricing_unit === PricingModelWithAreasPricingUnitEnum.WeightKg;
      const isDistanceKmBasedPricing =
        state.originalPricingModel?.distance_pricing_basis === PricingModelWithAreasDistancePricingBasisEnum.DistanceKm;
      return {
        ...state,
        ...validatedState,
        isValid: areAllFieldsValid(validatedState, isWeightKgBasedPricing, isDistanceKmBasedPricing),
      };
    }
    case 'INITIALIZE_PRICING_MODEL': {
      const fields = {
        ...state.fields,
      };
      fields.id.value = action.payload.pricingModel.id ?? '';
      fields.name.value = action.payload.pricingModel.name ?? '';
      fields.hourly_price.value = action.payload.pricingModel.hourly_price ?? true;
      fields.hourly_price_of_combined_vehicle.value =
        action.payload.pricingModel.hourly_price_of_combined_vehicle ?? null;
      fields.express_delivery_price.value = action.payload.pricingModel.express_delivery_price ?? '';
      fields.default_pricing_area.value = action.payload.pricingModel.default_pricing_area ?? null;
      fields.default_custom_pricing_category.value =
        action.payload.pricingModel.default_custom_pricing_category ?? null;
      fields.pricing_unit.value = action.payload.pricingModel.pricing_unit =
        (action.payload.pricingModel.pricing_unit as PricingModelWithAreasPricingUnitEnum) ??
        PricingModelWithAreasPricingUnitEnum.WeightKg;
      fields.distance_pricing_basis.value =
        (action.payload.pricingModel.distance_pricing_basis as PricingModelWithAreasDistancePricingBasisEnum) ??
        PricingModelWithAreasDistancePricingBasisEnum.DistanceKm;
      fields.legacy_combined_pricing_for_same_day_deliveries_in_same_address.value =
        action.payload.pricingModel.legacy_combined_pricing_for_same_day_deliveries_in_same_address ?? false;
      fields.legacy_chargeable_weight_flooring.value =
        action.payload.pricingModel.legacy_chargeable_weight_flooring ?? false;
      fields.legacy_ignore_basic_price_if_hours_exist_and_not_exception.value =
        action.payload.pricingModel.legacy_ignore_basic_price_if_hours_exist_and_not_exception ?? false;
      fields.legacy_ignore_working_hours.value = action.payload.pricingModel.legacy_ignore_working_hours ?? false;
      const geographicalAreaFields = createGeographicalAreaFields(action.payload.pricingModel.pricing_areas);
      const pricingAreaFields = createPricingAreaFields(action.payload.pricingModel.pricing_areas);
      const pricingRowFields = createPricingRowFields(action.payload.pricingModel.pricing_rows);
      const carPricingRowFields = createCarPricingRowFields(
        action.payload.pricingModel.car_pricing_rows,
        action.payload.pricingModel.id,
      );
      return {
        ...state,
        fields,
        originalPricingModel: action.payload.pricingModel,
        isValid: false,
        geographicalAreaFields,
        pricingAreaFields,
        pricingRowFields,
        carPricingRowFields,
      };
    }
    case 'ADD_PRICING_AREA_FIELD': {
      const pricingAreaFields = [...state.pricingAreaFields];
      const tempId = Date.now();
      pricingAreaFields.push({
        pricingArea: {
          id: tempId,
          name: '',
          pricing_model:
            action.payload.pricingAreaField && action.payload.pricingAreaField.pricingArea
              ? action.payload.pricingAreaField.pricingArea.pricing_model
              : (state.originalPricingModel?.id ?? 0),
        },
        areaType:
          action.payload.pricingAreaField && action.payload.pricingAreaField.areaType
            ? action.payload.pricingAreaField.areaType
            : GeographicalAreaAreaTypeEnum.PostalCode,
        value: '',
        feedback: undefined,
        hasError: false,
        required: true,
        isNew: true,
      });
      return {
        ...state,
        pricingAreaFields,
      };
    }
    case 'UPDATE_PRICING_AREA_FIELD': {
      const pricingAreaFields = [...state.pricingAreaFields];
      const existingPricingAreaIndex = pricingAreaFields.findIndex(
        (pricingAreaField) => pricingAreaField.pricingArea.id === action.payload.pricingAreaField.pricingArea.id,
      );
      pricingAreaFields[existingPricingAreaIndex].value = action.payload.value;
      pricingAreaFields[existingPricingAreaIndex].areaType = action.payload.areaType;
      return {
        ...state,
        pricingAreaFields,
      };
    }
    case 'DELETE_PRICING_AREA_FIELD': {
      const pricingAreaFields = [...state.pricingAreaFields];
      const updatedpricingAreaFields = pricingAreaFields.filter(
        (pricingAreaField) => pricingAreaField !== action.payload.pricingAreaField,
      );
      return {
        ...state,
        pricingAreaFields: updatedpricingAreaFields,
      };
    }
    case 'ADD_GEOGRAPHICAL_AREA_FIELD': {
      const geographicalAreaFields = [...state.geographicalAreaFields];
      const tempId = Date.now();
      geographicalAreaFields.push({
        geographicalArea: {
          id: tempId,
          name: '',
          pricing_area: action.payload.pricingAreaField.pricingArea.id,
          area_type:
            state.pricingAreaFields.find(
              (pricingAreaField) => pricingAreaField.pricingArea.id === action.payload.pricingAreaField.pricingArea.id,
            )?.areaType ?? GeographicalAreaAreaTypeEnum.PostalCode,
        },
        value: '',
        feedback: undefined,
        hasError: false,
        required: true,
        isNew: true,
      });
      return {
        ...state,
        geographicalAreaFields,
      };
    }
    case 'UPDATE_GEOGRAPHICAL_AREA_FIELD': {
      const geographicalAreaFields = [...state.geographicalAreaFields];
      const existinGeographicalAreaIndex = geographicalAreaFields.findIndex(
        (geographicalAreaField) =>
          geographicalAreaField.geographicalArea.id === action.payload.geographicalAreaField.geographicalArea.id,
      );
      geographicalAreaFields[existinGeographicalAreaIndex].value = action.payload.value;
      geographicalAreaFields[existinGeographicalAreaIndex].geographicalArea.area_type =
        action.payload.geographicalAreaField.geographicalArea.area_type;
      return {
        ...state,
        geographicalAreaFields,
      };
    }
    case 'DELETE_GEOGRAPHICAL_AREA_FIELD': {
      const geographicalAreaFields = [...state.geographicalAreaFields];
      const updatedGeographicalAreaFields = geographicalAreaFields.filter(
        (geographicalAreaField) => geographicalAreaField !== action.payload.geographicalAreaField,
      );
      return {
        ...state,
        geographicalAreaFields: updatedGeographicalAreaFields,
      };
    }
    case 'UPDATE_PRICING_ROW_VALUE': {
      const pricingRowFields = [...state.pricingRowFields];
      const existingPricingRowIndex = pricingRowFields.findIndex(
        (pricingRowField) => pricingRowField.pricingRow.id === action.payload.pricingRowField.pricingRow.id,
      );
      pricingRowFields[existingPricingRowIndex] = action.payload.pricingRowField;
      pricingRowFields[existingPricingRowIndex].value = action.payload.value;
      return {
        ...state,
        pricingRowFields,
      };
    }
    case 'UPDATE_PRICING_ROW_DISTANCE_KM': {
      const pricingRowFields = [...state.pricingRowFields];
      const existingPricingRowIndex = pricingRowFields.findIndex(
        (pricingRowField) => pricingRowField.pricingRow.id === action.payload.pricingRowField.pricingRow.id,
      );
      pricingRowFields[existingPricingRowIndex] = action.payload.pricingRowField;
      pricingRowFields[existingPricingRowIndex].pricingDistanceKm = action.payload.pricingDistanceKm;
      return {
        ...state,
        pricingRowFields,
      };
    }
    case 'UPDATE_PRICING_ROW_WEIGHT_KG': {
      const pricingRowFields = [...state.pricingRowFields];
      const existingPricingRowIndex = pricingRowFields.findIndex(
        (pricingRowField) => pricingRowField.pricingRow.id === action.payload.pricingRowField.pricingRow.id,
      );
      pricingRowFields[existingPricingRowIndex] = action.payload.pricingRowField;
      pricingRowFields[existingPricingRowIndex].pricingWeightKg = action.payload.pricingWeightKg;
      return {
        ...state,
        pricingRowFields,
      };
    }
    case 'UPDATE_CAR_PRICING_ROW_VALUE': {
      const carPricingRowFields = [...state.carPricingRowFields];
      const existingCarPricingRowIndex = carPricingRowFields.findIndex(
        (carPricingRowField) =>
          carPricingRowField.carPricingRow.id === action.payload.carPricingRowField.carPricingRow.id,
      );
      carPricingRowFields[existingCarPricingRowIndex] = action.payload.carPricingRowField;
      carPricingRowFields[existingCarPricingRowIndex].value = action.payload.value;
      return {
        ...state,
        carPricingRowFields,
      };
    }
    default: {
      throw new Error(`Unhandled action ${JSON.stringify(action)}`);
    }
  }
};

type FieldValue = string | boolean | number | null;

export const debouncedValidateFieldsDispatch = debounce((dispatch: Dispatch<Action>) => {
  dispatch({
    type: 'VALIDATE_FIELDS',
  });
}, 500);

export const updateFieldValue = (fieldName: FieldName, value: FieldValue, dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_FIELD_VALUE',
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
  debouncedValidateFieldsDispatch(dispatch);
};

export const getInitialState = (): State => {
  return {
    fields: {
      id: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: 0,
      },
      name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      hourly_price: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: 0,
      },
      hourly_price_of_combined_vehicle: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: 0,
      },
      express_delivery_price: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: 0,
      },
      default_pricing_area: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      default_custom_pricing_category: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      pricing_unit: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: PricingModelWithAreasPricingUnitEnum.WeightKg,
      },
      distance_pricing_basis: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: PricingModelWithAreasDistancePricingBasisEnum.DistanceKm,
      },
      legacy_combined_pricing_for_same_day_deliveries_in_same_address: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      legacy_chargeable_weight_flooring: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      legacy_ignore_basic_price_if_hours_exist_and_not_exception: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      legacy_ignore_working_hours: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
    },
    isLoading: false,
    notification: {
      message: null,
    },
    isValid: false,
    pricingAreas: [],
    geographicalAreaFields: [],
    pricingAreaFields: [],
    pricingRowFields: [],
    carPricingRowFields: [],
  };
};
