import React, { Dispatch, useEffect, useReducer, useState } from 'react';
import { api, Organization, OrganizationShipmentResponseBody, ShipmentResponseBody, User } from '../../api';
import Main from '../../components/Main';
import {
  Action,
  getInitialState,
  getOrganizationShipmentPostBodyFromState,
  getShipmentDefaultValues,
  getShipmentPostBodyFromState,
  reducer,
  ShipmentLocationState,
  State,
  updateRows,
} from './shipment.state';
import { Loading } from '../../components/Loading';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { SaveButton } from '../../components/SaveButton';
import { Header } from '../../components/Header';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { useCurrentUser, useCurrentUserOrganization } from '../../hooks/useCurrentUser';
import { canAccessCoordination } from '../../utils';
import { AllShipmentFields } from './components/allShipmentFields';
import NewShipmenRowTable from './components/NewShipmentRowTable';

const saveNewShipment = async (
  state: State,
  dispatch: Dispatch<Action>,
  navigate: ReturnType<typeof useNavigate>,
  currentUser?: User,
) => {
  if (!currentUser?.organization_id) {
    throw new Error('Missing org id');
  }
  if (!state.isValid && !canAccessCoordination(currentUser)) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });
  try {
    let shipmentResponse: OrganizationShipmentResponseBody | ShipmentResponseBody;
    if (canAccessCoordination(currentUser)) {
      shipmentResponse = await api.shipments.createShipment({
        shipmentPostBody: getShipmentPostBodyFromState(state, currentUser),
      });
    } else {
      shipmentResponse = await api.organizationShipments.createOrganizationShipment({
        organizationId: state.fields.organization_id.value,
        organizationShipmentPostBody: getOrganizationShipmentPostBodyFromState(state),
      });
    }
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Toimitus tallennettu!' } });
    setTimeout(() => {
      dispatch({ type: 'SET_LOADING', payload: false });
      navigate(`/shipments/${shipmentResponse.data.id}`);
    }, 1000);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

const load = async (
  userOrganization: Organization,
  currentUser: User,
  location: Location<ShipmentLocationState>,
  dispatch: Dispatch<Action>,
) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let organizations: Organization[] = [];
    if (currentUser.is_multi_organization || currentUser.is_superuser) {
      organizations = (await api.organizations.getOrganizations({})).data;
    } else {
      organizations = [userOrganization];
    }
    const organization = location.state?.shipment
      ? (organizations.find((org) => org.id === location.state?.shipment.organization_id) ?? userOrganization)
      : userOrganization;
    const [organizationOfficesResponse, organizationAdditionalServicesResponse] = await Promise.all([
      api.organizationOffices.getOrganizationOffices({ organizationId: organization.id }),
      api.organizationAdditionalServices.getOrganizationAdditionalServices({ organizationId: organization.id }),
    ]);
    let ordererValue = '';
    if (currentUser.first_name) {
      ordererValue = (currentUser.first_name + ' ' + (currentUser.last_name || '')).trim();
    } else {
      ordererValue = currentUser.username;
    }
    let pricingModelsResponse;
    if (canAccessCoordination(currentUser)) {
      pricingModelsResponse = (await api.pricing.getPricingModels()).data;
    } else {
      pricingModelsResponse = (
        await api.organizationPricing.getOrganizationPricingModels({
          organizationId: organization.id,
        })
      ).data;
    }
    dispatch({
      type: 'INITIALIZE_SHIPMENT',
      payload: {
        shipment: {
          ...(location.state?.shipment ? location.state?.shipment : getShipmentDefaultValues(userOrganization)),
          orderer: ordererValue,
          orderer_phone_number: currentUser.gsm || null,
          load_id: null,
          order_in_load: null,
        },
        shipmentRows: location.state?.shipmentRows ?? [],
        currentUser,
        organization,
        organizationOffices: organizationOfficesResponse.data,
        organizationAdditionalServices: organizationAdditionalServicesResponse.data,
        shipmentAdditionalServices:
          location.state?.additionalServiceFields.map((additionalServiceField) => ({
            quantity: additionalServiceField.value || 0,
            additional_service_id: additionalServiceField.additionalService.id,
          })) ?? [],
        organizations: organizations ?? [organization],
        isNewShipment: true,
        pricingModels: pricingModelsResponse ?? [],
      },
    });
    if (location.state?.shipment) {
      dispatch({
        type: 'VALIDATE_FIELDS',
      });
    }
  } catch (err) {
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Sivun lataus epäonnistui', severity: 'error' } });
    console.error(err);
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const CreateShipment: React.FC = () => {
  const currentUser = useCurrentUser();
  const userOrganization = useCurrentUserOrganization();
  const [state, dispatch] = useReducer(reducer, getInitialState(currentUser));
  const navigate = useNavigate();
  const location = useLocation();
  const [areShipmentRowsBeingEdited, setAreShipmentRowsBeingEdited] = useState(false);

  useEffect(() => {
    if (userOrganization === undefined || currentUser === undefined) {
      return;
    }
    dispatch({
      type: 'CLEAR_SHIPMENT',
      payload: { currentUser },
    });
    load(userOrganization, currentUser, location, dispatch);
  }, [userOrganization, currentUser]);

  const canSave = canAccessCoordination(currentUser) || (state.isValid && state.canSave);

  return (
    <Main>
      <Loading isLoading={state.isLoading}></Loading>
      <StyledForm noValidate autoComplete="off">
        <Header title="Uusi toimitus">
          <SaveButton
            disabled={!canSave || state.isLoading || areShipmentRowsBeingEdited}
            color={!state.isValid ? 'warning' : 'success'}
            id="save-shipment-button"
            tooltip={!state.isValid ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              saveNewShipment(state, dispatch, navigate, currentUser);
              event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
            }}
          >
            Luo toimitus
          </SaveButton>
        </Header>
        <AllShipmentFields
          dispatch={dispatch}
          state={state}
          currentUser={currentUser}
          organization={userOrganization}
        />
        <NewShipmenRowTable
          shipmentRows={state.rows}
          setShipmentRows={(rows) => updateRows(rows, dispatch)}
          setAreShipmentRowsBeingEdited={setAreShipmentRowsBeingEdited}
          currentUser={currentUser}
        />
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default CreateShipment;
