import React from 'react';
import { MenuItem, MenuItemProps } from '@mui/material';

export const EmptySelectMenuItem = (props: MenuItemProps) => {
  return (
    <MenuItem {...props}>
      <em>Ei valintaa</em>
    </MenuItem>
  );
};
