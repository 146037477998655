import React from 'react';
import { AdditionalServicePutBody } from '../../../api';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { Delete } from '../../../components/icons';
import { Add } from '@mui/icons-material';
import { additionalServiceName, additionalServicePrices } from '../../../validation';
import { DenseTableCell, EmptyTableCell, HeaderTableCell, StyledButton } from './StyledComponents';

interface AdditionalServicesTableProps {
  additionalServices: AdditionalServicePutBody[];
  onAdditionalServicesUpdate?: (additionalServices: AdditionalServicePutBody[]) => void;
}

export const validateAdditionalServices = (additionalServices: AdditionalServicePutBody[]): boolean => {
  for (const additionalService of additionalServices) {
    const isDisabled = Boolean(
      additionalServiceName.validate(additionalService.name).error ||
        additionalServicePrices.validate(additionalService.price).error ||
        additionalServicePrices.validate(additionalService.work_price).error,
    );
    if (isDisabled) {
      return isDisabled;
    }
  }
  return false;
};

export const AdditionalServicesTable: React.FC<AdditionalServicesTableProps> = ({
  additionalServices,
  onAdditionalServicesUpdate,
}) => {
  return (
    <FieldSet>
      <legend>Lisäpalvelut</legend>
      <Table className="additional_services_table">
        <TableHead>
          <TableRow>
            <HeaderTableCell>Nimi</HeaderTableCell>
            <HeaderTableCell>Tuntihinta €</HeaderTableCell>
            <HeaderTableCell>Alihankkijalle maksettava tuntihinta €</HeaderTableCell>
            <HeaderTableCell>Arkistoitu</HeaderTableCell>
            {/* Empty cell for actionbuttons */}
            <HeaderTableCell></HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {additionalServices.length === 0 ? (
            <TableRow>
              <EmptyTableCell colSpan={5}>Ei lisäpalveluita</EmptyTableCell>
            </TableRow>
          ) : (
            additionalServices
              .sort((a, b) => {
                return a.id === undefined ? 1 : b.id === undefined ? -1 : (a.id || -1) > (b.id || -1) ? 1 : -1;
              })
              .map((additionalService, index) => {
                return (
                  <TableRow key={index}>
                    <DenseTableCell>
                      <TextField
                        error={Boolean(additionalServiceName.validate(additionalService.name).error)}
                        helperText={additionalServiceName.validate(additionalService.name).error?.message}
                        type="text"
                        name="additional_service_name"
                        required={true}
                        onChange={(e) => {
                          const newArr = [...additionalServices];
                          newArr[index].name = e.target.value;
                          onAdditionalServicesUpdate && onAdditionalServicesUpdate(newArr);
                        }}
                        value={additionalService.name ?? ''}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      <FormControl error={Boolean(additionalServicePrices.validate(additionalService.price).error)}>
                        <Input
                          id="additional_service_price"
                          name="additional_service_price"
                          type="number"
                          value={additionalService.price ?? ''}
                          onChange={(e) => {
                            const newArr = [...additionalServices];
                            newArr[index].price = e.target.value !== '' ? Number(e.target.value) : ('' as any);
                            onAdditionalServicesUpdate && onAdditionalServicesUpdate(newArr);
                          }}
                        />
                        {additionalServicePrices.validate(additionalService.price).error?.message ? (
                          <FormHelperText>
                            {additionalServicePrices.validate(additionalService.price).error?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </DenseTableCell>
                    <DenseTableCell>
                      <FormControl
                        error={Boolean(additionalServicePrices.validate(additionalService.work_price).error)}
                      >
                        <Input
                          id="additional_service_work_price"
                          name="additional_service_work_price"
                          type="number"
                          value={additionalService.work_price ?? ''}
                          onChange={(e) => {
                            const newArr = [...additionalServices];
                            newArr[index].work_price = e.target.value !== '' ? Number(e.target.value) : ('' as any);
                            onAdditionalServicesUpdate && onAdditionalServicesUpdate(newArr);
                          }}
                        />
                        {additionalServicePrices.validate(additionalService.work_price).error?.message ? (
                          <FormHelperText>
                            {additionalServicePrices.validate(additionalService.work_price).error?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </DenseTableCell>
                    <DenseTableCell>
                      <Checkbox
                        className="additional_service_is_archived_checkbox"
                        name="additional_service_is_is_archived"
                        checked={additionalService.archived}
                        onChange={() => {
                          const newArr = [...additionalServices];
                          newArr[index].archived = !newArr[index].archived;
                          onAdditionalServicesUpdate && onAdditionalServicesUpdate(newArr);
                        }}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      {!additionalService.id ? (
                        <IconButton
                          className="delete_additional_service_button"
                          onClick={() => {
                            const newArr = [...additionalServices];
                            newArr.splice(index, 1);
                            onAdditionalServicesUpdate && onAdditionalServicesUpdate(newArr);
                          }}
                          size="large"
                        >
                          <Delete />
                        </IconButton>
                      ) : null}
                    </DenseTableCell>
                  </TableRow>
                );
              })
          )}
        </TableBody>
      </Table>
      <StyledButton
        className="add_additional_service_button"
        disabled={validateAdditionalServices(additionalServices)}
        onClick={() => {
          const newArr = [...additionalServices];
          newArr.push({
            name: '',
            //start with empty field, instead of starting with 0. undefined or null not allowed in schema.
            work_price: '' as any,
            price: '' as any,
            archived: false,
          });
          onAdditionalServicesUpdate && onAdditionalServicesUpdate(newArr);
        }}
        startIcon={<Add />}
      >
        Lisää Lisäpalvelu
      </StyledButton>
    </FieldSet>
  );
};

export default AdditionalServicesTable;
