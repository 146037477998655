import { DateTime } from 'luxon';
import { organizationId, organizationName } from '../../validation';
import { Field, FieldName, State } from './organization.state';

type ValidationResult = {
  hasError: boolean;
  feedback?: string;
};

const getValidationResult = (joiSchema: any, field: Field<any>): ValidationResult => {
  const result = joiSchema.validate(field.value);
  const validationResult: ValidationResult = {
    hasError: result.error !== undefined,
    feedback: result.error?.message,
  };
  return validationResult;
};

const validateDeliveryTimeWindow = (field: Field<any>, fields: State['fields']): ValidationResult => {
  const validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  const deliveryWindowTime = DateTime.fromISO(field.value);
  if (!deliveryWindowTime.isValid && field.value) {
    validationResult.hasError = true;
    validationResult.feedback = 'Virheellinen aika';
  } else if (
    fields.default_agreed_delivery_window_time_starts_at.value !== null &&
    fields.default_agreed_delivery_window_time_ends_at.value !== null &&
    DateTime.fromISO(fields.default_agreed_delivery_window_time_starts_at.value) >
      DateTime.fromISO(fields.default_agreed_delivery_window_time_ends_at.value)
  ) {
    validationResult.hasError = true;
    validationResult.feedback = '"Aikaisintaan" ei voi olla myöhemmin kuin "Viimeistään"';
  }
  return validationResult;
};

const validateField = (fieldName: FieldName, fields: State['fields'], state: State): ValidationResult => {
  const field = fields[fieldName];
  let validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  switch (fieldName) {
    case 'id': {
      const invalidOrganizationIds = state.allOrganizations.map((organization) => organization.id);
      validationResult = getValidationResult(organizationId.disallow(...invalidOrganizationIds).insensitive(), field);
      break;
    }
    case 'name': {
      validationResult = getValidationResult(organizationName, field);
      break;
    }
    case 'default_agreed_delivery_window_time_starts_at':
    case 'default_agreed_delivery_window_time_ends_at': {
      validationResult = validateDeliveryTimeWindow(field, fields);
      break;
    }
  }
  if (field.required && (field.value === undefined || field.value === null || field.value === '')) {
    validationResult.hasError = true;
    validationResult.feedback = 'Kenttä on pakollinen';
  }
  return validationResult;
};

export const validateFields = (state: State): { fields: State['fields'] } => {
  const newFields = { ...state.fields } as any;
  for (const fieldName of Object.keys(state.fields)) {
    newFields[fieldName] = {
      ...newFields[fieldName],
      ...validateField(fieldName as FieldName, state.fields, state),
    };
  }
  return {
    fields: newFields,
  };
};
