import { IconButton, Menu, ListItem, Button, TextField } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import React, { Dispatch, useState } from 'react';
import {
  Action,
  CarPricingRowField,
  debouncedValidateFieldsDispatch,
  PricingRowField,
  State,
} from '../pricingModel.state';
import { formatFloat } from '../../../formatters';

interface PriceMultiplierMenuProps extends React.HTMLAttributes<HTMLElement> {
  isWorkHoursBasedPricing: boolean;
  state: State;
  dispatch: Dispatch<Action>;
}

export const PriceMultiplierMenu: React.FC<PriceMultiplierMenuProps> = ({
  isWorkHoursBasedPricing,
  state,
  dispatch,
}) => {
  const [multiplier, setMultiplier] = useState<string | number | null>(1.0);
  const [anchorEl, setAnchorEl] = useState(null);
  const multiplierMenuOpen = Boolean(anchorEl);
  const handleMultiplierMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMultiplierMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        style={{ width: 'fit-content', alignSelf: 'self-end' }}
        id="price-multiplier-open-menu-button"
        aria-controls={multiplierMenuOpen ? 'price-multiplier-button-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={multiplierMenuOpen ? 'true' : undefined}
        onClick={handleMultiplierMenuOpen}
        size="large"
      >
        <FilterList />
      </IconButton>
      <Menu
        id="price-multiplier-menu"
        anchorEl={anchorEl}
        open={multiplierMenuOpen}
        onClose={handleMultiplierMenuClose}
        MenuListProps={{
          'aria-labelledby': 'price-multiplier-button',
        }}
      >
        <ListItem>
          <TextField
            style={{ width: '2 rem' }}
            label="Kerroin"
            name="price-multiplier"
            type="number"
            value={multiplier}
            onChange={(e) => {
              setMultiplier(e.target.value !== '' ? parseFloat(e.target.value) : '');
              debouncedValidateFieldsDispatch(dispatch);
            }}
          />
          <Button
            style={{ marginLeft: '0.25rem' }}
            id="price-multiplier-confirm-button"
            disabled={!multiplier || !state.isValid}
            onClick={() => {
              const priceRowFields = isWorkHoursBasedPricing ? state.carPricingRowFields : state.pricingRowFields;
              priceRowFields.map((pricingRowField) => {
                if (!pricingRowField.value) {
                  return;
                }
                const newValue = Number(formatFloat(pricingRowField.value * Number(multiplier)));
                if (isWorkHoursBasedPricing) {
                  dispatch({
                    type: 'UPDATE_CAR_PRICING_ROW_VALUE',
                    payload: {
                      carPricingRowField: pricingRowField as CarPricingRowField,
                      value: newValue,
                    },
                  });
                } else {
                  dispatch({
                    type: 'UPDATE_PRICING_ROW_VALUE',
                    payload: {
                      pricingRowField: pricingRowField as PricingRowField,
                      value: newValue,
                    },
                  });
                }

                debouncedValidateFieldsDispatch(dispatch);
                handleMultiplierMenuClose();
                setMultiplier(1.0);
              });
            }}
          >
            Laske
          </Button>
        </ListItem>
      </Menu>
    </>
  );
};
