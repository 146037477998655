import React, { Dispatch, useEffect, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import { Action, getInitialState, reducer, State } from './user.state';
import { api, getAllPages, PostOrganizationUserRequestBody, User } from '../../api';
import { useNavigate } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AllUserFields } from './components/inputs';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { canAccessUsers } from '../../utils';

export const load = async (dispatch: Dispatch<Action>, currentUser: User) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [organizationsResponse, driversResponse, usersResponse] = await Promise.all([
      canAccessUsers(currentUser)
        ? (await api.organizations.getOrganizations({})).data
        : [(await api.organizations.getOrganization({ organizationId: currentUser.organization_id })).data],
      canAccessUsers(currentUser) ? getAllPages(api.drivers.getDrivers.bind(api.drivers), {}) : [],
      canAccessUsers(currentUser) ? getAllPages(api.users.getUsers.bind(api.users), {}) : [],
    ]);
    dispatch({
      type: 'SET_ORGANIZATIONS',
      payload: {
        organizations: organizationsResponse,
      },
    });
    dispatch({
      type: 'SET_DRIVERS',
      payload: {
        drivers: driversResponse,
      },
    });
    dispatch({
      type: 'SET_USERS',
      payload: {
        users: usersResponse,
      },
    });
  } catch (err) {
    let message = 'Sivun lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Sivun lataus epäonnistui!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

export const getUserPostBodyFromState = (state: State): PostOrganizationUserRequestBody => {
  return {
    username: state.fields.username.value,
    first_name: state.fields.first_name.value ?? '',
    last_name: state.fields.last_name.value ?? '',
    email: state.fields.email.value || null,
    gsm: state.fields.gsm.value || null,
    is_admin: state.fields.is_admin.value || false,
    is_coordinator: state.fields.is_coordinator.value || false,
    is_multi_organization: state.fields.is_multi_organization.value || false,
    is_superuser: state.fields.is_superuser.value || false,
    is_workshop: state.fields.is_workshop.value || false,
    is_driver: state.fields.is_driver.value || false,
    is_manager: state.fields.is_manager.value || false,
    can_access_customer_report: state.fields.can_access_customer_report.value || false,
    driver_id: state.fields.driver_id.value || null,
    password: state.fields.password.value ?? '',
  };
};

export const saveNewUser = async (
  state: State,
  dispatch: Dispatch<Action>,
  navigate: ReturnType<typeof useNavigate>,
  currentUser: User,
) => {
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });

  try {
    const userResponse = await api.organizationUsers.createOrganizationUser({
      organizationId: canAccessUsers(currentUser) ? state.fields.organization_id.value : currentUser.organization_id,
      postOrganizationUserRequestBody: getUserPostBodyFromState(state),
    });
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Käyttäjä tallennettu!' } });
    setTimeout(() => {
      dispatch({ type: 'SET_LOADING', payload: false });

      navigate(`/users/${userResponse.data?.username}`);
    }, 1000);
  } catch (err) {
    if ((err as any).status === 400) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: 'Valitsemasi käyttäjätunnus tai sähköposti on jo käytössä. Yritä uudelleen.' },
      });
    } else {
      dispatch(tryAgainMessage);
    }
    console.error(err);
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

const CreateUser: React.FC = () => {
  const currentUser = useCurrentUser();
  const [state, dispatch] = useReducer(reducer, getInitialState(true, currentUser));
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      load(dispatch, currentUser);
    }
  }, [currentUser]);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title="Uusi käyttäjä">
            <SaveButton
              disabled={!state.isValid || state.isLoading}
              id="save-user-button"
              tooltip={!state.isValid ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (currentUser) {
                  saveNewUser(state, dispatch, navigate, currentUser);
                }
                event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
              }}
            >
              Luo uusi käyttäjä
            </SaveButton>
          </Header>
        </HeaderContainer>
        <AllUserFields state={state} dispatch={dispatch} currentUser={currentUser} createNewUser={true} />
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default CreateUser;
