/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShipmentRowPostPutBody,
  ShipmentRowPostPutBodyFromJSON,
  ShipmentRowPostPutBodyFromJSONTyped,
  ShipmentRowPostPutBodyToJSON,
} from './ShipmentRowPostPutBody';
import {
  ShipmentStateEnum,
  ShipmentStateEnumFromJSON,
  ShipmentStateEnumFromJSONTyped,
  ShipmentStateEnumToJSON,
} from './ShipmentStateEnum';

/**
 *
 * @export
 * @interface ShipmentsPatchBody
 */
export interface ShipmentsPatchBody {
  /**
   *
   * @type {number}
   * @memberof ShipmentsPatchBody
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentsPatchBody
   */
  load_id?: number | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentsPatchBody
   */
  order_in_load?: number | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentsPatchBody
   */
  price?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentsPatchBody
   */
  has_contract_price?: boolean;
  /**
   *
   * @type {number}
   * @memberof ShipmentsPatchBody
   */
  hourly_work_hours?: number;
  /**
   *
   * @type {string}
   * @memberof ShipmentsPatchBody
   */
  hourly_work_reason?: string;
  /**
   *
   * @type {Date}
   * @memberof ShipmentsPatchBody
   */
  billed_at?: Date | null;
  /**
   *
   * @type {ShipmentStateEnum}
   * @memberof ShipmentsPatchBody
   */
  state?: ShipmentStateEnum;
  /**
   *
   * @type {Array<ShipmentRowPostPutBody>}
   * @memberof ShipmentsPatchBody
   */
  rows?: Array<ShipmentRowPostPutBody>;
}

export function ShipmentsPatchBodyFromJSON(json: any): ShipmentsPatchBody {
  return ShipmentsPatchBodyFromJSONTyped(json, false);
}

export function ShipmentsPatchBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentsPatchBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    load_id: !exists(json, 'load_id') ? undefined : json['load_id'],
    order_in_load: !exists(json, 'order_in_load') ? undefined : json['order_in_load'],
    price: !exists(json, 'price') ? undefined : json['price'],
    has_contract_price: !exists(json, 'has_contract_price') ? undefined : json['has_contract_price'],
    hourly_work_hours: !exists(json, 'hourly_work_hours') ? undefined : json['hourly_work_hours'],
    hourly_work_reason: !exists(json, 'hourly_work_reason') ? undefined : json['hourly_work_reason'],
    billed_at: !exists(json, 'billed_at') ? undefined : json['billed_at'] === null ? null : new Date(json['billed_at']),
    state: !exists(json, 'state') ? undefined : ShipmentStateEnumFromJSON(json['state']),
    rows: !exists(json, 'rows') ? undefined : (json['rows'] as Array<any>).map(ShipmentRowPostPutBodyFromJSON),
  };
}

export function ShipmentsPatchBodyToJSON(value?: ShipmentsPatchBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    load_id: value.load_id,
    order_in_load: value.order_in_load,
    price: value.price,
    has_contract_price: value.has_contract_price,
    hourly_work_hours: value.hourly_work_hours,
    hourly_work_reason: value.hourly_work_reason,
    billed_at:
      value.billed_at === undefined
        ? undefined
        : value.billed_at === null
          ? null
          : value.billed_at.toISOString().substr(0, 10),
    state: ShipmentStateEnumToJSON(value.state),
    rows: value.rows === undefined ? undefined : (value.rows as Array<any>).map(ShipmentRowPostPutBodyToJSON),
  };
}
