import React, { FunctionComponent, useReducer, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Button, Link } from '@mui/material';
import { Column, Row } from 'react-table';
import { initialState, load, PricingModelsViewSettings, reducer } from './pricingModels.state';
import Main from '../../components/Main';
import { ReactTable } from '../../components/ReactTable';
import { getViewSettings } from '../settings';
import { PricingModelWithAreas } from '../../api';

const VIEW_ID = 'pricingModels' as const;

const PricingModels: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const viewSettings = React.useMemo(() => getViewSettings<PricingModelsViewSettings>(VIEW_ID), []);

  useEffect(() => {
    load(dispatch);
  }, []);

  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        Header: 'Nimi',
        accessor: 'name',
        Cell: ({ row }: { row: Row<PricingModelWithAreas> }) => (
          <Link component={RouterLink} to={{ pathname: `/pricing_models/${row.original.id}` }}>
            {row.values.name}
          </Link>
        ),
      },
    ],
    [],
  );
  const data = React.useMemo(() => state.pricingModels, [state.pricingModels]);

  return (
    <Main>
      <Button
        disabled={state.isLoading}
        id="create-new-pricing-model-button"
        onClick={() => {
          navigate('/pricing_models/new');
        }}
      >
        Luo uusi hinnoittelumalli
      </Button>
      <ReactTable
        columns={columns}
        data={data}
        header="Hinnoittelumallit"
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä hinnoittelumalleja."
        initialState={{
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'name',
              asc: true,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        onStateChange={(state) => {
          dispatch({
            type: 'SET_VIEW_SETTINGS',
            payload: { filters: state.filters, sortBy: state.sortBy, hiddenColumns: state.hiddenColumns },
          });
        }}
        columnSelector={true}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default PricingModels;
