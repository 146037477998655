import { Dispatch } from 'react';
import { api, getAllPages, Client, User } from '../../api';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { canAccessUsers } from '../../utils';
import { CurrentUser } from '../../reducers/authReducer';

export interface EnrichedUser extends User {
  organizationName: string;
}

export interface State {
  clients: Client[];
  users: EnrichedUser[];
  notification: NotificationType;
  isLoading: boolean;
}

export const initialState: State = {
  users: [],
  clients: [],
  notification: {
    message: null,
  },
  isLoading: true,
};

export type Action =
  | { type: 'SET_CLIENTS'; payload: Client[] }
  | { type: 'SET_USERS'; payload: User[] }
  | SetMessageAction
  | SetLoadingAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CLIENTS':
      return {
        ...state,
        clients: action.payload,
      };
    case 'SET_USERS':
      return {
        ...state,
        users: action.payload.map((user) => {
          return {
            ...user,
            organizationName: state.clients.find((client) => client.id === user.organization_id)?.name ?? '',
          };
        }),
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
  }
};

export const load = async (dispatch: Dispatch<Action>, currentUser: CurrentUser): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [usersResponse] = await Promise.all([
      canAccessUsers(currentUser)
        ? getAllPages(api.users.getUsers.bind(api.users), {})
        : api.organizationUsers.getOrganizationUsers({
            organizationId: currentUser.organization_id,
          }),
    ]);
    dispatch({
      type: 'SET_CLIENTS',
      payload: currentUser.clients,
    });
    dispatch({
      type: 'SET_USERS',
      payload: Array.isArray(usersResponse) ? usersResponse : usersResponse.data,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa käyttäjiä',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};
