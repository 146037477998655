import React, { Dispatch, useEffect, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import { Action, getInitialState, reducer, State } from './car.state';
import { api, apiV2, CarPostBodyTypeEnum, CarrierCar, CarrierCarPostBody, getAllPages, getData } from '../../api';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AllCarFields } from './components/inputs';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { CarrierUser } from '../../reducers/authReducer';
import { isCarrierUser } from '../../utils';

const load = async (carrierUser: CarrierUser, dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const carrierAccess = isCarrierUser(carrierUser);
    const [carsResponse, driversResponse] = await Promise.all([
      carrierAccess
        ? getData(apiV2.carrier.getCarrierCars.bind(apiV2.carrier), { carrierId: carrierUser.carrier_id })
        : getData(api.cars.getCars.bind(api.cars), {}),
      getAllPages(api.drivers.getDrivers.bind(api.drivers), {}),
    ]);
    dispatch({
      type: 'SET_CARS',
      payload: {
        cars: carsResponse as CarrierCar[],
      },
    });
    dispatch({
      type: 'SET_DRIVERS',
      payload: {
        drivers: driversResponse,
      },
    });
  } catch (err) {
    let message = 'Sivun lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Sivun lataus epäonnistui!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const getCarPostBodyFromState = (state: State): CarrierCarPostBody => {
  return {
    licence_plate: state.fields.licence_plate.value,
    is_active: state.fields.is_active.value,
    default_driver_id: state.fields.default_driver_id.value || 0,
    car_identifier: state.fields.car_identifier.value || null,
    car_number: state.fields.car_number.value || null,
    manufacturer: state.fields.manufacturer.value,
    model: state.fields.model.value,
    type: state.fields.type.value,
    imei: state.fields.imei.value || null,
    vin_number: state.fields.vin_number.value || null,
    first_registration_date: state.fields.first_registration_date.value || null,
    vehicle_inspection_date: state.fields.vehicle_inspection_date.value || null,
    transport_licence: state.fields.transport_licence.value || null,
    transport_licence_date: state.fields.transport_licence_date.value || null,
    tyres: state.fields.tyres.value || null,
    hoist: state.fields.hoist.value || null,
    platform_details: state.fields.platform_details.value || null,
    is_adr: state.fields.is_adr.value,
    unladen_weight: state.fields.unladen_weight.value || null,
    gross_weight: state.fields.gross_weight.value || null,
    height: state.fields.height.value || null,
    fuel_card: state.fields.fuel_card.value || null,
    fuel_id: state.fields.fuel_id.value || null,
    hoist_inspection_date: state.fields.hoist_inspection_date.value || null,
    note: state.fields.note.value || null,
    is_coordination: state.fields.is_coordination.value,
    default_trailer_id: state.fields.default_trailer_id.value || null,
  };
};

const saveNewCar = async (
  carrierUser: CarrierUser,
  state: State,
  dispatch: Dispatch<Action>,
  navigate: ReturnType<typeof useNavigate>,
) => {
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });
  const carrierAccess = isCarrierUser(carrierUser);

  try {
    const carPostBody = getCarPostBodyFromState(state);
    const carResponse = carrierAccess
      ? await apiV2.carrier.postCarrierCar({
          carrierId: carrierUser.carrier_id,
          carrierCarPostBody: carPostBody,
        })
      : await api.cars.createCar({
          carPostBody: {
            ...carPostBody,
            first_registeration_date: carPostBody.first_registration_date,
            type: carPostBody.type as unknown as CarPostBodyTypeEnum,
          },
        });
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Auto tallennettu!' } });
    setTimeout(() => {
      dispatch({ type: 'SET_LOADING', payload: false });
      navigate(`/cars/${carResponse.data.licence_plate}`);
    }, 1000);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

const CreateCar: React.FC = () => {
  const carrierUser = useOutletContext<CarrierUser>();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const navigate = useNavigate();

  useEffect(() => {
    if (carrierUser) {
      load(carrierUser, dispatch);
    }
  }, [carrierUser]);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title="Uusi auto">
            <SaveButton
              disabled={!state.isValid || state.isLoading}
              id="save-car-button"
              tooltip={!state.isValid ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                saveNewCar(carrierUser, state, dispatch, navigate);
                event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
              }}
            >
              Luo uusi auto
            </SaveButton>
          </Header>
        </HeaderContainer>
        <AllCarFields state={state} dispatch={dispatch} showId={false} />
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default CreateCar;
