import React, { BaseSyntheticEvent, Dispatch } from 'react';
import { Action, debouncedValidateFieldsDispatch, PricingRowField, State } from '../pricingModel.state';
import { FormControl, FormHelperText, Input, Table, TableBody, TableRow } from '@mui/material';
import { Dictionary, groupBy, orderBy } from 'lodash';
import {
  PricingModelWithAreasDistancePricingBasisEnum,
  PricingModelWithAreasPricingUnitEnum,
  PricingRow,
} from '../../../api';
import { pricingModelArea } from '../../../validation';
import { DenseTableCell, HeaderTableCell } from '../../organizations/components/StyledComponents';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { PriceMultiplierMenu } from './PriceMultiplierMenu';

interface PriceByPricingUnit {
  id: number;
  distanceKm: number;
  price: number;
}

interface FormattedPricingRow {
  pricingRowIds: number[];
  pricingUnit: number;
  pricesByPricingUnit: PriceByPricingUnit[];
}

const formatPricing = (groupedPricingRows: Dictionary<any>, isWeightBasedPricing: boolean): FormattedPricingRow[] => {
  return Object.values(groupedPricingRows).map((pricingRows) => {
    return {
      pricingRowIds: pricingRows.map((row: PricingRow) => {
        return row.id;
      }),
      pricingUnit: isWeightBasedPricing ? pricingRows[0].weight_kg : pricingRows[0].custom_pricing_category_id,
      pricesByPricingUnit: pricingRows.map((row: PricingRow) => {
        return {
          id: row.id,
          distanceKm: row.distance_km,
          price: row.price,
        };
      }),
    };
  });
};

interface PricingTableProps {
  state: State;
  pricingRows: PricingRow[];
  dispatch: Dispatch<Action>;
}

export const PricingTable: React.FC<PricingTableProps> = ({ state, pricingRows, dispatch }) => {
  const isWeightKgBasedPricing =
    state.originalPricingModel?.pricing_unit === PricingModelWithAreasPricingUnitEnum.WeightKg;
  const isDistanceKmBasedPricing =
    state.originalPricingModel?.distance_pricing_basis === PricingModelWithAreasDistancePricingBasisEnum.DistanceKm;
  const orderedPricingRows = orderBy(
    pricingRows,
    ['pricing_area_id', 'custom_pricing_category_id', 'weight_kg', 'distance_km'],
    ['asc', 'asc', 'asc', 'asc'],
  );
  const groupedPricingRows = isWeightKgBasedPricing
    ? groupBy(orderedPricingRows, 'weight_kg')
    : groupBy(orderedPricingRows, 'custom_pricing_category_id');
  const formattedPricingRows = formatPricing(groupedPricingRows, isWeightKgBasedPricing) ?? [];
  const xHeader = isDistanceKmBasedPricing ? 'Km 🡆' : 'Alue 🡆';
  const yHeader = isWeightKgBasedPricing ? 'Kg 🡇' : 'Kategoria 🡇';

  return (
    <FieldSet>
      <legend>Hintataulu</legend>
      {formattedPricingRows.length > 0 ? (
        <PriceMultiplierMenu isWorkHoursBasedPricing={false} state={state} dispatch={dispatch} />
      ) : null}
      <Table className="pricing-table">
        <TableBody>
          <TableRow className="header-row">
            {formattedPricingRows.length > 0 ? (
              <>
                <Header xHeader={xHeader} yHeader={yHeader} />
                {formattedPricingRows[0].pricesByPricingUnit.map((priceByPricingUnit) => {
                  if (isDistanceKmBasedPricing) {
                    return (
                      <DistanceKmField
                        key={priceByPricingUnit.id}
                        priceByPricingUnit={priceByPricingUnit}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  } else {
                    return (
                      <AreaIdField
                        key={priceByPricingUnit.id}
                        priceByPricingUnit={priceByPricingUnit}
                        state={state}
                        dispatch={dispatch}
                      />
                    );
                  }
                })}
              </>
            ) : null}
          </TableRow>
          {formattedPricingRows.length > 0
            ? formattedPricingRows.map((pricingRow) => {
                if (isWeightKgBasedPricing) {
                  return (
                    <WeightKgAndPriceField
                      key={pricingRow.pricesByPricingUnit[0].id}
                      pricingRow={pricingRow}
                      state={state}
                      dispatch={dispatch}
                    />
                  );
                } else {
                  return (
                    <CustomCategoryAndPriceField
                      key={pricingRow.pricesByPricingUnit[0].id}
                      pricingRow={pricingRow}
                      state={state}
                      dispatch={dispatch}
                    />
                  );
                }
              })
            : null}
        </TableBody>
      </Table>
    </FieldSet>
  );
};

interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
  xHeader: string;
  yHeader: string;
}

const Header: React.FC<HeaderProps> = ({ xHeader, yHeader }) => {
  return (
    <HeaderTableCell>
      <span style={{ float: 'right' }}>{xHeader}</span>
      <br />
      <span>{yHeader}</span>
    </HeaderTableCell>
  );
};

interface PriceFieldProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  priceByPricingUnit: PriceByPricingUnit;
  dispatch: Dispatch<Action>;
}

const PriceField: React.FC<PriceFieldProps> = ({ state, priceByPricingUnit, dispatch }) => {
  const pricingRowField = state.pricingRowFields.find(
    (pricingRowField) => pricingRowField.pricingRow.id === priceByPricingUnit.id,
  );
  return (
    <DenseTableCell>
      <FormControl error={pricingRowField?.hasError}>
        <Input
          className="pricing-row-price"
          name="pricing-row-price"
          margin="dense"
          type="number"
          disabled={false}
          error={pricingRowField?.hasError}
          value={pricingRowField?.value ?? ''}
          //disable changing value with mouse scroll
          onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
          onChange={(e) => {
            dispatch({
              type: 'UPDATE_PRICING_ROW_VALUE',
              payload: {
                pricingRowField: pricingRowField as PricingRowField,
                value: e.target.value ? Number(e.target.value) : undefined,
              },
            });
            debouncedValidateFieldsDispatch(dispatch);
          }}
        />
        {pricingRowField?.feedback ? (
          <FormHelperText className="pricing-row-price-helper-text">{pricingRowField?.feedback}</FormHelperText>
        ) : null}
      </FormControl>
    </DenseTableCell>
  );
};

interface WeightKgCustomCategoryAndPriceFieldProps extends React.HTMLAttributes<HTMLElement> {
  pricingRow: FormattedPricingRow;
  state: State;
  dispatch: Dispatch<Action>;
}

const WeightKgAndPriceField: React.FC<WeightKgCustomCategoryAndPriceFieldProps> = ({ pricingRow, state, dispatch }) => {
  const pricingRowSize = state.pricingRowFields.filter((pricingRowField) =>
    pricingRow.pricingRowIds.includes(pricingRowField.pricingRow.id),
  );
  const pricingRowField = state.pricingRowFields.find(
    (pricingRowField) => pricingRowField.pricingRow.id === pricingRow.pricesByPricingUnit[0].id,
  );
  const correctSizeData = pricingRowField?.pricingWeightKg;
  const disallowed = state.pricingRowFields
    .map((field) => {
      if (field.pricingRow.weight_kg !== pricingRow.pricingUnit) {
        return field.pricingWeightKg;
      }
    })
    .filter((id) => !!id);
  const pricingRowFieldError = pricingModelArea.disallow(...disallowed).validate(correctSizeData).error;
  return (
    <TableRow className="weight-and-price-row">
      <DenseTableCell>
        <div style={{ display: 'flex' }}>
          <FormControl error={Boolean(pricingRowFieldError)}>
            <Input
              className="pricing-weight-name"
              name="pricing-weight-name"
              margin="dense"
              type="number"
              disabled={false}
              error={Boolean(pricingRowFieldError)}
              value={correctSizeData ?? ''}
              //disable changing value with mouse scroll
              onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
              onChange={(e) => {
                pricingRowSize.map((x) => {
                  dispatch({
                    type: 'UPDATE_PRICING_ROW_WEIGHT_KG',
                    payload: {
                      pricingRowField: x,
                      pricingWeightKg: e.target.value ? parseInt(e.target.value) : undefined,
                    },
                  });
                });
                debouncedValidateFieldsDispatch(dispatch);
              }}
            />
            {pricingRowFieldError?.message ? (
              <FormHelperText className="pricing-weight-name-helper-text">
                {pricingRowFieldError?.message}
              </FormHelperText>
            ) : null}
          </FormControl>
        </div>
      </DenseTableCell>
      {pricingRow.pricesByPricingUnit.map((priceByPricingUnit) => {
        return (
          <PriceField
            key={priceByPricingUnit.id}
            state={state}
            priceByPricingUnit={priceByPricingUnit}
            dispatch={dispatch}
          />
        );
      })}
    </TableRow>
  );
};

const CustomCategoryAndPriceField: React.FC<WeightKgCustomCategoryAndPriceFieldProps> = ({
  pricingRow,
  state,
  dispatch,
}) => {
  const pricingRowField = state.pricingRowFields.find(
    (pricingRowField) => pricingRowField.pricingRow.id === pricingRow.pricesByPricingUnit[0].id,
  );
  const customPricingCategoryName = state.originalPricingModel?.custom_pricing_categories.find(
    (customPricingCategory) => customPricingCategory.id === pricingRowField?.pricingCustomCategoryId,
  )?.name;
  return (
    <TableRow className="custom-category-and-price-row">
      <DenseTableCell>
        <Input disabled={true} name="custom-category-name" value={customPricingCategoryName} />
      </DenseTableCell>
      {pricingRow.pricesByPricingUnit.map((priceByPricingUnit) => {
        return (
          <PriceField
            key={priceByPricingUnit.id}
            state={state}
            priceByPricingUnit={priceByPricingUnit}
            dispatch={dispatch}
          />
        );
      })}
    </TableRow>
  );
};

interface DistanceKmAndAreaIdFieldProps extends React.HTMLAttributes<HTMLElement> {
  priceByPricingUnit: PriceByPricingUnit;
  state: State;
  dispatch: Dispatch<Action>;
}

const DistanceKmField: React.FC<DistanceKmAndAreaIdFieldProps> = ({ priceByPricingUnit, state, dispatch }) => {
  const allPricingRowFieldsWithSameDistanceKm = state.pricingRowFields.filter(
    (pricingRowField) => pricingRowField.pricingRow.distance_km === priceByPricingUnit.distanceKm,
  );
  const pricingRowField = state.pricingRowFields.find(
    (pricingRowField) => pricingRowField.pricingRow.id === priceByPricingUnit.id,
  );
  const disallowedDistanceKms = state.pricingRowFields
    .map((field) => {
      if (field.pricingRow.distance_km !== priceByPricingUnit.distanceKm) {
        return field.pricingDistanceKm;
      }
    })
    .filter((field) => !!field);
  const pricingRowFieldError = pricingModelArea
    .disallow(...disallowedDistanceKms)
    .validate(pricingRowField?.pricingDistanceKm).error;
  return (
    <DenseTableCell>
      <div style={{ display: 'flex' }}>
        <FormControl error={Boolean(pricingRowFieldError)}>
          <Input
            className="pricing-distance-field"
            name="pricing-distance-field"
            margin="dense"
            type="number"
            disabled={false}
            error={Boolean(pricingRowFieldError)}
            value={pricingRowField?.pricingDistanceKm ?? ''}
            //disable changing value with mouse scroll
            onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
            onChange={(e) => {
              allPricingRowFieldsWithSameDistanceKm.map((pricingRowField) => {
                dispatch({
                  type: 'UPDATE_PRICING_ROW_DISTANCE_KM',
                  payload: {
                    pricingRowField: pricingRowField,
                    pricingDistanceKm: e.target.value ? parseInt(e.target.value) : undefined,
                  },
                });
              });
              debouncedValidateFieldsDispatch(dispatch);
            }}
          />
          {pricingRowFieldError?.message ? (
            <FormHelperText className="pricing-distance-field-helper-text">
              {pricingRowFieldError?.message}
            </FormHelperText>
          ) : null}
        </FormControl>
      </div>
    </DenseTableCell>
  );
};

const AreaIdField: React.FC<DistanceKmAndAreaIdFieldProps> = ({ priceByPricingUnit, state }) => {
  const pricingRowField = state.pricingRowFields.find(
    (pricingRowField) => pricingRowField.pricingRow.id === priceByPricingUnit.id,
  );
  const pricingAreaName = state.pricingAreaFields.find(
    (pricingAreaField) => pricingAreaField.pricingArea.id === pricingRowField?.pricingAreaId,
  )?.pricingArea.name;
  return (
    <DenseTableCell>
      <Input disabled={true} name="pricing-area-id-name" value={pricingAreaName} />
    </DenseTableCell>
  );
};

export default PricingTable;
