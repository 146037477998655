import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api, Driver, getAllPages, SalaryInfo, SalaryInfoPublicHolidays, SalaryInfoRange } from '../../api';
import {
  Button,
  Card,
  FormControlLabel,
  IconButton,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { dateFormatUnderscore, formatFloatNumber, formatWeekDay } from '../../formatters';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from '../../components/Notification';
import { Loading } from '../../components/Loading';
import { DateTime, Duration } from 'luxon';
import { DateRangePickerProps } from '../../components/DateRangePicker';
import { DateRangePicker } from '../../components/MaterialReactTable/MRTDateRangePicker';
import { DriverPicker } from '../../components/DriverPicker';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import FutureWorkHourTable, { FutureWorkHourWithIsNewAndUpdated, typeIsNormalOrWaiting } from './FutureWorkHourTable';
import {
  DateRange,
  getCurrentSalaryPeriodDateRange,
  getDateRangeFromUrl,
  updateDateRangeToUrl,
} from '../../utils/dateRangeUtils';
import { SaveButton } from '../../components/SaveButton';

type Holiday = {
  date: DateTime;
  name: string;
};

type FormattedSalaryInfo = {
  employee_number: Driver['employee_number'];
  range: DateRange;
  public_holidays: Holiday[];
  normal_hours: Duration;
  evening_hours: Duration;
  night_hours: Duration;
  overtime50: Duration;
  overtime100: Duration;
  sick_hours: Duration;
  pekkas_hours: Duration;
  wait_hours: Duration;
  guarantee_hours: Duration;
  working_days: number;
  pekkas_days: number;
  pekkanen_days_dates: DateTime[];
  vacation_days_dates: DateTime[];
  sick_days: number;
  sick_days_dates: DateTime[];
  vacation_days: number;
  allowance_days: number;
  allowance_days_dates: DateTime[];
  partial_allowance_days: number;
  partial_allowance_days_dates: DateTime[];
  productivity_extra: number;
  available_working_days: number;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const HeadTableCell = styled(TableCell)(() => ({
  fontWeight: 'bold',
}));

const formatSalaryInfo = (info: SalaryInfo): FormattedSalaryInfo => {
  const getRangeFromISO = (range: SalaryInfoRange) => ({
    start: DateTime.fromISO(range.start),
    end: DateTime.fromISO(range.end),
  });
  const getPublicHolidaysFromISO = (holidays: SalaryInfoPublicHolidays[]) =>
    holidays.map((h) => ({
      name: h.name,
      date: DateTime.fromISO(h.date),
    }));
  const getDateTimeFromISO = (dates: string[]) => dates.map((d) => DateTime.fromISO(d));
  const getDurationFromISO = (iso: string) => Duration.fromISO(iso);

  return {
    ...info,
    range: getRangeFromISO(info.range),
    public_holidays: getPublicHolidaysFromISO(info.public_holidays),
    normal_hours: getDurationFromISO(info.normal_hours),
    evening_hours: getDurationFromISO(info.evening_hours),
    night_hours: getDurationFromISO(info.night_hours),
    overtime50: getDurationFromISO(info.overtime50),
    overtime100: getDurationFromISO(info.overtime100),
    sick_hours: getDurationFromISO(info.sick_hours),
    pekkas_hours: getDurationFromISO(info.pekkas_hours),
    wait_hours: getDurationFromISO(info.wait_hours),
    guarantee_hours: getDurationFromISO(info.guarantee_hours),
    pekkanen_days_dates: getDateTimeFromISO(info.pekkanen_days_dates),
    vacation_days_dates: getDateTimeFromISO(info.vacation_days_dates),
    sick_days_dates: getDateTimeFromISO(info.sick_days_dates),
    allowance_days_dates: getDateTimeFromISO(info.allowance_days_dates),
    partial_allowance_days_dates: getDateTimeFromISO(info.partial_allowance_days_dates),
  };
};

const zeroDuration = Duration.fromObject({ hours: 0, minutes: 0 });

const getSalaryInfoDateFormat = (date: DateTime) => `${formatWeekDay(date)} ${date.toFormat('dd.MM')}`;
const getPublicHolidaysString = (holidays: Holiday[]) =>
  holidays.map((h) => `${h.name} ${getSalaryInfoDateFormat(h.date)}`).join(', ');
const getDatesString = (dates: DateTime[]) => dates && dates.map((d) => getSalaryInfoDateFormat(d)).join(', ');
const getHoursString = (duration: Duration) => duration.toFormat('h:mm');

const getSalaryInfoRow = (
  header: string,
  showEmpty: boolean,
  days: number,
  hours: Duration,
  productivityExtra: number,
  dates: DateTime[],
) => {
  if (showEmpty || days > 0 || hours > zeroDuration || dates.length > 0 || productivityExtra > 0) {
    return (
      <StyledTableRow>
        <TableCell>{header}</TableCell>
        <TableCell>{days > 0 && days}</TableCell>
        <TableCell>{hours > zeroDuration && getHoursString(hours)}</TableCell>
        <TableCell>{productivityExtra > 0 && formatFloatNumber(productivityExtra)}</TableCell>
        <TableCell>{dates.length > 0 && getDatesString(dates)}</TableCell>
      </StyledTableRow>
    );
  }
};

const isSalaryInfoEmpty = (salaryInfo: FormattedSalaryInfo): boolean => {
  const { range, employee_number, ...rest } = salaryInfo;
  Object.values(rest).some((value) => {
    if ((Duration.isDuration(value) && value <= zeroDuration) || !isEmpty(value)) {
      return true;
    }
  });
  return false;
};

const saveFutureWorkHours = async (
  updateData: FutureWorkHourWithIsNewAndUpdated[],
  oldData: FutureWorkHourWithIsNewAndUpdated[],
  employeeNumber: Driver['employee_number'],
  setNotification: Dispatch<SetStateAction<NotificationType>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  //Futureworkhour timeoffs don't have id
  const getIdIfExistsOrTempId = (row: FutureWorkHourWithIsNewAndUpdated) => (row.id && row.id >= 1 ? row.id : -1);

  try {
    setLoading(true);
    for (const newRow of updateData) {
      const withHours = typeIsNormalOrWaiting(newRow.type);
      if (newRow.isNew) {
        await api.futureWorkHour.postFutureWorkHour({
          employeeNumber: employeeNumber,
          futureWorkHourPostBody: {
            date: newRow.date,
            note: newRow.note,
            type: newRow.type,
            ...(withHours && {
              assignment_id: newRow.assignment_id,
              starts_at: newRow.starts_at,
              ends_at: newRow.ends_at,
            }),
          },
        });
      } else if (newRow.isUpdated) {
        const oldRow = oldData.find((oldRow) => oldRow.id === newRow.id);
        if (!oldRow) {
          throw new Error('Prerequisities not met');
        }
        await api.futureWorkHour.patchFutureWorkHour({
          previousDate: oldRow.date,
          workHourId: getIdIfExistsOrTempId(oldRow),
          employeeNumber: employeeNumber,
          futureWorkHourPatchBody: {
            date: newRow.date,
            note: newRow.note,
            type: newRow.type,
            ...(withHours && {
              assignment_id: newRow.assignment_id,
              starts_at: newRow.starts_at,
              ends_at: newRow.ends_at,
            }),
          },
        });
      }
    }
    const dataToDelete = oldData.filter((item) => updateData.map((x) => x.id).indexOf(item.id) === -1);
    for (const row of dataToDelete) {
      await api.futureWorkHour.deleteFutureWorkHour({
        workHourId: getIdIfExistsOrTempId(row),
        previousDate: row.date,
        employeeNumber: employeeNumber,
        type: row.type,
      });
    }
    setNotification({ message: 'Tunnit tallennettu!' });
  } catch (err) {
    setNotification({ message: 'Tuntien tallennus epäonnistui', severity: 'error' });
  } finally {
    setLoading(false);
  }
};

const SalaryInfo: React.FC = () => {
  const [dateRange, setDateRange] = useState(getDateRangeFromUrl() ?? getCurrentSalaryPeriodDateRange());
  const [employees, setEmployees] = useState<Driver[]>([]);
  const [employeeNumber, setEmployeeNumber] = useState<string | null>(null);
  const [salaryInfo, setSalaryInfo] = useState<FormattedSalaryInfo | undefined>(undefined);
  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationType>({ message: null });
  const [futureWorkHours, setFutureWorkHours] = useState<FutureWorkHourWithIsNewAndUpdated[]>([]);
  const [oldFutureWorkHours, setOldFutureWorkHours] = useState<FutureWorkHourWithIsNewAndUpdated[]>([]);
  const [areFutureWorkHoursBeingEdited, setAreFutureWorkHoursBeingEdited] = useState(true);

  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };

  const dateRangePickerProps: DateRangePickerProps = {
    startDate: dateRange?.start ?? null,
    endDate: dateRange?.end ?? null,
    disabled: loading,
    onValue: (startDate, endDate): void => {
      const range = {
        start: startDate,
        end: endDate,
      };
      setDateRange(range);
      updateDateRangeToUrl(range);
    },
  };

  const loadEmployees = async () => {
    setLoading(true);
    try {
      const response = await getAllPages(api.drivers.getDrivers.bind(api.drivers), {});
      const onlyWithEmployeeNumber = response.filter((e) => e.employee_number);
      setEmployees(onlyWithEmployeeNumber);
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Työntekijöiden lataus epäonnistui!', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEmployees();
  }, []);

  const loadSalaryInfoById = async () => {
    setLoading(true);
    try {
      if (!employeeNumber) {
        return;
      }
      const response = await api.employees.getEmployeeSalaryInfoById({
        employeeNumber: employeeNumber,
        agreedDeliveryWindowDateRangeStartsAt: dateRange.start.toJSDate(),
        agreedDeliveryWindowDateRangeEndsAt: dateRange.end.toJSDate(),
      });
      setSalaryInfo(formatSalaryInfo(response.data));
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Palkkatietojen lataus epäonnistui!', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const loadFutureWorkHoursByEmployeeNumber = async () => {
    setLoading(true);
    try {
      if (!employeeNumber) {
        return;
      }
      const response = await api.futureWorkHour.getFutureWorkHoursByEmployeeNumber({
        employeeNumber: employeeNumber,
        agreedDeliveryWindowDateRangeStartsAt: dateRange.start.toJSDate(),
        agreedDeliveryWindowDateRangeEndsAt: dateRange.end.toJSDate(),
      });
      const responseWithTempIds = response.data.map((x) => ({ ...x, id: x.id ? x.id : Math.random() }));
      setFutureWorkHours(responseWithTempIds);
      setOldFutureWorkHours(responseWithTempIds);
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Tuntien lataus epäonnistui', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFutureWorkHoursByEmployeeNumber();
    loadSalaryInfoById();
  }, [dateRange, employeeNumber]);

  const setNextEmployee = (next: boolean) => {
    const nextEmployeeIndex = next ? 1 : -1;
    const currentEmployeeIndex = employees.findIndex((e) => e.employee_number === employeeNumber);
    setEmployeeNumber(employees[currentEmployeeIndex + nextEmployeeIndex].employee_number);
  };

  const downloadSalaryInfoExcel = async () => {
    setLoading(true);
    try {
      const response = await api.employees.getEmployeeSalaryInfoExcel({
        agreedDeliveryWindowDateRangeStartsAt: dateRange.start.toJSDate(),
        agreedDeliveryWindowDateRangeEndsAt: dateRange.end.toJSDate(),
      });
      const fileURL = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute(
        'download',
        `tikonvienti_${dateRange?.start.toFormat(dateFormatUnderscore)}_${dateRange?.end.toFormat(
          dateFormatUnderscore,
        )}`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Palkkatietojen lataus epäonnistui!', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Card
        sx={{
          margin: '0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          '> *': {
            margin: '0.5rem',
          },
        }}
      >
        <DriverPicker
          size="small"
          variant="outlined"
          disabled={loading}
          drivers={employees}
          value={employees.find((employee) => employee.employee_number === employeeNumber) ?? null}
          onChange={(employee) => (employee ? setEmployeeNumber(employee?.employee_number) : null)}
          required={true}
        />
        <IconButton
          disabled={!employeeNumber || employees.findIndex((e) => e.employee_number === employeeNumber) === 0}
          onClick={() => setNextEmployee(false)}
        >
          <ArrowLeft />
        </IconButton>
        <IconButton
          disabled={employees.findIndex((e) => e.employee_number === employeeNumber) === employees.length - 1}
          onClick={() => setNextEmployee(true)}
        >
          <ArrowRight />
        </IconButton>
        <FormControlLabel
          control={<Switch />}
          label="Tyhjät"
          value={showEmpty}
          onChange={() => setShowEmpty(!showEmpty)}
        />
        <Button variant="text" onClick={downloadSalaryInfoExcel} sx={{ minWidth: '8rem' }}>
          Lataa excel
        </Button>
        <DateRangePicker {...dateRangePickerProps} />
        <SaveButton
          disabled={loading || areFutureWorkHoursBeingEdited}
          onClick={async () => {
            try {
              if (!employeeNumber) {
                throw new Error('Prerequisities not met');
              }
              await saveFutureWorkHours(
                futureWorkHours,
                oldFutureWorkHours,
                employeeNumber,
                setNotification,
                setLoading,
              );
              await loadSalaryInfoById();
              await loadFutureWorkHoursByEmployeeNumber();
            } catch (err) {
              console.error(err);
              setNotification({ message: 'Tallennus Epäonnistui, Yritä uudelleen!' });
            }
          }}
        >
          Tallenna
        </SaveButton>
      </Card>
      <TableContainer component={Card} sx={{ margin: '0.5rem', width: 'auto' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <HeadTableCell sx={{ width: '18rem' }}>Laji</HeadTableCell>
              <HeadTableCell sx={{ width: '6rem' }}>Määrä</HeadTableCell>
              <HeadTableCell sx={{ width: '6rem' }}>Aika</HeadTableCell>
              <HeadTableCell sx={{ width: '6rem' }}>Hinta</HeadTableCell>
              <HeadTableCell>Selite</HeadTableCell>
            </TableRow>
          </TableHead>
          {salaryInfo && !isSalaryInfoEmpty(salaryInfo) ? (
            <TableBody>
              {getSalaryInfoRow(
                'Päiväraha',
                showEmpty,
                salaryInfo.allowance_days,
                zeroDuration,
                0,
                salaryInfo.allowance_days_dates,
              )}
              {getSalaryInfoRow(
                'Osapäiväraha',
                showEmpty,
                salaryInfo.partial_allowance_days,
                zeroDuration,
                0,
                salaryInfo.partial_allowance_days_dates,
              )}
              {getSalaryInfoRow(
                'Pekkaset',
                showEmpty,
                salaryInfo.pekkas_days,
                salaryInfo.pekkas_hours,
                0,
                salaryInfo.pekkanen_days_dates,
              )}
              {getSalaryInfoRow(
                'Sairaslomat',
                showEmpty,
                salaryInfo.sick_days,
                salaryInfo.sick_hours,
                0,
                salaryInfo.sick_days_dates,
              )}
              {getSalaryInfoRow(
                'Lomat',
                showEmpty,
                salaryInfo.vacation_days,
                zeroDuration,
                0,
                salaryInfo.vacation_days_dates,
              )}
              {getSalaryInfoRow('Ylityöt 50 %', showEmpty, 0, salaryInfo.overtime50, 0, [])}
              {getSalaryInfoRow('Ylityöt 100 %', showEmpty, 0, salaryInfo.overtime100, 0, [])}
              {getSalaryInfoRow('Työpäivät', showEmpty, salaryInfo.working_days, zeroDuration, 0, [])}
              {getSalaryInfoRow('Normaalit tunnit', showEmpty, 0, salaryInfo.normal_hours, 0, [])}
              {getSalaryInfoRow('Iltatunnit', showEmpty, 0, salaryInfo.evening_hours, 0, [])}
              {getSalaryInfoRow('Yötunnit', showEmpty, 0, salaryInfo.night_hours, 0, [])}
              {getSalaryInfoRow('Takuutunnit', showEmpty, 0, salaryInfo.guarantee_hours, 0, [])}
              {getSalaryInfoRow('Odotustunnit', showEmpty, 0, salaryInfo.wait_hours, 0, [])}
              {getSalaryInfoRow(
                'Mahdolliset työpäivät aikavälillä',
                showEmpty,
                salaryInfo.available_working_days,
                zeroDuration,
                0,
                [],
              )}
              {getSalaryInfoRow('Tuottavuuslisä', showEmpty, 0, zeroDuration, salaryInfo.productivity_extra, [])}
              {showEmpty || salaryInfo.public_holidays.length ? (
                <StyledTableRow>
                  <TableCell>Arkipyhät</TableCell>
                  <TableCell colSpan={3} />
                  <TableCell>{getPublicHolidaysString(salaryInfo.public_holidays)}</TableCell>
                </StyledTableRow>
              ) : null}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <TableCell colSpan={5}>Ei tunteja tai lomia valitulla aikavälillä</TableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {employeeNumber && (
        <FutureWorkHourTable
          employeeNumber={employeeNumber}
          futureWorkHours={futureWorkHours}
          setFutureWorkHours={setFutureWorkHours}
          setNotification={setNotification}
          setAreFutureWorkHoursBeingEdited={setAreFutureWorkHoursBeingEdited}
        />
      )}
      <Notification {...snackbarProps} />
    </>
  );
};

export default SalaryInfo;
