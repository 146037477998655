/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ShipmentAdditionalServicePutBody
 */
export interface ShipmentAdditionalServicePutBody {
  /**
   *
   * @type {number}
   * @memberof ShipmentAdditionalServicePutBody
   */
  quantity: number | null;
}

export function ShipmentAdditionalServicePutBodyFromJSON(json: any): ShipmentAdditionalServicePutBody {
  return ShipmentAdditionalServicePutBodyFromJSONTyped(json, false);
}

export function ShipmentAdditionalServicePutBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShipmentAdditionalServicePutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    quantity: json['quantity'],
  };
}

export function ShipmentAdditionalServicePutBodyToJSON(value?: ShipmentAdditionalServicePutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    quantity: value.quantity,
  };
}
