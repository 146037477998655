/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A resource which describes the link between AdditionalService and a Shipment. I.e. additional services that were performed during the shipment.
 * @export
 * @interface ShipmentAdditionalService
 */
export interface ShipmentAdditionalService {
  /**
   *
   * @type {number}
   * @memberof ShipmentAdditionalService
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentAdditionalService
   */
  shipment_id: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentAdditionalService
   */
  additional_service_id: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentAdditionalService
   */
  quantity: number | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentAdditionalService
   */
  price_per_unit?: number;
}

export function ShipmentAdditionalServiceFromJSON(json: any): ShipmentAdditionalService {
  return ShipmentAdditionalServiceFromJSONTyped(json, false);
}

export function ShipmentAdditionalServiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShipmentAdditionalService {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    shipment_id: json['shipment_id'],
    additional_service_id: json['additional_service_id'],
    quantity: json['quantity'],
    price_per_unit: !exists(json, 'price_per_unit') ? undefined : json['price_per_unit'],
  };
}

export function ShipmentAdditionalServiceToJSON(value?: ShipmentAdditionalService | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    shipment_id: value.shipment_id,
    additional_service_id: value.additional_service_id,
    quantity: value.quantity,
    price_per_unit: value.price_per_unit,
  };
}
