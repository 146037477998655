import React, { useEffect, useReducer } from 'react';
import { styled } from '@mui/material';
import { LocalShippingOutlined } from '@mui/icons-material';
import { api } from '../api';
import { Dispatch } from 'react';
import theme from '../theme';
import { NotificationType, SetMessageAction } from './Notification';

const Container = styled('div')({
  marginTop: '0.5rem',
  marginLeft: '1rem',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0.25rem',
  },
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  fontSize: '0.8rem',
});

const ShipmentsContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row nowrap',
});

const StatusText = styled('span')({
  fontSize: '0.8rem',
});

const StatusBadge = styled('span')({
  marginLeft: '0.25rem',
  marginRight: '1rem',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  width: '1.1rem',
  height: '1.1rem',
  color: theme.palette.common.white,
  display: 'block',
  textAlign: 'center',
});

const ShipmentIcon = styled(LocalShippingOutlined)({
  fontSize: '1rem',
  marginRight: '0.5rem',
});

type CoordinationStatusShipment = {
  organizationName: string;
  count: number;
};

export interface State {
  shipments: CoordinationStatusShipment[];
  notification: NotificationType;
}

export const initialState: State = {
  shipments: [],
  notification: {
    message: null,
  },
};

export type Action = { type: 'SET_SHIPMENTS'; payload: CoordinationStatusShipment[] } | SetMessageAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SHIPMENTS':
      return {
        ...state,
        shipments: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
  }
};

export const load = async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const statusResponse = await api.coordinationStatus.getCoordinationStatus();
    dispatch({
      type: 'SET_SHIPMENTS',
      payload: statusResponse.shipments as CoordinationStatusShipment[],
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe ladattaessa ajojärjestelyn statusta',
        severity: 'error',
      },
    });
  }
};

export const CoordinationStatus: React.FC<React.HTMLAttributes<HTMLElement>> = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const refreshTimeMilliseconds = 60000;

  useEffect(() => {
    load(dispatch);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      load(dispatch);
    }, refreshTimeMilliseconds);
    return () => clearInterval(interval);
  }, []);

  if (state.shipments.length > 0) {
    return (
      <Container className="coordination-status">
        {state.shipments.length > 0 ? <ShipmentIcon /> : null}
        {state.shipments.map((shipment, index) => (
          <ShipmentsContainer key={index}>
            <StatusText>{shipment.organizationName}</StatusText>
            <StatusBadge>{shipment.count}</StatusBadge>
          </ShipmentsContainer>
        ))}
      </Container>
    );
  } else {
    return <></>;
  }
};
