/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CarrierEmployeePutBody
 */
export interface CarrierEmployeePutBody {
  /**
   *
   * @type {boolean}
   * @memberof CarrierEmployeePutBody
   */
  is_at_service: boolean;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  company_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  employee_number: string;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  profession: CarrierEmployeePutBodyProfessionEnum;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  license: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  hired_by: string | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierEmployeePutBody
   */
  proactive_driving_expire_date: Date | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierEmployeePutBody
   */
  adr_expire_date: Date | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierEmployeePutBody
   */
  work_safety_expire_date: Date | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierEmployeePutBody
   */
  professional_qualification: Date | null;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  collective_agreement: CarrierEmployeePutBodyCollectiveAgreementEnum;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  personal_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  address: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierEmployeePutBody
   */
  city: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum CarrierEmployeePutBodyProfessionEnum {
  Kuski = 'kuski',
  Appari = 'appari',
  Muu = 'muu',
  Alihankkija = 'alihankkija',
  Asentaja = 'asentaja',
  Toimihenkilo = 'toimihenkilo',
} /**
 * @export
 * @enum {string}
 */
export enum CarrierEmployeePutBodyCollectiveAgreementEnum {
  Empty = '',
  Kuljettaja = 'kuljettaja',
  Korjaamo = 'korjaamo',
  Toimihenkilo = 'toimihenkilo',
  Osaaikainen = 'osaaikainen',
}

export function CarrierEmployeePutBodyFromJSON(json: any): CarrierEmployeePutBody {
  return CarrierEmployeePutBodyFromJSONTyped(json, false);
}

export function CarrierEmployeePutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierEmployeePutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    is_at_service: json['is_at_service'],
    company_phone_number: json['company_phone_number'],
    employee_number: json['employee_number'],
    profession: json['profession'],
    license: json['license'],
    hired_by: json['hired_by'],
    proactive_driving_expire_date:
      json['proactive_driving_expire_date'] === null ? null : new Date(json['proactive_driving_expire_date']),
    adr_expire_date: json['adr_expire_date'] === null ? null : new Date(json['adr_expire_date']),
    work_safety_expire_date:
      json['work_safety_expire_date'] === null ? null : new Date(json['work_safety_expire_date']),
    professional_qualification:
      json['professional_qualification'] === null ? null : new Date(json['professional_qualification']),
    collective_agreement: json['collective_agreement'],
    personal_phone_number: json['personal_phone_number'],
    address: json['address'],
    postal_code: json['postal_code'],
    city: json['city'],
  };
}

export function CarrierEmployeePutBodyToJSON(value?: CarrierEmployeePutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    is_at_service: value.is_at_service,
    company_phone_number: value.company_phone_number,
    employee_number: value.employee_number,
    profession: value.profession,
    license: value.license,
    hired_by: value.hired_by,
    proactive_driving_expire_date:
      value.proactive_driving_expire_date === null ? null : value.proactive_driving_expire_date.toISOString(),
    adr_expire_date: value.adr_expire_date === null ? null : value.adr_expire_date.toISOString(),
    work_safety_expire_date:
      value.work_safety_expire_date === null ? null : value.work_safety_expire_date.toISOString(),
    professional_qualification:
      value.professional_qualification === null ? null : value.professional_qualification.toISOString(),
    collective_agreement: value.collective_agreement,
    personal_phone_number: value.personal_phone_number,
    address: value.address,
    postal_code: value.postal_code,
    city: value.city,
  };
}
