/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Distance, DistanceFromJSON, DistanceFromJSONTyped, DistanceToJSON } from './Distance';

/**
 *
 * @export
 * @interface DistancesResponseBody1
 */
export interface DistancesResponseBody1 {
  /**
   *
   * @type {Array<Distance>}
   * @memberof DistancesResponseBody1
   */
  data: Array<Distance>;
  /**
   *
   * @type {number}
   * @memberof DistancesResponseBody1
   */
  next: number | null;
}

export function DistancesResponseBody1FromJSON(json: any): DistancesResponseBody1 {
  return DistancesResponseBody1FromJSONTyped(json, false);
}

export function DistancesResponseBody1FromJSONTyped(json: any, ignoreDiscriminator: boolean): DistancesResponseBody1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(DistanceFromJSON),
    next: json['next'],
  };
}

export function DistancesResponseBody1ToJSON(value?: DistancesResponseBody1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(DistanceToJSON),
    next: value.next,
  };
}
