import {
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Input,
  InputAdornment,
  FormControl,
  Button,
  TextField,
} from '@mui/material';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from './Notification';
import { SaveButton } from './SaveButton';
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import {
  api,
  LoadStateEnum,
  Shipment,
  Driver,
  Client,
  CarrierLoad,
  ShipmentsPatchBody,
  CarrierCar,
  apiV2,
  CarrierLoadStateEnum,
  CarrierLoadPostBodyStateEnum,
  CarType,
} from '../api';
import { Loading } from './Loading';
import theme from '../theme';
import { LoadShipmentsTable } from './LoadShipmentsTable';
import { CarPicker } from './CarPicker';
import { DriverPicker } from './DriverPicker';
import { ClientPicker } from './ClientPicker';
import { FieldSet } from './StyledComponents/FieldSet';
import { getLoadSize, TopRow } from '../views/loads/EditLoad';
import { orderShipments } from '../utils';
import { TrailerPicker } from './TrailerPicker';
import { dateNotRequired } from '../validation';
import { FieldSetContainer } from './StyledComponents/FieldSetContainer';
import { StyledForm } from './StyledComponents/StyledForm';
import { convertToUTCNoon, StandardDatePicker, todayNoon } from './DateAndTimePickers/StandardDatePicker';
import { StandardTimePicker } from './DateAndTimePickers/StandardTimePicker';
import { useOutletContext } from 'react-router-dom';
import { CarrierUser } from '../reducers/authReducer';

interface LoadModalProps {
  open: boolean;
  onClose: (didCreateNewLoad: boolean) => void;
  creationShipments: Shipment[];
  cars: CarrierCar[];
  drivers: Driver[];
  clients: Client[];
  presetFields?: Partial<CarrierLoad>;
}

export const LoadModal: React.FC<LoadModalProps> = ({
  open,
  onClose,
  cars,
  drivers,
  clients,
  creationShipments,
  presetFields,
}) => {
  const carrierUser = useOutletContext<CarrierUser>();
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationType>({ message: null });
  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };
  const [load, setLoad] = useState<Omit<CarrierLoad, 'id' | 'carrier_id'>>({
    state: CarrierLoadStateEnum.Uusi,
    drive_date: null,
    driver_id: 0,
    car_id: null,
    organization_id: null,
    picked_up_at: null,
    driver_started_at: null,
    driver_ended_at: null,
    chargeable_weight_kg: 0,
    weight_kg: 0,
    volume_m3: 0,
    length_ldm: 0,
    note: null,
    trailer_id: null,
  });
  const [shipments, setShipments] = useState<Shipment[]>(orderShipments(creationShipments));
  async function createLoad() {
    const pickedUpTime = load.picked_up_at ? DateTime.fromJSDate(load.picked_up_at) : null;
    const loadResponseBody = await apiV2.carrier.postCarrierLoad({
      carrierId: carrierUser.carrier_id,
      carrierLoadPostBody: {
        ...load,
        picked_up_at:
          load.drive_date && pickedUpTime
            ? DateTime.fromJSDate(load.drive_date)
                .set({
                  hour: pickedUpTime.hour,
                  minute: pickedUpTime.minute,
                  second: 0,
                  millisecond: 0,
                })
                .toJSDate()
            : null,
        state: load.state as unknown as CarrierLoadPostBodyStateEnum,
      },
    });
    await patchShipments(loadResponseBody.data.id);
    refreshLoads();
    setLoad({ ...load, organization_id: null, car_id: null, driver_id: null, trailer_id: null });
  }
  const [loads, setLoads] = useState<CarrierLoad[]>([]);

  const refreshLoads = async () => {
    const driveDateNoonOrTodayNoon = load.drive_date
      ? convertToUTCNoon(DateTime.fromJSDate(load.drive_date))
      : todayNoon;
    const loadsResponse = await apiV2.carrier.getCarrierLoads({
      carrierId: carrierUser.carrier_id,
      driveDateRangeStartsAt: driveDateNoonOrTodayNoon,
      driveDateRangeEndsAt: driveDateNoonOrTodayNoon,
    });
    setLoads(loadsResponse.data);
  };

  async function patchShipments(loadId: CarrierLoad['id']) {
    const shipmentPatchBodies: ShipmentsPatchBody[] = shipments.map(({ id, order_in_load }) => ({
      id,
      load_id: loadId,
      order_in_load,
    }));
    await api.shipments.patchShipments({ shipmentsPatchBody: shipmentPatchBodies });
  }

  useEffect(() => {
    setShipments(creationShipments);
    if (creationShipments.length) {
      setLoad({
        ...load,
        organization_id: creationShipments.every(
          // Preset client if all shipments belong to same client
          (shipment) => shipment.organization_id === creationShipments[0].organization_id,
        )
          ? creationShipments[0].organization_id
          : null,
      });
    } else {
      setLoad({
        ...load,
        organization_id: null,
      });
    }
  }, [creationShipments]);

  useEffect(() => {
    if (load.drive_date && DateTime.fromJSDate(load.drive_date).isValid) {
      refreshLoads();
    }
  }, [load.drive_date]);

  useEffect(() => {
    if (presetFields) {
      setLoad({
        ...load,
        ...presetFields,
      });
    }
  }, [presetFields]);

  useEffect(() => {
    // This will update load sizes also if a shipment is removed from CarrierLoad in LoadModal
    setLoad({
      ...load,
      ...getLoadSize(shipments),
    });
  }, [shipments, load.car_id]);

  const isDriveDateValid = load.drive_date
    ? DateTime.fromJSDate(load.drive_date).isValid && !Boolean(dateNotRequired.validate(load.drive_date).error)
    : true;

  const canSave =
    load.organization_id &&
    load.driver_id &&
    load.car_id &&
    load.drive_date &&
    isDriveDateValid &&
    (load.picked_up_at ? DateTime.fromJSDate(load.picked_up_at).isValid : true);

  const onShipmentUpdate = (shipments: Shipment[]) => {
    setShipments(shipments);
  };

  return (
    <Dialog
      className="load-modal"
      aria-labelledby="load-modal-title"
      open={open}
      onClose={() => onClose(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        margin: theme.spacing(2),
      }}
      maxWidth="xl"
      fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
    >
      <DialogTitle id="load-modal-title">Uusi kuorma</DialogTitle>
      <Loading isLoading={isLoading} />
      <DialogContent>
        <StyledForm noValidate autoComplete="off">
          <Loading isLoading={isLoading} />
          <FieldSetContainer>
            <FieldSet>
              <legend>Perustiedot</legend>
              <TopRow>
                <StandardDatePicker
                  disabled={isLoading}
                  label="Ajopäivä"
                  className="drive-date-picker"
                  slotProps={{
                    textField: {
                      name: 'drive_date',
                      error: !isDriveDateValid,
                      helperText: dateNotRequired.validate(load.drive_date).error?.message,
                      required: true,
                    },
                  }}
                  onChange={(date) => setLoad({ ...load, drive_date: date })}
                  value={load.drive_date}
                />
                <StandardTimePicker
                  disabled={isLoading}
                  ampm={false}
                  label="Lähtöaika"
                  value={load.picked_up_at}
                  className="starting-time-picker"
                  slotProps={{
                    textField: {
                      name: 'picked_up_at',
                      error: !isDriveDateValid,
                      helperText: dateNotRequired.validate(load.drive_date).error?.message,
                      required: false,
                    },
                  }}
                  onChange={(time) => {
                    setLoad({
                      ...load,
                      picked_up_at: time,
                    });
                  }}
                />
                <FormControl>
                  <InputLabel shrink id="state_label">
                    Tila
                  </InputLabel>
                  <Select
                    className="state-picker"
                    labelId="state_label"
                    disabled={isLoading}
                    value={load.state}
                    onChange={(event) =>
                      setLoad({
                        ...load,
                        state: event.target.value as CarrierLoadStateEnum,
                      })
                    }
                  >
                    <MenuItem key={LoadStateEnum.Uusi} value={LoadStateEnum.Uusi}>
                      Uusi
                    </MenuItem>
                    <MenuItem key={LoadStateEnum.Jarjestelty} value={LoadStateEnum.Jarjestelty}>
                      Järjestelty
                    </MenuItem>
                    <MenuItem key={LoadStateEnum.Noudettu} value={LoadStateEnum.Noudettu}>
                      Noudettu
                    </MenuItem>
                    <MenuItem key={LoadStateEnum.Toimitettu} value={LoadStateEnum.Toimitettu}>
                      Toimitettu
                    </MenuItem>
                    <MenuItem key={LoadStateEnum.Laskutettavissa} value={LoadStateEnum.Laskutettavissa}>
                      Laskutettavissa
                    </MenuItem>
                    <MenuItem key={LoadStateEnum.Laskutettu} value={LoadStateEnum.Laskutettu}>
                      Laskutettu
                    </MenuItem>
                  </Select>
                </FormControl>
              </TopRow>
              <ClientPicker
                disabled={isLoading}
                clients={clients}
                value={clients.find((client) => client.id === load.organization_id) ?? null}
                onChange={(client) => setLoad({ ...load, organization_id: client !== null ? client.id : null })}
                required
              />
              <CarPicker
                disabled={isLoading}
                cars={cars.filter((car) => car.type !== CarType.Pervaunu)}
                value={cars.find((car) => car.id === load.car_id) ?? null}
                onChange={(car) =>
                  setLoad({
                    ...load,
                    car_id: car !== null ? car.id : null,
                    driver_id: car !== null ? car.default_driver_id : null,
                    trailer_id: car !== null ? car.default_trailer_id : null,
                  })
                }
              />
              <DriverPicker
                disabled={isLoading}
                drivers={drivers}
                value={drivers.find((driver) => driver.id === load.driver_id) ?? null}
                onChange={(driver) => setLoad({ ...load, driver_id: driver !== null ? driver.id : null })}
                required={true}
              />
              <TrailerPicker
                disabled={isLoading}
                currentLoad={load as CarrierLoad}
                loads={loads}
                loadShipments={shipments}
                drivers={drivers}
                cars={cars.filter((car) => car.type === CarType.Pervaunu)}
                value={cars.find((trailer) => trailer.id === load.trailer_id) ?? null}
                onChange={(trailer) =>
                  setLoad({
                    ...load,
                    trailer_id: trailer !== null ? trailer.id : null,
                  })
                }
              />
            </FieldSet>
            <FieldSet>
              <legend>Koko</legend>
              <FormControl>
                <InputLabel htmlFor="weight_kg">Paino</InputLabel>
                <Input
                  disabled={true}
                  id="weight_kg"
                  name="weight_kg"
                  type="number"
                  startAdornment={<InputAdornment position="start">kg</InputAdornment>}
                  value={load.weight_kg}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="chargeable_weight_kg">Rahdituspaino</InputLabel>
                <Input
                  disabled={true}
                  id="chargeable_weight_kg"
                  name="chargeable_weight_kg"
                  type="number"
                  startAdornment={<InputAdornment position="start">kg</InputAdornment>}
                  value={load.chargeable_weight_kg}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="volume_m3">Tilavuus</InputLabel>
                <Input
                  disabled={true}
                  id="volume_m3"
                  name="volume_m3"
                  type="number"
                  startAdornment={<InputAdornment position="start">m³</InputAdornment>}
                  value={load.volume_m3}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="length_ldm">Lavametrit</InputLabel>
                <Input
                  disabled={true}
                  id="length_ldm"
                  name="length_ldm"
                  type="number"
                  startAdornment={<InputAdornment position="start">lvm</InputAdornment>}
                  value={load.length_ldm}
                />
              </FormControl>
            </FieldSet>
            <FieldSet>
              <legend>Lisätietoja</legend>
              <TextField
                multiline={true}
                disabled={isLoading}
                required={false}
                name="note"
                label="Lisätietoja"
                value={load.note || ''}
                onChange={(event) => {
                  setLoad({
                    ...load,
                    note: event.target.value !== '' ? event.target.value : null,
                  });
                }}
              />
            </FieldSet>
          </FieldSetContainer>
          <LoadShipmentsTable shipments={shipments} onShipmentUpdate={onShipmentUpdate} isLoading={isLoading} />
          <DialogActions style={{ justifyContent: 'flex-start' }}>
            <SaveButton
              onClick={async () => {
                setIsLoading(true);
                try {
                  await createLoad();
                  onClose(true);
                } catch (err) {
                  setNotification({ message: 'Kuorman tallennus epäonnistui! Yritä uudelleen', severity: 'error' });
                }
                setIsLoading(false);
              }}
              disabled={isLoading || !canSave}
            >
              Luo kuorma
            </SaveButton>
            <Button className="close-modal-button" color="error" onClick={() => onClose(false)} disabled={isLoading}>
              Peruuta
            </Button>
          </DialogActions>
        </StyledForm>
      </DialogContent>
      <Notification {...snackbarProps} />
    </Dialog>
  );
};
