import { Dispatch } from 'react';
import {
  api,
  apiV2,
  Car,
  CarrierLoad,
  Client,
  Driver,
  getAllPages,
  getData,
  Load,
  OrganizationCar,
  OrganizationDriver,
} from '../../api';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { isCarrierUser } from '../../utils';
import { DateRange, getDefaultDateRange, SetDateRangeAction } from '../../utils/dateRangeUtils';
import { CarrierUser, CurrentUser } from '../../reducers/authReducer';
import { convertToUTCNoon } from '../../components/DateAndTimePickers/StandardDatePicker';

export type LoadOrCarrierLoad = Load | CarrierLoad;

export type DriverOrOrganizationDriver = Driver | OrganizationDriver;

export type EnrichedLoad = LoadOrCarrierLoad & {
  organizationName: string;
  driver: DriverOrOrganizationDriver | null;
  car: Car | OrganizationCar | null;
  trailerLicenceplate: string;
};

export interface State {
  clients: Client[];
  loads: EnrichedLoad[];
  drivers: Driver[] | OrganizationDriver[];
  cars: Car[] | OrganizationCar[];
  notification: NotificationType;
  dateRange: DateRange;
  isLoading: boolean;
}

export const getInitialState = (): State => ({
  clients: [],
  loads: [],
  drivers: [],
  cars: [],
  notification: {
    message: null,
  },
  dateRange: getDefaultDateRange(),
  isLoading: true,
});

export type Action =
  | { type: 'SET_CLIENTS'; payload: Client[] }
  | { type: 'SET_LOADS'; payload: Load[] | CarrierLoad[] }
  | { type: 'SET_DRIVERS'; payload: Driver[] | OrganizationDriver[] }
  | { type: 'SET_CARS'; payload: Car[] | OrganizationCar[] }
  | SetMessageAction
  | SetDateRangeAction
  | SetLoadingAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CLIENTS':
      return {
        ...state,
        clients: action.payload,
      };
    case 'SET_LOADS':
      return {
        ...state,
        loads: action.payload.map((load) => {
          return {
            ...load,
            organizationName: state.clients.find((client) => client.id === load.organization_id)?.name ?? '',
            driver: state.drivers.find((driver) => driver.id === load?.driver_id) ?? null,
            car: state.cars.find((car) => car.id === load?.car_id) ?? null,
            trailerLicenceplate: state.cars.find((car) => car.id === load?.trailer_id)?.licence_plate ?? '',
          };
        }),
      };
    case 'SET_DRIVERS':
      return {
        ...state,
        drivers: action.payload,
      };
    case 'SET_CARS':
      return {
        ...state,
        cars: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
  }
};

export const load = async (
  currentUser: CurrentUser | CarrierUser,
  state: State,
  dispatch: Dispatch<Action>,
  abortController?: AbortController,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const canAccessCarrier = isCarrierUser(currentUser);
    const [loads] = await Promise.all([
      canAccessCarrier
        ? getAllPages(
            apiV2.carrier.getCarrierLoads.bind(apiV2.carrier),
            {
              carrierId: currentUser.carrier_id,
              driveDateRangeStartsAt: convertToUTCNoon(state.dateRange.start),
              driveDateRangeEndsAt: convertToUTCNoon(state.dateRange.end),
            },
            abortController,
          )
        : getAllPages(
            api.organizationLoads.getOrganizationLoads.bind(api.organizationLoads),
            {
              organizationId: currentUser?.organization_id ?? '',
              driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
              driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
            },
            abortController,
          ),
    ]);
    const loadIds = loads.map((load) => load.id);
    const [drivers, cars] = await Promise.all([
      canAccessCarrier
        ? getAllPages(api.drivers.getDrivers.bind(api.drivers), {}, abortController)
        : loadIds.length > 0
          ? getAllPages(
              api.organizationDrivers.getOrganizationDrivers.bind(api.organizationDrivers),
              {
                organizationId: currentUser.organization_id,
                loadIds: loadIds,
                driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
                driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
              },
              abortController,
            )
          : [],
      canAccessCarrier
        ? getData(
            apiV2.carrier.getCarrierCars.bind(apiV2.carrier),
            { carrierId: currentUser.carrier_id },
            abortController,
          )
        : loadIds.length > 0
          ? getAllPages(
              api.organizationCars.getOrganizationCars.bind(api.organizationCars),
              {
                organizationId: currentUser.organization_id,
                loadIds: loadIds,
                driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
                driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
              },
              abortController,
            )
          : [],
    ]);
    // Action order matters here!
    dispatch({
      type: 'SET_CLIENTS',
      payload: currentUser.clients,
    });
    dispatch({
      type: 'SET_CARS',
      payload: cars,
    });
    dispatch({
      type: 'SET_DRIVERS',
      payload: drivers,
    });
    dispatch({
      type: 'SET_LOADS',
      payload: loads,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa kuormia',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};
