import { DateTime } from 'luxon';
import { Driver, DriverProfessionEnum, TimeOffLegacy } from '../../api';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { orderBy } from 'lodash';

export interface DriverWithFullNameAndTimeOffs extends Driver {
  fullName: string;
  timeOffs: TimeOffWithIsEdited[];
}

export interface TimeOffWithIsEdited extends TimeOffLegacy {
  isEdited: boolean;
}

export interface State {
  notification: NotificationType;
  isLoading: boolean;
  year: DateTime['year'];
  hoveredDate: DateTime | null;
  driverWithFullNameAndTimeOffs: DriverWithFullNameAndTimeOffs[];
}

export const initialState: State = {
  notification: {
    message: null,
  },
  isLoading: true,
  year: DateTime.local().year,
  hoveredDate: null,
  driverWithFullNameAndTimeOffs: [],
};

export type Action =
  | {
      type: 'INITIALIZE';
      payload: { drivers: Driver[]; timeOffs: TimeOffLegacy[] };
    }
  | { type: 'SET_DRIVER_WITH_FULL_NAME_AND_TIME_OFFS'; payload: DriverWithFullNameAndTimeOffs[] }
  | SetMessageAction
  | SetLoadingAction
  | { type: 'SET_YEAR'; payload: DateTime['year'] }
  | { type: 'SET_HOVERED_DATE'; payload: DateTime | null };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        driverWithFullNameAndTimeOffs: orderBy(action.payload.drivers, ['last_name', 'first_name'])
          //TODO drivers are filtered out based on original PHP code. Add flag.
          .filter((driver) => driver.last_name && driver.profession !== DriverProfessionEnum.Muu)
          .map((driver) => {
            const driverTimeOffs = action.payload.timeOffs.filter((timeOff) => timeOff.driver_id === driver.id);
            return {
              ...driver,
              fullName: `${driver.last_name} ${driver.first_name}`,
              timeOffs: driverTimeOffs.map((t) => {
                return { ...t, isEdited: false };
              }),
            };
          }),
      };
    case 'SET_DRIVER_WITH_FULL_NAME_AND_TIME_OFFS':
      return {
        ...state,
        driverWithFullNameAndTimeOffs: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_YEAR':
      return {
        ...state,
        year: action.payload,
      };
    case 'SET_HOVERED_DATE':
      return {
        ...state,
        hoveredDate: action.payload,
      };
  }
};
