/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Load, LoadFromJSON, LoadFromJSONTyped, LoadToJSON } from './Load';
import { Shipment, ShipmentFromJSON, ShipmentFromJSONTyped, ShipmentToJSON } from './Shipment';
import { ShipmentRow, ShipmentRowFromJSON, ShipmentRowFromJSONTyped, ShipmentRowToJSON } from './ShipmentRow';

/**
 *
 * @export
 * @interface DriverOverviewResponseBody
 */
export interface DriverOverviewResponseBody {
  /**
   *
   * @type {Array<Load>}
   * @memberof DriverOverviewResponseBody
   */
  loads?: Array<Load>;
  /**
   *
   * @type {Array<Shipment>}
   * @memberof DriverOverviewResponseBody
   */
  shipments?: Array<Shipment>;
  /**
   *
   * @type {Array<ShipmentRow>}
   * @memberof DriverOverviewResponseBody
   */
  shipment_rows?: Array<ShipmentRow>;
}

export function DriverOverviewResponseBodyFromJSON(json: any): DriverOverviewResponseBody {
  return DriverOverviewResponseBodyFromJSONTyped(json, false);
}

export function DriverOverviewResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DriverOverviewResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    loads: !exists(json, 'loads') ? undefined : (json['loads'] as Array<any>).map(LoadFromJSON),
    shipments: !exists(json, 'shipments') ? undefined : (json['shipments'] as Array<any>).map(ShipmentFromJSON),
    shipment_rows: !exists(json, 'shipment_rows')
      ? undefined
      : (json['shipment_rows'] as Array<any>).map(ShipmentRowFromJSON),
  };
}

export function DriverOverviewResponseBodyToJSON(value?: DriverOverviewResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    loads: value.loads === undefined ? undefined : (value.loads as Array<any>).map(LoadToJSON),
    shipments: value.shipments === undefined ? undefined : (value.shipments as Array<any>).map(ShipmentToJSON),
    shipment_rows:
      value.shipment_rows === undefined ? undefined : (value.shipment_rows as Array<any>).map(ShipmentRowToJSON),
  };
}
