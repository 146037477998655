import React, { BaseSyntheticEvent, Dispatch, FunctionComponent, useEffect, useMemo, useReducer, useRef } from 'react';
import { api, getAllPages, TimeOffLegacy } from '../../api';
import Notification, { getSnackbarPropsFromState, tryAgainMessage } from '../../components/Notification';
import { Box, Button, styled } from '@mui/material';
import { formatMonth, formatWeekDay } from '../../formatters';
import {
  Action,
  DriverWithFullNameAndTimeOffs,
  initialState,
  reducer,
  State,
  TimeOffWithIsEdited,
} from './holidays.state';
import { DateTime, Interval } from 'luxon';
import { debounce, groupBy } from 'lodash';
import theme from '../../theme';
import { DatePicker } from '@mui/x-date-pickers';
import { Summary } from './Summary';
import { CellProps, Column, DataSheetGridRef, DynamicDataSheetGrid, keyColumn, textColumn } from 'react-datasheet-grid';
import { Loading } from '../../components/Loading';
import 'react-datasheet-grid/dist/style.css';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const cellSize = 24;
const navigationBarHeight = 64;
const highlightBorder = '2px solid rgb(69,128,230)';
const headerColor = 'rgb(157,166,171)';

const ActionContainer = styled(Box)({
  display: 'flex',
  width: 200,
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 1,
  backgroundColor: theme.palette.common.white,
  borderLeft: '1px solid lightgray',
  borderRight: '1px solid lightgray',
});

const load = async (year: State['year'], dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [drivers, timeOffs] = await Promise.all([
      getAllPages(api.drivers.getDrivers.bind(api.drivers), {}),
      getAllPages(api.timeOffs.getTimeOffs.bind(api.timeOffs), {
        year: year,
      }),
    ]);
    dispatch({
      type: 'INITIALIZE',
      payload: {
        drivers: drivers,
        timeOffs: timeOffs,
      },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa lomia',
        severity: 'error',
      },
    });
  } finally {
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  }
};

/**
 * Returns next time off as legacy value.
 * (0 as empty, 1 as holiday, 2 as day off, 3 as sick leave)
 */
const nextTimeOffType = (timeOff: number | undefined) => {
  switch (timeOff) {
    case 1: {
      return 2;
    }
    case 2: {
      return 3;
    }
    case 3: {
      return 0;
    }
    default: {
      return 1;
    }
  }
};

const getFullYearDays = (year: number): DateTime[] => {
  const interval = Interval.fromDateTimes(
    DateTime.local().set({ year: year }).startOf('year'),
    DateTime.local().set({ year: year }).endOf('year'),
  );
  return interval.splitBy({ day: 1 }).map((d) => {
    return d.start;
  });
};

const handleSave = async (timeOffs: TimeOffWithIsEdited[], dispatch: Dispatch<Action>) => {
  dispatch({ type: 'SET_LOADING', payload: true });
  try {
    await Promise.all(
      timeOffs.map((timeOff) => {
        if (!timeOff.isEdited) {
          return;
        }
        if (timeOff.id === -1) {
          api.timeOffs.createTimeOff({ timeOffLegacy: timeOff });
        } else {
          api.timeOffs.patchTimeOff({ timeOffId: timeOff.id, timeOffPatchBody: timeOff });
        }
      }),
    );
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Lomat tallennettu!' } });
  } catch (err) {
    console.error(err);
    dispatch(tryAgainMessage);
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

const setTimeOff = (
  day: DateTime,
  timeOff: TimeOffWithIsEdited | undefined,
  rowData: DriverWithFullNameAndTimeOffs,
  setRowData: (rowData: DriverWithFullNameAndTimeOffs) => void,
) => {
  let copyOfCurrentTimeOffs = [...rowData.timeOffs];
  let edited;
  if (timeOff) {
    const timeOffIndex = copyOfCurrentTimeOffs.indexOf(timeOff);
    edited = {
      ...timeOff,
      isEdited: true,
      [`d${day.day}`]: nextTimeOffType(timeOff[`d${day.day}`]),
    };
    copyOfCurrentTimeOffs[timeOffIndex] = edited;
  } else {
    edited = {
      id: -1,
      isEdited: true,
      driver_id: rowData.id,
      year: day.year,
      month: day.month,
      d1: 0,
      d2: 0,
      d3: 0,
      d4: 0,
      d5: 0,
      d6: 0,
      d7: 0,
      d8: 0,
      d9: 0,
      d10: 0,
      d11: 0,
      d12: 0,
      d13: 0,
      d14: 0,
      d15: 0,
      d16: 0,
      d17: 0,
      d18: 0,
      d19: 0,
      d20: 0,
      d21: 0,
      d22: 0,
      d23: 0,
      d24: 0,
      d25: 0,
      d26: 0,
      d27: 0,
      d28: 0,
      d29: 0,
      d30: 0,
      d31: 0,
    };
    edited = { ...edited, driver_id: rowData.id, [`d${day.day}`]: nextTimeOffType(undefined) };
    copyOfCurrentTimeOffs = copyOfCurrentTimeOffs.concat([edited]);
  }
  setRowData({ ...rowData, timeOffs: copyOfCurrentTimeOffs });
};

const debouncedHoverCapture = debounce((date: State['hoveredDate'], dispatch: Dispatch<Action>) => {
  dispatch({
    type: 'SET_HOVERED_DATE',
    payload: date,
  });
}, 100);

const DayComponent = (
  { rowData, setRowData, rowIndex, columnIndex }: CellProps,
  date: DateTime,
  dispatch: Dispatch<Action>,
  ref: React.RefObject<DataSheetGridRef>,
  isLoading: boolean,
) => {
  const currentTimeOff = rowData.timeOffs.find((t: TimeOffLegacy) => t.month === date.month && t.year === date.year);
  const timeOffType = currentTimeOff ? currentTimeOff[`d${date.day}`] : 0;
  const backgroundColor = useMemo(
    () =>
      timeOffType === 1
        ? theme.palette.holiday
        : timeOffType === 2
          ? theme.palette.dayOff
          : timeOffType === 3
            ? theme.palette.sickLeave
            : date.weekday === 7
              ? 'lightgray'
              : theme.palette.common.white,
    [timeOffType],
  );
  return (
    <option
      disabled={isLoading}
      data-cy={`${date.day}-${date.month}-${date.year}`}
      onMouseEnter={() => {
        ref.current?.setActiveCell({ col: columnIndex, row: rowIndex });
        debouncedHoverCapture(date, dispatch);
      }}
      onMouseLeave={() => debouncedHoverCapture(null, dispatch)}
      style={{ width: '100%', height: '100%', backgroundColor: backgroundColor }}
      onClick={() => {
        setTimeOff(date, currentTimeOff, rowData, setRowData);
      }}
    />
  );
};

const getFullYearColumns = (
  year: number,
  dispatch: Dispatch<Action>,
  ref: React.RefObject<DataSheetGridRef>,
  isLoading: boolean,
): Partial<Column<any, any, any>>[] => {
  const interval = Interval.fromDateTimes(
    DateTime.local().set({ year: year }).startOf('year'),
    DateTime.local().set({ year: year }).endOf('year'),
  );
  return interval.splitBy({ day: 1 }).map((d) => {
    const dayTitle = `${formatWeekDay(d.start)} ${d.start.day}`;
    return {
      ...keyColumn(dayTitle + d.start.month, textColumn),
      component: (rowData) => DayComponent(rowData, d.start, dispatch, ref, isLoading),
      disableKeys: true,
      minWidth: cellSize,
      title: dayTitle,
    };
  });
};

const debouncedHoverCapture2 = debounce((e: BaseSyntheticEvent) => {
  const target = document.getElementById('head');
  if (target) {
    target.scrollLeft = e.target.scrollLeft;
  }
}, 10);

const moveBothScrollbars = (e: BaseSyntheticEvent) => {
  debouncedHoverCapture2(e);
};

const debouncedSetYear = debounce((date: DateTime | null, dispatch: Dispatch<Action>) => {
  if (!date) {
    return;
  }
  dispatch({
    type: 'SET_YEAR',
    payload: date.year,
  });
}, 500);

const Holidays: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentUser = useCurrentUser();
  const ref = useRef<DataSheetGridRef>(null);
  const days = useMemo(() => getFullYearDays(state.year), [state.year]);
  const columns = useMemo(
    () => getFullYearColumns(state.year, dispatch, ref, state.isLoading),
    [state.year, state.isLoading],
  );
  const disabled = !state.driverWithFullNameAndTimeOffs
    .map((d) => d.timeOffs)
    .flat()
    .some((t) => t.isEdited);

  useEffect(() => {
    load(state.year, dispatch);
  }, [currentUser, state.year]);

  return (
    <>
      <Loading isLoading={state.isLoading} />
      <Box
        id="head"
        sx={{ overflow: 'hidden', backgroundColor: theme.palette.common.white, fontSize: '0.6rem', fontWeight: 'bold' }}
      >
        <Box data-cy="month-header" sx={{ display: 'flex', width: 'fit-content' }}>
          <ActionContainer
            sx={{
              borderTop: '1px solid lightgray',
            }}
          >
            <Button
              data-cy="save-holidays-button"
              disabled={disabled || state.isLoading}
              variant="text"
              sx={{ maxHeight: cellSize, textTransform: 'capitalize' }}
              onClick={async () => {
                await handleSave(state.driverWithFullNameAndTimeOffs.map((d) => d.timeOffs).flat(), dispatch);
                dispatch({
                  type: 'SET_DRIVER_WITH_FULL_NAME_AND_TIME_OFFS',
                  payload: state.driverWithFullNameAndTimeOffs.map((d) => ({
                    ...d,
                    timeOffs: d.timeOffs.map((t) => ({ ...t, isEdited: false })),
                  })),
                });
              }}
            >
              Tallenna
            </Button>
            <DatePicker
              disabled={!disabled || state.isLoading}
              sx={{ maxHeight: cellSize, padding: 0 }}
              views={['year']}
              value={DateTime.local().set({ year: state.year })}
              onChange={(date) => debouncedSetYear(date, dispatch)}
              slotProps={{
                textField: {
                  name: 'year_picker',
                  size: 'small',
                  sx: { padding: 0 },
                },
                openPickerButton: {
                  size: 'small',
                  sx: { padding: 0 },
                },
                inputAdornment: {
                  sx: { margin: 0 },
                },
              }}
            />

            <Button
              data-cy="cancel-holidays-button"
              disabled={disabled || state.isLoading}
              variant="text"
              sx={{ maxHeight: cellSize, textTransform: 'capitalize' }}
              onClick={async () => await load(state.year, dispatch)}
            >
              Peruuta
            </Button>
          </ActionContainer>
          {Object.entries(groupBy(days, 'month')).map(([key, value], index) => {
            return (
              <Box
                data-cy={`month-${key}`}
                key={index}
                sx={{
                  width: value.length * cellSize,
                  border: '1px solid lightgray',
                  position: 'sticky',
                  top: 0,
                  color: state.hoveredDate?.month === parseInt(key) ? theme.palette.common.black : headerColor,
                  borderBottom: state.hoveredDate?.month === parseInt(key) ? highlightBorder : undefined,
                  textTransform: 'capitalize',
                }}
              >
                {formatMonth(value[index])}
              </Box>
            );
          })}
        </Box>
        <Box data-cy="week-header" sx={{ display: 'flex', width: 'fit-content' }}>
          <ActionContainer sx={{ top: cellSize }}>
            <Summary date={state.hoveredDate} data={state.driverWithFullNameAndTimeOffs} />
          </ActionContainer>
          {Object.entries(groupBy(days, (item) => `${item.weekNumber}-${item.weekYear}`)).map(([key, value], index) => {
            const weekNumber = parseInt(key);
            return (
              <Box
                data-cy={`week-${key}`}
                key={index}
                sx={{
                  width: value.length * cellSize,
                  position: 'sticky',
                  top: cellSize,
                  borderRight: '1px solid lightgray',
                  color: state.hoveredDate?.weekNumber === weekNumber ? theme.palette.common.black : headerColor,
                  borderBottom: state.hoveredDate?.weekNumber === weekNumber ? highlightBorder : undefined,
                }}
              >
                {weekNumber}
              </Box>
            );
          })}
        </Box>
      </Box>
      <DynamicDataSheetGrid
        lockRows={true}
        disableContextMenu={true}
        disableExpandSelection={true}
        ref={ref}
        onScroll={moveBothScrollbars}
        rowHeight={cellSize}
        style={{
          fontWeight: 'bolder',
          fontSize: '0.6rem',
          textAlign: 'center',
        }}
        height={document.documentElement.clientHeight - navigationBarHeight}
        gutterColumn={{
          basis: 200,
          component: ({ rowData }) => {
            const timeOffTypes = (rowData.timeOffs as TimeOffLegacy[])
              .map(({ id, driver_id, year, month, ...rest }) => Object.values(rest))
              .flat();
            const holidays = timeOffTypes.filter((i) => i === 1).length;
            const dayOffs = timeOffTypes.filter((i) => i === 2).length;
            const sickDays = timeOffTypes.filter((i) => i === 3).length;
            return (
              <div
                data-cy="driver-summary"
                style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}
              >
                <div>{rowData.fullName}</div>
                <div>{`(${holidays} / ${dayOffs} / ${sickDays})`}</div>
              </div>
            );
          },
        }}
        value={state.driverWithFullNameAndTimeOffs}
        onChange={(newValue) => dispatch({ type: 'SET_DRIVER_WITH_FULL_NAME_AND_TIME_OFFS', payload: newValue })}
        columns={columns}
        addRowsComponent={false}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default Holidays;
