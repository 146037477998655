/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ShipmentAdditionalServicePostBody1,
  ShipmentAdditionalServicePostBody1FromJSON,
  ShipmentAdditionalServicePostBody1ToJSON,
  ShipmentAdditionalServicePutBody1,
  ShipmentAdditionalServicePutBody1FromJSON,
  ShipmentAdditionalServicePutBody1ToJSON,
  ShipmentAdditionalServiceResponseBody,
  ShipmentAdditionalServiceResponseBodyFromJSON,
  ShipmentAdditionalServiceResponseBodyToJSON,
  ShipmentAdditionalServicesResponseBody,
  ShipmentAdditionalServicesResponseBodyFromJSON,
  ShipmentAdditionalServicesResponseBodyToJSON,
} from '../models';

export interface DriverShipmentsApiCreateDriverLoadShipmentAdditionalServiceRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  shipmentAdditionalServicePostBody1: ShipmentAdditionalServicePostBody1;
}

export interface DriverShipmentsApiDeleteDriverLoadShipmentAdditionalServiceRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  shipmentAdditionalServiceId: number;
}

export interface DriverShipmentsApiGetDriverLoadShipmentAdditionalServiceRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  shipmentAdditionalServiceId: number;
}

export interface DriverShipmentsApiGetDriverLoadShipmentAdditionalServicesRequest {
  username: string;
  loadId: number;
  shipmentId: number;
}

export interface DriverShipmentsApiUpdateDriverLoadShipmentAdditionalServiceRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  shipmentAdditionalServiceId: number;
  shipmentAdditionalServicePutBody1: ShipmentAdditionalServicePutBody1;
}

/**
 *
 */
export class DriverShipmentsApi extends runtime.BaseAPI {
  /**
   * Create new ShipmentAdditionalService.
   */
  async createDriverLoadShipmentAdditionalServiceRaw(
    requestParameters: DriverShipmentsApiCreateDriverLoadShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentAdditionalServiceResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling createDriverLoadShipmentAdditionalService.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling createDriverLoadShipmentAdditionalService.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling createDriverLoadShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServicePostBody1 === null ||
      requestParameters.shipmentAdditionalServicePostBody1 === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServicePostBody1',
        'Required parameter requestParameters.shipmentAdditionalServicePostBody1 was null or undefined when calling createDriverLoadShipmentAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/additional_services`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ShipmentAdditionalServicePostBody1ToJSON(requestParameters.shipmentAdditionalServicePostBody1),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShipmentAdditionalServiceResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Create new ShipmentAdditionalService.
   */
  async createDriverLoadShipmentAdditionalService(
    requestParameters: DriverShipmentsApiCreateDriverLoadShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentAdditionalServiceResponseBody> {
    const response = await this.createDriverLoadShipmentAdditionalServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete ShipmentAdditionalService.
   */
  async deleteDriverLoadShipmentAdditionalServiceRaw(
    requestParameters: DriverShipmentsApiDeleteDriverLoadShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling deleteDriverLoadShipmentAdditionalService.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling deleteDriverLoadShipmentAdditionalService.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling deleteDriverLoadShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServiceId === null ||
      requestParameters.shipmentAdditionalServiceId === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServiceId',
        'Required parameter requestParameters.shipmentAdditionalServiceId was null or undefined when calling deleteDriverLoadShipmentAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/additional_services/{shipment_additional_service_id}`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(
            `{${'shipment_additional_service_id'}}`,
            encodeURIComponent(String(requestParameters.shipmentAdditionalServiceId)),
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete ShipmentAdditionalService.
   */
  async deleteDriverLoadShipmentAdditionalService(
    requestParameters: DriverShipmentsApiDeleteDriverLoadShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteDriverLoadShipmentAdditionalServiceRaw(requestParameters, initOverrides);
  }

  /**
   * Get ShipmentAdditionalService of a Shipment.
   */
  async getDriverLoadShipmentAdditionalServiceRaw(
    requestParameters: DriverShipmentsApiGetDriverLoadShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentAdditionalServiceResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoadShipmentAdditionalService.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoadShipmentAdditionalService.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getDriverLoadShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServiceId === null ||
      requestParameters.shipmentAdditionalServiceId === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServiceId',
        'Required parameter requestParameters.shipmentAdditionalServiceId was null or undefined when calling getDriverLoadShipmentAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/additional_services/{shipment_additional_service_id}`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(
            `{${'shipment_additional_service_id'}}`,
            encodeURIComponent(String(requestParameters.shipmentAdditionalServiceId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShipmentAdditionalServiceResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get ShipmentAdditionalService of a Shipment.
   */
  async getDriverLoadShipmentAdditionalService(
    requestParameters: DriverShipmentsApiGetDriverLoadShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentAdditionalServiceResponseBody> {
    const response = await this.getDriverLoadShipmentAdditionalServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentAdditionalServices of a Shipment.
   */
  async getDriverLoadShipmentAdditionalServicesRaw(
    requestParameters: DriverShipmentsApiGetDriverLoadShipmentAdditionalServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentAdditionalServicesResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoadShipmentAdditionalServices.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoadShipmentAdditionalServices.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getDriverLoadShipmentAdditionalServices.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/additional_services`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShipmentAdditionalServicesResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get ShipmentAdditionalServices of a Shipment.
   */
  async getDriverLoadShipmentAdditionalServices(
    requestParameters: DriverShipmentsApiGetDriverLoadShipmentAdditionalServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentAdditionalServicesResponseBody> {
    const response = await this.getDriverLoadShipmentAdditionalServicesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update ShipmentAdditionalService.
   */
  async updateDriverLoadShipmentAdditionalServiceRaw(
    requestParameters: DriverShipmentsApiUpdateDriverLoadShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentAdditionalServiceResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling updateDriverLoadShipmentAdditionalService.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling updateDriverLoadShipmentAdditionalService.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling updateDriverLoadShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServiceId === null ||
      requestParameters.shipmentAdditionalServiceId === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServiceId',
        'Required parameter requestParameters.shipmentAdditionalServiceId was null or undefined when calling updateDriverLoadShipmentAdditionalService.',
      );
    }

    if (
      requestParameters.shipmentAdditionalServicePutBody1 === null ||
      requestParameters.shipmentAdditionalServicePutBody1 === undefined
    ) {
      throw new runtime.RequiredError(
        'shipmentAdditionalServicePutBody1',
        'Required parameter requestParameters.shipmentAdditionalServicePutBody1 was null or undefined when calling updateDriverLoadShipmentAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/additional_services/{shipment_additional_service_id}`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(
            `{${'shipment_additional_service_id'}}`,
            encodeURIComponent(String(requestParameters.shipmentAdditionalServiceId)),
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ShipmentAdditionalServicePutBody1ToJSON(requestParameters.shipmentAdditionalServicePutBody1),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShipmentAdditionalServiceResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Update ShipmentAdditionalService.
   */
  async updateDriverLoadShipmentAdditionalService(
    requestParameters: DriverShipmentsApiUpdateDriverLoadShipmentAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentAdditionalServiceResponseBody> {
    const response = await this.updateDriverLoadShipmentAdditionalServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
