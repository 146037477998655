import { styled } from '@mui/material';
import theme from '../../theme';

export const StyledToolbar = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
});
