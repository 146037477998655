/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ShipmentPatchBody
 */
export interface ShipmentPatchBody {
  /**
   *
   * @type {number}
   * @memberof ShipmentPatchBody
   */
  load_id?: number | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentPatchBody
   */
  order_in_load?: number | null;
}

export function ShipmentPatchBodyFromJSON(json: any): ShipmentPatchBody {
  return ShipmentPatchBodyFromJSONTyped(json, false);
}

export function ShipmentPatchBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentPatchBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    load_id: !exists(json, 'load_id') ? undefined : json['load_id'],
    order_in_load: !exists(json, 'order_in_load') ? undefined : json['order_in_load'],
  };
}

export function ShipmentPatchBodyToJSON(value?: ShipmentPatchBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    load_id: value.load_id,
    order_in_load: value.order_in_load,
  };
}
