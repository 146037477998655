/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows,
  ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsFromJSON,
  ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsFromJSONTyped,
  ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsToJSON,
} from './ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows';

/**
 *
 * @export
 * @interface PatchDriverLoadShipmentRowsRequestBody
 */
export interface PatchDriverLoadShipmentRowsRequestBody {
  /**
   *
   * @type {Array<ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows>}
   * @memberof PatchDriverLoadShipmentRowsRequestBody
   */
  rows?: Array<ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows>;
}

export function PatchDriverLoadShipmentRowsRequestBodyFromJSON(json: any): PatchDriverLoadShipmentRowsRequestBody {
  return PatchDriverLoadShipmentRowsRequestBodyFromJSONTyped(json, false);
}

export function PatchDriverLoadShipmentRowsRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchDriverLoadShipmentRowsRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    rows: !exists(json, 'rows')
      ? undefined
      : (json['rows'] as Array<any>).map(ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsFromJSON),
  };
}

export function PatchDriverLoadShipmentRowsRequestBodyToJSON(
  value?: PatchDriverLoadShipmentRowsRequestBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    rows:
      value.rows === undefined
        ? undefined
        : (value.rows as Array<any>).map(ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsToJSON),
  };
}
