import React from 'react';
import { Stack, StackProps } from '@mui/material';

export const ButtonContainer: React.FC<StackProps> = ({ children, ...props }) => {
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: '0.25rem', sm: '0.5rem' }} {...props}>
      {children}
    </Stack>
  );
};
