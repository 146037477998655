import React, { useState, useReducer, useEffect } from 'react';
import { Tabs, Tab, Paper, styled } from '@mui/material';
import ShipmentCoordination from './ShipmentCoordination';
import { DriverCoordination } from './DriverCoordination';
import Main from '../../components/Main';
import { getInitialState, reducer } from './coordination.state';
import ShipmentRowsCoordination from './ShipmentRowsCoordination';

const TAB_QUERY_PARAMETER = 'tab';

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface TabBarProps extends React.HTMLAttributes<HTMLElement> {
  tab: number; // <Tabs> expects a number
  setTab: (tab: number) => void;
}

const TabBar: React.FC<TabBarProps> = ({ tab, setTab }) => {
  return (
    <Paper square>
      <Tabs
        value={tab}
        onChange={(_event, newValue) => setTab(newValue)}
        variant="scrollable"
        scrollButtons
        indicatorColor="primary"
        textColor="primary"
        aria-label="coordination-tabs"
        style={{ marginBottom: '1rem' }}
        allowScrollButtonsMobile
      >
        <Tab label="Kuljettajittain" {...a11yProps(0)} />
        <Tab label="Toimitukset" {...a11yProps(1)} />
        <Tab label="Toimittamattomat toimitusrivit" {...a11yProps(2)} />
      </Tabs>
    </Paper>
  );
};

const CoordinationMain = styled(Main)({
  padding: 0,
});

const Content = styled('div')({
  padding: '0 1rem 1rem 1rem',
});

export const Coordination: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const [tab, setTab] = useState<number>(0);

  function changeTab(newTab: number) {
    const url = new URL(window.location.href);
    url.searchParams.set(TAB_QUERY_PARAMETER, String(newTab));
    window.history.replaceState(null, window.document.title, url.href);
    setTab(newTab);
  }
  useEffect(() => {
    const url = new URL(window.location.href);
    const urlTab = Number(url.searchParams.get(TAB_QUERY_PARAMETER)) ?? 0;
    changeTab([0, 1, 2].indexOf(urlTab) !== -1 ? urlTab : 0);
  }, []);

  return (
    <CoordinationMain>
      <TabBar tab={tab} setTab={changeTab} />
      <Content>
        {tab === 0 ? (
          <DriverCoordination state={state} dispatch={dispatch} />
        ) : tab === 1 ? (
          <ShipmentCoordination state={state} dispatch={dispatch} />
        ) : (
          <ShipmentRowsCoordination />
        )}
      </Content>
    </CoordinationMain>
  );
};
