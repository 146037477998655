import {
  Box,
  CircularProgress,
  DialogActions,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { debouncedValidateFieldsDispatch, updateFieldValue } from '../shipment.state';
import { Action, State } from '../types/shipment.types';
import { api, Office, Organization, PricingModelWithAreas, ShipmentStateEnum, User } from '../../../api';
import { OrganizationPicker } from '../../../components/OrganizationPicker';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { formatDate } from '../../../formatters';
import { Link as RouterLink } from 'react-router-dom';
import { ImmutableTextField } from '../../../components/ImmutableTextField';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { load } from '../EditShipment';
import { FullWidthButton } from '../../../components/StyledComponents/FullWidthButton';
import { StandardDatePicker } from '../../../components/DateAndTimePickers/StandardDatePicker';
import { StandardTimePicker } from '../../../components/DateAndTimePickers/StandardTimePicker';
import { EmptySelectMenuItem } from '../../../components/EmptySelectMenuItem';
import { PickersActionBar, PickersActionBarProps, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { canAccessCoordination } from '../../../utils';
import { FieldName } from '../types/shipment.field.types';
import {
  basicCheckboxWithLabel,
  basicInputWithLabel,
  basicTextFieldWithLabel,
  DispatchableInputFieldProps,
} from './basicInputs';

export interface WithUser {
  currentUser?: User;
}

export const StyledFormControl = styled(FormControl)(() => ({
  width: '7rem',
}));

const StyledDatePickerHelperText = styled('span')(() => ({
  color: 'gray',
  maxWidth: '18.5rem',
  fontSize: '0.75rem',
}));

export interface InputProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  disabled?: boolean;
}

export interface DispatchableInputProps extends InputProps {
  dispatch: Dispatch<Action>;
}

export const WeightKgInput = basicInputWithLabel('weight_kg', 'Paino', {
  startAdornment: <InputAdornment position="start">kg</InputAdornment>,
  inputProps: { min: 0 },
});

export const ChargeableWeightInput = basicInputWithLabel('chargeable_weight_kg', 'Rahdituspaino', {
  startAdornment: <InputAdornment position="start">kg</InputAdornment>,
});

export const VolumeM3Input = basicInputWithLabel('volume_m3', 'Tilavuus', {
  startAdornment: <InputAdornment position="start">m&sup3;</InputAdornment>,
  inputProps: { min: 0, max: 999 },
});

export const LengthLdmInput = basicInputWithLabel('length_ldm', 'Lavametrit', {
  startAdornment: <InputAdornment position="start">lvm</InputAdornment>,
  inputProps: { min: 0, max: 99 },
});

const DeliveryTimePickerContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  '> *': {
    flex: 1,
  },
});

export const DeliveryTimePicker: React.FC<DispatchableInputProps> = ({ state, dispatch }) => {
  if (
    state.fields.delivery_time_window_start.access === 'hidden' ||
    state.fields.delivery_time_window_end.access === 'hidden'
  ) {
    return null;
  }
  return (
    <DeliveryTimePickerContainer>
      <StandardTimePicker
        sx={{ maxWidth: '12rem' }}
        disabled={state.fields.delivery_time_window_start.access === 'readonly'}
        label="Aikaisintaan"
        value={state.fields.delivery_time_window_start.value}
        slotProps={{
          textField: {
            required: state.fields.delivery_time_window_start.required,
            id: 'delivery_time_window_start',
            name: 'delivery_time_window_start',
          },
        }}
        onChange={(time) => updateFieldValue('delivery_time_window_start', time, dispatch)}
      />
      <StandardTimePicker
        disabled={state.fields.delivery_time_window_end.access === 'readonly'}
        ampm={false}
        label="Viimeistään"
        value={state.fields.delivery_time_window_end.value}
        slotProps={{
          textField: {
            required: state.fields.delivery_time_window_end.required,
            id: 'delivery_time_window_end',
            name: 'delivery_time_window_end',
            error: state.fields.delivery_time_window_end.hasError,
            helperText: state.fields.delivery_time_window_end.feedback,
          },
        }}
        onChange={(time) => updateFieldValue('delivery_time_window_end', time, dispatch)}
      />
    </DeliveryTimePickerContainer>
  );
};

function PickersDayWithDoubleClick(props: PickersDayProps<DateTime>) {
  return <PickersDay {...props} />;
}

function DatePickerHelperText(props: PickersActionBarProps) {
  return (
    <DialogActions className={props.className} sx={{ paddingTop: 0 }}>
      <PickersActionBar {...props} />
      <StyledDatePickerHelperText>
        Tuplaklikkaamalla päivämäärää saat valittua kerralla nouto- ja toimituspäivän.
      </StyledDatePickerHelperText>
    </DialogActions>
  );
}

export const DeliveryDatePicker: React.FC<DispatchableInputProps & WithUser> = ({ state, dispatch, currentUser }) => {
  if (state.fields.delivery_date.access === 'hidden') {
    return null;
  }
  return (
    <StandardDatePicker
      disabled={state.fields.delivery_date.access === 'readonly'}
      label="Toimituspäivä"
      minDate={canAccessCoordination(currentUser) ? undefined : DateTime.local().startOf('day')}
      value={state.fields.delivery_date.value}
      slots={{
        actionBar: DatePickerHelperText,
        day: (props) =>
          PickersDayWithDoubleClick({
            ...props,
            onDoubleClick: () => updateFieldValue('pickup_date', state.fields.delivery_date.value ?? null, dispatch),
          }),
      }}
      slotProps={{
        textField: {
          required: state.fields.delivery_date.required,
          id: 'delivery_date',
          name: 'delivery_date',
          error: state.fields.delivery_date.hasError,
          helperText: state.fields.delivery_date.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('delivery_date', date, dispatch)}
    />
  );
};

export const Notes = basicTextFieldWithLabel('note', 'Lisätietoja', { multiline: true });

interface WithOrganization {
  organization?: Organization;
}

export const DeliveryPhoneNumber: React.FC<DispatchableInputFieldProps<string> & WithOrganization> = ({
  field,
  dispatch,
  organization,
}) => {
  if (field.access === 'hidden') {
    return null;
  }
  return (
    <TextField
      disabled={field.access === 'readonly'}
      required={field.required}
      id="delivery_phone_number"
      name="delivery_phone_number"
      error={field.hasError}
      helperText={field.feedback}
      label={
        organization?.id === field.value && organization.send_delivery_notification_sms
          ? 'Puhelinnumero / Avisointinumero'
          : 'Puhelinnumero'
      }
      value={field.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const DeliveryPhoneNumberSecondary = basicTextFieldWithLabel(
  'delivery_phone_number_secondary',
  'Toinen puhelinnumero',
);

export const DeliveryCity = basicTextFieldWithLabel('delivery_city', 'Kaupunki');

export const DeliveryPostalCode = basicTextFieldWithLabel('delivery_postal_code', 'Postinumero');

export const DeliveryAddress = basicTextFieldWithLabel('delivery_address', 'Osoite');

export const DeliveryName = basicTextFieldWithLabel('delivery_name', 'Nimi');

export interface StateSelectProps extends DispatchableInputProps {
  children: React.ReactElement[];
}

export const StateSelect: React.FC<StateSelectProps> = ({ state, dispatch, children }) => {
  if (state.fields.state.access === 'hidden') {
    return null;
  }
  return (
    <FormControl>
      <InputLabel shrink id="state_label">
        Tila
      </InputLabel>
      <Select
        id="state"
        labelId="state_label"
        disabled={state.fields.state.access === 'readonly'}
        value={state.fields.state.value}
        onChange={(event) => updateFieldValue('state', event.target.value as ShipmentStateEnum, dispatch)}
      >
        {children}
      </Select>
    </FormControl>
  );
};

const PickupTimePickerContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row nowrap',
});

export const PickupTimePicker: React.FC<DispatchableInputProps & WithUser> = ({ state, dispatch, currentUser }) => {
  if (state.fields.pickup_date.access === 'hidden' || state.fields.pickup_time.access === 'hidden') {
    return null;
  }
  return (
    <PickupTimePickerContainer>
      <StandardDatePicker
        sx={{ flexGrow: 1 }}
        disabled={state.fields.pickup_date.access === 'readonly'}
        label="Noutopäivä"
        minDate={canAccessCoordination(currentUser) ? undefined : DateTime.local().startOf('day')}
        value={state.fields.pickup_date.value}
        slots={{
          actionBar: DatePickerHelperText,
          day: (props) =>
            PickersDayWithDoubleClick({
              ...props,
              onDoubleClick: () => updateFieldValue('delivery_date', state.fields.pickup_date.value ?? null, dispatch),
            }),
        }}
        slotProps={{
          textField: {
            required: state.fields.pickup_date.required,
            id: 'pickup_date',
            name: 'pickup_date',
            error: state.fields.pickup_date.hasError,
            helperText: state.fields.pickup_date.feedback,
          },
        }}
        onChange={(date) => {
          updateFieldValue('pickup_date', date, dispatch);
        }}
      />
      <StandardTimePicker
        sx={{ flexGrow: 0, width: '7rem' }}
        disabled={state.fields.pickup_time.access === 'readonly'}
        ampm={false}
        label="Noutoaika"
        value={state.fields.pickup_time.value}
        slotProps={{
          textField: {
            required: state.fields.pickup_time.required,
            id: 'pickup_time',
            name: 'pickup_time',
            error: state.fields.pickup_time.hasError,
            helperText: state.fields.pickup_time.feedback,
          },
        }}
        onChange={(time) => updateFieldValue('pickup_time', time, dispatch)}
      />
    </PickupTimePickerContainer>
  );
};

export const PickupPhoneNumber = basicTextFieldWithLabel('pickup_phone_number', 'Puhelinnumero');

export const PickupPhoneNumberSecondary = basicTextFieldWithLabel(
  'pickup_phone_number_secondary',
  'Toinen puhelinnumero',
);

export const PickupCity = basicTextFieldWithLabel('pickup_city', 'Kaupunki');

export const PickupPostalCode = basicTextFieldWithLabel('pickup_postal_code', 'Postinumero');

export const PickupAddress = basicTextFieldWithLabel('pickup_address', 'Osoite');

export const PickupName = basicTextFieldWithLabel('pickup_name', 'Nimi');

export const JobNumber = basicTextFieldWithLabel('job_number', 'Työnumero');

export const ReferenceNumber = basicTextFieldWithLabel('reference_number', 'Asiakasviite');

export const BillingReferenceNumber = basicTextFieldWithLabel('billing_reference_number', 'Laskutusviite');

export const CustomerDistributionArea = basicTextFieldWithLabel('customer_distribution_area', 'Jakelualue');

export const OrganizationField: React.FC<DispatchableInputProps & WithUser> = ({ state, dispatch, currentUser }) => {
  if (state.fields.organization_id.access === 'hidden') {
    return null;
  }
  return currentUser?.is_multi_organization || currentUser?.is_superuser ? (
    <OrganizationPicker
      className="organization-field"
      disabled={state.fields.organization_id.access === 'readonly'}
      organizations={state.organizations}
      value={state.organizations.find((org) => org.id === state.fields.organization_id.value) ?? null}
      onChange={(organization) => {
        updateFieldValue('organization_id', organization?.id ?? null, dispatch);
        if (organization) {
          Promise.all([
            api.organizationOffices.getOrganizationOffices({ organizationId: organization?.id }),
            api.organizationAdditionalServices.getOrganizationAdditionalServices({ organizationId: organization?.id }),
          ]).then((responses) => {
            const [organizationOfficesResponse, organizationAdditionalServicesResponse] = responses;
            updateFieldValue('billing_office_organization_specific_id', '', dispatch);
            updateFieldValue('delivery_office_organization_specific_id', '', dispatch);
            updateFieldValue('pickup_office_organization_specific_id', '', dispatch);
            dispatch({
              type: 'CHANGE_ORGANIZATION',
              payload: {
                organizationOffices: organizationOfficesResponse.data,
                organizationAdditionalServices: organizationAdditionalServicesResponse.data,
              },
            });
            dispatch({
              type: 'UPDATE_PRICING_MODEL_AND_CUSTOM_CATEGORY',
            });
          });
        }
      }}
    />
  ) : null;
};

export const OrdererPhoneNumber = basicTextFieldWithLabel('orderer_phone_number', 'Puhelinnumero');

export const Orderer = basicTextFieldWithLabel('orderer', 'Nimi');

const updatePickupAddress = (office: Office, dispatch: React.Dispatch<Action>) => {
  updateFieldValue('pickup_name', office.name, dispatch);
  updateFieldValue('pickup_address', office.address, dispatch);
  updateFieldValue('pickup_postal_code', office.postal_code, dispatch);
  updateFieldValue('pickup_city', office.city, dispatch);
  updateFieldValue('pickup_phone_number', office.phone_number, dispatch);
};

const updateDeliveryAddress = (office: Office, dispatch: React.Dispatch<Action>) => {
  updateFieldValue('delivery_name', office.name, dispatch);
  updateFieldValue('delivery_address', office.address, dispatch);
  updateFieldValue('delivery_postal_code', office.postal_code, dispatch);
  updateFieldValue('delivery_city', office.city, dispatch);
  updateFieldValue('delivery_phone_number', office.phone_number, dispatch);
};

type addressType = 'pickup' | 'delivery';

const updateOfficeAndAddress = (
  event: SelectChangeEvent<string | null>,
  addressType: addressType,
  state: State,
  dispatch: React.Dispatch<Action>,
) => {
  const organizationSpecificId = event.target.value as string;
  updateFieldValue(event.target.name as FieldName, organizationSpecificId, dispatch);

  const office = state.organizationOffices.find(
    (office) =>
      office.organization_specific_office_id == organizationSpecificId &&
      office.organization_id == state.fields.organization_id.value,
  );
  if (office) {
    if (addressType == 'pickup') {
      updatePickupAddress(office, dispatch);
    } else {
      updateDeliveryAddress(office, dispatch);
    }
  }
};

const getAvailableOfficeMenuItems = (state: State) => {
  return state.organizationOffices
    .filter((office) => office.billing_enabled)
    .map((office, index) => (
      <MenuItem key={index + 1} value={office.organization_specific_office_id}>
        {office.name}
      </MenuItem>
    ));
};

export const PickupOffice: React.FC<DispatchableInputProps> = ({ state, dispatch }) => {
  if (state.fields.pickup_office_organization_specific_id.access === 'hidden') {
    return null;
  }
  return (
    <FormControl fullWidth>
      <InputLabel id="pickup_office_organization_specific_id-label">Toimipaikka</InputLabel>
      <Select
        disabled={state.fields.pickup_office_organization_specific_id.access === 'readonly'}
        id="pickup_office_organization_specific_id"
        name="pickup_office_organization_specific_id"
        error={state.fields.pickup_office_organization_specific_id.hasError}
        label="Toimipaikka"
        value={state.fields.pickup_office_organization_specific_id.value}
        required={false}
        onChange={(event) => {
          updateOfficeAndAddress(event, 'pickup', state, dispatch);
        }}
      >
        <EmptySelectMenuItem value="" />
        {getAvailableOfficeMenuItems(state)}
      </Select>
    </FormControl>
  );
};

const getAvailableCustomPricingCategoryMenuItems = (
  customPricingCategories: PricingModelWithAreas['custom_pricing_categories'],
) => {
  return customPricingCategories.map((customPricingCategory, index) => (
    <MenuItem key={index + 1} value={customPricingCategory.id}>
      {customPricingCategory.name}
    </MenuItem>
  ));
};

export const CustomPricingCategory: React.FC<DispatchableInputProps> = ({ state, dispatch }) => {
  if (state.fields.custom_pricing_category_id.access === 'hidden') {
    return null;
  }
  const customPricingCategories =
    state.pricingModels.find((pricingModel) => pricingModel.id === state.fields.pricing_model.value)
      ?.custom_pricing_categories ?? [];

  return (
    <FormControl fullWidth>
      <InputLabel id="custom_pricing_category_id-label">Hinnoittelukategoria</InputLabel>
      <Select
        disabled={state.fields.custom_pricing_category_id.access === 'readonly'}
        id="custom_pricing_category_id"
        name="custom_pricing_category_id"
        error={state.fields.custom_pricing_category_id.hasError}
        label="Hinnoittelukategoria"
        value={state.fields.custom_pricing_category_id.value}
        displayEmpty={true}
        required={false}
        onChange={(event) => {
          updateFieldValue(event.target.name as FieldName, event.target.value as string, dispatch);
        }}
      >
        {getAvailableCustomPricingCategoryMenuItems(customPricingCategories)}
      </Select>
    </FormControl>
  );
};

export const DeliveryOffice: React.FC<DispatchableInputProps> = ({ state, dispatch }) => {
  if (state.fields.delivery_office_organization_specific_id.access === 'hidden') {
    return null;
  }
  return (
    <FormControl fullWidth>
      <InputLabel id="delivery_office_organization_specific_id-label">Toimipaikka</InputLabel>
      <Select
        disabled={state.fields.delivery_office_organization_specific_id.access === 'readonly'}
        id="delivery_office_organization_specific_id"
        name="delivery_office_organization_specific_id"
        error={state.fields.delivery_office_organization_specific_id.hasError}
        label="Toimipaikka"
        value={state.fields.delivery_office_organization_specific_id.value}
        required={false}
        onChange={(event) => {
          updateOfficeAndAddress(event, 'delivery', state, dispatch);
        }}
      >
        <EmptySelectMenuItem value="" />
        {getAvailableOfficeMenuItems(state)}
      </Select>
    </FormControl>
  );
};

export const BillingOffice: React.FC<DispatchableInputProps> = ({ state, dispatch }) => {
  if (state.fields.billing_office_organization_specific_id.access === 'hidden') {
    return null;
  }
  return (
    <FormControl fullWidth>
      <InputLabel id="billing_office_organization_specific_id-label">Kustannuspaikka</InputLabel>
      <Select
        disabled={state.fields.billing_office_organization_specific_id.access === 'readonly'}
        id="billing_office_organization_specific_id"
        name="billing_office_organization_specific_id"
        error={state.fields.billing_office_organization_specific_id.hasError}
        label="Kustannuspaikka"
        value={state.fields.billing_office_organization_specific_id.value}
        required={false}
        onChange={async (event) => {
          updateFieldValue(event.target.name as FieldName, event.target.value as string, dispatch);
          dispatch({
            type: 'UPDATE_PRICING_MODEL_AND_CUSTOM_CATEGORY',
          });
        }}
      >
        <EmptySelectMenuItem value="" />
        {getAvailableOfficeMenuItems(state)}
      </Select>
    </FormControl>
  );
};

export const AdditionalServices: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <List>
      {state.additionalServiceFields
        .sort((a, b) => (a.additionalService.name ?? '').localeCompare(b.additionalService.name ?? ''))
        .filter(
          (additionalServiceField) =>
            !(additionalServiceField.additionalService.archived && (additionalServiceField.value || 0) === 0),
        )
        .map((additionalServiceField) => {
          return (
            <li key={additionalServiceField.additionalService.id} className="additional-service-list-item">
              <FormControl error={additionalServiceField.hasError} sx={{ width: '100%' }}>
                <InputLabel
                  className="additional-service-label"
                  htmlFor={`additionalService-${additionalServiceField.additionalService.name}`}
                >
                  {additionalServiceField.additionalService.name}
                </InputLabel>
                <Input
                  id={`additionalService_${additionalServiceField.additionalService.id}`}
                  name={`additionalService_${additionalServiceField.additionalService.id}`}
                  className="additional-service"
                  disabled={disabled}
                  type="number"
                  error={additionalServiceField.hasError}
                  value={additionalServiceField.value ?? ''}
                  inputProps={{ min: 0, max: 1000 }}
                  startAdornment={<InputAdornment position="start">kpl</InputAdornment>}
                  onChange={(event) => {
                    dispatch({
                      type: 'UPDATE_SHIPMENT_ADDITIONAL_SERVICE',
                      payload: {
                        additionalService: additionalServiceField.additionalService,
                        value: event.target.value,
                      },
                    });
                    debouncedValidateFieldsDispatch(dispatch);
                  }}
                />
                {additionalServiceField.feedback ? (
                  <FormHelperText className="additional-service-helper-text">
                    {additionalServiceField.feedback}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </li>
          );
        })}
    </List>
  );
};

export const CoordinationFieldSet: React.FC<InputProps & WithUser> = ({ state, currentUser }) => {
  const driverPhoneNumber = state.driver?.company_phone_number ?? '';
  return (
    <FieldSet id="coordination-fields-container">
      <legend>Ajojärjestely</legend>
      <ImmutableTextField label={'Kuorma'} id="load">
        {canAccessCoordination(currentUser) ? (
          <Link component={RouterLink} to={{ pathname: `/loads/${state.load?.id}` }}>
            {state.load ? `${state.load.id} ${state.load.organization_id ?? ''}` : ''}
          </Link>
        ) : (
          <Typography variant="body2">
            {state.load ? `${state.load.id} ${state.load.organization_id ?? ''}` : ''}
          </Typography>
        )}
      </ImmutableTextField>
      <ImmutableTextField label={'Kuljettaja'} id="load-driver">
        <Typography variant="body2">
          {`${state.driver?.last_name ?? ''} ${state.driver?.first_name ?? ''}`}{' '}
          <Link href={`tel:${driverPhoneNumber}`}>{driverPhoneNumber}</Link>
        </Typography>
      </ImmutableTextField>
      <ImmutableTextField label={'Auto'} id="load-car">
        <Typography variant="body2">{state.car ? (state.car?.licence_plate ?? '') : ''}</Typography>
      </ImmutableTextField>
      <ImmutableTextField label={'Ajopäivä'} id="load-drive-date">
        <Typography variant="body2">{(state.load?.drive_date && formatDate(state.load.drive_date)) ?? ''}</Typography>
      </ImmutableTextField>
      <ImmutableTextField label={'Avisointi'} id="delivery-status-url-identifier">
        <Link
          href={
            window.location.href.indexOf('prod') !== -1 || window.location.href.indexOf('extranet2.timecap.fi') !== -1
              ? `https://extranet2.timecap.fi/avi/${state.originalShipment?.delivery_status_url_identifier}`
              : `https://staging.dev.timecap.fi/avi/${state.originalShipment?.delivery_status_url_identifier}`
          }
          target="_blank"
        >
          {state.originalShipment?.delivery_status_url_identifier ? 'Näytä avisointi' : ''}
        </Link>
      </ImmutableTextField>
    </FieldSet>
  );
};

const IsAdrDelivery = basicCheckboxWithLabel('is_adr_delivery', 'ADR');
const RequiresHoist = basicCheckboxWithLabel('requires_hoist', 'Nosturi');
const IsExpressDelivery = basicCheckboxWithLabel('is_express_delivery', 'Pikatoimitus');
const RequiresCombinationVehicle = basicCheckboxWithLabel('requires_combination_vehicle', 'Yhdistelmäajoneuvo');

export const AdditionalRequirementFields: React.FC<DispatchableInputProps & WithUser> = ({ dispatch, state }) => {
  return (
    <>
      <IsAdrDelivery field={state.fields.is_adr_delivery} dispatch={dispatch} />
      <RequiresHoist field={state.fields.requires_hoist} dispatch={dispatch} />
      <IsExpressDelivery field={state.fields.is_express_delivery} dispatch={dispatch} />
      <RequiresCombinationVehicle field={state.fields.requires_combination_vehicle} dispatch={dispatch} />
    </>
  );
};

export const OtherContractNumber = basicTextFieldWithLabel('other_contract_number', 'Toimitusmääräys', {
  multiline: true,
  maxRows: 3,
});

interface WithHideAndLoadingRelatedShipments {
  hideRelatedShipments: boolean;
  setHideRelatedShipments: Dispatch<SetStateAction<boolean>>;
  isRelatedShipmentsLoading: boolean;
  showAllRelatedShipments?: boolean;
  setShowAllRelatedShipments?: Dispatch<SetStateAction<boolean>>;
}

export const RelatedShipmentsFieldSet: React.FC<
  DispatchableInputProps & WithUser & WithHideAndLoadingRelatedShipments
> = ({
  state,
  dispatch,
  currentUser,
  hideRelatedShipments,
  setHideRelatedShipments,
  isRelatedShipmentsLoading,
  showAllRelatedShipments,
  setShowAllRelatedShipments,
}) => {
  const defaultNumberOfShownShipments = 10;
  return (
    <FieldSet className="related-shipments-container">
      <legend>Sama viitenumero</legend>
      {isRelatedShipmentsLoading ? (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '0.25rem' }}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {state.relatedShipments.map((shipment, index) => (
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                currentUser && load(shipment.organization_id, shipment.id, dispatch, currentUser);
              }}
              style={
                index + 1 > defaultNumberOfShownShipments && hideRelatedShipments ? { display: 'none' } : undefined
              }
              key={shipment.id}
              component={RouterLink}
              to={{ pathname: `/shipments/${shipment.id}` }}
            >
              {shipment.id}{' '}
              {shipment.agreed_delivery_window_starts_at
                ? `(${formatDate(shipment.agreed_delivery_window_starts_at)})`
                : ''}
            </Link>
          ))}
          {state.relatedShipments.length > defaultNumberOfShownShipments ? (
            <FullWidthButton variant="text" onClick={() => setHideRelatedShipments(!hideRelatedShipments)}>
              {hideRelatedShipments ? <ExpandMore /> : <ExpandLess />}
            </FullWidthButton>
          ) : null}
          {!showAllRelatedShipments ? (
            <Tooltip
              title="Oletuksena näytetään vain kuukauden takaiset saman viitenumeron toimitukset."
              aria-label="Oletuksena näytetään vain kuukauden takaiset saman viitenumeron toimitukset."
            >
              <span>
                {/* This span makes Tooltip work with disabled button. Disabled elements do not send events, so we need this wrapper */}
                <FullWidthButton
                  variant="text"
                  className="get-all-related-shipments-button"
                  disabled={state.isLoading}
                  onClick={() => setShowAllRelatedShipments && setShowAllRelatedShipments(!showAllRelatedShipments)}
                >
                  Hae kaikki
                </FullWidthButton>
              </span>
            </Tooltip>
          ) : null}
        </>
      )}
    </FieldSet>
  );
};
