import { Button, styled, TableCell } from '@mui/material';

export const HeaderTableCell = styled(TableCell)({
  minWidth: '64px',
  fontWeight: 'bold',
  padding: '0.25rem',
});

export const DenseTableCell = styled(TableCell)({
  padding: '0.25rem',
});

export const EmptyTableCell = styled(TableCell)({
  textAlign: 'center',
  fontSize: '1rem',
});

export const StyledButton = styled(Button)({
  width: 'fit-content',
  margin: '1rem',
});
