/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { ShipmentRow, ShipmentRowFromJSON, ShipmentRowFromJSONTyped, ShipmentRowToJSON } from './ShipmentRow';

/**
 *
 * @export
 * @interface ShipmentRowResponseBody
 */
export interface ShipmentRowResponseBody {
  /**
   *
   * @type {ShipmentRow}
   * @memberof ShipmentRowResponseBody
   */
  data?: ShipmentRow;
}

export function ShipmentRowResponseBodyFromJSON(json: any): ShipmentRowResponseBody {
  return ShipmentRowResponseBodyFromJSONTyped(json, false);
}

export function ShipmentRowResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentRowResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, 'data') ? undefined : ShipmentRowFromJSON(json['data']),
  };
}

export function ShipmentRowResponseBodyToJSON(value?: ShipmentRowResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ShipmentRowToJSON(value.data),
  };
}
