/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CoordinationStatusResponseBody,
  CoordinationStatusResponseBodyFromJSON,
  CoordinationStatusResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

/**
 *
 */
export class CoordinatorResourcesApi extends runtime.BaseAPI {
  /**
   * Get status overview of unhandled Shipments.
   */
  async getCoordinationStatusRaw(
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CoordinationStatusResponseBody>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/coordination_status`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CoordinationStatusResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get status overview of unhandled Shipments.
   */
  async getCoordinationStatus(initOverrides?: RequestInit): Promise<CoordinationStatusResponseBody> {
    const response = await this.getCoordinationStatusRaw(initOverrides);
    return await response.value();
  }
}
