/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { CarrierDriver, CarrierDriverFromJSON, CarrierDriverFromJSONTyped, CarrierDriverToJSON } from './CarrierDriver';

/**
 *
 * @export
 * @interface GetCarrierDriverReponse
 */
export interface GetCarrierDriverReponse {
  /**
   *
   * @type {CarrierDriver}
   * @memberof GetCarrierDriverReponse
   */
  data: CarrierDriver;
}

export function GetCarrierDriverReponseFromJSON(json: any): GetCarrierDriverReponse {
  return GetCarrierDriverReponseFromJSONTyped(json, false);
}

export function GetCarrierDriverReponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCarrierDriverReponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: CarrierDriverFromJSON(json['data']),
  };
}

export function GetCarrierDriverReponseToJSON(value?: GetCarrierDriverReponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: CarrierDriverToJSON(value.data),
  };
}
