/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AssignmentResponseBody1,
  AssignmentResponseBody1FromJSON,
  AssignmentResponseBody1ToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

export interface OrganizationAssignmentApiGetOrganizationAssignmentRequest {
  organizationId: string;
  assignmentId: number;
}

/**
 *
 */
export class OrganizationAssignmentApi extends runtime.BaseAPI {
  /**
   * Get an assignment by id.
   */
  async getOrganizationAssignmentRaw(
    requestParameters: OrganizationAssignmentApiGetOrganizationAssignmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AssignmentResponseBody1>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationAssignment.',
      );
    }

    if (requestParameters.assignmentId === null || requestParameters.assignmentId === undefined) {
      throw new runtime.RequiredError(
        'assignmentId',
        'Required parameter requestParameters.assignmentId was null or undefined when calling getOrganizationAssignment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/assignments/{assignment_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'assignment_id'}}`, encodeURIComponent(String(requestParameters.assignmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssignmentResponseBody1FromJSON(jsonValue));
  }

  /**
   * Get an assignment by id.
   */
  async getOrganizationAssignment(
    requestParameters: OrganizationAssignmentApiGetOrganizationAssignmentRequest,
    initOverrides?: RequestInit,
  ): Promise<AssignmentResponseBody1> {
    const response = await this.getOrganizationAssignmentRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
