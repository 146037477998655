import React, { ReactElement, ReactNode } from 'react';
import { FilterProps, Row } from 'react-table';
import {
  FilledTextFieldProps,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedSelectProps,
  OutlinedTextFieldProps,
  Select,
  StandardTextFieldProps,
  styled,
  TextField,
} from '@mui/material';
import { Search, Close } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import theme from '../theme';
import { StandardDatePicker } from './DateAndTimePickers/StandardDatePicker';
import { DateTime } from 'luxon';
import { EmptySelectMenuItem } from './EmptySelectMenuItem';
import { DatePickerProps } from '@mui/x-date-pickers';

const StyledInputAdornment = styled(InputAdornment)({
  color: 'rgba(0, 0, 0, 0.54)',
});

const highlightBorder = `2px solid ${theme.palette.highlight.main}`;

const StyledTextField = styled(TextField)((props: OutlinedTextFieldProps & { filtervalue: string }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: props.filtervalue ? highlightBorder : undefined,
  },
}));

const StyledDatePicker = styled(StandardDatePicker)((props: DatePickerProps<any> & { filtervalue: string }) => ({
  ' & .MuiInputBase-input': {
    maxHeight: '0.8rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: props.filtervalue ? highlightBorder : undefined,
  },
}));

const StyledSelect = styled(Select)((props: OutlinedSelectProps & { filtervalue: string }) => ({
  ' & .MuiInputBase-input': {
    maxHeight: '0.8rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: props.filtervalue ? highlightBorder : undefined,
  },
}));

export const INPUT_HEIGHT = '1.8rem';

// Global search filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: Array<Row>;
  globalFilter: string;
  setGlobalFilter: FunctionStringCallback;
}): ReactElement {
  const count = preGlobalFilteredRows.length;
  return (
    <TextField
      variant="outlined"
      className="global-filter"
      size="small"
      value={globalFilter || ''}
      onChange={(e) => {
        setGlobalFilter(e.target.value);
      }}
      placeholder={`Hae ${count} kohteesta`}
      InputProps={{
        startAdornment: (
          <StyledInputAdornment position="start">
            <Search />
          </StyledInputAdornment>
        ),
        endAdornment: (
          <StyledInputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                setGlobalFilter('');
              }}
            >
              <Close />
            </IconButton>
          </StyledInputAdornment>
        ),
      }}
    />
  );
}

// When we don't want to render any filter, we have to take the space somehow
export function NoFilter(): ReactNode {
  return null;
}

// Default filtering
export function DefaultColumnFilter({ column: { filterValue, setFilter } }: FilterProps<any>): ReactElement {
  return (
    <StyledTextField
      inputProps={{
        style: {
          height: INPUT_HEIGHT,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: '0.4rem',
          paddingRight: '0.4rem',
        },
      }}
      size="small"
      variant="outlined"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      filtervalue={filterValue}
    />
  );
}

const TextFieldWrapper = (props: FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps) => {
  return <TextField {...props} placeholder="" />;
};

export function DateColumnFilter({
  column: { filterValue, setFilter },
}: FilterProps<DatePickerProps<any>>): ReactElement {
  return (
    <StyledDatePicker
      value={filterValue ? DateTime.fromMillis(Date.parse(filterValue)) : null}
      onChange={(date: DateTime) => {
        const dateString = date && date.isValid ? date?.startOf('day').toJSDate().toString().substring(0, 15) : '';
        setFilter(dateString);
      }}
      slotProps={{
        textField: {
          variant: 'outlined',
        },
      }}
      slots={{
        textField: TextFieldWrapper,
      }}
      filtervalue={filterValue}
    />
  );
}

// Enum filtering
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: FilterProps<any>): ReactElement {
  const options = React.useMemo(() => {
    const options = new Set<any>();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...Array.from(options.values())].sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  }, [id, preFilteredRows]);
  return (
    <StyledSelect
      style={{ width: '100%', height: INPUT_HEIGHT }}
      variant="outlined"
      defaultValue={''}
      value={filterValue ?? ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      SelectDisplayProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
      filtervalue={filterValue}
    >
      <EmptySelectMenuItem value="" />
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

// Autocomplete filtering
export function AutoCompleteFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: FilterProps<any>): ReactElement {
  const options = React.useMemo(() => {
    const options = new Set<string>(
      preFilteredRows.filter((row) => !/^\s*$/.test(row.values[id])).map((row) => row.values[id]),
    );
    if (options)
      return Array.from(options.values()).sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
  }, [id, preFilteredRows]);

  return (
    <Autocomplete
      options={options ? options.map((option) => option) : []}
      sx={{
        ' & .MuiInputBase-input': {
          maxHeight: '1.05rem',
        },
      }}
      getOptionLabel={(option) => option}
      autoHighlight
      size="small"
      renderInput={(params) => (
        <StyledTextField
          {...params}
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            style: { textOverflow: 'clip', padding: 0, margin: 0 },
          }}
          filtervalue={filterValue}
        />
      )}
      onChange={(_e, option) => {
        setFilter(option);
      }}
      defaultValue={null}
      value={filterValue || null}
    />
  );
}
