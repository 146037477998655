import React, { FunctionComponent, useReducer, useEffect } from 'react';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Column } from 'react-table';
import { load, initialState, reducer, DistancesListViewSettings } from './distancesList.state';
import { ReactTable } from '../../components/ReactTable';
import { getViewSettings } from '../settings';
import { distanceColumns } from './components/distanceColumns';

const VIEW_ID = 'distances_list' as const;

export const DistancesList: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const viewSettings = React.useMemo(() => getViewSettings<DistancesListViewSettings>(VIEW_ID), []);

  useEffect(() => {
    load(state, dispatch);
  }, []);

  const columns = React.useMemo((): Column<any>[] => distanceColumns, []);

  const data = React.useMemo(() => state.distances, [state.distances]);

  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        header="Virheelliset etäisyydet"
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä etäisyyksiä."
        initialState={{
          sortBy: viewSettings.sortBy ?? [],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        onStateChange={(state) => {
          dispatch({
            type: 'SET_VIEW_SETTINGS',
            payload: { filters: state.filters, sortBy: state.sortBy, hiddenColumns: state.hiddenColumns },
          });
        }}
        columnSelector={true}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default DistancesList;
