import { Checkbox, FormControlLabel, FormHelperText, Link, TextField } from '@mui/material';
import React, { Dispatch } from 'react';
import { Action, FieldName, State, canChangeManagedUserPassword, updateFieldValue } from '../user.state';
import { FieldSetWithSetWidth } from '../../../components/StyledComponents/FieldSet';
import { OrganizationPicker } from '../../../components/OrganizationPicker';
import { Organization, User } from '../../../api';
import { DriverPicker } from '../../../components/DriverPicker';
import { ImmutableTextField } from '../../../components/ImmutableTextField';
import { Link as RouterLink } from 'react-router-dom';
import { FieldSetContainer } from '../../../components/StyledComponents/FieldSetContainer';
import { canAccessUsers } from '../../../utils';

interface InputProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  disabled?: boolean;
  createNewUser?: boolean;
  required?: boolean;
  alsoCreateEmployee?: boolean;
}

interface WithUser {
  currentUser?: User;
}

interface DispatchableInputProps extends InputProps {
  dispatch: Dispatch<Action>;
}

export const AllUserFields: React.FC<DispatchableInputProps & WithUser> = ({
  dispatch,
  state,
  currentUser,
  createNewUser,
  alsoCreateEmployee,
}) => {
  return (
    <FieldSetContainer>
      <FieldSetWithSetWidth>
        <legend>Perustiedot</legend>
        {canAccessUsers(currentUser) ? <Organization state={state} dispatch={dispatch} disabled={false} /> : null}
        <Username state={state} dispatch={dispatch} disabled={!createNewUser} />
        {alsoCreateEmployee ? null : <FirstName state={state} dispatch={dispatch} disabled={false} />}
        {alsoCreateEmployee ? null : <LastName state={state} dispatch={dispatch} disabled={false} />}
        <Email state={state} dispatch={dispatch} disabled={false} />
        {alsoCreateEmployee ? null : <PhoneNumber state={state} dispatch={dispatch} disabled={false} />}
        {canAccessUsers(currentUser) && !alsoCreateEmployee ? (
          <LinkedDriver state={state} dispatch={dispatch} disabled={!Boolean(state.fields.is_driver.value)} />
        ) : null}
      </FieldSetWithSetWidth>
      {canAccessUsers(currentUser) || currentUser?.is_superuser ? (
        <FieldSetWithSetWidth>
          <legend>Käyttöoikeudet</legend>
          {canAccessUsers(currentUser) ? (
            <>
              <IsCoordinatorChecbox state={state} dispatch={dispatch} />
              <IsMultiOrganizationCheckbox state={state} dispatch={dispatch} />
              <IsWorkshopCheckbox state={state} dispatch={dispatch} />
              <IsDriverCheckbox state={state} dispatch={dispatch} />
              <CanAccessCustomerReport state={state} dispatch={dispatch} />
              <IsManagerCheckbox state={state} dispatch={dispatch} />
            </>
          ) : null}
          {currentUser?.is_superuser ? <IsSuperUserCheckbox state={state} dispatch={dispatch} /> : null}
        </FieldSetWithSetWidth>
      ) : null}
      {canChangeManagedUserPassword(currentUser, state.originalUser) || createNewUser ? (
        <FieldSetWithSetWidth>
          <legend>{createNewUser ? 'Salasana' : 'Vaihda salasana'}</legend>
          {currentUser?.username === state.originalUser?.username && !createNewUser ? (
            <OldPassword state={state} dispatch={dispatch} />
          ) : null}
          <Password state={state} dispatch={dispatch} />
          <PasswordAgain state={state} dispatch={dispatch} />
          {currentUser?.username !== state.originalUser?.username &&
          currentUser?.organization_id === HOST_ORGANIZATION &&
          !createNewUser ? (
            <OwnPassword state={state} dispatch={dispatch} />
          ) : null}
        </FieldSetWithSetWidth>
      ) : null}
      {!createNewUser && canAccessUsers(currentUser) ? (
        <FieldSetWithSetWidth>
          <legend>Lisätiedot</legend>
          <LinkedEmployeeLink state={state} dispatch={dispatch} disabled={false} />
        </FieldSetWithSetWidth>
      ) : null}
    </FieldSetContainer>
  );
};

const Username: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.username.required}
      name="username"
      error={state.fields.username.hasError}
      helperText={state.fields.username.feedback}
      className="username"
      label="Käyttäjätunnus"
      value={state.fields.username.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const Organization: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <OrganizationPicker
      required={state.fields.organization_id.required}
      disabled={disabled}
      organizations={state.organizations ?? []}
      value={
        state.organizations
          //TODO, remove this filter. Php needs an empty organization for some aspects of the old service to work.
          .filter((organization) => organization.id !== '')
          .find((org) => org.id === state.fields.organization_id.value) ?? null
      }
      onChange={(organization) => {
        updateFieldValue('organization_id', organization?.id ?? null, dispatch);
      }}
      helperText={state.fields.organization_id.feedback}
      error={state.fields.organization_id.hasError}
    />
  );
};

const Email: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.email.required}
      name="email"
      error={state.fields.email.hasError}
      helperText={state.fields.email.feedback}
      className="email"
      label="Sähköposti"
      value={state.fields.email.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const FirstName: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.first_name.required}
      name="first_name"
      error={state.fields.first_name.hasError}
      helperText={state.fields.first_name.feedback}
      className="first_name"
      label="Etunimi"
      value={state.fields.first_name.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const LastName: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.last_name.required}
      name="last_name"
      error={state.fields.last_name.hasError}
      helperText={state.fields.last_name.feedback}
      className="last_name"
      label="Sukunimi"
      value={state.fields.last_name.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const PhoneNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.gsm.required}
      name="gsm"
      error={state.fields.gsm.hasError}
      helperText={state.fields.gsm.feedback}
      className="gsm"
      label="Puhelinnumero"
      value={state.fields.gsm.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const IsCoordinatorChecbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_coordinator_checkbox"
          name="is_coordinator"
          checked={state.fields.is_coordinator.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_coordinator.value, dispatch)
          }
        />
      }
      label="Ajojärjestelijä"
    />
  );
};

const IsMultiOrganizationCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_multi_organization_checkbox"
          name="is_multi_organization"
          checked={state.fields.is_multi_organization.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_multi_organization.value, dispatch)
          }
        />
      }
      label="Moniyritys"
    />
  );
};

const IsSuperUserCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_superuser_checkbox"
          name="is_superuser"
          checked={state.fields.is_superuser.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_superuser.value, dispatch)
          }
        />
      }
      label="Superuser"
    />
  );
};

const IsWorkshopCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_workshop_checkbox"
          name="is_workshop"
          checked={state.fields.is_workshop.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_workshop.value, dispatch)
          }
        />
      }
      label="Korjaamo"
    />
  );
};

const IsDriverCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_driver_checkbox"
          name="is_driver"
          checked={state.fields.is_driver.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_driver.value, dispatch)
          }
        />
      }
      label="Kuljettaja"
    />
  );
};

const IsManagerCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_manager_checkbox"
          name="is_manager"
          checked={state.fields.is_manager.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_manager.value, dispatch)
          }
        />
      }
      label="Esihenkilö"
    />
  );
};

const CanAccessCustomerReport: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="can_access_customer_report_checkbox"
          name="can_access_customer_report"
          checked={state.fields.can_access_customer_report.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.can_access_customer_report.value, dispatch)
          }
        />
      }
      label="Asiakasraportit ja hinnat"
    />
  );
};

const LinkedDriver: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <>
      <DriverPicker
        disabled={state.isLoading || disabled}
        required={state.fields.driver_id.required}
        error={state.fields.driver_id.hasError}
        helperText={state.fields.driver_id.feedback}
        drivers={state.drivers}
        value={state.drivers.find((driver) => driver.id === state.fields.driver_id.value) ?? null}
        onChange={(driver) => {
          updateFieldValue('driver_id', driver?.id ?? null, dispatch);
        }}
      />
      {state.fields.is_driver.value ? (
        <FormHelperText>Valitse Timeweb kuljettaja, jonka tämä käyttäjätunnus on.</FormHelperText>
      ) : (
        <FormHelperText>Kuljettaja käyttöoikeus vaaditaan kentän muokkaukseen.</FormHelperText>
      )}
    </>
  );
};

const OwnPassword: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.own_password.required}
      name="own_password"
      type="password"
      error={state.fields.own_password.hasError}
      helperText={state.fields.own_password.feedback}
      className="own_password"
      label="Syötä oma salasana"
      value={state.fields.own_password.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const OldPassword: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.old_password.required}
      name="old_password"
      type="password"
      error={state.fields.old_password.hasError}
      helperText={state.fields.old_password.feedback}
      className="old_password"
      label="Vanha salasana"
      value={state.fields.old_password.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const Password: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.password.required}
      name="password"
      type="password"
      error={state.fields.password.hasError}
      helperText={state.fields.password.feedback}
      className="password"
      label="Uusi salasana"
      value={state.fields.password.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const PasswordAgain: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.password_again.required}
      name="password_again"
      type="password"
      error={state.fields.password_again.hasError}
      helperText={state.fields.password_again.feedback}
      className="password_again"
      label="Uusi salasana uudestaan"
      value={state.fields.password_again.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const LinkedEmployeeLink: React.FC<DispatchableInputProps> = ({ state }) => {
  const linkedEmployee = state.drivers.find(
    (employee) => employee.id === state.originalUser?.driver_id && state.originalUser?.driver_id,
  );
  return (
    <ImmutableTextField label={'Yhdistetty työntekijä'} id="linked-user-link">
      {linkedEmployee ? (
        <Link component={RouterLink} to={`/employees/${linkedEmployee?.id}`}>
          {linkedEmployee.first_name} {linkedEmployee.last_name}
        </Link>
      ) : (
        <span>-</span>
      )}
    </ImmutableTextField>
  );
};
