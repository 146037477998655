import {
  userTextFields,
  name,
  phoneNumber,
  employeeNumber,
  employeePostalCode,
  dateNotRequired,
} from '../../validation';
import { Field, FieldName, State } from './employee.state';

type ValidationResult = {
  hasError: boolean;
  feedback?: string;
};

const getValidationResult = (joiSchema: any, field: Field<any>): ValidationResult => {
  const result = joiSchema.validate(field.value);
  const validationResult: ValidationResult = {
    hasError: result.error !== undefined,
    feedback: result.error?.message,
  };
  return validationResult;
};

const validateField = (fieldName: FieldName, fields: State['fields'], state: State): ValidationResult => {
  const field = fields[fieldName];
  let validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  switch (fieldName) {
    case 'employee_number': {
      const invalidEmployeeNumbers = state.employees
        .map((employee) => employee.employee_number)
        .filter(
          (employeeNumber) => employeeNumber !== state.originalEmployee?.employee_number && employeeNumber !== '',
        );
      validationResult = getValidationResult(employeeNumber.disallow(...invalidEmployeeNumbers).insensitive(), field);
      break;
    }
    case 'first_name':
    case 'last_name':
    case 'license': {
      validationResult = getValidationResult(userTextFields, field);
      break;
    }
    case 'company_phone_number':
    case 'personal_phone_number': {
      validationResult = getValidationResult(phoneNumber, field);
      break;
    }
    case 'hired_by':
    case 'address':
    case 'city': {
      validationResult = getValidationResult(name, field);
      break;
    }
    case 'postal_code': {
      validationResult = getValidationResult(employeePostalCode, field);
      break;
    }
    case 'proactive_driving_expire_date':
    case 'adr_expire_date':
    case 'work_safety_expire_date':
    case 'professional_qualification': {
      validationResult = getValidationResult(dateNotRequired, field);
      break;
    }
  }
  if (field.required && (field.value === undefined || field.value === null || field.value === '')) {
    validationResult.hasError = true;
    validationResult.feedback = 'Kenttä on pakollinen';
  }
  return validationResult;
};

export const validateFields = (state: State): { fields: State['fields'] } => {
  const newFields = { ...state.fields } as any;
  for (const fieldName of Object.keys(state.fields)) {
    newFields[fieldName] = {
      ...newFields[fieldName],
      ...validateField(fieldName as FieldName, state.fields, state),
    };
  }
  return {
    fields: newFields,
  };
};
