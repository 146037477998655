/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FutureWorkHourType,
  FutureWorkHourTypeFromJSON,
  FutureWorkHourTypeFromJSONTyped,
  FutureWorkHourTypeToJSON,
} from './FutureWorkHourType';

/**
 *
 * @export
 * @interface FutureWorkHourPostBody
 */
export interface FutureWorkHourPostBody {
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHourPostBody
   */
  date: Date;
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHourPostBody
   */
  starts_at?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHourPostBody
   */
  ends_at?: Date | null;
  /**
   *
   * @type {number}
   * @memberof FutureWorkHourPostBody
   */
  assignment_id?: number;
  /**
   *
   * @type {string}
   * @memberof FutureWorkHourPostBody
   */
  note?: string | null;
  /**
   *
   * @type {FutureWorkHourType}
   * @memberof FutureWorkHourPostBody
   */
  type: FutureWorkHourType;
}

export function FutureWorkHourPostBodyFromJSON(json: any): FutureWorkHourPostBody {
  return FutureWorkHourPostBodyFromJSONTyped(json, false);
}

export function FutureWorkHourPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FutureWorkHourPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: new Date(json['date']),
    starts_at: !exists(json, 'starts_at') ? undefined : json['starts_at'] === null ? null : new Date(json['starts_at']),
    ends_at: !exists(json, 'ends_at') ? undefined : json['ends_at'] === null ? null : new Date(json['ends_at']),
    assignment_id: !exists(json, 'assignment_id') ? undefined : json['assignment_id'],
    note: !exists(json, 'note') ? undefined : json['note'],
    type: FutureWorkHourTypeFromJSON(json['type']),
  };
}

export function FutureWorkHourPostBodyToJSON(value?: FutureWorkHourPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date.toISOString(),
    starts_at:
      value.starts_at === undefined ? undefined : value.starts_at === null ? null : value.starts_at.toISOString(),
    ends_at: value.ends_at === undefined ? undefined : value.ends_at === null ? null : value.ends_at.toISOString(),
    assignment_id: value.assignment_id,
    note: value.note,
    type: FutureWorkHourTypeToJSON(value.type),
  };
}
