/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssignmentPostBody
 */
export interface AssignmentPostBody {
  /**
   *
   * @type {number}
   * @memberof AssignmentPostBody
   */
  assignment_specific_id: number;
  /**
   *
   * @type {string}
   * @memberof AssignmentPostBody
   */
  description: string | null;
}

export function AssignmentPostBodyFromJSON(json: any): AssignmentPostBody {
  return AssignmentPostBodyFromJSONTyped(json, false);
}

export function AssignmentPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignmentPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    assignment_specific_id: json['assignment_specific_id'],
    description: json['description'],
  };
}

export function AssignmentPostBodyToJSON(value?: AssignmentPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    assignment_specific_id: value.assignment_specific_id,
    description: value.description,
  };
}
