/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from './User';

/**
 *
 * @export
 * @interface UserResponseBody
 */
export interface UserResponseBody {
  /**
   *
   * @type {User}
   * @memberof UserResponseBody
   */
  data?: User;
}

export function UserResponseBodyFromJSON(json: any): UserResponseBody {
  return UserResponseBodyFromJSONTyped(json, false);
}

export function UserResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, 'data') ? undefined : UserFromJSON(json['data']),
  };
}

export function UserResponseBodyToJSON(value?: UserResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: UserToJSON(value.data),
  };
}
