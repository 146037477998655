/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OfficePutBody
 */
export interface OfficePutBody {
  /**
   *
   * @type {number}
   * @memberof OfficePutBody
   */
  readonly id?: number;
  /**
   *
   * @type {string}
   * @memberof OfficePutBody
   */
  readonly organization_id?: string;
  /**
   *
   * @type {string}
   * @memberof OfficePutBody
   */
  organization_specific_office_id: string;
  /**
   *
   * @type {string}
   * @memberof OfficePutBody
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof OfficePutBody
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof OfficePutBody
   */
  postal_code: string;
  /**
   *
   * @type {string}
   * @memberof OfficePutBody
   */
  city: string;
  /**
   *
   * @type {boolean}
   * @memberof OfficePutBody
   */
  billing_enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof OfficePutBody
   */
  phone_number: string;
  /**
   *
   * @type {string}
   * @memberof OfficePutBody
   */
  contact_person_name: string | null;
  /**
   *
   * @type {number}
   * @memberof OfficePutBody
   */
  default_pricing_model: number | null;
  /**
   *
   * @type {boolean}
   * @memberof OfficePutBody
   */
  is_other_carrier: boolean;
}

export function OfficePutBodyFromJSON(json: any): OfficePutBody {
  return OfficePutBodyFromJSONTyped(json, false);
}

export function OfficePutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfficePutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    organization_id: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    organization_specific_office_id: json['organization_specific_office_id'],
    name: json['name'],
    address: json['address'],
    postal_code: json['postal_code'],
    city: json['city'],
    billing_enabled: json['billing_enabled'],
    phone_number: json['phone_number'],
    contact_person_name: json['contact_person_name'],
    default_pricing_model: json['default_pricing_model'],
    is_other_carrier: json['is_other_carrier'],
  };
}

export function OfficePutBodyToJSON(value?: OfficePutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organization_specific_office_id: value.organization_specific_office_id,
    name: value.name,
    address: value.address,
    postal_code: value.postal_code,
    city: value.city,
    billing_enabled: value.billing_enabled,
    phone_number: value.phone_number,
    contact_person_name: value.contact_person_name,
    default_pricing_model: value.default_pricing_model,
    is_other_carrier: value.is_other_carrier,
  };
}
