import { Dispatch, Reducer } from 'redux';
import { Organization, api, User, apiV2, Client, getData } from '../api';
import { getOrganizationAsClient, Organizations } from '../formatters';

export type CurrentUser = User & {
  clients: Client[];
  organization: Organization;
};

export type CarrierUser = CurrentUser & { carrier_id: number };

const SET_CURRENT_USER = 'SET_CURRENT_USER' as const;
const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN' as const;
const SET_ORGANIZATION = 'SET_ORGANIZATION' as const;

interface SetCurrentUserAction {
  type: typeof SET_CURRENT_USER;
  user: CurrentUser | CarrierUser;
}

interface SetIsLoggedInAction {
  type: typeof SET_IS_LOGGED_IN;
  isLoggedIn: boolean;
}

interface SetOrganizationAction {
  type: typeof SET_ORGANIZATION;
  organization: Organization;
}

type AuthAction = SetCurrentUserAction | SetIsLoggedInAction | SetOrganizationAction;

interface State {
  user?: CurrentUser | CarrierUser;
  organization?: Organization;
  isLoggedIn: boolean;
}

const initialState: State = {
  isLoggedIn: false,
};

const authReducer: Reducer<State, AuthAction> = (state = initialState, action): State => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, user: action.user };
    case SET_IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.isLoggedIn };
    case SET_ORGANIZATION:
      return { ...state, organization: action.organization };
    default:
      return state;
  }
};

export const initializeAuthentication = () => {
  return async (dispatch: Dispatch<AuthAction>): Promise<void> => {
    try {
      const currentUser = await api.auth.getCurrentUser();
      const organization = await getData(api.organizations.getOrganization.bind(api.organizations), {
        organizationId: currentUser.organization_id,
      });
      const partnerships = organization.carrier_id
        ? await getData(apiV2.carrier.getCarrierClients.bind(apiV2.carrier), { carrierId: organization.carrier_id })
        : [];

      //TODO Remove workshop client logic after fixing workshop users
      const isWorkshopUser = currentUser.organization_id === Organizations.Workshop;
      const workShopOrganizations = isWorkshopUser
        ? await getData(api.organizations.getOrganizations.bind(api.organizations), {})
        : [];
      const workShopClients = workShopOrganizations.map((x) => getOrganizationAsClient(x));

      const organizationAsClient = getOrganizationAsClient(organization);
      const clients = partnerships
        .map((partnership) => partnership.client)
        .filter((client): client is Client => client !== undefined && client.is_active)
        .concat(organizationAsClient);

      dispatch({
        type: SET_CURRENT_USER,
        user: {
          ...currentUser,
          organization: organization,
          clients: isWorkshopUser ? workShopClients : clients,
          ...(organization.carrier_id && { carrier_id: organization.carrier_id }),
        },
      });
      dispatch({
        type: SET_ORGANIZATION,
        organization: organization,
      });
      dispatch({
        type: SET_IS_LOGGED_IN,
        isLoggedIn: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export default authReducer;
