/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Shipment, ShipmentFromJSON, ShipmentFromJSONTyped, ShipmentToJSON } from './Shipment';

/**
 *
 * @export
 * @interface PostShipmentDeliveryTransportResponseBody
 */
export interface PostShipmentDeliveryTransportResponseBody {
  /**
   *
   * @type {Shipment}
   * @memberof PostShipmentDeliveryTransportResponseBody
   */
  line_haul_transport: Shipment;
  /**
   *
   * @type {Shipment}
   * @memberof PostShipmentDeliveryTransportResponseBody
   */
  delivery_transport: Shipment;
}

export function PostShipmentDeliveryTransportResponseBodyFromJSON(
  json: any,
): PostShipmentDeliveryTransportResponseBody {
  return PostShipmentDeliveryTransportResponseBodyFromJSONTyped(json, false);
}

export function PostShipmentDeliveryTransportResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PostShipmentDeliveryTransportResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    line_haul_transport: ShipmentFromJSON(json['line_haul_transport']),
    delivery_transport: ShipmentFromJSON(json['delivery_transport']),
  };
}

export function PostShipmentDeliveryTransportResponseBodyToJSON(
  value?: PostShipmentDeliveryTransportResponseBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    line_haul_transport: ShipmentToJSON(value.line_haul_transport),
    delivery_transport: ShipmentToJSON(value.delivery_transport),
  };
}
