/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  OrganizationScheduleShipment1,
  OrganizationScheduleShipment1FromJSON,
  OrganizationScheduleShipment1FromJSONTyped,
  OrganizationScheduleShipment1ToJSON,
} from './OrganizationScheduleShipment1';

/**
 *
 * @export
 * @interface OrganizationScheduleShipmentsResponseBody
 */
export interface OrganizationScheduleShipmentsResponseBody {
  /**
   *
   * @type {Array<OrganizationScheduleShipment1>}
   * @memberof OrganizationScheduleShipmentsResponseBody
   */
  data: Array<OrganizationScheduleShipment1>;
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipmentsResponseBody
   */
  next: number | null;
}

export function OrganizationScheduleShipmentsResponseBodyFromJSON(
  json: any,
): OrganizationScheduleShipmentsResponseBody {
  return OrganizationScheduleShipmentsResponseBodyFromJSONTyped(json, false);
}

export function OrganizationScheduleShipmentsResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationScheduleShipmentsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(OrganizationScheduleShipment1FromJSON),
    next: json['next'],
  };
}

export function OrganizationScheduleShipmentsResponseBodyToJSON(
  value?: OrganizationScheduleShipmentsResponseBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(OrganizationScheduleShipment1ToJSON),
    next: value.next,
  };
}
