/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CoordinationStatusResponseBodyShipments,
  CoordinationStatusResponseBodyShipmentsFromJSON,
  CoordinationStatusResponseBodyShipmentsFromJSONTyped,
  CoordinationStatusResponseBodyShipmentsToJSON,
} from './CoordinationStatusResponseBodyShipments';

/**
 *
 * @export
 * @interface CoordinationStatusResponseBody
 */
export interface CoordinationStatusResponseBody {
  /**
   *
   * @type {Array<CoordinationStatusResponseBodyShipments>}
   * @memberof CoordinationStatusResponseBody
   */
  shipments?: Array<CoordinationStatusResponseBodyShipments>;
}

export function CoordinationStatusResponseBodyFromJSON(json: any): CoordinationStatusResponseBody {
  return CoordinationStatusResponseBodyFromJSONTyped(json, false);
}

export function CoordinationStatusResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CoordinationStatusResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    shipments: !exists(json, 'shipments')
      ? undefined
      : (json['shipments'] as Array<any>).map(CoordinationStatusResponseBodyShipmentsFromJSON),
  };
}

export function CoordinationStatusResponseBodyToJSON(value?: CoordinationStatusResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    shipments:
      value.shipments === undefined
        ? undefined
        : (value.shipments as Array<any>).map(CoordinationStatusResponseBodyShipmentsToJSON),
  };
}
