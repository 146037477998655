import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { assignmentDescription } from '../../../validation';
import { TextField } from '@mui/material';

interface RecipientDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  recipient: string;
  setRecipient: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
}

export const RecipientDialog: React.FC<RecipientDialogProps> = ({
  open,
  onClose,
  recipient,
  setRecipient,
  onSave,
  isLoading,
}) => {
  const validatedRecipient = assignmentDescription.validate(recipient);
  const error = Boolean(validatedRecipient.error?.message);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="recipient-dialog-title"
        aria-describedby="recipient-dialog-description"
      >
        <DialogTitle id="recipient-dialog-title">Lisää vastaanottaja</DialogTitle>
        <DialogContent>
          <TextField
            name="recipient"
            fullWidth
            label="Vastaanottaja"
            value={recipient}
            error={error}
            helperText={validatedRecipient.error?.message}
            onChange={(e) => setRecipient(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button fullWidth disabled={isLoading || error} onClick={onSave} autoFocus>
            Merkitse toimitetuksi
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
