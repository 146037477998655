import React, { FunctionComponent, useEffect, useMemo, useReducer } from 'react';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { EnrichedLoad, getInitialState, load, reducer } from './loads.state';
import { dateFormatShort } from '../../formatters';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { canAccessCoordination } from '../../utils';
import MRT from '../../components/MaterialReactTable/MRT';
import { DateTime } from 'luxon';
import { createMRTColumnHelper, MRT_TableOptions } from 'material-react-table';
import { ShipmentSubRowAsync } from '../../components/MaterialReactTable/MRTShipmentSubRow';

const VIEW_ID = 'loads' as const;

const Loads: FunctionComponent = () => {
  const currentUser = useCurrentUser();
  const [state, dispatch] = useReducer(reducer, getInitialState());

  useEffect(() => {
    if (currentUser) {
      const abortController = new AbortController();
      load(currentUser, state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [state.dateRange, currentUser]);

  const data = React.useMemo(() => state.loads, [state.loads]);

  const columnHelper = createMRTColumnHelper<EnrichedLoad>();

  const columns = useMemo(
    () =>
      currentUser
        ? [
            columnHelper.accessor('id', {
              header: 'Id',
              Cell: ({ renderedCellValue }) =>
                canAccessCoordination(currentUser) ? (
                  <Link component={RouterLink} to={{ pathname: `/loads/${renderedCellValue}` }}>
                    {renderedCellValue}
                  </Link>
                ) : (
                  renderedCellValue
                ),
              filterFn: 'includesString',
            }),
            columnHelper.accessor((row) => `${row.driver?.last_name ?? ''} ${row.driver?.first_name ?? ''}`, {
              id: 'driver_name',
              header: 'Kuljettaja',
              filterVariant: 'multi-select',
              filterFn: 'arrIncludesSome',
            }),
            columnHelper.accessor((row) => `${row.car?.licence_plate ?? ''}`, {
              id: 'car',
              header: 'Auto',
              filterVariant: 'multi-select',
              filterFn: 'arrIncludesSome',
            }),
            columnHelper.accessor((row) => `${row.trailerLicenceplate ?? ''}`, {
              id: 'trailer',
              header: 'Perävaunu',
              filterVariant: 'multi-select',
              filterFn: 'arrIncludesSome',
            }),
            columnHelper.accessor((row) => (row.drive_date ? DateTime.fromJSDate(row.drive_date) : row.drive_date), {
              id: 'drive_date',
              header: 'Ajopäivä',
              filterFn: 'dateFilterFn',
              filterVariant: 'date',
              Cell: ({ cell }) => cell.getValue<DateTime>().toFormat(dateFormatShort),
            }),
            columnHelper.accessor('organizationName', {
              header: 'Asiakas',
              filterVariant: 'multi-select',
              filterFn: 'arrIncludesSome',
            }),

            columnHelper.accessor('weight_kg', {
              header: 'Paino (kg)',
            }),
            columnHelper.accessor('volume_m3', {
              header: 'Tilavuus (m³)',
            }),
            columnHelper.accessor('length_ldm', {
              header: 'Lavametrit',
            }),
          ]
        : [],
    [currentUser],
  );

  const tableOptions: MRT_TableOptions<EnrichedLoad> = {
    data: data,
    columns: columns,
    renderDetailPanel: ({ row }) => <ShipmentSubRowAsync row={row} />,
  };

  return (
    <>
      <MRT header={'Kuormat'} viewId={VIEW_ID} isLoading={state.isLoading} dispatch={dispatch} {...tableOptions} />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default Loads;
