/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  StatusResponseBody,
  StatusResponseBodyFromJSON,
  StatusResponseBodyToJSON,
} from '../models';

/**
 *
 */
export class StatusApi extends runtime.BaseAPI {
  /**
   * Get status of backend server
   */
  async getServerStatusRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatusResponseBody>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/status`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => StatusResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get status of backend server
   */
  async getServerStatus(initOverrides?: RequestInit): Promise<StatusResponseBody> {
    const response = await this.getServerStatusRaw(initOverrides);
    return await response.value();
  }
}
