/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DriverFields {
  IsAtService = 'is_at_service',
  FirstName = 'first_name',
  LastName = 'last_name',
  FullName = 'full_name',
  PhoneNumber = 'phone_number',
  Profession = 'profession',
  License = 'license',
  ProactiveDrivingExpireDate = 'proactive_driving_expire_date',
  AdrExpireDate = 'adr_expire_date',
  WorkSafetyExpireDate = 'work_safety_expire_date',
  ProfessionalQualification = 'professional_qualification',
}

export function DriverFieldsFromJSON(json: any): DriverFields {
  return DriverFieldsFromJSONTyped(json, false);
}

export function DriverFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverFields {
  return json as DriverFields;
}

export function DriverFieldsToJSON(value?: DriverFields | null): any {
  return value as any;
}
