import { Backdrop, Dialog, IconButton, Link, LinkProps, styled, useMediaQuery } from '@mui/material';
import { Clear, Room } from '@mui/icons-material';
import React from 'react';
import theme from '../theme';

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
});

const MapIcon = styled(Room)({
  color: theme.palette.primary.main,
});

const CloseDialogIconButton = styled(IconButton)({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
});

interface AddressLinkProps extends LinkProps {
  title: string;
  showIcon?: boolean;
  address: string | null;
  postalCode: string | null;
  city: string | null;
}

export const AddressLink: React.FC<AddressLinkProps> = ({
  title,
  showIcon,
  address,
  postalCode,
  city,
  ...linkProps
}) => {
  return (
    <StyledLink
      href={`https://www.google.com/maps/search/?api=1&query=${address}+${postalCode}+${city}`}
      onClick={(event: any) => event.stopPropagation()} // Prevents SubRows from opening
      target="_blank"
      {...linkProps}
    >
      {title}
      {showIcon && <MapIcon fontSize="small" />}
    </StyledLink>
  );
};

export const getFormattedAddresses = (addresses: Address[]): string => {
  return addresses.reduce((memo, address) => {
    memo += `${addressToFormattedString(address)}/`;
    return memo;
  }, '');
};

interface RouteLinkProps extends LinkProps {
  address1: string | null;
  postalCode1: string | null;
  city1: string | null;
  address2: string | null;
  postalCode2: string | null;
  city2: string | null;
}

export const RouteLink: React.FC<RouteLinkProps> = ({
  address1,
  city1,
  postalCode1,
  address2,
  city2,
  postalCode2,
  ...linkProps
}) => {
  if (!(address1 && city1 && postalCode1 && address2 && city2 && postalCode2)) {
    return null;
  }
  return (
    <StyledLink
      href={`https://www.google.com/maps/dir/${encodeURI(
        getFormattedAddresses([
          { address: address1, city: city1, postalCode: postalCode1 },
          { address: address2, city: city2, postalCode: postalCode2 },
        ]),
      )}`}
      onClick={(event: any) => event.stopPropagation()} // Prevents SubRows from opening
      target="_blank"
      {...linkProps}
    >
      Näytä reitti
      <MapIcon fontSize="small" />
    </StyledLink>
  );
};

export interface Address {
  address?: string;
  postalCode?: string;
  city?: string;
}

/*
 * Takes address object and returns formatted string
 * address returns street name and number, (e.g. Address 1 doors 1-2 = Address 1)
 * city returns city name (e.g. City and info = City)
 */
export const addressToFormattedString = (address: Address): string =>
  [
    address.address ? address.address.match(/^(\D+)(\d+)|^(\D+)/)?.[0] : '',
    address.postalCode ? address.postalCode : '',
    address.city ? address.city.match(/[a-öA-Ö]+/)?.[0] : '',
  ]
    .filter((address) => address)
    .join('+');

interface MapIFrameProps extends React.HTMLAttributes<HTMLElement> {
  addresses: Address[];
  apiKey: string;
}

const MapIFrame: React.FC<MapIFrameProps> = ({ className, addresses, apiKey }) => {
  const origin = addresses[0] ?? undefined;
  const destination = addresses[addresses.length - 1] ?? undefined;
  const waypoints = addresses.length > 2 ? addresses.slice(1, addresses.length - 1) : [];
  const waypointsString = waypoints.reduce((memo, waypoint) => {
    memo += `${memo ? '|' : ''}${addressToFormattedString(waypoint)}`;
    return memo;
  }, '');
  const mapDefaultLanguage = '&language=fi';
  const mapDefaultZoom = '&zoom=8';
  const mapDefaultCenterLocation = '&center=60.3402,24.7951';
  return !origin || !destination ? null : (
    <iframe
      className={'route-map-iframe ' + className}
      width="600"
      height="450"
      style={{ border: 0, borderRadius: 10 }}
      loading="lazy"
      allowFullScreen
      src={`https://www.google.com/maps/embed/v1/directions?key=${apiKey}&origin=${encodeURI(
        addressToFormattedString(origin),
      )}&destination=${encodeURI(addressToFormattedString(destination))}${
        waypointsString
          ? '&waypoints=' + encodeURI(waypointsString) + mapDefaultLanguage + mapDefaultZoom + mapDefaultCenterLocation
          : ''
      }`}
    ></iframe>
  );
};

interface MapIFrameLocationProps extends React.HTMLAttributes<HTMLElement> {
  address: Address;
  apiKey: string;
}

export const MapIFrameLocation: React.FC<MapIFrameLocationProps> = ({ className, address, apiKey }) => {
  if (!origin) {
    return <></>;
  }
  const mapDefaultLanguage = '&language=fi';

  return (
    <iframe
      className={'location-map-iframe ' + className}
      style={{ border: 0, height: '100%', width: '100%' }}
      loading="lazy"
      src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURI(addressToFormattedString(address))}${mapDefaultLanguage}`}
    ></iframe>
  );
};

interface RouteMapProps extends React.HTMLAttributes<HTMLElement> {
  addresses: Address[];
  apiKey: string;
  onClose: () => void;
}

export const RouteMap: React.FC<RouteMapProps> = ({ className, addresses, apiKey, onClose }) => {
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  return isSmall ? (
    <Dialog
      className="route-map-modal"
      aria-labelledby="shipment-row-product-modal-title"
      open={true}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        margin: theme.spacing(2),
      }}
      maxWidth="xl"
      fullScreen={true}
    >
      <MapIFrame addresses={addresses} style={{ width: '100%', height: '100%' }} apiKey={apiKey} />
      <CloseDialogIconButton onClick={onClose}>
        <Clear />
      </CloseDialogIconButton>
    </Dialog>
  ) : (
    <MapIFrame
      className={className}
      style={{
        margin: '0 1rem 1rem 0',
        flexGrow: 1,
        [theme.breakpoints.down('lg')]: {
          marginLeft: theme.spacing(1),
        },
      }}
      addresses={addresses}
      apiKey={apiKey}
    />
  );
};
