/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ColumnLayoutPostBody,
  ColumnLayoutPostBodyFromJSON,
  ColumnLayoutPostBodyToJSON,
  ColumnLayoutPostResponseBody,
  ColumnLayoutPostResponseBodyFromJSON,
  ColumnLayoutPostResponseBodyToJSON,
  ColumnLayoutPutBody,
  ColumnLayoutPutBodyFromJSON,
  ColumnLayoutPutBodyToJSON,
  ColumnLayoutPutResponseBody,
  ColumnLayoutPutResponseBodyFromJSON,
  ColumnLayoutPutResponseBodyToJSON,
  ColumnLayoutResponseBody,
  ColumnLayoutResponseBodyFromJSON,
  ColumnLayoutResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

export interface ColumnLayoutsApiCreateColumnLayoutRequest {
  organizationId: string;
  viewId: string;
  columnLayoutPostBody: ColumnLayoutPostBody;
}

export interface ColumnLayoutsApiDeleteColumnLayoutRequest {
  organizationId: string;
  viewId: string;
  columnLayoutId: number;
}

export interface ColumnLayoutsApiGetColumnLayoutsRequest {
  organizationId: string;
  viewId: string;
  next?: number;
}

export interface ColumnLayoutsApiPutColumnLayoutRequest {
  organizationId: string;
  viewId: string;
  columnLayoutId: number;
  columnLayoutPutBody: ColumnLayoutPutBody;
}

/**
 *
 */
export class ColumnLayoutsApi extends runtime.BaseAPI {
  /**
   * Create new column layout.
   */
  async createColumnLayoutRaw(
    requestParameters: ColumnLayoutsApiCreateColumnLayoutRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ColumnLayoutPostResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createColumnLayout.',
      );
    }

    if (requestParameters.viewId === null || requestParameters.viewId === undefined) {
      throw new runtime.RequiredError(
        'viewId',
        'Required parameter requestParameters.viewId was null or undefined when calling createColumnLayout.',
      );
    }

    if (requestParameters.columnLayoutPostBody === null || requestParameters.columnLayoutPostBody === undefined) {
      throw new runtime.RequiredError(
        'columnLayoutPostBody',
        'Required parameter requestParameters.columnLayoutPostBody was null or undefined when calling createColumnLayout.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/views/{view_id}/column_layouts`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'view_id'}}`, encodeURIComponent(String(requestParameters.viewId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ColumnLayoutPostBodyToJSON(requestParameters.columnLayoutPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ColumnLayoutPostResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create new column layout.
   */
  async createColumnLayout(
    requestParameters: ColumnLayoutsApiCreateColumnLayoutRequest,
    initOverrides?: RequestInit,
  ): Promise<ColumnLayoutPostResponseBody> {
    const response = await this.createColumnLayoutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a column layout by id.
   */
  async deleteColumnLayoutRaw(
    requestParameters: ColumnLayoutsApiDeleteColumnLayoutRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteColumnLayout.',
      );
    }

    if (requestParameters.viewId === null || requestParameters.viewId === undefined) {
      throw new runtime.RequiredError(
        'viewId',
        'Required parameter requestParameters.viewId was null or undefined when calling deleteColumnLayout.',
      );
    }

    if (requestParameters.columnLayoutId === null || requestParameters.columnLayoutId === undefined) {
      throw new runtime.RequiredError(
        'columnLayoutId',
        'Required parameter requestParameters.columnLayoutId was null or undefined when calling deleteColumnLayout.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/views/{view_id}/column_layouts/{column_layout_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'view_id'}}`, encodeURIComponent(String(requestParameters.viewId)))
          .replace(`{${'column_layout_id'}}`, encodeURIComponent(String(requestParameters.columnLayoutId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a column layout by id.
   */
  async deleteColumnLayout(
    requestParameters: ColumnLayoutsApiDeleteColumnLayoutRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteColumnLayoutRaw(requestParameters, initOverrides);
  }

  /**
   * Get all Column Layouts.
   */
  async getColumnLayoutsRaw(
    requestParameters: ColumnLayoutsApiGetColumnLayoutsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ColumnLayoutResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getColumnLayouts.',
      );
    }

    if (requestParameters.viewId === null || requestParameters.viewId === undefined) {
      throw new runtime.RequiredError(
        'viewId',
        'Required parameter requestParameters.viewId was null or undefined when calling getColumnLayouts.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/views/{view_id}/column_layouts`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'view_id'}}`, encodeURIComponent(String(requestParameters.viewId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ColumnLayoutResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all Column Layouts.
   */
  async getColumnLayouts(
    requestParameters: ColumnLayoutsApiGetColumnLayoutsRequest,
    initOverrides?: RequestInit,
  ): Promise<ColumnLayoutResponseBody> {
    const response = await this.getColumnLayoutsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Put a column layout.
   */
  async putColumnLayoutRaw(
    requestParameters: ColumnLayoutsApiPutColumnLayoutRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ColumnLayoutPutResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling putColumnLayout.',
      );
    }

    if (requestParameters.viewId === null || requestParameters.viewId === undefined) {
      throw new runtime.RequiredError(
        'viewId',
        'Required parameter requestParameters.viewId was null or undefined when calling putColumnLayout.',
      );
    }

    if (requestParameters.columnLayoutId === null || requestParameters.columnLayoutId === undefined) {
      throw new runtime.RequiredError(
        'columnLayoutId',
        'Required parameter requestParameters.columnLayoutId was null or undefined when calling putColumnLayout.',
      );
    }

    if (requestParameters.columnLayoutPutBody === null || requestParameters.columnLayoutPutBody === undefined) {
      throw new runtime.RequiredError(
        'columnLayoutPutBody',
        'Required parameter requestParameters.columnLayoutPutBody was null or undefined when calling putColumnLayout.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/views/{view_id}/column_layouts/{column_layout_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'view_id'}}`, encodeURIComponent(String(requestParameters.viewId)))
          .replace(`{${'column_layout_id'}}`, encodeURIComponent(String(requestParameters.columnLayoutId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ColumnLayoutPutBodyToJSON(requestParameters.columnLayoutPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ColumnLayoutPutResponseBodyFromJSON(jsonValue));
  }

  /**
   * Put a column layout.
   */
  async putColumnLayout(
    requestParameters: ColumnLayoutsApiPutColumnLayoutRequest,
    initOverrides?: RequestInit,
  ): Promise<ColumnLayoutPutResponseBody> {
    const response = await this.putColumnLayoutRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
