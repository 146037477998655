import React, { FunctionComponent, useState } from 'react';
import { api, Distance, getAllPages, SearchDistancesPostBody } from '../../api';
import { ReactTable } from '../../components/ReactTable';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from '../../components/Notification';
import { FormHelperText } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '../../components/Loading';
import { FormInput } from './components/searchShipmentsInputs';
import { distanceColumns } from './components/distanceColumns';
import { Column } from 'react-table';
import {
  StyledFormContentContainer,
  StyledFormFieldSet,
  StyledFormSearchButton,
} from '../../components/StyledComponents/StyledFormComponents';
import theme from '../../theme';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export interface Fields {
  address1: string;
  city1: string;
  address2: string;
  city2: string;
}

const SearchDistances: FunctionComponent = () => {
  const formMethods = useForm<Fields>({
    defaultValues: {
      address1: '',
      city1: '',
      address2: '',
      city2: '',
    },
  });
  const { handleSubmit, watch } = formMethods;
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationType>({ message: null });
  const [distances, setDistances] = useState<Distance[]>([]);
  const currentUser = useCurrentUser();
  const isValid =
    ((watch('address1').length > 2 && watch('city1').length > 2) ||
      (watch('address2').length > 2 && watch('city2').length > 2)) ??
    false;

  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };

  const getSearchDistancesPostBody = (fields: Fields): SearchDistancesPostBody => {
    return {
      address1: fields.address1 || undefined,
      city1: fields.city1 || undefined,
      address2: fields.address2 || undefined,
      city2: fields.city2 || undefined,
    };
  };

  const searchDistances = async (fields: Fields) => {
    try {
      setIsLoading(true);
      if (!currentUser) {
        return;
      }
      const distances = await getAllPages(api.search.searchDistances.bind(api.search), {
        searchDistancesPostBody: getSearchDistancesPostBody(fields),
      });
      setDistances(distances);
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Virhe haettaessa etäisyyksiä', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo((): Column<any>[] => distanceColumns, []);

  const data = React.useMemo(() => distances, [distances]);
  return (
    <>
      <Loading isLoading={isLoading} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(searchDistances)} autoComplete="off">
          <StyledFormFieldSet>
            <legend>Etsi etäisyyksiä</legend>
            <StyledFormContentContainer>
              <FormInput disabled={isLoading} required={true} label="Nouto-osoite" name="address1" />
              <FormInput disabled={isLoading} required={true} label="Noutokaupunki" name="city1" />
              <FormInput disabled={isLoading} required={true} label="Toimitusosoite" name="address2" />
              <FormInput disabled={isLoading} required={true} label="Toimituskaupunki" name="city2" />
            </StyledFormContentContainer>
            <StyledFormContentContainer>
              <StyledFormSearchButton
                variant="contained"
                color="primary"
                type="submit"
                className="search-button"
                disabled={isLoading || !isValid}
              >
                Etsi
              </StyledFormSearchButton>
              {!isValid ? (
                <FormHelperText
                  className="express_delivery_price-helper-text"
                  style={{ color: theme.palette.error.main }}
                >
                  Täytä vähintään noudon tai toimituksen osoitetiedot.
                </FormHelperText>
              ) : null}
            </StyledFormContentContainer>
          </StyledFormFieldSet>
        </form>
      </FormProvider>
      <ReactTable
        columns={columns}
        data={data}
        header="Etäisyydet"
        isLoading={isLoading}
        emptyText="Ei näytettäviä etäisyyksiä."
        autoResetSettings={true}
        autoResetPage={true}
      />
      <Notification {...snackbarProps} />
    </>
  );
};

export default SearchDistances;
