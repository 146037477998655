/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DriverGroupsPostBody
 */
export interface DriverGroupsPostBody {
  /**
   *
   * @type {string}
   * @memberof DriverGroupsPostBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DriverGroupsPostBody
   */
  description: string;
}

export function DriverGroupsPostBodyFromJSON(json: any): DriverGroupsPostBody {
  return DriverGroupsPostBodyFromJSONTyped(json, false);
}

export function DriverGroupsPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverGroupsPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    description: json['description'],
  };
}

export function DriverGroupsPostBodyToJSON(value?: DriverGroupsPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
  };
}
