/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Organization, OrganizationFromJSON, OrganizationFromJSONTyped, OrganizationToJSON } from './Organization';

/**
 *
 * @export
 * @interface OrganizationsResponseBody
 */
export interface OrganizationsResponseBody {
  /**
   *
   * @type {Array<Organization>}
   * @memberof OrganizationsResponseBody
   */
  data: Array<Organization>;
}

export function OrganizationsResponseBodyFromJSON(json: any): OrganizationsResponseBody {
  return OrganizationsResponseBodyFromJSONTyped(json, false);
}

export function OrganizationsResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(OrganizationFromJSON),
  };
}

export function OrganizationsResponseBodyToJSON(value?: OrganizationsResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(OrganizationToJSON),
  };
}
