import React from 'react';

const DriverFrontPage: React.FC = () => {
  return (
    <div>
      <h1>Driver Front Page</h1>
    </div>
  );
};

export default DriverFrontPage;
