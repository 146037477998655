/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShipmentReportAggregate,
  ShipmentReportAggregateFromJSON,
  ShipmentReportAggregateFromJSONTyped,
  ShipmentReportAggregateToJSON,
} from './ShipmentReportAggregate';
import {
  ShipmentReportFilter,
  ShipmentReportFilterFromJSON,
  ShipmentReportFilterFromJSONTyped,
  ShipmentReportFilterToJSON,
} from './ShipmentReportFilter';

/**
 *
 * @export
 * @interface ShipmentReportPostBody
 */
export interface ShipmentReportPostBody {
  /**
   *
   * @type {Date}
   * @memberof ShipmentReportPostBody
   */
  agreed_delivery_window_date_range_starts_at: Date;
  /**
   *
   * @type {Date}
   * @memberof ShipmentReportPostBody
   */
  agreed_delivery_window_date_range_ends_at: Date;
  /**
   *
   * @type {Array<ShipmentReportFilter>}
   * @memberof ShipmentReportPostBody
   */
  filters: Array<ShipmentReportFilter>;
  /**
   *
   * @type {Array<ShipmentReportAggregate>}
   * @memberof ShipmentReportPostBody
   */
  aggregates: Array<ShipmentReportAggregate>;
  /**
   *
   * @type {Array<string>}
   * @memberof ShipmentReportPostBody
   */
  group_by?: Array<ShipmentReportPostBodyGroupByEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum ShipmentReportPostBodyGroupByEnum {
  Day = 'day',
  OrganizationId = 'organization_id',
}

export function ShipmentReportPostBodyFromJSON(json: any): ShipmentReportPostBody {
  return ShipmentReportPostBodyFromJSONTyped(json, false);
}

export function ShipmentReportPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentReportPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    agreed_delivery_window_date_range_starts_at: new Date(json['agreed_delivery_window_date_range_starts_at']),
    agreed_delivery_window_date_range_ends_at: new Date(json['agreed_delivery_window_date_range_ends_at']),
    filters: (json['filters'] as Array<any>).map(ShipmentReportFilterFromJSON),
    aggregates: (json['aggregates'] as Array<any>).map(ShipmentReportAggregateFromJSON),
    group_by: !exists(json, 'group_by') ? undefined : json['group_by'],
  };
}

export function ShipmentReportPostBodyToJSON(value?: ShipmentReportPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    agreed_delivery_window_date_range_starts_at: value.agreed_delivery_window_date_range_starts_at.toISOString(),
    agreed_delivery_window_date_range_ends_at: value.agreed_delivery_window_date_range_ends_at.toISOString(),
    filters: (value.filters as Array<any>).map(ShipmentReportFilterToJSON),
    aggregates: (value.aggregates as Array<any>).map(ShipmentReportAggregateToJSON),
    group_by: value.group_by,
  };
}
