import React, { Dispatch, useEffect, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import {
  Action,
  getInitialState,
  reducer,
  State,
  updateAdditionalServices,
  updateAssignments,
  updateFieldValue,
  updateOffices,
} from './organization.state';
import { api, OrganizationPostBody } from '../../api';
import { useNavigate } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { FieldSet } from '../../components/StyledComponents/FieldSet';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AgreedDeliveryTimePicker, BusinessId, Id, IsActiveCheckbox, Name, SmsCheckbox } from './components/inputs';
import { AdditionalServicesTable, validateAdditionalServices } from './components/AdditionalServicesTable';
import OfficesTable, { validateOffices } from './components/OfficesTable';
import AssignmentsTable, { validateAssignments } from './components/AssignmentsTable';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { PricingModelPicker } from '../../components/PricingModelPicker';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { FieldSetContainer } from '../../components/StyledComponents/FieldSetContainer';

const load = async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [allAssignmentsResponse, allOrganizationsResponse, pricingModelsResponse] = await Promise.all([
      api.assignments.getAssignments(),
      api.organizations.getOrganizations({ alsoInactive: true }),
      api.pricing.getPricingModels(),
    ]);
    dispatch({
      type: 'SET_ALL_ORGANIZATIONS',
      payload: {
        allOrganizations: allOrganizationsResponse.data,
      },
    });
    dispatch({
      type: 'SET_ALL_ASSIGNMENTS',
      payload: {
        allAssignments: allAssignmentsResponse.data,
      },
    });
    dispatch({
      type: 'SET_PRICING_MODELS',
      payload: {
        pricingModels: pricingModelsResponse.data,
      },
    });
  } catch (err) {
    let message = 'Sivun lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Sivun lataus epäonnistui!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const getOrganizationPostBodyFromState = (state: State): OrganizationPostBody => {
  return {
    is_active: state.fields.is_active.value,
    id: state.fields.id.value,
    name: state.fields.name.value,
    business_id: state.fields.business_id.value,
    send_delivery_notification_sms: state.fields.send_delivery_notification_sms.value,
    default_agreed_delivery_window_time_starts_at: state.fields.default_agreed_delivery_window_time_starts_at.value,
    default_agreed_delivery_window_time_ends_at: state.fields.default_agreed_delivery_window_time_ends_at.value,
    default_pricing_model: state.fields.default_pricing_model.value,
  };
};

const saveNewOrganization = async (
  state: State,
  dispatch: Dispatch<Action>,
  navigate: ReturnType<typeof useNavigate>,
) => {
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });

  try {
    const organizationResponse = await api.organizations.createOrganization({
      organizationPostBody: getOrganizationPostBodyFromState(state),
    });
    await Promise.all([
      Promise.all(
        state.additionalServices.map((additionalService) => {
          return api.organizationAdditionalServices.createAdditionalService({
            organizationId: state.fields.id.value,
            additionalServicePostBody: {
              name: additionalService.name,
              price: additionalService.price,
              work_price: additionalService.work_price,
              archived: additionalService.archived,
            },
          });
        }),
      ),
      Promise.all(
        state.assignments.map((assignment) => {
          return api.organizationAssignments.createAssignment({
            organizationId: state.fields.id.value,
            assignmentPostBody: {
              description: assignment.description,
              assignment_specific_id: assignment.assignment_specific_id,
            },
          });
        }),
      ),
      Promise.all(
        state.offices.map((office) => {
          return api.organizationOffices.createOffice({
            organizationId: state.fields.id.value,
            officePostBody: {
              organization_specific_office_id: office.organization_specific_office_id,
              name: office.name,
              address: office.address,
              postal_code: office.postal_code,
              city: office.city,
              billing_enabled: office.billing_enabled,
              phone_number: office.phone_number,
              contact_person_name: office.contact_person_name,
              default_pricing_model: office.default_pricing_model,
              is_other_carrier: office.is_other_carrier,
            },
          });
        }),
      ),
    ]);
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Asiakas tallennettu!' } });
    setTimeout(() => {
      dispatch({ type: 'SET_LOADING', payload: false });
      navigate(`/organizations/${organizationResponse.data.id}`);
    }, 1000);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

const CreateOrganization: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const navigate = useNavigate();

  useEffect(() => {
    load(dispatch);
  }, []);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title="Uusi asiakas">
            <SaveButton
              disabled={
                !state.isValid ||
                state.isLoading ||
                validateAdditionalServices(state.additionalServices) ||
                validateOffices(state.offices) ||
                validateAssignments(state.assignments, state.allAssignments)
              }
              id="save-organization-button"
              tooltip={
                !state.isValid ||
                validateAdditionalServices(state.additionalServices) ||
                validateOffices(state.offices) ||
                validateAssignments(state.assignments, state.allAssignments)
                  ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä'
                  : ''
              }
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                saveNewOrganization(state, dispatch, navigate);
                event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
              }}
            >
              Luo uusi asiakas
            </SaveButton>
          </Header>
        </HeaderContainer>
        <FieldSetContainer>
          <FieldSet id="organization-basic-fields">
            <legend>Perustiedot</legend>
            <Id state={state} dispatch={dispatch} disabled={false} />
            <Name state={state} dispatch={dispatch} />
            <BusinessId state={state} dispatch={dispatch} />
            <AgreedDeliveryTimePicker state={state} dispatch={dispatch} />
            <IsActiveCheckbox state={state} dispatch={dispatch} />
            <SmsCheckbox state={state} dispatch={dispatch} />
            <PricingModelPicker
              disabled={state.isLoading}
              pricingModels={state.pricingModels}
              value={
                state.pricingModels.find(
                  (pricingModel) => pricingModel.id === state.fields.default_pricing_model.value,
                ) ?? null
              }
              onChange={(pricingModel) => {
                updateFieldValue('default_pricing_model', pricingModel?.id ?? null, dispatch);
              }}
            />
          </FieldSet>
        </FieldSetContainer>
      </StyledForm>
      <AdditionalServicesTable
        additionalServices={state.additionalServices}
        onAdditionalServicesUpdate={(additionalServices) => updateAdditionalServices(additionalServices, dispatch)}
      />
      <OfficesTable
        offices={state.offices}
        onOfficesUpdate={(offices) => updateOffices(offices, dispatch)}
        pricingModels={state.pricingModels}
      />
      <AssignmentsTable
        allAssignments={state.allAssignments}
        assignments={state.assignments}
        onAssignmentsUpdate={(assignments) => updateAssignments(assignments, dispatch)}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default CreateOrganization;
