/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PriceAccess {
  None = 'none',
  CustomerPricing = 'customer_pricing',
  CarrierPricing = 'carrier_pricing',
}

export function PriceAccessFromJSON(json: any): PriceAccess {
  return PriceAccessFromJSONTyped(json, false);
}

export function PriceAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceAccess {
  return json as PriceAccess;
}

export function PriceAccessToJSON(value?: PriceAccess | null): any {
  return value as any;
}
