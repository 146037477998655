/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShipmentUpdateAdditionalServicePutBody,
  ShipmentUpdateAdditionalServicePutBodyFromJSON,
  ShipmentUpdateAdditionalServicePutBodyFromJSONTyped,
  ShipmentUpdateAdditionalServicePutBodyToJSON,
} from './ShipmentUpdateAdditionalServicePutBody';

/**
 *
 * @export
 * @interface PatchDriverLoadShipmentsByIdRequestBody
 */
export interface PatchDriverLoadShipmentsByIdRequestBody {
  /**
   * NB. toimitetaan_seuraavaksi is not stored in database yet but it only triggers few actions like delivery notifications.
   * @type {string}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  state?: PatchDriverLoadShipmentsByIdRequestBodyStateEnum;
  /**
   *
   * @type {string}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  recipient?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  note?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  other_contract_number?: string | null;
  /**
   *
   * @type {number}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  order_in_load?: number | null;
  /**
   *
   * @type {number}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  hourly_work_hours?: number;
  /**
   *
   * @type {string}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  hourly_work_reason?: string;
  /**
   *
   * @type {number}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  wait_hours?: number;
  /**
   *
   * @type {string}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  wait_reason?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  unloaded_with_customer_permission?: boolean;
  /**
   *
   * @type {Date}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  arrived_to_pickup_location_at?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  picked_up_at?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  arrived_to_delivery_location_at?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  delivered_at?: Date | null;
  /**
   *
   * @type {number}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  length_ldm?: number;
  /**
   *
   * @type {number}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  weight_kg?: number;
  /**
   *
   * @type {number}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  volume_m3?: number;
  /**
   *
   * @type {Array<ShipmentUpdateAdditionalServicePutBody>}
   * @memberof PatchDriverLoadShipmentsByIdRequestBody
   */
  additional_services?: Array<ShipmentUpdateAdditionalServicePutBody>;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchDriverLoadShipmentsByIdRequestBodyStateEnum {
  Noudettavissa = 'noudettavissa',
  Noutokohteessa = 'noutokohteessa',
  Noudettu = 'noudettu',
  Toimituskohteessa = 'toimituskohteessa',
  Toimitettu = 'toimitettu',
  Peruttu = 'peruttu',
  Odottaa = 'odottaa',
  ToimitetaanSeuraavaksi = 'toimitetaan_seuraavaksi',
}

export function PatchDriverLoadShipmentsByIdRequestBodyFromJSON(json: any): PatchDriverLoadShipmentsByIdRequestBody {
  return PatchDriverLoadShipmentsByIdRequestBodyFromJSONTyped(json, false);
}

export function PatchDriverLoadShipmentsByIdRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchDriverLoadShipmentsByIdRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    state: !exists(json, 'state') ? undefined : json['state'],
    recipient: !exists(json, 'recipient') ? undefined : json['recipient'],
    note: !exists(json, 'note') ? undefined : json['note'],
    other_contract_number: !exists(json, 'other_contract_number') ? undefined : json['other_contract_number'],
    order_in_load: !exists(json, 'order_in_load') ? undefined : json['order_in_load'],
    hourly_work_hours: !exists(json, 'hourly_work_hours') ? undefined : json['hourly_work_hours'],
    hourly_work_reason: !exists(json, 'hourly_work_reason') ? undefined : json['hourly_work_reason'],
    wait_hours: !exists(json, 'wait_hours') ? undefined : json['wait_hours'],
    wait_reason: !exists(json, 'wait_reason') ? undefined : json['wait_reason'],
    unloaded_with_customer_permission: !exists(json, 'unloaded_with_customer_permission')
      ? undefined
      : json['unloaded_with_customer_permission'],
    arrived_to_pickup_location_at: !exists(json, 'arrived_to_pickup_location_at')
      ? undefined
      : json['arrived_to_pickup_location_at'] === null
        ? null
        : new Date(json['arrived_to_pickup_location_at']),
    picked_up_at: !exists(json, 'picked_up_at')
      ? undefined
      : json['picked_up_at'] === null
        ? null
        : new Date(json['picked_up_at']),
    arrived_to_delivery_location_at: !exists(json, 'arrived_to_delivery_location_at')
      ? undefined
      : json['arrived_to_delivery_location_at'] === null
        ? null
        : new Date(json['arrived_to_delivery_location_at']),
    delivered_at: !exists(json, 'delivered_at')
      ? undefined
      : json['delivered_at'] === null
        ? null
        : new Date(json['delivered_at']),
    length_ldm: !exists(json, 'length_ldm') ? undefined : json['length_ldm'],
    weight_kg: !exists(json, 'weight_kg') ? undefined : json['weight_kg'],
    volume_m3: !exists(json, 'volume_m3') ? undefined : json['volume_m3'],
    additional_services: !exists(json, 'additional_services')
      ? undefined
      : (json['additional_services'] as Array<any>).map(ShipmentUpdateAdditionalServicePutBodyFromJSON),
  };
}

export function PatchDriverLoadShipmentsByIdRequestBodyToJSON(
  value?: PatchDriverLoadShipmentsByIdRequestBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    state: value.state,
    recipient: value.recipient,
    note: value.note,
    other_contract_number: value.other_contract_number,
    order_in_load: value.order_in_load,
    hourly_work_hours: value.hourly_work_hours,
    hourly_work_reason: value.hourly_work_reason,
    wait_hours: value.wait_hours,
    wait_reason: value.wait_reason,
    unloaded_with_customer_permission: value.unloaded_with_customer_permission,
    arrived_to_pickup_location_at:
      value.arrived_to_pickup_location_at === undefined
        ? undefined
        : value.arrived_to_pickup_location_at === null
          ? null
          : value.arrived_to_pickup_location_at.toISOString(),
    picked_up_at:
      value.picked_up_at === undefined
        ? undefined
        : value.picked_up_at === null
          ? null
          : value.picked_up_at.toISOString(),
    arrived_to_delivery_location_at:
      value.arrived_to_delivery_location_at === undefined
        ? undefined
        : value.arrived_to_delivery_location_at === null
          ? null
          : value.arrived_to_delivery_location_at.toISOString(),
    delivered_at:
      value.delivered_at === undefined
        ? undefined
        : value.delivered_at === null
          ? null
          : value.delivered_at.toISOString(),
    length_ldm: value.length_ldm,
    weight_kg: value.weight_kg,
    volume_m3: value.volume_m3,
    additional_services:
      value.additional_services === undefined
        ? undefined
        : (value.additional_services as Array<any>).map(ShipmentUpdateAdditionalServicePutBodyToJSON),
  };
}
