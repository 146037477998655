/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A resource which describes a supplementary service that can be sold along with Shipment delivery, e.g. carrying parcels to higher floor.
 * @export
 * @interface AdditionalService
 */
export interface AdditionalService {
  /**
   *
   * @type {number}
   * @memberof AdditionalService
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof AdditionalService
   */
  organization_id: string | null;
  /**
   *
   * @type {string}
   * @memberof AdditionalService
   */
  name: string | null;
  /**
   *
   * @type {number}
   * @memberof AdditionalService
   */
  price?: number | null;
  /**
   *
   * @type {number}
   * @memberof AdditionalService
   */
  work_price?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof AdditionalService
   */
  archived: boolean;
}

export function AdditionalServiceFromJSON(json: any): AdditionalService {
  return AdditionalServiceFromJSONTyped(json, false);
}

export function AdditionalServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalService {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    organization_id: json['organization_id'],
    name: json['name'],
    price: !exists(json, 'price') ? undefined : json['price'],
    work_price: !exists(json, 'work_price') ? undefined : json['work_price'],
    archived: json['archived'],
  };
}

export function AdditionalServiceToJSON(value?: AdditionalService | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organization_id: value.organization_id,
    name: value.name,
    price: value.price,
    work_price: value.work_price,
    archived: value.archived,
  };
}
