/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { ShipmentRow, ShipmentRowFromJSON, ShipmentRowFromJSONTyped, ShipmentRowToJSON } from './ShipmentRow';

/**
 * A product row in a Shipment
 * @export
 * @interface UnhandledShipmentRow
 */
export interface UnhandledShipmentRow {
  /**
   *
   * @type {number}
   * @memberof UnhandledShipmentRow
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof UnhandledShipmentRow
   */
  readonly shipment_id: number;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  parcel_id: string | null;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  name: string | null;
  /**
   *
   * @type {number}
   * @memberof UnhandledShipmentRow
   */
  quantity: number | null;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  unit?: string | null;
  /**
   *
   * @type {number}
   * @memberof UnhandledShipmentRow
   */
  weight_per_piece_kg: number | null;
  /**
   *
   * @type {number}
   * @memberof UnhandledShipmentRow
   */
  total_weight_kg: number | null;
  /**
   *
   * @type {number}
   * @memberof UnhandledShipmentRow
   */
  volume_m3?: number | null;
  /**
   *
   * @type {Date}
   * @memberof UnhandledShipmentRow
   */
  readonly picked_up_at: Date | null;
  /**
   *
   * @type {number}
   * @memberof UnhandledShipmentRow
   */
  readonly picked_up_by: number | null;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  serial_number: string | null;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  parcel_type?: string | null;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  state?: UnhandledShipmentRowStateEnum;
  /**
   *
   * @type {Date}
   * @memberof UnhandledShipmentRow
   */
  readonly delivered_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof UnhandledShipmentRow
   */
  readonly lost_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  readonly lost_by: string | null;
  /**
   *
   * @type {Date}
   * @memberof UnhandledShipmentRow
   */
  readonly failed_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  readonly failed_by: string | null;
  /**
   *
   * @type {number}
   * @memberof UnhandledShipmentRow
   */
  longest_side_length_mm: number | null;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  joint_package_id: string | null;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  readonly organizationName: string;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  readonly shipmentDeliveryName: string;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  readonly shipmentDeliveryAddress: string;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  readonly shipmentNote: string;
  /**
   *
   * @type {string}
   * @memberof UnhandledShipmentRow
   */
  readonly shipmentReferenceNumber: string;
  /**
   *
   * @type {Array<ShipmentRow>}
   * @memberof UnhandledShipmentRow
   */
  relatedShipmentRows: Array<ShipmentRow>;
}

/**
 * @export
 * @enum {string}
 */
export enum UnhandledShipmentRowStateEnum {
  ReadyForPickup = 'ready_for_pickup',
  PickedUp = 'picked_up',
  Delivered = 'delivered',
  Lost = 'lost',
  Failed = 'failed',
}

export function UnhandledShipmentRowFromJSON(json: any): UnhandledShipmentRow {
  return UnhandledShipmentRowFromJSONTyped(json, false);
}

export function UnhandledShipmentRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnhandledShipmentRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    shipment_id: json['shipment_id'],
    parcel_id: json['parcel_id'],
    name: json['name'],
    quantity: json['quantity'],
    unit: !exists(json, 'unit') ? undefined : json['unit'],
    weight_per_piece_kg: json['weight_per_piece_kg'],
    total_weight_kg: json['total_weight_kg'],
    volume_m3: !exists(json, 'volume_m3') ? undefined : json['volume_m3'],
    picked_up_at: json['picked_up_at'] === null ? null : new Date(json['picked_up_at']),
    picked_up_by: json['picked_up_by'],
    serial_number: json['serial_number'],
    parcel_type: !exists(json, 'parcel_type') ? undefined : json['parcel_type'],
    state: !exists(json, 'state') ? undefined : json['state'],
    delivered_at: json['delivered_at'] === null ? null : new Date(json['delivered_at']),
    lost_at: json['lost_at'] === null ? null : new Date(json['lost_at']),
    lost_by: json['lost_by'],
    failed_at: json['failed_at'] === null ? null : new Date(json['failed_at']),
    failed_by: json['failed_by'],
    longest_side_length_mm: json['longest_side_length_mm'],
    joint_package_id: json['joint_package_id'],
    organizationName: json['organizationName'],
    shipmentDeliveryName: json['shipmentDeliveryName'],
    shipmentDeliveryAddress: json['shipmentDeliveryAddress'],
    shipmentNote: json['shipmentNote'],
    shipmentReferenceNumber: json['shipmentReferenceNumber'],
    relatedShipmentRows: (json['relatedShipmentRows'] as Array<any>).map(ShipmentRowFromJSON),
  };
}

export function UnhandledShipmentRowToJSON(value?: UnhandledShipmentRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    parcel_id: value.parcel_id,
    name: value.name,
    quantity: value.quantity,
    unit: value.unit,
    weight_per_piece_kg: value.weight_per_piece_kg,
    total_weight_kg: value.total_weight_kg,
    volume_m3: value.volume_m3,
    serial_number: value.serial_number,
    parcel_type: value.parcel_type,
    state: value.state,
    longest_side_length_mm: value.longest_side_length_mm,
    joint_package_id: value.joint_package_id,
    relatedShipmentRows: (value.relatedShipmentRows as Array<any>).map(ShipmentRowToJSON),
  };
}
