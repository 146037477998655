/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Assignment, AssignmentFromJSON, AssignmentFromJSONTyped, AssignmentToJSON } from './Assignment';

/**
 *
 * @export
 * @interface AssignmentsResponseBody
 */
export interface AssignmentsResponseBody {
  /**
   *
   * @type {Array<Assignment>}
   * @memberof AssignmentsResponseBody
   */
  data: Array<Assignment>;
}

export function AssignmentsResponseBodyFromJSON(json: any): AssignmentsResponseBody {
  return AssignmentsResponseBodyFromJSONTyped(json, false);
}

export function AssignmentsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignmentsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(AssignmentFromJSON),
  };
}

export function AssignmentsResponseBodyToJSON(value?: AssignmentsResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(AssignmentToJSON),
  };
}
