/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CarrierEmployee,
  CarrierEmployeeFromJSON,
  CarrierEmployeeFromJSONTyped,
  CarrierEmployeeToJSON,
} from './CarrierEmployee';

/**
 *
 * @export
 * @interface InlineResponse2013
 */
export interface InlineResponse2013 {
  /**
   *
   * @type {CarrierEmployee}
   * @memberof InlineResponse2013
   */
  data: CarrierEmployee;
}

export function InlineResponse2013FromJSON(json: any): InlineResponse2013 {
  return InlineResponse2013FromJSONTyped(json, false);
}

export function InlineResponse2013FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2013 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: CarrierEmployeeFromJSON(json['data']),
  };
}

export function InlineResponse2013ToJSON(value?: InlineResponse2013 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: CarrierEmployeeToJSON(value.data),
  };
}
