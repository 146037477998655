import React, { FunctionComponent, useReducer, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Button, Link } from '@mui/material';
import { AutoCompleteFilter } from '../../components/TableFilters';
import { Accessor, CellValue, Column, Row } from 'react-table';
import { initialState, load, OrganizationsViewSettings, reducer } from './organizations.state';
import Main from '../../components/Main';
import { ReactTable } from '../../components/ReactTable';
import { getViewSettings } from '../settings';

const VIEW_ID = 'organizations' as const;

const Organizations: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const viewSettings = React.useMemo(() => getViewSettings<OrganizationsViewSettings>(VIEW_ID), []);

  useEffect(() => {
    load(dispatch);
  }, []);

  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        id: 'id',
        Header: 'Id',
        accessor: 'id',
        Cell: ({ row }: { row: Row }) => (
          <Link component={RouterLink} to={{ pathname: `/organizations/${row.values.id}` }}>
            {row.values.id}
          </Link>
        ),
      },
      {
        Header: 'Asiakas',
        accessor: 'name',
        Filter: AutoCompleteFilter,
      },
      {
        id: 'is_active',
        Header: 'Aktiivinen',
        accessor: ((isActive: CellValue) =>
          isActive.is_active === true ? 'Kyllä' : isActive.is_active === false ? 'Ei' : '') as Accessor<any>,
        Filter: AutoCompleteFilter,
      },
    ],
    [],
  );
  const data = React.useMemo(() => state.organizations, [state.organizations]);

  return (
    <Main>
      <Button
        disabled={state.isLoading}
        id="create-new-organization-button"
        onClick={() => {
          navigate('/organizations/new');
        }}
      >
        Luo uusi asiakas
      </Button>
      <ReactTable
        columns={columns}
        data={data}
        header="Asiakkaat"
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä asiakkaita."
        initialState={{
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'name',
              asc: true,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        onStateChange={(state) => {
          dispatch({
            type: 'SET_VIEW_SETTINGS',
            payload: { filters: state.filters, sortBy: state.sortBy, hiddenColumns: state.hiddenColumns },
          });
        }}
        columnSelector={true}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default Organizations;
