import React, { FunctionComponent, useEffect, useReducer } from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Joi from 'joi';
import { passwordComplexity } from '../../../backend/src/common/passwordComplexity';
import { api } from '../api';
import Notification, {
  NotificationType,
  SetMessageAction,
  getSnackbarPropsFromState,
} from '../components/Notification';
import { LoginCard, StyledCardActions } from './LogIn';
import { TextField } from '@mui/material';

type State = {
  username: string;
  password: string;
  isPasswordInvalid: boolean;
  passwordHelperText: string;
  email: string;
  isEmailInvalid: boolean;
  emailHelperText: string;
  newPassword: string;
  isNewPasswordInvalid: boolean;
  newPasswordHelperText: string;
  newPasswordAgain: string;
  isNewPasswordAgainInvalid: boolean;
  newPasswordAgainHelperText: string;
  isButtonDisabled: boolean;
  notification: NotificationType;
};

const initialState: State = {
  username: '',
  password: '',
  isPasswordInvalid: false,
  passwordHelperText: '',
  email: '',
  isEmailInvalid: false,
  emailHelperText: '',
  newPassword: '',
  isNewPasswordInvalid: false,
  newPasswordHelperText:
    'Uuden salasanan tulee olla vähintään 8 merkkiä pitkä ja sisältää vähintään yksi pieni kirjain, yksi suuri kirjain, yksi numero ja yksi erikoismerkki.',
  newPasswordAgain: '',
  isNewPasswordAgainInvalid: false,
  newPasswordAgainHelperText: '',
  isButtonDisabled: true,
  notification: {
    message: null,
  },
};

type ErrorPayload = {
  helperText?: string;
  isError: boolean;
};

type Action =
  | { type: 'SET_USERNAME'; payload: string }
  | { type: 'SET_PASSWORD'; payload: string }
  | { type: 'SET_EMAIL'; payload: string }
  | { type: 'SET_NEW_PASSWORD'; payload: string }
  | { type: 'SET_NEW_PASSWORD_AGAIN'; payload: string }
  | { type: 'SET_IS_BUTTON_DISABLED'; payload: boolean }
  | { type: 'LOGIN_SUCCESS' }
  | SetMessageAction
  | { type: 'SET_PASSWORD_ERROR'; payload: ErrorPayload }
  | { type: 'SET_EMAIL_ERROR'; payload: ErrorPayload }
  | { type: 'SET_NEW_PASSWORD_ERROR'; payload: ErrorPayload }
  | { type: 'SET_NEW_PASSWORD_AGAIN_ERROR'; payload: ErrorPayload };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_USERNAME':
      return {
        ...state,
        username: action.payload,
      };
    case 'SET_PASSWORD':
      return {
        ...state,
        password: action.payload,
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'SET_NEW_PASSWORD':
      return {
        ...state,
        newPassword: action.payload,
      };
    case 'SET_NEW_PASSWORD_AGAIN':
      return {
        ...state,
        newPasswordAgain: action.payload,
      };
    case 'SET_IS_BUTTON_DISABLED':
      return {
        ...state,
        isButtonDisabled: action.payload,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        passwordHelperText: initialState.passwordHelperText,
        emailHelperText: initialState.emailHelperText,
        newPasswordHelperText: initialState.newPasswordHelperText,
        newPasswordAgainHelperText: initialState.newPasswordAgainHelperText,
        isPasswordInvalid: false,
        isEmailInvalid: false,
        isNewPasswordInvalid: false,
        isNewPasswordAgainInvalid: false,
        isButtonDisabled: true,
      };
    case 'SET_PASSWORD_ERROR':
      return {
        ...state,
        passwordHelperText: action.payload.helperText || '',
        isPasswordInvalid: action.payload.isError,
      };
    case 'SET_EMAIL_ERROR':
      return {
        ...state,
        emailHelperText: action.payload.helperText || '',
        isEmailInvalid: action.payload.isError,
      };
    case 'SET_NEW_PASSWORD_ERROR':
      return {
        ...state,
        newPasswordHelperText: action.payload.helperText || '',
        isNewPasswordInvalid: action.payload.isError,
      };
    case 'SET_NEW_PASSWORD_AGAIN_ERROR':
      return {
        ...state,
        newPasswordAgainHelperText: action.payload.isError
          ? 'Salasanat eivät täsmää.'
          : initialState.newPasswordAgainHelperText,
        isNewPasswordAgainInvalid: action.payload.isError,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
  }
};

const FirstLogin: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (
      state.username.trim() &&
      state.password.trim() &&
      state.email.trim() &&
      state.newPassword.trim() &&
      state.newPasswordAgain.trim() &&
      !state.isEmailInvalid &&
      !state.isNewPasswordInvalid &&
      !state.isNewPasswordAgainInvalid &&
      state.newPassword.trim() === state.newPasswordAgain.trim()
    ) {
      dispatch({
        type: 'SET_IS_BUTTON_DISABLED',
        payload: false,
      });
    } else {
      dispatch({
        type: 'SET_IS_BUTTON_DISABLED',
        payload: true,
      });
    }
  }, [state.username, state.password, state.email, state.newPassword, state.newPasswordAgain]);

  const handleActivation = async () => {
    try {
      await api.auth.logInFirstTime({
        firstLogInPostBody: {
          username: state.username,
          password: state.password,
          new_password: state.newPassword,
          email: state.email,
        },
      });
      dispatch({
        type: 'LOGIN_SUCCESS',
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: 'Käyttäjätunnus aktivoitu. Sinut ohjataan kirjautumiseen...' },
      });
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    } catch (err) {
      if ((err as any)?.status === 429) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: 'Epäonnistuit liian monta kertaa. Yritä hetken päästä uudelleen.',
            severity: 'error',
          },
        });
      }
      dispatch({
        type: 'SET_PASSWORD_ERROR',
        payload: {
          helperText: 'Virheellinen käyttäjänimi tai salasana, tai tunnus on jo aktivoitu aiemmin.',
          isError: true,
        },
      });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      state.isButtonDisabled || handleActivation();
    }
  };

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({
      type: 'SET_USERNAME',
      payload: event.target.value,
    });
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({
      type: 'SET_PASSWORD',
      payload: event.target.value,
    });
  };

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({
      type: 'SET_EMAIL',
      payload: event.target.value,
    });
    dispatch({
      type: 'SET_EMAIL_ERROR',
      payload: {
        helperText: 'Virheellinen sähköpostiosoite.',
        isError: Boolean(
          Joi.string()
            .email({ tlds: { allow: false } })
            .required()
            .validate(event.target.value).error,
        ),
      },
    });
  };

  const handleNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({
      type: 'SET_NEW_PASSWORD',
      payload: event.target.value,
    });
    dispatch({
      type: 'SET_NEW_PASSWORD_ERROR',
      payload: {
        helperText: initialState.newPasswordHelperText,
        isError: Boolean(passwordComplexity.validate(event.target.value).error),
      },
    });
    dispatch({
      type: 'SET_NEW_PASSWORD_AGAIN_ERROR',
      payload: {
        isError: state.newPasswordAgain !== event.target.value,
      },
    });
  };

  const handleNewPasswordAgainChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({
      type: 'SET_NEW_PASSWORD_AGAIN',
      payload: event.target.value,
    });
    dispatch({
      type: 'SET_NEW_PASSWORD_AGAIN_ERROR',
      payload: {
        isError: state.newPassword !== event.target.value,
      },
    });
  };

  return (
    <form noValidate autoComplete="off" style={{ padding: '1rem' }}>
      <LoginCard>
        <CardContent>
          <CardHeader title="Käyttäjätunnuksen aktivointi" />
          <Typography variant="caption">
            Täyttämällä lomakkeen aktivoit uuden Timeweb 2-käyttäjätunnuksesi. Sähköpostiosoitetta ja uutta salasanaa
            käytetään kirjautuessa Timeweb 2 -sivustoon. extranet.timecap.fi sivustoon kirjaudutaan edelleen vanhalla
            käyttäjätunnuksella ja salasanalla.
          </Typography>
          <TextField
            autoComplete="new-password"
            variant="outlined"
            error={state.isPasswordInvalid}
            fullWidth
            id="username"
            type="email"
            label="extranet.timecap.fi käyttäjänimi"
            margin="normal"
            onChange={handleUsernameChange}
            onKeyPress={handleKeyPress}
          />
          <TextField
            autoComplete="new-password"
            variant="outlined"
            error={state.isPasswordInvalid}
            fullWidth
            id="password"
            type="password"
            label="extranet.timecap.fi salasana"
            margin="normal"
            helperText={state.passwordHelperText}
            onChange={handlePasswordChange}
            onKeyPress={handleKeyPress}
          />
          <TextField
            variant="outlined"
            error={state.isEmailInvalid}
            fullWidth
            id="email"
            type="email"
            label="Sähköpostiosoite"
            margin="normal"
            helperText={state.emailHelperText}
            onChange={handleEmailChange}
            onKeyPress={handleKeyPress}
          />
          <TextField
            autoComplete="new-password"
            variant="outlined"
            error={state.isNewPasswordInvalid}
            fullWidth
            id="new-password"
            type="password"
            label="Uusi salasana"
            margin="normal"
            helperText={state.newPasswordHelperText}
            onChange={handleNewPasswordChange}
            onKeyPress={handleKeyPress}
          />
          <TextField
            autoComplete="new-password"
            variant="outlined"
            error={state.isNewPasswordAgainInvalid}
            fullWidth
            id="new-password-again"
            type="password"
            label="Uusi salasana uudelleen"
            margin="normal"
            helperText={state.newPasswordAgainHelperText}
            onChange={handleNewPasswordAgainChange}
            onKeyPress={handleKeyPress}
          />
          <StyledCardActions>
            <Button
              id="activate-button"
              size="large"
              color="primary"
              onClick={handleActivation}
              disabled={state.isButtonDisabled}
            >
              Aktivoi
            </Button>
          </StyledCardActions>
        </CardContent>
      </LoginCard>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </form>
  );
};
export default FirstLogin;
