import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Organization } from '../api';

export interface OrganizationPickerProps {
  className?: string;
  organizations: Organization[];
  value: Organization | null;
  onChange: (organization: Organization | null) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  style?: any;
  variant?: 'standard' | 'filled' | 'outlined';
  dataCy?: string;
}

export const OrganizationPicker: React.FC<OrganizationPickerProps> = ({
  className,
  disabled,
  organizations,
  value,
  onChange,
  required,
  error,
  helperText,
  size,
  style,
  variant,
  dataCy,
}) => {
  return (
    <Autocomplete
      data-cy={dataCy}
      disabled={disabled}
      style={style}
      fullWidth
      className={'organization-picker ' + className}
      options={organizations
        //TODO, remove this filter. Php needs an empty organization for some aspects of the old service to work
        .filter((organization) => organization.id !== '')
        .sort((organization1, organization2) =>
          organization1.name.toLowerCase() > organization2.name.toLowerCase() ? 1 : -1,
        )}
      autoHighlight
      getOptionLabel={(organization) => organization.name ?? String(organization.id)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, organization) => <li {...props}>{organization.name}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          variant={variant}
          error={error}
          helperText={helperText}
          label="Asiakas"
          required={required}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      value={value}
      onChange={(_event, organization) => onChange(organization)}
    />
  );
};
