/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CarrierUnhandledShipmentRow,
  CarrierUnhandledShipmentRowFromJSON,
  CarrierUnhandledShipmentRowFromJSONTyped,
  CarrierUnhandledShipmentRowToJSON,
} from './CarrierUnhandledShipmentRow';

/**
 *
 * @export
 * @interface GetCarrierUnhandledShipmentRowsResponse
 */
export interface GetCarrierUnhandledShipmentRowsResponse {
  /**
   *
   * @type {Array<CarrierUnhandledShipmentRow>}
   * @memberof GetCarrierUnhandledShipmentRowsResponse
   */
  data: Array<CarrierUnhandledShipmentRow>;
}

export function GetCarrierUnhandledShipmentRowsResponseFromJSON(json: any): GetCarrierUnhandledShipmentRowsResponse {
  return GetCarrierUnhandledShipmentRowsResponseFromJSONTyped(json, false);
}

export function GetCarrierUnhandledShipmentRowsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetCarrierUnhandledShipmentRowsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(CarrierUnhandledShipmentRowFromJSON),
  };
}

export function GetCarrierUnhandledShipmentRowsResponseToJSON(
  value?: GetCarrierUnhandledShipmentRowsResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(CarrierUnhandledShipmentRowToJSON),
  };
}
