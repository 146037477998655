/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { TimeOffLegacy, TimeOffLegacyFromJSON, TimeOffLegacyFromJSONTyped, TimeOffLegacyToJSON } from './TimeOffLegacy';

/**
 *
 * @export
 * @interface TimeOffLegacyResponseBody
 */
export interface TimeOffLegacyResponseBody {
  /**
   *
   * @type {Array<TimeOffLegacy>}
   * @memberof TimeOffLegacyResponseBody
   */
  data: Array<TimeOffLegacy>;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacyResponseBody
   */
  next: number | null;
}

export function TimeOffLegacyResponseBodyFromJSON(json: any): TimeOffLegacyResponseBody {
  return TimeOffLegacyResponseBodyFromJSONTyped(json, false);
}

export function TimeOffLegacyResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TimeOffLegacyResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(TimeOffLegacyFromJSON),
    next: json['next'],
  };
}

export function TimeOffLegacyResponseBodyToJSON(value?: TimeOffLegacyResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(TimeOffLegacyToJSON),
    next: value.next,
  };
}
