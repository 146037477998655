/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CarServicePutBody
 */
export interface CarServicePutBody {
  /**
   *
   * @type {number}
   * @memberof CarServicePutBody
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof CarServicePutBody
   */
  car_id: number;
  /**
   *
   * @type {Date}
   * @memberof CarServicePutBody
   */
  service_date: Date | null;
  /**
   *
   * @type {string}
   * @memberof CarServicePutBody
   */
  title: string | null;
  /**
   *
   * @type {string}
   * @memberof CarServicePutBody
   */
  description: string | null;
}

export function CarServicePutBodyFromJSON(json: any): CarServicePutBody {
  return CarServicePutBodyFromJSONTyped(json, false);
}

export function CarServicePutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarServicePutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    car_id: json['car_id'],
    service_date: json['service_date'] === null ? null : new Date(json['service_date']),
    title: json['title'],
    description: json['description'],
  };
}

export function CarServicePutBodyToJSON(value?: CarServicePutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    car_id: value.car_id,
    service_date: value.service_date === null ? null : value.service_date.toISOString(),
    title: value.title,
    description: value.description,
  };
}
