import React from 'react';
import theme from '../../theme';
import { DriverWithFullNameAndTimeOffs, State } from './holidays.state';
import { dateFormat } from '../../formatters';
import { Box, styled } from '@mui/material';
import { cellSize } from './Holidays';

interface SummaryProps {
  date: State['hoveredDate'];
  data: DriverWithFullNameAndTimeOffs[];
}

const summaryHeight = cellSize / 2;

const SummaryContainer = styled(Box)({
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: summaryHeight,
});

const DayContainer = styled(Box)({
  textAlign: 'center',
  width: '100%',
});

export const Summary: React.FC<SummaryProps> = ({ date, data }) => {
  const currentDayTimeOffs = data
    .map((d: any) => d.timeOffs)
    .flat()
    .filter((t: any) => t.month === date?.month && t.year === date?.year && t[`d${date?.day}`]);

  const holidays = currentDayTimeOffs.filter((t: any) => t[`d${date?.day}`] === 1 && t.month === date?.month).length;
  const freeDays = currentDayTimeOffs.filter((t: any) => t[`d${date?.day}`] === 2 && t.month === date?.month).length;
  const sicknessDays = currentDayTimeOffs.filter(
    (t: any) => t[`d${date?.day}`] === 3 && t.month === date?.month,
  ).length;
  return (
    <Box data-cy="general-summary" sx={{ width: '100%' }}>
      <SummaryContainer sx={{ height: summaryHeight }}>
        <DayContainer>{date?.toFormat(dateFormat) ?? '-'}</DayContainer>
      </SummaryContainer>
      <SummaryContainer sx={{ height: summaryHeight }}>
        <SummaryContainer>
          <DayContainer sx={{ background: theme.palette.holiday }}>Lomalla {holidays}</DayContainer>
          <DayContainer sx={{ background: theme.palette.dayOff }}>Pekkasella {freeDays}</DayContainer>
          <DayContainer sx={{ background: theme.palette.sickLeave }}>Sairaana {sicknessDays}</DayContainer>
        </SummaryContainer>
      </SummaryContainer>
    </Box>
  );
};
