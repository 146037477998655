import { Alert, Typography } from '@mui/material';
import React from 'react';
import { formatDateTime } from '../../../formatters';

interface UpdateInfoProps {
  updated_by: string | null;
  updated_at: Date | null;
}

export const UpdateInfo: React.FC<UpdateInfoProps> = ({ updated_by, updated_at }) => (
  <Alert icon={false} severity="info" style={{ margin: 0 }}>
    <Typography className="updated-by-field">
      Muokkaaja <strong>{updated_by ? updated_by : '-'}</strong>
    </Typography>
    <Typography className="updated-at-field">{updated_at ? formatDateTime(updated_at) : '-'}</Typography>
  </Alert>
);
