import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Button, Link } from '@mui/material';
import { Accessor, CellValue, Column, Row } from 'react-table';
import { EmployeesViewSettings, initialState, load, reducer } from './employees.state';
import Main from '../../components/Main';
import { ReactTable } from '../../components/ReactTable';
import { getViewSettings } from '../settings';
import { AutoCompleteFilter, DateColumnFilter, SelectColumnFilter } from '../../components/TableFilters';
import { dateFormatUnderscore, formatDate, formatProfession } from '../../formatters';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { DateTime } from 'luxon';

const VIEW_ID = 'employees' as const;

const Employees: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const viewSettings = React.useMemo(() => getViewSettings<EmployeesViewSettings>(VIEW_ID), []);

  useEffect(() => {
    if (currentUser) {
      load(dispatch);
    }
  }, [currentUser]);

  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        id: 'id',
        Header: 'Id',
        accessor: 'id',
        Cell: ({ row }: { row: Row }) => (
          <Link component={RouterLink} to={{ pathname: `/employees/${row.values.id}` }}>
            {row.values.id}
          </Link>
        ),
      },
      {
        Header: 'Työnumero',
        accessor: 'employee_number',
      },
      {
        Header: 'Etunimi',
        accessor: 'first_name',
      },
      {
        Header: 'Sukunimi',
        accessor: 'last_name',
      },
      {
        id: 'profession',
        Header: 'Ammatti',
        accessor: ((cellValue: CellValue) =>
          cellValue.profession ? formatProfession(cellValue.profession) : '') as Accessor<any>,
        Filter: SelectColumnFilter,
      },
      {
        id: 'is_at_service',
        Header: 'Aktiivinen',
        accessor: ((cellValue: CellValue) =>
          cellValue.is_at_service === true ? 'Kyllä' : cellValue.is_at_service === false ? 'Ei' : '') as Accessor<any>,
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Voimassa olevat koulutukset',
        columns: [
          {
            Header: 'Ammattipätevyys',
            accessor: 'professional_qualification',
            Cell: (professionalQualification: CellValue) =>
              professionalQualification.value ? formatDate(professionalQualification.value) : '',
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
          {
            Header: 'Ennakoiva ajo',
            accessor: 'proactive_driving_expire_date',
            Cell: (proactiveDrivingExpireDate: CellValue) =>
              proactiveDrivingExpireDate.value ? formatDate(proactiveDrivingExpireDate.value) : '',
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },

          {
            Header: 'ADR',
            accessor: 'adr_expire_date',
            Cell: (adrExpireDate: CellValue) => (adrExpireDate.value ? formatDate(adrExpireDate.value) : ''),
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
          {
            Header: 'Työturva',
            accessor: 'work_safety_expire_date',
            Cell: (workSafetyExpireDate: CellValue) =>
              workSafetyExpireDate.value ? formatDate(workSafetyExpireDate.value) : '',
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
        ],
      },
    ],
    [],
  );

  const data = React.useMemo(() => state.employees, [state.employees]);

  return (
    <Main>
      <Button
        disabled={state.isLoading}
        id="create-new-employee-button"
        onClick={() => {
          navigate('/employees/new');
        }}
      >
        Luo uusi työntekijä
      </Button>
      <ReactTable
        columns={columns}
        data={data}
        header="Työntekijät"
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä työntekijöitä."
        initialState={{
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'id',
              asc: true,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        onStateChange={(state) => {
          dispatch({
            type: 'SET_VIEW_SETTINGS',
            payload: { filters: state.filters, sortBy: state.sortBy, hiddenColumns: state.hiddenColumns },
          });
        }}
        columnSelector={true}
        exportName={`tyontekijat_${DateTime.now().toFormat(dateFormatUnderscore)}`}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default Employees;
