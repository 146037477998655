import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Select,
  InputAdornment,
  MenuItem,
  styled,
  Button,
  List,
  TextField,
  FormControl,
} from '@mui/material';
import React, { Dispatch } from 'react';
import {
  State,
  Action,
  updateFieldValue,
  FieldName,
  debouncedValidateFieldsDispatch,
  GeographicalAreaField,
} from '../pricingModel.state';
import {
  CustomPricingCategory,
  GeographicalAreaAreaTypeEnum,
  PricingModelWithAreasDistancePricingBasisEnum,
  PricingModelWithAreasPricingUnitEnum,
} from '../../../api';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { pricingUnitMenuItems, distancePricingBasisMenuItems, areaTypeMenuItems } from './MenuItems';
import { groupBy } from 'lodash';
import { Add, Delete } from '../../../components/icons';
import { Add as AddIcon } from '@mui/icons-material';
import { FieldSetContainer } from '../../../components/StyledComponents/FieldSetContainer';

interface InputProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  disabled?: boolean;
  showId?: boolean;
}

interface DispatchableInputProps extends InputProps {
  dispatch: Dispatch<Action>;
}

export const AllPricingModelFields: React.FC<DispatchableInputProps> = ({ dispatch, state, showId }) => {
  return (
    <FieldSetContainer>
      <FieldSet id="basic-fields">
        <legend>Perustiedot</legend>
        {showId ? <Id state={state} dispatch={dispatch} disabled={true} /> : null}
        <Name state={state} dispatch={dispatch} />
        <PricingUnit state={state} dispatch={dispatch} disabled={showId}>
          {pricingUnitMenuItems}
        </PricingUnit>
        <DistancePricingBasis state={state} dispatch={dispatch} disabled={showId}>
          {distancePricingBasisMenuItems}
        </DistancePricingBasis>
        {state.fields.distance_pricing_basis.value === PricingModelWithAreasDistancePricingBasisEnum.FixedArea ? (
          <DefaultPricingArea state={state} dispatch={dispatch} />
        ) : null}
        {state.fields.pricing_unit.value === PricingModelWithAreasPricingUnitEnum.CustomCategory ? (
          <DefaultCustomPricingCategory state={state} dispatch={dispatch} />
        ) : null}
        <HourlyPrice state={state} dispatch={dispatch} />
        <HourlyPriceOfCombinedVehicle state={state} dispatch={dispatch} />
        <ExpressDeliveryPrice state={state} dispatch={dispatch} />
      </FieldSet>
      <FieldSet id="exception-fields">
        <legend>Poikkeukset</legend>
        <IsLegacyCombinedPricingForSameDayDeliveriesInSameAddressCheckbox state={state} dispatch={dispatch} />
        <IsLegacyChargeableWeightFlooringCheckbox state={state} dispatch={dispatch} />
        <IsLegacyIgnoreBasicPriceIfHoursExistAndNotExceptionCheckbox state={state} dispatch={dispatch} />
        <IsLegacyIgnoreWorkingHoursCheckbox state={state} dispatch={dispatch} />
      </FieldSet>
    </FieldSetContainer>
  );
};

const GeographicalAreaListItem = styled('li')({
  marginTop: '0.5rem',
});

export const PricingAndGeographicalAreaSection: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  const groupedGeographicalAreas = groupBy(state.geographicalAreaFields, 'geographicalArea.pricing_area');
  return (
    <FieldSet>
      <legend>Hinnoittelualueet</legend>
      <div style={{ display: 'flex' }}>
        {state.pricingAreaFields.map((pricingAreaField) => {
          const geographicalAreaFields = groupedGeographicalAreas[pricingAreaField.pricingArea.id] ?? [];
          return (
            <FieldSet className="pricing-area-container" key={pricingAreaField.pricingArea.id}>
              {pricingAreaField.isNew ? (
                <Button
                  variant="text"
                  className="delete-pricing-area-button"
                  onClick={() => {
                    dispatch({
                      type: 'DELETE_PRICING_AREA_FIELD',
                      payload: {
                        pricingAreaField: pricingAreaField,
                      },
                    });
                    debouncedValidateFieldsDispatch(dispatch);
                  }}
                >
                  <Delete />
                </Button>
              ) : null}
              <TextField
                style={{ marginBottom: '1rem' }}
                label="Nimi"
                name="pricing-area-name"
                className="pricing-area-name"
                disabled={disabled}
                error={pricingAreaField.hasError}
                helperText={pricingAreaField.feedback}
                value={pricingAreaField.value ?? ''}
                onChange={(event) => {
                  dispatch({
                    type: 'UPDATE_PRICING_AREA_FIELD',
                    payload: {
                      pricingAreaField: pricingAreaField,
                      value: event.target.value,
                      areaType: pricingAreaField.areaType,
                    },
                  });
                  debouncedValidateFieldsDispatch(dispatch);
                }}
              />
              <FormControl className="type" style={{ marginBottom: '0.5rem' }}>
                <InputLabel shrink id="pricing_area_type">
                  Tyyppi
                </InputLabel>
                <Select
                  className="pricing-area-type"
                  name="pricing-area-type"
                  labelId="pricing_area_type"
                  disabled={disabled}
                  value={pricingAreaField.areaType}
                  onChange={(event) => {
                    dispatch({
                      type: 'UPDATE_PRICING_AREA_FIELD',
                      payload: {
                        pricingAreaField: pricingAreaField,
                        areaType: event.target.value as GeographicalAreaAreaTypeEnum,
                        value: pricingAreaField.value,
                      },
                    });
                    debouncedValidateFieldsDispatch(dispatch);
                  }}
                >
                  {areaTypeMenuItems}
                </Select>
              </FormControl>
              <br />
              <List key={pricingAreaField.pricingArea.id} className="geographical-area-list">
                <FormHelperText>Alueet</FormHelperText>
                {geographicalAreaFields.map((geographicalAreaField: GeographicalAreaField, index: number) => {
                  return (
                    <GeographicalAreaListItem key={index} className="geographical-area-list-item">
                      <FormControl error={geographicalAreaField.hasError}>
                        <Input
                          className="geographical-area-name"
                          name="geographical-area-name"
                          disabled={disabled}
                          error={geographicalAreaField.hasError}
                          value={geographicalAreaField.value ?? ''}
                          onChange={(event) => {
                            dispatch({
                              type: 'UPDATE_GEOGRAPHICAL_AREA_FIELD',
                              payload: {
                                geographicalAreaField: geographicalAreaField,
                                value: event.target.value,
                              },
                            });
                            debouncedValidateFieldsDispatch(dispatch);
                          }}
                        />
                        {geographicalAreaField.feedback ? (
                          <FormHelperText className="geographical-area-helper-text">
                            {geographicalAreaField.feedback}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                      <Button
                        variant="text"
                        className="delete-geographical-area-button"
                        onClick={() => {
                          dispatch({
                            type: 'DELETE_GEOGRAPHICAL_AREA_FIELD',
                            payload: {
                              geographicalAreaField: geographicalAreaField,
                            },
                          });
                          debouncedValidateFieldsDispatch(dispatch);
                        }}
                      >
                        <Delete />
                      </Button>
                    </GeographicalAreaListItem>
                  );
                })}
              </List>
              <Button
                variant="text"
                className="add-geographical-area-button"
                onClick={() => {
                  dispatch({
                    type: 'ADD_GEOGRAPHICAL_AREA_FIELD',
                    payload: {
                      pricingAreaField: pricingAreaField,
                    },
                  });
                  debouncedValidateFieldsDispatch(dispatch);
                }}
              >
                <Add />
              </Button>
            </FieldSet>
          );
        })}
      </div>
      <Button
        className="add-pricing-area-button"
        style={{ marginBottom: '1rem' }}
        startIcon={<AddIcon />}
        onClick={() => {
          dispatch({
            type: 'ADD_PRICING_AREA_FIELD',
            payload: {
              pricingAreaField: state.pricingAreaFields[0],
            },
          });
          debouncedValidateFieldsDispatch(dispatch);
        }}
      >
        Lisää hinnoittelualue
      </Button>
    </FieldSet>
  );
};

const Id: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <FormControl error={state.fields.id.hasError}>
      <InputLabel htmlFor="id">Id</InputLabel>
      <Input
        disabled={disabled}
        id="id"
        name="id"
        type="number"
        value={state.fields.id.value ?? ''}
        onChange={(event) =>
          updateFieldValue('id', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
      />
      {state.fields.id.feedback ? (
        <FormHelperText className="id-helper-text">{state.fields.id.feedback}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export const HourlyPrice: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControl error={state.fields.hourly_price.hasError}>
      <InputLabel htmlFor="hourly_price">Tuntihinta</InputLabel>
      <Input
        id="hourly_price"
        name="hourly_price"
        type="number"
        startAdornment={<InputAdornment position="start">€</InputAdornment>}
        value={state.fields.hourly_price.value ?? ''}
        onChange={(event) =>
          updateFieldValue('hourly_price', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
      />
      {state.fields.hourly_price.feedback ? (
        <FormHelperText className="hourly_price-helper-text">{state.fields.hourly_price.feedback}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export const HourlyPriceOfCombinedVehicle: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControl error={state.fields.hourly_price_of_combined_vehicle.hasError}>
      <InputLabel htmlFor="hourly_price_of_combined_vehicle">Yhdistelmätuntihintalisä</InputLabel>
      <Input
        id="hourly_price_of_combined_vehicle"
        name="hourly_price_of_combined_vehicle"
        type="number"
        startAdornment={<InputAdornment position="start">€</InputAdornment>}
        value={state.fields.hourly_price_of_combined_vehicle.value ?? ''}
        onChange={(event) =>
          updateFieldValue(
            'hourly_price_of_combined_vehicle',
            event.target.value !== '' ? parseFloat(event.target.value) : '',
            dispatch,
          )
        }
      />
      {state.fields.hourly_price_of_combined_vehicle.feedback ? (
        <FormHelperText className="hourly_price_of_combined_vehicle-helper-text">
          {state.fields.hourly_price_of_combined_vehicle.feedback}
        </FormHelperText>
      ) : null}
      <FormHelperText className="hourly_price_of_combined_vehicle-helper-text" style={{ marginBottom: '1rem' }}>
        Yhdistelmätuntihintalisä lisätään normaalin tuntilisän päälle, yhdistelmäajoneuvon ollessa käytössä.
      </FormHelperText>
    </FormControl>
  );
};

export const ExpressDeliveryPrice: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControl error={state.fields.express_delivery_price.hasError}>
      <InputLabel htmlFor="express_delivery_price">Pikatoimituslisä</InputLabel>
      <Input
        id="express_delivery_price"
        name="express_delivery_price"
        type="number"
        startAdornment={<InputAdornment position="start">€</InputAdornment>}
        value={state.fields.express_delivery_price.value}
        onChange={(event) =>
          updateFieldValue(
            'express_delivery_price',
            event.target.value !== '' ? parseFloat(event.target.value) : '',
            dispatch,
          )
        }
      />
      {state.fields.express_delivery_price.feedback ? (
        <FormHelperText className="express_delivery_price-helper-text">
          {state.fields.express_delivery_price.feedback}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

const getPricingAreas = (state: State) => {
  if (state.originalPricingModel) {
    return state.originalPricingModel.pricing_areas.map((pricingArea, index) => (
      <MenuItem key={index + 1} value={pricingArea.id}>
        {pricingArea.name}
      </MenuItem>
    ));
  }
};

export const DefaultPricingArea: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <FormControl>
      <InputLabel shrink id="default_pricing_area">
        Hinnoittelun oletusalue
      </InputLabel>
      <Select
        className="default_pricing_area"
        id="default_pricing_area"
        name="default_pricing_area"
        labelId="default_pricing_area"
        label="Hinnoittelun oletusalue"
        disabled={disabled}
        value={state.fields.default_pricing_area.value ?? ''}
        onChange={(event) =>
          updateFieldValue(event.target.name as FieldName, event.target.value as GeographicalAreaAreaTypeEnum, dispatch)
        }
      >
        {getPricingAreas(state)}
      </Select>
    </FormControl>
  );
};

const getCustomPricingCategories = (state: State) => {
  if (state.originalPricingModel) {
    return state.originalPricingModel.custom_pricing_categories.map((customPricingCategory, index) => (
      <MenuItem key={index + 1} value={customPricingCategory.id}>
        {customPricingCategory.name}
      </MenuItem>
    ));
  }
};

export const DefaultCustomPricingCategory: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <FormControl>
      <InputLabel shrink id="default_custom_pricing_category">
        Hinnoittelun oletuskategoria
      </InputLabel>
      <Select
        className="default_custom_pricing_category"
        id="default_custom_pricing_category"
        name="default_custom_pricing_category"
        labelId="default_custom_pricing_category"
        disabled={disabled}
        value={state.fields.default_custom_pricing_category.value ?? ''}
        onChange={(event) =>
          updateFieldValue(event.target.name as FieldName, event.target.value as CustomPricingCategory['id'], dispatch)
        }
      >
        <MenuItem key="0" value="">
          Valitse hinnoittelun oletuskategoria
        </MenuItem>
        {getCustomPricingCategories(state)}
      </Select>
    </FormControl>
  );
};

const Name: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.name.required}
      name="name"
      error={state.fields.name.hasError}
      helperText={state.fields.name.feedback}
      className="name"
      label="Nimi"
      value={state.fields.name.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

interface StateSelectProps extends DispatchableInputProps {
  children: React.ReactElement[];
}

const PricingUnit: React.FC<StateSelectProps> = ({ state, dispatch, disabled, children }) => {
  return (
    <FormControl>
      <InputLabel shrink id="pricing_unit">
        Pääasiallinen hinnoitteluperuste
      </InputLabel>
      <Select
        className="pricing_unit"
        id="pricing_unit"
        name="pricing_unit"
        labelId="pricing_unit"
        disabled={disabled}
        value={state.fields.pricing_unit.value}
        onChange={(event) =>
          updateFieldValue('pricing_unit', event.target.value as PricingModelWithAreasPricingUnitEnum, dispatch)
        }
      >
        {children}
      </Select>
    </FormControl>
  );
};

const DistancePricingBasis: React.FC<StateSelectProps> = ({ state, dispatch, disabled, children }) => {
  return (
    <FormControl>
      <InputLabel shrink id="distance_pricing_basis">
        Hinnoitteluetäisyys
      </InputLabel>
      <Select
        className="distance_pricing_basis"
        id="distance_pricing_basis"
        name="distance_pricing_basis"
        labelId="distance_pricing_basis"
        disabled={disabled}
        value={state.fields.distance_pricing_basis.value}
        onChange={(event) =>
          updateFieldValue(
            'distance_pricing_basis',
            event.target.value as PricingModelWithAreasDistancePricingBasisEnum,
            dispatch,
          )
        }
      >
        {children}
      </Select>
    </FormControl>
  );
};

const IsLegacyCombinedPricingForSameDayDeliveriesInSameAddressCheckbox: React.FC<DispatchableInputProps> = ({
  dispatch,
  state,
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            className="legacy_combined_pricing_for_same_day_deliveries_in_same_address_checkbox"
            name="legacy_combined_pricing_for_same_day_deliveries_in_same_address"
            checked={state.fields.legacy_combined_pricing_for_same_day_deliveries_in_same_address.value || false}
            onChange={(event) =>
              updateFieldValue(
                event.target.name as FieldName,
                !state.fields.legacy_combined_pricing_for_same_day_deliveries_in_same_address.value,
                dispatch,
              )
            }
          />
        }
        label="Yhteistoimitushinnoittelu"
      />
      <FormHelperText
        className="legacy_combined_pricing_for_same_day_deliveries_in_same_address-helper-text"
        style={{ marginBottom: '1rem' }}
      >
        Yhteinen hinnoittelu toimituksille jotka toimitetaan samana päivänä samassa kuormassa samalle asiakkaalle. Nolla
        hintaisia toimituksia ei oteta huomioon yhteistoimitushinnoittelussa.
      </FormHelperText>
    </>
  );
};

const IsLegacyChargeableWeightFlooringCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            className="legacy_chargeable_weight_flooring_checkbox"
            name="legacy_chargeable_weight_flooring"
            checked={state.fields.legacy_chargeable_weight_flooring.value || false}
            onChange={(event) =>
              updateFieldValue(
                event.target.name as FieldName,
                !state.fields.legacy_chargeable_weight_flooring.value,
                dispatch,
              )
            }
          />
        }
        label="Rahdituspaino pyöristetään alaspäin"
      />
    </>
  );
};

const IsLegacyIgnoreBasicPriceIfHoursExistAndNotExceptionCheckbox: React.FC<DispatchableInputProps> = ({
  dispatch,
  state,
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            className="legacy_ignore_basic_price_if_hours_exist_and_not_exception_checkbox"
            name="legacy_ignore_basic_price_if_hours_exist_and_not_exception"
            checked={state.fields.legacy_ignore_basic_price_if_hours_exist_and_not_exception.value || false}
            disabled={Boolean(state.fields.legacy_ignore_working_hours.value)}
            onChange={(event) =>
              updateFieldValue(
                event.target.name as FieldName,
                !state.fields.legacy_ignore_basic_price_if_hours_exist_and_not_exception.value,
                dispatch,
              )
            }
          />
        }
        label="Perushinta jätetään huomioimatta jos toimitukselle on asetettu työtunteja ja jos toimituksella ei ole asetettu tuntilisävalintaa"
      />
      <FormHelperText
        className="legacy_ignore_basic_price_if_hours_exist_and_not_exception-helper-text"
        style={{ marginBottom: '1rem' }}
      >
        Työtunnit jätetään huomioimatta -valintaruutu ei saa olla valittuna
      </FormHelperText>
    </>
  );
};

const IsLegacyIgnoreWorkingHoursCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            className="legacy_ignore_working_hours_checkbox"
            name="legacy_ignore_working_hours"
            checked={state.fields.legacy_ignore_working_hours.value || false}
            disabled={Boolean(state.fields.legacy_ignore_basic_price_if_hours_exist_and_not_exception.value)}
            onChange={(event) =>
              updateFieldValue(
                event.target.name as FieldName,
                !state.fields.legacy_ignore_working_hours.value,
                dispatch,
              )
            }
          />
        }
        label="Työtunnit jätetään huomioimatta"
      />
      <FormHelperText className="legacy_ignore_working_hours-helper-text" style={{ marginBottom: '1rem' }}>
        Perushinta jätetään huomioimatta -valintaruutu ei saa olla valittuna
      </FormHelperText>
    </>
  );
};
