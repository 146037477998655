import React, { Dispatch, SetStateAction, useState } from 'react';
import { Action, State } from '../types/shipment.types';
import { AddressLink } from '../../../components/map';
import { api, Organization, Photo, ShipmentStateEnum, User } from '../../../api';
import {
  canAccessCoordination,
  canAccessCustomerCoordination,
  canAccessCustomerPricing,
  getShipmentSimpleStateChip,
  isHostOrganizationUser,
} from '../../../utils';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { FieldSetContainer } from '../../../components/StyledComponents/FieldSetContainer';
import { formatDateTime } from '../../../formatters';
import { FullWidthButton } from '../../../components/StyledComponents/FullWidthButton';
import { Chip, FormHelperText, Link, MenuItem, styled, Typography } from '@mui/material';
import { PhotoCameraOutlined } from '@mui/icons-material';
import { ShipmentAddFileDialog } from './ShipmentAddFileDialog';
import { ShipmentTimeline } from './ShipmentTimeline';
import { UpdateHistory } from './UpdateHistory';
import {
  AdditionalRequirementFields,
  AdditionalServices,
  BillingOffice,
  BillingReferenceNumber,
  ChargeableWeightInput,
  CoordinationFieldSet,
  CustomerDistributionArea,
  CustomPricingCategory,
  DeliveryAddress,
  DeliveryCity,
  DeliveryDatePicker,
  DeliveryName,
  DeliveryOffice,
  DeliveryPhoneNumber,
  DeliveryPhoneNumberSecondary,
  DeliveryPostalCode,
  DeliveryTimePicker,
  JobNumber,
  LengthLdmInput,
  Notes,
  Orderer,
  OrdererPhoneNumber,
  OrganizationField,
  OtherContractNumber,
  PickupAddress,
  PickupCity,
  PickupName,
  PickupOffice,
  PickupPhoneNumber,
  PickupPhoneNumberSecondary,
  PickupPostalCode,
  PickupTimePicker,
  ReferenceNumber,
  RelatedShipmentsFieldSet,
  StateSelect,
  VolumeM3Input,
  WeightKgInput,
} from './inputs';
import theme from '../../../theme';
import { PricingFieldSet } from './PricingFieldSet';

interface InputProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  disabled?: boolean;
}

interface DispatchableInputProps extends InputProps {
  dispatch: Dispatch<Action>;
}

interface AllInputFieldProps extends DispatchableInputProps {
  dispatch: Dispatch<Action>;
  currentUser: User | undefined;
  organization: Organization | undefined;
  parsedShipmentId?: number;
  showAllRelatedShipments?: boolean;
  setShowAllRelatedShipments?: Dispatch<SetStateAction<boolean>>;
}

export const AllShipmentFields: React.FC<AllInputFieldProps> = ({
  dispatch,
  state,
  currentUser,
  organization,
  parsedShipmentId,
  showAllRelatedShipments,
  setShowAllRelatedShipments,
}) => {
  const hasCoordinationAccess = canAccessCoordination(currentUser);
  const hasCustomerCoordinationAccess = canAccessCustomerCoordination(currentUser);
  const [hideRelatedShipments, setHideRelatedShipments] = useState(true);
  const customPricingCategories = state.pricingModels.find(
    (pricingModel) => pricingModel.id === state.fields.pricing_model.value,
  )?.custom_pricing_categories;

  const getMenuItem = (state: ShipmentStateEnum, disabled: boolean) => (
    <MenuItem key={state} value={state} disabled={disabled}>
      {getShipmentSimpleStateChip(state)}
    </MenuItem>
  );

  const createShipmentStateMenuItems = [
    getMenuItem(ShipmentStateEnum.EiVarastossa, false),
    getMenuItem(ShipmentStateEnum.Noudettavissa, false),
  ];

  const editShipmentStateMenuItems = [
    getMenuItem(ShipmentStateEnum.Noudettu, !hasCoordinationAccess),
    getMenuItem(ShipmentStateEnum.Toimitettu, !hasCoordinationAccess),
    getMenuItem(ShipmentStateEnum.Peruttu, false),
    getMenuItem(ShipmentStateEnum.Odottaa, !hasCoordinationAccess),
  ];

  const stateMenuItems = createShipmentStateMenuItems.concat(parsedShipmentId ? editShipmentStateMenuItems : []);

  const LocationContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  });

  const EllipsisChip = styled(Chip)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  });

  const PhotoLink = styled(Link)({
    maxWidth: '50%',
  });

  const PhotoTimestamp = styled(Typography)({});

  const PhotoContainer = styled('div')({
    maxWidth: '80vw',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '0.2rem',
    justifyContent: 'space-between',
  });

  const PhotoChip: React.FC<{ photo: Photo; currentUser?: User }> = ({ photo, currentUser }) => {
    const getPhotoUrl = canAccessCoordination(currentUser)
      ? `/api/v1/shipments/${photo.shipment_id}/photos/${photo.id}`
      : `/api/v1/organizations/${currentUser?.organization_id}/shipments/${photo.shipment_id}/photos/${photo.id}`;
    return (
      <PhotoContainer className="photo_container">
        <PhotoLink href={getPhotoUrl} title={photo.description}>
          <EllipsisChip icon={<PhotoCameraOutlined />} label={photo.description} clickable />
        </PhotoLink>
        <PhotoTimestamp variant="body2">{formatDateTime(photo.timestamp)}</PhotoTimestamp>
      </PhotoContainer>
    );
  };
  return (
    <FieldSetContainer>
      <FieldSet id="orderer-fields-container">
        <legend>Tilaaja</legend>
        <OrganizationField state={state} dispatch={dispatch} currentUser={currentUser} />
        <Orderer field={state.fields.orderer} dispatch={dispatch} />
        <BillingOffice state={state} dispatch={dispatch} />
        <OrdererPhoneNumber field={state.fields.orderer_phone_number} dispatch={dispatch} />
        <ReferenceNumber field={state.fields.reference_number} dispatch={dispatch} />
        <JobNumber field={state.fields.job_number} dispatch={dispatch} />
        <BillingReferenceNumber field={state.fields.billing_reference_number} dispatch={dispatch} />
        <CustomerDistributionArea field={state.fields.customer_distribution_area} dispatch={dispatch} />
        <OtherContractNumber field={state.fields.other_contract_number} dispatch={dispatch} />
        {customPricingCategories && customPricingCategories.length > 0 ? (
          <CustomPricingCategory state={state} dispatch={dispatch} />
        ) : null}
      </FieldSet>
      <FieldSet id="pickup-fields-container">
        <legend>Nouto</legend>
        <LocationContainer className="pickup_address_link">
          <AddressLink
            title="Näytä sijainti"
            showIcon={true}
            address={state.fields.pickup_address.value}
            postalCode={state.fields.pickup_postal_code.value}
            city={state.fields.pickup_city.value}
          />
        </LocationContainer>
        <PickupOffice state={state} dispatch={dispatch} />
        <PickupName field={state.fields.pickup_name} dispatch={dispatch} />
        <PickupAddress field={state.fields.pickup_address} dispatch={dispatch} />
        <PickupPostalCode field={state.fields.pickup_postal_code} dispatch={dispatch} />
        <PickupCity field={state.fields.pickup_city} dispatch={dispatch} />
        <PickupPhoneNumber field={state.fields.pickup_phone_number} dispatch={dispatch} />
        <PickupPhoneNumberSecondary field={state.fields.pickup_phone_number_secondary} dispatch={dispatch} />
        <PickupTimePicker state={state} dispatch={dispatch} currentUser={currentUser} />
        <StateSelect state={state} dispatch={dispatch}>
          {stateMenuItems}
        </StateSelect>
      </FieldSet>
      <FieldSet id="delivery-fields-container">
        <legend>Toimitus</legend>
        <LocationContainer className="delivery_address_link">
          <AddressLink
            title="Näytä sijainti"
            showIcon={true}
            address={state.fields.delivery_address.value}
            postalCode={state.fields.delivery_postal_code.value}
            city={state.fields.delivery_city.value}
          />
        </LocationContainer>
        <DeliveryOffice state={state} dispatch={dispatch} />
        <DeliveryName field={state.fields.delivery_name} dispatch={dispatch} />
        <DeliveryAddress field={state.fields.delivery_address} dispatch={dispatch} />
        <DeliveryPostalCode field={state.fields.delivery_postal_code} dispatch={dispatch} />
        <DeliveryCity field={state.fields.delivery_city} dispatch={dispatch} />
        <DeliveryPhoneNumber
          field={state.fields.delivery_phone_number}
          dispatch={dispatch}
          organization={organization}
        />
        <DeliveryPhoneNumberSecondary field={state.fields.delivery_phone_number_secondary} dispatch={dispatch} />
        <DeliveryDatePicker state={state} dispatch={dispatch} currentUser={currentUser} />
        <DeliveryTimePicker state={state} dispatch={dispatch} />
      </FieldSet>
      <FieldSet className="additional-services-container">
        <legend>Lisäpalvelut</legend>
        <AdditionalRequirementFields state={state} dispatch={dispatch} />
        {hasCoordinationAccess && <AdditionalServices state={state} dispatch={dispatch} disabled={state.isLoading} />}
      </FieldSet>
      <FieldSet id="notes-fields-container">
        <legend>Lisätietoja</legend>
        <Notes field={state.fields.note} dispatch={dispatch} />
      </FieldSet>
      {(canAccessCustomerPricing(currentUser) && parsedShipmentId) || isHostOrganizationUser(currentUser) ? (
        <PricingFieldSet state={state} dispatch={dispatch} currentUser={currentUser} isEditShipment={true} />
      ) : null}
      {state.originalShipment && parsedShipmentId && (
        <>
          <FieldSet id="timeline-fields-container">
            <legend>Tapahtumat</legend>
            <ShipmentTimeline
              shipment={state.originalShipment}
              state={state}
              dispatch={dispatch}
              currentUser={currentUser}
            />
          </FieldSet>
          <FieldSet id="attachment-fields-container">
            <legend>Liitteet</legend>
            {state.photos.map((photo) => (
              <PhotoChip key={photo.id} photo={photo} currentUser={currentUser} />
            ))}
            <ShipmentAddFileDialog
              open={state.isAddPhotoDialogOpen}
              shipmentId={parsedShipmentId}
              onClose={() => {
                dispatch({ type: 'SET_IS_PHOTO_DIALOG_OPEN', payload: { isDialogOpen: false } });
              }}
              onSuccess={() => {
                let getPhotosApi;
                if (canAccessCoordination(currentUser)) {
                  getPhotosApi = api.shipments.getShipmentPhotos({ shipmentId: parsedShipmentId });
                } else if (currentUser?.organization_id) {
                  getPhotosApi = api.organizationShipments.getOrganizationShipmentPhotos({
                    organizationId: currentUser?.organization_id,
                    shipmentId: parsedShipmentId,
                  });
                } else {
                  throw new Error('Organization id not found');
                }
                getPhotosApi.then((response) => {
                  if (response?.data.length > 0) {
                    dispatch({ type: 'SET_SHIPMENT_PHOTOS', payload: { photos: response.data } });
                  }
                });
              }}
              onLoading={() => dispatch({ type: 'SET_LOADING', payload: true })}
              onLoadingDone={() => dispatch({ type: 'SET_LOADING', payload: false })}
              showMessage={(message) => dispatch({ type: 'SET_MESSAGE', payload: { message } })}
              currentUser={currentUser}
            />
            <FullWidthButton
              variant="text"
              onClick={(e) => {
                e.stopPropagation();
                dispatch({ type: 'SET_IS_PHOTO_DIALOG_OPEN', payload: { isDialogOpen: true } });
              }}
              id={'open-add-file-dialog'}
            >
              Lisää tiedosto
            </FullWidthButton>
          </FieldSet>
        </>
      )}
      {(hasCoordinationAccess || hasCustomerCoordinationAccess) && parsedShipmentId && (
        <CoordinationFieldSet state={state} currentUser={currentUser} />
      )}
      {parsedShipmentId && (
        <RelatedShipmentsFieldSet
          state={state}
          dispatch={dispatch}
          currentUser={currentUser}
          hideRelatedShipments={hideRelatedShipments}
          setHideRelatedShipments={setHideRelatedShipments}
          isRelatedShipmentsLoading={state.isRelatedShipmentsLoading}
          showAllRelatedShipments={showAllRelatedShipments}
          setShowAllRelatedShipments={setShowAllRelatedShipments}
        />
      )}
      {hasCoordinationAccess && parsedShipmentId && (
        <FieldSet id="update-history-fields-container">
          <legend>Muokkaushistoria</legend>
          <UpdateHistory shipmentId={parsedShipmentId} isLoading={state.isLoading} />
        </FieldSet>
      )}
      <FieldSet id="size-fields-container">
        <legend>Kokotiedot</legend>
        <LengthLdmInput field={state.fields.length_ldm} dispatch={dispatch} />
        <VolumeM3Input field={state.fields.volume_m3} dispatch={dispatch} />
        <WeightKgInput field={state.fields.weight_kg} dispatch={dispatch} />
        <ChargeableWeightInput field={state.fields.chargeable_weight_kg} dispatch={dispatch} />
        {state.fields.sizes.feedback && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>{state.fields.sizes.feedback}</FormHelperText>
        )}
      </FieldSet>
    </FieldSetContainer>
  );
};
