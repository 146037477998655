/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  OrganizationDriver,
  OrganizationDriverFromJSON,
  OrganizationDriverFromJSONTyped,
  OrganizationDriverToJSON,
} from './OrganizationDriver';

/**
 *
 * @export
 * @interface OrganizationDriversResponseBody
 */
export interface OrganizationDriversResponseBody {
  /**
   *
   * @type {Array<OrganizationDriver>}
   * @memberof OrganizationDriversResponseBody
   */
  data: Array<OrganizationDriver>;
  /**
   *
   * @type {number}
   * @memberof OrganizationDriversResponseBody
   */
  next: number | null;
}

export function OrganizationDriversResponseBodyFromJSON(json: any): OrganizationDriversResponseBody {
  return OrganizationDriversResponseBodyFromJSONTyped(json, false);
}

export function OrganizationDriversResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationDriversResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(OrganizationDriverFromJSON),
    next: json['next'],
  };
}

export function OrganizationDriversResponseBodyToJSON(value?: OrganizationDriversResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(OrganizationDriverToJSON),
    next: value.next,
  };
}
