/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  UnhandledShipmentRow,
  UnhandledShipmentRowFromJSON,
  UnhandledShipmentRowFromJSONTyped,
  UnhandledShipmentRowToJSON,
} from './UnhandledShipmentRow';

/**
 *
 * @export
 * @interface UnhandledShipmentRowsResponseBody
 */
export interface UnhandledShipmentRowsResponseBody {
  /**
   *
   * @type {Array<UnhandledShipmentRow>}
   * @memberof UnhandledShipmentRowsResponseBody
   */
  data: Array<UnhandledShipmentRow>;
  /**
   *
   * @type {number}
   * @memberof UnhandledShipmentRowsResponseBody
   */
  next: number | null;
}

export function UnhandledShipmentRowsResponseBodyFromJSON(json: any): UnhandledShipmentRowsResponseBody {
  return UnhandledShipmentRowsResponseBodyFromJSONTyped(json, false);
}

export function UnhandledShipmentRowsResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UnhandledShipmentRowsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(UnhandledShipmentRowFromJSON),
    next: json['next'],
  };
}

export function UnhandledShipmentRowsResponseBodyToJSON(value?: UnhandledShipmentRowsResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(UnhandledShipmentRowToJSON),
    next: value.next,
  };
}
