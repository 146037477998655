import { Dispatch } from 'react';
import { api, User } from '../../api';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { canAccessCoordination } from '../../utils';
import { DateRange, getTodaysDateRange, SetDateRangeAction } from '../../utils/dateRangeUtils';

export type StateCountShipment = {
  organizationName?: string;
  readyForPickupCount: number;
  atPickupLocationCount: number;
  pickedUpCount: number;
  atDeliveryLocationCount: number;
  deliveredCount: number;
  cancelledCount: number;
  waitingCount: number;
  notInStorageCount: number;
  totalCount: number;
};

export interface State {
  stateCountShipments: StateCountShipment[];
  organizationCount?: number;
  loadCount?: number;
  driverCount?: number;
  carCount?: number;
  notification: NotificationType;
  isLoading: boolean;
  dateRange: DateRange;
}

export const initialState: State = {
  stateCountShipments: [],
  organizationCount: 0,
  loadCount: 0,
  driverCount: 0,
  carCount: 0,
  notification: {
    message: null,
  },
  isLoading: true,
  dateRange: getTodaysDateRange(),
};

export type Action =
  | {
      type: 'INITIALIZE';
      payload: {
        stateCountShipments: StateCountShipment[];
        loadCount?: number;
        organizationCount?: number;
        driverCount?: number;
        carCount?: number;
      };
    }
  | SetMessageAction
  | SetLoadingAction
  | SetDateRangeAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        stateCountShipments: action.payload.stateCountShipments,
        organizationCount: action.payload.organizationCount,
        loadCount: action.payload.loadCount,
        driverCount: action.payload.driverCount,
        carCount: action.payload.carCount,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
  }
};

export const load = async (currentUser: User, state: State, dispatch: Dispatch<Action>): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    if (canAccessCoordination(currentUser)) {
      const summary = await api.summary.getSummary({
        agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
        agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
      });
      dispatch({
        type: 'INITIALIZE',
        payload: {
          stateCountShipments: (summary.shipments as StateCountShipment[]) ?? [],
          loadCount: summary.loadCount,
          driverCount: summary.driverCount,
          carCount: summary.carCount,
          organizationCount: summary.organizationCount,
        },
      });
    } else {
      const summary = await api.organizationSummary.getOrganizationSummary({
        agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
        agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
        organizationId: currentUser.organization_id,
      });
      dispatch({
        type: 'INITIALIZE',
        payload: {
          stateCountShipments: (summary.shipments as StateCountShipment[]) ?? [],
        },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa yhteenvetoa',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

export const getCombinedShipmentStateCounts = (stateCountShipments: StateCountShipment[]) => {
  return stateCountShipments.reduce(
    (memo, shipment) => {
      return {
        totalCount: memo.totalCount + shipment.totalCount,
        readyForPickupCount: memo.readyForPickupCount + shipment.readyForPickupCount,
        atPickupLocationCount: memo.atPickupLocationCount + shipment.atPickupLocationCount,
        pickedUpCount: memo.pickedUpCount + shipment.pickedUpCount,
        atDeliveryLocationCount: memo.atDeliveryLocationCount + shipment.atDeliveryLocationCount,
        deliveredCount: memo.deliveredCount + shipment.deliveredCount,
        cancelledCount: memo.cancelledCount + shipment.cancelledCount,
        waitingCount: memo.waitingCount + shipment.waitingCount,
        notInStorageCount: memo.notInStorageCount + shipment.notInStorageCount,
      };
    },
    {
      totalCount: 0,
      readyForPickupCount: 0,
      atPickupLocationCount: 0,
      pickedUpCount: 0,
      atDeliveryLocationCount: 0,
      deliveredCount: 0,
      cancelledCount: 0,
      waitingCount: 0,
      notInStorageCount: 0,
    },
  );
};

export function getShipmentStateCount(stateCountShipment: StateCountShipment, state: string): number {
  switch (state) {
    case 'pickedUp':
      return stateCountShipment.atPickupLocationCount + stateCountShipment.pickedUpCount;
    case 'delivered':
      return stateCountShipment.atDeliveryLocationCount + stateCountShipment.deliveredCount;
    case 'notInStorage':
      return stateCountShipment.cancelledCount + stateCountShipment.waitingCount + stateCountShipment.notInStorageCount;
    default:
      return 0;
  }
}
