/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  OrganizationCar,
  OrganizationCarFromJSON,
  OrganizationCarFromJSONTyped,
  OrganizationCarToJSON,
} from './OrganizationCar';

/**
 *
 * @export
 * @interface OrganizationCarsResponseBody
 */
export interface OrganizationCarsResponseBody {
  /**
   *
   * @type {Array<OrganizationCar>}
   * @memberof OrganizationCarsResponseBody
   */
  data: Array<OrganizationCar>;
  /**
   *
   * @type {number}
   * @memberof OrganizationCarsResponseBody
   */
  next: number | null;
}

export function OrganizationCarsResponseBodyFromJSON(json: any): OrganizationCarsResponseBody {
  return OrganizationCarsResponseBodyFromJSONTyped(json, false);
}

export function OrganizationCarsResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationCarsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(OrganizationCarFromJSON),
    next: json['next'],
  };
}

export function OrganizationCarsResponseBodyToJSON(value?: OrganizationCarsResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(OrganizationCarToJSON),
    next: value.next,
  };
}
