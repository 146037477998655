/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  LegacyIntegrationGetResponseBody,
  LegacyIntegrationGetResponseBodyFromJSON,
  LegacyIntegrationGetResponseBodyToJSON,
} from '../models';

export interface LegacySystemIntegrationApiLegacySystemShipmentEndpointRequest {
  shipmentId: number;
  body?: object;
}

export interface LegacySystemIntegrationApiLegacySystemShipmentGetRequest {
  shipmentId: number;
}

/**
 *
 */
export class LegacySystemIntegrationApi extends runtime.BaseAPI {
  /**
   * Allows the legacy PHP system to send updates
   */
  async legacySystemShipmentEndpointRaw(
    requestParameters: LegacySystemIntegrationApiLegacySystemShipmentEndpointRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling legacySystemShipmentEndpoint.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/legacy_system/shipments/legacy_update/{shipment_id}`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Allows the legacy PHP system to send updates
   */
  async legacySystemShipmentEndpoint(
    requestParameters: LegacySystemIntegrationApiLegacySystemShipmentEndpointRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.legacySystemShipmentEndpointRaw(requestParameters, initOverrides);
  }

  /**
   * Allows the legacy PHP system get shipment information
   */
  async legacySystemShipmentGetRaw(
    requestParameters: LegacySystemIntegrationApiLegacySystemShipmentGetRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<LegacyIntegrationGetResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling legacySystemShipmentGet.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/legacy_system/shipments/legacy_update/{shipment_id}`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LegacyIntegrationGetResponseBodyFromJSON(jsonValue));
  }

  /**
   * Allows the legacy PHP system get shipment information
   */
  async legacySystemShipmentGet(
    requestParameters: LegacySystemIntegrationApiLegacySystemShipmentGetRequest,
    initOverrides?: RequestInit,
  ): Promise<LegacyIntegrationGetResponseBody> {
    const response = await this.legacySystemShipmentGetRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
