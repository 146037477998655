import React from 'react';
import { SendToMobile } from '@mui/icons-material';
import { FieldSet } from '../../components/StyledComponents/FieldSet';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { api, Driver } from '../../api';
import { DriverSelector } from '../../components/DriverSelector';
import { Loading } from '../../components/Loading';
import Main from '../../components/Main';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from '../../components/Notification';

const Sms: React.FC = () => {
  const [message, setMessage] = React.useState('');
  const [selectedDrivers, setSelectedDrivers] = React.useState<Array<Driver>>([]);
  const [usePersonalNumbers, setUsePersonalNumbers] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [notification, setNotification] = React.useState<NotificationType>({ message: null });
  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };

  return (
    <Main>
      <Loading isLoading={isLoading} />
      <FieldSet>
        <legend>Lähetä viesti valituille kuljettajille</legend>
        <TextField
          multiline={true}
          required={true}
          label="Viesti"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
        <FormControlLabel
          sx={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}
          control={<Checkbox onChange={(event) => setUsePersonalNumbers(event.target.checked)} />}
          label="Käytä omia numeroita"
        />
        <Button
          sx={{ paddingTop: '0.25rem' }}
          startIcon={<SendToMobile />}
          color="success"
          disabled={isLoading || selectedDrivers.length === 0 || message === ''}
          onClick={async () => {
            try {
              setIsLoading(true);
              await api.employees.postEmployeeSms({
                employeeSmsPostBody: {
                  driver_ids: selectedDrivers.map((driver) => driver.id),
                  message: message,
                  use_personal_numbers: usePersonalNumbers,
                },
              });
              setMessage('');
              setNotification({ message: 'Viestit lähetetty!' });
            } catch (err) {
              setNotification({ message: 'Virhe viestien lähettämisessä', severity: 'error' });
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Lähetä
        </Button>
      </FieldSet>
      <DriverSelector onDriversSelected={(drivers) => setSelectedDrivers(drivers)} displayGroups={true} />
      <Notification {...snackbarProps} />
    </Main>
  );
};
export default Sms;
