import { Dispatch, SetStateAction } from 'react';
import { dateNotRequired, hourlyWorkReason, lengthLdm, name, volumeM3, weightKg, workHours } from '../../../validation';
import { State } from './inputs';

type Result = {
  hasError: boolean;
  feedback?: string;
  value: any;
};

type ValidationResult = Pick<Result, 'hasError' | 'feedback'>;
const getValidationResult = (joiSchema: any, value: any): ValidationResult => {
  const result = joiSchema.validate(value);
  const validationResult: ValidationResult = {
    hasError: result.error !== undefined,
    feedback: result.error?.message,
  };
  return validationResult;
};

const validateField = (fieldName: string, value: any): Result => {
  let result: Result = {
    hasError: false,
    feedback: undefined,
    value: value,
  };
  switch (fieldName) {
    case 'hourly_work_hours':
    case 'wait_hours': {
      result = { ...getValidationResult(workHours, value), value: value ? parseFloat(value ?? 0) : value };
      break;
    }
    case 'weight_kg': {
      result = { ...getValidationResult(weightKg, value), value: value ? parseFloat(value ?? 0) : value };
      break;
    }
    case 'volume_m3': {
      result = { ...getValidationResult(volumeM3, value), value: value ? parseFloat(value ?? 0) : value };
      break;
    }
    case 'length_ldm': {
      result = { ...getValidationResult(lengthLdm, value), value: value ? parseFloat(value ?? 0) : value };
      break;
    }
    case 'hourly_work_reason':
    case 'wait_reason': {
      result = { ...getValidationResult(hourlyWorkReason, value), value };
      break;
    }
    case 'recipient': {
      result = { ...getValidationResult(name, value), value };
      break;
    }
    case 'arrived_to_pickup_location_at':
    case 'picked_up_at':
    case 'arrived_to_delivery_location_at':
    case 'delivered_at': {
      result = { ...getValidationResult(dateNotRequired, value), value };
      break;
    }
  }
  return result;
};

export const setValueAndValidate = (
  state: State,
  setState: Dispatch<SetStateAction<State>>,
  value: any,
  field: string,
) => {
  setState({
    ...state,
    fields: {
      ...state.fields,
      [field]: {
        ...state.fields[field as keyof State['fields']],
        ...validateField(field, value),
      },
    },
  });
};

export const setAdditionalServicesAndValidate = (
  state: State,
  setState: Dispatch<SetStateAction<State>>,
  value: any,
  id: number,
) => {
  let quantity: number | '' = value !== '' ? Number(value) : '';
  quantity = Number.isNaN(quantity) ? '' : quantity;
  const additionalServiceFields = [...state.additionalServiceFields];
  const existingShipmentAdditionalServiceIndex = additionalServiceFields.findIndex(
    (shipAddServiceField) => shipAddServiceField.additionalService.id === id,
  );
  additionalServiceFields[existingShipmentAdditionalServiceIndex] = {
    ...additionalServiceFields[existingShipmentAdditionalServiceIndex],
    value: quantity,
    ...getValidationResult(workHours, quantity),
  };
  setState({
    ...state,
    additionalServiceFields,
  });
};
