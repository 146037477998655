/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ShipmentReportAggregate
 */
export interface ShipmentReportAggregate {
  /**
   *
   * @type {string}
   * @memberof ShipmentReportAggregate
   */
  type: ShipmentReportAggregateTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ShipmentReportAggregate
   */
  field?: ShipmentReportAggregateFieldEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ShipmentReportAggregateTypeEnum {
  Sum = 'sum',
  Count = 'count',
} /**
 * @export
 * @enum {string}
 */
export enum ShipmentReportAggregateFieldEnum {
  WeightKg = 'weight_kg',
  VolumeM3 = 'volume_m3',
  LengthLdm = 'length_ldm',
  HourlyWorkHours = 'hourly_work_hours',
  ChargeableWeightKg = 'chargeable_weight_kg',
  Price = 'price',
}

export function ShipmentReportAggregateFromJSON(json: any): ShipmentReportAggregate {
  return ShipmentReportAggregateFromJSONTyped(json, false);
}

export function ShipmentReportAggregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentReportAggregate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    field: !exists(json, 'field') ? undefined : json['field'],
  };
}

export function ShipmentReportAggregateToJSON(value?: ShipmentReportAggregate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    field: value.field,
  };
}
