import { LoaderFunctionArgs } from 'react-router-dom';
import { Office, OrganizationScheduleShipment } from '../../../backend/src/common/api';
import { api, getAllPages } from '../api';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';
import { Organizations } from '../formatters';
import { isReturnShipment } from './PeriPiha';

export type PeriPihaShipmentDay = { date: string; shipments: Array<OrganizationScheduleShipment> };
export type PeriPihaLoaderResponse = { data: Array<PeriPihaShipmentDay>; offices: Array<Office> };

export const periPihaLoader = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- params is required for the loader function
  { params }: LoaderFunctionArgs,
  organization_id: string,
): Promise<PeriPihaLoaderResponse> => {
  const today = DateTime.now().startOf('day');
  const lastDay = today.plus({ days: 21 }).endOf('day');

  const shipments = await getAllPages(
    api.organizationScheduleShipments.getOrganizationScheduleShipments.bind(api.organizationScheduleShipments),
    {
      organizationId: organization_id,
      agreedDeliveryWindowDateRangeStartsAt: today.toJSDate(),
      agreedDeliveryWindowDateRangeEndsAt: lastDay.toJSDate(),
    },
  );
  const organizationOfficesResponse = await api.organizationOffices.getOrganizationOffices({
    organizationId: Organizations.Peri,
  });

  const shipmentByDateMap = new Map<string, Array<OrganizationScheduleShipment>>();
  shipments.forEach((shipment) => {
    const deliveryDate = DateTime.fromJSDate(shipment.agreed_delivery_window_starts_at as Date).toISODate();
    const pickupDate = DateTime.fromJSDate(shipment.pickup_window_starts_at as Date).toISODate();
    const date =
      isReturnShipment(shipment as unknown as OrganizationScheduleShipment, organizationOfficesResponse.data[0]) &&
      shipment.pickup_window_starts_at
        ? pickupDate
        : deliveryDate;
    if (!shipmentByDateMap.has(date)) {
      shipmentByDateMap.set(date, []);
    }
    shipmentByDateMap
      .get(date)
      ?.push({ ...shipment, state: shipment.state as unknown as OrganizationScheduleShipment['state'] });
  });

  // Add entries for days without shipments
  for (let i = 0; i < 21; i++) {
    const date = today.plus({ days: i }).toISODate();
    if (!shipmentByDateMap.has(date)) {
      shipmentByDateMap.set(date, []);
    }
  }

  const response = Array.from(shipmentByDateMap)
    .map(([date, shipments]) => ({ date, shipments }))
    .sort();

  return { data: sortBy(response, 'date'), offices: organizationOfficesResponse.data };
};
