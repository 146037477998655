/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LegacyIntegrationGetResponseBody
 */
export interface LegacyIntegrationGetResponseBody {
  /**
   *
   * @type {string}
   * @memberof LegacyIntegrationGetResponseBody
   */
  price_basis_str: string | null;
}

export function LegacyIntegrationGetResponseBodyFromJSON(json: any): LegacyIntegrationGetResponseBody {
  return LegacyIntegrationGetResponseBodyFromJSONTyped(json, false);
}

export function LegacyIntegrationGetResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LegacyIntegrationGetResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    price_basis_str: json['price_basis_str'],
  };
}

export function LegacyIntegrationGetResponseBodyToJSON(value?: LegacyIntegrationGetResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    price_basis_str: value.price_basis_str,
  };
}
