import { DateTime } from 'luxon';
import { Field, FieldName, State } from './workHours.state';
import { dateRequired, dateWithMinAndMaxRequired, name } from '../../validation';

type ValidationResult = {
  hasError: boolean;
  feedback?: string;
};

const getValidationResult = (joiSchema: any, field: Field<any>): ValidationResult => {
  const result = joiSchema.validate(field.value);
  const validationResult: ValidationResult = {
    hasError: result.error !== undefined,
    feedback: result.error?.message,
  };
  return validationResult;
};

const validateDeliveryTimeWindow = (field: Field<any>): ValidationResult => {
  const validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  const deliveryWindowTime = DateTime.fromISO(field.value);
  if (!deliveryWindowTime.isValid && field.value) {
    validationResult.hasError = true;
    validationResult.feedback = 'Virheellinen aika';
  }
  return validationResult;
};

const validateField = (
  fieldName: FieldName,
  fields: State['fields'],
  minDate?: DateTime,
  maxDate?: DateTime,
): ValidationResult => {
  const field = fields[fieldName];
  let validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  switch (fieldName) {
    case 'starts_at':
    case 'ends_at': {
      validationResult = validateDeliveryTimeWindow(field);
      break;
    }
    case 'date': {
      if (minDate && maxDate) {
        validationResult = getValidationResult(dateWithMinAndMaxRequired(minDate, maxDate), field);
      } else {
        validationResult = getValidationResult(dateRequired, field);
      }
      break;
    }
    case 'note': {
      validationResult = getValidationResult(name, field);
      break;
    }
  }

  if (field.required && (field.value === undefined || field.value === null || field.value === '')) {
    validationResult.hasError = true;
    validationResult.feedback = 'Kenttä on pakollinen';
  }
  return validationResult;
};

export const validateFields = (fields: State['fields'], minDate?: DateTime, maxDate?: DateTime) => {
  const newFields = { ...fields } as any;
  for (const fieldName of Object.keys(fields)) {
    newFields[fieldName] = {
      ...newFields[fieldName],
      ...validateField(fieldName as FieldName, fields, minDate, maxDate),
    };
  }
  return {
    fields: newFields,
  };
};
