import React from 'react';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem,
  Menu,
  styled,
  Tooltip,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, FilterList, Settings } from '@mui/icons-material';
import { ColumnInstance } from 'react-table';
import { isFunction } from 'lodash';
import { ColumnLayouts } from './ColumnLayouts';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

interface ColumnAndFilterSelectorProps {
  columns: ColumnInstance[];
  hiddenColumns: string[];
  resetFilters?: any;
  viewId?: string;
}

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: '-10px',
});

const FilterContainer = styled('div')({
  marginLeft: 'auto',
  width: '10rem',
});

export const ColumnAndFilterSelector: React.FC<ColumnAndFilterSelectorProps> = ({
  columns,
  hiddenColumns,
  resetFilters,
  viewId,
}) => {
  const [edit, setEdit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popoverActions = React.useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setEdit(false);
  };
  const hiddenColumnsCount = hiddenColumns.length;
  const filterValuesCount = columns.filter((column) => column.filterValue).length;

  return (
    <div>
      <Tooltip
        title={
          hiddenColumnsCount || filterValuesCount ? (
            <div className="filter-values-and-hidden-columns-tooltip">
              {filterValuesCount ? <p>Täytettyjä suodattimia: {filterValuesCount}</p> : null}
              {hiddenColumnsCount ? <p>Piilotettuja sarakkeita: {hiddenColumnsCount}</p> : null}
            </div>
          ) : (
            ''
          )
        }
        aria-label={'filter-values-and-hidden-columns-tooltip'}
      >
        <IconButton
          aria-label="more"
          id="column-and-filter-selector-button"
          className="column-and-filter-selector-button"
          aria-controls={open ? 'column-and-filter-selector-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          <Badge
            overlap="rectangular"
            color="primary"
            badgeContent={hiddenColumnsCount}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Badge
              overlap="rectangular"
              color="error"
              badgeContent={filterValuesCount}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <FilterList />
            </Badge>
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        action={popoverActions}
        id="column-and-filter-selector-menu"
        className="column-and-filter-selector-menu"
        MenuListProps={{
          'aria-labelledby': 'column-and-filter-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              padding: '0 0.75rem 0.75rem 0.75rem',
              maxHeight: '90vh',
              width: 'fit-content',
            },
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ColumnLayouts columns={columns} viewId={viewId} isEditing={edit} popoverActions={popoverActions} />
          <Box sx={{ maxWidth: 'min-content' }}>
            <ButtonContainer>
              <Checkbox
                size="small"
                checked={columns.every((x) => x.isVisible)}
                indeterminate={!columns.every((x) => x.isVisible) && columns.some((x) => x.isVisible)}
                onClick={() => {
                  for (const column of columns) {
                    if (!column.isVisible || columns.every((x) => x.isVisible)) {
                      column.toggleHidden();
                    }
                  }
                }}
              />
              <Tooltip title="Muokkaa sarakepohjia">
                <IconButton onClick={() => setEdit(!edit)}>
                  <Settings />
                </IconButton>
              </Tooltip>
              <Button variant="text" onClick={resetFilters}>
                Tyhjennä suodattimet
              </Button>
            </ButtonContainer>
            {columns.map((column) => {
              const label = !isFunction(column.Header) ? (column.Header as string) : '';
              return (
                <ListItem key={column.id} style={{ margin: 0, marginRight: '0.25rem', padding: 0 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={column.id + '_checkbox'}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: '1rem' }}
                        checked={!column.isVisible}
                        {...column.getToggleHiddenProps()}
                      />
                    }
                    label={label}
                  />
                  <FilterContainer className={column.id + '_filter'}>
                    {column.canFilter ? column.render('Filter') : null}
                  </FilterContainer>
                </ListItem>
              );
            })}
          </Box>
        </Box>
      </Menu>
    </div>
  );
};
