/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  FutureWorkHourPatchBody,
  FutureWorkHourPatchBodyFromJSON,
  FutureWorkHourPatchBodyToJSON,
  FutureWorkHourPatchResponseBody,
  FutureWorkHourPatchResponseBodyFromJSON,
  FutureWorkHourPatchResponseBodyToJSON,
  FutureWorkHourPostBody,
  FutureWorkHourPostBodyFromJSON,
  FutureWorkHourPostBodyToJSON,
  FutureWorkHourPostResponseBody,
  FutureWorkHourPostResponseBodyFromJSON,
  FutureWorkHourPostResponseBodyToJSON,
  FutureWorkHourResponseBody,
  FutureWorkHourResponseBodyFromJSON,
  FutureWorkHourResponseBodyToJSON,
  FutureWorkHourType,
  FutureWorkHourTypeFromJSON,
  FutureWorkHourTypeToJSON,
} from '../models';

export interface FutureWorkHourApiDeleteFutureWorkHourRequest {
  workHourId: number;
  employeeNumber: string;
  previousDate: Date;
  type: FutureWorkHourType;
}

export interface FutureWorkHourApiGetFutureWorkHoursByEmployeeNumberRequest {
  employeeNumber: string;
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
}

export interface FutureWorkHourApiPatchFutureWorkHourRequest {
  workHourId: number;
  employeeNumber: string;
  previousDate: Date;
  futureWorkHourPatchBody: FutureWorkHourPatchBody;
}

export interface FutureWorkHourApiPostFutureWorkHourRequest {
  employeeNumber: string;
  futureWorkHourPostBody: FutureWorkHourPostBody;
}

/**
 *
 */
export class FutureWorkHourApi extends runtime.BaseAPI {
  /**
   * Delete a future work hour by id.
   */
  async deleteFutureWorkHourRaw(
    requestParameters: FutureWorkHourApiDeleteFutureWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.workHourId === null || requestParameters.workHourId === undefined) {
      throw new runtime.RequiredError(
        'workHourId',
        'Required parameter requestParameters.workHourId was null or undefined when calling deleteFutureWorkHour.',
      );
    }

    if (requestParameters.employeeNumber === null || requestParameters.employeeNumber === undefined) {
      throw new runtime.RequiredError(
        'employeeNumber',
        'Required parameter requestParameters.employeeNumber was null or undefined when calling deleteFutureWorkHour.',
      );
    }

    if (requestParameters.previousDate === null || requestParameters.previousDate === undefined) {
      throw new runtime.RequiredError(
        'previousDate',
        'Required parameter requestParameters.previousDate was null or undefined when calling deleteFutureWorkHour.',
      );
    }

    if (requestParameters.type === null || requestParameters.type === undefined) {
      throw new runtime.RequiredError(
        'type',
        'Required parameter requestParameters.type was null or undefined when calling deleteFutureWorkHour.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.previousDate !== undefined) {
      queryParameters['previous_date'] = (requestParameters.previousDate as any).toISOString();
    }

    if (requestParameters.type !== undefined) {
      queryParameters['type'] = requestParameters.type;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/employees/{employee_number}/future_work_hours/{work_hour_id}`
          .replace(`{${'work_hour_id'}}`, encodeURIComponent(String(requestParameters.workHourId)))
          .replace(`{${'employee_number'}}`, encodeURIComponent(String(requestParameters.employeeNumber))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a future work hour by id.
   */
  async deleteFutureWorkHour(
    requestParameters: FutureWorkHourApiDeleteFutureWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteFutureWorkHourRaw(requestParameters, initOverrides);
  }

  /**
   * Get future work hours of given driver
   */
  async getFutureWorkHoursByEmployeeNumberRaw(
    requestParameters: FutureWorkHourApiGetFutureWorkHoursByEmployeeNumberRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<FutureWorkHourResponseBody>> {
    if (requestParameters.employeeNumber === null || requestParameters.employeeNumber === undefined) {
      throw new runtime.RequiredError(
        'employeeNumber',
        'Required parameter requestParameters.employeeNumber was null or undefined when calling getFutureWorkHoursByEmployeeNumber.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/employees/{employee_number}/future_work_hours`.replace(
          `{${'employee_number'}}`,
          encodeURIComponent(String(requestParameters.employeeNumber)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => FutureWorkHourResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get future work hours of given driver
   */
  async getFutureWorkHoursByEmployeeNumber(
    requestParameters: FutureWorkHourApiGetFutureWorkHoursByEmployeeNumberRequest,
    initOverrides?: RequestInit,
  ): Promise<FutureWorkHourResponseBody> {
    const response = await this.getFutureWorkHoursByEmployeeNumberRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Patch future work hour
   */
  async patchFutureWorkHourRaw(
    requestParameters: FutureWorkHourApiPatchFutureWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<FutureWorkHourPatchResponseBody>> {
    if (requestParameters.workHourId === null || requestParameters.workHourId === undefined) {
      throw new runtime.RequiredError(
        'workHourId',
        'Required parameter requestParameters.workHourId was null or undefined when calling patchFutureWorkHour.',
      );
    }

    if (requestParameters.employeeNumber === null || requestParameters.employeeNumber === undefined) {
      throw new runtime.RequiredError(
        'employeeNumber',
        'Required parameter requestParameters.employeeNumber was null or undefined when calling patchFutureWorkHour.',
      );
    }

    if (requestParameters.previousDate === null || requestParameters.previousDate === undefined) {
      throw new runtime.RequiredError(
        'previousDate',
        'Required parameter requestParameters.previousDate was null or undefined when calling patchFutureWorkHour.',
      );
    }

    if (requestParameters.futureWorkHourPatchBody === null || requestParameters.futureWorkHourPatchBody === undefined) {
      throw new runtime.RequiredError(
        'futureWorkHourPatchBody',
        'Required parameter requestParameters.futureWorkHourPatchBody was null or undefined when calling patchFutureWorkHour.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.previousDate !== undefined) {
      queryParameters['previous_date'] = (requestParameters.previousDate as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/employees/{employee_number}/future_work_hours/{work_hour_id}`
          .replace(`{${'work_hour_id'}}`, encodeURIComponent(String(requestParameters.workHourId)))
          .replace(`{${'employee_number'}}`, encodeURIComponent(String(requestParameters.employeeNumber))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: FutureWorkHourPatchBodyToJSON(requestParameters.futureWorkHourPatchBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => FutureWorkHourPatchResponseBodyFromJSON(jsonValue));
  }

  /**
   * Patch future work hour
   */
  async patchFutureWorkHour(
    requestParameters: FutureWorkHourApiPatchFutureWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<FutureWorkHourPatchResponseBody> {
    const response = await this.patchFutureWorkHourRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create new future work hour
   */
  async postFutureWorkHourRaw(
    requestParameters: FutureWorkHourApiPostFutureWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<FutureWorkHourPostResponseBody>> {
    if (requestParameters.employeeNumber === null || requestParameters.employeeNumber === undefined) {
      throw new runtime.RequiredError(
        'employeeNumber',
        'Required parameter requestParameters.employeeNumber was null or undefined when calling postFutureWorkHour.',
      );
    }

    if (requestParameters.futureWorkHourPostBody === null || requestParameters.futureWorkHourPostBody === undefined) {
      throw new runtime.RequiredError(
        'futureWorkHourPostBody',
        'Required parameter requestParameters.futureWorkHourPostBody was null or undefined when calling postFutureWorkHour.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/employees/{employee_number}/future_work_hours`.replace(
          `{${'employee_number'}}`,
          encodeURIComponent(String(requestParameters.employeeNumber)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FutureWorkHourPostBodyToJSON(requestParameters.futureWorkHourPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => FutureWorkHourPostResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create new future work hour
   */
  async postFutureWorkHour(
    requestParameters: FutureWorkHourApiPostFutureWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<FutureWorkHourPostResponseBody> {
    const response = await this.postFutureWorkHourRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
