/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CarrierShipmentAdditionalServicePostBody
 */
export interface CarrierShipmentAdditionalServicePostBody {
  /**
   *
   * @type {number}
   * @memberof CarrierShipmentAdditionalServicePostBody
   */
  additional_service_id: number;
  /**
   *
   * @type {number}
   * @memberof CarrierShipmentAdditionalServicePostBody
   */
  amount: number;
}

export function CarrierShipmentAdditionalServicePostBodyFromJSON(json: any): CarrierShipmentAdditionalServicePostBody {
  return CarrierShipmentAdditionalServicePostBodyFromJSONTyped(json, false);
}

export function CarrierShipmentAdditionalServicePostBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CarrierShipmentAdditionalServicePostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    additional_service_id: json['additional_service_id'],
    amount: json['amount'],
  };
}

export function CarrierShipmentAdditionalServicePostBodyToJSON(
  value?: CarrierShipmentAdditionalServicePostBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    additional_service_id: value.additional_service_id,
    amount: value.amount,
  };
}
