/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RefreshTokenResponseBody
 */
export interface RefreshTokenResponseBody {
  /**
   *
   * @type {string}
   * @memberof RefreshTokenResponseBody
   */
  refresh_token?: string;
}

export function RefreshTokenResponseBodyFromJSON(json: any): RefreshTokenResponseBody {
  return RefreshTokenResponseBodyFromJSONTyped(json, false);
}

export function RefreshTokenResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RefreshTokenResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    refresh_token: !exists(json, 'refresh_token') ? undefined : json['refresh_token'],
  };
}

export function RefreshTokenResponseBodyToJSON(value?: RefreshTokenResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    refresh_token: value.refresh_token,
  };
}
