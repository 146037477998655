/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A custom pricing category that belongs to a pricing model
 * @export
 * @interface CustomPricingCategory
 */
export interface CustomPricingCategory {
  /**
   *
   * @type {number}
   * @memberof CustomPricingCategory
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof CustomPricingCategory
   */
  name: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomPricingCategory
   */
  description: string | null;
}

export function CustomPricingCategoryFromJSON(json: any): CustomPricingCategory {
  return CustomPricingCategoryFromJSONTyped(json, false);
}

export function CustomPricingCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomPricingCategory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: json['description'],
  };
}

export function CustomPricingCategoryToJSON(value?: CustomPricingCategory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
  };
}
