import React, { Dispatch, useReducer } from 'react';
import { Loading } from '../../components/Loading';
import { Action, getInitialState, reducer, State } from './createNewUser.state';
import { api, CreateNewUserPostBody } from '../../api';
import { useNavigate } from 'react-router-dom';
import Notification, { getSnackbarPropsFromState, tryAgainMessage } from '../../components/Notification';
import { AllUserFields } from './components/inputs';
import { LoginCard, StyledCardActions } from '../LogIn';
import { CardContent, CardMedia, CardHeader, Button } from '@mui/material';
import TimecapLogo from '../../../static/img/timecaplogo.png';

export const getUserPostBodyFromState = (state: State): CreateNewUserPostBody => {
  return {
    username: state.fields.username.value,
    first_name: state.fields.first_name.value ?? '',
    last_name: state.fields.last_name.value ?? '',
    email: state.fields.email.value ?? undefined,
    gsm: state.fields.gsm.value ?? undefined,
    password: state.fields.password.value,
  };
};

export const saveNewUser = async (
  state: State,
  dispatch: Dispatch<Action>,
  navigate: ReturnType<typeof useNavigate>,
  createUserToken: string | null,
) => {
  if (!state.isValid || !createUserToken) {
    dispatch(tryAgainMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });
  try {
    const userResponse = await api.CreateNewUser.createNewUser({
      createNewUserToken: createUserToken,
      createNewUserPostBody: getUserPostBodyFromState(state),
    });
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: `Käyttäjä ${userResponse.data?.username} luotu onnistuneesti. Sinut ohjataan kirjautumissivulle.`,
      },
    });
    dispatch({ type: 'SET_LOADING', payload: false });
    setTimeout(() => {
      dispatch({ type: 'SET_LOADING', payload: false });
      navigate('/login');
    }, 1000);
  } catch (err) {
    if ((err as any).status === 400) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: 'Valitsemasi käyttäjätunnus tai sähköposti on jo käytössä. Yritä uudelleen.',
          severity: 'error',
        },
      });
    } else {
      dispatch(tryAgainMessage);
    }
    console.error(err);
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

export const getCreateNewUserTokenFromUrl = (): string | null => {
  const url = new URL(window.location.href);
  return url.searchParams.get('token') ?? null;
};

const CreateNewUser: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState(true));
  const navigate = useNavigate();
  const createNewUserToken = getCreateNewUserTokenFromUrl();

  return (
    <form noValidate autoComplete="off" style={{ padding: '1rem' }}>
      <Loading isLoading={state.isLoading} />
      <LoginCard>
        <CardContent>
          <CardMedia component="img" alt="Timecap Logo" image={TimecapLogo} />
          <CardHeader title="Uuden käyttäjätunnuksen luominen" />
          <AllUserFields state={state} dispatch={dispatch} />
          <StyledCardActions>
            <Button
              data-cy="create-new-user-button"
              disabled={!state.isValid || state.isLoading}
              onClick={() => saveNewUser(state, dispatch, navigate, createNewUserToken)}
            >
              Luo uusi käyttäjätunnus
            </Button>
          </StyledCardActions>
        </CardContent>
      </LoginCard>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </form>
  );
};

export default CreateNewUser;
