import React, { useEffect, useRef, useState } from 'react';
import { api, Load, Organization, Shipment, ShipmentRow, ShipmentStateEnum } from '../../api';
import {
  Alert,
  AlertTitle,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Card,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { differenceBy, groupBy, keys, orderBy } from 'lodash';
import { DeliveryWindow } from '../../components/DeliveryWindow';
import theme from '../../theme';
import {
  Done,
  ErrorOutline,
  FormatAlignCenter,
  ListAlt,
  LocalShipping,
  PrecisionManufacturing,
  RvHookup,
  Speed,
  Warning,
} from '@mui/icons-material';
import { getShipmentStateUtils, isToday } from '../../utils';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { DateTime } from 'luxon';
import { formatFloat, formatShortDate, formatWeekDayLong } from '../../formatters';

type DriverShipment = Shipment & {
  driverState: DriverStateEnum;
};

type DriverOverview = {
  shipments: DriverShipment[];
  loads: Load[];
  shipmentRows: ShipmentRow[];
};

enum DriverStateEnum {
  Noudettavissa = 'noudettavissa',
  Noudettu = 'noudettu',
  Toimitettu = 'toimitettu',
  EiVarastossa = 'ei varastossa',
  Ignore = 'ignore',
}

const shipmentStateToDriverState = (state: ShipmentStateEnum): DriverStateEnum => {
  switch (state) {
    case ShipmentStateEnum.Noudettavissa:
    case ShipmentStateEnum.Noutokohteessa:
      return DriverStateEnum.Noudettavissa;
    case ShipmentStateEnum.Noudettu:
    case ShipmentStateEnum.Toimituskohteessa:
      return DriverStateEnum.Noudettu;
    case ShipmentStateEnum.Toimitettu:
      return DriverStateEnum.Toimitettu;
    case ShipmentStateEnum.EiVarastossa:
      return DriverStateEnum.EiVarastossa;
    default:
      return DriverStateEnum.Ignore;
  }
};

const SingleShipment: React.FC<{
  shipment: Shipment;
  loadOrganizationId: string | null | undefined;
  tab: number;
  getOrganization: (id: string | null) => Organization | undefined;
}> = ({ shipment, loadOrganizationId, tab, getOrganization }) => {
  const shipmentHeader = shipment.reference_number ? shipment.reference_number : shipment.id;
  const headerText =
    shipment.organization_id === loadOrganizationId
      ? shipmentHeader
      : `${getOrganization(shipment.organization_id)?.name} ${shipmentHeader}`;
  const stateText = tab === 3 && `(${shipment.state})`;
  return (
    <>
      <ListItemButton
        sx={{
          ...(tab === 3 && { backgroundColor: getShipmentStateUtils(shipment.state).backgroundColor }),
        }}
        key={shipment.id}
        alignItems="flex-start"
        component={RouterLink}
        to={{ pathname: `/driver/loads/${shipment.load_id}/shipments/${shipment.id}` }}
      >
        <Stack
          sx={{
            width: '100%',
            '> p': {
              marginBottom: '0.25rem',
              fontSize: '0.8rem',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              minHeigth: '2rem',
              display: 'flex',
              justifyContent: 'space-between',
              '> p': {
                fontWeight: 'bold',
              },
            }}
          >
            <Typography>
              {headerText} {stateText}
            </Typography>
            <Box
              sx={{
                '> *': {
                  margin: '0.1rem',
                },
              }}
            >
              {shipment.is_adr_delivery && <Warning />}
              {shipment.requires_hoist && <PrecisionManufacturing />}
              {shipment.is_express_delivery && <Speed />}
              {shipment.requires_combination_vehicle && <RvHookup />}
            </Box>
            <DeliveryWindow
              onlyTime={true}
              startsAt={shipment.agreed_delivery_window_starts_at}
              endsAt={shipment.agreed_delivery_window_ends_at}
            />
          </Box>
          <Typography>
            {shipment.pickup_name}, {shipment.pickup_address}, {shipment.pickup_postal_code} {shipment.pickup_city}
          </Typography>
          <Typography>
            {shipment.delivery_name}, {shipment.delivery_address}, {shipment.delivery_postal_code}{' '}
            {shipment.delivery_city}
          </Typography>
          {shipment.note && <Typography fontWeight={'bold'}>Huom: {shipment.note}</Typography>}
        </Stack>
      </ListItemButton>
    </>
  );
};

const ShipmentList: React.FC<{
  shipments: Shipment[];
  loads: Load[];
  getOrganization: (id: string | null) => Organization | undefined;
  tab: number;
}> = ({ getOrganization, loads, shipments, tab }) => {
  const groupedShipments = groupBy(shipments, 'load_id');
  const orderedLoads = orderBy(loads, [
    (l) => shipments.filter((s) => s.load_id === l.id).every((s) => s.state === ShipmentStateEnum.Toimitettu),
    (l) => l.drive_date,
    'id',
  ]).map((l) => l.id);
  const sortedKeys = orderBy(keys(groupedShipments), (k) => orderedLoads.indexOf(Number(k)));

  return (
    <>
      {sortedKeys.map((key) => {
        const shipments = groupedShipments[key];
        const loadId = Number(key);
        const load = loads.find((load) => load?.id === loadId);
        const title = load && load.organization_id ? getOrganization(load.organization_id)?.name : '';
        const driveDate = load?.drive_date && DateTime.fromJSDate(load.drive_date);

        return (
          <Card
            key={loadId}
            sx={{
              marginBottom: '12rem',
              backgroundColor:
                tab === 3 && shipments.every((s) => s.state === ShipmentStateEnum.Toimitettu)
                  ? getShipmentStateUtils(shipments[0].state).backgroundColor
                  : undefined,
            }}
          >
            {driveDate && (
              <Alert
                icon={isToday(driveDate) ? false : <ErrorOutline />}
                severity={isToday(driveDate) ? 'info' : 'warning'}
                sx={{
                  fontWeight: 'bold',
                  '.MuiAlert-message': {
                    width: '100%',
                  },
                }}
              >
                <Box
                  sx={{
                    textTransform: 'uppercase',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>
                    {formatWeekDayLong(driveDate)}, {formatShortDate(driveDate)}
                  </span>
                  <span>{loadId}</span>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {load.weight_kg ? <span>{`${formatFloat(load.weight_kg)} kg`}</span> : null}
                  {load.length_ldm ? <span>{`${formatFloat(load.length_ldm)} lvm`}</span> : null}
                  {load.volume_m3 ? <span>{`${formatFloat(load.volume_m3)} m³`}</span> : null}
                </Box>
              </Alert>
            )}
            <Box
              sx={{
                padding: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: theme.palette.primary.dark,
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography variant="h4">{title}</Typography>
              </Box>
              {load?.note && (
                <Alert severity="info" sx={{ margin: 0 }}>
                  {load.note}
                </Alert>
              )}
            </Box>
            <List>
              {shipments.map((shipment, index) => (
                <React.Fragment key={shipment.id}>
                  <SingleShipment
                    shipment={shipment}
                    key={shipment.id}
                    loadOrganizationId={load?.organization_id}
                    tab={tab}
                    getOrganization={getOrganization}
                  />
                  {index !== shipments.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Card>
        );
      })}
    </>
  );
};

const TAB_QUERY_PARAMETER = 'tab';

function a11yProps(index: number) {
  return {
    id: `auto-tab-${index}`,
    'aria-controls': `auto-tabpanel-${index}`,
  };
}

interface TabBarProps extends React.HTMLAttributes<HTMLElement> {
  tab: number; // <Tabs> expects a number
  setTab: (tab: number) => void;
  shipments: Shipment[];
}

const TabBar: React.FC<TabBarProps> = ({ tab, setTab, shipments }) => {
  const readyForPickup = shipments.filter((s) => s.state === ShipmentStateEnum.Noudettavissa).length;
  const pickedUp = shipments.filter((s) => s.state === ShipmentStateEnum.Noudettu).length;
  const delivered = shipments.filter((s) => s.state === ShipmentStateEnum.Toimitettu).length;

  return (
    <Paper sx={{ position: 'fixed', zIndex: 1, bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation value={tab} onChange={(_value, event) => setTab(event)}>
        <BottomNavigationAction
          label="Noudettavat"
          icon={
            <Badge badgeContent={readyForPickup} color="secondary">
              <ListAlt />
            </Badge>
          }
          {...a11yProps(0)}
        />

        <BottomNavigationAction
          label="Noudetut"
          icon={
            <Badge badgeContent={pickedUp} color="secondary">
              <LocalShipping />
            </Badge>
          }
          {...a11yProps(1)}
        />
        <BottomNavigationAction
          label="Toimitetut"
          icon={
            <Badge badgeContent={delivered} color="secondary">
              <Done />
            </Badge>
          }
          {...a11yProps(2)}
        />
        <BottomNavigationAction label="Kaikki" icon={<FormatAlignCenter />} {...a11yProps(3)} />
      </BottomNavigation>
    </Paper>
  );
};

const DriverShipments: React.FC = () => {
  const [selectedState, setSelectedState] = useState<DriverStateEnum[]>([DriverStateEnum.Noudettu]);
  const currentUser = useCurrentUser();
  const [overview, setOverview] = useState<DriverOverview>({
    shipments: [],
    loads: [],
    shipmentRows: [],
  });
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [tab, setTab] = useState<number>(0);
  const refreshTimeMilliseconds = 60000;
  const [added, setAdded] = useState<Shipment[]>([]);
  const [removed, setRemoved] = useState<Shipment[]>([]);
  const firstRender = useRef(true);
  const [openAddedNotification, setOpenAddedNotification] = React.useState(false);
  const [openRemovedNotification, setOpenRemovedNotification] = React.useState(false);

  function changeTab(newTab: number) {
    const url = new URL(window.location.href);
    url.searchParams.set(TAB_QUERY_PARAMETER, String(newTab));
    window.history.replaceState(null, window.document.title, url.href);
    setSelectedState(
      newTab === 0
        ? [DriverStateEnum.Noudettavissa]
        : newTab === 1
          ? [DriverStateEnum.Noudettu]
          : newTab === 2
            ? [DriverStateEnum.Toimitettu]
            : [
                DriverStateEnum.Noudettavissa,
                DriverStateEnum.Noudettu,
                DriverStateEnum.Toimitettu,
                DriverStateEnum.EiVarastossa,
              ],
    );
    setTab(newTab);
  }

  const getOrganization = (id: string | null) => {
    if (id == null || organizations.length === 0) {
      return undefined;
    }
    return organizations.find((organization) => organization.id === id);
  };

  const closeAddedNotification = (_event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    //notification has to be closed by pressing a button
    if (reason === 'clickaway') {
      return;
    }
    setOpenAddedNotification(false);
  };

  const closeRemovedNotification = (_event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    //notification has to be closed by pressing a button
    if (reason === 'clickaway') {
      return;
    }
    setOpenRemovedNotification(false);
  };

  const loadOverview = async () => {
    if (!currentUser) {
      return;
    }

    const result = await api.drivers.getDailyDriverOverview({
      username: currentUser.username,
      driveDate: DateTime.now().toJSDate(),
      workingDays: 5,
    });

    const order = [
      ShipmentStateEnum.EiVarastossa,
      ShipmentStateEnum.Noudettavissa,
      ShipmentStateEnum.Noudettu,
      ShipmentStateEnum.Toimitettu,
    ];
    const orderedShipments = orderBy(result.shipments, [(s) => order.indexOf(s.state), 'order_in_load']);

    setOverview((state) => {
      if (firstRender.current !== true) {
        const added = differenceBy(result?.shipments ?? [], state.shipments, 'id');
        const removed = differenceBy(state.shipments, result?.shipments ?? [], 'id');
        setAdded(added);
        setRemoved(removed);
        setOpenAddedNotification(added.length > 0);
        setOpenRemovedNotification(removed.length > 0);
      }
      firstRender.current = false;

      return {
        loads: result.loads ?? [],
        shipments: (orderedShipments ?? []).map((shipment) => ({
          ...shipment,
          driverState: shipmentStateToDriverState(shipment.state),
        })),
        shipmentRows: result.shipment_rows ?? [],
      };
    });
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const urlTab = Number(url.searchParams.get(TAB_QUERY_PARAMETER)) ?? undefined;
    changeTab([0, 1, 2, 3].indexOf(urlTab) !== -1 ? urlTab : 0);
  }, []);

  useEffect(() => {
    const loadOrganizations = async () => {
      const organizations = await api.organizations.getOrganizations({});
      setOrganizations(organizations.data);
    };

    loadOrganizations();
  }, []);

  useEffect(() => {
    if (firstRender.current === true) {
      loadOverview();
    }
    const interval = setInterval(() => {
      loadOverview();
    }, refreshTimeMilliseconds);
    return () => clearInterval(interval);
  }, [currentUser]);

  if (!currentUser) {
    return <></>;
  }

  return (
    <Box>
      <TabBar tab={tab} setTab={changeTab} shipments={overview.shipments} />
      <Box
        sx={{
          marginBottom: '4rem',
          '> div': {
            margin: '0.5rem',
            marginBottom: '2rem',
          },
        }}
      >
        <ShipmentList
          getOrganization={getOrganization}
          loads={overview.loads}
          shipments={overview.shipments.filter((x) => selectedState.includes(x.driverState))}
          tab={tab}
        />
        <Snackbar open={openAddedNotification} onClose={closeAddedNotification}>
          <Alert onClose={closeAddedNotification} severity="info" variant="filled" sx={{ width: '100%' }}>
            <AlertTitle>Työtehtävissä uusia toimituksia</AlertTitle>
            <List>
              {added.map((s) => (
                <ListItemText key={s.id}>
                  {s.id} - {s.delivery_name}
                </ListItemText>
              ))}
            </List>
          </Alert>
        </Snackbar>
        <Snackbar open={openRemovedNotification} onClose={closeRemovedNotification}>
          <Alert onClose={closeRemovedNotification} severity="warning" variant="filled" sx={{ width: '100%' }}>
            <AlertTitle>Työtehtävistä poistettuja toimituksia</AlertTitle>
            <List>
              {removed.map((s) => (
                <ListItemText key={s.id}>
                  {s.id} - {s.delivery_name}
                </ListItemText>
              ))}
            </List>
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default DriverShipments;
