import { debounce, isBoolean } from 'lodash';
import { Dispatch } from 'react';
import { Driver, Organization, User } from '../../api';
import { validateFields } from './userValidation';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { canAccessUsers } from '../../utils';

export const canAccessManagedUser = (currentUser: User | undefined, fields: State['fields']): boolean => {
  if (currentUser?.username === fields.username.value || canAccessUsers(currentUser)) {
    return true;
  } else if (
    currentUser?.is_manager &&
    fields.organization_id.value === currentUser?.organization_id &&
    !fields.is_superuser.value &&
    !fields.is_coordinator.value &&
    !fields.is_admin.value &&
    !fields.is_multi_organization.value &&
    !fields.is_manager.value
  ) {
    return true;
  }
  return false;
};

export const canChangeManagedUserPassword = (queryingUser: User | undefined, userBeingEdited: User | undefined) => {
  return (
    queryingUser?.is_superuser ||
    queryingUser?.username === userBeingEdited?.username ||
    (queryingUser?.organization_id === HOST_ORGANIZATION &&
      !(
        queryingUser.is_manager &&
        userBeingEdited?.organization_id === HOST_ORGANIZATION &&
        (userBeingEdited?.is_superuser ||
          userBeingEdited?.is_coordinator ||
          userBeingEdited?.is_admin ||
          userBeingEdited?.is_multi_organization ||
          userBeingEdited?.is_manager)
      ))
  );
};

export interface Field<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value: Value;
}

type ServerFieldNames = keyof Pick<
  User,
  | 'username'
  | 'organization_id'
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'gsm'
  | 'is_admin'
  | 'is_coordinator'
  | 'is_multi_organization'
  | 'is_superuser'
  | 'is_workshop'
  | 'is_driver'
  | 'is_manager'
  | 'can_access_customer_report'
  | 'driver_id'
>;

type UIOnlyFields = {
  own_password: Field<string | null>;
  old_password: Field<string | null>;
  password: Field<string | null>;
  password_again: Field<string | null>;
};

export type FieldName = ServerFieldNames | keyof UIOnlyFields;

export interface State {
  fields: {
    [P in ServerFieldNames]: Field<User[P] | ''>;
  } & UIOnlyFields;
  originalUser?: User;
  isLoading: boolean;
  loadingMessage?: string;
  notification: NotificationType;
  isValid: boolean;
  organizations: Organization[];
  drivers: Driver[];
  users: User[];
}

export type Action =
  | {
      type: 'VALIDATE_FIELD';
      payload: {
        fieldName: keyof State['fields'];
      };
    }
  | {
      type: 'VALIDATE_FIELDS';
    }
  | {
      type: 'SET_FIELD_VALUE';
      payload: {
        fieldName: keyof State['fields'];
        value: FieldValue;
      };
    }
  | SetMessageAction
  | SetLoadingAction
  | {
      type: 'INITIALIZE_USER';
      payload: {
        user: User;
        isNewUser?: boolean;
      };
    }
  | {
      type: 'SET_ORGANIZATIONS';
      payload: {
        organizations: Organization[];
      };
    }
  | {
      type: 'SET_DRIVERS';
      payload: {
        drivers: Driver[];
      };
    }
  | {
      type: 'SET_USERS';
      payload: {
        users: User[];
      };
    };

const areAllFieldsValid = (state: Pick<State, 'fields'>) => {
  return !Object.values(state.fields).some((field) => field.hasError);
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD_VALUE': {
      const fields = {
        ...state.fields,
      };
      fields.driver_id.required =
        action.payload.fieldName === 'is_driver' &&
        isBoolean(action.payload.value) &&
        //when creating new user from createEmployee, driver_id isnt required
        window.location.href.includes('users')
          ? action.payload.value
          : fields.driver_id.required;
      fields[action.payload.fieldName].value = action.payload.value;
      return {
        ...state,
        fields,
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'VALIDATE_FIELDS': {
      const validatedState = validateFields(state);
      return {
        ...state,
        ...validatedState,
        isValid: areAllFieldsValid(validatedState),
      };
    }
    case 'INITIALIZE_USER': {
      const fields = {
        ...state.fields,
      };
      fields.username.value = action.payload.user.username ?? '';
      fields.organization_id.value = action.payload.user.organization_id ?? '';
      fields.first_name.value = action.payload.user.first_name ?? '';
      fields.last_name.value = action.payload.user.last_name ?? '';
      fields.email.value = action.payload.user.email ?? '';
      fields.gsm.value = action.payload.user.gsm ?? '';
      fields.is_admin.value = action.payload.user.is_admin ?? false;
      fields.is_coordinator.value = action.payload.user.is_coordinator ?? false;
      fields.is_multi_organization.value = action.payload.user.is_multi_organization ?? false;
      fields.is_superuser.value = action.payload.user.is_superuser ?? false;
      fields.is_workshop.value = action.payload.user.is_workshop ?? false;
      fields.is_driver.value = action.payload.user.is_driver ?? false;
      fields.is_manager.value = action.payload.user.is_manager ?? false;
      fields.can_access_customer_report.value = action.payload.user.can_access_customer_report ?? false;
      fields.driver_id.value = action.payload.user.driver_id ?? null;
      fields.driver_id.required = Boolean(action.payload.user.is_driver);
      fields.own_password.value = '';
      fields.old_password.value = '';
      fields.password.value = '';
      fields.password_again.value = '';
      return {
        ...state,
        fields,
        originalUser: action.payload.user as User,
        isValid: false,
      };
    }
    case 'SET_ORGANIZATIONS': {
      return {
        ...state,
        organizations: action.payload.organizations,
      };
    }
    case 'SET_DRIVERS': {
      return {
        ...state,
        drivers: action.payload.drivers,
      };
    }
    case 'SET_USERS': {
      return {
        ...state,
        users: action.payload.users,
      };
    }
    default: {
      throw new Error(`Unhandled action ${JSON.stringify(action)}`);
    }
  }
};

type FieldValue = string | boolean | number | null;

export const debouncedValidateFieldsDispatch = debounce((dispatch: Dispatch<Action>) => {
  dispatch({
    type: 'VALIDATE_FIELDS',
  });
}, 500);

export const updateFieldValue = (fieldName: FieldName, value: FieldValue, dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_FIELD_VALUE',
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
  debouncedValidateFieldsDispatch(dispatch);
};

export const getInitialState = (createNewUser: boolean, currentUser: User | undefined): State => {
  return {
    fields: {
      username: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      organization_id: {
        required: canAccessUsers(currentUser),
        hasError: false,
        feedback: undefined,
        value: '',
      },
      first_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      last_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      email: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      gsm: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      is_admin: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      is_coordinator: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      is_multi_organization: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      is_superuser: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      is_workshop: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      is_driver: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      is_manager: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      can_access_customer_report: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      driver_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      own_password: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      old_password: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      password: {
        required: createNewUser,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      password_again: {
        required: createNewUser,
        hasError: false,
        feedback: undefined,
        value: '',
      },
    },
    isLoading: false,
    notification: {
      message: null,
    },
    isValid: false,
    organizations: [],
    drivers: [],
    users: [],
  };
};
