/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A car pricing row that belongs to a car pricing table
 * @export
 * @interface CarPricingRow
 */
export interface CarPricingRow {
  /**
   *
   * @type {number}
   * @memberof CarPricingRow
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof CarPricingRow
   */
  car_pricing_table_id: number | null;
  /**
   *
   * @type {string}
   * @memberof CarPricingRow
   */
  car_type: CarPricingRowCarTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CarPricingRow
   */
  price: number;
}

/**
 * @export
 * @enum {string}
 */
export enum CarPricingRowCarTypeEnum {
  Kaappi = 'kaappi',
  Pikkuauto = 'pikkuauto',
  _3AksPilkki = '3-aks pilkki',
  _2AksPilkki = '2-aks pilkki',
  Pikkupilkki = 'pikkupilkki',
  Erikoispilkki = 'erikoispilkki',
  Lavetti = 'lavetti',
  _4AksPilkki = '4-aks pilkki',
}

export function CarPricingRowFromJSON(json: any): CarPricingRow {
  return CarPricingRowFromJSONTyped(json, false);
}

export function CarPricingRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarPricingRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    car_pricing_table_id: json['car_pricing_table_id'],
    car_type: json['car_type'],
    price: json['price'],
  };
}

export function CarPricingRowToJSON(value?: CarPricingRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    car_pricing_table_id: value.car_pricing_table_id,
    car_type: value.car_type,
    price: value.price,
  };
}
