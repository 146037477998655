/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of work hours.
 * @export
 * @interface TimeOff
 */
export interface TimeOff {
  /**
   *
   * @type {number}
   * @memberof TimeOff
   */
  readonly id: number;
  /**
   *
   * @type {Date}
   * @memberof TimeOff
   */
  date: Date | null;
  /**
   *
   * @type {number}
   * @memberof TimeOff
   */
  readonly driver_id: number;
  /**
   *
   * @type {number}
   * @memberof TimeOff
   */
  readonly time_off_type: number;
}

export function TimeOffFromJSON(json: any): TimeOff {
  return TimeOffFromJSONTyped(json, false);
}

export function TimeOffFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeOff {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    date: json['date'] === null ? null : new Date(json['date']),
    driver_id: json['driver_id'],
    time_off_type: json['time_off_type'],
  };
}

export function TimeOffToJSON(value?: TimeOff | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date === null ? null : value.date.toISOString(),
  };
}
