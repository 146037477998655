/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Assignment, AssignmentFromJSON, AssignmentFromJSONTyped, AssignmentToJSON } from './Assignment';

/**
 *
 * @export
 * @interface AssignmentResponseBody1
 */
export interface AssignmentResponseBody1 {
  /**
   *
   * @type {Assignment}
   * @memberof AssignmentResponseBody1
   */
  data?: Assignment;
}

export function AssignmentResponseBody1FromJSON(json: any): AssignmentResponseBody1 {
  return AssignmentResponseBody1FromJSONTyped(json, false);
}

export function AssignmentResponseBody1FromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignmentResponseBody1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, 'data') ? undefined : AssignmentFromJSON(json['data']),
  };
}

export function AssignmentResponseBody1ToJSON(value?: AssignmentResponseBody1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: AssignmentToJSON(value.data),
  };
}
