import React, { Dispatch, useEffect, useReducer, useState } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import { Action, getEmployeePostPutBodyFromState, getInitialState, reducer, State } from './employee.state';
import { api, DriverProfessionEnum, getAllPages } from '../../api';
import { useNavigate } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AllEmployeeFields } from './components/inputs';
import { FilterCheckbox } from '../../components/FilterCheckbox';
import { AllUserFields } from '../users/components/inputs';
import {
  FieldName as UserFieldName,
  getInitialState as userGetInitialState,
  reducer as userReducer,
  updateFieldValue as userUpdateFieldValue,
} from '../users/user.state';
import { FieldSet } from '../../components/StyledComponents/FieldSet';
import { load as userLoad, saveNewUser } from '../users/CreateUser';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const load = async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [driversResponse, usersResponse] = await Promise.all([
      getAllPages(api.drivers.getDrivers.bind(api.drivers), { alsoInactive: true }),
      getAllPages(api.users.getUsers.bind(api.users), {}),
    ]);
    dispatch({
      type: 'SET_EMPLOYEES',
      payload: {
        employees: driversResponse,
      },
    });
    dispatch({
      type: 'SET_USERS',
      payload: {
        users: usersResponse,
      },
    });
  } catch (err) {
    let message = 'Sivun lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Sivun lataus epäonnistui!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const saveNewEmployee = async (state: State, dispatch: Dispatch<Action>, navigate: ReturnType<typeof useNavigate>) => {
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });

  try {
    const employeeResponse = await api.drivers.createDriver({
      driverPostPutBody: getEmployeePostPutBodyFromState(state),
    });
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Työntekijä tallennettu!' } });
    setTimeout(() => {
      dispatch({ type: 'SET_LOADING', payload: false });
      navigate(`/employees/${employeeResponse.data?.id}`);
    }, 1000);
    return employeeResponse.data?.id;
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

const CreateEmployee: React.FC = () => {
  const currentUser = useCurrentUser();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const [userState, userDispatch] = useReducer(userReducer, userGetInitialState(true, currentUser));
  const navigate = useNavigate();
  const [createNewUser, setCreateNewUser] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser) {
      load(dispatch);
      if (createNewUser) {
        userLoad(userDispatch, currentUser);
        if (state.fields.profession.value === DriverProfessionEnum.Kuski) {
          userUpdateFieldValue('is_driver' as UserFieldName, true, userDispatch);
        }
      }
    }
  }, [currentUser, createNewUser]);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title="Uusi työntekijä">
            <SaveButton
              disabled={
                !state.isValid ||
                state.isLoading ||
                (createNewUser && !userState.isValid) ||
                (createNewUser && userState.isLoading)
              }
              id="save-employee-button"
              tooltip={
                !state.isValid || (createNewUser && !userState.isValid)
                  ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä'
                  : ''
              }
              onClick={async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (currentUser) {
                  const newEmployeeId = await saveNewEmployee(state, dispatch, navigate);
                  if (createNewUser && newEmployeeId) {
                    const newUserState = {
                      ...userState,
                      fields: {
                        ...userState.fields,
                        driver_id: { ...userState.fields.driver_id, value: newEmployeeId },
                      },
                    };
                    saveNewUser(newUserState, userDispatch, navigate, currentUser);
                  }
                }
                event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
              }}
            >
              {createNewUser ? 'Luo uusi työntekijä ja käyttäjä' : 'Luo uusi työntekijä'}
            </SaveButton>
            <FilterCheckbox
              disabled={state.isLoading || (!createNewUser && userState.isLoading)}
              label={'Luo myös uusi käyttäjä'}
              checked={createNewUser}
              onChange={(_e) => {
                setCreateNewUser(!createNewUser);
              }}
              name="also-create-new-user-checkbox"
              className="also-create-new-user-checkbox"
            />
          </Header>
        </HeaderContainer>
        <AllEmployeeFields state={state} dispatch={dispatch} userDispatch={userDispatch} createNewEmployee={true} />
        {createNewUser ? (
          <FieldSet style={{ width: 'fit-content' }}>
            <legend>Käyttäjätiedot</legend>
            <AllUserFields
              state={userState}
              dispatch={userDispatch}
              currentUser={currentUser}
              createNewUser={true}
              alsoCreateEmployee={true}
            />
          </FieldSet>
        ) : null}
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default CreateEmployee;
