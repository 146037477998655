import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { api, Driver, DriverGroup, getAllPages } from '../../api';
import { FieldSet } from '../../components/StyledComponents/FieldSet';
import { SaveButton } from '../../components/SaveButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { DriverSelector } from '../../components/DriverSelector';
import Main from '../../components/Main';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from '../../components/Notification';
import { Loading } from '../../components/Loading';
import { Header } from '../../components/Header';
import { sortBy } from 'lodash';

interface EditGroupDialogProps {
  open: boolean;
  onClose: () => void;
  driverGroup: DriverGroup | null;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  disabled: boolean;
  allDrivers: Map<number, Driver>;
  setNotification: Dispatch<SetStateAction<NotificationType>>;
  isNew: boolean;
}

const EditGroupDialog: React.FC<EditGroupDialogProps> = ({
  driverGroup,
  open,
  onClose,
  isLoading,
  setIsLoading,
  disabled,
  setNotification,
  isNew,
}) => {
  const [driversInGroup, setDriversInGroup] = useState<Array<Driver> | null>(null);
  const [originalGroup, setOriginalGroup] = useState<Set<Driver['id']>>(new Set());
  const [modifiedGroup, setModifiedGroup] = useState({ name: '', description: '' });

  useEffect(() => {
    if (driverGroup === null) {
      return;
    }

    const fetchDriversInGroup = async () => {
      setIsLoading(true);
      try {
        const response = await api.driverGroups.getDriverGroup({ driverGroupId: driverGroup.id });
        if (response.data.drivers) {
          setDriversInGroup(sortBy(response.data.drivers, ['last_name', 'first_name']));
          setOriginalGroup(new Set(response.data.drivers.map((d) => d.id)));
        }
      } finally {
        setIsLoading(false);
      }
    };

    setModifiedGroup({ name: driverGroup.name, description: driverGroup.description || '' });
    fetchDriversInGroup();
  }, [driverGroup]);

  if (driverGroup === null || driversInGroup === null) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={async () => {
        if (isNew) {
          await api.driverGroups.deleteDriverGroup({ driverGroupId: driverGroup.id });
        }
        onClose();
      }}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>
        <>
          {!isNew ? driverGroup.name : 'Luo uusi ryhmä'}
          {!isNew && (
            <Button
              color="error"
              sx={{ float: 'right' }}
              onClick={async () => {
                setIsLoading(true);
                try {
                  await api.driverGroups.deleteDriverGroup({ driverGroupId: driverGroup.id });
                  setNotification({ message: `Ryhmä "${driverGroup?.name}" poistettu` });
                } finally {
                  setIsLoading(false);
                }
                onClose();
              }}
              disabled={disabled}
            >
              Poista ryhmä
            </Button>
          )}
        </>
      </DialogTitle>
      <DialogContent>
        <FieldSet>
          <TextField
            disabled={disabled}
            required={true}
            name="group-name"
            label="Ryhmän nimi"
            value={modifiedGroup.name}
            onChange={(event) => setModifiedGroup({ ...modifiedGroup, name: event.target.value })}
          />
          <TextField
            disabled={disabled}
            required={true}
            name="group-description"
            label="Ryhmän kuvaus"
            value={modifiedGroup.description}
            onChange={(event) => setModifiedGroup({ ...modifiedGroup, description: event.target.value })}
          />
        </FieldSet>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <DriverSelector initialSelectedDrivers={driversInGroup} onDriversSelected={setDriversInGroup} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            if (isNew) {
              await api.driverGroups.deleteDriverGroup({ driverGroupId: driverGroup.id });
            }
            onClose();
          }}
        >
          Peruuta
        </Button>
        <SaveButton
          disabled={!modifiedGroup.name}
          tooltip={!modifiedGroup.name ? 'Nimi on pakollinen' : undefined}
          onClick={async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIsLoading(true);
            try {
              if (driverGroup.name !== modifiedGroup.name || driverGroup.description !== modifiedGroup.description) {
                await api.driverGroups.updateDriverGroup({
                  driverGroupId: driverGroup.id,
                  driverGroupPostBody: {
                    name: modifiedGroup.name,
                    description: modifiedGroup.description,
                  },
                });
              }

              const driversInGroupIdSet = new Set(driversInGroup.map((driver) => driver.id));

              const addedDrivers = Array.from(driversInGroupIdSet).filter((driverId) => !originalGroup.has(driverId));
              const deletedDrivers = Array.from(originalGroup).filter((driverId) => !driversInGroupIdSet.has(driverId));

              if (addedDrivers.length > 0) {
                await api.driverGroups.updateDriverGroupDrivers({
                  driverGroupId: driverGroup.id,
                  driverGroupDriversEditBody: {
                    drivers: addedDrivers,
                  },
                });
              }
              if (deletedDrivers.length > 0) {
                await api.driverGroups.deleteDriverGroupDrivers({
                  driverGroupId: driverGroup.id,
                  driverGroupDriversEditBody: {
                    drivers: deletedDrivers,
                  },
                });
              }
              setNotification({ message: 'Ryhmä tallennettu' });
            } catch {
              setNotification({ message: 'Ryhmän tallennus epäonnistui', severity: 'error' });
            } finally {
              event.stopPropagation();
              setIsLoading(false);
            }
            onClose();
          }}
        >
          Tallenna ryhmä
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};

const ShowDriverGroups: React.FC<{
  driverGroups: Array<DriverGroup>;
  disabled: boolean;
  selectDriverGroup: (driverGroup: DriverGroup) => void;
}> = ({ driverGroups, disabled, selectDriverGroup }) => {
  return driverGroups.map((driverGroup: any) => (
    <ListItem alignItems="flex-start" key={driverGroup.id}>
      <ListItemText primary={driverGroup.name} secondary={driverGroup.description} />{' '}
      <Tooltip title={`Muokkaa "${driverGroup.name}" ryhmää`}>
        <span>
          <IconButton onClick={() => selectDriverGroup(driverGroup)} disabled={disabled}>
            <Edit />
          </IconButton>
        </span>
      </Tooltip>
    </ListItem>
  ));
};

const EditDriverGroups: React.FC = () => {
  const [driverGroups, setDriverGroups] = React.useState<Array<DriverGroup>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [allDrivers, setAllDrivers] = React.useState<Map<number, Driver>>(new Map());
  const [selectedDriverGroup, setSelectedDriverGroup] = React.useState<DriverGroup | null>(null);
  const [notification, setNotification] = React.useState<NotificationType>({ message: null });
  const [isNew, setIsNew] = React.useState<boolean>(false);
  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };

  const currentUser = useCurrentUser();

  const fetchGroups = async () => {
    const driverGroups = await api.driverGroups.getAllDriverGroups();
    setDriverGroups(driverGroups.data);
  };
  const fetchDrivers = async () => {
    const allDrivers = await getAllPages(api.drivers.getDrivers.bind(api.drivers), {});
    const driversMap = new Map(allDrivers.map((driver) => [driver.id, driver]));
    setAllDrivers(driversMap);
  };
  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchGroups(), fetchDrivers()])
      .catch(() => setNotification({ message: 'Virhe haettaessa työntekijäryhmiä', severity: 'error' }))
      .finally(() => setIsLoading(false));
  }, []);

  if (!currentUser) {
    return null;
  }

  return (
    <Main>
      <Loading isLoading={isLoading} />
      <Header title={'Työntekijäryhmät'}>
        <Button
          onClick={async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIsLoading(true);
            try {
              const result = await api.driverGroups.addDriverGroup({
                driverGroupsPostBody: {
                  name: '',
                  description: '',
                },
              });
              setSelectedDriverGroup(result.data);
              setIsNew(true);
            } catch (err) {
              console.log(err);
              setNotification({ message: 'Ryhmän luonti epäonnistui', severity: 'error' });
            } finally {
              event.stopPropagation();
              setIsLoading(false);
            }
          }}
        >
          Luo ryhmä
        </Button>
      </Header>
      <ShowDriverGroups driverGroups={driverGroups} disabled={isLoading} selectDriverGroup={setSelectedDriverGroup} />
      <EditGroupDialog
        open={selectedDriverGroup !== null}
        onClose={async () => {
          setIsNew(false);
          setIsLoading(true);
          setSelectedDriverGroup(null);
          await fetchGroups();
          setIsLoading(false);
        }}
        driverGroup={selectedDriverGroup}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        allDrivers={allDrivers}
        disabled={isLoading}
        setNotification={setNotification}
        isNew={isNew}
      />
      <Notification {...snackbarProps} />
    </Main>
  );
};
export default EditDriverGroups;
