import { type ChangeEvent, useState } from 'react';
import { type TextFieldProps } from '@mui/material/TextField';
import { parseFromValuesOrFunc } from './utils';
import { MRT_RowData, MRT_Cell, MRT_TableInstance } from 'material-react-table';
import React from 'react';
import { FormControl, Input, FormHelperText } from '@mui/material';

export interface MRT_MRTEditNumberInputProps<TData extends MRT_RowData> extends TextFieldProps<'standard'> {
  cell: MRT_Cell<TData>;
  table: MRT_TableInstance<TData>;
}

export const MRT_EditNumberInput = <TData extends MRT_RowData>({
  cell,
  table,
  ...rest
}: MRT_MRTEditNumberInputProps<TData>) => {
  const {
    getState,
    options: { createDisplayMode, editDisplayMode, muiEditTextFieldProps },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingRow,
  } = table;
  const { column, row } = cell;
  const { columnDef } = column;
  const { creatingRow, editingRow } = getState();

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;

  const [value, setValue] = useState(() => cell.getValue<number>());

  const textFieldProps: TextFieldProps = {
    ...parseFromValuesOrFunc(muiEditTextFieldProps, {
      cell,
      column,
      row,
      table,
    }),
    ...parseFromValuesOrFunc(columnDef.muiEditTextFieldProps, {
      cell,
      column,
      row,
      table,
    }),
    ...rest,
  };

  const saveInputValueToRowCache = (newValue: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    row._valuesCache[column.id] = newValue;
    if (isCreating) {
      setCreatingRow(row);
    } else if (isEditing) {
      setEditingRow(row);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ? parseFloat(event.target.value) : 0;
    textFieldProps.onChange?.(event);
    setValue(value);
    saveInputValueToRowCache(value);
  };

  return (
    <FormControl error={textFieldProps.error}>
      <Input
        disabled={parseFromValuesOrFunc(columnDef.enableEditing, row) === false}
        fullWidth
        inputRef={(inputRef) => {
          if (inputRef) {
            editInputRefs.current[column.id] = inputRef;
            if (textFieldProps.inputRef) {
              textFieldProps.inputRef = inputRef;
            }
          }
        }}
        name={column.id}
        placeholder={
          !['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
            ? columnDef.header
            : undefined
        }
        type="number"
        size="small"
        value={value ?? ''}
        inputProps={{
          autoComplete: 'off',
        }}
        onChange={handleChange}
        style={{ fontSize: '0.875rem', lineHeight: '150%' }}
      />
      {textFieldProps.helperText ? <FormHelperText>{textFieldProps.helperText}</FormHelperText> : null}
    </FormControl>
  );
};
