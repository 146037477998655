/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DriverGroupPostBody
 */
export interface DriverGroupPostBody {
  /**
   *
   * @type {string}
   * @memberof DriverGroupPostBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DriverGroupPostBody
   */
  description: string;
}

export function DriverGroupPostBodyFromJSON(json: any): DriverGroupPostBody {
  return DriverGroupPostBodyFromJSONTyped(json, false);
}

export function DriverGroupPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverGroupPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    description: json['description'],
  };
}

export function DriverGroupPostBodyToJSON(value?: DriverGroupPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
  };
}
