/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CarPutBody
 */
export interface CarPutBody {
  /**
   *
   * @type {number}
   * @memberof CarPutBody
   */
  readonly id?: number;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  licence_plate: string;
  /**
   *
   * @type {boolean}
   * @memberof CarPutBody
   */
  is_active: boolean;
  /**
   *
   * @type {number}
   * @memberof CarPutBody
   */
  default_driver_id: number | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  car_identifier: string | null;
  /**
   *
   * @type {number}
   * @memberof CarPutBody
   */
  car_number: number | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  type: CarPutBodyTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  imei: string | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  vin_number: string | null;
  /**
   *
   * @type {Date}
   * @memberof CarPutBody
   */
  first_registeration_date: Date | null;
  /**
   *
   * @type {Date}
   * @memberof CarPutBody
   */
  vehicle_inspection_date: Date | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  transport_licence: string | null;
  /**
   *
   * @type {Date}
   * @memberof CarPutBody
   */
  transport_licence_date: Date | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  tyres: string | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  hoist: string | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  platform_details: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CarPutBody
   */
  is_adr: boolean;
  /**
   *
   * @type {number}
   * @memberof CarPutBody
   */
  unladen_weight: number | null;
  /**
   *
   * @type {number}
   * @memberof CarPutBody
   */
  gross_weight: number | null;
  /**
   *
   * @type {number}
   * @memberof CarPutBody
   */
  height: number | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  fuel_card: string | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  fuel_id: string | null;
  /**
   *
   * @type {Date}
   * @memberof CarPutBody
   */
  hoist_inspection_date: Date | null;
  /**
   *
   * @type {string}
   * @memberof CarPutBody
   */
  note: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CarPutBody
   */
  is_coordination: boolean;
  /**
   *
   * @type {number}
   * @memberof CarPutBody
   */
  default_trailer_id: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum CarPutBodyTypeEnum {
  Kaappi = 'kaappi',
  Pikkuauto = 'pikkuauto',
  _3AksPilkki = '3-aks pilkki',
  _2AksPilkki = '2-aks pilkki',
  Pikkupilkki = 'pikkupilkki',
  Erikoispilkki = 'erikoispilkki',
  Lavetti = 'lavetti',
  _4AksPilkki = '4-aks pilkki',
  Pervaunu = 'perävaunu',
}

export function CarPutBodyFromJSON(json: any): CarPutBody {
  return CarPutBodyFromJSONTyped(json, false);
}

export function CarPutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarPutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    licence_plate: json['licence_plate'],
    is_active: json['is_active'],
    default_driver_id: json['default_driver_id'],
    car_identifier: json['car_identifier'],
    car_number: json['car_number'],
    manufacturer: json['manufacturer'],
    model: json['model'],
    type: json['type'],
    imei: json['imei'],
    vin_number: json['vin_number'],
    first_registeration_date:
      json['first_registeration_date'] === null ? null : new Date(json['first_registeration_date']),
    vehicle_inspection_date:
      json['vehicle_inspection_date'] === null ? null : new Date(json['vehicle_inspection_date']),
    transport_licence: json['transport_licence'],
    transport_licence_date: json['transport_licence_date'] === null ? null : new Date(json['transport_licence_date']),
    tyres: json['tyres'],
    hoist: json['hoist'],
    platform_details: json['platform_details'],
    is_adr: json['is_adr'],
    unladen_weight: json['unladen_weight'],
    gross_weight: json['gross_weight'],
    height: json['height'],
    fuel_card: json['fuel_card'],
    fuel_id: json['fuel_id'],
    hoist_inspection_date: json['hoist_inspection_date'] === null ? null : new Date(json['hoist_inspection_date']),
    note: json['note'],
    is_coordination: json['is_coordination'],
    default_trailer_id: json['default_trailer_id'],
  };
}

export function CarPutBodyToJSON(value?: CarPutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    licence_plate: value.licence_plate,
    is_active: value.is_active,
    default_driver_id: value.default_driver_id,
    car_identifier: value.car_identifier,
    car_number: value.car_number,
    manufacturer: value.manufacturer,
    model: value.model,
    type: value.type,
    imei: value.imei,
    vin_number: value.vin_number,
    first_registeration_date:
      value.first_registeration_date === null ? null : value.first_registeration_date.toISOString(),
    vehicle_inspection_date:
      value.vehicle_inspection_date === null ? null : value.vehicle_inspection_date.toISOString(),
    transport_licence: value.transport_licence,
    transport_licence_date: value.transport_licence_date === null ? null : value.transport_licence_date.toISOString(),
    tyres: value.tyres,
    hoist: value.hoist,
    platform_details: value.platform_details,
    is_adr: value.is_adr,
    unladen_weight: value.unladen_weight,
    gross_weight: value.gross_weight,
    height: value.height,
    fuel_card: value.fuel_card,
    fuel_id: value.fuel_id,
    hoist_inspection_date: value.hoist_inspection_date === null ? null : value.hoist_inspection_date.toISOString(),
    note: value.note,
    is_coordination: value.is_coordination,
    default_trailer_id: value.default_trailer_id,
  };
}
