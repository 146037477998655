/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CarPostBody,
  CarPostBodyFromJSON,
  CarPostBodyToJSON,
  CarPutBody,
  CarPutBodyFromJSON,
  CarPutBodyToJSON,
  CarResponseBody,
  CarResponseBodyFromJSON,
  CarResponseBodyToJSON,
  CarsResponseBody,
  CarsResponseBodyFromJSON,
  CarsResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

export interface CarsApiCreateCarRequest {
  carPostBody: CarPostBody;
}

export interface CarsApiDeleteCarRequest {
  carId: number;
}

export interface CarsApiGetCarRequest {
  carId: number;
}

export interface CarsApiGetCarByLicencePlateRequest {
  carLicencePlate: string;
}

export interface CarsApiGetCarsRequest {
  alsoInactive?: boolean;
  next?: number;
}

export interface CarsApiUpdateCarRequest {
  carId: number;
  carPutBody: CarPutBody;
}

/**
 *
 */
export class CarsApi extends runtime.BaseAPI {
  /**
   * Create new car.
   */
  async createCarRaw(
    requestParameters: CarsApiCreateCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CarResponseBody>> {
    if (requestParameters.carPostBody === null || requestParameters.carPostBody === undefined) {
      throw new runtime.RequiredError(
        'carPostBody',
        'Required parameter requestParameters.carPostBody was null or undefined when calling createCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/cars`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarPostBodyToJSON(requestParameters.carPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create new car.
   */
  async createCar(requestParameters: CarsApiCreateCarRequest, initOverrides?: RequestInit): Promise<CarResponseBody> {
    const response = await this.createCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a car by id.
   */
  async deleteCarRaw(
    requestParameters: CarsApiDeleteCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling deleteCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/cars/{car_id}`.replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a car by id.
   */
  async deleteCar(requestParameters: CarsApiDeleteCarRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteCarRaw(requestParameters, initOverrides);
  }

  /**
   * Get a car by id.
   */
  async getCarRaw(
    requestParameters: CarsApiGetCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CarResponseBody>> {
    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling getCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/cars/{car_id}`.replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a car by id.
   */
  async getCar(requestParameters: CarsApiGetCarRequest, initOverrides?: RequestInit): Promise<CarResponseBody> {
    const response = await this.getCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a car by licence plate number.
   */
  async getCarByLicencePlateRaw(
    requestParameters: CarsApiGetCarByLicencePlateRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CarResponseBody>> {
    if (requestParameters.carLicencePlate === null || requestParameters.carLicencePlate === undefined) {
      throw new runtime.RequiredError(
        'carLicencePlate',
        'Required parameter requestParameters.carLicencePlate was null or undefined when calling getCarByLicencePlate.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/cars/by_licence_plate/{car_licence_plate}`.replace(
          `{${'car_licence_plate'}}`,
          encodeURIComponent(String(requestParameters.carLicencePlate)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a car by licence plate number.
   */
  async getCarByLicencePlate(
    requestParameters: CarsApiGetCarByLicencePlateRequest,
    initOverrides?: RequestInit,
  ): Promise<CarResponseBody> {
    const response = await this.getCarByLicencePlateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all cars.
   */
  async getCarsRaw(
    requestParameters: CarsApiGetCarsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CarsResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.alsoInactive !== undefined) {
      queryParameters['also_inactive'] = requestParameters.alsoInactive;
    }

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/cars`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all cars.
   */
  async getCars(requestParameters: CarsApiGetCarsRequest, initOverrides?: RequestInit): Promise<CarsResponseBody> {
    const response = await this.getCarsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update a car by id.
   */
  async updateCarRaw(
    requestParameters: CarsApiUpdateCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CarResponseBody>> {
    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling updateCar.',
      );
    }

    if (requestParameters.carPutBody === null || requestParameters.carPutBody === undefined) {
      throw new runtime.RequiredError(
        'carPutBody',
        'Required parameter requestParameters.carPutBody was null or undefined when calling updateCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/cars/{car_id}`.replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarPutBodyToJSON(requestParameters.carPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarResponseBodyFromJSON(jsonValue));
  }

  /**
   * Update a car by id.
   */
  async updateCar(requestParameters: CarsApiUpdateCarRequest, initOverrides?: RequestInit): Promise<CarResponseBody> {
    const response = await this.updateCarRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
