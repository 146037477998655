/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A car pricing row that belongs to a car pricing table
 * @export
 * @interface CarPricingRowPostPutBody
 */
export interface CarPricingRowPostPutBody {
  /**
   *
   * @type {number}
   * @memberof CarPricingRowPostPutBody
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CarPricingRowPostPutBody
   */
  car_pricing_table_id: number | null;
  /**
   *
   * @type {string}
   * @memberof CarPricingRowPostPutBody
   */
  car_type: CarPricingRowPostPutBodyCarTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CarPricingRowPostPutBody
   */
  price?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum CarPricingRowPostPutBodyCarTypeEnum {
  Kaappi = 'kaappi',
  Pikkuauto = 'pikkuauto',
  _3AksPilkki = '3-aks pilkki',
  _2AksPilkki = '2-aks pilkki',
  Pikkupilkki = 'pikkupilkki',
  Erikoispilkki = 'erikoispilkki',
  Lavetti = 'lavetti',
  _4AksPilkki = '4-aks pilkki',
}

export function CarPricingRowPostPutBodyFromJSON(json: any): CarPricingRowPostPutBody {
  return CarPricingRowPostPutBodyFromJSONTyped(json, false);
}

export function CarPricingRowPostPutBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CarPricingRowPostPutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    car_pricing_table_id: json['car_pricing_table_id'],
    car_type: json['car_type'],
    price: !exists(json, 'price') ? undefined : json['price'],
  };
}

export function CarPricingRowPostPutBodyToJSON(value?: CarPricingRowPostPutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    car_pricing_table_id: value.car_pricing_table_id,
    car_type: value.car_type,
    price: value.price,
  };
}
