import { useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu, { type MenuProps } from '@mui/material/Menu';
import { MRT_RowData, MRT_TableInstance, MRT_Column, getDefaultColumnOrderIds } from 'material-react-table';
import React from 'react';
import { MRT_ShowHideColumnsMenuItems } from './MRTShowHideColumnsMenuItems';
import { IconButton, Tooltip } from '@mui/material';
import {
  Clear,
  FilterList,
  FilterListOff,
  PushPin,
  Settings,
  SortByAlpha,
  ToggleOff,
  ToggleOn,
} from '@mui/icons-material';
import { ColumnLayouts } from './MRTColumnLayouts';

export interface MRT_ShowHideColumnsMenuProps<TData extends MRT_RowData> extends Partial<MenuProps> {
  anchorEl: HTMLElement | null;
  isSubMenu?: boolean;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  table: MRT_TableInstance<TData>;
  viewId: string;
}

export const MRT_ShowHideColumnsMenu = <TData extends MRT_RowData>({
  anchorEl,
  setAnchorEl,
  table,
  viewId,
  ...rest
}: MRT_ShowHideColumnsMenuProps<TData>) => {
  const {
    getAllColumns,
    getAllLeafColumns,
    getCenterLeafColumns,
    getIsAllColumnsVisible,
    getIsSomeColumnsPinned,
    getLeftLeafColumns,
    getRightLeafColumns,
    getState,
    options: {
      enableColumnOrdering,
      enableColumnPinning,
      enableHiding,
      localization,
      mrtTheme: { menuBackgroundColor },
    },
    resetColumnFilters,
    setShowColumnFilters,
  } = table;
  const { columnOrder, columnPinning, density } = getState();

  const handleToggleAllColumns = (value?: boolean) => {
    getAllLeafColumns()
      .filter((col) => col.columnDef.enableHiding !== false)
      .forEach((col) => col.toggleVisibility(value));
  };

  const allColumns = useMemo(() => {
    const columns = getAllColumns();
    if (columnOrder.length > 0 && !columns.some((col) => col.columnDef.columnDefType === 'group')) {
      return [
        ...getLeftLeafColumns(),
        ...Array.from(new Set(columnOrder)).map((colId) => getCenterLeafColumns().find((col) => col?.id === colId)),
        ...getRightLeafColumns(),
      ].filter(Boolean);
    }
    return columns;
  }, [
    columnOrder,
    columnPinning,
    getAllColumns(),
    getCenterLeafColumns(),
    getLeftLeafColumns(),
    getRightLeafColumns(),
  ]) as MRT_Column<TData>[];

  const isNestedColumns = allColumns.some((col) => col.columnDef.columnDefType === 'group');

  const [hoveredColumn, setHoveredColumn] = useState<MRT_Column<TData> | null>(null);

  const [edit, setEdit] = useState(false);

  const popoverActions = useRef(null);

  return (
    <Menu
      MenuListProps={{
        dense: density === 'compact',
        sx: {
          backgroundColor: menuBackgroundColor,
        },
      }}
      anchorEl={anchorEl}
      disableScrollLock
      onClose={() => {
        setEdit(false);
        setAnchorEl(null);
      }}
      open={!!anchorEl}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          minWidth: '35rem',
          p: '0.5rem',
          pt: 0,
        }}
      >
        <Tooltip title="Muokkaa sarakepohjia">
          <IconButton onClick={() => setEdit(!edit)}>
            <Settings />
          </IconButton>
        </Tooltip>
        {enableHiding && (
          <Tooltip title={getIsAllColumnsVisible() ? localization.hideAll : localization.showAll}>
            <IconButton onClick={() => handleToggleAllColumns(!getIsAllColumnsVisible())}>
              {getIsAllColumnsVisible() ? <ToggleOn /> : <ToggleOff />}
            </IconButton>
          </Tooltip>
        )}
        {enableColumnOrdering && (
          <Tooltip title={localization.resetOrder}>
            <IconButton onClick={() => table.setColumnOrder(getDefaultColumnOrderIds(table.options, true))}>
              <SortByAlpha />
            </IconButton>
          </Tooltip>
        )}
        {enableColumnPinning && (
          <Tooltip title={localization.unpinAll}>
            <span>
              <IconButton disabled={!getIsSomeColumnsPinned()} onClick={() => table.resetColumnPinning(true)}>
                <PushPin />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title={localization.clearFilter}>
          <IconButton onClick={() => resetColumnFilters()}>
            <Clear />
          </IconButton>
        </Tooltip>
        <Tooltip title={localization.showHideFilters}>
          <IconButton onClick={() => setShowColumnFilters(!table.getState().showColumnFilters)}>
            {getState().showColumnFilters ? <FilterList /> : <FilterListOff />}
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <ColumnLayouts columns={allColumns} viewId={viewId} isEditing={edit} popoverActions={popoverActions} />
      {allColumns.map((column, index) => (
        <MRT_ShowHideColumnsMenuItems
          allColumns={allColumns}
          column={column}
          hoveredColumn={hoveredColumn}
          isNestedColumns={isNestedColumns}
          key={`${index}-${column.id}`}
          setHoveredColumn={setHoveredColumn}
          table={table}
        />
      ))}
    </Menu>
  );
};
