import { jsPDF } from 'jspdf';
import { Driver, OrganizationDriver, Shipment, ShipmentRow } from '../api';
import WaybillImage from '../../static/img/waybill.png';
import { formatDate } from '../formatters';
import JsBarcode from 'jsbarcode';

const firstRow = 15;

const firstColumn = 18;
const secondColumn = 87;
const thirdColumn = 110;
const fourthColumn = 155;

const shipmentRowSummaryHeight = 181;

export const downloadWaybill = (
  shipment: Shipment,
  rows: ShipmentRow[],
  driver?: Driver | OrganizationDriver,
): void => {
  const doc = new jsPDF({
    compress: true,
  });

  doc.setFontSize(8);

  const getShipmentRows = (row: ShipmentRow, index: number, positionFromTopInPixels: number) => {
    const yPosition = positionFromTopInPixels + index * 3;
    const rowNameMaxLength = 38;
    const formattedRowName =
      row.name && row.name.length >= rowNameMaxLength ? row.name.substring(0, rowNameMaxLength) + '...' : row.name;
    doc.text(row.parcel_id ?? '', firstColumn, yPosition);
    doc.text(row.quantity ? String(row.quantity) : ('' + row.parcel_type ?? ''), 65, yPosition);
    doc.text(formattedRowName ?? '', 86, yPosition);
    doc.text(row.total_weight_kg ? String(row.total_weight_kg) : '', 157, yPosition);
    doc.text(row.volume_m3 ? String(row.volume_m3) : '', 180, yPosition);
  };

  const getShipmentRowsInPages = (rows: ShipmentRow[], shipment: Shipment, pageSize: number) => {
    const allPagesOfShipmentRowsPerPage = [];
    for (let i = 0; i < rows.length; i += pageSize) {
      const shipmentRowsPerPage = rows.slice(i, i + pageSize);
      allPagesOfShipmentRowsPerPage.push(shipmentRowsPerPage);
    }
    allPagesOfShipmentRowsPerPage.map((shipmentRowsPerPage, index) => {
      doc.addPage();
      doc.text(`RAHTIKIRJAN ${shipment.reference_number} TOIMITUSRIVILUETTELO`, firstColumn, 10);
      doc.text(`Sivu ${index + 1}/${allPagesOfShipmentRowsPerPage.length}`, 180, 10);
      doc.text('Merkki / nro', firstColumn, 15);
      doc.text('Kolliluku tai -laji', 65, 15);
      doc.text('Sisältö, ulkomitat ja VAK-merkinnät', 86, 15);
      doc.text('Brutto, kg', 157, 15);
      doc.text('Tilavuus, m3', 180, 15);
      {
        shipmentRowsPerPage.map((shipmentRow, index) => {
          getShipmentRows(shipmentRow, index, 18);
        });
      }
    });
  };

  //Background image
  doc.addImage(WaybillImage, 'PNG', 0, 0, 210, 295);

  //Barcode
  const canvas = document.createElement('canvas');
  if (shipment.reference_number) {
    JsBarcode(canvas, shipment.reference_number, {
      format: 'CODE39',
      displayValue: false,
      valid: (valid) => valid,
    });
  }
  const barcode = canvas.toDataURL();
  doc.addImage(barcode, 'PNG', thirdColumn, 61, 70, 15);

  //Date, reference number, job number and id
  doc.text(
    shipment.agreed_delivery_window_starts_at ? formatDate(shipment.agreed_delivery_window_starts_at) : '',
    thirdColumn,
    firstRow,
  );
  doc.text(shipment.reference_number ?? '', fourthColumn, firstRow);
  doc.text(shipment.job_number ?? '', secondColumn, firstRow);
  doc.text(shipment.id ? String(shipment.id) : '', thirdColumn, 23);

  // Pick up
  doc.text(shipment.pickup_name ?? '', firstColumn, firstRow);
  doc.text(shipment.pickup_address ?? '', firstColumn, 18);
  doc.text(`${shipment.pickup_postal_code ?? ''} ${shipment.pickup_city ?? ''}`, firstColumn, 21);
  doc.text(shipment.pickup_phone_number ?? '', firstColumn, 24);

  // Delivery
  doc.text(shipment.delivery_name ?? '', firstColumn, 40);
  doc.text(shipment.delivery_address ?? '', firstColumn, 43);
  doc.text(`${shipment.delivery_postal_code ?? ''} ${shipment.delivery_city ?? ''}` ?? '', firstColumn, 46);
  doc.text(shipment.delivery_phone_number ?? '', firstColumn, 49);

  //Shipment row summary
  const shipmentRowQuantities = rows.reduce((partialSum, row) => partialSum + (row.quantity ?? 0), 0);

  doc.text(shipmentRowQuantities ? String(shipmentRowQuantities) : '', 65, shipmentRowSummaryHeight);
  doc.text(shipment.length_ldm ? String(shipment.length_ldm) : '', 134, shipmentRowSummaryHeight);
  doc.text(shipment.weight_kg ? String(shipment.weight_kg) : '', 157, shipmentRowSummaryHeight);
  doc.text(shipment.chargeable_weight_kg ? String(shipment.chargeable_weight_kg) : '', 180, shipmentRowSummaryHeight);

  // Note
  const noteMaxLength = 1300;
  const formattedNote =
    shipment.note && shipment.note.length >= noteMaxLength
      ? shipment.note.substring(0, noteMaxLength) + '...'
      : shipment.note;

  doc.text(formattedNote ?? '', firstColumn, 190, {
    maxWidth: 130,
  });

  // Recipient and driver (if shipment has been delivered)
  if (shipment.delivered_at) {
    const recipientWords = shipment.recipient ? shipment.recipient.split(' ') : [];
    const formattedRecipient = recipientWords
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');

    doc.text(formattedRecipient ?? '', firstColumn, 282);
    doc.text(shipment.delivered_at ? formatDate(shipment.delivered_at) : '', firstColumn, 285);
    doc.text(`${driver?.last_name ?? ''} ${driver?.first_name ?? ''}`, secondColumn, 282);
    doc.text(shipment.picked_up_at ? formatDate(shipment.picked_up_at) : '', secondColumn, 285);
  }

  //Shipment rows
  rows.map((shipmentRow, index) => {
    if (index < 18 || (index === 18 && rows.length === 19)) {
      getShipmentRows(shipmentRow, index, 118);
    } else if (index === 18 && rows.length > 19) {
      doc.text('TÄYDELLINEN TOIMITUSRIVILUETTELO ERILLISENÄ LIITTEENÄ', firstColumn, 173);
    }
  });
  rows.length > 19 ? getShipmentRowsInPages(rows, shipment, 90) : null;

  // Save waybill
  doc.save(`rahtikirja_${shipment.id}`);
};
