/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  GetShipmentIncludeEnum,
  GetShipmentIncludeEnumFromJSON,
  GetShipmentIncludeEnumToJSON,
  InlineResponse200,
  InlineResponse200FromJSON,
  InlineResponse200ToJSON,
  OrganizationPhotoFileResponseBody,
  OrganizationPhotoFileResponseBodyFromJSON,
  OrganizationPhotoFileResponseBodyToJSON,
  PhotoFileResponseBody,
  PhotoFileResponseBodyFromJSON,
  PhotoFileResponseBodyToJSON,
  PhotoResponseBody,
  PhotoResponseBodyFromJSON,
  PhotoResponseBodyToJSON,
  PhotosResponseBody,
  PhotosResponseBodyFromJSON,
  PhotosResponseBodyToJSON,
  PostShipmentDeliveryTransportResponseBody,
  PostShipmentDeliveryTransportResponseBodyFromJSON,
  PostShipmentDeliveryTransportResponseBodyToJSON,
  ShipmentPatchBody,
  ShipmentPatchBodyFromJSON,
  ShipmentPatchBodyToJSON,
  ShipmentPostBody,
  ShipmentPostBodyFromJSON,
  ShipmentPostBodyToJSON,
  ShipmentPutBody,
  ShipmentPutBodyFromJSON,
  ShipmentPutBodyToJSON,
  ShipmentResponseBody,
  ShipmentResponseBodyFromJSON,
  ShipmentResponseBodyToJSON,
  ShipmentResponseBody1,
  ShipmentResponseBody1FromJSON,
  ShipmentResponseBody1ToJSON,
  ShipmentRowProductsResponseBody,
  ShipmentRowProductsResponseBodyFromJSON,
  ShipmentRowProductsResponseBodyToJSON,
  ShipmentRowsResponseBody,
  ShipmentRowsResponseBodyFromJSON,
  ShipmentRowsResponseBodyToJSON,
  ShipmentUpdateHistoryResponseBody,
  ShipmentUpdateHistoryResponseBodyFromJSON,
  ShipmentUpdateHistoryResponseBodyToJSON,
  ShipmentsPatchBody,
  ShipmentsPatchBodyFromJSON,
  ShipmentsPatchBodyToJSON,
  ShipmentsPatchResponseBody,
  ShipmentsPatchResponseBodyFromJSON,
  ShipmentsPatchResponseBodyToJSON,
  ShipmentsResponseBody1,
  ShipmentsResponseBody1FromJSON,
  ShipmentsResponseBody1ToJSON,
  UnhandledShipmentRowsResponseBody,
  UnhandledShipmentRowsResponseBodyFromJSON,
  UnhandledShipmentRowsResponseBodyToJSON,
} from '../models';

export interface ShipmentsApiCreateShipmentRequest {
  shipmentPostBody: ShipmentPostBody;
}

export interface ShipmentsApiDeleteShipmentRequest {
  shipmentId: number;
}

export interface ShipmentsApiGetOrganizationShipmentPhotoRequest {
  organizationId: string;
  shipmentId: number;
  photoId: string;
}

export interface ShipmentsApiGetShipmentRequest {
  shipmentId: number;
  include?: Array<GetShipmentIncludeEnum>;
}

export interface ShipmentsApiGetShipmentEditRightsRequest {
  shipmentId: number;
}

export interface ShipmentsApiGetShipmentPhotoRequest {
  shipmentId: number;
  photoId: string;
}

export interface ShipmentsApiGetShipmentPhotosRequest {
  shipmentId: number;
}

export interface ShipmentsApiGetShipmentRowProductsRequest {
  shipmentId: number;
  shipmentRowId: number;
}

export interface ShipmentsApiGetShipmentRowsRequest {
  shipmentId: number;
}

export interface ShipmentsApiGetShipmentUpdateHistoryRequest {
  shipmentId: number;
}

export interface ShipmentsApiGetShipmentsRequest {
  next?: number;
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
  filterOutOtherCarriers?: boolean;
  getShipmentsByDriveDate?: boolean;
}

export interface ShipmentsApiGetUnhandledShipmentRowsRequest {
  next?: number;
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
}

export interface ShipmentsApiPatchShipmentRequest {
  shipmentId: number;
  shipmentPatchBody: ShipmentPatchBody;
}

export interface ShipmentsApiPatchShipmentsRequest {
  shipmentsPatchBody: Array<ShipmentsPatchBody>;
}

export interface ShipmentsApiPostShipmentDeliveryTransportRequest {
  shipmentId: number;
}

export interface ShipmentsApiPostShipmentPhotoRequest {
  shipmentId: number;
  description: string;
  timestamp: Date;
  shipmentRowId?: number;
  photo?: Blob;
}

export interface ShipmentsApiUpdateShipmentRequest {
  shipmentId: number;
  shipmentPutBody: ShipmentPutBody;
}

/**
 *
 */
export class ShipmentsApi extends runtime.BaseAPI {
  /**
   * Create new shipment.
   */
  async createShipmentRaw(
    requestParameters: ShipmentsApiCreateShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentResponseBody>> {
    if (requestParameters.shipmentPostBody === null || requestParameters.shipmentPostBody === undefined) {
      throw new runtime.RequiredError(
        'shipmentPostBody',
        'Required parameter requestParameters.shipmentPostBody was null or undefined when calling createShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/shipments`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ShipmentPostBodyToJSON(requestParameters.shipmentPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create new shipment.
   */
  async createShipment(
    requestParameters: ShipmentsApiCreateShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentResponseBody> {
    const response = await this.createShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a shipment by id.
   */
  async deleteShipmentRaw(
    requestParameters: ShipmentsApiDeleteShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling deleteShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a shipment by id.
   */
  async deleteShipment(
    requestParameters: ShipmentsApiDeleteShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteShipmentRaw(requestParameters, initOverrides);
  }

  /**
   * Get photo of a Shipment of organization.
   */
  async getOrganizationShipmentPhotoRaw(
    requestParameters: ShipmentsApiGetOrganizationShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationPhotoFileResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationShipmentPhoto.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getOrganizationShipmentPhoto.',
      );
    }

    if (requestParameters.photoId === null || requestParameters.photoId === undefined) {
      throw new runtime.RequiredError(
        'photoId',
        'Required parameter requestParameters.photoId was null or undefined when calling getOrganizationShipmentPhoto.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/shipments/{shipment_id}/photos/{photo_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(`{${'photo_id'}}`, encodeURIComponent(String(requestParameters.photoId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationPhotoFileResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get photo of a Shipment of organization.
   */
  async getOrganizationShipmentPhoto(
    requestParameters: ShipmentsApiGetOrganizationShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationPhotoFileResponseBody> {
    const response = await this.getOrganizationShipmentPhotoRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a shipment by id.
   */
  async getShipmentRaw(
    requestParameters: ShipmentsApiGetShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentResponseBody1>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getShipment.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include) {
      queryParameters['include'] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentResponseBody1FromJSON(jsonValue));
  }

  /**
   * Get a shipment by id.
   */
  async getShipment(
    requestParameters: ShipmentsApiGetShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentResponseBody1> {
    const response = await this.getShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getShipmentEditRightsRaw(
    requestParameters: ShipmentsApiGetShipmentEditRightsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse200>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getShipmentEditRights.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}/edit_rights`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
  }

  /**
   */
  async getShipmentEditRights(
    requestParameters: ShipmentsApiGetShipmentEditRightsRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse200> {
    const response = await this.getShipmentEditRightsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get photo of a Shipment.
   */
  async getShipmentPhotoRaw(
    requestParameters: ShipmentsApiGetShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PhotoFileResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getShipmentPhoto.',
      );
    }

    if (requestParameters.photoId === null || requestParameters.photoId === undefined) {
      throw new runtime.RequiredError(
        'photoId',
        'Required parameter requestParameters.photoId was null or undefined when calling getShipmentPhoto.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}/photos/{photo_id}`
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(`{${'photo_id'}}`, encodeURIComponent(String(requestParameters.photoId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFileResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get photo of a Shipment.
   */
  async getShipmentPhoto(
    requestParameters: ShipmentsApiGetShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<PhotoFileResponseBody> {
    const response = await this.getShipmentPhotoRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get list of photo metadata resources of a Shipment.
   */
  async getShipmentPhotosRaw(
    requestParameters: ShipmentsApiGetShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PhotosResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getShipmentPhotos.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}/photos`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotosResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get list of photo metadata resources of a Shipment.
   */
  async getShipmentPhotos(
    requestParameters: ShipmentsApiGetShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<PhotosResponseBody> {
    const response = await this.getShipmentPhotosRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentRowProducts of a ShipmentRow.
   */
  async getShipmentRowProductsRaw(
    requestParameters: ShipmentsApiGetShipmentRowProductsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentRowProductsResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getShipmentRowProducts.',
      );
    }

    if (requestParameters.shipmentRowId === null || requestParameters.shipmentRowId === undefined) {
      throw new runtime.RequiredError(
        'shipmentRowId',
        'Required parameter requestParameters.shipmentRowId was null or undefined when calling getShipmentRowProducts.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}/rows/{shipment_row_id}/shipment_row_products`
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(`{${'shipment_row_id'}}`, encodeURIComponent(String(requestParameters.shipmentRowId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentRowProductsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get ShipmentRowProducts of a ShipmentRow.
   */
  async getShipmentRowProducts(
    requestParameters: ShipmentsApiGetShipmentRowProductsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentRowProductsResponseBody> {
    const response = await this.getShipmentRowProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentRows of a Shipment.
   */
  async getShipmentRowsRaw(
    requestParameters: ShipmentsApiGetShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentRowsResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getShipmentRows.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}/rows`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentRowsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get ShipmentRows of a Shipment.
   */
  async getShipmentRows(
    requestParameters: ShipmentsApiGetShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentRowsResponseBody> {
    const response = await this.getShipmentRowsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get update history of a shipment
   */
  async getShipmentUpdateHistoryRaw(
    requestParameters: ShipmentsApiGetShipmentUpdateHistoryRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentUpdateHistoryResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getShipmentUpdateHistory.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}/update_history`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentUpdateHistoryResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get update history of a shipment
   */
  async getShipmentUpdateHistory(
    requestParameters: ShipmentsApiGetShipmentUpdateHistoryRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentUpdateHistoryResponseBody> {
    const response = await this.getShipmentUpdateHistoryRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all shipments in the system.
   */
  async getShipmentsRaw(
    requestParameters: ShipmentsApiGetShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentsResponseBody1>> {
    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    if (requestParameters.filterOutOtherCarriers !== undefined) {
      queryParameters['filter_out_other_carriers'] = requestParameters.filterOutOtherCarriers;
    }

    if (requestParameters.getShipmentsByDriveDate !== undefined) {
      queryParameters['get_shipments_by_drive_date'] = requestParameters.getShipmentsByDriveDate;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentsResponseBody1FromJSON(jsonValue));
  }

  /**
   * Get all shipments in the system.
   */
  async getShipments(
    requestParameters: ShipmentsApiGetShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentsResponseBody1> {
    const response = await this.getShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all unhandled shipment rows in specific date range.
   */
  async getUnhandledShipmentRowsRaw(
    requestParameters: ShipmentsApiGetUnhandledShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<UnhandledShipmentRowsResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/unhandled_shipment_rows`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UnhandledShipmentRowsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all unhandled shipment rows in specific date range.
   */
  async getUnhandledShipmentRows(
    requestParameters: ShipmentsApiGetUnhandledShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<UnhandledShipmentRowsResponseBody> {
    const response = await this.getUnhandledShipmentRowsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Patch a shipment by id.
   */
  async patchShipmentRaw(
    requestParameters: ShipmentsApiPatchShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling patchShipment.',
      );
    }

    if (requestParameters.shipmentPatchBody === null || requestParameters.shipmentPatchBody === undefined) {
      throw new runtime.RequiredError(
        'shipmentPatchBody',
        'Required parameter requestParameters.shipmentPatchBody was null or undefined when calling patchShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: ShipmentPatchBodyToJSON(requestParameters.shipmentPatchBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Patch a shipment by id.
   */
  async patchShipment(
    requestParameters: ShipmentsApiPatchShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentResponseBody> {
    const response = await this.patchShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Patch a batch of shipments.
   */
  async patchShipmentsRaw(
    requestParameters: ShipmentsApiPatchShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentsPatchResponseBody>> {
    if (requestParameters.shipmentsPatchBody === null || requestParameters.shipmentsPatchBody === undefined) {
      throw new runtime.RequiredError(
        'shipmentsPatchBody',
        'Required parameter requestParameters.shipmentsPatchBody was null or undefined when calling patchShipments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/shipments`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.shipmentsPatchBody.map(ShipmentsPatchBodyToJSON),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentsPatchResponseBodyFromJSON(jsonValue));
  }

  /**
   * Patch a batch of shipments.
   */
  async patchShipments(
    requestParameters: ShipmentsApiPatchShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentsPatchResponseBody> {
    const response = await this.patchShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Creates a new shipment for delivery transport, and updates original shipment delivery addres to distribution center.
   */
  async postShipmentDeliveryTransportRaw(
    requestParameters: ShipmentsApiPostShipmentDeliveryTransportRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PostShipmentDeliveryTransportResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling postShipmentDeliveryTransport.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}/delivery_transport`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PostShipmentDeliveryTransportResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Creates a new shipment for delivery transport, and updates original shipment delivery addres to distribution center.
   */
  async postShipmentDeliveryTransport(
    requestParameters: ShipmentsApiPostShipmentDeliveryTransportRequest,
    initOverrides?: RequestInit,
  ): Promise<PostShipmentDeliveryTransportResponseBody> {
    const response = await this.postShipmentDeliveryTransportRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Post s photo related to a shipment
   */
  async postShipmentPhotoRaw(
    requestParameters: ShipmentsApiPostShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PhotoResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling postShipmentPhoto.',
      );
    }

    if (requestParameters.description === null || requestParameters.description === undefined) {
      throw new runtime.RequiredError(
        'description',
        'Required parameter requestParameters.description was null or undefined when calling postShipmentPhoto.',
      );
    }

    if (requestParameters.timestamp === null || requestParameters.timestamp === undefined) {
      throw new runtime.RequiredError(
        'timestamp',
        'Required parameter requestParameters.timestamp was null or undefined when calling postShipmentPhoto.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.description !== undefined) {
      queryParameters['description'] = requestParameters.description;
    }

    if (requestParameters.timestamp !== undefined) {
      queryParameters['timestamp'] = (requestParameters.timestamp as any).toISOString();
    }

    if (requestParameters.shipmentRowId !== undefined) {
      queryParameters['shipment_row_id'] = requestParameters.shipmentRowId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.photo !== undefined) {
      formParams.append('photo', requestParameters.photo as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}/photos`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoResponseBodyFromJSON(jsonValue));
  }

  /**
   * Post s photo related to a shipment
   */
  async postShipmentPhoto(
    requestParameters: ShipmentsApiPostShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<PhotoResponseBody> {
    const response = await this.postShipmentPhotoRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update a shipment by id.
   */
  async updateShipmentRaw(
    requestParameters: ShipmentsApiUpdateShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentResponseBody>> {
    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling updateShipment.',
      );
    }

    if (requestParameters.shipmentPutBody === null || requestParameters.shipmentPutBody === undefined) {
      throw new runtime.RequiredError(
        'shipmentPutBody',
        'Required parameter requestParameters.shipmentPutBody was null or undefined when calling updateShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/shipments/{shipment_id}`.replace(
          `{${'shipment_id'}}`,
          encodeURIComponent(String(requestParameters.shipmentId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ShipmentPutBodyToJSON(requestParameters.shipmentPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Update a shipment by id.
   */
  async updateShipment(
    requestParameters: ShipmentsApiUpdateShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentResponseBody> {
    const response = await this.updateShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
