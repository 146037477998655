/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  OrganizationUsersResponseBody,
  OrganizationUsersResponseBodyFromJSON,
  OrganizationUsersResponseBodyToJSON,
  PostOrganizationUserPasswordRequestBody,
  PostOrganizationUserPasswordRequestBodyFromJSON,
  PostOrganizationUserPasswordRequestBodyToJSON,
  PostOrganizationUserRequestBody,
  PostOrganizationUserRequestBodyFromJSON,
  PostOrganizationUserRequestBodyToJSON,
  PutOrganizationUserRequestBody,
  PutOrganizationUserRequestBodyFromJSON,
  PutOrganizationUserRequestBodyToJSON,
  UserResponseBody,
  UserResponseBodyFromJSON,
  UserResponseBodyToJSON,
} from '../models';

export interface OrganizationUsersApiCreateOrganizationUserRequest {
  organizationId: string;
  postOrganizationUserRequestBody: PostOrganizationUserRequestBody;
}

export interface OrganizationUsersApiDeleteOrganizationUserRequest {
  organizationId: string;
  username: string;
}

export interface OrganizationUsersApiGetOrganizationUserRequest {
  organizationId: string;
  username: string;
}

export interface OrganizationUsersApiGetOrganizationUsersRequest {
  organizationId: string;
}

export interface OrganizationUsersApiPostOrganizationUserPasswordRequest {
  organizationId: string;
  username: string;
  postOrganizationUserPasswordRequestBody: PostOrganizationUserPasswordRequestBody;
}

export interface OrganizationUsersApiUpdateOrganizationUserRequest {
  organizationId: string;
  username: string;
  putOrganizationUserRequestBody: PutOrganizationUserRequestBody;
}

/**
 *
 */
export class OrganizationUsersApi extends runtime.BaseAPI {
  /**
   * Create new user.
   */
  async createOrganizationUserRaw(
    requestParameters: OrganizationUsersApiCreateOrganizationUserRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<UserResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrganizationUser.',
      );
    }

    if (
      requestParameters.postOrganizationUserRequestBody === null ||
      requestParameters.postOrganizationUserRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'postOrganizationUserRequestBody',
        'Required parameter requestParameters.postOrganizationUserRequestBody was null or undefined when calling createOrganizationUser.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/users`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PostOrganizationUserRequestBodyToJSON(requestParameters.postOrganizationUserRequestBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create new user.
   */
  async createOrganizationUser(
    requestParameters: OrganizationUsersApiCreateOrganizationUserRequest,
    initOverrides?: RequestInit,
  ): Promise<UserResponseBody> {
    const response = await this.createOrganizationUserRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a user by username.
   */
  async deleteOrganizationUserRaw(
    requestParameters: OrganizationUsersApiDeleteOrganizationUserRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteOrganizationUser.',
      );
    }

    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling deleteOrganizationUser.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/users/{username}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a user by username.
   */
  async deleteOrganizationUser(
    requestParameters: OrganizationUsersApiDeleteOrganizationUserRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteOrganizationUserRaw(requestParameters, initOverrides);
  }

  /**
   * Get a user by id.
   */
  async getOrganizationUserRaw(
    requestParameters: OrganizationUsersApiGetOrganizationUserRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<UserResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationUser.',
      );
    }

    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getOrganizationUser.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/users/{username}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a user by id.
   */
  async getOrganizationUser(
    requestParameters: OrganizationUsersApiGetOrganizationUserRequest,
    initOverrides?: RequestInit,
  ): Promise<UserResponseBody> {
    const response = await this.getOrganizationUserRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all users of organization.
   */
  async getOrganizationUsersRaw(
    requestParameters: OrganizationUsersApiGetOrganizationUsersRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationUsersResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationUsers.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/users`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationUsersResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all users of organization.
   */
  async getOrganizationUsers(
    requestParameters: OrganizationUsersApiGetOrganizationUsersRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationUsersResponseBody> {
    const response = await this.getOrganizationUsersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Set a new password for user.
   */
  async postOrganizationUserPasswordRaw(
    requestParameters: OrganizationUsersApiPostOrganizationUserPasswordRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<UserResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling postOrganizationUserPassword.',
      );
    }

    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling postOrganizationUserPassword.',
      );
    }

    if (
      requestParameters.postOrganizationUserPasswordRequestBody === null ||
      requestParameters.postOrganizationUserPasswordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'postOrganizationUserPasswordRequestBody',
        'Required parameter requestParameters.postOrganizationUserPasswordRequestBody was null or undefined when calling postOrganizationUserPassword.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/users/{username}/password`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PostOrganizationUserPasswordRequestBodyToJSON(requestParameters.postOrganizationUserPasswordRequestBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseBodyFromJSON(jsonValue));
  }

  /**
   * Set a new password for user.
   */
  async postOrganizationUserPassword(
    requestParameters: OrganizationUsersApiPostOrganizationUserPasswordRequest,
    initOverrides?: RequestInit,
  ): Promise<UserResponseBody> {
    const response = await this.postOrganizationUserPasswordRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update a user by username.
   */
  async updateOrganizationUserRaw(
    requestParameters: OrganizationUsersApiUpdateOrganizationUserRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<UserResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateOrganizationUser.',
      );
    }

    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling updateOrganizationUser.',
      );
    }

    if (
      requestParameters.putOrganizationUserRequestBody === null ||
      requestParameters.putOrganizationUserRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'putOrganizationUserRequestBody',
        'Required parameter requestParameters.putOrganizationUserRequestBody was null or undefined when calling updateOrganizationUser.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/users/{username}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PutOrganizationUserRequestBodyToJSON(requestParameters.putOrganizationUserRequestBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseBodyFromJSON(jsonValue));
  }

  /**
   * Update a user by username.
   */
  async updateOrganizationUser(
    requestParameters: OrganizationUsersApiUpdateOrganizationUserRequest,
    initOverrides?: RequestInit,
  ): Promise<UserResponseBody> {
    const response = await this.updateOrganizationUserRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
