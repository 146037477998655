/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Distance in kms between two addresses.
 * @export
 * @interface Distance
 */
export interface Distance {
  /**
   *
   * @type {number}
   * @memberof Distance
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof Distance
   */
  address1: string;
  /**
   *
   * @type {string}
   * @memberof Distance
   */
  city1: string;
  /**
   *
   * @type {string}
   * @memberof Distance
   */
  address2: string;
  /**
   *
   * @type {string}
   * @memberof Distance
   */
  city2: string;
  /**
   *
   * @type {boolean}
   * @memberof Distance
   */
  has_been_fixed: boolean;
  /**
   *
   * @type {number}
   * @memberof Distance
   */
  legacy_etaisyys_field: number | null;
  /**
   *
   * @type {Date}
   * @memberof Distance
   */
  saved_at: Date;
  /**
   *
   * @type {boolean}
   * @memberof Distance
   */
  distance_calculation_failed: boolean;
}

export function DistanceFromJSON(json: any): Distance {
  return DistanceFromJSONTyped(json, false);
}

export function DistanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Distance {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    address1: json['address1'],
    city1: json['city1'],
    address2: json['address2'],
    city2: json['city2'],
    has_been_fixed: json['has_been_fixed'],
    legacy_etaisyys_field: json['legacy_etaisyys_field'],
    saved_at: new Date(json['saved_at']),
    distance_calculation_failed: json['distance_calculation_failed'],
  };
}

export function DistanceToJSON(value?: Distance | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    address1: value.address1,
    city1: value.city1,
    address2: value.address2,
    city2: value.city2,
    has_been_fixed: value.has_been_fixed,
    legacy_etaisyys_field: value.legacy_etaisyys_field,
    saved_at: value.saved_at.toISOString(),
    distance_calculation_failed: value.distance_calculation_failed,
  };
}
