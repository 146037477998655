/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A meta resource describing properties of a driver User.
 * @export
 * @interface Driver
 */
export interface Driver {
  /**
   *
   * @type {number}
   * @memberof Driver
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  first_name: string | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  last_name: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Driver
   */
  is_at_service: boolean;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  personal_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  company_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  employee_number: string;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  profession: DriverProfessionEnum;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  license: string | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  legacy_driver_app_id: string;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  hired_by: string | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  address: string | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  city: string | null;
  /**
   *
   * @type {Date}
   * @memberof Driver
   */
  proactive_driving_expire_date: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Driver
   */
  adr_expire_date: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Driver
   */
  work_safety_expire_date: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Driver
   */
  professional_qualification: Date | null;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  collective_agreement: DriverCollectiveAgreementEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum DriverProfessionEnum {
  Kuski = 'kuski',
  Appari = 'appari',
  Muu = 'muu',
  Alihankkija = 'alihankkija',
  Asentaja = 'asentaja',
  Toimihenkilo = 'toimihenkilo',
} /**
 * @export
 * @enum {string}
 */
export enum DriverCollectiveAgreementEnum {
  Empty = '',
  Kuljettaja = 'kuljettaja',
  Korjaamo = 'korjaamo',
  Toimihenkilo = 'toimihenkilo',
  Osaaikainen = 'osaaikainen',
}

export function DriverFromJSON(json: any): Driver {
  return DriverFromJSONTyped(json, false);
}

export function DriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): Driver {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    is_at_service: json['is_at_service'],
    personal_phone_number: json['personal_phone_number'],
    company_phone_number: json['company_phone_number'],
    employee_number: json['employee_number'],
    profession: json['profession'],
    license: json['license'],
    legacy_driver_app_id: json['legacy_driver_app_id'],
    hired_by: json['hired_by'],
    address: json['address'],
    postal_code: json['postal_code'],
    city: json['city'],
    proactive_driving_expire_date:
      json['proactive_driving_expire_date'] === null ? null : new Date(json['proactive_driving_expire_date']),
    adr_expire_date: json['adr_expire_date'] === null ? null : new Date(json['adr_expire_date']),
    work_safety_expire_date:
      json['work_safety_expire_date'] === null ? null : new Date(json['work_safety_expire_date']),
    professional_qualification:
      json['professional_qualification'] === null ? null : new Date(json['professional_qualification']),
    collective_agreement: json['collective_agreement'],
  };
}

export function DriverToJSON(value?: Driver | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    first_name: value.first_name,
    last_name: value.last_name,
    is_at_service: value.is_at_service,
    personal_phone_number: value.personal_phone_number,
    company_phone_number: value.company_phone_number,
    employee_number: value.employee_number,
    profession: value.profession,
    license: value.license,
    legacy_driver_app_id: value.legacy_driver_app_id,
    hired_by: value.hired_by,
    address: value.address,
    postal_code: value.postal_code,
    city: value.city,
    proactive_driving_expire_date:
      value.proactive_driving_expire_date === null ? null : value.proactive_driving_expire_date.toISOString(),
    adr_expire_date: value.adr_expire_date === null ? null : value.adr_expire_date.toISOString(),
    work_safety_expire_date:
      value.work_safety_expire_date === null ? null : value.work_safety_expire_date.toISOString(),
    professional_qualification:
      value.professional_qualification === null ? null : value.professional_qualification.toISOString(),
    collective_agreement: value.collective_agreement,
  };
}
