import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Box, Button, Dialog, IconButton, TextField, styled } from '@mui/material';
import { Photo, Shipment, User, api } from '../../../api';
import { Camera, Cameraswitch, Close, Save } from '@mui/icons-material';
import { NotificationType } from '../../../components/Notification';

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '1rem',
});

interface DriverCameraProps {
  username: User['username'];
  shipment: Shipment;
  open: boolean;
  setOpen: (open: boolean) => void;
  setNotification: Dispatch<SetStateAction<NotificationType>>;
  setPhotos: Dispatch<SetStateAction<Photo[]>>;
}

const dataURLtoFile = (dataURL: string, fileName: string) => {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new File([ab], `${fileName}.png`, { type: mimeString });
};

const DriverCamera: React.FC<DriverCameraProps> = ({
  username,
  shipment,
  open,
  setOpen,
  setNotification,
  setPhotos,
}) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [frontCamera, setFrontCamera] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');

  const capture = useCallback(() => {
    if (webcamRef && webcamRef.current) {
      const imageSrc = (webcamRef.current as Webcam).getScreenshot();
      setImgSrc(imageSrc);
    }
  }, [webcamRef, setImgSrc]);

  const savePhoto = async () => {
    try {
      if (imgSrc === null || shipment === null || !shipment.load_id) {
        throw new Error('Prerequisities not met');
      }
      const fileName = description ? description : 'empty';
      const file = dataURLtoFile(imgSrc, fileName);
      await api.drivers.postDriverLoadShipmentPhotos({
        username: username,
        loadId: shipment.load_id,
        shipmentId: shipment.id,
        description: fileName,
        timestamp: new Date(),
        photo: file,
      });
      setNotification({ message: 'Kuva tallennettu onnistuneesti' });
      const photosResponseBody = await api.drivers.getDriverLoadShipmentPhotos({
        username: username,
        loadId: shipment.load_id,
        shipmentId: shipment.id,
      });
      setPhotos(photosResponseBody.data);
    } catch (err) {
      setNotification({ message: 'Kuvan tallennus epäonnistui, yritä uudelleen.', severity: 'error' });
      console.error(err);
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      {imgSrc ? (
        <>
          <img src={imgSrc} />
          <Button
            variant="text"
            onClick={() => {
              setImgSrc(null);
              capture();
            }}
          >
            Ota uusi kuva
          </Button>
          <ButtonContainer>
            <IconButton
              color="error"
              onClick={() => {
                setOpen(false);
                setImgSrc(null);
              }}
            >
              <Close color="error" />
            </IconButton>
            <TextField
              label="Lisää kuvaus"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <IconButton
              color="success"
              onClick={() => {
                setOpen(false);
                savePhoto();
                setImgSrc(null);
              }}
            >
              <Save color="success" />
            </IconButton>
          </ButtonContainer>
        </>
      ) : (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/png"
            videoConstraints={{
              facingMode: { exact: frontCamera ? 'user' : 'environment' },
            }}
          />
          <ButtonContainer>
            <IconButton onClick={() => setFrontCamera(!frontCamera)}>
              <Cameraswitch />
            </IconButton>
            <IconButton onClick={() => capture()}>
              <Camera color="success" />
            </IconButton>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </ButtonContainer>
        </>
      )}
    </Dialog>
  );
};

export default DriverCamera;
