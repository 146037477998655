/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { DriverGroup, DriverGroupFromJSON, DriverGroupFromJSONTyped, DriverGroupToJSON } from './DriverGroup';

/**
 *
 * @export
 * @interface DriverGroupsResponseBody
 */
export interface DriverGroupsResponseBody {
  /**
   *
   * @type {Array<DriverGroup>}
   * @memberof DriverGroupsResponseBody
   */
  memberOf: Array<DriverGroup>;
  /**
   *
   * @type {Array<DriverGroup>}
   * @memberof DriverGroupsResponseBody
   */
  notMemberOf: Array<DriverGroup>;
}

export function DriverGroupsResponseBodyFromJSON(json: any): DriverGroupsResponseBody {
  return DriverGroupsResponseBodyFromJSONTyped(json, false);
}

export function DriverGroupsResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DriverGroupsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    memberOf: (json['memberOf'] as Array<any>).map(DriverGroupFromJSON),
    notMemberOf: (json['notMemberOf'] as Array<any>).map(DriverGroupFromJSON),
  };
}

export function DriverGroupsResponseBodyToJSON(value?: DriverGroupsResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    memberOf: (value.memberOf as Array<any>).map(DriverGroupToJSON),
    notMemberOf: (value.notMemberOf as Array<any>).map(DriverGroupToJSON),
  };
}
