/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { TimeOffLegacy, TimeOffLegacyFromJSON, TimeOffLegacyFromJSONTyped, TimeOffLegacyToJSON } from './TimeOffLegacy';

/**
 *
 * @export
 * @interface TimeOffLegacyResponseBody1
 */
export interface TimeOffLegacyResponseBody1 {
  /**
   *
   * @type {TimeOffLegacy}
   * @memberof TimeOffLegacyResponseBody1
   */
  data: TimeOffLegacy;
}

export function TimeOffLegacyResponseBody1FromJSON(json: any): TimeOffLegacyResponseBody1 {
  return TimeOffLegacyResponseBody1FromJSONTyped(json, false);
}

export function TimeOffLegacyResponseBody1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TimeOffLegacyResponseBody1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: TimeOffLegacyFromJSON(json['data']),
  };
}

export function TimeOffLegacyResponseBody1ToJSON(value?: TimeOffLegacyResponseBody1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: TimeOffLegacyToJSON(value.data),
  };
}
