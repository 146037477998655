/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Create a new user with a create new user token.
 * @export
 * @interface CreateNewUserPostBody
 */
export interface CreateNewUserPostBody {
  /**
   *
   * @type {string}
   * @memberof CreateNewUserPostBody
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewUserPostBody
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewUserPostBody
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewUserPostBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewUserPostBody
   */
  gsm?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNewUserPostBody
   */
  password: string;
}

export function CreateNewUserPostBodyFromJSON(json: any): CreateNewUserPostBody {
  return CreateNewUserPostBodyFromJSONTyped(json, false);
}

export function CreateNewUserPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNewUserPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: json['username'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    gsm: !exists(json, 'gsm') ? undefined : json['gsm'],
    password: json['password'],
  };
}

export function CreateNewUserPostBodyToJSON(value?: CreateNewUserPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    first_name: value.first_name,
    last_name: value.last_name,
    email: value.email,
    gsm: value.gsm,
    password: value.password,
  };
}
