/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CarServicePostBody,
  CarServicePostBodyFromJSON,
  CarServicePostBodyToJSON,
  CarServicePutBody,
  CarServicePutBodyFromJSON,
  CarServicePutBodyToJSON,
  CarServiceResponseBody,
  CarServiceResponseBodyFromJSON,
  CarServiceResponseBodyToJSON,
  CarServiceResponseBody1,
  CarServiceResponseBody1FromJSON,
  CarServiceResponseBody1ToJSON,
  CarServicesResponseBody,
  CarServicesResponseBodyFromJSON,
  CarServicesResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

export interface CarServicesApiCreateCarServiceRequest {
  carServicePostBody: CarServicePostBody;
}

export interface CarServicesApiGetCarCarServicesRequest {
  carId: number;
}

export interface CarServicesApiGetCarServiceRequest {
  carServiceId: number;
}

export interface CarServicesApiUpdateCarServiceRequest {
  carServiceId: number;
  carServicePutBody: CarServicePutBody;
}

/**
 *
 */
export class CarServicesApi extends runtime.BaseAPI {
  /**
   * Create a car service.
   */
  async createCarServiceRaw(
    requestParameters: CarServicesApiCreateCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CarServiceResponseBody>> {
    if (requestParameters.carServicePostBody === null || requestParameters.carServicePostBody === undefined) {
      throw new runtime.RequiredError(
        'carServicePostBody',
        'Required parameter requestParameters.carServicePostBody was null or undefined when calling createCarService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/car_services`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarServicePostBodyToJSON(requestParameters.carServicePostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarServiceResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create a car service.
   */
  async createCarService(
    requestParameters: CarServicesApiCreateCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<CarServiceResponseBody> {
    const response = await this.createCarServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all car services of car.
   */
  async getCarCarServicesRaw(
    requestParameters: CarServicesApiGetCarCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CarServicesResponseBody>> {
    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling getCarCarServices.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/cars/{car_id}/car_services`.replace(
          `{${'car_id'}}`,
          encodeURIComponent(String(requestParameters.carId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarServicesResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all car services of car.
   */
  async getCarCarServices(
    requestParameters: CarServicesApiGetCarCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<CarServicesResponseBody> {
    const response = await this.getCarCarServicesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a car service by id.
   */
  async getCarServiceRaw(
    requestParameters: CarServicesApiGetCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CarServiceResponseBody1>> {
    if (requestParameters.carServiceId === null || requestParameters.carServiceId === undefined) {
      throw new runtime.RequiredError(
        'carServiceId',
        'Required parameter requestParameters.carServiceId was null or undefined when calling getCarService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/car_services/{car_service_id}`.replace(
          `{${'car_service_id'}}`,
          encodeURIComponent(String(requestParameters.carServiceId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarServiceResponseBody1FromJSON(jsonValue));
  }

  /**
   * Get a car service by id.
   */
  async getCarService(
    requestParameters: CarServicesApiGetCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<CarServiceResponseBody1> {
    const response = await this.getCarServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all car services.
   */
  async getCarServicesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CarServicesResponseBody>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/car_services`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarServicesResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all car services.
   */
  async getCarServices(initOverrides?: RequestInit): Promise<CarServicesResponseBody> {
    const response = await this.getCarServicesRaw(initOverrides);
    return await response.value();
  }

  /**
   * Update a car service by id.
   */
  async updateCarServiceRaw(
    requestParameters: CarServicesApiUpdateCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CarServiceResponseBody1>> {
    if (requestParameters.carServiceId === null || requestParameters.carServiceId === undefined) {
      throw new runtime.RequiredError(
        'carServiceId',
        'Required parameter requestParameters.carServiceId was null or undefined when calling updateCarService.',
      );
    }

    if (requestParameters.carServicePutBody === null || requestParameters.carServicePutBody === undefined) {
      throw new runtime.RequiredError(
        'carServicePutBody',
        'Required parameter requestParameters.carServicePutBody was null or undefined when calling updateCarService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/car_services/{car_service_id}`.replace(
          `{${'car_service_id'}}`,
          encodeURIComponent(String(requestParameters.carServiceId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarServicePutBodyToJSON(requestParameters.carServicePutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CarServiceResponseBody1FromJSON(jsonValue));
  }

  /**
   * Update a car service by id.
   */
  async updateCarService(
    requestParameters: CarServicesApiUpdateCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<CarServiceResponseBody1> {
    const response = await this.updateCarServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
