/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimeOffPatchBody
 */
export interface TimeOffPatchBody {
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d1?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d2?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d3?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d4?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d5?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d6?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d7?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d8?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d9?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d10?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d11?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d12?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d13?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d14?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d15?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d16?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d17?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d18?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d19?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d20?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d21?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d22?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d23?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d24?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d25?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d26?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d27?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d28?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d29?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d30?: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffPatchBody
   */
  d31?: number;
}

export function TimeOffPatchBodyFromJSON(json: any): TimeOffPatchBody {
  return TimeOffPatchBodyFromJSONTyped(json, false);
}

export function TimeOffPatchBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeOffPatchBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    d1: !exists(json, 'd1') ? undefined : json['d1'],
    d2: !exists(json, 'd2') ? undefined : json['d2'],
    d3: !exists(json, 'd3') ? undefined : json['d3'],
    d4: !exists(json, 'd4') ? undefined : json['d4'],
    d5: !exists(json, 'd5') ? undefined : json['d5'],
    d6: !exists(json, 'd6') ? undefined : json['d6'],
    d7: !exists(json, 'd7') ? undefined : json['d7'],
    d8: !exists(json, 'd8') ? undefined : json['d8'],
    d9: !exists(json, 'd9') ? undefined : json['d9'],
    d10: !exists(json, 'd10') ? undefined : json['d10'],
    d11: !exists(json, 'd11') ? undefined : json['d11'],
    d12: !exists(json, 'd12') ? undefined : json['d12'],
    d13: !exists(json, 'd13') ? undefined : json['d13'],
    d14: !exists(json, 'd14') ? undefined : json['d14'],
    d15: !exists(json, 'd15') ? undefined : json['d15'],
    d16: !exists(json, 'd16') ? undefined : json['d16'],
    d17: !exists(json, 'd17') ? undefined : json['d17'],
    d18: !exists(json, 'd18') ? undefined : json['d18'],
    d19: !exists(json, 'd19') ? undefined : json['d19'],
    d20: !exists(json, 'd20') ? undefined : json['d20'],
    d21: !exists(json, 'd21') ? undefined : json['d21'],
    d22: !exists(json, 'd22') ? undefined : json['d22'],
    d23: !exists(json, 'd23') ? undefined : json['d23'],
    d24: !exists(json, 'd24') ? undefined : json['d24'],
    d25: !exists(json, 'd25') ? undefined : json['d25'],
    d26: !exists(json, 'd26') ? undefined : json['d26'],
    d27: !exists(json, 'd27') ? undefined : json['d27'],
    d28: !exists(json, 'd28') ? undefined : json['d28'],
    d29: !exists(json, 'd29') ? undefined : json['d29'],
    d30: !exists(json, 'd30') ? undefined : json['d30'],
    d31: !exists(json, 'd31') ? undefined : json['d31'],
  };
}

export function TimeOffPatchBodyToJSON(value?: TimeOffPatchBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    d1: value.d1,
    d2: value.d2,
    d3: value.d3,
    d4: value.d4,
    d5: value.d5,
    d6: value.d6,
    d7: value.d7,
    d8: value.d8,
    d9: value.d9,
    d10: value.d10,
    d11: value.d11,
    d12: value.d12,
    d13: value.d13,
    d14: value.d14,
    d15: value.d15,
    d16: value.d16,
    d17: value.d17,
    d18: value.d18,
    d19: value.d19,
    d20: value.d20,
    d21: value.d21,
    d22: value.d22,
    d23: value.d23,
    d24: value.d24,
    d25: value.d25,
    d26: value.d26,
    d27: value.d27,
    d28: value.d28,
    d29: value.d29,
    d30: value.d30,
    d31: value.d31,
  };
}
