import React from 'react';
import { CheckOutlined, LocalShippingOutlined, ClearOutlined, AddCircle, DeleteOutline } from '@mui/icons-material';
import theme from '../theme';
import { styled } from '@mui/material';

const iconStyles = { height: '0.8rem', width: '0.8rem', marginRight: '0.2rem' };

export const ShipmentDelivered: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className = '' }) => (
  <CheckOutlined className={'shipment-delivered-icon ' + className} style={iconStyles} />
);
export const ShipmentPickedUp: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className = '' }) => (
  <LocalShippingOutlined className={'shipment-picked-up-icon ' + className} style={iconStyles} />
);
export const ShipmentCancelled: React.FC<React.HTMLAttributes<HTMLElement>> = ({ className = '' }) => (
  <ClearOutlined
    className={'shipment-cancelled-icon ' + className}
    style={{ ...iconStyles, color: theme.palette.error.main }}
  />
);
export const Add = styled(AddCircle)(({ theme }) => ({
  color: theme.palette.success.main,
}));
export const Delete = styled(DeleteOutline)(({ theme }) => ({
  color: theme.palette.error.main,
}));
