import {
  city,
  pricingModelDefaultPricingArea,
  pricingModelName,
  pricingModelPrices,
  pricingAreaName,
  pricingRowPrice,
  carPricingRowPrice,
  pricingModelPostalCode,
} from '../../validation';
import { Field, FieldName, State } from './pricingModel.state';

type ValidationResult = {
  hasError: boolean;
  feedback?: string;
};

const getValidationResult = (joiSchema: any, field: Field<any>): ValidationResult => {
  const result = joiSchema.validate(field.value);
  const validationResult: ValidationResult = {
    hasError: result.error !== undefined,
    feedback: result.error?.message,
  };
  return validationResult;
};

const validateField = (fieldName: FieldName, fields: State['fields']): ValidationResult => {
  const field = fields[fieldName];
  let validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  switch (fieldName) {
    case 'name': {
      validationResult = getValidationResult(pricingModelName, field);
      break;
    }
    case 'hourly_price':
    case 'hourly_price_of_combined_vehicle':
    case 'express_delivery_price': {
      validationResult = getValidationResult(pricingModelPrices, field);
      break;
    }
    case 'default_pricing_area': {
      validationResult = getValidationResult(pricingModelDefaultPricingArea, field);
      break;
    }
  }
  if (field.required && (field.value === undefined || field.value === null || field.value === '')) {
    validationResult.hasError = true;
    validationResult.feedback = 'Kenttä on pakollinen';
  }
  return validationResult;
};

export const validateFields = (
  state: State,
): {
  fields: State['fields'];
  geographicalAreaFields: State['geographicalAreaFields'];
  pricingAreaFields: State['pricingAreaFields'];
  pricingRowFields: State['pricingRowFields'];
  carPricingRowFields: State['carPricingRowFields'];
} => {
  const newFields = { ...state.fields } as any;
  for (const fieldName of Object.keys(state.fields)) {
    newFields[fieldName] = {
      ...newFields[fieldName],
      ...validateField(fieldName as FieldName, state.fields),
    };
  }
  const newGeographicalAreaFields = [];
  for (const field of state.geographicalAreaFields) {
    let validationResult;
    const areaType = state.pricingAreaFields.find(
      (pricingAreaField) => pricingAreaField.pricingArea.id === field.geographicalArea.pricing_area,
    )?.areaType;
    if (areaType === 'postal_code') {
      validationResult = pricingModelPostalCode.validate(field.value);
    } else {
      validationResult = city.validate(field.value);
    }
    newGeographicalAreaFields.push({
      ...field,
      hasError: validationResult.error !== undefined,
      feedback: validationResult.error?.message,
    });
  }
  const newPricingAreaFields = [];
  for (const field of state.pricingAreaFields) {
    const validationResult = pricingAreaName.validate(field.value);
    newPricingAreaFields.push({
      ...field,
      hasError: validationResult.error !== undefined,
      feedback: validationResult.error?.message,
    });
  }
  const newPricingRowFields = [];
  for (const field of state.pricingRowFields) {
    const validationResult = pricingRowPrice.validate(field.value);
    newPricingRowFields.push({
      ...field,
      hasError: validationResult.error !== undefined,
      feedback: validationResult.error?.message,
    });
  }
  const newCarPricingRowFields = [];
  for (const field of state.carPricingRowFields) {
    const validationResult = carPricingRowPrice.validate(field.value);
    newCarPricingRowFields.push({
      ...field,
      hasError: validationResult.error !== undefined,
      feedback: validationResult.error?.message,
    });
  }
  return {
    fields: newFields,
    geographicalAreaFields: newGeographicalAreaFields,
    pricingAreaFields: newPricingAreaFields,
    pricingRowFields: newPricingRowFields,
    carPricingRowFields: newCarPricingRowFields,
  };
};
