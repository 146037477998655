import React, { Dispatch, useEffect, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import { Action, canAccessManagedUser, getInitialState, reducer, State } from './user.state';
import { api, getAllPages, PutOrganizationUserRequestBody, User } from '../../api';
import { useParams } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AllUserFields } from './components/inputs';
import { useDispatch } from 'react-redux';
import { initializeAuthentication } from '../../reducers/authReducer';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { canAccessUsers } from '../../utils';

const load = async (username: User['username'], currentUser: User, dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [organizationsResponse, userResponse, driversResponse, usersResponse] = await Promise.all([
      canAccessUsers(currentUser)
        ? (await api.organizations.getOrganizations({})).data
        : [(await api.organizations.getOrganization({ organizationId: currentUser.organization_id })).data],
      canAccessUsers(currentUser)
        ? (await api.users.getUserById({ username: username })).data
        : (
            await api.organizationUsers.getOrganizationUser({
              username: username,
              organizationId: currentUser.organization_id,
            })
          ).data,
      canAccessUsers(currentUser) ? getAllPages(api.drivers.getDrivers.bind(api.drivers), {}) : [],
      canAccessUsers(currentUser) ? getAllPages(api.users.getUsers.bind(api.users), {}) : [],
    ]);
    dispatch({
      type: 'SET_ORGANIZATIONS',
      payload: {
        organizations: organizationsResponse,
      },
    });
    dispatch({
      type: 'INITIALIZE_USER',
      payload: {
        user: userResponse as User,
      },
    });
    dispatch({
      type: 'SET_DRIVERS',
      payload: {
        drivers: driversResponse,
      },
    });
    dispatch({
      type: 'SET_USERS',
      payload: {
        users: usersResponse,
      },
    });
    dispatch({
      type: 'VALIDATE_FIELDS',
    });
  } catch (err) {
    console.log('error');
    let message = 'Käyttäjän lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Käyttäjää ei löytynyt!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

export const getUserPutBodyFromState = (state: State): PutOrganizationUserRequestBody => {
  return {
    organization_id: state.fields.organization_id.value,
    username: state.fields.username.value,
    first_name: state.fields.first_name.value ?? '',
    last_name: state.fields.last_name.value ?? '',
    email: state.fields.email.value || null,
    gsm: state.fields.gsm.value || null,
    is_admin: state.fields.is_admin.value || false,
    is_coordinator: state.fields.is_coordinator.value || false,
    is_multi_organization: state.fields.is_multi_organization.value || false,
    is_superuser: state.fields.is_superuser.value || false,
    is_workshop: state.fields.is_workshop.value || false,
    is_driver: state.fields.is_driver.value || false,
    is_manager: state.fields.is_manager.value || false,
    can_access_customer_report: state.fields.can_access_customer_report.value || false,
    driver_id: state.fields.driver_id.value || null,
  };
};

const saveUser = async (
  username: User['username'],
  organizationId: User['organization_id'],
  currentUser: User,
  state: State,
  dispatch: Dispatch<Action>,
  authenticationDispatch: (dispatch: Dispatch<any>) => void,
) => {
  if (!username) {
    throw new Error('Missing username');
  }
  if (!organizationId) {
    throw new Error('Missing organization id');
  }
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });

  try {
    const updatedUser = (
      await api.organizationUsers.updateOrganizationUser({
        username,
        organizationId,
        putOrganizationUserRequestBody: getUserPutBodyFromState(state),
      })
    ).data;
    if (state.fields.password.value && state.isValid) {
      await api.organizationUsers.postOrganizationUserPassword({
        organizationId: organizationId,
        username: username,
        postOrganizationUserPasswordRequestBody: {
          password: state.fields.password.value,
          ...(state.fields.old_password.value && { old_password: state.fields.old_password.value }),
          ...(state.fields.own_password.value && { own_password: state.fields.own_password.value }),
        },
      });
    }
    if (!updatedUser) {
      throw new Error('Updating user failed');
    }
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Käyttäjä tallennettu!' } });
    if (updatedUser.username === currentUser.username) {
      authenticationDispatch(initializeAuthentication());
    } else {
      load(updatedUser.username, currentUser, dispatch);
    }
  } catch (err) {
    dispatch(tryAgainMessage);
    dispatch({ type: 'SET_LOADING', payload: false });

    console.error(err);
  }
};

type EditUserParams = {
  username?: string;
};

const EditUser: React.FC = () => {
  const currentUser = useCurrentUser();
  const [state, dispatch] = useReducer(reducer, getInitialState(false, currentUser));
  state.fields.password.required =
    Boolean(state.fields.password_again.value) || Boolean(state.fields.old_password.value);
  state.fields.password_again.required =
    Boolean(state.fields.password.value) || Boolean(state.fields.old_password.value);
  state.fields.old_password.required =
    currentUser?.username === state.fields.username.value
      ? Boolean(state.fields.password.value) || Boolean(state.fields.password_again.value)
      : false;
  state.fields.own_password.required =
    currentUser?.username !== state.fields.username.value
      ? Boolean(state.fields.password.value) || Boolean(state.fields.password_again.value)
      : false;

  const { username } = useParams<EditUserParams>();
  const authenticationDispatch = useDispatch();

  useEffect(() => {
    if (currentUser && username) {
      load(username, currentUser, dispatch);
    }
  }, [currentUser, username]);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title={`Käyttäjä ${username || ''}`}>
            <SaveButton
              disabled={!state.isValid || state.isLoading || !canAccessManagedUser(currentUser, state.fields)}
              id="save-user-button"
              tooltip={!state.isValid ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (state.originalUser && currentUser) {
                  saveUser(
                    state.originalUser.username,
                    state.originalUser.organization_id,
                    currentUser,
                    state,
                    dispatch,
                    authenticationDispatch,
                  );
                  load(state.originalUser.username, currentUser, dispatch);
                  event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
                }
              }}
            >
              Tallenna
            </SaveButton>
          </Header>
        </HeaderContainer>
        {state.originalUser && (
          <AllUserFields state={state} dispatch={dispatch} currentUser={currentUser} createNewUser={false} />
        )}
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default EditUser;
