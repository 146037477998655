import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper, styled } from '@mui/material';
import SearchDistances from './SearchDistances';
import { DistancesList } from './DistancesList';
import Main from '../../components/Main';

const TAB_QUERY_PARAMETER = 'tab';

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface TabBarProps extends React.HTMLAttributes<HTMLElement> {
  tab: number; // <Tabs> expects a number
  setTab: (tab: number) => void;
}

const TabBar: React.FC<TabBarProps> = ({ tab, setTab }) => {
  return (
    <Paper square>
      <Tabs
        value={tab}
        onChange={(_event, newValue) => setTab(newValue)}
        variant="scrollable"
        scrollButtons
        indicatorColor="primary"
        textColor="primary"
        aria-label="distances-tabs"
        style={{ marginBottom: '1rem' }}
        allowScrollButtonsMobile
      >
        <Tab label="Etsi etäisyyksiä" {...a11yProps(0)} />
        <Tab label="Virheelliset etäisyydet" {...a11yProps(1)} />
      </Tabs>
    </Paper>
  );
};

const DistancesMain = styled(Main)({
  padding: 0,
});

const Content = styled('div')({
  padding: '0 1rem 1rem 1rem',
});

export const Distances: React.FC = () => {
  const [tab, setTab] = useState<number>(0);

  function changeTab(newTab: number) {
    const url = new URL(window.location.href);
    url.searchParams.set(TAB_QUERY_PARAMETER, String(newTab));
    window.history.replaceState(null, window.document.title, url.href);
    setTab(newTab);
  }
  useEffect(() => {
    const url = new URL(window.location.href);
    const urlTab = Number(url.searchParams.get(TAB_QUERY_PARAMETER)) ?? 0;
    changeTab([0, 1].indexOf(urlTab) !== -1 ? urlTab : 0);
  }, []);

  return (
    <DistancesMain>
      <TabBar tab={tab} setTab={changeTab} />
      <Content>{tab === 0 ? <SearchDistances /> : <DistancesList />}</Content>
    </DistancesMain>
  );
};
