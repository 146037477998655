/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum EmployeeFields {
  IsAtService = 'is_at_service',
  FirstName = 'first_name',
  LastName = 'last_name',
  CompanyPhoneNumber = 'company_phone_number',
  EmployeeNumber = 'employee_number',
  Profession = 'profession',
  License = 'license',
  HiredBy = 'hired_by',
  ProactiveDrivingExpireDate = 'proactive_driving_expire_date',
  AdrExpireDate = 'adr_expire_date',
  WorkSafetyExpireDate = 'work_safety_expire_date',
  ProfessionalQualification = 'professional_qualification',
  CollectiveAgreement = 'collective_agreement',
  PersonalPhoneNumber = 'personal_phone_number',
  Address = 'address',
  PostalCode = 'postal_code',
  City = 'city',
}

export function EmployeeFieldsFromJSON(json: any): EmployeeFields {
  return EmployeeFieldsFromJSONTyped(json, false);
}

export function EmployeeFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeFields {
  return json as EmployeeFields;
}

export function EmployeeFieldsToJSON(value?: EmployeeFields | null): any {
  return value as any;
}
