import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Tooltip } from '@mui/material';
import { OrganizationPricingModelWithAreas, PricingModelWithAreas } from '../api';

export interface PricingModelPickerProps {
  className?: string;
  label?: string;
  pricingModels: Partial<PricingModelWithAreas | OrganizationPricingModelWithAreas>[];
  value: Partial<PricingModelWithAreas | OrganizationPricingModelWithAreas> | null;
  onChange: (pricingModel: Partial<PricingModelWithAreas | OrganizationPricingModelWithAreas> | null) => void;
  disabled?: boolean;
  required?: boolean;
  tooltip?: string;
  isTooltipVisible?: boolean;
  onTooltipOpen?: () => void;
  onTooltipClose?: () => void;
}

export const PricingModelPicker: React.FC<PricingModelPickerProps> = ({
  className,
  label,
  disabled,
  pricingModels,
  value,
  onChange,
  tooltip,
  isTooltipVisible,
  onTooltipOpen,
  onTooltipClose,
}) => {
  return (
    <Tooltip
      title={tooltip ?? ''}
      aria-label={tooltip ?? ''}
      open={isTooltipVisible}
      onOpen={onTooltipOpen}
      onClose={onTooltipClose}
    >
      <Autocomplete
        style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
        disabled={disabled}
        fullWidth
        className={'pricing-model-picker ' + className}
        options={pricingModels.sort((pricingModel1, pricingModel2) =>
          (pricingModel1.name ?? '').toLowerCase() > (pricingModel2.name ?? '').toLowerCase() ? 1 : -1,
        )}
        autoHighlight
        getOptionLabel={(pricingModel) => pricingModel.name ?? String(pricingModel.id)}
        renderOption={(props, pricingModel) => <li {...props}>{pricingModel.name}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label ?? 'Oletushinnoittelumalli'}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofillstyle
            }}
          />
        )}
        value={value}
        onChange={(_event, pricingModel) => onChange(pricingModel)}
      />
    </Tooltip>
  );
};
