/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { ShipmentRow, ShipmentRowFromJSON, ShipmentRowFromJSONTyped, ShipmentRowToJSON } from './ShipmentRow';

/**
 *
 * @export
 * @interface ShipmentRowsResponseBody
 */
export interface ShipmentRowsResponseBody {
  /**
   *
   * @type {Array<ShipmentRow>}
   * @memberof ShipmentRowsResponseBody
   */
  data: Array<ShipmentRow>;
}

export function ShipmentRowsResponseBodyFromJSON(json: any): ShipmentRowsResponseBody {
  return ShipmentRowsResponseBodyFromJSONTyped(json, false);
}

export function ShipmentRowsResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShipmentRowsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(ShipmentRowFromJSON),
  };
}

export function ShipmentRowsResponseBodyToJSON(value?: ShipmentRowsResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(ShipmentRowToJSON),
  };
}
