/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AviShipmentProducts,
  AviShipmentProductsFromJSON,
  AviShipmentProductsFromJSONTyped,
  AviShipmentProductsToJSON,
} from './AviShipmentProducts';
import {
  AviShipmentRows,
  AviShipmentRowsFromJSON,
  AviShipmentRowsFromJSONTyped,
  AviShipmentRowsToJSON,
} from './AviShipmentRows';

/**
 * AviShipment
 * @export
 * @interface AviShipment
 */
export interface AviShipment {
  /**
   *
   * @type {Date}
   * @memberof AviShipment
   */
  agreed_delivery_window_starts_at?: Date | null;
  /**
   *
   * @type {number}
   * @memberof AviShipment
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  organization_name: string;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  readonly organization_id: string;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  licence_plate?: string;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  pickup_name: string | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  pickup_address: string | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  pickup_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  pickup_city: string | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  delivery_name: string | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  delivery_address: string | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  delivery_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  delivery_city: string | null;
  /**
   *
   * @type {Date}
   * @memberof AviShipment
   */
  delivered_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  recipient: string | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  reference_number: string | null;
  /**
   *
   * @type {string}
   * @memberof AviShipment
   */
  readonly google_maps_api_key?: string;
  /**
   *
   * @type {Array<AviShipmentRows>}
   * @memberof AviShipment
   */
  rows: Array<AviShipmentRows>;
  /**
   *
   * @type {Array<AviShipmentProducts>}
   * @memberof AviShipment
   */
  products: Array<AviShipmentProducts>;
}

export function AviShipmentFromJSON(json: any): AviShipment {
  return AviShipmentFromJSONTyped(json, false);
}

export function AviShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AviShipment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    agreed_delivery_window_starts_at: !exists(json, 'agreed_delivery_window_starts_at')
      ? undefined
      : json['agreed_delivery_window_starts_at'] === null
        ? null
        : new Date(json['agreed_delivery_window_starts_at']),
    id: json['id'],
    organization_name: json['organization_name'],
    organization_id: json['organization_id'],
    licence_plate: !exists(json, 'licence_plate') ? undefined : json['licence_plate'],
    pickup_name: json['pickup_name'],
    pickup_address: json['pickup_address'],
    pickup_postal_code: json['pickup_postal_code'],
    pickup_city: json['pickup_city'],
    delivery_name: json['delivery_name'],
    delivery_address: json['delivery_address'],
    delivery_postal_code: json['delivery_postal_code'],
    delivery_city: json['delivery_city'],
    delivered_at: json['delivered_at'] === null ? null : new Date(json['delivered_at']),
    recipient: json['recipient'],
    reference_number: json['reference_number'],
    google_maps_api_key: !exists(json, 'google_maps_api_key') ? undefined : json['google_maps_api_key'],
    rows: (json['rows'] as Array<any>).map(AviShipmentRowsFromJSON),
    products: (json['products'] as Array<any>).map(AviShipmentProductsFromJSON),
  };
}

export function AviShipmentToJSON(value?: AviShipment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    agreed_delivery_window_starts_at:
      value.agreed_delivery_window_starts_at === undefined
        ? undefined
        : value.agreed_delivery_window_starts_at === null
          ? null
          : value.agreed_delivery_window_starts_at.toISOString(),
    organization_name: value.organization_name,
    licence_plate: value.licence_plate,
    pickup_name: value.pickup_name,
    pickup_address: value.pickup_address,
    pickup_postal_code: value.pickup_postal_code,
    pickup_city: value.pickup_city,
    delivery_name: value.delivery_name,
    delivery_address: value.delivery_address,
    delivery_postal_code: value.delivery_postal_code,
    delivery_city: value.delivery_city,
    delivered_at: value.delivered_at === null ? null : value.delivered_at.toISOString(),
    recipient: value.recipient,
    reference_number: value.reference_number,
    rows: (value.rows as Array<any>).map(AviShipmentRowsToJSON),
    products: (value.products as Array<any>).map(AviShipmentProductsToJSON),
  };
}
