import { Button, styled } from '@mui/material';
import theme from '../../theme';
import { FieldSet } from './FieldSet';

export const StyledFormSearchButton = styled(Button)({
  marginTop: '1rem',
});

export const StyledFormContainer = styled('div')({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  '& > div': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

export const StyledFormContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledFormContentContainerMiddle = styled(StyledFormContentContainer)({
  [theme.breakpoints.up('sm')]: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
});

export const StyledFormFieldSet = styled(FieldSet)(() => ({
  maxWidth: '40rem',
}));
