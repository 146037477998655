/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A assignment that can be linked to a load.
 * @export
 * @interface Assignment
 */
export interface Assignment {
  /**
   *
   * @type {number}
   * @memberof Assignment
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof Assignment
   */
  organization_id: string;
  /**
   *
   * @type {number}
   * @memberof Assignment
   */
  assignment_specific_id: number;
  /**
   *
   * @type {string}
   * @memberof Assignment
   */
  description: string | null;
}

export function AssignmentFromJSON(json: any): Assignment {
  return AssignmentFromJSONTyped(json, false);
}

export function AssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Assignment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    organization_id: json['organization_id'],
    assignment_specific_id: json['assignment_specific_id'],
    description: json['description'],
  };
}

export function AssignmentToJSON(value?: Assignment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organization_id: value.organization_id,
    assignment_specific_id: value.assignment_specific_id,
    description: value.description,
  };
}
