/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  SummaryResponseBody,
  SummaryResponseBodyFromJSON,
  SummaryResponseBodyToJSON,
} from '../models';

export interface SummaryApiGetSummaryRequest {
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
}

/**
 *
 */
export class SummaryApi extends runtime.BaseAPI {
  /**
   * Get summary of daily resources.
   */
  async getSummaryRaw(
    requestParameters: SummaryApiGetSummaryRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<SummaryResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/summary`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SummaryResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get summary of daily resources.
   */
  async getSummary(
    requestParameters: SummaryApiGetSummaryRequest,
    initOverrides?: RequestInit,
  ): Promise<SummaryResponseBody> {
    const response = await this.getSummaryRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
