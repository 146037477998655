import { debounce } from 'lodash';
import { Dispatch } from 'react';
import { Car, CarService, CarTypeEnum, Driver } from '../../api';
import { validateFields } from './carValidation';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { IsNewAndIsUpdated } from '../../components/MaterialReactTable/MRTEditableTable';

export interface Field<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value: Value;
}

type ServerFieldNames = keyof Pick<
  Car,
  | 'id'
  | 'licence_plate'
  | 'is_active'
  | 'default_driver_id'
  | 'car_identifier'
  | 'car_number'
  | 'manufacturer'
  | 'model'
  | 'type'
  | 'imei'
  | 'vin_number'
  | 'first_registeration_date'
  | 'vehicle_inspection_date'
  | 'transport_licence'
  | 'transport_licence_date'
  | 'tyres'
  | 'hoist'
  | 'platform_details'
  | 'is_adr'
  | 'unladen_weight'
  | 'gross_weight'
  | 'height'
  | 'fuel_card'
  | 'fuel_id'
  | 'hoist_inspection_date'
  | 'note'
  | 'is_coordination'
  | 'default_trailer_id'
>;

export type FieldName = ServerFieldNames;

export type CarServiceWithIsNewAndIsUpdated = CarService & IsNewAndIsUpdated;

export interface State {
  fields: {
    [P in ServerFieldNames]: Field<Car[P]>;
  };
  originalCar?: Car;
  isLoading: boolean;
  loadingMessage?: string;
  notification: NotificationType;
  isValid: boolean;
  drivers: Driver[];
  cars: Car[];
  carServices: CarServiceWithIsNewAndIsUpdated[];
}

export type Action =
  | {
      type: 'VALIDATE_FIELD';
      payload: {
        fieldName: keyof State['fields'];
      };
    }
  | {
      type: 'VALIDATE_FIELDS';
    }
  | {
      type: 'SET_FIELD_VALUE';
      payload: {
        fieldName: keyof State['fields'];
        value: FieldValue;
      };
    }
  | SetMessageAction
  | SetLoadingAction
  | {
      type: 'INITIALIZE_CAR';
      payload: {
        car: Car;
        isNewCar?: boolean;
      };
    }
  | {
      type: 'SET_DRIVERS';
      payload: {
        drivers: Driver[];
      };
    }
  | {
      type: 'SET_CARS';
      payload: {
        cars: Car[];
      };
    }
  | {
      type: 'SET_CAR_SERVICES';
      payload: {
        carServices: CarServiceWithIsNewAndIsUpdated[];
      };
    };

const areAllFieldsValid = (state: Pick<State, 'fields'>) => {
  return !Object.values(state.fields).some((field) => field.hasError);
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD_VALUE': {
      const fields = {
        ...state.fields,
      };
      fields[action.payload.fieldName].value = action.payload.value;
      return {
        ...state,
        fields,
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'VALIDATE_FIELDS': {
      const validatedState = validateFields(state);
      return {
        ...state,
        ...validatedState,
        isValid: areAllFieldsValid(validatedState),
      };
    }

    case 'INITIALIZE_CAR': {
      const fields = {
        ...state.fields,
      };
      fields.id.value = action.payload.car.id ?? null;
      fields.licence_plate.value = action.payload.car.licence_plate ?? '';
      fields.is_active.value = action.payload.car.is_active ?? true;
      fields.default_driver_id.value = action.payload.car.default_driver_id ?? null;
      fields.car_identifier.value = action.payload.car.car_identifier ?? '';
      fields.car_number.value = action.payload.car.car_number ?? null;
      fields.manufacturer.value = action.payload.car.manufacturer ?? '';
      fields.model.value = action.payload.car.model ?? '';
      fields.type.value = action.payload.car.type = (action.payload.car.type as CarTypeEnum) ?? CarTypeEnum.Kaappi;
      fields.imei.value = action.payload.car.imei ?? '';
      fields.vin_number.value = action.payload.car.vin_number ?? '';
      fields.first_registeration_date.value = action.payload.car.first_registeration_date ?? null;
      fields.vehicle_inspection_date.value = action.payload.car.vehicle_inspection_date ?? null;
      fields.transport_licence.value = action.payload.car.transport_licence ?? '';
      fields.transport_licence_date.value = action.payload.car.transport_licence_date ?? null;
      fields.tyres.value = action.payload.car.tyres ?? '';
      fields.hoist.value = action.payload.car.hoist ?? '';
      fields.platform_details.value = action.payload.car.platform_details ?? '';
      fields.is_adr.value = action.payload.car.is_adr ?? false;
      fields.unladen_weight.value = action.payload.car.unladen_weight ?? null;
      fields.gross_weight.value = action.payload.car.gross_weight ?? null;
      fields.height.value = action.payload.car.height ?? null;
      fields.fuel_card.value = action.payload.car.fuel_card ?? '';
      fields.fuel_id.value = action.payload.car.fuel_id ?? '';
      fields.hoist_inspection_date.value = action.payload.car.hoist_inspection_date ?? null;
      fields.note.value = action.payload.car.note ?? '';
      fields.is_coordination.value = action.payload.car.is_coordination ?? false;
      fields.default_trailer_id.value = action.payload.car.default_trailer_id ?? null;
      return {
        ...state,
        fields,
        originalCar: action.payload.car as Car,
        isValid: false,
      };
    }
    case 'SET_DRIVERS': {
      return {
        ...state,
        drivers: action.payload.drivers,
      };
    }
    case 'SET_CARS': {
      return {
        ...state,
        cars: action.payload.cars,
      };
    }
    case 'SET_CAR_SERVICES': {
      return {
        ...state,
        carServices: action.payload.carServices,
      };
    }
    default: {
      throw new Error(`Unhandled action ${JSON.stringify(action)}`);
    }
  }
};

type FieldValue = string | boolean | number | Date | null;

export const debouncedValidateFieldsDispatch = debounce((dispatch: Dispatch<Action>) => {
  dispatch({
    type: 'VALIDATE_FIELDS',
  });
}, 500);

export const updateFieldValue = (fieldName: FieldName, value: FieldValue, dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_FIELD_VALUE',
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
  debouncedValidateFieldsDispatch(dispatch);
};

export const getInitialState = (): State => {
  return {
    fields: {
      id: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: 0,
      },
      licence_plate: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      is_active: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: true,
      },
      default_driver_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      car_identifier: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      car_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      manufacturer: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      model: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      type: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: CarTypeEnum.Kaappi,
      },
      imei: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      vin_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      first_registeration_date: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      vehicle_inspection_date: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      transport_licence: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      transport_licence_date: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      tyres: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      hoist: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      platform_details: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      is_adr: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      unladen_weight: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      gross_weight: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      height: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      fuel_card: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      fuel_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      hoist_inspection_date: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      note: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      is_coordination: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      default_trailer_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
    },
    isLoading: false,
    notification: {
      message: null,
    },
    isValid: false,
    drivers: [],
    cars: [],
    carServices: [],
  };
};
