import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import Shipments from './views/shipments/Shipments';
import NotFound from './views/NotFound';
import Navigation, { NavigationItem } from './components/Navigation';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { initializeAuthentication } from './reducers/authReducer';
import { Provider, useDispatch } from 'react-redux';
import FirstLogin from './views/FirstLogin';
import CssBaseline from '@mui/material/CssBaseline';
import CreateShipment from './views/shipments/CreateShipment';
import EditShipment from './views/shipments/EditShipment';
import SearchShipments from './views/shipments/SearchShipments';
import store from './store';
import theme from './theme';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Coordination } from './views/Coordination/Coordination';
import * as Sentry from '@sentry/react';
import Loads from './views/loads/Loads';
import { EditLoad } from './views/loads/EditLoad';
import LogIn from './views/LogIn';
import { Reports } from './views/reports/Reports';
import Organizations from './views/organizations/Organizations';
import EditOrganization from './views/organizations/EditOrganization';
import CreateOrganization from './views/organizations/CreateOrganization';
import Cars from './views/cars/Cars';
import EditCar from './views/cars/EditCar';
import CreateCar from './views/cars/CreateCar';
import Users from './views/users/Users';
import CreateUser from './views/users/CreateUser';
import EditUser from './views/users/EditUser';
import CreateNewUser from './views/createNewUser/CreateNewUser';
import CreatePricingModel from './views/pricingModels/CreatePricingModel';
import EditPricingModel from './views/pricingModels/EditPricingModel';
import PricingModels from './views/pricingModels/PricingModels';
import Employees from './views/employees/Employees';
import EditEmployee from './views/employees/EditEmployee';
import CreateEmployee from './views/employees/CreateEmployee';
import Summary from './views/summary/Summary';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Distances } from './views/distances/Distances';
import WorkHours from './views/workHours/workHours';
import Jobs from './views/jobs/Jobs';
import { CoordinationStatus } from './components/CoordinationStatus';
import Holidays from './views/holidays/Holidays';
import { canAccessCoordination, isPwaOrDriverOnly } from './utils';
import DriverFrontPage from './views/drivers/DriverFrontPage';
import DriverShipments from './views/drivers/DriverShipments';
import DriverHours from './views/drivers/DriverHours';
import DriverEditShipment from './views/drivers/DriverEditShipment';
import WorkHourMonitoring from './views/workHourMonitoring/WorkHourMonitoring';
import { useCurrentUser } from './hooks/useCurrentUser';
import PeriPiha from './views/PeriPiha';
import { periPihaLoader } from './views/PeriPiha.loader';
import Avi from './views/avi/Avi';
import EditDriverGroups from './views/employees/EditDriverGroups';
import Sms from './views/employees/Sms';
import SalaryInfo from './views/salaryInfo/SalaryInfo';
import { Organizations as Orgs } from './formatters';

// Enables Webpack Hot Module Reload
if (module.hot) {
  module.hot.accept();
}

if (SENTRY_DSN && SOURCE_VERSION) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: SOURCE_VERSION,
  });
}

const allowMobile = async () => {
  const viewport = document.getElementById('viewport');
  viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0');
  //loader functions requires a return
  return null;
};

const App: React.FC = () => {
  const currentUser = useCurrentUser();
  const pwaOrDriverOnly = isPwaOrDriverOnly(currentUser);

  let navigationItems: NavigationItem[] = [];
  if (currentUser) {
    const currentUserName =
      currentUser.first_name && currentUser.last_name
        ? currentUser.first_name + ' ' + currentUser.last_name
        : (currentUser.username ?? '');
    navigationItems = [
      { name: 'Uusi toimitus', path: '/shipments/new', id: 'new_shipment_nav' },
      {
        name: 'Etsi toimituksia',
        path: '/shipments/search',
        id: 'search_shipments',
      },
      { name: 'Toimitukset', path: '/shipments', id: 'shipments_nav' },
      { name: currentUserName, path: `/users/${currentUser.username}`, id: 'edit_user_nav', isProfileMenu: true },
    ];
    if (currentUser.is_superuser || currentUser.is_coordinator) {
      navigationItems.push({ name: 'Kuormat', path: '/loads', id: 'loads_nav' });
    }
    if (currentUser.is_superuser || (currentUser.is_coordinator && currentUser.is_multi_organization)) {
      navigationItems.push(
        {
          name: 'Ajojärjestely',
          path: '/coordination',
          id: 'coordination_nav',
        },
        {
          name: 'Raportit',
          path: '/reports',
          id: 'reports_nav',
        },
        {
          name: 'Etäisyydet',
          path: '/distances',
          id: 'distances_nav',
          isResourceMenu: true,
        },
      );
    }
    if (currentUser.is_superuser || currentUser.is_manager) {
      navigationItems.push({
        name: 'Käyttäjät',
        path: '/users',
        id: 'users_nav',
        isResourceMenu: true,
      });
    }
    if (currentUser.is_superuser || (currentUser.is_manager && currentUser.organization_id === HOST_ORGANIZATION)) {
      navigationItems.push(
        {
          name: 'Asiakkaat',
          path: '/organizations',
          id: 'organizations_nav',
          isResourceMenu: true,
        },
        {
          name: 'Työntekijät',
          path: '/employees',
          id: 'employees_nav',
          isResourceMenu: true,
        },
        {
          name: 'Työntekijäryhmät',
          path: '/driver_groups',
          id: 'driver_groups_nav',
          isResourceMenu: true,
        },
        {
          name: 'Työtunnit',
          path: '/work_hours',
          id: 'work_hours_nav',
          isResourceMenu: true,
        },
        {
          name: 'Tuntiseuranta',
          path: '/work_hour_monitoring',
          id: 'work_hour_monitoring_nav',
          isResourceMenu: true,
        },
        {
          name: 'Keikat',
          path: '/jobs',
          id: 'jobs_nav',
          isResourceMenu: true,
        },
        {
          name: 'Hinnoittelumallit',
          path: '/pricing_models',
          id: 'pricing_models_nav',
          isResourceMenu: true,
        },
        {
          name: 'Lomat',
          path: '/holidays',
          id: 'holidays_nav',
          isResourceMenu: true,
        },
        {
          name: 'Tekstiviestit',
          path: '/sms',
          id: 'sms_nav',
          isResourceMenu: true,
        },
      );
    }
    if (
      currentUser.is_superuser ||
      currentUser.is_workshop ||
      (currentUser.is_manager && currentUser.organization_id === HOST_ORGANIZATION)
    ) {
      navigationItems.push({
        name: 'Autot',
        path: '/cars',
        id: 'cars_nav',
        isResourceMenu: true,
      });
    }
    if (currentUser.organization_id === Orgs.Peri || currentUser.is_superuser) {
      navigationItems.push({
        name: 'Piha',
        path: '/peri_piha',
        id: 'peri_piha_nav',
        isResourceMenu: true,
      });
    }
    if (pwaOrDriverOnly) {
      navigationItems.push(
        {
          name: 'Tehtävät',
          path: '/driver/shipments',
          id: 'driver_loads_nav',
          isDriverMenu: true,
        },
        {
          name: 'Tunnit',
          path: '/driver/hours',
          id: 'driver_hours_nav',
          isDriverMenu: true,
        },
      );
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeAuthentication());
  }, [dispatch]);

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <>
          <Navigation items={navigationItems} />
          {canAccessCoordination(currentUser) ? <CoordinationStatus /> : null}
          <Outlet />
        </>
      ),
      children: [
        {
          index: true,
          element: pwaOrDriverOnly ? <Navigate replace to="/driver/shipments" /> : <Summary />,
        },
        { path: 'cars', element: <Cars /> },
        { path: 'cars/:carLicencePlate', element: <EditCar /> },
        { path: 'cars/new', element: <CreateCar /> },
        { path: 'coordination', element: <Coordination /> },
        { path: 'distances', element: <Distances /> },
        { path: 'driver_groups', element: <EditDriverGroups /> },
        { path: 'employees', element: <Employees /> },
        { path: 'employees/:employeeId', element: <EditEmployee /> },
        { path: 'employees/new', element: <CreateEmployee /> },
        { path: 'employees/salary_info', element: <SalaryInfo /> },
        { path: 'holidays', element: <Holidays /> },
        { path: 'jobs', element: <Jobs /> },
        { path: 'loads', element: <Loads /> },
        { path: 'loads/:loadId', element: <EditLoad /> },
        { path: 'organizations', element: <Organizations /> },
        { path: 'organizations/:organizationId', element: <EditOrganization /> },
        { path: 'organizations/new', element: <CreateOrganization /> },
        {
          path: 'peri_piha',
          element: <PeriPiha />,
          loader: (locationParams) => periPihaLoader(locationParams, currentUser?.organization_id ?? Orgs.Peri),
        },
        { path: 'pricing_models', element: <PricingModels /> },
        { path: 'pricing_models/:pricingModelId', element: <EditPricingModel /> },
        { path: 'pricing_models/new', element: <CreatePricingModel /> },
        { path: 'reports', element: <Reports /> },
        { path: 'shipments', element: <Shipments /> },
        { path: 'shipments/:shipmentId', element: <EditShipment /> },
        { path: 'shipments/new', element: <CreateShipment /> },
        { path: 'shipments/search', element: <SearchShipments /> },
        { path: 'sms', element: <Sms /> },
        { path: 'users', element: <Users /> },
        { path: 'users/:username', element: <EditUser /> },
        { path: 'users/new', element: <CreateUser /> },
        { path: 'work_hours', element: <WorkHours /> },
        { path: 'work_hour_monitoring', element: <WorkHourMonitoring /> },

        { path: 'driver', element: <DriverFrontPage />, loader: allowMobile },
        { path: 'driver/hours', element: <DriverHours />, loader: allowMobile },
        { path: 'driver/shipments', element: <DriverShipments />, loader: allowMobile },
        { path: 'driver/loads/:loadId/shipments/:shipmentId', element: <DriverEditShipment />, loader: allowMobile },

        { path: '*', element: <NotFound /> },
      ],
    },
    { path: 'login', element: currentUser ? <Navigate replace to="/" /> : <LogIn />, loader: allowMobile },
    {
      path: 'first_login',
      element: currentUser ? <Navigate replace to="/" /> : <FirstLogin />,
      loader: allowMobile,
    },
    {
      path: 'create_user',
      element: currentUser ? <Navigate replace to="/" /> : <CreateNewUser />,
      loader: allowMobile,
    },
    {
      path: 'avi/:aviUrl',
      element: <Avi />,
      loader: allowMobile,
    },
  ]);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js');
  }

  return <RouterProvider router={router} />;
};

const container = document.getElementById('container');
const root = createRoot(container!);

root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale="fi-FI"
      localeText={{
        //Mui timepicker localization
        datePickerToolbarTitle: 'Valitse päivä',
        dateTimePickerToolbarTitle: 'Valitse päivä ja aika',
        timePickerToolbarTitle: 'Valitse aika',
        clearButtonLabel: 'Tyhjennä',
      }}
    >
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </LocalizationProvider>
  </Sentry.ErrorBoundary>,
);
