/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  Load,
  LoadFromJSON,
  LoadToJSON,
  LoadResponseBody,
  LoadResponseBodyFromJSON,
  LoadResponseBodyToJSON,
  LoadShipmentsOptimizedRouteResponseBody,
  LoadShipmentsOptimizedRouteResponseBodyFromJSON,
  LoadShipmentsOptimizedRouteResponseBodyToJSON,
  LoadsResponseBody,
  LoadsResponseBodyFromJSON,
  LoadsResponseBodyToJSON,
  ShipmentsResponseBody1,
  ShipmentsResponseBody1FromJSON,
  ShipmentsResponseBody1ToJSON,
} from '../models';

export interface LoadsApiCreateLoadRequest {
  load: Load;
}

export interface LoadsApiDeleteLoadRequest {
  loadId: number;
}

export interface LoadsApiGetLoadRequest {
  loadId: number;
}

export interface LoadsApiGetLoadOptimizedRouteRequest {
  loadId: number;
}

export interface LoadsApiGetLoadShipmentsRequest {
  loadId: number;
}

export interface LoadsApiGetLoadsRequest {
  next?: number;
  driveDateRangeStartsAt?: Date;
  driveDateRangeEndsAt?: Date;
}

export interface LoadsApiUpdateLoadRequest {
  loadId: number;
  load: Load;
}

/**
 *
 */
export class LoadsApi extends runtime.BaseAPI {
  /**
   * Create new load.
   */
  async createLoadRaw(
    requestParameters: LoadsApiCreateLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<LoadResponseBody>> {
    if (requestParameters.load === null || requestParameters.load === undefined) {
      throw new runtime.RequiredError(
        'load',
        'Required parameter requestParameters.load was null or undefined when calling createLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/loads`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LoadToJSON(requestParameters.load),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LoadResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create new load.
   */
  async createLoad(
    requestParameters: LoadsApiCreateLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<LoadResponseBody> {
    const response = await this.createLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a load by id.
   */
  async deleteLoadRaw(
    requestParameters: LoadsApiDeleteLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling deleteLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/loads/{load_id}`.replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a load by id.
   */
  async deleteLoad(requestParameters: LoadsApiDeleteLoadRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteLoadRaw(requestParameters, initOverrides);
  }

  /**
   * Get a load by id.
   */
  async getLoadRaw(
    requestParameters: LoadsApiGetLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<LoadResponseBody>> {
    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/loads/{load_id}`.replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LoadResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a load by id.
   */
  async getLoad(requestParameters: LoadsApiGetLoadRequest, initOverrides?: RequestInit): Promise<LoadResponseBody> {
    const response = await this.getLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get optimized route for Shipments of a Load.
   */
  async getLoadOptimizedRouteRaw(
    requestParameters: LoadsApiGetLoadOptimizedRouteRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<LoadShipmentsOptimizedRouteResponseBody>> {
    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getLoadOptimizedRoute.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/loads/{load_id}/route`.replace(
          `{${'load_id'}}`,
          encodeURIComponent(String(requestParameters.loadId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoadShipmentsOptimizedRouteResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get optimized route for Shipments of a Load.
   */
  async getLoadOptimizedRoute(
    requestParameters: LoadsApiGetLoadOptimizedRouteRequest,
    initOverrides?: RequestInit,
  ): Promise<LoadShipmentsOptimizedRouteResponseBody> {
    const response = await this.getLoadOptimizedRouteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all Shipments of Load.
   */
  async getLoadShipmentsRaw(
    requestParameters: LoadsApiGetLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentsResponseBody1>> {
    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getLoadShipments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/loads/{load_id}/shipments`.replace(
          `{${'load_id'}}`,
          encodeURIComponent(String(requestParameters.loadId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentsResponseBody1FromJSON(jsonValue));
  }

  /**
   * Get all Shipments of Load.
   */
  async getLoadShipments(
    requestParameters: LoadsApiGetLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentsResponseBody1> {
    const response = await this.getLoadShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all loads.
   */
  async getLoadsRaw(
    requestParameters: LoadsApiGetLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<LoadsResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.driveDateRangeStartsAt !== undefined) {
      queryParameters['drive_date_range_starts_at'] = (requestParameters.driveDateRangeStartsAt as any).toISOString();
    }

    if (requestParameters.driveDateRangeEndsAt !== undefined) {
      queryParameters['drive_date_range_ends_at'] = (requestParameters.driveDateRangeEndsAt as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/loads`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LoadsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all loads.
   */
  async getLoads(requestParameters: LoadsApiGetLoadsRequest, initOverrides?: RequestInit): Promise<LoadsResponseBody> {
    const response = await this.getLoadsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update a load by id.
   */
  async updateLoadRaw(
    requestParameters: LoadsApiUpdateLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<LoadResponseBody>> {
    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling updateLoad.',
      );
    }

    if (requestParameters.load === null || requestParameters.load === undefined) {
      throw new runtime.RequiredError(
        'load',
        'Required parameter requestParameters.load was null or undefined when calling updateLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/loads/{load_id}`.replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: LoadToJSON(requestParameters.load),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LoadResponseBodyFromJSON(jsonValue));
  }

  /**
   * Update a load by id.
   */
  async updateLoad(
    requestParameters: LoadsApiUpdateLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<LoadResponseBody> {
    const response = await this.updateLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
