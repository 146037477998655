/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  DistancesResponseBody1,
  DistancesResponseBody1FromJSON,
  DistancesResponseBody1ToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  UpdateDistanceAndItsRelatedShipmentsPatchBody,
  UpdateDistanceAndItsRelatedShipmentsPatchBodyFromJSON,
  UpdateDistanceAndItsRelatedShipmentsPatchBodyToJSON,
  UpdateDistanceAndItsRelatedShipmentsResponseBody,
  UpdateDistanceAndItsRelatedShipmentsResponseBodyFromJSON,
  UpdateDistanceAndItsRelatedShipmentsResponseBodyToJSON,
} from '../models';

export interface DistancesApiGetDistancesWithFailedCalculationRequest {
  next?: number;
}

export interface DistancesApiUpdateDistanceAndItsRelatedShipmentsRequest {
  updateDistanceAndItsRelatedShipmentsPatchBody: UpdateDistanceAndItsRelatedShipmentsPatchBody;
}

/**
 *
 */
export class DistancesApi extends runtime.BaseAPI {
  /**
   * Get all distances with failed calculation.
   */
  async getDistancesWithFailedCalculationRaw(
    requestParameters: DistancesApiGetDistancesWithFailedCalculationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DistancesResponseBody1>> {
    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/distances/distances_with_failed_calculation`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DistancesResponseBody1FromJSON(jsonValue));
  }

  /**
   * Get all distances with failed calculation.
   */
  async getDistancesWithFailedCalculation(
    requestParameters: DistancesApiGetDistancesWithFailedCalculationRequest,
    initOverrides?: RequestInit,
  ): Promise<DistancesResponseBody1> {
    const response = await this.getDistancesWithFailedCalculationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Add a new distance and update all shipments that have same address.
   */
  async updateDistanceAndItsRelatedShipmentsRaw(
    requestParameters: DistancesApiUpdateDistanceAndItsRelatedShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<UpdateDistanceAndItsRelatedShipmentsResponseBody>> {
    if (
      requestParameters.updateDistanceAndItsRelatedShipmentsPatchBody === null ||
      requestParameters.updateDistanceAndItsRelatedShipmentsPatchBody === undefined
    ) {
      throw new runtime.RequiredError(
        'updateDistanceAndItsRelatedShipmentsPatchBody',
        'Required parameter requestParameters.updateDistanceAndItsRelatedShipmentsPatchBody was null or undefined when calling updateDistanceAndItsRelatedShipments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/distances/update_distance_and_its_related_shipments`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDistanceAndItsRelatedShipmentsPatchBodyToJSON(
          requestParameters.updateDistanceAndItsRelatedShipmentsPatchBody,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateDistanceAndItsRelatedShipmentsResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Add a new distance and update all shipments that have same address.
   */
  async updateDistanceAndItsRelatedShipments(
    requestParameters: DistancesApiUpdateDistanceAndItsRelatedShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<UpdateDistanceAndItsRelatedShipmentsResponseBody> {
    const response = await this.updateDistanceAndItsRelatedShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
