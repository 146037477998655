/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of Column Layouts.
 * @export
 * @interface ColumnLayout
 */
export interface ColumnLayout {
  /**
   *
   * @type {number}
   * @memberof ColumnLayout
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof ColumnLayout
   */
  view_id: string;
  /**
   *
   * @type {string}
   * @memberof ColumnLayout
   */
  organization_id: string;
  /**
   *
   * @type {string}
   * @memberof ColumnLayout
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ColumnLayout
   */
  columns: Array<string>;
}

export function ColumnLayoutFromJSON(json: any): ColumnLayout {
  return ColumnLayoutFromJSONTyped(json, false);
}

export function ColumnLayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnLayout {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    view_id: json['view_id'],
    organization_id: json['organization_id'],
    name: json['name'],
    columns: json['columns'],
  };
}

export function ColumnLayoutToJSON(value?: ColumnLayout | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    view_id: value.view_id,
    organization_id: value.organization_id,
    name: value.name,
    columns: value.columns,
  };
}
