import React from 'react';
import { Typography, Chip, TableCell, styled, Card, CardContent } from '@mui/material';
import theme from '../../../theme';

export const HeaderTableCell = styled(TableCell)({
  fontWeight: 'bold',
});

export const FooterTableCell = styled(TableCell)({
  color: theme.palette.common.black,
  fontWeight: 'bold',
});

export const MainContainer = styled('div')({
  position: 'relative',
  padding: '1rem',
});

export const SectionContainer = styled('div')({
  display: 'flex',
  flexFlow: 'wrap',
  justifyContent: 'center',
});

export const TitleContainer = styled('div')({
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const ChartContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
});

export const StyledChip = styled(Chip)({
  fontSize: '0.85rem',
  margin: '0.25rem',
});

type InfoCardProps = {
  title: string;
  value: number;
};

export const InfoCard: React.FC<InfoCardProps> = (props) => {
  return (
    <Card style={{ width: '7rem', height: '7rem', margin: '1rem' }}>
      <CardContent
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: 'auto' }}
      >
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h2" style={{ fontWeight: 'bold' }}>
            {props.value}
          </Typography>
          <Typography variant="h6">{props.title}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};
