import React, { useEffect, useState } from 'react';
import { api, Assignment, Client, DriverWithWorkHoursAndTimeOffs, User, WorkHour } from '../../api';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { groupBy, isEmpty, orderBy, pick } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { formatTimeString, timeFormat } from '../../formatters';
import { DateTime, Interval } from 'luxon';
import { StringTimePicker } from '../../components/DateAndTimePickers/StandardTimePicker';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { validateFields } from '../workHours/workHourValidation';
import { multipleDateRangeOverlaps } from '../workHours/workHours';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from '../../components/Notification';
import { FieldName, FieldValue, Fields, getInitialState } from '../workHours/workHours.state';
import { Loading } from '../../components/Loading';
import { Add } from '@mui/icons-material';
import theme from '../../theme';
import { ClientPicker } from '../../components/ClientPicker';
import DriverDateMenu from './components/DateMenu';
import { ifDateExistGetSameDateAtMidday } from '../../utils';
import { getSalaryPeriod } from '../../utils/dateRangeUtils';
import { CurrentUser } from '../../reducers/authReducer';

type AssignmentWithOrg = Assignment & { organization_name: string };

const getValidatedFields = (validatedFields: Fields, minDate?: DateTime, maxDate?: DateTime) => {
  return validateFields(validatedFields, minDate, maxDate).fields;
};

const getWorkHourPostBodyFromFields = (fields: Fields, date: Date) => {
  return {
    date: date,
    starts_at: fields.starts_at.value,
    ends_at: fields.ends_at.value,
    assignment_id: fields.assignment_id.value ? fields.assignment_id.value : -1,
    note: fields.note.value ? fields.note.value : null,
  };
};

const AddHours: React.FC<{
  user: User;
  loadHours: () => void;
  assignments: Assignment[];
  clients: Client[];
  pastHours: DriverWithWorkHoursAndTimeOffs | undefined;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: React.Dispatch<React.SetStateAction<NotificationType>>;
  date: Date;
}> = ({ user, loadHours, assignments, clients, pastHours, loading, setLoading, setNotification, date }) => {
  const emptyWorkHourFields = getInitialState().fields;

  const [minDate, maxDate] = getSalaryPeriod(DateTime.now(), true);

  const [fields, setfields] = useState<Fields>(getValidatedFields(emptyWorkHourFields));

  //multipleDateRangeOverlaps function need net_hours and id to work
  const dateRangesOverlap = multipleDateRangeOverlaps(pastHours?.work_hours ?? [], {
    ...getWorkHourPostBodyFromFields(fields, date),
    net_hours: 1,
    id: -1,
  });

  const dateIsNotOnSalaryPeriod = !Interval.fromDateTimes(minDate, maxDate).contains(DateTime.fromJSDate(date));
  const driverFieldsHaveErrors = Object.values(
    pick(fields, ['starts_at', 'ends_at', 'assignment_id', 'note', 'organization_id']),
  ).some((field) => field.hasError);

  const setFieldsAndValidate = (fieldName: FieldName, value: FieldValue) => {
    const validatedFields = getValidatedFields(
      {
        ...fields,
        [fieldName]: {
          ...fields[fieldName],
          value: value,
        },
        ...(fieldName === 'organization_id' &&
          value !== fields.organization_id.value && {
            assignment_id: {
              ...fields.assignment_id,
              value: '',
            },
          }),
      },
      minDate,
      maxDate,
    );
    setfields(validatedFields);
  };

  const saveHours = async () => {
    setLoading(true);
    try {
      await api.drivers.postDriverWithWorkHoursAndTimeOffs({
        username: user.username,
        driverWithWorkHoursAndTimeOffsPostBody: getWorkHourPostBodyFromFields(fields, date),
      });
      setfields(getValidatedFields(emptyWorkHourFields));
      setNotification({ message: 'Tuntikirjaus tallennettu onnistuneesti!' });
      loadHours();
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Tallennus epäonnistui. Yritä uudelleen', severity: 'error' });
    }
    setLoading(false);
  };
  return (
    <Card>
      <Accordion>
        <AccordionSummary disabled={loading || dateIsNotOnSalaryPeriod} expandIcon={<Add color="success" />}>
          <Typography sx={{ color: theme.palette.success.main }}>Lisää tuntikirjaus</Typography>
          {dateIsNotOnSalaryPeriod && (
            <FormHelperText sx={{ color: theme.palette.error.main, opacity: 1 }} error={dateRangesOverlap}>
              Työtunteja ei voida kirjata palkkakauden ulkopuolelle.
            </FormHelperText>
          )}
        </AccordionSummary>

        <AccordionDetails>
          <StyledForm
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              saveHours();
            }}
          >
            <ClientPicker
              clients={clients}
              value={clients.find((org) => org.id === fields.organization_id.value) ?? null}
              onChange={(client) => {
                setFieldsAndValidate('organization_id', client?.id ?? null);
              }}
              required={fields.organization_id.required}
              error={fields.organization_id.hasError}
              helperText={fields.organization_id.feedback}
            />
            <Autocomplete
              fullWidth
              options={
                assignments.filter((assignment) => assignment.organization_id === fields.organization_id.value) ?? null
              }
              autoHighlight
              getOptionLabel={(assignment) => `${assignment.assignment_specific_id} - ${assignment.description}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, assignment) => (
                <li {...props}>{`${assignment.assignment_specific_id} - ${assignment.description}`}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={fields.assignment_id.hasError}
                  helperText={fields.assignment_id.feedback}
                  label="Työtehtävä"
                  required={fields.assignment_id.required}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
              value={
                assignments.find((assignment) => assignment.assignment_specific_id === fields.assignment_id.value) ??
                null
              }
              onChange={(_event, assignment) =>
                setFieldsAndValidate('assignment_id', assignment?.assignment_specific_id ?? null)
              }
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexFlow: 'row wrap',
                '& > *': {
                  flex: 1,
                },
              }}
            >
              <StringTimePicker
                disabled={loading}
                format={timeFormat}
                value={fields.starts_at.value}
                label="Aloitus"
                onChange={(time) => setFieldsAndValidate('starts_at', time)}
                slotProps={{
                  textField: {
                    error: fields.starts_at.hasError,
                    helperText: fields.starts_at.feedback,
                  },
                }}
              />
              <StringTimePicker
                disabled={loading}
                label="Lopetus"
                format={timeFormat}
                value={fields.ends_at.value}
                onChange={(time) => setFieldsAndValidate('ends_at', time)}
                slotProps={{
                  textField: {
                    error: fields.ends_at.hasError,
                    helperText: fields.ends_at.feedback,
                  },
                }}
              />
            </Box>
            <TextField
              disabled={loading}
              label="Selite"
              type="text"
              name="note"
              value={fields.note.value ?? ''}
              onChange={(e) => setFieldsAndValidate(e.target.name as FieldName, e.target.value)}
              error={fields.note.hasError}
              helperText={fields.note.feedback}
            />
            {dateRangesOverlap && (
              <FormHelperText error={dateRangesOverlap}>
                Työtunteja on jo kirjattu kyseiselle aikavälille. Tarkista kentät.
              </FormHelperText>
            )}
            {dateIsNotOnSalaryPeriod && (
              <FormHelperText sx={{ color: theme.palette.error.main, opacity: 1 }} error={dateRangesOverlap}>
                Työtunteja ei voida kirjata palkkakauden ulkopuolelle.
              </FormHelperText>
            )}
            <Button
              type="submit"
              sx={{ marginTop: '1rem' }}
              disabled={driverFieldsHaveErrors || loading || dateRangesOverlap || dateIsNotOnSalaryPeriod}
            >
              Tallenna
            </Button>
          </StyledForm>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
const HoursOfDay: React.FC<{ hours: WorkHour[]; assignments: AssignmentWithOrg[] }> = ({ hours, assignments }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      fontWeight: 'bold',
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const organizationName = assignments.find(
    (a) => a.assignment_specific_id === hours[0].assignment_id,
  )?.organization_name;

  return (
    <TableContainer component={Paper}>
      <Box sx={{ padding: '1rem', display: 'flex', color: theme.palette.primary.dark }}>
        <Typography variant="h4">{organizationName}</Typography>
      </Box>
      <Table size="small" sx={{ th: { padding: '0.25rem' }, td: { padding: '0.25rem' }, tableLayout: 'fixed' }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Aika</StyledTableCell>
            <StyledTableCell>Tehtävä</StyledTableCell>
            <StyledTableCell>Selite</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {hours.map((workHour) => {
            const assignment = assignments.find((a) => a.assignment_specific_id === workHour.assignment_id);
            return (
              <StyledTableRow key={workHour.id}>
                <StyledTableCell>
                  {formatTimeString(workHour.starts_at ?? '')} - {formatTimeString(workHour.ends_at ?? '')}
                </StyledTableCell>
                <StyledTableCell>
                  {workHour.assignment_id} - {assignment?.description}
                </StyledTableCell>
                <StyledTableCell>{workHour.note}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const PastHours: React.FC<{
  workHours: DriverWithWorkHoursAndTimeOffs;
  assignments: Assignment[];
  clients: Client[];
}> = ({ workHours, assignments, clients }) => {
  const assignmentsWithOrgs: AssignmentWithOrg[] = orderBy(
    assignments.map((assignment) => {
      return {
        ...assignment,
        organization_name: clients.find((o) => o.id === assignment.organization_id)?.name ?? '',
      };
    }),
    ['organization_name', 'assignment_specific_id'],
    ['asc', 'asc'],
  );

  const hoursByDay = groupBy(workHours.work_hours, (workHour: WorkHour) =>
    workHour.assignment_id
      ? assignmentsWithOrgs.find((a) => a.assignment_specific_id === workHour.assignment_id)?.organization_name
      : '',
  );
  return (
    <>
      {!isEmpty(hoursByDay) ? (
        Object.keys(hoursByDay)
          .sort()
          .map((day) => {
            return (
              <Card key={day}>
                <HoursOfDay hours={orderBy(hoursByDay[day], 'starts_at')} assignments={assignmentsWithOrgs} />
              </Card>
            );
          })
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
          <Typography>Ei tuntikirjauksia</Typography>
        </Box>
      )}
    </>
  );
};
const DriverHoursActual: React.FC<{ currentUser: CurrentUser }> = ({ currentUser }) => {
  const [date, setDate] = useState<Date>(ifDateExistGetSameDateAtMidday(new Date()) ?? new Date());
  const [pastHours, setPastHours] = useState<DriverWithWorkHoursAndTimeOffs | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationType>({ message: null });
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const clients = currentUser.clients;
  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };

  const loadHours = async () => {
    setLoading(true);
    try {
      const response = await api.drivers.getDriverWithWorkHoursAndTimeOffs({
        username: currentUser?.username ?? '',
        agreedDeliveryWindowDateRangeStartsAt: DateTime.fromJSDate(date).startOf('day').toJSDate(),
        agreedDeliveryWindowDateRangeEndsAt: DateTime.fromJSDate(date).endOf('day').toJSDate(),
      });
      setPastHours(response);
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Tuntikirjauksien lataus epäonnistui!', severity: 'error' });
    }
    setLoading(false);
  };

  useEffect(() => {
    const loadAssignments = async () => {
      const response = await api.assignments.getAssignments();
      setAssignments(response.data);
    };
    loadAssignments();
  }, []);

  useEffect(() => {
    loadHours();
  }, [date]);
  if (!pastHours || !assignments || !clients) return null;

  return (
    <>
      <DriverDateMenu date={date} setDate={setDate} />
      <Box sx={{ '> div': { margin: '0.5rem' } }}>
        <Loading isLoading={loading} />
        <PastHours workHours={pastHours} assignments={assignments} clients={clients} />
        <AddHours
          user={currentUser}
          loadHours={loadHours}
          assignments={assignments}
          clients={clients}
          pastHours={pastHours}
          loading={loading}
          setLoading={setLoading}
          setNotification={setNotification}
          date={date}
        />
        <Notification {...snackbarProps} />
      </Box>
    </>
  );
};

const DriverHours: React.FC = () => {
  const currentUser = useCurrentUser();
  if (currentUser) return <DriverHoursActual currentUser={currentUser} />;
  else return null;
};

export default DriverHours;
