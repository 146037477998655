import React from 'react';
import { LinearProgress } from '@mui/material';

export type SetLoadingAction = { type: 'SET_LOADING'; payload: boolean };

interface LoadingProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  isLoading: boolean;
  loadingMessage?: string;
}

export const Loading: React.FC<LoadingProps> = ({ isLoading }) => {
  if (isLoading) {
    return <LinearProgress style={{ width: '100%', top: 0, left: 0, position: 'fixed' }} />;
  }
};
