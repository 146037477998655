/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OptionalCarrierEmployee
 */
export interface OptionalCarrierEmployee {
  /**
   *
   * @type {boolean}
   * @memberof OptionalCarrierEmployee
   */
  is_at_service?: boolean;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  company_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  employee_number?: string;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  profession?: OptionalCarrierEmployeeProfessionEnum;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  license?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  hired_by?: string | null;
  /**
   *
   * @type {Date}
   * @memberof OptionalCarrierEmployee
   */
  proactive_driving_expire_date?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OptionalCarrierEmployee
   */
  adr_expire_date?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OptionalCarrierEmployee
   */
  work_safety_expire_date?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OptionalCarrierEmployee
   */
  professional_qualification?: Date | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  collective_agreement?: OptionalCarrierEmployeeCollectiveAgreementEnum;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  personal_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  postal_code?: string | null;
  /**
   *
   * @type {string}
   * @memberof OptionalCarrierEmployee
   */
  city?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum OptionalCarrierEmployeeProfessionEnum {
  Kuski = 'kuski',
  Appari = 'appari',
  Muu = 'muu',
  Alihankkija = 'alihankkija',
  Asentaja = 'asentaja',
  Toimihenkilo = 'toimihenkilo',
} /**
 * @export
 * @enum {string}
 */
export enum OptionalCarrierEmployeeCollectiveAgreementEnum {
  Empty = '',
  Kuljettaja = 'kuljettaja',
  Korjaamo = 'korjaamo',
  Toimihenkilo = 'toimihenkilo',
  Osaaikainen = 'osaaikainen',
}

export function OptionalCarrierEmployeeFromJSON(json: any): OptionalCarrierEmployee {
  return OptionalCarrierEmployeeFromJSONTyped(json, false);
}

export function OptionalCarrierEmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalCarrierEmployee {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    is_at_service: !exists(json, 'is_at_service') ? undefined : json['is_at_service'],
    first_name: !exists(json, 'first_name') ? undefined : json['first_name'],
    last_name: !exists(json, 'last_name') ? undefined : json['last_name'],
    company_phone_number: !exists(json, 'company_phone_number') ? undefined : json['company_phone_number'],
    employee_number: !exists(json, 'employee_number') ? undefined : json['employee_number'],
    profession: !exists(json, 'profession') ? undefined : json['profession'],
    license: !exists(json, 'license') ? undefined : json['license'],
    hired_by: !exists(json, 'hired_by') ? undefined : json['hired_by'],
    proactive_driving_expire_date: !exists(json, 'proactive_driving_expire_date')
      ? undefined
      : json['proactive_driving_expire_date'] === null
        ? null
        : new Date(json['proactive_driving_expire_date']),
    adr_expire_date: !exists(json, 'adr_expire_date')
      ? undefined
      : json['adr_expire_date'] === null
        ? null
        : new Date(json['adr_expire_date']),
    work_safety_expire_date: !exists(json, 'work_safety_expire_date')
      ? undefined
      : json['work_safety_expire_date'] === null
        ? null
        : new Date(json['work_safety_expire_date']),
    professional_qualification: !exists(json, 'professional_qualification')
      ? undefined
      : json['professional_qualification'] === null
        ? null
        : new Date(json['professional_qualification']),
    collective_agreement: !exists(json, 'collective_agreement') ? undefined : json['collective_agreement'],
    personal_phone_number: !exists(json, 'personal_phone_number') ? undefined : json['personal_phone_number'],
    address: !exists(json, 'address') ? undefined : json['address'],
    postal_code: !exists(json, 'postal_code') ? undefined : json['postal_code'],
    city: !exists(json, 'city') ? undefined : json['city'],
  };
}

export function OptionalCarrierEmployeeToJSON(value?: OptionalCarrierEmployee | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    is_at_service: value.is_at_service,
    first_name: value.first_name,
    last_name: value.last_name,
    company_phone_number: value.company_phone_number,
    employee_number: value.employee_number,
    profession: value.profession,
    license: value.license,
    hired_by: value.hired_by,
    proactive_driving_expire_date:
      value.proactive_driving_expire_date === undefined
        ? undefined
        : value.proactive_driving_expire_date === null
          ? null
          : value.proactive_driving_expire_date.toISOString(),
    adr_expire_date:
      value.adr_expire_date === undefined
        ? undefined
        : value.adr_expire_date === null
          ? null
          : value.adr_expire_date.toISOString(),
    work_safety_expire_date:
      value.work_safety_expire_date === undefined
        ? undefined
        : value.work_safety_expire_date === null
          ? null
          : value.work_safety_expire_date.toISOString(),
    professional_qualification:
      value.professional_qualification === undefined
        ? undefined
        : value.professional_qualification === null
          ? null
          : value.professional_qualification.toISOString(),
    collective_agreement: value.collective_agreement,
    personal_phone_number: value.personal_phone_number,
    address: value.address,
    postal_code: value.postal_code,
    city: value.city,
  };
}
