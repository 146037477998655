/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  GeographicalAreaPutBody,
  GeographicalAreaPutBodyFromJSON,
  GeographicalAreaPutBodyFromJSONTyped,
  GeographicalAreaPutBodyToJSON,
} from './GeographicalAreaPutBody';

/**
 * An area that is used for pricing purposes. For example, capital region might have different prices than rest of Finland. A pricing area consists of several geographical areas
 * @export
 * @interface PricingAreaPutBody
 */
export interface PricingAreaPutBody {
  /**
   *
   * @type {number}
   * @memberof PricingAreaPutBody
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PricingAreaPutBody
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PricingAreaPutBody
   */
  pricing_model?: number;
  /**
   *
   * @type {boolean}
   * @memberof PricingAreaPutBody
   */
  is_default_area?: boolean;
  /**
   *
   * @type {Array<GeographicalAreaPutBody>}
   * @memberof PricingAreaPutBody
   */
  geographical_areas: Array<GeographicalAreaPutBody>;
}

export function PricingAreaPutBodyFromJSON(json: any): PricingAreaPutBody {
  return PricingAreaPutBodyFromJSONTyped(json, false);
}

export function PricingAreaPutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingAreaPutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    pricing_model: !exists(json, 'pricing_model') ? undefined : json['pricing_model'],
    is_default_area: !exists(json, 'is_default_area') ? undefined : json['is_default_area'],
    geographical_areas: (json['geographical_areas'] as Array<any>).map(GeographicalAreaPutBodyFromJSON),
  };
}

export function PricingAreaPutBodyToJSON(value?: PricingAreaPutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    pricing_model: value.pricing_model,
    is_default_area: value.is_default_area,
    geographical_areas: (value.geographical_areas as Array<any>).map(GeographicalAreaPutBodyToJSON),
  };
}
