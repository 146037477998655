import { Accessor, CellValue } from 'react-table';
import { DateColumnFilter } from '../../../components/TableFilters';
import { formatDate } from '../../../formatters';

export const distanceColumns = [
  {
    Header: 'Nouto-osoite',
    accessor: 'address1',
  },
  {
    Header: 'Noutokaupunki',
    accessor: 'city1',
  },
  {
    Header: 'Toimitusosoite',
    accessor: 'address2',
  },
  {
    Header: 'Toimituskaupunki',
    accessor: 'city2',
  },
  {
    Header: 'Etäisyys',
    accessor: 'legacy_etaisyys_field',
  },
  {
    Header: 'Virhe',
    id: 'distance_calculation_failed',
    accessor: ((distance: CellValue) =>
      distance.distance_calculation_failed === true ? 'Kyllä' : 'Ei') as Accessor<any>,
  },
  {
    Header: 'Korjattu',
    id: 'has_been_fixed',
    accessor: ((distance: CellValue) => (distance.has_been_fixed === true ? 'Kyllä' : 'Ei')) as Accessor<any>,
  },
  {
    Header: 'Luontipäivämäärä',
    accessor: 'saved_at',
    Cell: (distance: CellValue) => (distance.value ? formatDate(distance.value) : ''),
    Filter: DateColumnFilter,
  },
];
