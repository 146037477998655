/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ShipmentReportResponseBody
 */
export interface ShipmentReportResponseBody {
  /**
   *
   * @type {Array<any>}
   * @memberof ShipmentReportResponseBody
   */
  data: Array<any>;
}

export function ShipmentReportResponseBodyFromJSON(json: any): ShipmentReportResponseBody {
  return ShipmentReportResponseBodyFromJSONTyped(json, false);
}

export function ShipmentReportResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShipmentReportResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: json['data'],
  };
}

export function ShipmentReportResponseBodyToJSON(value?: ShipmentReportResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: value.data,
  };
}
