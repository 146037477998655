import React from 'react';
import { DateTime } from 'luxon';
import theme from '../theme';
import { styled } from '@mui/material';
import { Alarm } from '@mui/icons-material';
import { formatTime, formatDateTime } from '../formatters';
import { Shipment } from '../api';

const PreciseDelivery = styled('div')({
  color: theme.palette.error.main,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
});

const AlarmIcon = styled(Alarm)({
  height: '1rem',
  width: '1rem',
  marginRight: '0.25rem',
});

function formatShipmentDeliveryWindow(
  deliveryWindowStartsAt: DateTime | null,
  deliveryWindowEndsAt: DateTime | null,
  onlyTime: boolean = false,
): string {
  const startTime =
    deliveryWindowStartsAt !== null
      ? onlyTime
        ? formatTime(deliveryWindowStartsAt)
        : formatDateTime(deliveryWindowStartsAt)
      : '';
  const endTime = deliveryWindowEndsAt !== null ? formatTime(deliveryWindowEndsAt) : '';
  return `${startTime} - ${endTime}`;
}

interface DeliveryWindowProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  startsAt: Date | null;
  endsAt: Date | null;
  onlyTime?: boolean;
}

const preciseDeliveryTimeInHours = 2;

export const isPreciseDelivery = (
  deliveryWindowStartTime: Date | null,
  deliveryWindowEndTime: Date | null,
): boolean => {
  return (
    (deliveryWindowStartTime &&
      deliveryWindowEndTime &&
      DateTime.fromJSDate(deliveryWindowEndTime).diff(DateTime.fromJSDate(deliveryWindowStartTime), ['hours']).hours <=
        preciseDeliveryTimeInHours) ??
    false
  );
};

export const getPreciseDeliveryTimeDifferenceInMinutes = (
  deliveryWindowStartTime: Date | null,
  deliveryWindowEndTime: Date | null,
  deliveredAt: Date | null,
): string | undefined => {
  if (
    isPreciseDelivery(deliveryWindowStartTime, deliveryWindowEndTime) &&
    deliveryWindowStartTime &&
    deliveryWindowEndTime &&
    deliveredAt
  ) {
    return Math.round(
      DateTime.fromJSDate(deliveredAt).diff(DateTime.fromJSDate(deliveryWindowEndTime), ['minutes']).minutes,
    ).toString();
  } else {
    return '';
  }
};

export const getPreciseDeliveryShipments = (shipments: Shipment[]): Shipment[] => {
  return shipments.filter(
    (shipment) =>
      shipment.agreed_delivery_window_ends_at &&
      shipment.agreed_delivery_window_starts_at &&
      DateTime.fromJSDate(shipment.agreed_delivery_window_ends_at).diff(
        DateTime.fromJSDate(shipment.agreed_delivery_window_starts_at),
        ['hours'],
      ).hours <= preciseDeliveryTimeInHours,
  );
};

export const DeliveryWindow: React.FC<DeliveryWindowProps> = ({ className, startsAt, endsAt, onlyTime }) => {
  const startsAtDateTime = startsAt !== null ? DateTime.fromJSDate(startsAt) : null;
  const endsAtDateTime = endsAt !== null ? DateTime.fromJSDate(endsAt) : null;
  if (startsAt === null || endsAt === null) {
    return <span>{formatShipmentDeliveryWindow(startsAtDateTime, endsAtDateTime, onlyTime ?? false)}</span>;
  }
  const deliveryWindowText = formatShipmentDeliveryWindow(startsAtDateTime, endsAtDateTime, onlyTime ?? false);
  return (endsAtDateTime as DateTime).diff(startsAtDateTime as DateTime, ['hours']).hours <=
    preciseDeliveryTimeInHours ? (
    <PreciseDelivery className={`precise-delivery-time ${className}`}>
      <AlarmIcon className="precise-delivery-icon" />
      <b>{deliveryWindowText}</b>
    </PreciseDelivery>
  ) : (
    <span className={className}>{deliveryWindowText}</span>
  );
};
