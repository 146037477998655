import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { Dispatch } from 'react';
import { Action, State } from '../coordination.state';
import { FilterCheckbox } from '../../../components/FilterCheckbox';
import { FilterContainer } from '../../../components/StyledComponents/FilterContainer';
import { Close } from '@mui/icons-material';

interface ShipmentSettingsModalProps {
  open: boolean;
  onClose: () => void;
  state: State;
  dispatch: Dispatch<Action>;
}

export const ShipmentSettingsModal = ({ open, onClose, state, dispatch }: ShipmentSettingsModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign="center" sx={{ flexGrow: 1 }}>
        Suodattimet
      </DialogTitle>
      <IconButton
        sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
        data-cy="close-settings"
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ display: 'flex' }}>
        <FilterContainer sx={{ alignItems: 'flex-start' }}>
          <FilterCheckbox
            disabled={state.isLoading}
            label={'Vain täsmätoimitukset'}
            checked={state.viewSettings.onlyShipmentsWithPreciseDelivery}
            onChange={(_e) => {
              dispatch({
                type: 'SET_VIEW_SETTINGS',
                payload: {
                  ...state.viewSettings,
                  onlyShipmentsWithPreciseDelivery: !state.viewSettings.onlyShipmentsWithPreciseDelivery,
                },
              });
            }}
            name="precise_shipments"
            className="precise-shipments"
            tooltip="Näytä vain toimitukset, joissa toimitusaikaväli on 2 tuntia tai pienempi."
          />
          <FilterCheckbox
            disabled={state.isLoading}
            label={'Vain kuormattomat toimitukset'}
            checked={state.viewSettings.onlyShipmentsWithoutLoads}
            onChange={(_e) => {
              dispatch({
                type: 'SET_VIEW_SETTINGS',
                payload: {
                  ...state.viewSettings,
                  onlyShipmentsWithoutLoads: !state.viewSettings.onlyShipmentsWithoutLoads,
                },
              });
            }}
            name="unhandled_shipments"
            className="unhandled-shipments"
            tooltip="Näytä vain toimitukset, joita ei ole vielä asetettu kuormaan."
          />
          <FilterCheckbox
            disabled={state.isLoading}
            label={'Piilota perutut toimitukset'}
            checked={state.viewSettings.hideCancelledShipments}
            onChange={(_e) => {
              dispatch({
                type: 'SET_VIEW_SETTINGS',
                payload: {
                  ...state.viewSettings,
                  hideCancelledShipments: !state.viewSettings.hideCancelledShipments,
                },
              });
            }}
            name="hide_cancelled_shipments"
            className="hide-cancelled-shipments"
          />
          <FilterCheckbox
            disabled={state.isLoading}
            label={'Vain tuplaosoitteet'}
            checked={state.viewSettings.onlyShipmentsWithDuplicaDeliveryAddress}
            onChange={(_e) => {
              dispatch({
                type: 'SET_VIEW_SETTINGS',
                payload: {
                  ...state.viewSettings,
                  onlyShipmentsWithDuplicaDeliveryAddress: !state.viewSettings.onlyShipmentsWithDuplicaDeliveryAddress,
                },
              });
            }}
            name="duplicate_delivery_address_shipments"
            className="duplicate-delivery-address-shipments"
            tooltip="Näytä vain toimitukset, joissa on enemmän kuin yksi toimitus samalle kadulle."
          />
          <FilterCheckbox
            disabled={state.isLoading}
            label={'Näytä tilaväritykset'}
            checked={state.viewSettings.showStateColors}
            onChange={(_e) => {
              dispatch({
                type: 'SET_VIEW_SETTINGS',
                payload: {
                  ...state.viewSettings,
                  showStateColors: !state.viewSettings.showStateColors,
                },
              });
            }}
            name="show_state_colors"
            className="show-state-colors"
          />
        </FilterContainer>
      </DialogContent>
    </Dialog>
  );
};
