/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Shipment, ShipmentFromJSON, ShipmentFromJSONTyped, ShipmentToJSON } from './Shipment';

/**
 *
 * @export
 * @interface ShipmentsResponseBody1
 */
export interface ShipmentsResponseBody1 {
  /**
   *
   * @type {Array<Shipment>}
   * @memberof ShipmentsResponseBody1
   */
  data: Array<Shipment>;
  /**
   *
   * @type {number}
   * @memberof ShipmentsResponseBody1
   */
  next: number | null;
}

export function ShipmentsResponseBody1FromJSON(json: any): ShipmentsResponseBody1 {
  return ShipmentsResponseBody1FromJSONTyped(json, false);
}

export function ShipmentsResponseBody1FromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentsResponseBody1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(ShipmentFromJSON),
    next: json['next'],
  };
}

export function ShipmentsResponseBody1ToJSON(value?: ShipmentsResponseBody1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(ShipmentToJSON),
    next: value.next,
  };
}
