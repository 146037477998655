import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MRT_Row, MRT_RowData, MRT_TableInstance } from 'material-react-table';
import React from 'react';

interface Props<TData extends MRT_RowData> extends BoxProps {
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
  variant?: 'icon' | 'text';
  isValid: boolean;
  isLoading: boolean;
}

export const MRT_EditActionButtons = <TData extends MRT_RowData>({
  row,
  table,
  variant = 'icon',
  isValid,
  isLoading,
}: Props<TData>) => {
  const {
    getState,
    options: {
      icons: { CancelIcon, SaveIcon },
      localization,
      onCreatingRowCancel,
      onCreatingRowSave,
      onEditingRowSave,
      onEditingRowCancel,
    },
    refs: { editInputRefs },
    setEditingRow,
    setCreatingRow,
  } = table;
  const { editingRow, creatingRow } = getState();

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;

  const handleCancel = () => {
    if (isCreating) {
      onCreatingRowCancel?.({ row, table });
      setCreatingRow(null);
    } else if (isEditing) {
      onEditingRowCancel?.({ row, table });
      setEditingRow(null);
    }
    row._valuesCache = {} as any; //reset values cache
  };

  const handleSave = () => {
    //look for auto-filled input values
    Object.values(editInputRefs?.current)
      .filter((inputRef) => row.id === inputRef?.name?.split('_')?.[0])
      ?.forEach((input) => {
        if (input.value !== undefined && (Object as any).hasOwn(row?._valuesCache as object, input.name)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          row._valuesCache[input.name] = input.value;
        }
      });
    if (isCreating)
      onCreatingRowSave?.({
        exitCreatingMode: () => setCreatingRow(null),
        row,
        table,
        values: row._valuesCache,
      });
    else if (isEditing) {
      onEditingRowSave?.({
        exitEditingMode: () => setEditingRow(null),
        row,
        table,
        values: row?._valuesCache,
      });
    }
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      {variant === 'icon' ? (
        <>
          <Tooltip arrow title={localization.cancel}>
            <span>
              <IconButton
                disabled={isLoading}
                aria-label={localization.cancel}
                onClick={handleCancel}
                data-cy="cancel-edit-row-button"
              >
                <CancelIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip arrow title={localization.save}>
            <span>
              <IconButton
                disabled={!isValid || isLoading}
                aria-label={localization.save}
                color="success"
                onClick={handleSave}
                data-cy="save-row-button"
              >
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      ) : (
        <>
          <Button disabled={isLoading} onClick={handleCancel}>
            {localization.cancel}
          </Button>
          <Button disabled={!isValid || isLoading} onClick={handleSave} variant="contained">
            {localization.save}
          </Button>
        </>
      )}
    </Box>
  );
};
