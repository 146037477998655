/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PhotoResponseBody
 */
export interface PhotoResponseBody {
  /**
   *
   * @type {string}
   * @memberof PhotoResponseBody
   */
  photoId?: string;
}

export function PhotoResponseBodyFromJSON(json: any): PhotoResponseBody {
  return PhotoResponseBodyFromJSONTyped(json, false);
}

export function PhotoResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    photoId: !exists(json, 'photoId') ? undefined : json['photoId'],
  };
}

export function PhotoResponseBodyToJSON(value?: PhotoResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    photoId: value.photoId,
  };
}
