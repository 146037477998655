import React, { Dispatch, useEffect, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import { Action, getEmployeePostPutBodyFromState, getInitialState, reducer, State } from './employee.state';
import { api, Driver, getAllPages } from '../../api';
import { useParams } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AllEmployeeFields } from './components/inputs';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const load = async (employeeId: Driver['id'], dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [driversResponse, usersResponse] = await Promise.all([
      getAllPages(api.drivers.getDrivers.bind(api.drivers), { alsoInactive: true }),
      getAllPages(api.users.getUsers.bind(api.users), {}),
    ]);
    const editableEmployee = driversResponse.find((driver) => driver.id === employeeId);
    if (!editableEmployee) {
      throw new Error('Employee not found');
    }
    dispatch({
      type: 'INITIALIZE_EMPLOYEE',
      payload: {
        employee: editableEmployee,
      },
    });
    dispatch({
      type: 'SET_EMPLOYEES',
      payload: {
        employees: driversResponse,
      },
    });
    dispatch({
      type: 'SET_USERS',
      payload: {
        users: usersResponse,
      },
    });
    const driverGroups = await api.employees.getDriverGroups({ driverId: employeeId });
    dispatch({
      type: 'SET_DRIVER_GROUPS',
      payload: {
        driverGroups,
      },
    });
    dispatch({
      type: 'VALIDATE_FIELDS',
    });
  } catch (err) {
    let message = 'Työntekijän lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Työntekijää ei löytynyt!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const saveEmployee = async (employeeId: Driver['id'], state: State, dispatch: Dispatch<Action>) => {
  if (!employeeId) {
    throw new Error('Missing driver id');
  }
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });

  try {
    const updatedDriver = (
      await api.drivers.updateDriver({
        driverId: employeeId,
        driverPostPutBody: getEmployeePostPutBodyFromState(state),
      })
    ).data;
    if (!updatedDriver) {
      throw new Error('Updating user failed');
    }
    const groupsToDelete = state.driverGroups.notMemberOf.filter((group) => group.isModified);
    const groupsToAdd = state.driverGroups.memberOf.filter((group) => group.isModified);
    for (const modifiedEmployeeDriverGroup of groupsToAdd) {
      await api.employees.postDriverGroups({
        driverId: employeeId,
        driverDriverGroupsPostBody: { group_id: modifiedEmployeeDriverGroup.id },
      });
    }
    for (const modifiedEmployeeDriverGroup of groupsToDelete) {
      await api.employees.deleteDriverGroups({
        driverId: employeeId,
        driverGroupsDeleteBody: { group_id: modifiedEmployeeDriverGroup.id },
      });
    }
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Työntekijä tallennettu!' } });
    load(updatedDriver.id, dispatch);
  } catch (err) {
    dispatch(tryAgainMessage);
    dispatch({ type: 'SET_LOADING', payload: false });

    console.error(err);
  }
};

type EditEmployeeParams = {
  employeeId?: string;
};

const EditEmployee: React.FC = () => {
  const currentUser = useCurrentUser();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { employeeId } = useParams<EditEmployeeParams>();
  const parsedEmployeeId = parseInt(employeeId || '');
  const employeeName = `${state.originalEmployee?.first_name ?? ''} ${state.originalEmployee?.last_name ?? ''}`;

  useEffect(() => {
    if (currentUser && employeeId) {
      load(parsedEmployeeId, dispatch);
    }
  }, [currentUser, employeeId, saveEmployee]);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title={`Työntekijä ${employeeName.length > 1 ? employeeName : parsedEmployeeId}`}>
            <SaveButton
              disabled={!state.isValid || state.isLoading}
              id="save-employee-button"
              tooltip={!state.isValid ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (state.originalEmployee && currentUser) {
                  saveEmployee(parsedEmployeeId, state, dispatch);
                  event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
                }
              }}
            >
              Tallenna
            </SaveButton>
          </Header>
        </HeaderContainer>
        <AllEmployeeFields state={state} dispatch={dispatch} createNewEmployee={false} />
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default EditEmployee;
