/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LogInPostBody
 */
export interface LogInPostBody {
  /**
   *
   * @type {string}
   * @memberof LogInPostBody
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof LogInPostBody
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof LogInPostBody
   */
  token?: boolean;
}

export function LogInPostBodyFromJSON(json: any): LogInPostBody {
  return LogInPostBodyFromJSONTyped(json, false);
}

export function LogInPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogInPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: json['username'],
    password: json['password'],
    token: !exists(json, 'token') ? undefined : json['token'],
  };
}

export function LogInPostBodyToJSON(value?: LogInPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    password: value.password,
    token: value.token,
  };
}
