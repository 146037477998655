import { Dispatch } from 'react';
import {
  api,
  Car,
  Driver,
  getAllPages,
  Load,
  Organization,
  OrganizationCar,
  OrganizationDriver,
  User,
} from '../../api';
import { TableState } from 'react-table';
import { updateViewSettings } from '../settings';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { canAccessCoordination } from '../../utils';
import { DateRange, getDefaultDateRange, SetDateRangeAction } from '../../utils/dateRangeUtils';

export interface EnrichedLoad extends Load {
  organizationName: string;
  driver: Driver | OrganizationDriver | null;
  car: Car | OrganizationCar | null;
  trailerLicenceplate: string;
}

export interface LoadsViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
}

export interface State {
  organizations: Organization[];
  loads: EnrichedLoad[];
  drivers: Driver[] | OrganizationDriver[];
  cars: Car[] | OrganizationCar[];
  notification: NotificationType;
  dateRange: DateRange;
  isLoading: boolean;
  viewSettings: LoadsViewSettings;
}

export const getInitialState = (): State => {
  return {
    organizations: [],
    loads: [],
    drivers: [],
    cars: [],
    notification: {
      message: null,
    },
    dateRange: getDefaultDateRange(),
    isLoading: true,
    viewSettings: {
      filters: [],
      sortBy: [],
      hiddenColumns: [],
    },
  };
};

export type Action =
  | { type: 'SET_ORGANIZATIONS'; payload: Organization[] }
  | { type: 'SET_LOADS'; payload: Load[] }
  | { type: 'SET_DRIVERS'; payload: Driver[] | OrganizationDriver[] }
  | { type: 'SET_CARS'; payload: Car[] | OrganizationCar[] }
  | SetMessageAction
  | SetDateRangeAction
  | SetLoadingAction
  | { type: 'SET_VIEW_SETTINGS'; payload: LoadsViewSettings };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_ORGANIZATIONS':
      return {
        ...state,
        organizations: action.payload,
      };
    case 'SET_LOADS':
      return {
        ...state,
        loads: action.payload.map((load) => {
          return {
            ...load,
            organizationName:
              state.organizations.find((organization) => organization.id === load.organization_id)?.name ?? '',
            driver: state.drivers.find((driver) => driver.id === load?.driver_id) ?? null,
            car: state.cars.find((car) => car.id === load?.car_id) ?? null,
            trailerLicenceplate: state.cars.find((car) => car.id === load?.trailer_id)?.licence_plate ?? '',
          };
        }),
      };
    case 'SET_DRIVERS':
      return {
        ...state,
        drivers: action.payload,
      };
    case 'SET_CARS':
      return {
        ...state,
        cars: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_VIEW_SETTINGS':
      updateViewSettings('loads', action.payload);
      return {
        ...state,
        viewSettings: action.payload,
      };
  }
};

export const load = async (
  currentUser: User,
  state: State,
  dispatch: Dispatch<Action>,
  abortController?: AbortController,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [organizationsResponse, loads] = await Promise.all([
      canAccessCoordination(currentUser)
        ? api.organizations.getOrganizations({})
        : api.organizations.getOrganization({ organizationId: currentUser.organization_id }),
      canAccessCoordination(currentUser)
        ? getAllPages(
            api.loads.getLoads.bind(api.loads),
            {
              driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
              driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
            },
            abortController,
          )
        : getAllPages(
            api.organizationLoads.getOrganizationLoads.bind(api.organizationLoads),
            {
              organizationId: currentUser?.organization_id ?? '',
              driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
              driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
            },
            abortController,
          ),
    ]);
    const loadIds = loads.map((load) => load.id);
    const [drivers, cars] = await Promise.all([
      canAccessCoordination(currentUser)
        ? getAllPages(api.drivers.getDrivers.bind(api.drivers), {}, abortController)
        : loadIds.length > 0
          ? getAllPages(
              api.organizationDrivers.getOrganizationDrivers.bind(api.organizationDrivers),
              {
                organizationId: currentUser.organization_id,
                loadIds: loadIds,
                driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
                driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
              },
              abortController,
            )
          : [],
      canAccessCoordination(currentUser)
        ? getAllPages(api.cars.getCars.bind(api.cars), {}, abortController)
        : loadIds.length > 0
          ? getAllPages(
              api.organizationCars.getOrganizationCars.bind(api.organizationCars),
              {
                organizationId: currentUser.organization_id,
                loadIds: loadIds,
                driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
                driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
              },
              abortController,
            )
          : [],
    ]);
    // Action order matters here!
    dispatch({
      type: 'SET_ORGANIZATIONS',
      payload: Array.isArray(organizationsResponse.data) ? organizationsResponse.data : [organizationsResponse.data],
    });
    dispatch({
      type: 'SET_CARS',
      payload: cars,
    });
    dispatch({
      type: 'SET_DRIVERS',
      payload: drivers,
    });
    dispatch({
      type: 'SET_LOADS',
      payload: loads,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa kuormia',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};
