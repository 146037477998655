/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FieldAccess {
  Readonly = 'readonly',
  Editable = 'editable',
  Hidden = 'hidden',
}

export function FieldAccessFromJSON(json: any): FieldAccess {
  return FieldAccessFromJSONTyped(json, false);
}

export function FieldAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldAccess {
  return json as FieldAccess;
}

export function FieldAccessToJSON(value?: FieldAccess | null): any {
  return value as any;
}
