/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Office, OfficeFromJSON, OfficeFromJSONTyped, OfficeToJSON } from './Office';

/**
 *
 * @export
 * @interface OfficeResponseBody
 */
export interface OfficeResponseBody {
  /**
   *
   * @type {Office}
   * @memberof OfficeResponseBody
   */
  data: Office;
}

export function OfficeResponseBodyFromJSON(json: any): OfficeResponseBody {
  return OfficeResponseBodyFromJSONTyped(json, false);
}

export function OfficeResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfficeResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: OfficeFromJSON(json['data']),
  };
}

export function OfficeResponseBodyToJSON(value?: OfficeResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: OfficeToJSON(value.data),
  };
}
