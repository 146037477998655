import { Box, IconButton, MenuItem, Select, styled, Typography } from '@mui/material';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import React from 'react';
import theme from '../theme';

export const StyledBox = styled(Box)({
  position: 'sticky',
  left: 0,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
});

export interface PaginationProps {
  pageSize: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageIndex: number;
  pageCount: number;
  pageOptions: { length: number };
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  gotoPage: (pageSize: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  pageSize,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageCount,
  pageOptions,
  nextPage,
  previousPage,
  setPageSize,
  gotoPage,
}) => {
  return (
    <StyledBox>
      <Select
        disableUnderline
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 25, 50, 75, 100].map((pageSize) => (
          <MenuItem key={pageSize} value={pageSize}>
            {pageSize} riviä
          </MenuItem>
        ))}
      </Select>
      <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage} size="large">
        <FirstPage />
      </IconButton>
      <IconButton onClick={() => previousPage()} disabled={!canPreviousPage} size="large">
        <KeyboardArrowLeft />
      </IconButton>
      <Typography className="table-page-counter" display="inline" noWrap>
        {pageOptions.length > 0 ? pageIndex + 1 : 0} / {pageOptions.length}
      </Typography>
      <IconButton onClick={() => nextPage()} disabled={!canNextPage} size="large">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} size="large">
        <LastPage />
      </IconButton>
    </StyledBox>
  );
};
