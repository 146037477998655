/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Client
 */
export interface Client {
  /**
   *
   * @type {string}
   * @memberof Client
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof Client
   */
  is_active: boolean;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  business_id: string | null;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  default_agreed_delivery_window_starts_at: string | null;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  default_agreed_delivery_window_ends_at: string | null;
}

export function ClientFromJSON(json: any): Client {
  return ClientFromJSONTyped(json, false);
}

export function ClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Client {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    is_active: json['is_active'],
    name: json['name'],
    business_id: json['business_id'],
    default_agreed_delivery_window_starts_at: json['default_agreed_delivery_window_starts_at'],
    default_agreed_delivery_window_ends_at: json['default_agreed_delivery_window_ends_at'],
  };
}

export function ClientToJSON(value?: Client | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    is_active: value.is_active,
    name: value.name,
    business_id: value.business_id,
    default_agreed_delivery_window_starts_at: value.default_agreed_delivery_window_starts_at,
    default_agreed_delivery_window_ends_at: value.default_agreed_delivery_window_ends_at,
  };
}
