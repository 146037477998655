/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Distance, DistanceFromJSON, DistanceFromJSONTyped, DistanceToJSON } from './Distance';
import { Shipment, ShipmentFromJSON, ShipmentFromJSONTyped, ShipmentToJSON } from './Shipment';

/**
 *
 * @export
 * @interface UpdateDistanceAndItsRelatedShipmentsResponseBody
 */
export interface UpdateDistanceAndItsRelatedShipmentsResponseBody {
  /**
   *
   * @type {Distance}
   * @memberof UpdateDistanceAndItsRelatedShipmentsResponseBody
   */
  distance: Distance;
  /**
   *
   * @type {Array<Shipment>}
   * @memberof UpdateDistanceAndItsRelatedShipmentsResponseBody
   */
  shipments: Array<Shipment>;
}

export function UpdateDistanceAndItsRelatedShipmentsResponseBodyFromJSON(
  json: any,
): UpdateDistanceAndItsRelatedShipmentsResponseBody {
  return UpdateDistanceAndItsRelatedShipmentsResponseBodyFromJSONTyped(json, false);
}

export function UpdateDistanceAndItsRelatedShipmentsResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateDistanceAndItsRelatedShipmentsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    distance: DistanceFromJSON(json['distance']),
    shipments: (json['shipments'] as Array<any>).map(ShipmentFromJSON),
  };
}

export function UpdateDistanceAndItsRelatedShipmentsResponseBodyToJSON(
  value?: UpdateDistanceAndItsRelatedShipmentsResponseBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    distance: DistanceToJSON(value.distance),
    shipments: (value.shipments as Array<any>).map(ShipmentToJSON),
  };
}
