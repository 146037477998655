import React, { Dispatch, useEffect, useState } from 'react';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Alert, Button, Checkbox, Link, styled } from '@mui/material';
import { Accessor, CellValue, Column, Row, UseTableInstanceProps } from 'react-table';
import { DateRangePicker } from '../../components/DateRangePicker';
import { getViewSettings } from '../settings';
import { Action, ReportShipment, ShipmentBillingReportViewSettings, State } from './reports.state';
import { FilterContainer } from '../../components/StyledComponents/FilterContainer';
import { FilterCheckbox } from '../../components/FilterCheckbox';
import { api, getAllPages, Office, Organization, OrganizationShipment, ShipmentStateEnum } from '../../api';
import { OrganizationPicker } from '../../components/OrganizationPicker';
import { dateFormatUnderscore, formatDate, formatFloat, formatPriceBasis } from '../../formatters';
import theme from '../../theme';
import { OfficePicker } from '../../components/OfficePicker';
import { AutoCompleteFilter, DateColumnFilter, NoFilter } from '../../components/TableFilters';
import { Link as RouterLink } from 'react-router-dom';
import { ReactTable } from '../../components/ReactTable';
import { DateTime } from 'luxon';
import { dateNotRequired } from '../../validation';
import { downloadInvoice } from '../../components/Invoice';
import { ifDateExistGetSameDateAtMidday } from '../../utils';
import { StyledToolbar } from '../../components/StyledComponents/StyledToolbar';
import { getJSDateOrNull, StandardDatePicker } from '../../components/DateAndTimePickers/StandardDatePicker';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { chunk } from 'lodash';
import { getDateRangePickerPropsFromState } from '../../utils/dateRangeUtils';

const VIEW_ID = 'shipment_billing_report' as const;

const ActionContainer = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
});

const fetchData = async (state: State, dispatch: React.Dispatch<Action>, abortController?: AbortController) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    if (state.organizations.length === 0 || state.offices.length === 0) {
      const [organizationsResponse, officesResponse] = await Promise.all([
        api.organizations.getOrganizations({}),
        api.offices.getOffices(),
      ]);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          organizations: organizationsResponse?.data ?? [],
          offices: officesResponse.data.filter((office) => office.billing_enabled) ?? [],
        },
      });
    }
    if (state.shipmentBillingReportViewSettings.organization) {
      const [organizationShipmentsResponse, organizationLoadsResponse] = await Promise.all([
        state.shipmentBillingReportViewSettings.organization
          ? getAllPages(
              api.organizationShipments.getOrganizationShipments.bind(api.organizationShipments),
              {
                organizationId: state.shipmentBillingReportViewSettings.organization.id,
                agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
                agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
                filterOutOtherCarriers: true,
                getShipmentsByDriveDate: true,
              },
              abortController,
            )
          : [],
        getAllPages(
          api.organizationLoads.getOrganizationLoads.bind(api.organizationLoads),
          {
            organizationId: state.shipmentBillingReportViewSettings.organization.id,
            driveDateRangeStartsAt: state.dateRange.start.toJSDate(),
            driveDateRangeEndsAt: state.dateRange.end.toJSDate(),
          },
          abortController,
        ),
      ]);
      dispatch({
        type: 'SET_ORGANIZATION_SHIPMENTS',
        payload: {
          organizationShipments: organizationShipmentsResponse
            .filter((shipment) => shipment.state !== ShipmentStateEnum.Peruttu)
            .map((shipment) => {
              return {
                ...shipment,
                organizationName: state.organizations.find(
                  (organization) => organization.id === shipment.organization_id,
                )?.name,
                billingOfficeName:
                  state.offices.find(
                    (office) =>
                      office.organization_specific_office_id === shipment.billing_office_organization_specific_id &&
                      office.organization_id === shipment.organization_id,
                  )?.name ?? '',
                driveDate: organizationLoadsResponse.find((load) => load.id === shipment.load_id)?.drive_date ?? '',
              };
            }),
        },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe raportin laskennassa',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const patchBilledAtDateToShipments = async (
  shipments: ReportShipment[],
  state: State,
  dispatch: Dispatch<Action>,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const shipmentsPatchBody = shipments.map((shipment) => {
      return {
        id: shipment.id,
        billed_at: ifDateExistGetSameDateAtMidday(state.shipmentBillingReportViewSettings.billingDate),
      };
    });
    for (const patchBodyChunk of chunk(shipmentsPatchBody, 100)) {
      await api.shipments.patchShipments({ shipmentsPatchBody: patchBodyChunk });
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: state.shipmentBillingReportViewSettings.billingDate
          ? `Laskutuspäivämäärä lisätty ${shipments.length} toimitukseen.`
          : `Laskutuspäivämäärä poistettu ${shipments.length} toimituksesta.`,
      },
    });
    fetchData(state, dispatch);
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Laskutuspäivämäärän lisäys epäonnistui!',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const filterShipments = (
  shipments: ReportShipment[],
  showContractPricedShipments: boolean,
  showRegularPricedShipments: boolean,
  showShipmentsWithBillingReferenceNumber: boolean,
  showShipmentsWithoutBillingReferenceNumber: boolean,
  showShipmentsWithBillingDate: boolean,
  organization: Organization | undefined,
  selectedOffices: Office[],
) => {
  const selectedOfficesOrganizationSpecificOfficeIds = selectedOffices.map(
    (office) => office.organization_specific_office_id,
  );
  const filteredShipments = shipments.filter((shipment) => {
    const showByPricing = shipment.has_contract_price ? showContractPricedShipments : showRegularPricedShipments;
    const showByReferenceNumber = !!shipment.billing_reference_number
      ? showShipmentsWithBillingReferenceNumber
      : showShipmentsWithoutBillingReferenceNumber;
    const showByOrgranization = organization ? shipment.organization_id === organization.id : true;
    const showBySelectedOffices =
      selectedOffices.length > 0
        ? selectedOfficesOrganizationSpecificOfficeIds.includes(shipment.billing_office_organization_specific_id)
        : true;
    const showByBillingDate = showShipmentsWithBillingDate ? true : !shipment.billed_at;
    return showByPricing && showByOrgranization && showByReferenceNumber && showBySelectedOffices && showByBillingDate;
  });
  return filteredShipments;
};

interface ShipmentBillingReportProps {
  state: State;
  dispatch: Dispatch<Action>;
}

const sumFooter =
  (field: keyof OrganizationShipment) =>
  (info: UseTableInstanceProps<OrganizationShipment>): string =>
    formatFloat(info.rows.reduce((acc: number, row: Row<OrganizationShipment>) => acc + row.values[field], 0));

export const ShipmentBillingReport: React.FC<ShipmentBillingReportProps> = ({ state, dispatch }) => {
  const currentUser = useCurrentUser();
  const [canSave, setCanSave] = useState<boolean>(false);
  const [showShipmentNote, setShowShipmentNote] = useState<boolean>(false);
  const [addBilledAtDateToShipments, setAddBilledAtDateToShipments] = useState<boolean>(false);
  const [showBillingOffices, setShowBillingOffices] = useState<boolean>(false);

  const tableRef = React.useRef<any>(null);

  const viewSettings = React.useMemo(() => getViewSettings<ShipmentBillingReportViewSettings>(VIEW_ID), []);

  useEffect(() => {
    if (currentUser?.organization_id) {
      const abortController = new AbortController();
      fetchData(state, dispatch, abortController);
      setCanSave(false);
      return () => abortController.abort();
    }
  }, [currentUser, state.dateRange, state.shipmentBillingReportViewSettings.organization]);

  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        Header: ({ rows, getToggleAllRowsSelectedProps, toggleAllRowsSelected }) => {
          useEffect(() => {
            toggleAllRowsSelected(false);
          }, [state.shipmentBillingReportViewSettings.organization]);
          return (
            <Checkbox
              onClick={() => {
                rows.map((row: Row) => {
                  const shipmentId = parseInt(row.id);
                  dispatch({
                    type: 'SELECT_SHIPMENT',
                    payload: {
                      shipmentId: shipmentId,
                      value: !getToggleAllRowsSelectedProps().checked,
                    },
                  });
                });
              }}
              {...getToggleAllRowsSelectedProps()}
            />
          );
        },
        Filter: NoFilter,
        id: 'selection',
        Cell: ({ row, toggleRowSelected }) => {
          const shipmentId = parseInt(row.id);
          return (
            <Checkbox
              size="small"
              checked={(row as any).isSelected}
              onChange={() => {
                toggleRowSelected(shipmentId);
                dispatch({
                  type: 'SELECT_SHIPMENT',
                  payload: {
                    shipmentId: shipmentId,
                    value: !(row as any).isSelected,
                  },
                });
              }}
              name="selected"
            />
          );
        },
      },
      {
        id: 'id',
        Header: 'Id',
        accessor: 'id',
        Cell: ({ row }: { row: Row }) => (
          <Link component={RouterLink} to={{ pathname: `/shipments/${row.values.id}` }}>
            {row.values.id}
          </Link>
        ),
        Footer: 'Yhteensä',
      },
      {
        id: 'reference_number',
        Header: 'Asiakasviite',
        accessor: 'reference_number',
      },
      {
        id: 'organization',
        Header: 'Asiakas',
        accessor: 'organizationName',
        Filter: AutoCompleteFilter,
      },
      {
        id: 'billingOfficeName',
        Header: 'Kustannuspaikka',
        accessor: 'billingOfficeName',
        Filter: AutoCompleteFilter,
      },
      {
        id: 'driveDate',
        Header: 'Ajopäivä',
        accessor: 'driveDate',
        Cell: (cell: CellValue) => (cell.value ? formatDate(cell.value) : ''),
        Filter: DateColumnFilter,
      },
      {
        Header: 'Toimitusasiakas',
        accessor: 'delivery_name',
      },
      {
        id: 'has_contract_price',
        Header: 'Sopimushinta',
        accessor: ((shipment: CellValue) =>
          shipment.has_contract_price === true
            ? 'Kyllä'
            : shipment.has_contract_price === false
              ? 'Ei'
              : '') as Accessor<any>,
      },
      {
        Header: 'Hinta',
        accessor: 'price',
        Footer: sumFooter('price'),
      },
      {
        id: 'price_basis',
        Header: 'Hintaperuste',
        accessor: 'price_basis',
        Cell: ({ row }: { row: Row }) => (
          <pre style={{ fontFamily: theme.typography.fontFamily, fontSize: theme.typography.fontSize }}>
            {row.values.price_basis ? formatPriceBasis(row.values.price_basis, true) : ''}
          </pre>
        ),
      },
      {
        id: 'billing_reference_number',
        Header: 'Laskutusviite',
        accessor: 'billing_reference_number',
      },
      {
        Header: 'Laskutuspäivämäärä',
        accessor: 'billed_at',
        Cell: (billedAt: CellValue) => (billedAt.value ? formatDate(billedAt.value) : ''),
        Filter: DateColumnFilter,
        sortType: 'datetime',
      },
    ],
    [],
  );
  const data = React.useMemo(
    () =>
      filterShipments(
        state.organizationShipments,
        state.shipmentBillingReportViewSettings.showContractPricedShipments,
        state.shipmentBillingReportViewSettings.showRegularPricedShipments,
        state.shipmentBillingReportViewSettings.showShipmentsWithBillingReferenceNumber,
        state.shipmentBillingReportViewSettings.showShipmentsWithoutBillingReferenceNumber,
        state.shipmentBillingReportViewSettings.showShipmentsWithBillingDate,
        state.shipmentBillingReportViewSettings.organization,
        state.shipmentBillingReportViewSettings.selectedOffices,
      ),
    [
      state.organizationShipments,
      state.shipmentBillingReportViewSettings.showContractPricedShipments,
      state.shipmentBillingReportViewSettings.showRegularPricedShipments,
      state.shipmentBillingReportViewSettings.showShipmentsWithBillingReferenceNumber,
      state.shipmentBillingReportViewSettings.showShipmentsWithoutBillingReferenceNumber,
      state.shipmentBillingReportViewSettings.showShipmentsWithBillingDate,
      state.shipmentBillingReportViewSettings.organization,
      state.shipmentBillingReportViewSettings.selectedOffices,
    ],
  );
  const [selectedShipments, setSelectedShipments] = useState<Record<ReportShipment['id'], boolean>>(
    state.selectedShipments,
  );
  const billedAtDateExists = state.shipmentBillingReportViewSettings.billingDate;
  const visibleShipments = tableRef.current ? tableRef.current.rows.map((r: Row<ReportShipment>) => r.original) : [];
  const selectedVisibleShipments = visibleShipments.filter(
    (shipment: ReportShipment) => state.selectedShipments[shipment.id],
  );
  const selectedVisibleShipmentsExists = Object.values(selectedVisibleShipments).length > 0;
  const visibleShipmentsExists = visibleShipments.length > 0 && visibleShipments.length !== data.length;
  const billedAtShipmentsExists = selectedVisibleShipmentsExists
    ? data.filter((shipment) => state.selectedShipments[shipment.id]).find((shipment) => shipment.billed_at)
    : data.find((shipment) => shipment.billed_at);
  const isBillingDateValid = state.shipmentBillingReportViewSettings.billingDate
    ? DateTime.fromJSDate(state.shipmentBillingReportViewSettings.billingDate).isValid &&
      !Boolean(dateNotRequired.validate(state.shipmentBillingReportViewSettings.billingDate).error)
    : true;
  const createInvoiceText = `Luo lasku ${visibleShipmentsExists ? 'suodatetuista' : ''}
    ${visibleShipmentsExists && selectedVisibleShipmentsExists ? 'ja' : ''}
    ${selectedVisibleShipmentsExists ? 'valituista' : ''} toimituksista`;
  const addOrRemoveBillingDateText =
    addBilledAtDateToShipments && state.shipmentBillingReportViewSettings.billingDate
      ? ' ja lisää laskutuspvm'
      : addBilledAtDateToShipments && !state.shipmentBillingReportViewSettings.billingDate
        ? ' ja poista laskutuspvm'
        : '';

  const createInvoiceColor =
    addBilledAtDateToShipments && state.shipmentBillingReportViewSettings.billingDate
      ? 'success'
      : addBilledAtDateToShipments && !state.shipmentBillingReportViewSettings.billingDate
        ? 'error'
        : undefined;

  return (
    <>
      <StyledToolbar>
        <div style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'center' }}>
          <ActionContainer>
            <DateRangePicker {...getDateRangePickerPropsFromState(state, dispatch)} />
            <OrganizationPicker
              disabled={state.isLoading}
              variant="outlined"
              style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
              size="small"
              organizations={state.organizations}
              value={state.shipmentBillingReportViewSettings.organization ?? null}
              onChange={(organization) => {
                dispatch({
                  type: 'SET_VIEW_SETTINGS',
                  payload: {
                    viewId: VIEW_ID,
                    shipmentBillingReportViewSettings: {
                      ...state.shipmentBillingReportViewSettings,
                      organization: organization ?? undefined,
                      selectedOffices:
                        state.shipmentBillingReportViewSettings.organization !== organization
                          ? []
                          : state.shipmentBillingReportViewSettings.selectedOffices,
                    },
                  },
                });
                dispatch({
                  type: 'CLEAR_SELECTED_SHIPMENTS',
                });
                tableRef.current?.deselectAllRows();
              }}
              required
            />
            <OfficePicker
              disabled={state.isLoading}
              variant="outlined"
              offices={
                state.offices?.filter(
                  (office) => office.organization_id === state.shipmentBillingReportViewSettings?.organization?.id,
                ) ?? []
              }
              value={state.shipmentBillingReportViewSettings.selectedOffices}
              onChange={(offices) => {
                dispatch({
                  type: 'SET_VIEW_SETTINGS',
                  payload: {
                    viewId: VIEW_ID,
                    shipmentBillingReportViewSettings: {
                      ...state.shipmentBillingReportViewSettings,
                      selectedOffices: offices,
                    },
                  },
                });
                setShowBillingOffices(offices.length > 0 ? true : false);
                dispatch({
                  type: 'CLEAR_SELECTED_SHIPMENTS',
                });
                tableRef.current?.deselectAllRows();
              }}
            />
          </ActionContainer>
          <FilterContainer style={{ alignItems: 'start', marginLeft: '1rem' }}>
            <FilterCheckbox
              disabled={state.isLoading}
              label={'listahintaiset'}
              checked={state.shipmentBillingReportViewSettings.showRegularPricedShipments}
              onChange={(_e) => {
                dispatch({
                  type: 'SET_VIEW_SETTINGS',
                  payload: {
                    viewId: VIEW_ID,
                    shipmentBillingReportViewSettings: {
                      ...state.shipmentBillingReportViewSettings,
                      showRegularPricedShipments: !state.shipmentBillingReportViewSettings.showRegularPricedShipments,
                    },
                  },
                });
              }}
              name="show_regular_priced_shipments"
              className="show-regular-priced-shipments"
            />
            <FilterCheckbox
              disabled={state.isLoading}
              label={'sopimushintaiset'}
              checked={state.shipmentBillingReportViewSettings.showContractPricedShipments}
              onChange={(_e) => {
                dispatch({
                  type: 'SET_VIEW_SETTINGS',
                  payload: {
                    viewId: VIEW_ID,
                    shipmentBillingReportViewSettings: {
                      ...state.shipmentBillingReportViewSettings,
                      showContractPricedShipments: !state.shipmentBillingReportViewSettings.showContractPricedShipments,
                    },
                  },
                });
              }}
              name="show_contract_priced_shipments"
              className="show-contract-priced-shipments"
            />
            <FilterCheckbox
              disabled={state.isLoading}
              label={'laskutusviitteelliset'}
              checked={state.shipmentBillingReportViewSettings.showShipmentsWithBillingReferenceNumber}
              onChange={(_e) => {
                dispatch({
                  type: 'SET_VIEW_SETTINGS',
                  payload: {
                    viewId: VIEW_ID,
                    shipmentBillingReportViewSettings: {
                      ...state.shipmentBillingReportViewSettings,
                      showShipmentsWithBillingReferenceNumber:
                        !state.shipmentBillingReportViewSettings.showShipmentsWithBillingReferenceNumber,
                    },
                  },
                });
              }}
              name="show_shipments_with_billing_reference_number"
              className="show-shipments-with-billing-reference-number"
            />
            <FilterCheckbox
              disabled={state.isLoading}
              label={'laskutusviitteettömät'}
              checked={state.shipmentBillingReportViewSettings.showShipmentsWithoutBillingReferenceNumber}
              onChange={(_e) => {
                dispatch({
                  type: 'SET_VIEW_SETTINGS',
                  payload: {
                    viewId: VIEW_ID,
                    shipmentBillingReportViewSettings: {
                      ...state.shipmentBillingReportViewSettings,
                      showShipmentsWithoutBillingReferenceNumber:
                        !state.shipmentBillingReportViewSettings.showShipmentsWithoutBillingReferenceNumber,
                    },
                  },
                });
              }}
              name="show_shipments_without_billing_reference_number"
              className="show-shipments-without-billing-reference-number"
            />
            <FilterCheckbox
              disabled={state.isLoading}
              label={'laskutetut'}
              checked={state.shipmentBillingReportViewSettings.showShipmentsWithBillingDate}
              onChange={(_e) => {
                dispatch({
                  type: 'SET_VIEW_SETTINGS',
                  payload: {
                    viewId: VIEW_ID,
                    shipmentBillingReportViewSettings: {
                      ...state.shipmentBillingReportViewSettings,
                      showShipmentsWithBillingDate:
                        !state.shipmentBillingReportViewSettings.showShipmentsWithBillingDate,
                    },
                  },
                });
              }}
              name="show_shipments_with_billing_date"
              className="show-shipments-with-billing-date"
            />
          </FilterContainer>
        </div>
        <ActionContainer>
          {!state.shipmentBillingReportViewSettings.organization ? (
            <Alert severity="warning">Valitse ensin asiakas.</Alert>
          ) : null}
          {!billedAtDateExists && Boolean(state.shipmentBillingReportViewSettings.organization) ? (
            <Alert className="billed-at-date-missing-alert" severity="warning">
              Laskutuspäivämäärä puuttuu.
            </Alert>
          ) : null}
          {billedAtShipmentsExists && Boolean(state.shipmentBillingReportViewSettings.organization) ? (
            <Alert className="already-billed-shipments-alert" severity="warning">
              Osa valituista toimituksista on jo laskutettu.
            </Alert>
          ) : null}
          <StandardDatePicker
            disabled={state.isLoading || Boolean(!state.shipmentBillingReportViewSettings.organization)}
            label="Laskutuspäivämäärä"
            value={state.shipmentBillingReportViewSettings.billingDate}
            className="billing-date"
            slotProps={{
              textField: {
                variant: 'outlined',
                name: 'billing_date',
                error: !isBillingDateValid,
                helperText: dateNotRequired.validate(state.shipmentBillingReportViewSettings.billingDate).error
                  ?.message,
              },
            }}
            onChange={(date) => {
              dispatch({
                type: 'SET_VIEW_SETTINGS',
                payload: {
                  viewId: VIEW_ID,
                  shipmentBillingReportViewSettings: {
                    ...state.shipmentBillingReportViewSettings,
                    billingDate: getJSDateOrNull(date),
                  },
                },
              });
              if (!date) {
                setAddBilledAtDateToShipments(false);
              }
            }}
          />
          <FilterCheckbox
            disabled={state.isLoading || Boolean(!state.shipmentBillingReportViewSettings.organization)}
            label={
              state.shipmentBillingReportViewSettings.billingDate
                ? 'Lisää toimituksille laskutuspvm'
                : 'Poista toimituksien laskutuspvm'
            }
            checked={addBilledAtDateToShipments}
            onChange={(_e) => {
              setAddBilledAtDateToShipments(!addBilledAtDateToShipments);
            }}
            name="add-billed-at-date-to-shipments-checkbox"
            className="add-billed-at-date-to-shipments-checkbox"
          />
          <FilterCheckbox
            disabled={state.isLoading || Boolean(!state.shipmentBillingReportViewSettings.organization)}
            label={'Näytä toimituksen lisätiedot erittelyssä'}
            checked={showShipmentNote}
            onChange={(_e) => {
              setShowShipmentNote(!showShipmentNote);
            }}
            name="show-shipment-note-in-billing-report-checkbox"
            className="show-shipment-note-in-billing-report-checkbox"
          />
          <FilterCheckbox
            disabled={state.isLoading || Boolean(!state.shipmentBillingReportViewSettings.organization)}
            label={'Näytä kustannuspaikat laskun erittelyssä'}
            checked={showBillingOffices}
            onChange={(_e) => {
              setShowBillingOffices(!showBillingOffices);
            }}
            name="show-billing-offices-in-billing-report-checkbox"
            className="show-billing-offices-in-billing-report-checkbox"
          />
          {(!billedAtDateExists || billedAtShipmentsExists) &&
          Boolean(state.shipmentBillingReportViewSettings.organization) ? (
            <FilterCheckbox
              disabled={state.isLoading || Boolean(!state.shipmentBillingReportViewSettings.organization)}
              label={'Luo lasku varoituksista huolimatta'}
              checked={canSave}
              onChange={(_e) => {
                setCanSave(!canSave);
              }}
              name="confirm-create-billing-report-checkbox"
              className="confirm-create-billing-report-checkbox"
            />
          ) : null}
          <Button
            color={createInvoiceColor}
            disabled={
              state.isLoading ||
              Boolean(!state.shipmentBillingReportViewSettings.organization) ||
              !isBillingDateValid ||
              (!canSave && Boolean(!billedAtDateExists || billedAtShipmentsExists))
            }
            style={{ marginTop: '0.5rem' }}
            className="create-billing-report-button"
            onClick={async () => {
              const visibleRows = tableRef.current
                ? tableRef.current.rows.map((r: Row<ReportShipment>) => r.original)
                : [];
              const selectedShipments = visibleRows.filter(
                (shipment: ReportShipment) => state.selectedShipments[shipment.id],
              );
              const shipmentsUsedForInvoice = selectedShipments.length > 0 ? selectedShipments : visibleRows;
              downloadInvoice(
                shipmentsUsedForInvoice,
                state.dateRange?.end.toJSDate() ?? state.shipmentBillingReportViewSettings.billingDate,
                state.dateRange,
                state.shipmentBillingReportViewSettings.organization?.name,
                state.shipmentBillingReportViewSettings.selectedOffices,
                showShipmentNote,
                showBillingOffices,
              );
              if (addBilledAtDateToShipments) {
                patchBilledAtDateToShipments(shipmentsUsedForInvoice, state, dispatch);
              }
            }}
          >
            {createInvoiceText + addOrRemoveBillingDateText}
          </Button>
        </ActionContainer>
      </StyledToolbar>
      <ReactTable
        tableRef={tableRef}
        columns={columns}
        data={data}
        header="Laskutusraportti"
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä toimituksia."
        columnSelector
        exportName={`laskutusraportti_${state.dateRange?.start.toFormat(
          dateFormatUnderscore,
        )}_${state.dateRange?.end.toFormat(dateFormatUnderscore)}`}
        initialState={{
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'id',
              desc: true,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        onStateChange={(tableState) => {
          dispatch({
            type: 'SET_VIEW_SETTINGS',
            payload: {
              viewId: VIEW_ID,
              shipmentBillingReportViewSettings: { ...state.shipmentBillingReportViewSettings, ...tableState },
            },
          });
        }}
        selectedRows={selectedShipments}
        setSelectedRows={setSelectedShipments}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};
