/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  UserResponseBody,
  UserResponseBodyFromJSON,
  UserResponseBodyToJSON,
  UsersResponseBody,
  UsersResponseBodyFromJSON,
  UsersResponseBodyToJSON,
} from '../models';

export interface UsersApiGetUserByIdRequest {
  username: string;
}

export interface UsersApiGetUsersRequest {
  next?: string;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   * Get a user by id.
   */
  async getUserByIdRaw(
    requestParameters: UsersApiGetUserByIdRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<UserResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getUserById.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/users/{username}`.replace(
          `{${'username'}}`,
          encodeURIComponent(String(requestParameters.username)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a user by id.
   */
  async getUserById(
    requestParameters: UsersApiGetUserByIdRequest,
    initOverrides?: RequestInit,
  ): Promise<UserResponseBody> {
    const response = await this.getUserByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all users.
   */
  async getUsersRaw(
    requestParameters: UsersApiGetUsersRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<UsersResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/users`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UsersResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all users.
   */
  async getUsers(requestParameters: UsersApiGetUsersRequest, initOverrides?: RequestInit): Promise<UsersResponseBody> {
    const response = await this.getUsersRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
