/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SearchDistancesPostBody
 */
export interface SearchDistancesPostBody {
  /**
   *
   * @type {string}
   * @memberof SearchDistancesPostBody
   */
  address1?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDistancesPostBody
   */
  city1?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDistancesPostBody
   */
  address2?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDistancesPostBody
   */
  city2?: string;
}

export function SearchDistancesPostBodyFromJSON(json: any): SearchDistancesPostBody {
  return SearchDistancesPostBodyFromJSONTyped(json, false);
}

export function SearchDistancesPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchDistancesPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    address1: !exists(json, 'address1') ? undefined : json['address1'],
    city1: !exists(json, 'city1') ? undefined : json['city1'],
    address2: !exists(json, 'address2') ? undefined : json['address2'],
    city2: !exists(json, 'city2') ? undefined : json['city2'],
  };
}

export function SearchDistancesPostBodyToJSON(value?: SearchDistancesPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    address1: value.address1,
    city1: value.city1,
    address2: value.address2,
    city2: value.city2,
  };
}
