import { Grid2, IconButton, styled, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { api, ShipmentRow } from '../../api';
import { ShipmentRowProductsModal } from '../ShipmentRowProductsModal';
import { orderBy } from 'lodash';
import { ChevronRight, ListAlt } from '@mui/icons-material';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { MRT_Row, MRT_RowData } from 'material-react-table';
import { canAccessCoordination, getShipmentRowStateChip } from '../../utils';

export const SubRowContainer = styled('div')({
  width: '90vw',
});

export const subRowDefaults = {
  container: true,
  rowSpacing: 1,
  columnSpacing: { xs: 1, sm: 2, md: 3 },
};

export interface StarterItemForSubRowProps {
  size: number;
}

export const StarterItemForSubRow: React.FC<StarterItemForSubRowProps> = ({ size }) => {
  return (
    <Grid2 size={{ xs: size }} sx={{ textAlign: 'right' }}>
      <ChevronRight sx={{ opacity: 0.5 }} />
    </Grid2>
  );
};

interface ShipmentRowSubRowsProps {
  shipmentRows: ShipmentRow[];
  hideSizeInformation?: boolean;
}

export const ShipmentRowSubRows: React.FC<ShipmentRowSubRowsProps> = ({ shipmentRows, hideSizeInformation }) => {
  const [modalShipmentRow, setModalShipmentRow] = useState<ShipmentRow | undefined>(undefined);

  const small = hideSizeInformation ? 1 : 0.75;
  const medium = hideSizeInformation ? 1.5 : 1.25;
  const large = hideSizeInformation ? 2 : 1.5;
  const extraLarge = hideSizeInformation ? 4 : 3.5;

  if (shipmentRows.length === 0) {
    return (
      <SubRowContainer>
        <Grid2 {...subRowDefaults}>
          <StarterItemForSubRow size={small} />
          <Grid2>ei toimitusrivejä</Grid2>
        </Grid2>
      </SubRowContainer>
    );
  } else {
    return (
      <SubRowContainer>
        {orderBy(shipmentRows, ['parcel_type', 'total_weight_kg'], ['asc', 'desc']).map((shipmentRow, index) => {
          return (
            <Grid2 {...subRowDefaults} key={index} data-cy="shipment-row-grid">
              <StarterItemForSubRow size={small} />
              <Grid2 size={{ xs: large }}>{shipmentRow.parcel_id ?? ''}</Grid2>
              <Grid2 size={{ xs: small }}>{shipmentRow.parcel_type ?? ''}</Grid2>
              <Grid2 size={{ xs: extraLarge }}>{shipmentRow.name}</Grid2>
              {hideSizeInformation ? null : (
                <>
                  <Grid2 size={{ xs: small }} alignSelf={'right'}>{`${shipmentRow.quantity} kpl`}</Grid2>
                  <Grid2 size={{ xs: small }} alignSelf={'right'}>{`${shipmentRow.total_weight_kg} kg`}</Grid2>
                  <Grid2 size={{ xs: small }} alignSelf={'right'}>{`${shipmentRow.volume_m3 ?? 0} m³`}</Grid2>
                  <Grid2
                    size={{ xs: small }}
                    alignSelf={'right'}
                  >{`${shipmentRow.longest_side_length_mm ?? 0} mm`}</Grid2>
                </>
              )}
              <Grid2 size={{ xs: medium }}>{getShipmentRowStateChip(shipmentRow)}</Grid2>
              <Grid2 size={{ xs: small }}>
                <Tooltip
                  data-cy="show-shipment-row-products-button-tooltip"
                  placement="bottom"
                  title={'Näytä lähetysluettelo'}
                  aria-label={'Näytä lähetysluettelo'}
                >
                  <span>
                    <IconButton
                      data-cy="show-shipment-row-products-button"
                      onClick={() => setModalShipmentRow(shipmentRow)}
                      size="small"
                    >
                      <ListAlt />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid2>
            </Grid2>
          );
        })}
        {modalShipmentRow && (
          <ShipmentRowProductsModal
            shipmentRow={modalShipmentRow}
            open={modalShipmentRow !== undefined}
            onClose={() => setModalShipmentRow(undefined)}
          />
        )}
      </SubRowContainer>
    );
  }
};

interface SubRowAsyncProps<TData extends MRT_RowData> {
  row: MRT_Row<TData>;
  hideSizeInformation?: boolean;
}

export const ShipmentRowSubRowAsync = <TData extends MRT_RowData>({
  row,
  hideSizeInformation,
}: SubRowAsyncProps<TData>) => {
  const [shipmentRows, setShipmentRows] = useState<ShipmentRow[]>([]);
  const currentUser = useCurrentUser();
  const hasCoordinationAccess = canAccessCoordination(currentUser);

  const load = async () => {
    const shipmentRows = hasCoordinationAccess
      ? (
          await api.shipments.getShipmentRows({
            shipmentId: Number(row.original.id),
          })
        ).data
      : (
          await api.organizationShipments.getOrganizationShipmentRows({
            organizationId: currentUser?.organization_id ?? '',
            shipmentId: Number(row.original.id),
          })
        ).data;
    setShipmentRows(shipmentRows as ShipmentRow[]);
  };

  useEffect(() => {
    load();
  }, [row.original.id, row.subRows]);

  return <ShipmentRowSubRows shipmentRows={shipmentRows} hideSizeInformation={hideSizeInformation} />;
};
