/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A geographical area that belongs to some larger PricingArea.
 * @export
 * @interface GeographicalAreaPutBody
 */
export interface GeographicalAreaPutBody {
  /**
   *
   * @type {number}
   * @memberof GeographicalAreaPutBody
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof GeographicalAreaPutBody
   */
  pricing_area?: number;
  /**
   *
   * @type {string}
   * @memberof GeographicalAreaPutBody
   */
  area_type: GeographicalAreaPutBodyAreaTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GeographicalAreaPutBody
   */
  name: string;
}

/**
 * @export
 * @enum {string}
 */
export enum GeographicalAreaPutBodyAreaTypeEnum {
  PostalCode = 'postal_code',
  City = 'city',
}

export function GeographicalAreaPutBodyFromJSON(json: any): GeographicalAreaPutBody {
  return GeographicalAreaPutBodyFromJSONTyped(json, false);
}

export function GeographicalAreaPutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeographicalAreaPutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    pricing_area: !exists(json, 'pricing_area') ? undefined : json['pricing_area'],
    area_type: json['area_type'],
    name: json['name'],
  };
}

export function GeographicalAreaPutBodyToJSON(value?: GeographicalAreaPutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    pricing_area: value.pricing_area,
    area_type: value.area_type,
    name: value.name,
  };
}
