/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  OrganizationShipment,
  OrganizationShipmentFromJSON,
  OrganizationShipmentFromJSONTyped,
  OrganizationShipmentToJSON,
} from './OrganizationShipment';

/**
 *
 * @export
 * @interface OrganizationShipmentsResponseBody1
 */
export interface OrganizationShipmentsResponseBody1 {
  /**
   *
   * @type {Array<OrganizationShipment>}
   * @memberof OrganizationShipmentsResponseBody1
   */
  data: Array<OrganizationShipment>;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipmentsResponseBody1
   */
  next: number | null;
}

export function OrganizationShipmentsResponseBody1FromJSON(json: any): OrganizationShipmentsResponseBody1 {
  return OrganizationShipmentsResponseBody1FromJSONTyped(json, false);
}

export function OrganizationShipmentsResponseBody1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationShipmentsResponseBody1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(OrganizationShipmentFromJSON),
    next: json['next'],
  };
}

export function OrganizationShipmentsResponseBody1ToJSON(value?: OrganizationShipmentsResponseBody1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(OrganizationShipmentToJSON),
    next: value.next,
  };
}
