import { Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, Button } from '@mui/material';
import React, { Dispatch, useState } from 'react';
import { CarrierCar, CarrierLoad, CarType, Driver, Client } from '../../../api';
import { FieldSetContainer } from '../../../components/StyledComponents/FieldSetContainer';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { ClientPicker } from '../../../components/ClientPicker';
import { CarPicker } from '../../../components/CarPicker';
import theme from '../../../theme';
import { DriverPicker } from '../../../components/DriverPicker';
import { TrailerPicker } from '../../../components/TrailerPicker';
import { SaveButton } from '../../../components/SaveButton';
import { StyledForm } from '../../../components/StyledComponents/StyledForm';
import { Action, EmptyLoad, State } from '../coordination.state';

interface LoadModalProps {
  open: boolean;
  onClose: () => void;
  dispatch: Dispatch<Action>;
  state: State;
  cars: CarrierCar[];
  drivers: Driver[];
  clients: Client[];
}

export const EmptyLoadModal: React.FC<LoadModalProps> = ({
  open,
  onClose,
  dispatch,
  state,
  cars,
  drivers,
  clients,
}) => {
  const [load, setLoad] = useState<EmptyLoad>({
    driver_id: 0,
    car_id: null,
    organization_id: null,
    trailer_id: null,
  });
  const canSave = load.organization_id && load.driver_id && load.car_id;

  return (
    <Dialog
      className="load-modal"
      aria-labelledby="load-modal-title"
      open={open}
      onClose={() => onClose()}
      closeAfterTransition
      fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
    >
      <DialogTitle id="load-modal-title">Uusi tyhjä kuorma</DialogTitle>
      <DialogContent>
        <StyledForm noValidate autoComplete="off">
          <FieldSetContainer>
            <FieldSet sx={{ width: '100%' }}>
              <legend>Perustiedot</legend>
              <ClientPicker
                data-cy="client-picker"
                clients={clients}
                value={clients.find((client) => client.id === load.organization_id) ?? null}
                onChange={(client) => setLoad({ ...load, organization_id: client !== null ? client.id : null })}
                required
              />
              <CarPicker
                cars={cars.filter((car) => car.type !== CarType.Pervaunu)}
                value={cars.find((car) => car.id === load.car_id) ?? null}
                onChange={(car) =>
                  setLoad({
                    ...load,
                    car_id: car !== null ? car.id : null,
                    driver_id: car !== null ? car.default_driver_id : null,
                    trailer_id: car !== null ? car.default_trailer_id : null,
                  })
                }
              />
              <DriverPicker
                drivers={drivers}
                value={drivers.find((driver) => driver.id === load.driver_id) ?? null}
                onChange={(driver) => setLoad({ ...load, driver_id: driver !== null ? driver.id : null })}
                required={true}
              />
              <TrailerPicker
                currentLoad={load as CarrierLoad}
                loads={[]}
                loadShipments={[]}
                drivers={drivers}
                cars={cars.filter((car) => car.type === CarType.Pervaunu)}
                value={cars.find((trailer) => trailer.id === load.trailer_id) ?? null}
                onChange={(trailer) =>
                  setLoad({
                    ...load,
                    trailer_id: trailer !== null ? trailer.id : null,
                  })
                }
              />
            </FieldSet>
          </FieldSetContainer>
          <DialogActions
            sx={{
              '> button, > div': {
                margin: '0.25rem',
              },
            }}
          >
            <SaveButton
              data-cy="create-new-load-button"
              onClick={() => {
                dispatch({
                  type: 'SET_VIEW_SETTINGS',
                  payload: {
                    ...state.viewSettings,
                    emptyLoads: [...state.viewSettings.emptyLoads, load] as any,
                  },
                });
                setLoad({ ...load, driver_id: null, car_id: null, trailer_id: null });
                onClose();
              }}
              disabled={!canSave}
              tooltip={!canSave ? 'Kaikkia pakollisia kenttiä ei ole täytetty' : ''}
            >
              Luo kuorma
            </SaveButton>
            <Button className="close-modal-button" color="error" onClick={() => onClose()}>
              Peruuta
            </Button>
          </DialogActions>
        </StyledForm>
      </DialogContent>
    </Dialog>
  );
};
