/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FutureWorkHourType,
  FutureWorkHourTypeFromJSON,
  FutureWorkHourTypeFromJSONTyped,
  FutureWorkHourTypeToJSON,
} from './FutureWorkHourType';

/**
 *
 * @export
 * @interface FutureWorkHourPatchBody
 */
export interface FutureWorkHourPatchBody {
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHourPatchBody
   */
  date?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHourPatchBody
   */
  starts_at?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHourPatchBody
   */
  ends_at?: Date | null;
  /**
   *
   * @type {number}
   * @memberof FutureWorkHourPatchBody
   */
  assignment_id?: number;
  /**
   *
   * @type {string}
   * @memberof FutureWorkHourPatchBody
   */
  note?: string | null;
  /**
   *
   * @type {FutureWorkHourType}
   * @memberof FutureWorkHourPatchBody
   */
  type?: FutureWorkHourType;
}

export function FutureWorkHourPatchBodyFromJSON(json: any): FutureWorkHourPatchBody {
  return FutureWorkHourPatchBodyFromJSONTyped(json, false);
}

export function FutureWorkHourPatchBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FutureWorkHourPatchBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: !exists(json, 'date') ? undefined : json['date'] === null ? null : new Date(json['date']),
    starts_at: !exists(json, 'starts_at') ? undefined : json['starts_at'] === null ? null : new Date(json['starts_at']),
    ends_at: !exists(json, 'ends_at') ? undefined : json['ends_at'] === null ? null : new Date(json['ends_at']),
    assignment_id: !exists(json, 'assignment_id') ? undefined : json['assignment_id'],
    note: !exists(json, 'note') ? undefined : json['note'],
    type: !exists(json, 'type') ? undefined : FutureWorkHourTypeFromJSON(json['type']),
  };
}

export function FutureWorkHourPatchBodyToJSON(value?: FutureWorkHourPatchBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date === undefined ? undefined : value.date === null ? null : value.date.toISOString(),
    starts_at:
      value.starts_at === undefined ? undefined : value.starts_at === null ? null : value.starts_at.toISOString(),
    ends_at: value.ends_at === undefined ? undefined : value.ends_at === null ? null : value.ends_at.toISOString(),
    assignment_id: value.assignment_id,
    note: value.note,
    type: FutureWorkHourTypeToJSON(value.type),
  };
}
