/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShipmentRowPostPutBody,
  ShipmentRowPostPutBodyFromJSON,
  ShipmentRowPostPutBodyFromJSONTyped,
  ShipmentRowPostPutBodyToJSON,
} from './ShipmentRowPostPutBody';

/**
 *
 * @export
 * @interface OrganizationShipmentPostBody
 */
export interface OrganizationShipmentPostBody {
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  billing_office_organization_specific_id?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  state?: OrganizationShipmentPostBodyStateEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  orderer: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  orderer_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  job_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  other_contract_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  reference_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  pickup_office_organization_specific_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  pickup_name: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  pickup_address: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  pickup_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  pickup_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  pickup_phone_number_secondary: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  pickup_city: string | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipmentPostBody
   */
  pickup_window_starts_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipmentPostBody
   */
  pickup_window_ends_at: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipmentPostBody
   */
  is_express_delivery: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipmentPostBody
   */
  requires_combination_vehicle: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  delivery_office_organization_specific_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  delivery_name: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  delivery_address: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  delivery_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  delivery_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  delivery_city: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  note: string | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipmentPostBody
   */
  length_ldm: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipmentPostBody
   */
  weight_kg: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipmentPostBody
   */
  volume_m3: number;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipmentPostBody
   */
  agreed_delivery_window_starts_at: Date;
  /**
   *
   * @type {Date}
   * @memberof OrganizationShipmentPostBody
   */
  agreed_delivery_window_ends_at: Date;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipmentPostBody
   */
  pricing_model: number | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipmentPostBody
   */
  is_adr_delivery: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  delivery_phone_number_secondary: string | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationShipmentPostBody
   */
  custom_pricing_category_id: number | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationShipmentPostBody
   */
  billing_reference_number: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationShipmentPostBody
   */
  requires_hoist: boolean;
  /**
   *
   * @type {Array<ShipmentRowPostPutBody>}
   * @memberof OrganizationShipmentPostBody
   */
  rows: Array<ShipmentRowPostPutBody>;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationShipmentPostBodyStateEnum {
  EiVarastossa = 'ei varastossa',
  Noudettavissa = 'noudettavissa',
}

export function OrganizationShipmentPostBodyFromJSON(json: any): OrganizationShipmentPostBody {
  return OrganizationShipmentPostBodyFromJSONTyped(json, false);
}

export function OrganizationShipmentPostBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationShipmentPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    billing_office_organization_specific_id: !exists(json, 'billing_office_organization_specific_id')
      ? undefined
      : json['billing_office_organization_specific_id'],
    state: !exists(json, 'state') ? undefined : json['state'],
    orderer: json['orderer'],
    orderer_phone_number: json['orderer_phone_number'],
    job_number: !exists(json, 'job_number') ? undefined : json['job_number'],
    other_contract_number: !exists(json, 'other_contract_number') ? undefined : json['other_contract_number'],
    reference_number: json['reference_number'],
    pickup_office_organization_specific_id: !exists(json, 'pickup_office_organization_specific_id')
      ? undefined
      : json['pickup_office_organization_specific_id'],
    pickup_name: json['pickup_name'],
    pickup_address: json['pickup_address'],
    pickup_postal_code: json['pickup_postal_code'],
    pickup_phone_number: json['pickup_phone_number'],
    pickup_phone_number_secondary: json['pickup_phone_number_secondary'],
    pickup_city: json['pickup_city'],
    pickup_window_starts_at:
      json['pickup_window_starts_at'] === null ? null : new Date(json['pickup_window_starts_at']),
    pickup_window_ends_at: json['pickup_window_ends_at'] === null ? null : new Date(json['pickup_window_ends_at']),
    is_express_delivery: json['is_express_delivery'],
    requires_combination_vehicle: json['requires_combination_vehicle'],
    delivery_office_organization_specific_id: !exists(json, 'delivery_office_organization_specific_id')
      ? undefined
      : json['delivery_office_organization_specific_id'],
    delivery_name: json['delivery_name'],
    delivery_address: json['delivery_address'],
    delivery_postal_code: json['delivery_postal_code'],
    delivery_phone_number: json['delivery_phone_number'],
    delivery_city: json['delivery_city'],
    note: json['note'],
    length_ldm: json['length_ldm'],
    weight_kg: json['weight_kg'],
    volume_m3: json['volume_m3'],
    agreed_delivery_window_starts_at: new Date(json['agreed_delivery_window_starts_at']),
    agreed_delivery_window_ends_at: new Date(json['agreed_delivery_window_ends_at']),
    pricing_model: json['pricing_model'],
    is_adr_delivery: json['is_adr_delivery'],
    delivery_phone_number_secondary: json['delivery_phone_number_secondary'],
    custom_pricing_category_id: json['custom_pricing_category_id'],
    billing_reference_number: json['billing_reference_number'],
    requires_hoist: json['requires_hoist'],
    rows: (json['rows'] as Array<any>).map(ShipmentRowPostPutBodyFromJSON),
  };
}

export function OrganizationShipmentPostBodyToJSON(value?: OrganizationShipmentPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    billing_office_organization_specific_id: value.billing_office_organization_specific_id,
    state: value.state,
    orderer: value.orderer,
    orderer_phone_number: value.orderer_phone_number,
    job_number: value.job_number,
    other_contract_number: value.other_contract_number,
    reference_number: value.reference_number,
    pickup_office_organization_specific_id: value.pickup_office_organization_specific_id,
    pickup_name: value.pickup_name,
    pickup_address: value.pickup_address,
    pickup_postal_code: value.pickup_postal_code,
    pickup_phone_number: value.pickup_phone_number,
    pickup_phone_number_secondary: value.pickup_phone_number_secondary,
    pickup_city: value.pickup_city,
    pickup_window_starts_at:
      value.pickup_window_starts_at === null ? null : value.pickup_window_starts_at.toISOString(),
    pickup_window_ends_at: value.pickup_window_ends_at === null ? null : value.pickup_window_ends_at.toISOString(),
    is_express_delivery: value.is_express_delivery,
    requires_combination_vehicle: value.requires_combination_vehicle,
    delivery_office_organization_specific_id: value.delivery_office_organization_specific_id,
    delivery_name: value.delivery_name,
    delivery_address: value.delivery_address,
    delivery_postal_code: value.delivery_postal_code,
    delivery_phone_number: value.delivery_phone_number,
    delivery_city: value.delivery_city,
    note: value.note,
    length_ldm: value.length_ldm,
    weight_kg: value.weight_kg,
    volume_m3: value.volume_m3,
    agreed_delivery_window_starts_at: value.agreed_delivery_window_starts_at.toISOString(),
    agreed_delivery_window_ends_at: value.agreed_delivery_window_ends_at.toISOString(),
    pricing_model: value.pricing_model,
    is_adr_delivery: value.is_adr_delivery,
    delivery_phone_number_secondary: value.delivery_phone_number_secondary,
    custom_pricing_category_id: value.custom_pricing_category_id,
    billing_reference_number: value.billing_reference_number,
    requires_hoist: value.requires_hoist,
    rows: (value.rows as Array<any>).map(ShipmentRowPostPutBodyToJSON),
  };
}
