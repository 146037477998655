import { debounce } from 'lodash';
import { Dispatch } from 'react';
import { User } from '../../api';
import { validateFields } from './createNewUserValidation';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';

export interface Field<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value: Value;
}

type ServerFieldNames = keyof Pick<User, 'username' | 'first_name' | 'last_name' | 'email' | 'gsm'>;

type UIOnlyFields = {
  password: Field<string>;
  password_again: Field<string>;
};

export type FieldName = ServerFieldNames | keyof UIOnlyFields;

export interface State {
  fields: {
    [P in ServerFieldNames]: Field<User[P] | ''>;
  } & UIOnlyFields;
  isLoading: boolean;
  notification: NotificationType;
  isValid: boolean;
}

export type Action =
  | {
      type: 'VALIDATE_FIELD';
      payload: {
        fieldName: keyof State['fields'];
      };
    }
  | {
      type: 'VALIDATE_FIELDS';
    }
  | {
      type: 'SET_FIELD_VALUE';
      payload: {
        fieldName: keyof State['fields'];
        value: FieldValue;
      };
    }
  | SetMessageAction
  | SetLoadingAction;

const areAllFieldsValid = (state: Pick<State, 'fields'>) => {
  return !Object.values(state.fields).some((field) => field.hasError);
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD_VALUE': {
      const fields = {
        ...state.fields,
      };
      fields[action.payload.fieldName].value = action.payload.value;
      return {
        ...state,
        fields,
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'VALIDATE_FIELDS': {
      const validatedState = validateFields(state);
      return {
        ...state,
        ...validatedState,
        isValid: areAllFieldsValid(validatedState),
      };
    }
    default: {
      throw new Error(`Unhandled action ${JSON.stringify(action)}`);
    }
  }
};

type FieldValue = string | null;

export const debouncedValidateFieldsDispatch = debounce((dispatch: Dispatch<Action>) => {
  dispatch({
    type: 'VALIDATE_FIELDS',
  });
}, 500);

export const updateFieldValue = (fieldName: FieldName, value: FieldValue, dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_FIELD_VALUE',
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
  debouncedValidateFieldsDispatch(dispatch);
};

export const getInitialState = (createNewUser: boolean): State => {
  return {
    fields: {
      username: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      first_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      last_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      email: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      gsm: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      password: {
        required: createNewUser,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      password_again: {
        required: createNewUser,
        hasError: false,
        feedback: undefined,
        value: '',
      },
    },
    isLoading: false,
    notification: {
      message: null,
    },
    isValid: false,
  };
};
