import { type ChangeEvent, type FocusEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import { type TextFieldProps } from '@mui/material/TextField';
import { MRT_Cell, MRT_RowData, MRT_TableInstance } from 'material-react-table';
import React from 'react';
import { parseFromValuesOrFunc } from './utils';

export interface MRT_EditCellTextAreaProps<TData extends MRT_RowData> extends TextFieldProps<'standard'> {
  cell: MRT_Cell<TData, string | null>;
  table: MRT_TableInstance<TData>;
}

export const MRT_EditCellTextArea = <TData extends MRT_RowData>({
  cell,
  table,
  ...rest
}: MRT_EditCellTextAreaProps<TData>) => {
  const {
    getState,
    options: { createDisplayMode, editDisplayMode, muiEditTextFieldProps },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table;
  const { column, row } = cell;
  const { columnDef } = column;
  const { creatingRow, editingRow } = getState();

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;

  const [value, setValue] = useState(() => cell.getValue<string>());

  const textFieldProps: TextFieldProps = {
    ...parseFromValuesOrFunc(muiEditTextFieldProps as any, {
      cell,
      column,
      row,
      table,
    }),
    ...parseFromValuesOrFunc((columnDef as any).muiEditTextFieldProps, {
      cell,
      column,
      row,
      table,
    }),
    ...rest,
  };

  const saveInputValueToRowCache = (newValue: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    row._valuesCache[column.id] = newValue;
    if (isCreating) {
      setCreatingRow(row);
    } else if (isEditing) {
      setEditingRow(row);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    textFieldProps.onChange?.(event);
    setValue(event.target.value);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    textFieldProps.onBlur?.(event);
    saveInputValueToRowCache(value);
    setEditingCell(null);
  };

  return (
    <TextField
      disabled={parseFromValuesOrFunc(columnDef.enableEditing, row) === false}
      fullWidth
      inputRef={(inputRef) => {
        if (inputRef) {
          editInputRefs.current[column.id] = inputRef;
          if (textFieldProps.inputRef) {
            textFieldProps.inputRef = inputRef;
          }
        }
      }}
      label={
        ['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
          ? columnDef.header
          : undefined
      }
      margin="none"
      name={column.id}
      placeholder={
        !['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
          ? columnDef.header
          : undefined
      }
      size="small"
      value={value ?? ''}
      variant="standard"
      multiline={true}
      {...textFieldProps}
      InputProps={{
        ...(textFieldProps.variant !== 'outlined' ? { disableUnderline: editDisplayMode === 'table' } : {}),
        ...textFieldProps.InputProps,
        sx: (theme) => ({
          mb: 0,
          ...(parseFromValuesOrFunc(textFieldProps?.InputProps?.sx, theme) as any),
        }),
      }}
      SelectProps={{
        MenuProps: { disableScrollLock: true },
        ...textFieldProps.SelectProps,
      }}
      inputProps={{
        autoComplete: 'new-password', //disable autocomplete and autofill
        ...textFieldProps.inputProps,
      }}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={(e) => {
        e.stopPropagation();
        textFieldProps?.onClick?.(e);
      }}
    />
  );
};
