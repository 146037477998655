/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The template for omitting properties.
 * @export
 * @interface CarrierShipmentRowPostBody
 */
export interface CarrierShipmentRowPostBody {
  /**
   *
   * @type {string}
   * @memberof CarrierShipmentRowPostBody
   */
  parcel_id: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierShipmentRowPostBody
   */
  name: string | null;
  /**
   *
   * @type {number}
   * @memberof CarrierShipmentRowPostBody
   */
  quantity: number | null;
  /**
   *
   * @type {string}
   * @memberof CarrierShipmentRowPostBody
   */
  unit: string | null;
  /**
   *
   * @type {number}
   * @memberof CarrierShipmentRowPostBody
   */
  weight_per_piece_kg: number | null;
  /**
   *
   * @type {number}
   * @memberof CarrierShipmentRowPostBody
   */
  total_weight_kg: number | null;
  /**
   *
   * @type {number}
   * @memberof CarrierShipmentRowPostBody
   */
  volume_m3: number | null;
  /**
   *
   * @type {string}
   * @memberof CarrierShipmentRowPostBody
   */
  serial_number: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierShipmentRowPostBody
   */
  parcel_type: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierShipmentRowPostBody
   */
  state: CarrierShipmentRowPostBodyStateEnum;
  /**
   *
   * @type {number}
   * @memberof CarrierShipmentRowPostBody
   */
  longest_side_length_mm: number | null;
  /**
   *
   * @type {string}
   * @memberof CarrierShipmentRowPostBody
   */
  joint_package_id: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum CarrierShipmentRowPostBodyStateEnum {
  ReadyForPickup = 'ready_for_pickup',
  PickedUp = 'picked_up',
  Delivered = 'delivered',
  Lost = 'lost',
  Failed = 'failed',
}

export function CarrierShipmentRowPostBodyFromJSON(json: any): CarrierShipmentRowPostBody {
  return CarrierShipmentRowPostBodyFromJSONTyped(json, false);
}

export function CarrierShipmentRowPostBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CarrierShipmentRowPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    parcel_id: json['parcel_id'],
    name: json['name'],
    quantity: json['quantity'],
    unit: json['unit'],
    weight_per_piece_kg: json['weight_per_piece_kg'],
    total_weight_kg: json['total_weight_kg'],
    volume_m3: json['volume_m3'],
    serial_number: json['serial_number'],
    parcel_type: json['parcel_type'],
    state: json['state'],
    longest_side_length_mm: json['longest_side_length_mm'],
    joint_package_id: json['joint_package_id'],
  };
}

export function CarrierShipmentRowPostBodyToJSON(value?: CarrierShipmentRowPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parcel_id: value.parcel_id,
    name: value.name,
    quantity: value.quantity,
    unit: value.unit,
    weight_per_piece_kg: value.weight_per_piece_kg,
    total_weight_kg: value.total_weight_kg,
    volume_m3: value.volume_m3,
    serial_number: value.serial_number,
    parcel_type: value.parcel_type,
    state: value.state,
    longest_side_length_mm: value.longest_side_length_mm,
    joint_package_id: value.joint_package_id,
  };
}
