/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Load, LoadFromJSON, LoadFromJSONTyped, LoadToJSON } from './Load';

/**
 *
 * @export
 * @interface LoadsResponseBody
 */
export interface LoadsResponseBody {
  /**
   *
   * @type {Array<Load>}
   * @memberof LoadsResponseBody
   */
  data: Array<Load>;
  /**
   *
   * @type {number}
   * @memberof LoadsResponseBody
   */
  next: number | null;
}

export function LoadsResponseBodyFromJSON(json: any): LoadsResponseBody {
  return LoadsResponseBodyFromJSONTyped(json, false);
}

export function LoadsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(LoadFromJSON),
    next: json['next'],
  };
}

export function LoadsResponseBodyToJSON(value?: LoadsResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(LoadToJSON),
    next: value.next,
  };
}
