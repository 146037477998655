import { AdditionalService, Shipment, ShipmentAdditionalService } from '../../../api';

export interface Field<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value: Value;
}

export interface FieldWithAccess<Value> extends Field<Value> {
  access: ShipmentFieldAccess;
}

export type ShipmentFieldAccess = 'editable' | 'readonly' | 'hidden';
export const isShipmentFieldAccess = (value: any): value is ShipmentFieldAccess =>
  typeof value === 'string' && ['editable', 'readonly', 'hidden'].includes(value);

export type ServerFieldNames = keyof Pick<
  Shipment,
  | 'organization_id'
  | 'orderer'
  | 'orderer_phone_number'
  | 'job_number'
  | 'reference_number'
  | 'pickup_name'
  | 'pickup_address'
  | 'pickup_postal_code'
  | 'pickup_phone_number'
  | 'pickup_phone_number_secondary'
  | 'pickup_city'
  | 'delivery_name'
  | 'delivery_address'
  | 'delivery_postal_code'
  | 'delivery_phone_number'
  | 'delivery_city'
  | 'note'
  | 'state'
  | 'length_ldm'
  | 'chargeable_weight_kg'
  | 'weight_kg'
  | 'price'
  | 'legacy_price_basis'
  | 'has_contract_price'
  | 'load_id'
  | 'is_express_delivery'
  | 'hourly_work_hours'
  | 'hourly_work_reason'
  | 'requires_combination_vehicle'
  | 'volume_m3'
  | 'wait_reason'
  | 'pickup_office_organization_specific_id'
  | 'delivery_office_organization_specific_id'
  | 'billing_office_organization_specific_id'
  | 'has_additional_hourly_pricing'
  | 'legacy_etaisyys_field'
  | 'customer_distribution_area'
  | 'pricing_model'
  | 'custom_pricing_category_id'
  | 'delivery_status_url_identifier'
  | 'other_contract_number'
  | 'is_adr_delivery'
  | 'ordered_at'
  | 'arrived_to_pickup_location_at'
  | 'picked_up_at'
  | 'arrived_to_delivery_location_at'
  | 'delivered_at'
  | 'billed_at'
  | 'wait_hours'
  | 'delivery_phone_number_secondary'
  | 'has_distance_been_fixed'
  | 'billing_reference_number'
  | 'requires_hoist'
>;
type UIOnlyFields = {
  pickup_date: FieldWithAccess<Date | null>;
  pickup_time: FieldWithAccess<Date | null>;
  delivery_date: FieldWithAccess<Date | null>;
  delivery_time_window_start: FieldWithAccess<Date | null>;
  delivery_time_window_end: FieldWithAccess<Date | null>;
  sizes: FieldWithAccess<boolean>;
};
export type FieldName = ServerFieldNames | keyof UIOnlyFields;

export interface AdditionalServiceField extends Field<number | ''> {
  additionalService: AdditionalService;
  shipmentAdditionalServiceId?: ShipmentAdditionalService['id'];
}

export type AllFields = {
  [P in ServerFieldNames]: FieldWithAccess<Shipment[P] | ''>;
} & UIOnlyFields;
export type FieldValue = string | number | Date | boolean | null;
