/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  OrganizationLoadResponseBody,
  OrganizationLoadResponseBodyFromJSON,
  OrganizationLoadResponseBodyToJSON,
  OrganizationLoadsResponseBody,
  OrganizationLoadsResponseBodyFromJSON,
  OrganizationLoadsResponseBodyToJSON,
  OrganizationShipmentsResponseBody1,
  OrganizationShipmentsResponseBody1FromJSON,
  OrganizationShipmentsResponseBody1ToJSON,
} from '../models';

export interface OrganizationLoadsApiGetOrganizationLoadRequest {
  organizationId: string;
  loadId: number;
}

export interface OrganizationLoadsApiGetOrganizationLoadShipmentsRequest {
  organizationId: string;
  loadId: number;
}

export interface OrganizationLoadsApiGetOrganizationLoadsRequest {
  organizationId: string;
  next?: number;
  driveDateRangeStartsAt?: Date;
  driveDateRangeEndsAt?: Date;
}

/**
 *
 */
export class OrganizationLoadsApi extends runtime.BaseAPI {
  /**
   * Get a load by id.
   */
  async getOrganizationLoadRaw(
    requestParameters: OrganizationLoadsApiGetOrganizationLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationLoadResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationLoad.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getOrganizationLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/loads/{load_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationLoadResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a load by id.
   */
  async getOrganizationLoad(
    requestParameters: OrganizationLoadsApiGetOrganizationLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationLoadResponseBody> {
    const response = await this.getOrganizationLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all Shipments of Load.
   */
  async getOrganizationLoadShipmentsRaw(
    requestParameters: OrganizationLoadsApiGetOrganizationLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationShipmentsResponseBody1>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationLoadShipments.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getOrganizationLoadShipments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/loads/{load_id}/shipments`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationShipmentsResponseBody1FromJSON(jsonValue));
  }

  /**
   * Get all Shipments of Load.
   */
  async getOrganizationLoadShipments(
    requestParameters: OrganizationLoadsApiGetOrganizationLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationShipmentsResponseBody1> {
    const response = await this.getOrganizationLoadShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all loads of organization.
   */
  async getOrganizationLoadsRaw(
    requestParameters: OrganizationLoadsApiGetOrganizationLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationLoadsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationLoads.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.driveDateRangeStartsAt !== undefined) {
      queryParameters['drive_date_range_starts_at'] = (requestParameters.driveDateRangeStartsAt as any).toISOString();
    }

    if (requestParameters.driveDateRangeEndsAt !== undefined) {
      queryParameters['drive_date_range_ends_at'] = (requestParameters.driveDateRangeEndsAt as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/loads`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationLoadsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all loads of organization.
   */
  async getOrganizationLoads(
    requestParameters: OrganizationLoadsApiGetOrganizationLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationLoadsResponseBody> {
    const response = await this.getOrganizationLoadsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
