import React, { useEffect, useReducer, useState } from 'react';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { getCombinedShipmentStateCounts, getShipmentStateCount, initialState, load, reducer } from './summary.state';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import theme from '../../theme';
import { PieChart } from 'react-minimal-pie-chart';
import {
  ChartContainer,
  FooterTableCell,
  HeaderTableCell,
  InfoCard,
  MainContainer,
  SectionContainer,
  StyledChip,
  TitleContainer,
} from './components/StyledComponents';
import { Loading } from '../../components/Loading';
import { dateRequired } from '../../validation';
import { DatePicker } from '@mui/x-date-pickers';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { canAccessCoordination } from '../../utils';
import { DateRange } from '../../utils/dateRangeUtils';

const isDateRangeValid = (dateRange: DateRange) => {
  return (
    !dateRequired.validate(dateRange.start.toJSDate()).error && !dateRequired.validate(dateRange.end.toJSDate()).error
  );
};

const Summary: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentUser = useCurrentUser();
  const hasCoordinationAccess = canAccessCoordination(currentUser);
  const [collapseTable, setCollapseTable] = useState(true);
  const allCombinedStateCounts = getCombinedShipmentStateCounts(state.stateCountShipments);
  const combinedReadyForPickupStateCounts = allCombinedStateCounts.readyForPickupCount;
  const combinedPickedUpStateCounts = getShipmentStateCount(allCombinedStateCounts, 'pickedUp');
  const combinedDeliveredStateCounts = getShipmentStateCount(allCombinedStateCounts, 'delivered');
  const combinedNotInStorageStateCounts = getShipmentStateCount(allCombinedStateCounts, 'notInStorage');
  const combinedTotalStateCounts = allCombinedStateCounts.totalCount;
  const refreshTimeMilliseconds = 60000;

  useEffect(() => {
    if (currentUser && isDateRangeValid(state.dateRange)) {
      load(currentUser, state, dispatch);
    }
  }, [currentUser, state.dateRange]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentUser && isDateRangeValid(state.dateRange)) {
        load(currentUser, state, dispatch);
      }
    }, refreshTimeMilliseconds);
    return () => clearInterval(interval);
  }, [currentUser, state.dateRange]);

  return (
    <MainContainer>
      <Loading isLoading={state.isLoading} />
      <SectionContainer>
        <Card style={{ textAlign: 'center', width: 'fit-content', margin: '1rem', maxWidth: '22rem' }}>
          <CardContent className="shipments-chart">
            <ChartContainer>
              <TitleContainer>
                <Typography variant="h1">{combinedTotalStateCounts}</Typography>
                <Typography variant="h2" gutterBottom>
                  Toimitusta
                </Typography>
              </TitleContainer>
              <PieChart
                style={{ width: '20rem' }}
                data={[
                  {
                    title: 'Tilattu',
                    value: combinedReadyForPickupStateCounts,
                    color: theme.palette.readyForPickup,
                  },
                  {
                    title: 'Kuljetuksessa',
                    value: combinedPickedUpStateCounts,
                    color: theme.palette.pickedUp,
                  },
                  {
                    title: 'Toimitettu',
                    value: combinedDeliveredStateCounts,
                    color: theme.palette.delivered,
                  },
                  {
                    title: 'Peruttu / ei varastossa',
                    value: combinedNotInStorageStateCounts,
                    color: theme.palette.notInStock,
                  },
                ]}
                labelStyle={{
                  fontSize: '0.25rem',
                }}
                lineWidth={30}
                radius={45}
                startAngle={180}
                labelPosition={80}
              />
            </ChartContainer>
            {combinedReadyForPickupStateCounts > 0 ? (
              <StyledChip
                style={{ backgroundColor: theme.palette.readyForPickup }}
                label={`Tilattu: ${combinedReadyForPickupStateCounts}`}
              />
            ) : null}
            {combinedPickedUpStateCounts > 0 ? (
              <StyledChip
                style={{ backgroundColor: theme.palette.pickedUp }}
                label={`Kuljetuksessa: ${combinedPickedUpStateCounts}`}
              />
            ) : null}
            {combinedDeliveredStateCounts > 0 ? (
              <StyledChip
                style={{ backgroundColor: theme.palette.delivered }}
                label={`Toimitettu: ${combinedDeliveredStateCounts}`}
              />
            ) : null}
            {combinedNotInStorageStateCounts > 0 ? (
              <StyledChip
                style={{ backgroundColor: theme.palette.notInStock, color: theme.palette.common.white }}
                label={`Peruttu / ei varastossa: ${combinedNotInStorageStateCounts}`}
              />
            ) : null}
          </CardContent>
        </Card>
        {state.stateCountShipments.length > 0 && hasCoordinationAccess ? (
          <TableContainer className="shipments-table" component={Card} style={{ width: 'fit-content', margin: '1rem' }}>
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  <HeaderTableCell>
                    Asiakas
                    <Tooltip title={collapseTable ? 'Näytä kaikki' : 'Piilota'}>
                      <IconButton size="small" onClick={() => setCollapseTable(!collapseTable)} style={{ margin: 0 }}>
                        {collapseTable ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                      </IconButton>
                    </Tooltip>
                  </HeaderTableCell>
                  <HeaderTableCell align="right">Tilattu</HeaderTableCell>
                  <HeaderTableCell align="right">Noudettu</HeaderTableCell>
                  <HeaderTableCell align="right">Toimitettu</HeaderTableCell>
                  <HeaderTableCell align="right">Peruttu / ei varastossa</HeaderTableCell>
                  <HeaderTableCell align="right">Yhteensä</HeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.stateCountShipments.map((stateCountShipment, index) => {
                  if (!collapseTable || (collapseTable && index <= 4)) {
                    return (
                      <TableRow key={stateCountShipment.organizationName}>
                        <TableCell>{stateCountShipment.organizationName}</TableCell>
                        <TableCell align="right">{stateCountShipment.readyForPickupCount}</TableCell>
                        <TableCell align="right">{getShipmentStateCount(stateCountShipment, 'pickedUp')}</TableCell>
                        <TableCell align="right">{getShipmentStateCount(stateCountShipment, 'delivered')}</TableCell>
                        <TableCell align="right">{getShipmentStateCount(stateCountShipment, 'notInStorage')}</TableCell>
                        <TableCell align="right">{stateCountShipment.totalCount}</TableCell>
                      </TableRow>
                    );
                  } else if (!collapseTable || (collapseTable && index === 5)) {
                    const restOfStateCountShipments = getCombinedShipmentStateCounts(
                      state.stateCountShipments.slice(5),
                    );
                    return (
                      <TableRow key={'Muut'}>
                        <TableCell>Muut</TableCell>
                        <TableCell align="right">{restOfStateCountShipments.readyForPickupCount}</TableCell>
                        <TableCell align="right">{getShipmentStateCount(stateCountShipment, 'pickedUp')}</TableCell>
                        <TableCell align="right">{getShipmentStateCount(stateCountShipment, 'delivered')}</TableCell>
                        <TableCell align="right">{getShipmentStateCount(stateCountShipment, 'notInStorage')}</TableCell>
                        <TableCell align="right">{restOfStateCountShipments.totalCount}</TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <FooterTableCell>Yhteensä</FooterTableCell>
                  <FooterTableCell align="right">{combinedReadyForPickupStateCounts}</FooterTableCell>
                  <FooterTableCell align="right">{combinedPickedUpStateCounts}</FooterTableCell>
                  <FooterTableCell align="right">{combinedDeliveredStateCounts}</FooterTableCell>
                  <FooterTableCell align="right">{combinedNotInStorageStateCounts}</FooterTableCell>
                  <FooterTableCell align="right">{combinedTotalStateCounts}</FooterTableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : null}
      </SectionContainer>
      {hasCoordinationAccess ? (
        <SectionContainer className="resource-cards">
          <InfoCard title={'Asiakasta'} value={state.stateCountShipments.length} />
          <InfoCard title={'Kuormaa'} value={state.loadCount ?? 0} />
          <InfoCard title={'Autoa'} value={state.carCount ?? 0} />
          <InfoCard title={'Kuljettajaa'} value={state.driverCount ?? 0} />
        </SectionContainer>
      ) : null}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <DatePicker
          disabled={state.isLoading}
          className="summary-date-picker"
          value={state.dateRange.start}
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
              className: 'summary-date-picker',
              error: Boolean(dateRequired.validate(state.dateRange.start.toJSDate()).error),
              helperText: dateRequired.validate(state.dateRange.start.toJSDate()).error?.message,
            },
          }}
          onChange={(date) => {
            if (date) {
              dispatch({
                type: 'SET_DATE_RANGE',
                payload: {
                  start: date.startOf('day'),
                  end: date.endOf('day'),
                },
              });
            }
          }}
        />
      </div>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </MainContainer>
  );
};

export default Summary;
