import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { Dispatch, useEffect } from 'react';
import { Action, FieldName, ModifiedEmployeeDriverGroup, State, updateFieldValue } from '../employee.state';
import { FieldSetWithSetWidth } from '../../../components/StyledComponents/FieldSet';
import { DriverCollectiveAgreementEnum, DriverProfessionEnum } from '../../../api';
import { Link as RouterLink } from 'react-router-dom';
import { ImmutableTextField } from '../../../components/ImmutableTextField';
import {
  Action as UserAction,
  FieldName as UserFieldName,
  updateFieldValue as userUpdateFieldValue,
} from '../../users/user.state';
import { FieldSetContainer } from '../../../components/StyledComponents/FieldSetContainer';
import { getJSDateOrNull, StandardDatePicker } from '../../../components/DateAndTimePickers/StandardDatePicker';
import { Add } from '@mui/icons-material';

interface InputProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  disabled?: boolean;
  createNewEmployee?: boolean;
  required?: boolean;
}

interface DispatchableInputProps extends InputProps {
  dispatch: Dispatch<Action>;
  userDispatch?: Dispatch<UserAction>;
}

export const AllEmployeeFields: React.FC<DispatchableInputProps> = ({
  dispatch,
  state,
  createNewEmployee,
  userDispatch,
}) => {
  return (
    <FieldSetContainer>
      <FieldSetWithSetWidth>
        <legend>Perustiedot</legend>
        {createNewEmployee ? null : <Id state={state} dispatch={dispatch} disabled={true} />}
        <IsActiveChecbox state={state} dispatch={dispatch} disabled={false} />
        <EmployeeNumber state={state} dispatch={dispatch} disabled={false} />
        <IsLegacyDriverAppInUse state={state} dispatch={dispatch} disabled={false} />
        <ProfessionSelect
          state={state}
          dispatch={dispatch}
          userDispatch={userDispatch}
          disabled={false}
          createNewEmployee={createNewEmployee}
        >
          {professionMenuItems}
        </ProfessionSelect>
        <CollectiveAgreementSelect
          state={state}
          dispatch={dispatch}
          userDispatch={userDispatch}
          disabled={false}
          createNewEmployee={createNewEmployee}
        >
          {collectiveAgreementMenuItems}
        </CollectiveAgreementSelect>
        <License state={state} dispatch={dispatch} disabled={false} />
        <CompanyPhoneNumber
          state={state}
          dispatch={dispatch}
          userDispatch={userDispatch}
          disabled={false}
          createNewEmployee={createNewEmployee}
        />
        <HiredBy state={state} dispatch={dispatch} disabled={false} />
      </FieldSetWithSetWidth>
      <FieldSetWithSetWidth>
        <legend>Henkilötiedot</legend>
        <FirstName
          state={state}
          dispatch={dispatch}
          userDispatch={userDispatch}
          disabled={false}
          createNewEmployee={createNewEmployee}
        />
        <LastName
          state={state}
          dispatch={dispatch}
          userDispatch={userDispatch}
          disabled={false}
          createNewEmployee={createNewEmployee}
        />
        <PersonalPhoneNumber state={state} dispatch={dispatch} disabled={false} />
        <Address state={state} dispatch={dispatch} disabled={false} />
        <PostalCode state={state} dispatch={dispatch} disabled={false} />
        <City state={state} dispatch={dispatch} disabled={false} />
      </FieldSetWithSetWidth>
      <FieldSetWithSetWidth>
        <legend>Voimassa olevat koulutukset</legend>
        <ProfessionalQualification state={state} dispatch={dispatch} disabled={false} />
        <ProactiveDrivingExpireDate state={state} dispatch={dispatch} userDispatch={userDispatch} disabled={false} />
        <AdrExpireDate state={state} dispatch={dispatch} userDispatch={userDispatch} disabled={false} />
        <WorkSafetyExpireDate state={state} dispatch={dispatch} disabled={false} />
      </FieldSetWithSetWidth>
      <FieldSetWithSetWidth>
        <legend>Kuljettajaryhmät</legend>
        <DriverGroups state={state} dispatch={dispatch} disabled={false} />
      </FieldSetWithSetWidth>
      {!createNewEmployee ? (
        <FieldSetWithSetWidth>
          <legend>Lisätiedot</legend>
          <LinkedUserLink state={state} dispatch={dispatch} disabled={false} />
        </FieldSetWithSetWidth>
      ) : null}
    </FieldSetContainer>
  );
};

const Id: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <FormControl className="id" error={state.fields.id.hasError}>
      <InputLabel htmlFor="id">Id</InputLabel>
      <Input
        disabled={disabled}
        id="id"
        name="id"
        type="number"
        value={state.fields.id.value ?? ''}
        onChange={(event) =>
          updateFieldValue('id', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
      />
      {state.fields.id.feedback ? (
        <FormHelperText className="id-helper-text">{state.fields.id.feedback}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

const FirstName: React.FC<DispatchableInputProps> = ({
  state,
  dispatch,
  userDispatch,
  disabled,
  createNewEmployee,
}) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.first_name.required}
      name="first_name"
      error={state.fields.first_name.hasError}
      helperText={state.fields.first_name.feedback}
      className="first_name"
      label="Etunimi"
      value={state.fields.first_name.value}
      onChange={(event) => {
        updateFieldValue(event.target.name as FieldName, event.target.value, dispatch);
        if (createNewEmployee && userDispatch) {
          userUpdateFieldValue(event.target.name as UserFieldName, event.target.value, userDispatch);
        }
      }}
    />
  );
};

const LastName: React.FC<DispatchableInputProps> = ({ state, dispatch, userDispatch, disabled, createNewEmployee }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.last_name.required}
      name="last_name"
      error={state.fields.last_name.hasError}
      helperText={state.fields.last_name.feedback}
      className="last_name"
      label="Sukunimi"
      value={state.fields.last_name.value}
      onChange={(event) => {
        updateFieldValue(event.target.name as FieldName, event.target.value, dispatch);
        if (createNewEmployee && userDispatch) {
          userUpdateFieldValue(event.target.name as UserFieldName, event.target.value, userDispatch);
        }
      }}
    />
  );
};

const EmployeeNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.employee_number.required}
      name="employee_number"
      error={state.fields.employee_number.hasError}
      helperText={state.fields.employee_number.feedback}
      className="employee_number"
      label="Työntekijänumero"
      value={state.fields.employee_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const License: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.license.required}
      name="license"
      error={state.fields.license.hasError}
      helperText={state.fields.license.feedback}
      className="license"
      label="Ajokorttiluokka"
      value={state.fields.license.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const HiredBy: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.hired_by.required}
      name="hired_by"
      error={state.fields.hired_by.hasError}
      helperText={state.fields.hired_by.feedback}
      className="hired_by"
      label="Rekrytoija"
      value={state.fields.hired_by.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const CompanyPhoneNumber: React.FC<DispatchableInputProps> = ({
  state,
  dispatch,
  userDispatch,
  disabled,
  createNewEmployee,
}) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.company_phone_number.required}
      name="company_phone_number"
      error={state.fields.company_phone_number.hasError}
      helperText={state.fields.company_phone_number.feedback}
      className="company_phone_number"
      label="Työpuhelinnumero"
      value={state.fields.company_phone_number.value}
      onChange={(event) => {
        updateFieldValue(event.target.name as FieldName, event.target.value, dispatch);
        if (createNewEmployee && userDispatch) {
          userUpdateFieldValue('gsm' as UserFieldName, event.target.value, userDispatch);
        }
      }}
    />
  );
};

const PersonalPhoneNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.personal_phone_number.required}
      name="personal_phone_number"
      error={state.fields.personal_phone_number.hasError}
      helperText={state.fields.personal_phone_number.feedback}
      className="personal_phone_number"
      label="Henkilökohtainen puhelinnumero"
      value={state.fields.personal_phone_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const IsLegacyDriverAppInUse: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  const disabled = !Boolean(state.fields.employee_number.value);

  //update legacy driver app id if employee number changes
  useEffect(() => {
    if (state.fields.legacy_driver_app_id.value !== '') {
      updateFieldValue('legacy_driver_app_id', state.fields.employee_number.value, dispatch);
    }
  }, [state.fields.employee_number.value]);

  return (
    <Tooltip title={disabled ? 'Työntekijänumero vaaditaan' : ''} aria-label={'Vanha kuljettajasovellus käytössä'}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            className="legacy_driver_app_id"
            name="legacy_driver_app_id"
            checked={state.fields.legacy_driver_app_id.value !== ''}
            onChange={(event) => {
              if (state.fields.legacy_driver_app_id.value === '') {
                updateFieldValue(event.target.name as FieldName, state.fields.employee_number.value, dispatch);
              } else {
                updateFieldValue(event.target.name as FieldName, '', dispatch);
              }
            }}
          />
        }
        label="Vanha kuljettajasovellus käytössä"
      />
    </Tooltip>
  );
};

const IsActiveChecbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="is_at_service"
          name="is_at_service"
          checked={state.fields.is_at_service.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.is_at_service.value, dispatch)
          }
        />
      }
      label="Aktiivinen"
    />
  );
};

const professionMenuItems = [
  <MenuItem key={DriverProfessionEnum.Alihankkija} value={DriverProfessionEnum.Alihankkija}>
    Alihankkija
  </MenuItem>,
  <MenuItem key={DriverProfessionEnum.Appari} value={DriverProfessionEnum.Appari}>
    Apukuljettaja
  </MenuItem>,
  <MenuItem key={DriverProfessionEnum.Asentaja} value={DriverProfessionEnum.Asentaja}>
    Asentaja
  </MenuItem>,
  <MenuItem key={DriverProfessionEnum.Kuski} value={DriverProfessionEnum.Kuski}>
    Kuljettaja
  </MenuItem>,
  <MenuItem key={DriverProfessionEnum.Muu} value={DriverProfessionEnum.Muu}>
    Muu
  </MenuItem>,
  <MenuItem key={DriverProfessionEnum.Toimihenkilo} value={DriverProfessionEnum.Toimihenkilo}>
    Toimihenkilö
  </MenuItem>,
];

export interface ProfessionSelectProps extends DispatchableInputProps {
  children: React.ReactElement[];
}

export const ProfessionSelect: React.FC<ProfessionSelectProps> = ({
  state,
  dispatch,
  userDispatch,
  disabled,
  children,
  createNewEmployee,
}) => {
  return (
    <FormControl>
      <InputLabel shrink id="state_label">
        Toimenkuva
      </InputLabel>
      <Select
        className="profession"
        id="profession"
        labelId="profession_label"
        disabled={disabled}
        value={state.fields.profession.value}
        onChange={(event) => {
          updateFieldValue('profession', event.target.value as DriverProfessionEnum, dispatch);
          if (createNewEmployee && userDispatch) {
            if (event.target.value === DriverProfessionEnum.Kuski) {
              userUpdateFieldValue('is_driver' as UserFieldName, true, userDispatch);
            } else {
              userUpdateFieldValue('is_driver' as UserFieldName, false, userDispatch);
            }
          }
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

const collectiveAgreementMenuItems = [
  <MenuItem key={DriverCollectiveAgreementEnum.Empty} value={DriverCollectiveAgreementEnum.Empty}>
    Tyhjä
  </MenuItem>,
  <MenuItem key={DriverCollectiveAgreementEnum.Korjaamo} value={DriverCollectiveAgreementEnum.Korjaamo}>
    Korjaamo
  </MenuItem>,
  <MenuItem key={DriverCollectiveAgreementEnum.Kuljettaja} value={DriverCollectiveAgreementEnum.Kuljettaja}>
    Kuljettaja
  </MenuItem>,
  <MenuItem key={DriverCollectiveAgreementEnum.Osaaikainen} value={DriverCollectiveAgreementEnum.Osaaikainen}>
    Osa-aikainen
  </MenuItem>,
  <MenuItem key={DriverCollectiveAgreementEnum.Toimihenkilo} value={DriverCollectiveAgreementEnum.Toimihenkilo}>
    Toimihenkilö
  </MenuItem>,
];

export interface CollectiveAgreementSelectProps extends DispatchableInputProps {
  children: React.ReactElement[];
}

export const CollectiveAgreementSelect: React.FC<CollectiveAgreementSelectProps> = ({
  state,
  dispatch,
  userDispatch,
  disabled,
  children,
  createNewEmployee,
}) => {
  return (
    <FormControl>
      <InputLabel shrink id="collective_agreement_label">
        Työehtosopimus
      </InputLabel>
      <Select
        className="collective_agreement"
        id="collective_agreement"
        labelId="collective_agreement_label"
        disabled={disabled}
        value={state.fields.collective_agreement.value}
        onChange={(event) => {
          updateFieldValue('collective_agreement', event.target.value as DriverCollectiveAgreementEnum, dispatch);
          if (createNewEmployee && userDispatch) {
            if (event.target.value === DriverCollectiveAgreementEnum.Kuljettaja) {
              userUpdateFieldValue('is_driver' as UserFieldName, true, userDispatch);
            } else {
              userUpdateFieldValue('is_driver' as UserFieldName, false, userDispatch);
            }
          }
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

const Address: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.address.required}
      name="address"
      error={state.fields.address.hasError}
      helperText={state.fields.address.feedback}
      className="address"
      label="Osoite"
      value={state.fields.address.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const PostalCode: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.postal_code.required}
      name="postal_code"
      error={state.fields.postal_code.hasError}
      helperText={state.fields.postal_code.feedback}
      className="postal_code"
      label="Postinumero"
      value={state.fields.postal_code.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const City: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.city.required}
      name="city"
      error={state.fields.city.hasError}
      helperText={state.fields.city.feedback}
      className="city"
      label="Kaupunki"
      value={state.fields.city.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const LinkedUserLink: React.FC<DispatchableInputProps> = ({ state }) => {
  const linkedUser = state.users.find(
    (user) => user.driver_id === state.originalEmployee?.id && state.originalEmployee?.id,
  );
  return (
    <ImmutableTextField label={'Yhdistetty käyttäjätunnus'} id="linked-user-link">
      {linkedUser ? (
        <Link component={RouterLink} to={`/users/${linkedUser?.username}`}>
          {linkedUser.username}
        </Link>
      ) : (
        <span>-</span>
      )}
    </ImmutableTextField>
  );
};

const ProactiveDrivingExpireDate: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StandardDatePicker
      disabled={disabled}
      label="Ennakoiva ajo"
      value={state.fields.proactive_driving_expire_date.value}
      slotProps={{
        textField: {
          name: 'proactive_driving_expire_date',
          id: 'proactive_driving_expire_date',
          required: state.fields.proactive_driving_expire_date.required,
          error: state.fields.proactive_driving_expire_date.hasError,
          helperText: state.fields.proactive_driving_expire_date.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('proactive_driving_expire_date', getJSDateOrNull(date), dispatch)}
    />
  );
};

const AdrExpireDate: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StandardDatePicker
      disabled={disabled}
      label="ADR"
      value={state.fields.adr_expire_date.value}
      slotProps={{
        textField: {
          name: 'adr_expire_date',
          id: 'adr_expire_date',
          required: state.fields.adr_expire_date.required,
          error: state.fields.adr_expire_date.hasError,
          helperText: state.fields.adr_expire_date.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('adr_expire_date', getJSDateOrNull(date), dispatch)}
    />
  );
};

const WorkSafetyExpireDate: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StandardDatePicker
      disabled={disabled}
      label="Työturva"
      value={state.fields.work_safety_expire_date.value}
      slotProps={{
        textField: {
          name: 'work_safety_expire_date',
          id: 'work_safety_expire_date',
          required: state.fields.work_safety_expire_date.required,
          error: state.fields.work_safety_expire_date.hasError,
          helperText: state.fields.work_safety_expire_date.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('work_safety_expire_date', getJSDateOrNull(date), dispatch)}
    />
  );
};

const ProfessionalQualification: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StandardDatePicker
      disabled={disabled}
      label="Ammattipätevyys"
      value={state.fields.professional_qualification.value}
      slotProps={{
        textField: {
          name: 'professional_qualification',
          id: 'professional_qualification',
          required: state.fields.professional_qualification.required,
          error: state.fields.professional_qualification.hasError,
          helperText: state.fields.professional_qualification.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('professional_qualification', getJSDateOrNull(date), dispatch)}
    />
  );
};

const DriverGroups: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  const updateGroupStatus = (group: ModifiedEmployeeDriverGroup, isMember: boolean) => {
    const updatedGroup = {
      ...group,
      // If group was already added/removed, then it is now returned back to original state
      isModified: group.isModified !== true,
    };
    const newDriverGroups = isMember
      ? {
          memberOf: state.driverGroups.memberOf.filter((memberOfGroup) => memberOfGroup.id !== group.id),
          notMemberOf: [...state.driverGroups.notMemberOf, updatedGroup],
        }
      : {
          memberOf: [...state.driverGroups.memberOf, updatedGroup],
          notMemberOf: state.driverGroups.notMemberOf.filter((notMemberOfGroup) => notMemberOfGroup.id !== group.id),
        };
    dispatch({
      type: 'SET_DRIVER_GROUPS',
      payload: {
        driverGroups: newDriverGroups,
      },
    });

    return false;
  };

  const MemberOfGroup = (group: ModifiedEmployeeDriverGroup, disabled: boolean | undefined) => {
    return (
      <Tooltip title={group.description} aria-label={group.description} key={group.id}>
        <Chip
          sx={{ margin: 1 }}
          disabled={disabled}
          label={group.name}
          onDelete={() => {
            if (disabled) return;
            updateGroupStatus(group, true);
          }}
        />
      </Tooltip>
    );
  };
  const NotMemberOfGroup = (group: ModifiedEmployeeDriverGroup, disabled: boolean | undefined) => {
    return (
      <Tooltip title={group.description} aria-label={group.description} key={group.id}>
        <Chip
          sx={{ margin: 1 }}
          icon={<Add />}
          label={group.name}
          disabled={disabled}
          onClick={() => {
            if (disabled) return;
            updateGroupStatus(group, false);
          }}
        />
      </Tooltip>
    );
  };

  return (
    <>
      <h3>Työntekijän ryhmät</h3>
      <div>{state.driverGroups.memberOf.map((group) => MemberOfGroup(group, disabled))}</div>
      <h3>Ei kuulu ryhmiin</h3>
      <div>{state.driverGroups.notMemberOf.map((group) => NotMemberOfGroup(group, disabled))}</div>
    </>
  );
};
