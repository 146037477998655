/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FirstLogInPostBody
 */
export interface FirstLogInPostBody {
  /**
   *
   * @type {string}
   * @memberof FirstLogInPostBody
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof FirstLogInPostBody
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof FirstLogInPostBody
   */
  new_password: string;
  /**
   *
   * @type {string}
   * @memberof FirstLogInPostBody
   */
  email: string;
}

export function FirstLogInPostBodyFromJSON(json: any): FirstLogInPostBody {
  return FirstLogInPostBodyFromJSONTyped(json, false);
}

export function FirstLogInPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirstLogInPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: json['username'],
    password: json['password'],
    new_password: json['new_password'],
    email: json['email'],
  };
}

export function FirstLogInPostBodyToJSON(value?: FirstLogInPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    password: value.password,
    new_password: value.new_password,
    email: value.email,
  };
}
