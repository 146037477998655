/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CarrierShipment,
  CarrierShipmentFromJSON,
  CarrierShipmentFromJSONTyped,
  CarrierShipmentToJSON,
} from './CarrierShipment';

/**
 *
 * @export
 * @interface GetCarrierShipmentsResponse
 */
export interface GetCarrierShipmentsResponse {
  /**
   *
   * @type {Array<CarrierShipment>}
   * @memberof GetCarrierShipmentsResponse
   */
  data: Array<CarrierShipment>;
}

export function GetCarrierShipmentsResponseFromJSON(json: any): GetCarrierShipmentsResponse {
  return GetCarrierShipmentsResponseFromJSONTyped(json, false);
}

export function GetCarrierShipmentsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetCarrierShipmentsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(CarrierShipmentFromJSON),
  };
}

export function GetCarrierShipmentsResponseToJSON(value?: GetCarrierShipmentsResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(CarrierShipmentToJSON),
  };
}
