import React, { Dispatch, useEffect, useState } from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Badge, Box, Button, Checkbox, IconButton, Link } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { AutoCompleteFilter, DateColumnFilter } from '../../components/TableFilters';
import { Accessor, Cell, CellValue, Column, ColumnInstance, Row, TableRowProps } from 'react-table';
import { DateRangePicker } from '../../components/DateRangePicker';
import { DateTime } from 'luxon';
import {
  Action,
  addSelectedShipmentsToLoad,
  addShipmentsToLoad,
  CoordinationShipment,
  CoordinationViewSettings,
  createDeliveryTransports,
  getDriveDateFromSelectedDateRange,
  getLoad,
  load,
  maxPreciseDeliveryWindowDurationMs,
  State,
} from './coordination.state';
import { ReactTable } from '../../components/ReactTable';
import { Shipment, ShipmentStateEnum } from '../../api';
import { LoadModal } from '../../components/LoadModal';
import { DeliveryWindow } from '../../components/DeliveryWindow';
import { ShipmentRowSubRowAsync } from '../../components/ShipmentRowSubRow';
import { AddressLink } from '../../components/map';
import { getViewSettings } from '../settings';
import { LoadPicker } from '../../components/LoadPicker';
import { dateFormatUnderscore, formatDateTime, Organizations } from '../../formatters';
import { ShipmentSettingsModal } from './components/ShipmentSettingsModal';
import { getDateRangePickerPropsFromState } from '../../utils/dateRangeUtils';
import { getShipmentStateUtils } from '../../utils';
import { CarrierUser } from '../../reducers/authReducer';

const VIEW_ID = 'coordination' as const;

const formatAddress = (address: string | null) => {
  if (address) {
    return address.toLowerCase().replace(/[0-9 ]/g, '');
  }
};

const filterShipments = (
  shipments: CoordinationShipment[],
  onlyShipmentsWithoutLoads: boolean,
  onlyShipmentsWithPreciseDelivery: boolean,
  hideCancelledShipments: boolean,
  onlyShipmentsWithDuplicaDeliveryAddress: boolean,
) => {
  return onlyShipmentsWithoutLoads ||
    onlyShipmentsWithPreciseDelivery ||
    hideCancelledShipments ||
    onlyShipmentsWithDuplicaDeliveryAddress
    ? shipments.filter(
        (shipment, index, array) =>
          (hideCancelledShipments ? shipment.state !== ShipmentStateEnum.Peruttu : true) &&
          (onlyShipmentsWithoutLoads ? shipment.load_id === null : true) &&
          (onlyShipmentsWithPreciseDelivery
            ? shipment.agreed_delivery_window_ends_at && shipment.agreed_delivery_window_starts_at
              ? shipment.agreed_delivery_window_ends_at.getTime() -
                  shipment.agreed_delivery_window_starts_at.getTime() <=
                maxPreciseDeliveryWindowDurationMs
              : true
            : true) &&
          (onlyShipmentsWithDuplicaDeliveryAddress
            ? (array
                .map((shipment) => formatAddress(shipment.delivery_address))
                .lastIndexOf(formatAddress(shipment.delivery_address)) !== index ||
                array
                  .map((shipment) => formatAddress(shipment.delivery_address))
                  .indexOf(formatAddress(shipment.delivery_address)) !== index) &&
              (array
                .map((shipment) => formatAddress(shipment.delivery_city))
                .lastIndexOf(formatAddress(shipment.delivery_city)) !== index ||
                array
                  .map((shipment) => formatAddress(shipment.delivery_city))
                  .indexOf(formatAddress(shipment.delivery_city)) !== index)
            : true),
      )
    : shipments;
};

interface ShipmentCoordinationProps {
  state: State;
  dispatch: Dispatch<Action>;
}

const ShipmentCoordination: React.FC<ShipmentCoordinationProps> = ({ state, dispatch }) => {
  const carrierUser = useOutletContext<CarrierUser>();
  const [openSettings, setOpenSettings] = React.useState(false);
  const handleOpenSettings = () => setOpenSettings(true);
  const handleCloseSettings = () => setOpenSettings(false);
  const viewSettings = React.useMemo(() => getViewSettings<CoordinationViewSettings>(VIEW_ID), []);

  useEffect(() => {
    dispatch({
      type: 'SET_VIEW_SETTINGS',
      payload: viewSettings,
    });
  }, []);

  useEffect(() => {
    if (state.dateRange && carrierUser) {
      const abortController = new AbortController();
      load(carrierUser, state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [state.dateRange]);

  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        Header: 'Valitse',
        disableFilters: true,
        Cell: ({ row, toggleRowSelected }) => {
          const shipmentId = parseInt(row.id);
          return (
            <Checkbox
              size="small"
              checked={(row as any).isSelected}
              onChange={() => {
                toggleRowSelected(row.id);
                dispatch({
                  type: 'SELECT_SHIPMENT',
                  payload: {
                    shipmentId: shipmentId,
                    value: !(row as any).isSelected,
                  },
                });
              }}
              onClick={(event) => {
                event.stopPropagation(); // Prevents SubRows from opening
              }}
              name="selected"
              color="primary"
            />
          );
        },
      },
      {
        id: 'id',
        Header: 'Id / Asiakasviite',
        accessor: (shipment: CellValue) => `${shipment.id ?? ''} / ${shipment.reference_number ?? ''}`,
        Cell: ({ row, value }: { row: Row<CoordinationShipment>; value: CellValue }) => (
          <Link component={RouterLink} to={{ pathname: `/shipments/${row.original.id}` }}>
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asiakas',
        accessor: 'organizationName',
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Kuljettaja',
        accessor: (shipment: CellValue) =>
          `${shipment.load?.driver?.last_name ?? ''} ${shipment.load?.driver?.first_name ?? ''}`,
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Auto',
        accessor: (shipment: CellValue) => `${shipment.load?.car?.licence_plate ?? ''}`,
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Perävaunu',
        accessor: (shipment: CellValue) => `${shipment.trailerLicenceplate ?? ''}`,
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Kuorma',
        id: 'load_id',
        accessor: (shipment: CellValue) =>
          shipment.load === null ? '' : `${shipment.load.organizationName ?? ''} (${shipment.load.id ?? ''})`,
        Cell: ({ row, value }: { row: Row<CoordinationShipment>; value: CellValue }) => (
          <>
            {row.original.load && (
              <Link component={RouterLink} to={{ pathname: `/loads/${row.original.load.id}` }}>
                {value}
              </Link>
            )}
          </>
        ),
      },
      {
        Header: 'Kuormajärjestys',
        accessor: 'order_in_load',
      },
      {
        Header: 'Tila',
        accessor: 'state',
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Nouto',
        columns: [
          {
            Header: 'Noutonimi',
            accessor: 'pickup_name',
          },
          {
            Header: 'Nouto-osoite',
            accessor: 'pickup_address',
            Cell: (shipment: CellValue) => {
              return (
                <AddressLink
                  className="pickup-address-link"
                  title={shipment.cell.row.original.pickup_address}
                  address={shipment.cell.row.original.pickup_address}
                  postalCode={shipment.cell.row.original.pickup_postal_code}
                  city={shipment.cell.row.original.pickup_city}
                />
              );
            },
          },
          {
            Header: 'Noutopostinumero',
            accessor: 'pickup_postal_code',
          },
          {
            Header: 'Noutokaupunki',
            accessor: 'pickup_city',
          },
          {
            Header: 'Noudettu',
            accessor: 'picked_up_at',
            Cell: (shipment: CellValue) => {
              return shipment.cell.row.original.delivered_at
                ? formatDateTime(shipment.cell.row.original.delivered_at)
                : null;
            },
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
        ],
      },
      {
        Header: 'Toimitus',
        columns: [
          {
            Header: 'Nimi',
            accessor: 'delivery_name',
          },
          {
            Header: 'Osoite',
            accessor: 'delivery_address',
            Cell: (shipment: CellValue) => {
              return (
                <AddressLink
                  className="delivery-address-link"
                  title={shipment.cell.row.original.delivery_address}
                  address={shipment.cell.row.original.delivery_address}
                  postalCode={shipment.cell.row.original.delivery_postal_code}
                  city={shipment.cell.row.original.delivery_city}
                />
              );
            },
          },
          {
            Header: 'Postinumero',
            accessor: 'delivery_postal_code',
          },
          {
            Header: 'Kaupunki',
            accessor: 'delivery_city',
          },
          {
            Header: 'Toimitusaika',
            accessor: 'agreed_delivery_window_starts_at',
            Cell: (shipment: CellValue) => {
              return (
                <DeliveryWindow
                  startsAt={shipment.cell.row.original.agreed_delivery_window_starts_at}
                  endsAt={shipment.cell.row.original.agreed_delivery_window_ends_at}
                />
              );
            },
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
          {
            Header: 'Toimitettu',
            accessor: 'delivered_at',
            Cell: (shipment: CellValue) => {
              return shipment.cell.row.original.delivered_at
                ? formatDateTime(shipment.cell.row.original.delivered_at)
                : null;
            },
            Filter: DateColumnFilter,
            sortType: 'datetime',
          },
        ],
      },
      {
        Header: 'Paino (kg)',
        accessor: 'weight_kg',
      },
      {
        Header: 'Tilavuus (m3)',
        accessor: 'volume_m3',
      },
      {
        Header: 'Lavametrit',
        accessor: 'length_ldm',
      },
      {
        Header: 'Pituus (mm)',
        accessor: 'longest_shipment_row_side_length_mm',
      },
      {
        id: 'is_adr_delivery',
        Header: 'ADR',
        accessor: ((isAdrDelivery: CellValue) =>
          isAdrDelivery.is_adr_delivery === true
            ? 'Kyllä'
            : isAdrDelivery.is_adr_delivery === false
              ? 'Ei'
              : '') as Accessor<any>,
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Lisätietoja',
        accessor: 'note',
      },
    ],
    [],
  );
  const [selectedShipments, setSelectedShipments] = useState<Record<Shipment['id'], boolean>>(state.selectedShipments);
  const data = React.useMemo(
    () =>
      filterShipments(
        state.shipments,
        state.viewSettings.onlyShipmentsWithoutLoads,
        state.viewSettings.onlyShipmentsWithPreciseDelivery,
        state.viewSettings.hideCancelledShipments,
        state.viewSettings.onlyShipmentsWithDuplicaDeliveryAddress,
      ),
    [
      state.shipments,
      state.viewSettings.onlyShipmentsWithoutLoads,
      state.viewSettings.onlyShipmentsWithPreciseDelivery,
      state.viewSettings.hideCancelledShipments,
      state.viewSettings.onlyShipmentsWithDuplicaDeliveryAddress,
    ],
  );
  const renderRowSubComponent = React.useCallback(
    (row: Row, rowProps: TableRowProps, visibleColumns: ColumnInstance[]) => (
      <ShipmentRowSubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
    ),
    [],
  );

  const tableRef = React.useRef<any>(null);
  // Not used, since select all checkbox is commented out
  //const allSelected = state.shipments.every((s) => state.selectedShipments[s.id]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: '1rem',
          '> button, > div': {
            margin: '0.25rem',
          },
        }}
      >
        <LoadPicker
          disabled={state.isLoading || state.isLoadLoading}
          loads={state.loads.filter(
            (load) =>
              (load.drive_date && state.dateRange && DateTime.fromJSDate(load.drive_date) >= state.dateRange.start) ||
              DateTime.local().startOf('day'),
          )}
          drivers={state.drivers}
          clients={state.clients}
          value={state.load || null}
          onChange={(load) => {
            dispatch({ type: 'SET_LOAD_ID', payload: load ? load.id : null });
            getLoad(carrierUser, load ? load.id : null, dispatch);
          }}
        />
        <Button
          id="add-to-load-button"
          onClick={() => {
            state.loadId !== '' && addShipmentsToLoad(carrierUser, selectedShipments, state, dispatch);
            dispatch({ type: 'CLEAR_SELECTED_SHIPMENTS' });
            tableRef.current?.deselectAllRows();
            dispatch({ type: 'SET_LOAD_ID', payload: null });
            getLoad(carrierUser, null, dispatch);
          }}
          disabled={Object.entries(selectedShipments).length < 1 || state.isLoading || state.load === null}
        >
          Lisää valitut kuormaan
        </Button>
        <Button
          id="create-load-button"
          onClick={() => {
            const selectedShipmentsArray = state.shipments.filter((shipment) => selectedShipments[shipment.id]);
            for (let i = 0; i < selectedShipmentsArray.length; i++) {
              selectedShipmentsArray[i].order_in_load = i + 1;
            }
            dispatch({
              type: 'SET_IS_LOAD_MODAL_OPEN',
              payload: {
                isLoadModalOpen: true,
                presetLoadFields: {
                  drive_date: getDriveDateFromSelectedDateRange(state.dateRange),
                },
              },
            });
          }}
          disabled={state.isLoading}
        >
          {!Object.values(state.selectedShipments).some((value) => value)
            ? 'Luo uusi tyhjä kuorma'
            : 'Luo valituista uusi kuorma'}
        </Button>
        <Button
          color="error"
          className="remove-from-loads-button"
          disabled={!Object.values(state.selectedShipments).some((value) => value) || state.isLoading}
          onClick={() => {
            tableRef.current?.deselectAllRows();
            addSelectedShipmentsToLoad(carrierUser, null, state, dispatch);
            dispatch({ type: 'CLEAR_SELECTED_SHIPMENTS' });
          }}
        >
          Poista valitut kuormista
        </Button>
        <Button
          id="create-delivery-transfers-button"
          onClick={() => {
            createDeliveryTransports(carrierUser, state, dispatch, tableRef);
          }}
          disabled={
            state.isLoading ||
            !Object.values(state.selectedShipments).some((value) => value) ||
            !state.shipments
              .filter((shipment) => state.selectedShipments[shipment.id])
              .every((x) => x.organization_id === Organizations.Krautayp)
          }
        >
          Luo jakelutoimitukset valituille
        </Button>
        {/*
        This is commented out, since this selects ALL shipments, but we actually want to select VISIBLE shipment
        <label htmlFor={'select-all'}>Valitse kaikki</label>
        <Checkbox
          checked={allSelected}
          name={'select-all'}
          id={'select-all'}
          onChange={() => {
            const actionType = allSelected ? 'CLEAR_SELECTED_SHIPMENTS' : 'SELECT_ALL_SHIPMENTS';
            if (allSelected) {
              tableRef.current?.deselectAllRows();
            } else {
              tableRef.current?.selectAllRows();
            }
            dispatch({ type: actionType });
          }}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        */}
        <Box style={{ flexGrow: 1 }} />
        <IconButton data-cy="settings" onClick={handleOpenSettings}>
          <Badge
            color="primary"
            badgeContent={
              [
                state.viewSettings.onlyShipmentsWithPreciseDelivery,
                state.viewSettings.onlyShipmentsWithoutLoads,
                state.viewSettings.hideCancelledShipments,
                state.viewSettings.onlyShipmentsWithDuplicaDeliveryAddress,
                state.viewSettings.showStateColors,
              ].filter(Boolean).length
            }
          >
            <Settings />
          </Badge>
        </IconButton>
        <ShipmentSettingsModal open={openSettings} onClose={handleCloseSettings} state={state} dispatch={dispatch} />
      </Box>
      <ReactTable
        tableRef={tableRef}
        columns={columns}
        data={data}
        header="Ajojärjestely"
        dateRangePicker={<DateRangePicker {...getDateRangePickerPropsFromState(state, dispatch)}></DateRangePicker>}
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä toimituksia."
        selectedRows={selectedShipments}
        setSelectedRows={setSelectedShipments}
        renderRowSubComponent={renderRowSubComponent}
        exportName={`ajojarjestelyraportti_${state.dateRange?.start.toFormat(
          dateFormatUnderscore,
        )}_${state.dateRange?.end.toFormat(dateFormatUnderscore)}`}
        initialState={{
          selectedRowIds: state.selectedShipments,
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'load_id',
              asc: true,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        getCellProps={(cell: Cell) => {
          const stateUtils = state.viewSettings.showStateColors ? getShipmentStateUtils(cell.value) : undefined;
          return {
            style: {
              backgroundColor: stateUtils?.backgroundColor,
              color: stateUtils?.color,
            },
          };
        }}
        onStateChange={(state) => {
          dispatch({
            type: 'SET_TABLE_SETTINGS',
            payload: state,
          });
        }}
        columnSelector={true}
        viewId={VIEW_ID}
      />
      <LoadModal
        open={state.isLoadModalOpen}
        creationShipments={state.shipments.filter((shipment) => selectedShipments[shipment.id])}
        cars={state.cars}
        drivers={state.drivers}
        clients={state.clients}
        presetFields={state.presetLoadFields}
        onClose={(didCreateNewLoad) => {
          if (didCreateNewLoad) {
            tableRef.current?.deselectAllRows();
            dispatch({
              type: 'SET_MESSAGE',
              payload: {
                message: 'Kuorma tallennettu!',
              },
            });
            dispatch({
              type: 'CLEAR_SELECTED_SHIPMENTS',
            });
            load(carrierUser, state, dispatch);
          }
          dispatch({ type: 'SET_IS_LOAD_MODAL_OPEN', payload: { isLoadModalOpen: false } });
        }}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default ShipmentCoordination;
