/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { CarPricingRow, CarPricingRowFromJSON, CarPricingRowFromJSONTyped, CarPricingRowToJSON } from './CarPricingRow';
import {
  CustomPricingCategory,
  CustomPricingCategoryFromJSON,
  CustomPricingCategoryFromJSONTyped,
  CustomPricingCategoryToJSON,
} from './CustomPricingCategory';
import { PricingArea, PricingAreaFromJSON, PricingAreaFromJSONTyped, PricingAreaToJSON } from './PricingArea';
import { PricingRow, PricingRowFromJSON, PricingRowFromJSONTyped, PricingRowToJSON } from './PricingRow';

/**
 * A pricing model and its related pricing areas
 * @export
 * @interface PricingModelWithAreas
 */
export interface PricingModelWithAreas {
  /**
   *
   * @type {number}
   * @memberof PricingModelWithAreas
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof PricingModelWithAreas
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PricingModelWithAreas
   */
  hourly_price: number;
  /**
   *
   * @type {number}
   * @memberof PricingModelWithAreas
   */
  hourly_price_of_combined_vehicle: number;
  /**
   *
   * @type {number}
   * @memberof PricingModelWithAreas
   */
  express_delivery_price: number;
  /**
   *
   * @type {string}
   * @memberof PricingModelWithAreas
   */
  pricing_unit: PricingModelWithAreasPricingUnitEnum;
  /**
   *
   * @type {string}
   * @memberof PricingModelWithAreas
   */
  distance_pricing_basis: PricingModelWithAreasDistancePricingBasisEnum;
  /**
   *
   * @type {boolean}
   * @memberof PricingModelWithAreas
   */
  legacy_combined_pricing_for_same_day_deliveries_in_same_address: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PricingModelWithAreas
   */
  legacy_chargeable_weight_flooring: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PricingModelWithAreas
   */
  legacy_ignore_basic_price_if_hours_exist_and_not_exception: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PricingModelWithAreas
   */
  legacy_ignore_working_hours: boolean;
  /**
   *
   * @type {number}
   * @memberof PricingModelWithAreas
   */
  default_pricing_area: number | null;
  /**
   *
   * @type {number}
   * @memberof PricingModelWithAreas
   */
  default_custom_pricing_category: number | null;
  /**
   *
   * @type {Array<PricingArea>}
   * @memberof PricingModelWithAreas
   */
  pricing_areas: Array<PricingArea>;
  /**
   *
   * @type {Array<PricingRow>}
   * @memberof PricingModelWithAreas
   */
  pricing_rows: Array<PricingRow>;
  /**
   *
   * @type {Array<CarPricingRow>}
   * @memberof PricingModelWithAreas
   */
  car_pricing_rows: Array<CarPricingRow>;
  /**
   *
   * @type {Array<CustomPricingCategory>}
   * @memberof PricingModelWithAreas
   */
  custom_pricing_categories: Array<CustomPricingCategory>;
}

/**
 * @export
 * @enum {string}
 */
export enum PricingModelWithAreasPricingUnitEnum {
  VolumeM3 = 'volume_m3',
  LengthLdm = 'length_ldm',
  WeightKg = 'weight_kg',
  WorkingHours = 'working_hours',
  CustomCategory = 'custom_category',
} /**
 * @export
 * @enum {string}
 */
export enum PricingModelWithAreasDistancePricingBasisEnum {
  FixedArea = 'fixed_area',
  DistanceKm = 'distance_km',
}

export function PricingModelWithAreasFromJSON(json: any): PricingModelWithAreas {
  return PricingModelWithAreasFromJSONTyped(json, false);
}

export function PricingModelWithAreasFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingModelWithAreas {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    hourly_price: json['hourly_price'],
    hourly_price_of_combined_vehicle: json['hourly_price_of_combined_vehicle'],
    express_delivery_price: json['express_delivery_price'],
    pricing_unit: json['pricing_unit'],
    distance_pricing_basis: json['distance_pricing_basis'],
    legacy_combined_pricing_for_same_day_deliveries_in_same_address:
      json['legacy_combined_pricing_for_same_day_deliveries_in_same_address'],
    legacy_chargeable_weight_flooring: json['legacy_chargeable_weight_flooring'],
    legacy_ignore_basic_price_if_hours_exist_and_not_exception:
      json['legacy_ignore_basic_price_if_hours_exist_and_not_exception'],
    legacy_ignore_working_hours: json['legacy_ignore_working_hours'],
    default_pricing_area: json['default_pricing_area'],
    default_custom_pricing_category: json['default_custom_pricing_category'],
    pricing_areas: (json['pricing_areas'] as Array<any>).map(PricingAreaFromJSON),
    pricing_rows: (json['pricing_rows'] as Array<any>).map(PricingRowFromJSON),
    car_pricing_rows: (json['car_pricing_rows'] as Array<any>).map(CarPricingRowFromJSON),
    custom_pricing_categories: (json['custom_pricing_categories'] as Array<any>).map(CustomPricingCategoryFromJSON),
  };
}

export function PricingModelWithAreasToJSON(value?: PricingModelWithAreas | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    hourly_price: value.hourly_price,
    hourly_price_of_combined_vehicle: value.hourly_price_of_combined_vehicle,
    express_delivery_price: value.express_delivery_price,
    pricing_unit: value.pricing_unit,
    distance_pricing_basis: value.distance_pricing_basis,
    legacy_combined_pricing_for_same_day_deliveries_in_same_address:
      value.legacy_combined_pricing_for_same_day_deliveries_in_same_address,
    legacy_chargeable_weight_flooring: value.legacy_chargeable_weight_flooring,
    legacy_ignore_basic_price_if_hours_exist_and_not_exception:
      value.legacy_ignore_basic_price_if_hours_exist_and_not_exception,
    legacy_ignore_working_hours: value.legacy_ignore_working_hours,
    default_pricing_area: value.default_pricing_area,
    default_custom_pricing_category: value.default_custom_pricing_category,
    pricing_areas: (value.pricing_areas as Array<any>).map(PricingAreaToJSON),
    pricing_rows: (value.pricing_rows as Array<any>).map(PricingRowToJSON),
    car_pricing_rows: (value.car_pricing_rows as Array<any>).map(CarPricingRowToJSON),
    custom_pricing_categories: (value.custom_pricing_categories as Array<any>).map(CustomPricingCategoryToJSON),
  };
}
