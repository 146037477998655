/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A client that is listed in Timeweb system, or the host organization.
 * @export
 * @interface Organization
 */
export interface Organization {
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  readonly id: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  is_active: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  send_delivery_notification_sms: boolean;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  default_agreed_delivery_window_time_starts_at: string | null;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  default_agreed_delivery_window_time_ends_at: string | null;
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  default_pricing_model: number | null;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  business_id?: string | null;
}

export function OrganizationFromJSON(json: any): Organization {
  return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    is_active: json['is_active'],
    send_delivery_notification_sms: json['send_delivery_notification_sms'],
    default_agreed_delivery_window_time_starts_at: json['default_agreed_delivery_window_time_starts_at'],
    default_agreed_delivery_window_time_ends_at: json['default_agreed_delivery_window_time_ends_at'],
    default_pricing_model: json['default_pricing_model'],
    business_id: !exists(json, 'business_id') ? undefined : json['business_id'],
  };
}

export function OrganizationToJSON(value?: Organization | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    is_active: value.is_active,
    send_delivery_notification_sms: value.send_delivery_notification_sms,
    default_agreed_delivery_window_time_starts_at: value.default_agreed_delivery_window_time_starts_at,
    default_agreed_delivery_window_time_ends_at: value.default_agreed_delivery_window_time_ends_at,
    default_pricing_model: value.default_pricing_model,
    business_id: value.business_id,
  };
}
