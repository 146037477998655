/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FutureWorkHourType,
  FutureWorkHourTypeFromJSON,
  FutureWorkHourTypeFromJSONTyped,
  FutureWorkHourTypeToJSON,
} from './FutureWorkHourType';

/**
 * A single future work hour.
 * @export
 * @interface FutureWorkHour
 */
export interface FutureWorkHour {
  /**
   *
   * @type {number}
   * @memberof FutureWorkHour
   */
  readonly id?: number;
  /**
   *
   * @type {string}
   * @memberof FutureWorkHour
   */
  employee_number: string | null;
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHour
   */
  date: Date;
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHour
   */
  starts_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHour
   */
  ends_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof FutureWorkHour
   */
  net_hours: string;
  /**
   *
   * @type {string}
   * @memberof FutureWorkHour
   */
  evening_hours: string;
  /**
   *
   * @type {string}
   * @memberof FutureWorkHour
   */
  night_hours: string;
  /**
   *
   * @type {number}
   * @memberof FutureWorkHour
   */
  assignment_id: number;
  /**
   *
   * @type {string}
   * @memberof FutureWorkHour
   */
  note: string | null;
  /**
   *
   * @type {Date}
   * @memberof FutureWorkHour
   */
  received_at: Date | null;
  /**
   *
   * @type {FutureWorkHourType}
   * @memberof FutureWorkHour
   */
  type: FutureWorkHourType;
}

export function FutureWorkHourFromJSON(json: any): FutureWorkHour {
  return FutureWorkHourFromJSONTyped(json, false);
}

export function FutureWorkHourFromJSONTyped(json: any, ignoreDiscriminator: boolean): FutureWorkHour {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    employee_number: json['employee_number'],
    date: new Date(json['date']),
    starts_at: json['starts_at'] === null ? null : new Date(json['starts_at']),
    ends_at: json['ends_at'] === null ? null : new Date(json['ends_at']),
    net_hours: json['net_hours'],
    evening_hours: json['evening_hours'],
    night_hours: json['night_hours'],
    assignment_id: json['assignment_id'],
    note: json['note'],
    received_at: json['received_at'] === null ? null : new Date(json['received_at']),
    type: FutureWorkHourTypeFromJSON(json['type']),
  };
}

export function FutureWorkHourToJSON(value?: FutureWorkHour | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employee_number: value.employee_number,
    date: value.date.toISOString(),
    starts_at: value.starts_at === null ? null : value.starts_at.toISOString(),
    ends_at: value.ends_at === null ? null : value.ends_at.toISOString(),
    net_hours: value.net_hours,
    evening_hours: value.evening_hours,
    night_hours: value.night_hours,
    assignment_id: value.assignment_id,
    note: value.note,
    received_at: value.received_at === null ? null : value.received_at.toISOString(),
    type: FutureWorkHourTypeToJSON(value.type),
  };
}
