import { Stack, TextField } from '@mui/material';
import React, { Dispatch } from 'react';
import { Action, FieldName, State, updateFieldValue } from '../createNewUser.state';

interface InputProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  disabled?: boolean;
}

interface DispatchableInputProps extends InputProps {
  dispatch: Dispatch<Action>;
}

export const AllUserFields: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <Stack spacing={2} marginBottom={2}>
      <Username state={state} dispatch={dispatch} disabled={false} />
      <FirstName state={state} dispatch={dispatch} disabled={false} />
      <LastName state={state} dispatch={dispatch} disabled={false} />
      <Email state={state} dispatch={dispatch} disabled={false} />
      <PhoneNumber state={state} dispatch={dispatch} disabled={false} />
      <Password state={state} dispatch={dispatch} />
      <PasswordAgain state={state} dispatch={dispatch} />
    </Stack>
  );
};

const Username: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      data-cy="username"
      variant="outlined"
      fullWidth
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.username.required}
      name="username"
      error={state.fields.username.hasError}
      helperText={state.fields.username.feedback}
      label="Käyttäjätunnus"
      value={state.fields.username.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const Email: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      data-cy="email"
      variant="outlined"
      fullWidth
      disabled={disabled}
      required={state.fields.email.required}
      name="email"
      error={state.fields.email.hasError}
      helperText={state.fields.email.feedback}
      label="Sähköposti"
      value={state.fields.email.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const FirstName: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      data-cy="first_name"
      variant="outlined"
      fullWidth
      disabled={disabled}
      required={state.fields.first_name.required}
      name="first_name"
      error={state.fields.first_name.hasError}
      helperText={state.fields.first_name.feedback}
      label="Etunimi"
      value={state.fields.first_name.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const LastName: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      data-cy="last_name"
      variant="outlined"
      fullWidth
      disabled={disabled}
      required={state.fields.last_name.required}
      name="last_name"
      error={state.fields.last_name.hasError}
      helperText={state.fields.last_name.feedback}
      label="Sukunimi"
      value={state.fields.last_name.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const PhoneNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      data-cy="gsm"
      variant="outlined"
      fullWidth
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.gsm.required}
      name="gsm"
      error={state.fields.gsm.hasError}
      helperText={state.fields.gsm.feedback}
      label="Puhelinnumero"
      value={state.fields.gsm.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const Password: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      data-cy="password"
      variant="outlined"
      fullWidth
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.password.required}
      name="password"
      type="password"
      error={state.fields.password.hasError}
      helperText={state.fields.password.feedback}
      label="Uusi salasana"
      value={state.fields.password.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const PasswordAgain: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      data-cy="password_again"
      variant="outlined"
      fullWidth
      autoComplete="new-password"
      disabled={disabled}
      required={state.fields.password_again.required}
      name="password_again"
      type="password"
      error={state.fields.password_again.hasError}
      helperText={state.fields.password_again.feedback}
      label="Uusi salasana uudestaan"
      value={state.fields.password_again.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};
