import { Backdrop, Button, Dialog, DialogActions, DialogContent, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loading } from './Loading';
import theme from '../theme';
import { api, ShipmentRow, ShipmentRowProduct } from '../api';
import { ReactTable } from './ReactTable';
import { Column } from 'react-table';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { canAccessCoordination } from '../utils';

interface ShipmentRowProductsModalProps extends React.HTMLAttributes<HTMLElement> {
  shipmentRow: ShipmentRow;
  open: boolean;
  onClose: () => void;
}

export const ShipmentRowProductsModal: React.FC<ShipmentRowProductsModalProps> = ({ shipmentRow, open, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<ShipmentRowProduct[]>([]);
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (canAccessCoordination(currentUser)) {
      api.shipments
        .getShipmentRowProducts({ shipmentId: shipmentRow.shipment_id, shipmentRowId: shipmentRow.id })
        .then((shipmentRowProductsResponse) => {
          setProducts(shipmentRowProductsResponse.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      api.organizationShipments
        .getOrganizationShipmentRowProducts({
          organizationId: currentUser?.organization_id ?? '',
          shipmentId: shipmentRow.shipment_id,
          shipmentRowId: shipmentRow.id,
        })
        .then((shipmentRowProductsResponse) => {
          setProducts(shipmentRowProductsResponse.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [shipmentRow.id]);

  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        Header: 'Nimi',
        accessor: 'name',
      },
      {
        Header: 'kpl',
        accessor: 'quantity',
        sortType: 'basic',
      },
      {
        Header: 'Kappalepaino (kg)',
        accessor: 'weight_per_piece_kg',
        sortType: 'basic',
      },
      {
        Header: 'Kokonaispaino (kg)',
        accessor: 'total_weight_kg',
        sortType: 'basic',
      },
    ],
    [],
  );
  const data = React.useMemo(() => products, [products]);
  return (
    <Dialog
      className="shipment-row-product-modal"
      aria-labelledby="shipment-row-product-modal-title"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        margin: theme.spacing(2),
      }}
      maxWidth="xl"
      fullScreen={useMediaQuery(theme.breakpoints.down('md'))}
    >
      <Loading isLoading={isLoading} />
      <DialogContent>
        <ReactTable
          columns={columns}
          data={data}
          header="Lähetysluettelo"
          isLoading={isLoading}
          emptyText="Ei näytettäviä tuotteita."
        />
        <DialogActions>
          <Button color="error" onClick={onClose} disabled={isLoading}>
            Sulje
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
