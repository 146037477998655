import React, { Dispatch, useEffect, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import {
  Action,
  getInitialState,
  reducer,
  State,
  updateAdditionalServices,
  updateAssignments,
  updateFieldValue,
  updateOffices,
} from './organization.state';
import {
  AdditionalServicePutBody,
  api,
  AssignmentPutBody,
  OfficePutBody,
  Organization,
  OrganizationPutBody,
} from '../../api';
import { useParams } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { FieldSet } from '../../components/StyledComponents/FieldSet';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AgreedDeliveryTimePicker, BusinessId, Id, IsActiveCheckbox, Name, SmsCheckbox } from './components/inputs';
import { AdditionalServicesTable, validateAdditionalServices } from './components/AdditionalServicesTable';
import OfficesTable, { validateOffices } from './components/OfficesTable';
import AssignmentsTable, { validateAssignments } from './components/AssignmentsTable';
import { PricingModelPicker } from '../../components/PricingModelPicker';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { FieldSetContainer } from '../../components/StyledComponents/FieldSetContainer';

const load = async (organizationId: Organization['id'], dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [
      organizationResponse,
      additionalServicesResponse,
      assignmentsResponse,
      officesResponse,
      allAssignmetsResponse,
      pricingModelsResponse,
    ] = await Promise.all([
      api.organizations.getOrganization({ organizationId }),
      api.organizationAdditionalServices.getOrganizationAdditionalServices({ organizationId }),
      api.organizationAssignments.getOrganizationAssignments({ organizationId }),
      api.organizationOffices.getOrganizationOffices({ organizationId }),
      api.assignments.getAssignments(),
      api.pricing.getPricingModels(),
    ]);
    dispatch({
      type: 'initializeOrganization',
      payload: {
        organization: organizationResponse.data,
      },
    });
    dispatch({
      type: 'setAdditionalServices',
      payload: {
        additionalServices: additionalServicesResponse.data as AdditionalServicePutBody[],
      },
    });
    dispatch({
      type: 'setAssignments',
      payload: {
        assignments: assignmentsResponse.data as AssignmentPutBody[],
      },
    });
    dispatch({
      type: 'setOffices',
      payload: {
        offices: officesResponse.data as OfficePutBody[],
      },
    });
    dispatch({
      type: 'SET_ALL_ASSIGNMENTS',
      payload: {
        allAssignments: allAssignmetsResponse.data,
      },
    });
    dispatch({
      type: 'SET_PRICING_MODELS',
      payload: {
        pricingModels: pricingModelsResponse.data,
      },
    });
    dispatch({
      type: 'VALIDATE_FIELDS',
    });
  } catch (err) {
    let message = 'Asiakkaan lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Asiakasta ei löytynyt!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

export const getOrganizationPutBodyFromState = (state: State): OrganizationPutBody => {
  return {
    is_active: state.fields.is_active.value,
    name: state.fields.name.value,
    business_id: state.fields.business_id.value,
    send_delivery_notification_sms: state.fields.send_delivery_notification_sms.value,
    default_agreed_delivery_window_time_starts_at: state.fields.default_agreed_delivery_window_time_starts_at.value,
    default_agreed_delivery_window_time_ends_at: state.fields.default_agreed_delivery_window_time_ends_at.value,
    default_pricing_model: state.fields.default_pricing_model.value,
  };
};

const saveOrganization = async (organizationId: Organization['id'], state: State, dispatch: Dispatch<Action>) => {
  if (!organizationId) {
    throw new Error('Missing org id');
  }
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });

  try {
    await api.organizations.updateOrganization({
      organizationId,
      organizationPutBody: getOrganizationPutBodyFromState(state),
    });
    await Promise.all([
      Promise.all(
        state.additionalServices.map((additionalService) => {
          if (additionalService.id) {
            return api.organizationAdditionalServices.updateAdditionalService({
              organizationId: organizationId,
              additionalServiceId: additionalService.id,
              additionalServicePutBody: {
                name: additionalService.name,
                price: additionalService.price,
                work_price: additionalService.work_price,
                archived: additionalService.archived,
              },
            });
          } else {
            return api.organizationAdditionalServices.createAdditionalService({
              organizationId: organizationId,
              additionalServicePostBody: {
                name: additionalService.name,
                price: additionalService.price,
                work_price: additionalService.work_price,
                archived: additionalService.archived,
              },
            });
          }
        }),
      ),
      Promise.all(
        state.assignments.map((assignment) => {
          if (assignment.id) {
            return api.organizationAssignments.updateAssignment({
              organizationId: organizationId,
              assignmentId: assignment.id,
              assignmentPutBody: {
                description: assignment.description,
                assignment_specific_id: assignment.assignment_specific_id,
              },
            });
          } else {
            return api.organizationAssignments.createAssignment({
              organizationId: organizationId,
              assignmentPostBody: {
                description: assignment.description,
                assignment_specific_id: assignment.assignment_specific_id,
              },
            });
          }
        }),
      ),
      Promise.all(
        state.offices.map((office) => {
          if (office.id) {
            return api.organizationOffices.updateOffice({
              organizationId: organizationId,
              officeId: office.id,
              officePutBody: {
                organization_specific_office_id: office.organization_specific_office_id,
                name: office.name,
                address: office.address,
                postal_code: office.postal_code,
                city: office.city,
                billing_enabled: office.billing_enabled,
                phone_number: office.phone_number,
                contact_person_name: office.contact_person_name,
                default_pricing_model: office.default_pricing_model,
                is_other_carrier: office.is_other_carrier,
              },
            });
          } else {
            return api.organizationOffices.createOffice({
              organizationId: organizationId,
              officePostBody: {
                organization_specific_office_id: office.organization_specific_office_id,
                name: office.name,
                address: office.address,
                postal_code: office.postal_code,
                city: office.city,
                billing_enabled: office.billing_enabled,
                phone_number: office.phone_number,
                contact_person_name: office.contact_person_name,
                default_pricing_model: office.default_pricing_model,
                is_other_carrier: office.is_other_carrier,
              },
            });
          }
        }),
      ),
    ]);
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Asiakas tallennettu!' } });
    load(organizationId, dispatch);
  } catch (err) {
    dispatch(tryAgainMessage);
    dispatch({ type: 'SET_LOADING', payload: false });

    console.error(err);
  }
};

type EditOrganizationParams = {
  organizationId?: string;
};

const EditOrganization: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { organizationId } = useParams<EditOrganizationParams>();

  useEffect(() => {
    load(organizationId || '', dispatch);
  }, [organizationId]);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <>
        <StyledForm noValidate autoComplete="off">
          <HeaderContainer>
            <Header title={`Asiakas ${state.originalOrganization?.name || ''}`}>
              <SaveButton
                disabled={
                  !state.isValid ||
                  state.isLoading ||
                  validateAdditionalServices(state.additionalServices) ||
                  validateOffices(state.offices) ||
                  validateAssignments(state.assignments, state.allAssignments)
                }
                id="save-organization-button"
                tooltip={
                  !state.isValid ||
                  validateAdditionalServices(state.additionalServices) ||
                  validateOffices(state.offices) ||
                  validateAssignments(state.assignments, state.allAssignments)
                    ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä'
                    : ''
                }
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  saveOrganization(organizationId as string, state, dispatch);
                  event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
                }}
              >
                Tallenna
              </SaveButton>
            </Header>
          </HeaderContainer>
          <FieldSetContainer>
            <FieldSet id="organization-basic-fields">
              <legend>Perustiedot</legend>
              <Id state={state} dispatch={dispatch} disabled={true} />
              <Name state={state} dispatch={dispatch} />
              <BusinessId state={state} dispatch={dispatch} />
              <AgreedDeliveryTimePicker state={state} dispatch={dispatch} />
              <IsActiveCheckbox state={state} dispatch={dispatch} />
              <SmsCheckbox state={state} dispatch={dispatch} />
              <PricingModelPicker
                disabled={state.isLoading}
                pricingModels={state.pricingModels}
                value={
                  state.pricingModels.find(
                    (pricingModel) => pricingModel.id === state.fields.default_pricing_model.value,
                  ) ?? null
                }
                onChange={(pricingModel) => {
                  updateFieldValue('default_pricing_model', pricingModel?.id ?? null, dispatch);
                }}
              />
            </FieldSet>
          </FieldSetContainer>
        </StyledForm>
        <AdditionalServicesTable
          additionalServices={state.additionalServices}
          onAdditionalServicesUpdate={(additionalServices) => updateAdditionalServices(additionalServices, dispatch)}
        />
        <OfficesTable
          offices={state.offices}
          onOfficesUpdate={(offices) => updateOffices(offices, dispatch)}
          pricingModels={state.pricingModels}
        />
        <AssignmentsTable
          allAssignments={state.allAssignments}
          assignments={state.assignments}
          onAssignmentsUpdate={(assignments) => updateAssignments(assignments, dispatch)}
        />
      </>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default EditOrganization;
