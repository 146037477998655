import React, { Dispatch, useEffect, useReducer, useState } from 'react';
import Main from '../../components/Main';
import { updateRows } from './shipment.state';
import {
  getInitialState,
  getOrganizationShipmentPutBodyFromState,
  getShipmentPutBodyFromState,
  getUpdateDistanceAndItsRelatedShipmentsPutBodyFromState,
} from './state/shipment.state.utils';
import { convertToReturnShipment, copyShipment } from './utils/shipment.utils';
import { Action, State } from './types/shipment.types';
import {
  AdditionalService,
  api,
  Car,
  GetShipmentIncludeEnum,
  Load,
  Office,
  Organization,
  OrganizationCar,
  OrganizationPhotosResponseBody,
  OrganizationPricingModelsResponseBody,
  OrganizationShipment,
  OrganizationShipmentResponseBody,
  OrganizationShipmentRowsResponseBody,
  OrganizationsResponseBody,
  Photo,
  PhotosResponseBody,
  PricingModelsResponseBody,
  Shipment,
  ShipmentAdditionalService,
  ShipmentResponseBody1,
  ShipmentRow,
  ShipmentStateEnum,
  User,
} from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { getSnackbarPropsFromState, tryAgainMessage } from '../../components/Notification';
import { Alert, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { UpdateInfo } from './components/UpdateInfo';
import { downloadWaybill } from '../../components/Waybill';
import { DateTime } from 'luxon';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { previousAndNextShipmentLinks } from './components/ShipmentSetHistory';
import { canAccessCoordination, canAccessCustomerCoordination } from '../../utils';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { ButtonContainer } from '../../components/StyledComponents/ButtonContainer';
import { useCurrentUser, useCurrentUserOrganization } from '../../hooks/useCurrentUser';
import { AllShipmentFields } from './components/allShipmentFields';
import NewShipmenRowTable from './components/NewShipmentRowTable';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { isPreciseDelivery } from '../../components/DeliveryWindow';
import { isNumber } from 'lodash';
import { DriverOrOrganizationDriver } from '../loads/loads.state';
import { reducer } from './state/shipment.reducer';

export const load = async (
  userOrganizationId: OrganizationShipment['organization_id'],
  shipmentId: OrganizationShipment['id'],
  dispatch: Dispatch<Action>,
  currentUser: User,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let shipmentResponse: OrganizationShipmentResponseBody | ShipmentResponseBody1;
    let shipmentRowsResponse: OrganizationShipmentRowsResponseBody | undefined = undefined;
    let shipmentPhotosResponse: PhotosResponseBody | OrganizationPhotosResponseBody | undefined;
    let organizationsResponse: OrganizationsResponseBody | undefined;
    let pricingModelsResponse: PricingModelsResponseBody | OrganizationPricingModelsResponseBody | undefined;
    let load: Load | undefined = undefined;
    let driver: DriverOrOrganizationDriver | undefined = undefined;
    let car: Car | OrganizationCar | undefined = undefined;
    let shipmentOrganization: Organization | undefined = undefined;
    let shipmentRows: ShipmentRow[] = [];
    let shipmentPhotos: Photo[] | undefined = undefined;
    let organizationOffices: Office[] = [];
    let organizationAdditionalServices: AdditionalService[] = [];
    let shipmentAdditionalServices: ShipmentAdditionalService[] = [];
    if (canAccessCoordination(currentUser)) {
      [shipmentResponse, organizationsResponse, pricingModelsResponse] = await Promise.all([
        api.shipments.getShipment({
          shipmentId,
          include: [
            GetShipmentIncludeEnum.Load,
            GetShipmentIncludeEnum.LoadDriver,
            GetShipmentIncludeEnum.LoadCar,
            GetShipmentIncludeEnum.Organization,
            GetShipmentIncludeEnum.Rows,
            GetShipmentIncludeEnum.Photos,
            GetShipmentIncludeEnum.ShipmentAdditionalServices,
            GetShipmentIncludeEnum.OrganizationAdditionalServices,
            GetShipmentIncludeEnum.OrganizationOffices,
          ],
        }),
        api.organizations.getOrganizations({}),
        api.pricing.getPricingModels(),
      ]);
      const response = shipmentResponse as ShipmentResponseBody1;
      shipmentRows = response.related?.rows ?? [];
      shipmentOrganization = response.related?.organization;
      shipmentPhotos = response.related?.photos ?? [];
      organizationOffices = response.related?.offices ?? [];
      organizationAdditionalServices = response.related?.additional_services ?? [];
      shipmentAdditionalServices = response.related?.shipment_additional_services ?? [];
      if (shipmentResponse.data.load_id) {
        load = response.related?.load;
        driver = response.related?.driver ?? undefined;
        car = response.related?.car ?? undefined;
      }
    } else {
      [shipmentResponse, shipmentRowsResponse, shipmentPhotosResponse] = await Promise.all([
        api.organizationShipments.getOrganizationShipment({
          organizationId: userOrganizationId,
          shipmentId,
        }),
        api.organizationShipments.getOrganizationShipmentRows({
          organizationId: userOrganizationId,
          shipmentId,
        }),
        api.organizationShipments.getOrganizationShipmentPhotos({
          organizationId: userOrganizationId,
          shipmentId,
        }),
      ]);
      if (shipmentResponse.data.load_id && canAccessCustomerCoordination(currentUser)) {
        load = (
          await api.organizationLoads.getOrganizationLoad({
            organizationId: userOrganizationId,
            loadId: shipmentResponse.data.load_id,
          })
        ).data;
        if (load.drive_date) {
          const startOfDriveDate = DateTime.fromJSDate(load.drive_date).startOf('day').toJSDate();
          const endOfDriveDate = DateTime.fromJSDate(load.drive_date).endOf('day').toJSDate();
          if (load.driver_id) {
            driver = (
              await api.organizationDrivers.getOrganizationDrivers({
                organizationId: userOrganizationId,
                loadIds: [load.id],
                driveDateRangeStartsAt: startOfDriveDate,
                driveDateRangeEndsAt: endOfDriveDate,
              })
            ).data[0];
          }
          if (load.car_id) {
            car = (
              await api.organizationCars.getOrganizationCars({
                organizationId: userOrganizationId,
                loadIds: [load.id],
                driveDateRangeStartsAt: startOfDriveDate,
                driveDateRangeEndsAt: endOfDriveDate,
              })
            ).data[0];
          }
        }
      }
      shipmentOrganization = (
        await api.organizations.getOrganization({ organizationId: shipmentResponse.data.organization_id })
      ).data;
      const [organizationOfficesResponse, organizationAdditionalServicesResponse, shipmentAdditionalServicesResponse] =
        await Promise.all([
          api.organizationOffices.getOrganizationOffices({ organizationId: shipmentOrganization.id }),
          api.organizationAdditionalServices.getOrganizationAdditionalServices({
            organizationId: shipmentOrganization.id,
          }),
          api.organizationShipments.getOrganizationShipmentAdditionalServices({
            organizationId: shipmentOrganization.id,
            shipmentId: shipmentResponse.data.id,
          }),
        ]);
      organizationOffices = organizationOfficesResponse.data;
      organizationAdditionalServices = organizationAdditionalServicesResponse.data;
      shipmentAdditionalServices = shipmentAdditionalServicesResponse.data ?? [];
      pricingModelsResponse = await api.organizationPricing.getOrganizationPricingModels({
        organizationId: shipmentOrganization.id,
      });
    }
    const organization = shipmentOrganization as Organization;
    const editRights = await api.shipments.getShipmentEditRights({
      shipmentId: shipmentResponse.data.id,
    });
    dispatch({
      type: 'INITIALIZE_SHIPMENT',
      payload: {
        shipment: shipmentResponse.data,
        shipmentPhotos: shipmentPhotos ?? shipmentPhotosResponse?.data ?? [],
        shipmentRows: shipmentRowsResponse?.data ?? shipmentRows,
        load,
        driver,
        car,
        currentUser: currentUser,
        organization: organization,
        organizationOffices,
        organizationAdditionalServices,
        shipmentAdditionalServices: shipmentAdditionalServices,
        organizations: organizationsResponse?.data ?? [organization],
        pricingModels: pricingModelsResponse?.data ?? [],
        editRights: editRights.data,
      },
    });
    dispatch({
      type: 'VALIDATE_FIELDS',
    });
  } catch (err) {
    let message = 'Toimituksen lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Toimitusta ei löytynyt!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const getRelatedShipments = async (
  shipmentId: OrganizationShipment['id'],
  shipmentOrganizationId: OrganizationShipment['organization_id'],
  shipmentReferenceNumber: OrganizationShipment['reference_number'],
  showAllRelatedShipments: boolean,
  dispatch: Dispatch<Action>,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_IS_RELATED_SHIPMENTS_LOADING',
      payload: {
        isRelatedShipmentsLoading: true,
      },
    });
    let relatedShipmentsResponse: Shipment[] | undefined = undefined;
    if (
      shipmentReferenceNumber &&
      !shipmentReferenceNumber.includes('SIS SIIRTO') &&
      !shipmentReferenceNumber.includes('KURIIRI')
    ) {
      const formattedReferenceNumber = shipmentReferenceNumber.match(/[^-/.]*/) ?? [];
      relatedShipmentsResponse = showAllRelatedShipments
        ? (
            await api.search.searchOrganizationShipments({
              searchOrganizationShipmentsPostBody: {
                reference_number: formattedReferenceNumber[0] ? formattedReferenceNumber[0] : shipmentReferenceNumber,
              },
              organizationId: shipmentOrganizationId,
            })
          ).data
        : (
            await api.search.searchOrganizationShipments({
              searchOrganizationShipmentsPostBody: {
                reference_number: formattedReferenceNumber[0] ? formattedReferenceNumber[0] : shipmentReferenceNumber,
                agreed_delivery_window_date_range_starts_at: DateTime.local()
                  .startOf('day')
                  .minus({ days: 30 })
                  .toJSDate(),
              },
              organizationId: shipmentOrganizationId,
            })
          ).data;
    }
    dispatch({
      type: 'SET_RELATED_SHIPMENTS',
      payload: {
        relatedShipments: relatedShipmentsResponse?.filter((shipment) => shipment.id !== shipmentId),
      },
    });
  } catch (err) {
    dispatch({
      type: 'SET_MESSAGE',
      payload: { message: 'Saman viitenumeron toimitusten lataus epäonnistui', severity: 'warning' },
    });
    console.error(err);
  }
  dispatch({
    type: 'SET_IS_RELATED_SHIPMENTS_LOADING',
    payload: {
      isRelatedShipmentsLoading: false,
    },
  });
};

const saveShipment = async (
  shipmentId: Shipment['id'],
  state: State,
  dispatch: Dispatch<Action>,
  currentUser?: User,
) => {
  dispatch({ type: 'SET_LOADING', payload: true });
  if (!currentUser?.organization_id) {
    throw new Error('Missing org id');
  }
  if (!state.canSave && !canAccessCoordination(currentUser)) {
    throw new Error('Prerequisities not met');
  }
  try {
    if (canAccessCoordination(currentUser)) {
      await api.shipments.updateShipment({
        shipmentId,
        shipmentPutBody: getShipmentPutBodyFromState(state, currentUser),
      });
      if (
        state.fields.has_distance_been_fixed.value &&
        state.fields.legacy_etaisyys_field.value &&
        state.fields.legacy_etaisyys_field.value !== state.originalShipment?.legacy_etaisyys_field
      ) {
        await api.distances.updateDistanceAndItsRelatedShipments({
          updateDistanceAndItsRelatedShipmentsPatchBody: getUpdateDistanceAndItsRelatedShipmentsPutBodyFromState(state),
        });
      }
    } else {
      await api.organizationShipments.updateOrganizationShipment({
        organizationId: state.fields.organization_id.value,
        shipmentId,
        organizationShipmentPutBody: getOrganizationShipmentPutBodyFromState(state),
      });
    }
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Toimitus tallennettu!' } });
    load(state.fields.organization_id.value, shipmentId, dispatch, currentUser);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
  }
  dispatch({ type: 'SET_LOADING', payload: false });
};

type EditShipmentParams = {
  shipmentId?: string;
};

const getSaveShipmentTooltip = (
  currentUser: User | undefined,
  canSave: boolean,
  isValid: boolean,
  fields: State['fields'],
) => {
  const isInLoadOrPickedUp =
    fields.load_id.value ||
    [ShipmentStateEnum.Noudettu, ShipmentStateEnum.Noutokohteessa, ShipmentStateEnum.Toimituskohteessa].includes(
      fields.state.value as ShipmentStateEnum,
    );

  if (!canSave) {
    if (fields.billed_at.value) {
      return 'Laskutettua toimitusta ei voi muokata';
    }
    if (fields.state.value === ShipmentStateEnum.Toimitettu) {
      return 'Toimitettua toimitusta ei voi muokata';
    }
    if (fields.state.value === ShipmentStateEnum.Peruttu) {
      return 'Peruttua toimitusta ei voi muokata';
    }
    if (isInLoadOrPickedUp) {
      return 'Kuormassa olevaa toimitusta ei voi muokata';
    }
  }

  if (!isValid) {
    return 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä';
  }

  if (isInLoadOrPickedUp && !canAccessCoordination(currentUser)) {
    return 'Kuormaan lisätyn toimituksen kaikkia kenttiä ei voi muokata';
  }
};

const EditShipment: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const organization = useCurrentUserOrganization();
  const [state, dispatch] = useReducer(reducer, getInitialState(currentUser));
  const { shipmentId } = useParams<EditShipmentParams>();
  const parsedShipmentId = parseInt(shipmentId || '');
  const [showAllRelatedShipments, setShowAllRelatedShipments] = React.useState(false);
  const [previousShipment, nextShipment] = React.useMemo(previousAndNextShipmentLinks, [shipmentId]);
  const [areShipmentRowsBeingEdited, setAreShipmentRowsBeingEdited] = useState(false);

  useEffect(() => {
    if (currentUser?.organization_id) {
      dispatch({ type: 'CLEAR_SHIPMENT', payload: { currentUser } });
      load(currentUser.organization_id, parsedShipmentId, dispatch, currentUser);
    }
  }, [currentUser, parsedShipmentId]);

  useEffect(() => {
    if (currentUser && state.originalShipment) {
      getRelatedShipments(
        state.originalShipment.id,
        state.originalShipment.organization_id,
        state.originalShipment.reference_number,
        showAllRelatedShipments,
        dispatch,
      );
    }
  }, [state.originalShipment?.reference_number, showAllRelatedShipments]);

  return (
    <Main
      isInitialized={state.initialized}
      isLoading={state.isLoading}
      notificationProps={getSnackbarPropsFromState(state, dispatch)}
    >
      <StyledForm noValidate autoComplete="on">
        <HeaderContainer>
          <Header title={`Toimitus ${parsedShipmentId}`}>
            <ButtonContainer>
              <SaveButton
                disabled={state.isLoading || !state.canSave || areShipmentRowsBeingEdited}
                color={!state.isValid ? 'warning' : 'success'}
                id="save-shipment-button"
                tooltip={getSaveShipmentTooltip(currentUser, state.canSave, state.isValid, state.fields)}
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  saveShipment(parsedShipmentId, state, dispatch, currentUser);
                  event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
                }}
              >
                Tallenna toimitus
              </SaveButton>
              <Button
                disabled={state.isLoading}
                id="copy-shipment-button"
                onClick={() => {
                  if (!state.originalShipment || !organization) {
                    throw new Error('Prerequisities not met');
                  }
                  navigate('/shipments/new', {
                    state: {
                      shipment: copyShipment(state.originalShipment),
                      shipmentRows: state.rows,
                      additionalServiceFields: state.additionalServiceFields,
                    },
                  });
                }}
              >
                Luo kopio
              </Button>
              <Button
                disabled={state.isLoading}
                id="create-return-shipment-button"
                onClick={() => {
                  if (!state.originalShipment || !organization) {
                    throw new Error('Prerequisities not met');
                  }
                  navigate('/shipments/new', {
                    state: {
                      shipment: convertToReturnShipment(state.originalShipment),
                      shipmentRows: state.rows,
                      additionalServiceFields: state.additionalServiceFields,
                    },
                  });
                }}
              >
                Luo paluutoimitus
              </Button>
              <Button
                disabled={state.isLoading}
                id="create-waybill-button"
                onClick={() => {
                  if (state.originalShipment) {
                    downloadWaybill(state.originalShipment, state.rows, state.driver);
                  }
                }}
              >
                Luo rahtikirja
              </Button>
              {/* TODO This is commented out, since we are not ready to use it */}
              {/* <Button
                disabled={state.isLoading}
                id="create-shipment-row-stickers-button"
                onClick={() => {
                  if (state.originalShipment && state.rows) {
                    downloadShipmentRowStickers(state.originalShipment, state.rows);
                  }
                }}
              >
                Luo kollitarrat
              </Button> */}
              {(previousShipment || nextShipment) && (
                <>
                  <Tooltip title="Edellinen toimitus">
                    <span>
                      <IconButton
                        disabled={!previousShipment}
                        id="previous-shipment-button"
                        data-cy={'previous-shipment'}
                        onClick={() => previousShipment && navigate(previousShipment)}
                      >
                        <ChevronLeft />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Seuraava toimitus">
                    <span>
                      <IconButton
                        disabled={!nextShipment}
                        id="next-shipment-button"
                        data-cy={'next-shipment'}
                        onClick={() => nextShipment && navigate(nextShipment)}
                      >
                        <ChevronRight />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )}
            </ButtonContainer>
          </Header>
          <Stack direction="row" spacing={1} sx={{ padding: '0.25rem' }}>
            {isPreciseDelivery(
              state.fields.delivery_time_window_start.value,
              state.fields.delivery_time_window_end.value,
            ) ? (
              <Alert className="precise-delivery-alert" severity="warning" sx={{ alignItems: 'center' }}>
                Täsmätoimitus!
              </Alert>
            ) : null}
            {isNumber(state.fields.price.value) && state.fields.price.value < 0 ? (
              <Alert className="negative-price-alert" severity="warning" sx={{ alignItems: 'center' }}>
                Negatiivinen hinta
              </Alert>
            ) : null}
            {state?.originalShipment?.id ? (
              <UpdateInfo updated_by={state.updated_by} updated_at={state.updated_at} />
            ) : null}
          </Stack>
        </HeaderContainer>
        <AllShipmentFields
          dispatch={dispatch}
          state={state}
          currentUser={currentUser}
          parsedShipmentId={parsedShipmentId}
          organization={organization}
          showAllRelatedShipments={showAllRelatedShipments}
          setShowAllRelatedShipments={setShowAllRelatedShipments}
        />
        <NewShipmenRowTable
          shipmentRows={state.rows}
          setShipmentRows={(rows) => updateRows(rows, dispatch)}
          setAreShipmentRowsBeingEdited={setAreShipmentRowsBeingEdited}
          currentUser={currentUser}
          isEditShipment={true}
          //same access as pickup_name
          canEdit={state.fields.pickup_name.access === 'editable'}
        />
      </StyledForm>
    </Main>
  );
};

export default EditShipment;
