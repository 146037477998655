import React, { Dispatch, useEffect, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import {
  Action,
  CarPricingRowField,
  GeographicalAreaField,
  getInitialState,
  PricingAreaField,
  PricingRowField,
  reducer,
  State,
} from './pricingModel.state';
import {
  api,
  CarPricingRowCarTypeEnum,
  GeographicalAreaPutBody,
  GeographicalAreaPutBodyAreaTypeEnum,
  PricingArea,
  PricingModelWithAreas,
  PricingModelWithAreasDistancePricingBasisEnum,
  PricingModelWithAreasPricingUnitEnum,
  PricingModelWithAreasPutBody,
  PricingModelWithAreasPutBodyDistancePricingBasisEnum,
  PricingModelWithAreasPutBodyPricingUnitEnum,
} from '../../api';
import { useParams } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AllPricingModelFields, PricingAndGeographicalAreaSection } from './components/inputs';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import PricingTable from './components/PricingTable';
import CarPricingTable from './components/CarPricingTable';
import { StyledForm } from '../../components/StyledComponents/StyledForm';

const load = async (pricingModelId: PricingModelWithAreas['id'], dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [pricingModelResponse] = await Promise.all([api.pricing.getPricingModel({ pricingModelId: pricingModelId })]);
    dispatch({
      type: 'INITIALIZE_PRICING_MODEL',
      payload: {
        pricingModel: pricingModelResponse.data,
      },
    });
    dispatch({
      type: 'VALIDATE_FIELDS',
    });
  } catch (err) {
    let message = 'Hinnoittelumallin lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Hinnoittelumallia ei löytynyt!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const getGeographicalAreas = (
  pricingAreaId: PricingArea['id'],
  pricingAreaFields: PricingAreaField[],
  geographicalAreaFields: GeographicalAreaField[],
) => {
  const pricingAreaGeographicalAreaFields: GeographicalAreaField[] = geographicalAreaFields.filter(
    (geographicalAreaField) => geographicalAreaField.geographicalArea.pricing_area === pricingAreaId,
  );
  const updatedAreaType = pricingAreaFields.find(
    (pricingAreaField) => pricingAreaField.pricingArea.id === pricingAreaId,
  )?.areaType;
  const updatedGeographicalAreas = pricingAreaGeographicalAreaFields.map((geographicalAreaField) => {
    let updatedGeographicalArea: GeographicalAreaPutBody = {
      ...geographicalAreaField.geographicalArea,
      area_type: updatedAreaType as unknown as GeographicalAreaPutBodyAreaTypeEnum,
      name: geographicalAreaField.value,
    };
    if (pricingAreaFields.find((pricingAreaField) => pricingAreaField.pricingArea.id === pricingAreaId)?.isNew) {
      const { pricing_area, ...updatedGeographicalAreaWithoutId } = updatedGeographicalArea;
      updatedGeographicalArea = updatedGeographicalAreaWithoutId;
    }
    if (geographicalAreaField.isNew) {
      const { id, ...updatedGeographicalAreaWithoutId } = updatedGeographicalArea;
      updatedGeographicalArea = updatedGeographicalAreaWithoutId;
    }
    return updatedGeographicalArea;
  });
  return updatedGeographicalAreas;
};

const getUpdatedPricingAreas = (
  pricingAreaFields: PricingAreaField[],
  geographicalAreaFields: GeographicalAreaField[],
) => {
  const updatedPricingAreas = pricingAreaFields.map((pricingArea) => {
    const updatedPricingArea = {
      id: pricingArea.pricingArea.id,
      name: pricingArea.value,
      pricing_model: pricingArea.pricingArea.pricing_model,
      geographical_areas: getGeographicalAreas(pricingArea.pricingArea.id, pricingAreaFields, geographicalAreaFields),
    };
    if (pricingArea.isNew) {
      const { id, ...updatedGeographicalAreaWithoutId } = updatedPricingArea;
      return updatedGeographicalAreaWithoutId;
    } else {
      return updatedPricingArea;
    }
  });
  return updatedPricingAreas;
};

const getUpdatedPricingRows = (pricingRowFields: PricingRowField[]) => {
  const updatedPricingRows = pricingRowFields.map((pricingRowField) => {
    const updatedPricingRow = {
      ...pricingRowField.pricingRow,
      price: pricingRowField.value ? Number(pricingRowField.value) : pricingRowField.pricingRow.price,
      distance_km: pricingRowField.pricingDistanceKm
        ? Number(pricingRowField.pricingDistanceKm)
        : pricingRowField.pricingRow.distance_km,
      weight_kg: pricingRowField.pricingWeightKg ?? 0,
    };
    return updatedPricingRow;
  });
  return updatedPricingRows;
};

export const getUpdatedCarPricingRows = (carPricingRowFields: CarPricingRowField[]) => {
  const updatedCarPricingRows: any[] = [];
  carPricingRowFields.forEach((carPricingRowField) => {
    const updatedCarPricingRow = {
      ...carPricingRowField.carPricingRow,
      car_type: carPricingRowField.carPricingRow.car_type as unknown as CarPricingRowCarTypeEnum,
      price: carPricingRowField.value,
    };
    if (carPricingRowField.isNew) {
      if (carPricingRowField.value !== '') {
        const { id, ...updatedCarPricingRowWithoutId } = updatedCarPricingRow;
        updatedCarPricingRows.push(updatedCarPricingRowWithoutId);
      } else {
        return;
      }
    } else {
      return updatedCarPricingRows.push(updatedCarPricingRow);
    }
  });
  return updatedCarPricingRows;
};

const getPricingModelWithAreasPutBodyFromState = (state: State): PricingModelWithAreasPutBody => {
  return {
    id: state.fields.id.value || 0,
    name: state.fields.name.value,
    hourly_price: state.fields.hourly_price.value || 0,
    hourly_price_of_combined_vehicle: state.fields.hourly_price_of_combined_vehicle.value || 0,
    express_delivery_price: state.fields.express_delivery_price.value || 0,
    default_pricing_area: state.fields.default_pricing_area.value || null,
    default_custom_pricing_category: state.fields.default_custom_pricing_category.value || null,
    pricing_unit: state.fields.pricing_unit.value as unknown as PricingModelWithAreasPutBodyPricingUnitEnum,
    distance_pricing_basis: state.fields.distance_pricing_basis
      .value as unknown as PricingModelWithAreasPutBodyDistancePricingBasisEnum,
    legacy_combined_pricing_for_same_day_deliveries_in_same_address:
      state.fields.legacy_combined_pricing_for_same_day_deliveries_in_same_address.value || false,
    legacy_chargeable_weight_flooring: state.fields.legacy_chargeable_weight_flooring.value || false,
    legacy_ignore_basic_price_if_hours_exist_and_not_exception:
      state.fields.legacy_ignore_basic_price_if_hours_exist_and_not_exception.value || false,
    legacy_ignore_working_hours: state.fields.legacy_ignore_working_hours.value || false,
    pricing_areas: getUpdatedPricingAreas(state.pricingAreaFields, state.geographicalAreaFields),
    pricing_rows: getUpdatedPricingRows(state.pricingRowFields),
    car_pricing_rows: getUpdatedCarPricingRows(state.carPricingRowFields),
  };
};

const saveNewPricingModelWithAreas = async (state: State, dispatch: Dispatch<Action>) => {
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });

  try {
    if (!state.originalPricingModel || !state.originalPricingModel.id) {
      return;
    }
    await api.pricing.updatePricingModel({
      pricingModelId: state.originalPricingModel.id,
      pricingModelWithAreasPutBody: getPricingModelWithAreasPutBodyFromState(state),
    });
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Hinnoittelumalli tallennettu!' } });
    load(state.originalPricingModel.id, dispatch);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

type EditPricingModelParams = {
  pricingModelId?: string;
};

const EditPricingModel: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { pricingModelId } = useParams<EditPricingModelParams>();
  const parsedPricingModelId = parseInt(pricingModelId || '');

  useEffect(() => {
    load(parsedPricingModelId, dispatch);
  }, [parsedPricingModelId]);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title={`Hinnoittelumalli ${state.originalPricingModel?.name || ''}`}>
            <SaveButton
              disabled={!state.isValid || state.isLoading}
              id="save-pricing-model-button"
              tooltip={!state.isValid ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (state.originalPricingModel) {
                  saveNewPricingModelWithAreas(state, dispatch);
                  event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
                }
              }}
            >
              Tallenna
            </SaveButton>
          </Header>
        </HeaderContainer>
        <AllPricingModelFields state={state} dispatch={dispatch} showId={true} />
      </StyledForm>
      {state.pricingRowFields.length > 0 ? (
        <PricingTable state={state} pricingRows={state.pricingRowFields.map((x) => x.pricingRow)} dispatch={dispatch} />
      ) : null}
      {state.carPricingRowFields.length > 0 &&
      state.fields.pricing_unit.value === PricingModelWithAreasPricingUnitEnum.WorkingHours ? (
        <CarPricingTable state={state} dispatch={dispatch} />
      ) : null}
      {state.fields.distance_pricing_basis.value === PricingModelWithAreasDistancePricingBasisEnum.FixedArea ? (
        <PricingAndGeographicalAreaSection state={state} dispatch={dispatch} disabled={false} />
      ) : null}
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default EditPricingModel;
