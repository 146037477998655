/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of time offs in legacy format.
 * @export
 * @interface TimeOffLegacy
 */
export interface TimeOffLegacy {
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  driver_id: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  year: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d1: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d2: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d3: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d4: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d5: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d6: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d7: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d8: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d9: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d10: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d11: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d12: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d13: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d14: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d15: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d16: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d17: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d18: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d19: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d20: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d21: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d22: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d23: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d24: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d25: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d26: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d27: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d28: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d29: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d30: number;
  /**
   *
   * @type {number}
   * @memberof TimeOffLegacy
   */
  d31: number;
}

export function TimeOffLegacyFromJSON(json: any): TimeOffLegacy {
  return TimeOffLegacyFromJSONTyped(json, false);
}

export function TimeOffLegacyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeOffLegacy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    driver_id: json['driver_id'],
    year: json['year'],
    month: json['month'],
    d1: json['d1'],
    d2: json['d2'],
    d3: json['d3'],
    d4: json['d4'],
    d5: json['d5'],
    d6: json['d6'],
    d7: json['d7'],
    d8: json['d8'],
    d9: json['d9'],
    d10: json['d10'],
    d11: json['d11'],
    d12: json['d12'],
    d13: json['d13'],
    d14: json['d14'],
    d15: json['d15'],
    d16: json['d16'],
    d17: json['d17'],
    d18: json['d18'],
    d19: json['d19'],
    d20: json['d20'],
    d21: json['d21'],
    d22: json['d22'],
    d23: json['d23'],
    d24: json['d24'],
    d25: json['d25'],
    d26: json['d26'],
    d27: json['d27'],
    d28: json['d28'],
    d29: json['d29'],
    d30: json['d30'],
    d31: json['d31'],
  };
}

export function TimeOffLegacyToJSON(value?: TimeOffLegacy | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    driver_id: value.driver_id,
    year: value.year,
    month: value.month,
    d1: value.d1,
    d2: value.d2,
    d3: value.d3,
    d4: value.d4,
    d5: value.d5,
    d6: value.d6,
    d7: value.d7,
    d8: value.d8,
    d9: value.d9,
    d10: value.d10,
    d11: value.d11,
    d12: value.d12,
    d13: value.d13,
    d14: value.d14,
    d15: value.d15,
    d16: value.d16,
    d17: value.d17,
    d18: value.d18,
    d19: value.d19,
    d20: value.d20,
    d21: value.d21,
    d22: value.d22,
    d23: value.d23,
    d24: value.d24,
    d25: value.d25,
    d26: value.d26,
    d27: value.d27,
    d28: value.d28,
    d29: value.d29,
    d30: value.d30,
    d31: value.d31,
  };
}
