/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  OrganizationPricingModelWithAreas,
  OrganizationPricingModelWithAreasFromJSON,
  OrganizationPricingModelWithAreasFromJSONTyped,
  OrganizationPricingModelWithAreasToJSON,
} from './OrganizationPricingModelWithAreas';

/**
 *
 * @export
 * @interface OrganizationPricingModelsResponseBody
 */
export interface OrganizationPricingModelsResponseBody {
  /**
   *
   * @type {Array<OrganizationPricingModelWithAreas>}
   * @memberof OrganizationPricingModelsResponseBody
   */
  data: Array<OrganizationPricingModelWithAreas>;
}

export function OrganizationPricingModelsResponseBodyFromJSON(json: any): OrganizationPricingModelsResponseBody {
  return OrganizationPricingModelsResponseBodyFromJSONTyped(json, false);
}

export function OrganizationPricingModelsResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationPricingModelsResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(OrganizationPricingModelWithAreasFromJSON),
  };
}

export function OrganizationPricingModelsResponseBodyToJSON(value?: OrganizationPricingModelsResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(OrganizationPricingModelWithAreasToJSON),
  };
}
