import React, { Dispatch, useEffect, useReducer, useState } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import {
  Action,
  convertToReturnShipment,
  copyShipment,
  copyShipmentRows,
  getInitialState,
  getOrganizationShipmentPostBodyFromState,
  getShipmentPostBodyFromState,
  reducer,
  State,
  updateRows,
} from './shipment.state';
import {
  AdditionalService,
  api,
  Car,
  Driver,
  GetShipmentIncludeEnum,
  Load,
  Office,
  Organization,
  OrganizationCar,
  OrganizationDriver,
  OrganizationPhotosResponseBody,
  OrganizationPricingModelsResponseBody,
  OrganizationShipment,
  OrganizationShipmentPutBody,
  OrganizationShipmentPutBodyStateEnum,
  OrganizationShipmentResponseBody,
  OrganizationShipmentRowsResponseBody,
  OrganizationsResponseBody,
  Photo,
  PhotosResponseBody,
  PricingModelsResponseBody,
  Shipment,
  ShipmentAdditionalService,
  ShipmentPutBody,
  ShipmentResponseBody1,
  ShipmentRow,
  UpdateDistanceAndItsRelatedShipmentsPatchBody,
  User,
} from '../../api';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Notification, { getSnackbarPropsFromState, tryAgainMessage } from '../../components/Notification';
import { Button, Link, styled } from '@mui/material';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { UpdateInfo } from './components/UpdateInfo';
import { downloadWaybill } from '../../components/Waybill';
import { DateTime } from 'luxon';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { previousAndNextShipmentLinks } from './components/ShipmentSetHistory';
import { canAccessCoordination, canAccessCustomerCoordination, ifDateExistGetSameDateAtMidday } from '../../utils';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { ButtonContainer } from '../../components/StyledComponents/ButtonContainer';
import { useCurrentUser, useCurrentUserOrganization } from '../../hooks/useCurrentUser';
import { AllShipmentFields } from './components/allShipmentFields';
import NewShipmenRowTable from './components/NewShipmentRowTable';

export const load = async (
  userOrganizationId: OrganizationShipment['organization_id'],
  shipmentId: OrganizationShipment['id'],
  dispatch: Dispatch<Action>,
  currentUser: User,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let shipmentResponse: OrganizationShipmentResponseBody | ShipmentResponseBody1;
    let shipmentRowsResponse: OrganizationShipmentRowsResponseBody | undefined = undefined;
    let shipmentPhotosResponse: PhotosResponseBody | OrganizationPhotosResponseBody | undefined;
    let organizationsResponse: OrganizationsResponseBody | undefined;
    let pricingModelsResponse: PricingModelsResponseBody | OrganizationPricingModelsResponseBody | undefined;
    let load: Load | undefined = undefined;
    let driver: Driver | OrganizationDriver | undefined = undefined;
    let car: Car | OrganizationCar | undefined = undefined;
    let shipmentOrganization: Organization | undefined = undefined;
    let shipmentRows: ShipmentRow[] = [];
    let shipmentPhotos: Photo[] | undefined = undefined;
    let organizationOffices: Office[] = [];
    let organizationAdditionalServices: AdditionalService[] = [];
    let shipmentAdditionalServices: ShipmentAdditionalService[] = [];
    if (canAccessCoordination(currentUser)) {
      [shipmentResponse, organizationsResponse, pricingModelsResponse] = await Promise.all([
        api.shipments.getShipment({
          shipmentId,
          include: [
            GetShipmentIncludeEnum.Load,
            GetShipmentIncludeEnum.LoadDriver,
            GetShipmentIncludeEnum.LoadCar,
            GetShipmentIncludeEnum.Organization,
            GetShipmentIncludeEnum.Rows,
            GetShipmentIncludeEnum.Photos,
            GetShipmentIncludeEnum.ShipmentAdditionalServices,
            GetShipmentIncludeEnum.OrganizationAdditionalServices,
            GetShipmentIncludeEnum.OrganizationOffices,
          ],
        }),
        api.organizations.getOrganizations({}),
        api.pricing.getPricingModels(),
      ]);
      const response = shipmentResponse as ShipmentResponseBody1;
      shipmentRows = response.related?.rows ?? [];
      shipmentOrganization = response.related?.organization;
      shipmentPhotos = response.related?.photos ?? [];
      organizationOffices = response.related?.offices ?? [];
      organizationAdditionalServices = response.related?.additional_services ?? [];
      shipmentAdditionalServices = response.related?.shipment_additional_services ?? [];
      if (shipmentResponse.data.load_id) {
        load = response.related?.load;
        driver = response.related?.driver ?? undefined;
        car = response.related?.car ?? undefined;
      }
    } else {
      [shipmentResponse, shipmentRowsResponse, shipmentPhotosResponse] = await Promise.all([
        api.organizationShipments.getOrganizationShipment({
          organizationId: userOrganizationId,
          shipmentId,
        }),
        api.organizationShipments.getOrganizationShipmentRows({
          organizationId: userOrganizationId,
          shipmentId,
        }),
        api.organizationShipments.getOrganizationShipmentPhotos({
          organizationId: userOrganizationId,
          shipmentId,
        }),
      ]);
      if (shipmentResponse.data.load_id && canAccessCustomerCoordination(currentUser)) {
        load = (
          await api.organizationLoads.getOrganizationLoad({
            organizationId: userOrganizationId,
            loadId: shipmentResponse.data.load_id,
          })
        ).data;
        if (load.drive_date) {
          const startOfDriveDate = DateTime.fromJSDate(load.drive_date).startOf('day').toJSDate();
          const endOfDriveDate = DateTime.fromJSDate(load.drive_date).endOf('day').toJSDate();
          if (load.driver_id) {
            driver = (
              await api.organizationDrivers.getOrganizationDrivers({
                organizationId: userOrganizationId,
                loadIds: [load.id],
                driveDateRangeStartsAt: startOfDriveDate,
                driveDateRangeEndsAt: endOfDriveDate,
              })
            ).data[0];
          }
          if (load.car_id) {
            car = (
              await api.organizationCars.getOrganizationCars({
                organizationId: userOrganizationId,
                loadIds: [load.id],
                driveDateRangeStartsAt: startOfDriveDate,
                driveDateRangeEndsAt: endOfDriveDate,
              })
            ).data[0];
          }
        }
      }
      shipmentOrganization = (
        await api.organizations.getOrganization({ organizationId: shipmentResponse.data.organization_id })
      ).data;
      const [organizationOfficesResponse, organizationAdditionalServicesResponse, shipmentAdditionalServicesResponse] =
        await Promise.all([
          api.organizationOffices.getOrganizationOffices({ organizationId: shipmentOrganization.id }),
          api.organizationAdditionalServices.getOrganizationAdditionalServices({
            organizationId: shipmentOrganization.id,
          }),
          api.organizationShipments.getOrganizationShipmentAdditionalServices({
            organizationId: shipmentOrganization.id,
            shipmentId: shipmentResponse.data.id,
          }),
        ]);
      organizationOffices = organizationOfficesResponse.data;
      organizationAdditionalServices = organizationAdditionalServicesResponse.data;
      shipmentAdditionalServices = shipmentAdditionalServicesResponse.data ?? [];
      pricingModelsResponse = await api.organizationPricing.getOrganizationPricingModels({
        organizationId: shipmentOrganization.id,
      });
    }
    const organization = shipmentOrganization as Organization;
    dispatch({
      type: 'INITIALIZE_SHIPMENT',
      payload: {
        shipment: shipmentResponse.data,
        shipmentPhotos: shipmentPhotos ?? shipmentPhotosResponse?.data ?? [],
        shipmentRows: shipmentRowsResponse?.data ?? shipmentRows,
        load,
        driver,
        car,
        currentUser: currentUser,
        organization: organization,
        organizationOffices,
        organizationAdditionalServices,
        shipmentAdditionalServices: shipmentAdditionalServices,
        organizations: organizationsResponse?.data ?? [organization],
        pricingModels: pricingModelsResponse?.data ?? [],
      },
    });
    dispatch({
      type: 'VALIDATE_FIELDS',
    });
  } catch (err) {
    let message = 'Toimituksen lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Toimitusta ei löytynyt!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const getRelatedShipments = async (
  shipmentId: OrganizationShipment['id'],
  shipmentOrganizationId: OrganizationShipment['organization_id'],
  shipmentReferenceNumber: OrganizationShipment['reference_number'],
  showAllRelatedShipments: boolean,
  dispatch: Dispatch<Action>,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_IS_RELATED_SHIPMENTS_LOADING',
      payload: {
        isRelatedShipmentsLoading: true,
      },
    });
    let relatedShipmentsResponse: Shipment[] | undefined = undefined;
    if (
      shipmentReferenceNumber &&
      !shipmentReferenceNumber.includes('SIS SIIRTO') &&
      !shipmentReferenceNumber.includes('KURIIRI')
    ) {
      const formattedReferenceNumber = shipmentReferenceNumber.match(/[^-/.]*/) ?? [];
      relatedShipmentsResponse = showAllRelatedShipments
        ? (
            await api.search.searchOrganizationShipments({
              searchOrganizationShipmentsPostBody: {
                reference_number: formattedReferenceNumber[0] ? formattedReferenceNumber[0] : shipmentReferenceNumber,
              },
              organizationId: shipmentOrganizationId,
            })
          ).data
        : (
            await api.search.searchOrganizationShipments({
              searchOrganizationShipmentsPostBody: {
                reference_number: formattedReferenceNumber[0] ? formattedReferenceNumber[0] : shipmentReferenceNumber,
                agreed_delivery_window_date_range_starts_at: DateTime.local()
                  .startOf('day')
                  .minus({ days: 30 })
                  .toJSDate(),
              },
              organizationId: shipmentOrganizationId,
            })
          ).data;
    }
    dispatch({
      type: 'SET_RELATED_SHIPMENTS',
      payload: {
        relatedShipments: relatedShipmentsResponse?.filter((shipment) => shipment.id !== shipmentId),
      },
    });
  } catch (err) {
    dispatch({
      type: 'SET_MESSAGE',
      payload: { message: 'Saman viitenumeron toimitusten lataus epäonnistui', severity: 'warning' },
    });
    console.error(err);
  }
  dispatch({
    type: 'SET_IS_RELATED_SHIPMENTS_LOADING',
    payload: {
      isRelatedShipmentsLoading: false,
    },
  });
};

const HeaderRow = styled('div')({
  display: 'flex',
  flexBasis: '100%',
});

const getOrganizationShipmentPutBodyFromState = (state: State): OrganizationShipmentPutBody => {
  const body = getOrganizationShipmentPostBodyFromState(state);
  return {
    ...body,
    state: body.state as unknown as OrganizationShipmentPutBodyStateEnum,
    rows: body.rows as OrganizationShipmentPutBody['rows'],
  };
};

const getShipmentPutBodyFromState = (state: State, currentUser?: User): ShipmentPutBody => {
  return {
    ...getOrganizationShipmentPutBodyFromState(state),
    ...getShipmentPostBodyFromState(state, currentUser),
    ordered_at: state.fields.ordered_at.value || null,
    arrived_to_pickup_location_at: state.fields.arrived_to_pickup_location_at.value || null,
    picked_up_at: state.fields.picked_up_at.value || null,
    arrived_to_delivery_location_at: state.fields.arrived_to_delivery_location_at.value || null,
    delivered_at: state.fields.delivered_at.value || null,
    billed_at: ifDateExistGetSameDateAtMidday(state.fields.billed_at.value),
  };
};

const getUpdateDistanceAndItsRelatedShipmentsPutBodyFromState = (
  state: State,
): UpdateDistanceAndItsRelatedShipmentsPatchBody => {
  return {
    legacy_etaisyys_field: state.fields.legacy_etaisyys_field.value || null,
    address1: state.fields.pickup_address.value || '',
    city1: state.fields.pickup_city.value || '',
    address2: state.fields.delivery_address.value || '',
    city2: state.fields.delivery_city.value || '',
  };
};

const saveShipment = async (
  shipmentId: Shipment['id'],
  state: State,
  dispatch: Dispatch<Action>,
  currentUser?: User,
) => {
  dispatch({ type: 'SET_LOADING', payload: true });
  if (!currentUser?.organization_id) {
    throw new Error('Missing org id');
  }
  if (!state.canSave && !canAccessCoordination(currentUser)) {
    throw new Error('Prerequisities not met');
  }
  try {
    if (canAccessCoordination(currentUser)) {
      await api.shipments.updateShipment({
        shipmentId,
        shipmentPutBody: getShipmentPutBodyFromState(state, currentUser),
      });
      if (
        state.fields.has_distance_been_fixed.value &&
        state.fields.legacy_etaisyys_field.value &&
        state.fields.legacy_etaisyys_field.value !== state.originalShipment?.legacy_etaisyys_field
      ) {
        await api.distances.updateDistanceAndItsRelatedShipments({
          updateDistanceAndItsRelatedShipmentsPatchBody: getUpdateDistanceAndItsRelatedShipmentsPutBodyFromState(state),
        });
      }
    } else {
      await api.organizationShipments.updateOrganizationShipment({
        organizationId: state.fields.organization_id.value,
        shipmentId,
        organizationShipmentPutBody: getOrganizationShipmentPutBodyFromState(state),
      });
    }
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Toimitus tallennettu!' } });
    load(state.fields.organization_id.value, shipmentId, dispatch, currentUser);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
  }
  dispatch({ type: 'SET_LOADING', payload: false });
};

type EditShipmentParams = {
  shipmentId?: string;
};

const EditShipment: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const organization = useCurrentUserOrganization();
  const [state, dispatch] = useReducer(reducer, getInitialState(currentUser));
  const { shipmentId } = useParams<EditShipmentParams>();
  const parsedShipmentId = parseInt(shipmentId || '');
  const [showAllRelatedShipments, setShowAllRelatedShipments] = React.useState(false);
  const [previousShipment, nextShipment] = React.useMemo(previousAndNextShipmentLinks, [shipmentId]);
  const [areShipmentRowsBeingEdited, setAreShipmentRowsBeingEdited] = useState(false);

  useEffect(() => {
    if (currentUser?.organization_id) {
      dispatch({ type: 'CLEAR_SHIPMENT', payload: { currentUser } });
      load(currentUser.organization_id, parsedShipmentId, dispatch, currentUser);
    }
  }, [currentUser, parsedShipmentId]);

  useEffect(() => {
    if (currentUser && state.originalShipment) {
      getRelatedShipments(
        state.originalShipment.id,
        state.originalShipment.organization_id,
        state.originalShipment.reference_number,
        showAllRelatedShipments,
        dispatch,
      );
    }
  }, [state.originalShipment?.reference_number, showAllRelatedShipments]);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title={`Toimitus ${parsedShipmentId}`}>
            <ButtonContainer>
              <SaveButton
                disabled={state.isLoading || !state.canSave || areShipmentRowsBeingEdited}
                color={!state.isValid || !state.canEdit ? 'warning' : 'success'}
                id="save-shipment-button"
                tooltip={
                  !state.canSave && state.originalShipment?.billed_at
                    ? 'Laskutettua toimitusta ei voi muokata'
                    : !state.canEdit
                      ? 'Perutun tai jo kuormaan lisätyn toimituksen kaikkia kenttiä ei voi muokata'
                      : !state.isValid
                        ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä'
                        : ''
                }
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  saveShipment(parsedShipmentId, state, dispatch, currentUser);
                  event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
                }}
              >
                Tallenna toimitus
              </SaveButton>
              <Button
                disabled={state.isLoading}
                id="copy-shipment-button"
                onClick={() => {
                  if (!state.originalShipment || !organization) {
                    throw new Error('Prerequisities not met');
                  }
                  navigate('/shipments/new', {
                    state: {
                      shipment: copyShipment(state.originalShipment),
                      shipmentRows: copyShipmentRows(state.rows),
                      additionalServiceFields: state.additionalServiceFields,
                    },
                  });
                }}
              >
                Luo kopio
              </Button>
              <Button
                disabled={state.isLoading}
                id="create-return-shipment-button"
                onClick={() => {
                  if (!state.originalShipment || !organization) {
                    throw new Error('Prerequisities not met');
                  }
                  navigate('/shipments/new', {
                    state: {
                      shipment: convertToReturnShipment(state.originalShipment),
                      shipmentRows: copyShipmentRows(state.rows),
                      additionalServiceFields: state.additionalServiceFields,
                    },
                  });
                }}
              >
                Luo paluutoimitus
              </Button>
              <Button
                disabled={state.isLoading}
                id="create-waybill-button"
                onClick={() => {
                  if (state.originalShipment) {
                    downloadWaybill(state.originalShipment, state.rows, state.driver);
                  }
                }}
              >
                Luo rahtikirja
              </Button>
              {/* TODO This is commented out, since we are not ready to use it */}
              {/* <Button
                disabled={state.isLoading}
                id="create-shipment-row-stickers-button"
                onClick={() => {
                  if (state.originalShipment && state.rows) {
                    downloadShipmentRowStickers(state.originalShipment, state.rows);
                  }
                }}
              >
                Luo kollitarrat
              </Button> */}
            </ButtonContainer>
            {(nextShipment || previousShipment) && (
              <HeaderRow>
                {previousShipment && (
                  <Link
                    id="previous-shipment-button"
                    data-cy={'previous-shipment'}
                    component={RouterLink}
                    to={previousShipment}
                  >
                    Edellinen toimitus
                  </Link>
                )}
                {nextShipment && (
                  <Link
                    style={{ marginLeft: 'auto' }}
                    id="next-shipment-button"
                    data-cy={'next-shipment'}
                    component={RouterLink}
                    to={nextShipment}
                  >
                    Seuraava toimitus
                  </Link>
                )}
              </HeaderRow>
            )}
          </Header>
          {state?.originalShipment?.id ? (
            <UpdateInfo updated_by={state.updated_by} updated_at={state.updated_at} />
          ) : null}
        </HeaderContainer>
        <AllShipmentFields
          dispatch={dispatch}
          state={state}
          currentUser={currentUser}
          parsedShipmentId={parsedShipmentId}
          organization={organization}
          showAllRelatedShipments={showAllRelatedShipments}
          setShowAllRelatedShipments={setShowAllRelatedShipments}
        />
        <NewShipmenRowTable
          shipmentRows={state.rows}
          setShipmentRows={(rows) => updateRows(rows, dispatch)}
          setAreShipmentRowsBeingEdited={setAreShipmentRowsBeingEdited}
          currentUser={currentUser}
        />
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default EditShipment;
