/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  OrganizationPostBody,
  OrganizationPostBodyFromJSON,
  OrganizationPostBodyToJSON,
  OrganizationPutBody,
  OrganizationPutBodyFromJSON,
  OrganizationPutBodyToJSON,
  OrganizationResponseBody,
  OrganizationResponseBodyFromJSON,
  OrganizationResponseBodyToJSON,
  OrganizationsResponseBody,
  OrganizationsResponseBodyFromJSON,
  OrganizationsResponseBodyToJSON,
} from '../models';

export interface OrganizationsApiCreateOrganizationRequest {
  organizationPostBody: OrganizationPostBody;
}

export interface OrganizationsApiDeleteOrganizationRequest {
  organizationId: string;
}

export interface OrganizationsApiGetOrganizationRequest {
  organizationId: string;
}

export interface OrganizationsApiGetOrganizationsRequest {
  alsoInactive?: boolean;
}

export interface OrganizationsApiUpdateOrganizationRequest {
  organizationId: string;
  organizationPutBody: OrganizationPutBody;
}

/**
 *
 */
export class OrganizationsApi extends runtime.BaseAPI {
  /**
   * Create an organization.
   */
  async createOrganizationRaw(
    requestParameters: OrganizationsApiCreateOrganizationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationResponseBody>> {
    if (requestParameters.organizationPostBody === null || requestParameters.organizationPostBody === undefined) {
      throw new runtime.RequiredError(
        'organizationPostBody',
        'Required parameter requestParameters.organizationPostBody was null or undefined when calling createOrganization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationPostBodyToJSON(requestParameters.organizationPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create an organization.
   */
  async createOrganization(
    requestParameters: OrganizationsApiCreateOrganizationRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationResponseBody> {
    const response = await this.createOrganizationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete an organization by id.
   */
  async deleteOrganizationRaw(
    requestParameters: OrganizationsApiDeleteOrganizationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteOrganization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete an organization by id.
   */
  async deleteOrganization(
    requestParameters: OrganizationsApiDeleteOrganizationRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteOrganizationRaw(requestParameters, initOverrides);
  }

  /**
   * Get an organization by id.
   */
  async getOrganizationRaw(
    requestParameters: OrganizationsApiGetOrganizationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get an organization by id.
   */
  async getOrganization(
    requestParameters: OrganizationsApiGetOrganizationRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationResponseBody> {
    const response = await this.getOrganizationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all organizations.
   */
  async getOrganizationsRaw(
    requestParameters: OrganizationsApiGetOrganizationsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationsResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.alsoInactive !== undefined) {
      queryParameters['also_inactive'] = requestParameters.alsoInactive;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all organizations.
   */
  async getOrganizations(
    requestParameters: OrganizationsApiGetOrganizationsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationsResponseBody> {
    const response = await this.getOrganizationsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update an organization by id.
   */
  async updateOrganizationRaw(
    requestParameters: OrganizationsApiUpdateOrganizationRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateOrganization.',
      );
    }

    if (requestParameters.organizationPutBody === null || requestParameters.organizationPutBody === undefined) {
      throw new runtime.RequiredError(
        'organizationPutBody',
        'Required parameter requestParameters.organizationPutBody was null or undefined when calling updateOrganization.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationPutBodyToJSON(requestParameters.organizationPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseBodyFromJSON(jsonValue));
  }

  /**
   * Update an organization by id.
   */
  async updateOrganization(
    requestParameters: OrganizationsApiUpdateOrganizationRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationResponseBody> {
    const response = await this.updateOrganizationRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
