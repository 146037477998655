import { Dispatch } from 'react';
import { api, Driver, getAllPages } from '../../api';
import { TableState } from 'react-table';
import { updateViewSettings } from '../settings';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';

export interface EmployeesViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
}

export interface State {
  employees: Driver[];
  notification: NotificationType;
  isLoading: boolean;
  viewSettings: EmployeesViewSettings;
}

export const initialState: State = {
  employees: [],
  notification: {
    message: null,
  },
  isLoading: true,
  viewSettings: {
    filters: [],
    sortBy: [],
    hiddenColumns: [],
  },
};

export type Action =
  | { type: 'SET_EMPLOYEES'; payload: Driver[] }
  | SetMessageAction
  | SetLoadingAction
  | { type: 'SET_VIEW_SETTINGS'; payload: EmployeesViewSettings };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_EMPLOYEES':
      return {
        ...state,
        employees: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_VIEW_SETTINGS':
      updateViewSettings('employees', action.payload);
      return {
        ...state,
        viewSettings: action.payload,
      };
  }
};

export const load = async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [employeesResponse] = await Promise.all([
      getAllPages(api.drivers.getDrivers.bind(api.drivers), { alsoInactive: true }),
    ]);
    dispatch({
      type: 'SET_EMPLOYEES',
      payload: employeesResponse,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa työntekijöitä',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};
