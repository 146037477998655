/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AviShipmentResponseBody,
  AviShipmentResponseBodyFromJSON,
  AviShipmentResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

export interface AviApiGetShipmentByAviUrlRequest {
  aviUrl: string;
}

/**
 *
 */
export class AviApi extends runtime.BaseAPI {
  /**
   * Get a shipment by avi url.
   */
  async getShipmentByAviUrlRaw(
    requestParameters: AviApiGetShipmentByAviUrlRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AviShipmentResponseBody>> {
    if (requestParameters.aviUrl === null || requestParameters.aviUrl === undefined) {
      throw new runtime.RequiredError(
        'aviUrl',
        'Required parameter requestParameters.aviUrl was null or undefined when calling getShipmentByAviUrl.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/avi/{avi_url}`.replace(`{${'avi_url'}}`, encodeURIComponent(String(requestParameters.aviUrl))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AviShipmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a shipment by avi url.
   */
  async getShipmentByAviUrl(
    requestParameters: AviApiGetShipmentByAviUrlRequest,
    initOverrides?: RequestInit,
  ): Promise<AviShipmentResponseBody> {
    const response = await this.getShipmentByAviUrlRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
