/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  WorkHourPatchBody,
  WorkHourPatchBodyFromJSON,
  WorkHourPatchBodyToJSON,
  WorkHourPostBody,
  WorkHourPostBodyFromJSON,
  WorkHourPostBodyToJSON,
  WorkHourResponseBody,
  WorkHourResponseBodyFromJSON,
  WorkHourResponseBodyToJSON,
  WorkHourResponseBody1,
  WorkHourResponseBody1FromJSON,
  WorkHourResponseBody1ToJSON,
} from '../models';

export interface WorkHoursApiCreateWorkHourRequest {
  workHourPostBody: WorkHourPostBody;
}

export interface WorkHoursApiDeleteWorkHourRequest {
  workHourId: number;
}

export interface WorkHoursApiPatchWorkHourRequest {
  workHourId: number;
  workHourPatchBody: WorkHourPatchBody;
}

/**
 *
 */
export class WorkHoursApi extends runtime.BaseAPI {
  /**
   * Create new work hour.
   */
  async createWorkHourRaw(
    requestParameters: WorkHoursApiCreateWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<WorkHourResponseBody>> {
    if (requestParameters.workHourPostBody === null || requestParameters.workHourPostBody === undefined) {
      throw new runtime.RequiredError(
        'workHourPostBody',
        'Required parameter requestParameters.workHourPostBody was null or undefined when calling createWorkHour.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/work_hours`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: WorkHourPostBodyToJSON(requestParameters.workHourPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => WorkHourResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create new work hour.
   */
  async createWorkHour(
    requestParameters: WorkHoursApiCreateWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<WorkHourResponseBody> {
    const response = await this.createWorkHourRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a work hour by id.
   */
  async deleteWorkHourRaw(
    requestParameters: WorkHoursApiDeleteWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.workHourId === null || requestParameters.workHourId === undefined) {
      throw new runtime.RequiredError(
        'workHourId',
        'Required parameter requestParameters.workHourId was null or undefined when calling deleteWorkHour.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/work_hours/{work_hour_id}`.replace(
          `{${'work_hour_id'}}`,
          encodeURIComponent(String(requestParameters.workHourId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a work hour by id.
   */
  async deleteWorkHour(
    requestParameters: WorkHoursApiDeleteWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteWorkHourRaw(requestParameters, initOverrides);
  }

  /**
   * Patch a work hour.
   */
  async patchWorkHourRaw(
    requestParameters: WorkHoursApiPatchWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<WorkHourResponseBody1>> {
    if (requestParameters.workHourId === null || requestParameters.workHourId === undefined) {
      throw new runtime.RequiredError(
        'workHourId',
        'Required parameter requestParameters.workHourId was null or undefined when calling patchWorkHour.',
      );
    }

    if (requestParameters.workHourPatchBody === null || requestParameters.workHourPatchBody === undefined) {
      throw new runtime.RequiredError(
        'workHourPatchBody',
        'Required parameter requestParameters.workHourPatchBody was null or undefined when calling patchWorkHour.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/work_hours/{work_hour_id}`.replace(
          `{${'work_hour_id'}}`,
          encodeURIComponent(String(requestParameters.workHourId)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: WorkHourPatchBodyToJSON(requestParameters.workHourPatchBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => WorkHourResponseBody1FromJSON(jsonValue));
  }

  /**
   * Patch a work hour.
   */
  async patchWorkHour(
    requestParameters: WorkHoursApiPatchWorkHourRequest,
    initOverrides?: RequestInit,
  ): Promise<WorkHourResponseBody1> {
    const response = await this.patchWorkHourRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
