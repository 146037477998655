/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A product row in a ShipmentRow
 * @export
 * @interface ShipmentRowProduct
 */
export interface ShipmentRowProduct {
  /**
   *
   * @type {number}
   * @memberof ShipmentRowProduct
   */
  readonly id?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowProduct
   */
  readonly shipment_id?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowProduct
   */
  readonly row_number?: number;
  /**
   *
   * @type {string}
   * @memberof ShipmentRowProduct
   */
  parcel_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentRowProduct
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowProduct
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowProduct
   */
  weight_per_piece_kg?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowProduct
   */
  total_weight_kg?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentRowProduct
   */
  volume_m3?: number;
}

export function ShipmentRowProductFromJSON(json: any): ShipmentRowProduct {
  return ShipmentRowProductFromJSONTyped(json, false);
}

export function ShipmentRowProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentRowProduct {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    shipment_id: !exists(json, 'shipment_id') ? undefined : json['shipment_id'],
    row_number: !exists(json, 'row_number') ? undefined : json['row_number'],
    parcel_id: !exists(json, 'parcel_id') ? undefined : json['parcel_id'],
    name: json['name'],
    quantity: json['quantity'],
    weight_per_piece_kg: !exists(json, 'weight_per_piece_kg') ? undefined : json['weight_per_piece_kg'],
    total_weight_kg: !exists(json, 'total_weight_kg') ? undefined : json['total_weight_kg'],
    volume_m3: !exists(json, 'volume_m3') ? undefined : json['volume_m3'],
  };
}

export function ShipmentRowProductToJSON(value?: ShipmentRowProduct | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    parcel_id: value.parcel_id,
    name: value.name,
    quantity: value.quantity,
    weight_per_piece_kg: value.weight_per_piece_kg,
    total_weight_kg: value.total_weight_kg,
    volume_m3: value.volume_m3,
  };
}
