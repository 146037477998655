/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  FutureWorkHour,
  FutureWorkHourFromJSON,
  FutureWorkHourFromJSONTyped,
  FutureWorkHourToJSON,
} from './FutureWorkHour';

/**
 *
 * @export
 * @interface FutureWorkHourPostResponseBody
 */
export interface FutureWorkHourPostResponseBody {
  /**
   *
   * @type {FutureWorkHour}
   * @memberof FutureWorkHourPostResponseBody
   */
  data: FutureWorkHour;
}

export function FutureWorkHourPostResponseBodyFromJSON(json: any): FutureWorkHourPostResponseBody {
  return FutureWorkHourPostResponseBodyFromJSONTyped(json, false);
}

export function FutureWorkHourPostResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FutureWorkHourPostResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: FutureWorkHourFromJSON(json['data']),
  };
}

export function FutureWorkHourPostResponseBodyToJSON(value?: FutureWorkHourPostResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: FutureWorkHourToJSON(value.data),
  };
}
