import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@mui/icons-material';
import React, { forwardRef } from 'react';
import { Add, Delete } from './icons';
import { Card, Paper } from '@mui/material';
import { MaterialTableProps, Options } from '@material-table/core';

export const materialTableIcons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <Add {...props} ref={ref} id="material-table-add-button" />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export const materialTableLocalization = {
  header: {
    actions: '',
  },
  toolbar: {
    saveAllChanges: 'Tallenna kaikki muutokset',
    searchPlaceholder: 'Haku',
    searchTooltip: 'Haku',
    addRemoveColumns: 'Lisää',
    nRowsSelected: '{0} valittuna',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} / {count}',
    labelRows: 'riviä',
    labelRowsPerPage: 'riviä per sivu:',
    firstAriaLabel: 'Ensimmäinen sivu',
    firstTooltip: 'Ensimmäinen sivu',
    previousAriaLabel: 'Edellinen sivu',
    previousTooltip: 'Edellinen sivu',
    nextAriaLabel: 'Seuraava sivu',
    nextTooltip: 'Seuraava sivu',
    lastAriaLabel: 'Viimeinen sivu',
    lastTooltip: 'Viimeinen sivu',
  },
};

export const materialTableOptions: Options<any> = {
  rowStyle: {
    fontSize: '0.9rem',
  },
  searchFieldStyle: {
    marginTop: '0.5rem',
  },
};

export const materialTableComponents = {
  Container: (props: MaterialTableProps<any>['components']) => (
    <Paper
      {...props}
      component={Card}
      elevation={0}
      sx={{
        '.MuiToolbar-root': {
          fontWeight: 'bold',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          minHeight: 'fit-content',
        },
      }}
    />
  ),
};

interface MaterialTableHeaderProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
}

export const MaterialTableHeader: React.FC<MaterialTableHeaderProps> = ({ title }) => {
  return <span style={{ fontSize: '0.95rem', fontWeight: 'bold' }}>{title}</span>;
};
