/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateDistanceAndItsRelatedShipmentsPatchBody
 */
export interface UpdateDistanceAndItsRelatedShipmentsPatchBody {
  /**
   *
   * @type {number}
   * @memberof UpdateDistanceAndItsRelatedShipmentsPatchBody
   */
  legacy_etaisyys_field: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateDistanceAndItsRelatedShipmentsPatchBody
   */
  address1: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDistanceAndItsRelatedShipmentsPatchBody
   */
  city1: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDistanceAndItsRelatedShipmentsPatchBody
   */
  address2: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDistanceAndItsRelatedShipmentsPatchBody
   */
  city2: string;
}

export function UpdateDistanceAndItsRelatedShipmentsPatchBodyFromJSON(
  json: any,
): UpdateDistanceAndItsRelatedShipmentsPatchBody {
  return UpdateDistanceAndItsRelatedShipmentsPatchBodyFromJSONTyped(json, false);
}

export function UpdateDistanceAndItsRelatedShipmentsPatchBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateDistanceAndItsRelatedShipmentsPatchBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    legacy_etaisyys_field: json['legacy_etaisyys_field'],
    address1: json['address1'],
    city1: json['city1'],
    address2: json['address2'],
    city2: json['city2'],
  };
}

export function UpdateDistanceAndItsRelatedShipmentsPatchBodyToJSON(
  value?: UpdateDistanceAndItsRelatedShipmentsPatchBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    legacy_etaisyys_field: value.legacy_etaisyys_field,
    address1: value.address1,
    city1: value.city1,
    address2: value.address2,
    city2: value.city2,
  };
}
