import { debounce, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { Dispatch } from 'react';
import {
  AdditionalService,
  Car,
  CustomPricingCategory,
  Driver,
  Load,
  Office,
  Organization,
  OrganizationCar,
  OrganizationDriver,
  OrganizationPricingModelWithAreas,
  OrganizationShipment,
  OrganizationShipmentPostBody,
  OrganizationShipmentPostBodyStateEnum,
  OrganizationShipmentPutBody,
  OrganizationShipmentRow,
  Photo,
  PricingModelWithAreas,
  PricingModelWithAreasPricingUnitEnum,
  Shipment,
  ShipmentAdditionalService,
  ShipmentPostBody,
  ShipmentRow,
  ShipmentRowStateEnum,
  ShipmentStateEnum,
  User,
} from '../../api';
import { validateFields } from './shipmentValidation';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { canAccessCoordination, canAccessPricing } from '../../utils';
import { getDiff } from 'json-difference';
import { ShipmentRowWithIsNewAndUpdated } from './components/NewShipmentRowTable';
import { dateTimeFormat, LEGACY_ZERO_DATE_TIME } from '../../formatters';

export interface Field<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value: Value;
}

export type ServerFieldNames = keyof Pick<
  Shipment,
  | 'organization_id'
  | 'orderer'
  | 'orderer_phone_number'
  | 'job_number'
  | 'reference_number'
  | 'pickup_name'
  | 'pickup_address'
  | 'pickup_postal_code'
  | 'pickup_phone_number'
  | 'pickup_phone_number_secondary'
  | 'pickup_city'
  | 'delivery_name'
  | 'delivery_address'
  | 'delivery_postal_code'
  | 'delivery_phone_number'
  | 'delivery_city'
  | 'note'
  | 'state'
  | 'length_ldm'
  | 'chargeable_weight_kg'
  | 'weight_kg'
  | 'price'
  | 'legacy_price_basis'
  | 'has_contract_price'
  | 'load_id'
  | 'is_express_delivery'
  | 'hourly_work_hours'
  | 'hourly_work_reason'
  | 'requires_combination_vehicle'
  | 'volume_m3'
  | 'wait_reason'
  | 'pickup_office_organization_specific_id'
  | 'delivery_office_organization_specific_id'
  | 'billing_office_organization_specific_id'
  | 'has_additional_hourly_pricing'
  | 'legacy_etaisyys_field'
  | 'customer_distribution_area'
  | 'pricing_model'
  | 'custom_pricing_category_id'
  | 'delivery_status_url_identifier'
  | 'other_contract_number'
  | 'is_adr_delivery'
  | 'ordered_at'
  | 'arrived_to_pickup_location_at'
  | 'picked_up_at'
  | 'arrived_to_delivery_location_at'
  | 'delivered_at'
  | 'billed_at'
  | 'wait_hours'
  | 'delivery_phone_number_secondary'
  | 'has_distance_been_fixed'
  | 'billing_reference_number'
  | 'requires_hoist'
>;

type UIOnlyFields = {
  pickup_date: Field<Date | null>;
  pickup_time: Field<Date | null>;
  delivery_date: Field<Date | null>;
  delivery_time_window_start: Field<Date | null>;
  delivery_time_window_end: Field<Date | null>;
  sizes: Field<boolean>;
};

export type FieldName = ServerFieldNames | keyof UIOnlyFields;

type PartialShipmentAdditionalService = Pick<ShipmentAdditionalService, 'additional_service_id' | 'quantity'> & {
  id?: ShipmentAdditionalService['id'];
};

export interface AdditionalServiceField extends Field<number | ''> {
  additionalService: AdditionalService;
  shipmentAdditionalServiceId?: ShipmentAdditionalService['id'];
}

type AllFields = {
  // OrganizationShipment instead of OrganizationShipmentPostBody because OrganizationShipmentPostBody does not have chargeable_weight_kg
  [P in ServerFieldNames]: Field<Shipment[P] | ''>;
} & UIOnlyFields;

export interface State {
  fields: AllFields;
  originalFields?: AllFields;
  originalShipment?: Shipment;
  isLoading: boolean;
  loadingMessage?: string;
  notification: NotificationType;
  isValid: boolean;
  canEdit: boolean;
  canSave: boolean;
  rows: ShipmentRowWithIsNewAndUpdated[];
  photos: Photo[];
  load?: Load;
  driver?: Driver | OrganizationDriver;
  car?: Car | OrganizationCar;
  organizationOffices: Office[];
  updated_at: Date | null;
  updated_by: string | null;
  organizations: Organization[];
  relatedShipments: Shipment[];
  isRelatedShipmentsLoading: boolean;
  organizationAdditionalServices: AdditionalService[];
  shipmentAdditionalServices: PartialShipmentAdditionalService[];
  additionalServiceFields: AdditionalServiceField[];
  pricingModels: Partial<PricingModelWithAreas | OrganizationPricingModelWithAreas>[];
  isAddPhotoDialogOpen: boolean;
  currentUser?: User;
}

export type Action =
  | {
      type: 'VALIDATE_FIELD';
      payload: {
        fieldName: keyof State['fields'];
      };
    }
  | {
      type: 'VALIDATE_FIELDS';
    }
  | {
      type: 'SET_FIELD_VALUE';
      payload: {
        fieldName: keyof State['fields'];
        value: FieldValue;
      };
    }
  | {
      type: 'SET_ROWS';
      payload: {
        rows: ShipmentRowWithIsNewAndUpdated[];
      };
    }
  | SetMessageAction
  | SetLoadingAction
  | {
      type: 'INITIALIZE_SHIPMENT';
      payload: {
        shipment: Partial<Shipment> | Partial<OrganizationShipment>;
        shipmentRows: (ShipmentRow | OrganizationShipmentRow)[];
        shipmentPhotos?: Photo[];
        load?: Load;
        driver?: Driver | OrganizationDriver;
        car?: Car | OrganizationCar;
        currentUser: User;
        organization: Organization;
        organizationOffices: Office[];
        organizations?: Organization[];
        organizationAdditionalServices: AdditionalService[];
        shipmentAdditionalServices: PartialShipmentAdditionalService[];
        isNewShipment?: boolean;
        pricingModels: Partial<PricingModelWithAreas | OrganizationPricingModelWithAreas>[];
      };
    }
  | {
      type: 'INITIALIZE_RETURN_SHIPMENT';
      payload: {
        shipmentToBeReturned: Shipment | OrganizationShipment;
        shipmentRowsToBeReturned: (ShipmentRow | OrganizationShipmentRow)[];
        load?: Load;
        currentUser: User;
        organization: Organization;
        organizationOffices: Office[];
      };
    }
  | {
      type: 'CLEAR_SHIPMENT';
      payload: {
        currentUser: User;
      };
    }
  | {
      type: 'CHANGE_ORGANIZATION';
      payload: {
        organizationOffices: Office[];
        organizationAdditionalServices: AdditionalService[];
      };
    }
  | {
      type: 'UPDATE_SHIPMENT_ADDITIONAL_SERVICE';
      payload: {
        additionalService: AdditionalService;
        value: string;
      };
    }
  | {
      type: 'SET_RELATED_SHIPMENTS';
      payload: {
        relatedShipments?: Shipment[];
      };
    }
  | {
      type: 'SET_IS_RELATED_SHIPMENTS_LOADING';
      payload: {
        isRelatedShipmentsLoading: boolean;
      };
    }
  | {
      type: 'SET_IS_PHOTO_DIALOG_OPEN';
      payload: { isDialogOpen: boolean };
    }
  | {
      type: 'UPDATE_PRICING_MODEL_AND_CUSTOM_CATEGORY';
    }
  | {
      type: 'SET_SHIPMENT_PHOTOS';
      payload: {
        photos: Photo[];
      };
    };

export const copyShipment = <T extends OrganizationShipment | Shipment>(shipment: T): T => {
  return {
    ...shipment,
    state: ShipmentStateEnum.Noudettavissa,
    orderer: null,
    orderer_phone_number: null,
    recipient: null,
    has_distance_been_fixed: false,
    billed_at: null,
    pickup_window_starts_at: shipment.pickup_window_starts_at
      ? getPreviousDateOrCurrentIfPassed(shipment.pickup_window_starts_at)
      : null,
    pickup_window_ends_at: shipment.pickup_window_ends_at
      ? getPreviousDateOrCurrentIfPassed(shipment.pickup_window_ends_at)
      : null,
    agreed_delivery_window_starts_at: getPreviousDateOrCurrentIfPassed(shipment.agreed_delivery_window_starts_at),
    agreed_delivery_window_ends_at: getPreviousDateOrCurrentIfPassed(shipment.agreed_delivery_window_ends_at),
  };
};

export const copyShipmentRows = (shipmentRows: ShipmentRowWithIsNewAndUpdated[]): ShipmentRowWithIsNewAndUpdated[] =>
  shipmentRows.map((shipmentRow) => ({ ...shipmentRow, state: ShipmentRowStateEnum.ReadyForPickup }));

const getPreviousDateOrCurrentIfPassed = (date: Date | null) => {
  const previousDate = date ? DateTime.fromJSDate(date) : null;
  const currentDate = DateTime.local();
  if (date === null || (previousDate && previousDate.startOf('day') < currentDate.startOf('day'))) {
    return DateTime.local()
      .set({
        hour: previousDate?.hour ?? 0,
        minute: previousDate?.minute ?? 0,
        second: previousDate?.second ?? 0,
      })
      .toJSDate();
  } else {
    return previousDate ? previousDate.toJSDate() : null;
  }
};

export const convertToReturnShipment = <T extends OrganizationShipment | Shipment>(shipment: T): T => {
  return {
    ...copyShipment(shipment),
    pickup_address: shipment.delivery_address,
    pickup_city: shipment.delivery_city,
    pickup_postal_code: shipment.delivery_postal_code,
    pickup_name: shipment.delivery_name,
    pickup_phone_number: shipment.delivery_phone_number,
    pickup_phone_number_secondary: shipment.delivery_phone_number_secondary,
    delivery_address: shipment.pickup_address,
    delivery_city: shipment.pickup_city,
    delivery_postal_code: shipment.pickup_postal_code,
    delivery_name: shipment.pickup_name,
    delivery_phone_number: shipment.pickup_phone_number,
    delivery_phone_number_secondary: shipment.pickup_phone_number_secondary,
  };
};

function isShipment(value: Partial<Shipment> | Partial<OrganizationShipment>): value is Shipment {
  return value.hasOwnProperty('price');
}

const areAllFieldsValid = (state: Pick<State, 'fields' | 'additionalServiceFields'>) => {
  return (
    !Object.values(state.fields).some((field) => field.hasError) &&
    !state.additionalServiceFields.some((field) => field.hasError) &&
    areTimelineEventsInCorrectOrder(state.fields)
  );
};

const getFormattedRows = (rows: ShipmentRowWithIsNewAndUpdated[]) => {
  return rows.map((row) => {
    row.serial_number = row.serial_number ?? null;
    row.parcel_id = row.parcel_id ?? null;
    row.quantity = row.quantity ?? null;
    row.weight_per_piece_kg = row.weight_per_piece_kg ?? null;
    if (row.isNew) {
      const { id, ...rowWithOutId } = row;
      return rowWithOutId;
    } else {
      return row;
    }
  });
};

export const getOrganizationShipmentPostBodyFromState = (state: State): OrganizationShipmentPostBody => {
  const pickupWindow = getPickupWindow(state.fields);
  const agreedDeliveryWindow = getAgreedDeliveryWindow(state.fields);
  return {
    agreed_delivery_window_starts_at: agreedDeliveryWindow.startsAt.toJSDate(),
    agreed_delivery_window_ends_at: agreedDeliveryWindow.endsAt.toJSDate(),
    billing_office_organization_specific_id: state.fields.billing_office_organization_specific_id.value || '',
    delivery_office_organization_specific_id: state.fields.delivery_office_organization_specific_id.value || null,
    delivery_name: state.fields.delivery_name.value,
    delivery_address: state.fields.delivery_address.value,
    delivery_city: state.fields.delivery_city.value,
    delivery_phone_number: state.fields.delivery_phone_number.value || null,
    delivery_postal_code: state.fields.delivery_postal_code.value,
    length_ldm: state.fields.length_ldm.value || 0,
    note: state.fields.note.value,
    orderer: state.fields.orderer.value || null,
    orderer_phone_number: state.fields.orderer_phone_number.value || null,
    reference_number: state.fields.reference_number.value,
    job_number: state.fields.job_number.value,
    pickup_office_organization_specific_id: state.fields.pickup_office_organization_specific_id.value || null,
    pickup_name: state.fields.pickup_name.value,
    pickup_address: state.fields.pickup_address.value,
    pickup_city: state.fields.pickup_city.value,
    pickup_phone_number: state.fields.pickup_phone_number.value || null,
    pickup_phone_number_secondary: state.fields.pickup_phone_number_secondary.value || null,
    pickup_postal_code: state.fields.pickup_postal_code.value,
    pickup_window_starts_at: pickupWindow?.startsAt.toJSDate() ?? null,
    pickup_window_ends_at: pickupWindow?.endsAt.toJSDate() ?? null,
    weight_kg: state.fields.weight_kg.value || 0,
    volume_m3: state.fields.volume_m3.value || 0,
    state: state.fields.state.value as unknown as OrganizationShipmentPostBodyStateEnum,
    pricing_model: state.fields.pricing_model.value || null,
    custom_pricing_category_id: state.fields.custom_pricing_category_id.value || null,
    other_contract_number: state.fields.other_contract_number.value || null,
    is_adr_delivery: state.fields.is_adr_delivery.value || false,
    delivery_phone_number_secondary: state.fields.delivery_phone_number_secondary.value || null,
    billing_reference_number: state.fields.billing_reference_number.value || null,
    requires_combination_vehicle: state.fields.requires_combination_vehicle.value || false,
    requires_hoist: state.fields.requires_hoist.value || false,
    is_express_delivery: state.fields.is_express_delivery.value || false,
    rows: getFormattedRows(state.rows) as OrganizationShipmentPutBody['rows'],
  };
};

export const getShipmentPostBodyFromState = (state: State, currentUser?: User): ShipmentPostBody => {
  return {
    ...getOrganizationShipmentPostBodyFromState(state),
    hourly_work_hours: state.fields.hourly_work_hours.value || 0,
    hourly_work_reason: state.fields.hourly_work_reason.value,
    job_number: state.fields.job_number.value,
    load_id: state.fields.load_id.value || null,
    order_in_load: state.originalShipment?.order_in_load ?? null,
    organization_id: state.fields.organization_id.value,
    other_contract_number: state.fields.other_contract_number.value || null,
    recipient: state.originalShipment?.recipient ?? null,
    state: state.fields.state.value as unknown as ShipmentStateEnum,
    wait_reason: state.fields.wait_reason.value,
    weight_kg: state.fields.weight_kg.value || 0,
    wait_hours: state.fields.wait_hours.value || 0,
    ...(canAccessPricing(currentUser) && {
      has_contract_price: state.fields.has_contract_price.value || false,
      legacy_price_basis: state.fields.legacy_price_basis.value,
      price: state.fields.price.value || 0,
      has_additional_hourly_pricing: state.fields.has_additional_hourly_pricing.value || false,
      legacy_etaisyys_field: state.fields.legacy_etaisyys_field.value || null,
      has_distance_been_fixed: state.fields.has_distance_been_fixed.value || false,
    }),
    additional_services: state.additionalServiceFields.map((field) => ({
      additional_service_id: field.additionalService.id,
      shipment_additional_service_id: field.shipmentAdditionalServiceId,
      amount: field.value || 0,
    })),
  };
};

export const createAdditionalServiceFields = (
  organizationAdditionalServices: AdditionalService[],
  shipmentAdditionalServices: PartialShipmentAdditionalService[],
): AdditionalServiceField[] => {
  const additionalServiceFields: AdditionalServiceField[] = [];
  orderBy(
    organizationAdditionalServices.map((orgAddService) => {
      const shipmentAdditionalService = shipmentAdditionalServices.find(
        (shipAddService) => shipAddService.additional_service_id === orgAddService.id,
      );
      return {
        additionalService: orgAddService,
        shipmentAdditionalService: shipmentAdditionalService,
      };
    }),
    'name',
  ).forEach((services) => {
    additionalServiceFields.push({
      required: false,
      hasError: false,
      feedback: undefined,
      value: services.shipmentAdditionalService?.quantity ?? 0,
      additionalService: services.additionalService,
      shipmentAdditionalServiceId: services.shipmentAdditionalService?.id,
    });
  });
  return additionalServiceFields;
};

const getShipmentPricingModelId = (
  shipmentPricingModelId: Shipment['pricing_model'] | undefined,
  shipmentBillingOfficeOrganizationSpecificId: Shipment['billing_office_organization_specific_id'] | undefined,
  organizationDefaultPricingModel: Organization['default_pricing_model'],
  organizationOffices: Office[],
): PricingModelWithAreas['id'] | null => {
  const shipmentBillingOfficeDefaultPricingModelId = organizationOffices.find(
    (office) => office.organization_specific_office_id === shipmentBillingOfficeOrganizationSpecificId,
  )?.default_pricing_model;
  const correctShipmentPricingModelId =
    shipmentPricingModelId ?? shipmentBillingOfficeDefaultPricingModelId ?? organizationDefaultPricingModel;
  return correctShipmentPricingModelId;
};

export const getShipmentCustomPricingCategoryFromState = (state: State): CustomPricingCategory | null => {
  const currentPricingModel = state.pricingModels.find(
    (pricingModel) => pricingModel.id === state.fields.pricing_model.value,
  );
  const customPricingCategories = currentPricingModel?.custom_pricing_categories;
  return (
    customPricingCategories?.find(
      (customPricingCategory) => customPricingCategory.id === state.fields.custom_pricing_category_id.value,
    ) ?? null
  );
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD_VALUE': {
      const fields = {
        ...state.fields,
      };
      fields[action.payload.fieldName].value = action.payload.value;
      return {
        ...state,
        fields,
      };
    }
    case 'SET_ROWS': {
      return {
        ...state,
        rows: action.payload.rows,
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'VALIDATE_FIELDS': {
      const validatedState = validateFields(state);
      const editedFields = state.originalFields && getDiff(validatedState.fields, state.originalFields).edited;
      const newErrors = editedFields
        ?.filter((x) => x[0].includes('hasError') || x[0].includes('feedback'))
        .some((x) => x[1]);
      return {
        ...state,
        ...validatedState,
        isValid: areAllFieldsValid(validatedState),
        canSave:
          (newErrors || state.originalShipment?.billed_at) && !canAccessCoordination(state.currentUser) ? false : true,
      };
    }

    case 'CLEAR_SHIPMENT': {
      return {
        ...state,
        originalShipment: undefined,
        fields: getInitialState(action.payload.currentUser).fields,
        rows: [],
      };
    }
    case 'INITIALIZE_SHIPMENT': {
      const currentUser = action.payload.currentUser;
      const fields = {
        ...state.fields,
      };
      const deliveryWindowStartsAt =
        action.payload.shipment.agreed_delivery_window_starts_at instanceof Date
          ? DateTime.fromJSDate(action.payload.shipment.agreed_delivery_window_starts_at)
          : null;
      const deliveryWindowEndsAt =
        action.payload.shipment.agreed_delivery_window_ends_at instanceof Date
          ? DateTime.fromJSDate(action.payload.shipment.agreed_delivery_window_ends_at)
          : null;
      fields.delivery_date.value = deliveryWindowStartsAt !== null ? deliveryWindowStartsAt.toJSDate() : null;
      fields.delivery_time_window_start.value =
        deliveryWindowStartsAt !== null ? deliveryWindowStartsAt.toJSDate() : null;
      fields.delivery_time_window_end.value = deliveryWindowEndsAt !== null ? deliveryWindowEndsAt.toJSDate() : null;
      const pickupWindowStartsAt =
        action.payload.shipment.pickup_window_starts_at instanceof Date
          ? DateTime.fromJSDate(action.payload.shipment.pickup_window_starts_at)
          : null;
      const pickupWindowEndsAt =
        action.payload.shipment.pickup_window_ends_at instanceof Date
          ? DateTime.fromJSDate(action.payload.shipment.pickup_window_ends_at)
          : null;
      fields.pickup_date.value = pickupWindowStartsAt !== null ? pickupWindowStartsAt.toJSDate() : null;
      fields.pickup_time.value = // Pickup "window" is always an exact moment of time
        pickupWindowStartsAt !== null &&
        pickupWindowEndsAt !== null &&
        pickupWindowStartsAt.toMillis() === pickupWindowEndsAt.toMillis()
          ? pickupWindowStartsAt.toJSDate()
          : null;
      fields.ordered_at.value = getDateOrNullIfInvalidOrLegacy(action.payload.shipment.ordered_at);
      fields.arrived_to_pickup_location_at.value = getDateOrNullIfInvalidOrLegacy(
        action.payload.shipment.arrived_to_pickup_location_at,
      );
      fields.picked_up_at.value = getDateOrNullIfInvalidOrLegacy(action.payload.shipment.picked_up_at);
      fields.arrived_to_delivery_location_at.value = getDateOrNullIfInvalidOrLegacy(
        action.payload.shipment.arrived_to_delivery_location_at,
      );
      fields.delivered_at.value = getDateOrNullIfInvalidOrLegacy(action.payload.shipment.delivered_at);
      fields.billed_at.value = action.payload.shipment.billed_at ?? null;
      fields.organization_id.value = action.payload.shipment.organization_id ?? '';
      fields.delivery_office_organization_specific_id.value =
        action.payload.shipment.delivery_office_organization_specific_id ?? '';
      fields.delivery_name.value = action.payload.shipment.delivery_name ?? '';
      fields.delivery_address.value = action.payload.shipment.delivery_address ?? '';
      fields.delivery_city.value = action.payload.shipment.delivery_city ?? '';
      fields.delivery_phone_number.value = action.payload.shipment.delivery_phone_number ?? '';
      fields.delivery_postal_code.value = action.payload.shipment.delivery_postal_code ?? '';
      fields.job_number.value = action.payload.shipment.job_number ?? '';
      fields.note.value = action.payload.shipment.note ?? '';
      fields.billing_office_organization_specific_id.value =
        action.payload.shipment.billing_office_organization_specific_id || '';
      fields.orderer.value = action.payload.shipment.orderer ?? '';
      fields.orderer_phone_number.value = action.payload.shipment.orderer_phone_number ?? '';
      fields.reference_number.value = action.payload.shipment.reference_number ?? '';
      fields.pickup_office_organization_specific_id.value =
        action.payload.shipment.pickup_office_organization_specific_id ?? '';
      fields.pickup_name.value = action.payload.shipment.pickup_name ?? '';
      fields.pickup_address.value = action.payload.shipment.pickup_address ?? '';
      fields.pickup_city.value = action.payload.shipment.pickup_city ?? '';
      fields.pickup_phone_number.value = action.payload.shipment.pickup_phone_number ?? '';
      fields.pickup_phone_number_secondary.value = action.payload.shipment.pickup_phone_number_secondary ?? '';
      fields.pickup_postal_code.value = action.payload.shipment.pickup_postal_code ?? '';
      fields.length_ldm.value = action.payload.shipment.length_ldm ?? '';
      fields.weight_kg.value = action.payload.shipment.weight_kg ?? '';
      fields.chargeable_weight_kg.value = action.payload.shipment.chargeable_weight_kg ?? 0;
      fields.state.value = (action.payload.shipment.state as ShipmentStateEnum) ?? ShipmentStateEnum.Noudettavissa;
      fields.volume_m3.value = action.payload.shipment.volume_m3 ?? '';
      fields.customer_distribution_area.value = action.payload.shipment.customer_distribution_area ?? '';
      fields.other_contract_number.value = action.payload.shipment.other_contract_number ?? '';
      fields.is_adr_delivery.value = action.payload.shipment.is_adr_delivery ?? false;
      fields.delivery_phone_number_secondary.value = action.payload.shipment.delivery_phone_number_secondary ?? '';
      fields.pricing_model.value = action.payload.shipment.pricing_model ?? '';
      fields.custom_pricing_category_id.value = action.payload.shipment.custom_pricing_category_id ?? '';
      fields.requires_combination_vehicle.value = action.payload.shipment.requires_combination_vehicle ?? false;
      fields.requires_hoist.value = action.payload.shipment.requires_hoist ?? false;
      fields.is_express_delivery.value = action.payload.shipment.is_express_delivery ?? false;
      fields.billing_reference_number.value = action.payload.shipment.billing_reference_number ?? '';
      if (isShipment(action.payload.shipment)) {
        fields.price.value = action.payload.shipment.price ?? 0;
        fields.legacy_price_basis.value = action.payload.shipment.legacy_price_basis ?? '';
        fields.has_contract_price.value = action.payload.shipment.has_contract_price ?? false;
        fields.load_id.value = action.payload.shipment.load_id ?? '';
        fields.hourly_work_hours.value = action.payload.shipment.hourly_work_hours ?? 0;
        fields.hourly_work_reason.value = action.payload.shipment.hourly_work_reason ?? '';
        fields.wait_reason.value = action.payload.shipment.wait_reason ?? '';
        fields.wait_hours.value = action.payload.shipment.wait_hours ?? 0;
        fields.has_additional_hourly_pricing.value = action.payload.shipment.has_additional_hourly_pricing ?? false;
        fields.legacy_etaisyys_field.value =
          action.payload.shipment.legacy_etaisyys_field !== null &&
          action.payload.shipment.legacy_etaisyys_field !== undefined
            ? action.payload.shipment.legacy_etaisyys_field
            : '';
        fields.delivery_status_url_identifier.value = action.payload.shipment.delivery_status_url_identifier ?? '';
        fields.has_distance_been_fixed.value = action.payload.shipment.has_distance_been_fixed ?? false;
      }
      const additionalServiceFields = createAdditionalServiceFields(
        action.payload.organizationAdditionalServices,
        action.payload.shipmentAdditionalServices,
      );
      const shipmentPricingModelId = getShipmentPricingModelId(
        action.payload.shipment.pricing_model,
        action.payload.shipment.billing_office_organization_specific_id,
        action.payload.organization.default_pricing_model,
        action.payload.organizationOffices,
      );
      fields.pricing_model.value = shipmentPricingModelId ?? '';
      const shipmentPricingModelDefaultCustomPricingCategoryId = action.payload.pricingModels.find(
        (pricingModel) => pricingModel.id === shipmentPricingModelId,
      )?.default_custom_pricing_category;
      const shipmentCustomPricingCategoryId =
        action.payload.shipment.custom_pricing_category_id ?? shipmentPricingModelDefaultCustomPricingCategoryId ?? '';
      fields.custom_pricing_category_id.value = shipmentCustomPricingCategoryId;
      return {
        ...state,
        fields,
        originalFields: validateFields({ ...state, fields: fields }).fields,
        rows: action.payload.shipmentRows as ShipmentRow[],
        photos: action.payload.shipmentPhotos ?? [],
        originalShipment: action.payload.shipment as Shipment,
        load: action.payload.load,
        driver: action.payload.driver,
        car: action.payload.car,
        isValid: false,
        organizationOffices: action.payload.organizationOffices,
        organizationAdditionalServices: action.payload.organizationAdditionalServices ?? [],
        shipmentAdditionalServices: action.payload.shipmentAdditionalServices ?? [],
        updated_at: action.payload.shipment.updated_at ?? null,
        updated_by: action.payload.shipment.updated_by ?? '',
        canEdit:
          ((action.payload.shipment.load_id ?? 0) === 0 &&
            action.payload.shipment.state !== ShipmentStateEnum.Peruttu &&
            !action.payload.shipment.billed_at) ||
          canAccessCoordination(currentUser),
        canSave: !action.payload.shipment.billed_at || canAccessCoordination(currentUser),
        organizations: action.payload.organizations ?? [],
        additionalServiceFields,
        pricingModels: action.payload.pricingModels,
        currentUser: currentUser,
      };
    }
    case 'SET_RELATED_SHIPMENTS': {
      return {
        ...state,
        relatedShipments: action.payload.relatedShipments ?? [],
      };
    }
    case 'SET_IS_RELATED_SHIPMENTS_LOADING': {
      return {
        ...state,
        isRelatedShipmentsLoading: action.payload.isRelatedShipmentsLoading,
      };
    }
    case 'SET_IS_PHOTO_DIALOG_OPEN': {
      return {
        ...state,
        isAddPhotoDialogOpen: action.payload.isDialogOpen,
      };
    }
    case 'UPDATE_PRICING_MODEL_AND_CUSTOM_CATEGORY': {
      const organizationDefaultPricingModelId = state.organizations.find(
        (organization) => organization.id === state.fields.organization_id.value,
      )?.default_pricing_model;
      const organizationOfficeDefaultPricingModelId = state.organizationOffices.find(
        (office) =>
          office.organization_specific_office_id === state.fields.billing_office_organization_specific_id.value,
      )?.default_pricing_model;
      let newPricingModel: number | '' | null = null;
      if (
        organizationOfficeDefaultPricingModelId &&
        organizationOfficeDefaultPricingModelId !== organizationDefaultPricingModelId
      ) {
        newPricingModel = organizationOfficeDefaultPricingModelId;
      } else {
        newPricingModel = organizationDefaultPricingModelId ?? state.fields.pricing_model.value ?? null;
      }

      let newCustomPricingCategoryId: number | '' | null = null;
      const shipmentPricingModel = state.pricingModels.find((pricingModel) => pricingModel.id === newPricingModel);
      if (shipmentPricingModel?.default_custom_pricing_category) {
        newCustomPricingCategoryId = shipmentPricingModel?.default_custom_pricing_category;
      } else if (shipmentPricingModel?.pricing_unit !== PricingModelWithAreasPricingUnitEnum.CustomCategory) {
        newCustomPricingCategoryId = state.fields.custom_pricing_category_id.value ?? null;
      }

      return {
        ...state,
        fields: {
          ...state.fields,
          pricing_model: { ...state.fields.pricing_model, value: newPricingModel },
          custom_pricing_category_id: { ...state.fields.pricing_model, value: newCustomPricingCategoryId },
        },
      };
    }
    case 'SET_SHIPMENT_PHOTOS': {
      return {
        ...state,
        photos: action.payload.photos,
      };
    }
    case 'CHANGE_ORGANIZATION': {
      return {
        ...state,
        organizationOffices: action.payload.organizationOffices,
        organizationAdditionalServices: action.payload.organizationAdditionalServices,
        additionalServiceFields: createAdditionalServiceFields(
          action.payload.organizationAdditionalServices,
          state.shipmentAdditionalServices,
        ),
      };
    }
    case 'UPDATE_SHIPMENT_ADDITIONAL_SERVICE': {
      let quantity: number | '' = action.payload.value !== '' ? Number(action.payload.value) : '';
      quantity = Number.isNaN(quantity) ? '' : quantity;
      const additionalServiceFields = [...state.additionalServiceFields];
      const existingShipmentAdditionalServiceIndex = additionalServiceFields.findIndex(
        (shipAddServiceField) => shipAddServiceField.additionalService.id === action.payload.additionalService.id,
      );
      if (existingShipmentAdditionalServiceIndex === -1) {
        additionalServiceFields.push({
          additionalService: action.payload.additionalService,
          value: quantity || 0,
          required: false,
          hasError: false,
          feedback: undefined,
        });
      } else {
        additionalServiceFields[existingShipmentAdditionalServiceIndex].value = quantity;
      }
      return {
        ...state,
        additionalServiceFields,
      };
    }
    default: {
      throw new Error(`Unhandled action ${JSON.stringify(action)}`);
    }
  }
};

export const getAgreedDeliveryWindow = (fields: State['fields']): { startsAt: DateTime; endsAt: DateTime } => {
  const zeroTime = DateTime.fromFormat('00:00', 'HH:mm');
  const agreedDeliveryTimeWindowStartsAt =
    fields.delivery_time_window_start.value && DateTime.fromJSDate(fields.delivery_time_window_start.value).isValid
      ? DateTime.fromJSDate(fields.delivery_time_window_start.value)
      : zeroTime;
  const agreedDeliveryTimeWindowEndsAt =
    fields.delivery_time_window_end.value && DateTime.fromJSDate(fields.delivery_time_window_end.value).isValid
      ? DateTime.fromJSDate(fields.delivery_time_window_end.value)
      : zeroTime;
  const agreedDeliveryWindowStartsAt = DateTime.fromJSDate(fields.delivery_date.value as Date).set({
    hour: agreedDeliveryTimeWindowStartsAt.hour,
    minute: agreedDeliveryTimeWindowStartsAt.minute,
  });
  return {
    startsAt: agreedDeliveryWindowStartsAt,
    endsAt: agreedDeliveryWindowStartsAt.set({
      hour: agreedDeliveryTimeWindowEndsAt.hour,
      minute: agreedDeliveryTimeWindowEndsAt.minute,
    }),
  };
};

export const getPickupWindow = (fields: State['fields']): { startsAt: DateTime; endsAt: DateTime } | null => {
  const pickupTime = DateTime.fromJSDate(fields.pickup_time.value as Date);
  let pickupWindowStartsAt = (fields.pickup_date.value && DateTime.fromJSDate(fields.pickup_date.value)) ?? null;
  let pickupWindowEndsAt = (pickupWindowStartsAt && pickupWindowStartsAt.plus({ days: 1 })) ?? null;
  if (pickupWindowStartsAt === null || pickupWindowEndsAt === null) {
    return null;
  }
  if (pickupTime.isValid && pickupWindowStartsAt) {
    pickupWindowStartsAt = pickupWindowStartsAt.set({
      hour: pickupTime.hour,
      minute: pickupTime.minute,
    });
    pickupWindowEndsAt = pickupWindowStartsAt;
  }
  return {
    startsAt: pickupWindowStartsAt,
    endsAt: pickupWindowEndsAt,
  };
};

export const getDateOrNullIfInvalidOrLegacy = (date: Date | null | undefined) => {
  const formattedDate = date ? DateTime.fromJSDate(date) : null;
  if (!date || !formattedDate?.isValid || formattedDate.toFormat(dateTimeFormat) === LEGACY_ZERO_DATE_TIME) {
    return null;
  }
  return date;
};

export const areTimelineEventsInCorrectOrder = (fields: State['fields']): any => {
  const billedAt = fields.billed_at.value ? DateTime.fromJSDate(fields.billed_at.value).endOf('day').toJSDate() : 0;
  const dates = [
    fields.ordered_at.value ?? 0,
    fields.arrived_to_pickup_location_at.value ?? 0,
    fields.picked_up_at.value ?? 0,
    fields.arrived_to_delivery_location_at.value ?? 0,
    fields.delivered_at.value ?? 0,
    billedAt,
  ];
  return dates.filter(Boolean).every((e, i, a) => !i || a[i - 1] <= e);
};

type FieldValue = string | number | Date | boolean | null;

export const debouncedValidateFieldsDispatch = debounce((dispatch: Dispatch<Action>) => {
  dispatch({
    type: 'VALIDATE_FIELDS',
  });
}, 500);

export const updateFieldValue = (fieldName: FieldName, value: FieldValue, dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_FIELD_VALUE',
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
  debouncedValidateFieldsDispatch(dispatch);
};

export type ShipmentLocationState =
  | {
      shipment: Shipment;
      shipmentRows: ShipmentRow[];
      additionalServiceFields: AdditionalServiceField[];
      isCopy?: boolean;
    }
  | undefined;

export const getShipmentDefaultValues = (organization: Organization): Partial<Shipment> => {
  return {
    agreed_delivery_window_starts_at:
      organization.default_agreed_delivery_window_time_starts_at === null
        ? DateTime.local()
            .set({
              hour: 7,
              minute: 0,
              second: 0,
            })
            .toJSDate()
        : DateTime.fromFormat(organization.default_agreed_delivery_window_time_starts_at, 'HH:mm:ss').toJSDate(),
    agreed_delivery_window_ends_at:
      organization.default_agreed_delivery_window_time_ends_at === null
        ? DateTime.local()
            .set({
              hour: 16,
              minute: 0,
              second: 0,
            })
            .toJSDate()
        : DateTime.fromFormat(organization.default_agreed_delivery_window_time_ends_at, 'HH:mm:ss').toJSDate(),
    organization_id: organization.id,
  };
};

export function updateRows(rows: ShipmentRowWithIsNewAndUpdated[], dispatch: Dispatch<Action>): void {
  const rowsWithTotalWeight = rows.map((x) => ({
    ...x,
    total_weight_kg: (x.weight_per_piece_kg ?? 0) * (x.quantity ?? 0),
  }));
  dispatch({
    type: 'SET_ROWS',
    payload: {
      rows: rowsWithTotalWeight,
    },
  });
  updateFieldValue(
    'weight_kg',
    rowsWithTotalWeight.reduce((weight, row) => (weight += row.total_weight_kg ?? 0), 0),
    dispatch,
  );
  updateFieldValue(
    'volume_m3',
    rowsWithTotalWeight.reduce((volume, row) => (volume += row.volume_m3 ?? 0), 0),
    dispatch,
  );
}

export const getInitialState = (currentUser: User | undefined): State => {
  return {
    fields: {
      organization_id: {
        required: currentUser?.is_multi_organization ?? false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      billing_office_organization_specific_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      delivery_date: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      delivery_time_window_start: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      delivery_time_window_end: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      delivery_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      delivery_address: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      delivery_city: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      delivery_phone_number: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      delivery_postal_code: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      job_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      note: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      orderer: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      orderer_phone_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      reference_number: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      delivery_office_organization_specific_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      pickup_office_organization_specific_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      pickup_name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      pickup_address: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      pickup_city: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      pickup_phone_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      pickup_phone_number_secondary: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      pickup_postal_code: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      pickup_date: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      pickup_time: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      state: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: ShipmentStateEnum.Noudettavissa,
      },
      length_ldm: {
        required: false,
        hasError: false,
        feedback: '',
        value: '',
      },
      chargeable_weight_kg: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: 0,
      },
      weight_kg: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      price: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: 0,
      },
      legacy_price_basis: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      has_contract_price: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      load_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      is_express_delivery: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      hourly_work_hours: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      hourly_work_reason: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      requires_combination_vehicle: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      volume_m3: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      wait_reason: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      has_additional_hourly_pricing: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      legacy_etaisyys_field: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      customer_distribution_area: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      pricing_model: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      custom_pricing_category_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      delivery_status_url_identifier: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      other_contract_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      is_adr_delivery: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      ordered_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      arrived_to_pickup_location_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      picked_up_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      arrived_to_delivery_location_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      delivered_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      billed_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      wait_hours: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      delivery_phone_number_secondary: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      has_distance_been_fixed: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      billing_reference_number: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      requires_hoist: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      sizes: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
    },
    rows: [],
    photos: [],
    originalShipment: undefined,
    isLoading: true,
    notification: {
      message: null,
    },
    isValid: false,
    canEdit: false,
    canSave: false,
    organizationOffices: [],
    updated_at: null,
    updated_by: '',
    organizations: [],
    relatedShipments: [],
    isRelatedShipmentsLoading: false,
    organizationAdditionalServices: [],
    shipmentAdditionalServices: [],
    additionalServiceFields: [],
    pricingModels: [],
    isAddPhotoDialogOpen: false,
    currentUser: undefined,
  };
};
