import { DateTime } from 'luxon';
import { Dispatch } from 'react';
import { DateRangePickerProps } from '../components/DateRangePicker';

export interface DateRange {
  start: DateTime;
  end: DateTime;
}

const START_QUERY_PARAMETER = 'start';
const END_DATE_QUERY_PARAMETER = 'end';

type DateRangePickerState = { dateRange: DateRange | null; isLoading: boolean };
export type SetDateRangeAction = { type: 'SET_DATE_RANGE'; payload: DateRange };

export const getDateRangePickerPropsFromState = (
  state: DateRangePickerState,
  dispatch: Dispatch<SetDateRangeAction>,
): DateRangePickerProps => {
  return {
    startDate: state.dateRange?.start ?? null,
    endDate: state.dateRange?.end ?? null,
    disabled: state.isLoading,
    onValue: (startDate, endDate): void => {
      const range = {
        start: startDate,
        end: endDate,
      };
      dispatch({
        type: 'SET_DATE_RANGE',
        payload: range,
      });
      updateDateRangeToUrl(range);
    },
  };
};

export const getDefaultDateRange = (): DateRange => getDateRangeFromUrl() ?? getTodaysDateRange();

export const getDateRangeFromUrl = (): DateRange | null => {
  const url = new URL(window.location.href);
  const startDateTime = DateTime.fromISO(url.searchParams.get(START_QUERY_PARAMETER) ?? '');
  const endDateTime = DateTime.fromISO(url.searchParams.get(END_DATE_QUERY_PARAMETER) ?? '');
  if (startDateTime.isValid && endDateTime.isValid) {
    return {
      start: startDateTime,
      end: endDateTime.endOf('day'),
    };
  } else {
    return null;
  }
};

export const getTodaysDateRange = (): DateRange => ({
  start: DateTime.local().startOf('day'),
  end: DateTime.local().endOf('day'),
});

export const getCurrentSalaryPeriodDateRange = (): DateRange => {
  const [start, end] = getSalaryPeriod(DateTime.local());
  return {
    start: start,
    end: end,
  };
};

export const updateDateRangeToUrl = (dateRange: DateRange): void => {
  const url = new URL(window.location.href);
  url.searchParams.set(START_QUERY_PARAMETER, dateRange.start.toFormat('yyyy-MM-dd'));
  url.searchParams.set(END_DATE_QUERY_PARAMETER, dateRange.end.toFormat('yyyy-MM-dd'));
  window.history.replaceState(null, window.document.title, url.href);
};

/**
 * If returnPreviousSalaryPeriodIfSalaryPeriodStartsToday is set to true and today is the first day of
 * the salary period, it returns both current and previous salary period.
 * This is needed so drivers can add / update previous salary period work hours.
 */
export const getSalaryPeriod = (
  date: DateTime,
  returnPreviousSalaryPeriodIfSalaryPeriodStartsToday: boolean = false,
) => {
  const getSalaryPeriodEnd = (start: DateTime) => {
    return start.plus({ days: 13 }).endOf('day');
  };
  const getSalaryPeriodStart = (start: DateTime) => {
    const isTodaySalaryPeriodStartDate = start.startOf('day').equals(date.startOf('day'));
    return isTodaySalaryPeriodStartDate && returnPreviousSalaryPeriodIfSalaryPeriodStartsToday
      ? start.minus({ days: 14 })
      : start;
  };
  let salaryPeriodStart = DateTime.fromObject({
    year: 2023,
    month: 11,
    day: 20,
    hour: 12,
  }).startOf('day');
  let salaryPeriod = salaryPeriodStart.until(getSalaryPeriodEnd(salaryPeriodStart));

  while (!salaryPeriod.contains(date)) {
    if (salaryPeriodStart < date) {
      salaryPeriodStart = salaryPeriodStart.plus({ days: 14 });
    } else {
      salaryPeriodStart = salaryPeriodStart.minus({ days: 14 });
    }
    salaryPeriod = salaryPeriodStart.until(getSalaryPeriodEnd(salaryPeriodStart));
  }

  return [getSalaryPeriodStart(salaryPeriod.start), salaryPeriod.end];
};
