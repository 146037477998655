/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ShipmentReportFilter
 */
export interface ShipmentReportFilter {
  /**
   *
   * @type {string}
   * @memberof ShipmentReportFilter
   */
  type: ShipmentReportFilterTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ShipmentReportFilter
   */
  field: ShipmentReportFilterFieldEnum;
  /**
   *
   * @type {string | number}
   * @memberof ShipmentReportFilter
   */
  value: string | number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ShipmentReportFilterTypeEnum {
  Equal = '=',
  LessThan = '<',
  LessThanOrEqualTo = '<=',
  GreaterThan = '>',
  GreaterThanOrEqualTo = '>=',
} /**
 * @export
 * @enum {string}
 */
export enum ShipmentReportFilterFieldEnum {
  OrganizationId = 'organization_id',
  State = 'state',
}

export function ShipmentReportFilterFromJSON(json: any): ShipmentReportFilter {
  return ShipmentReportFilterFromJSONTyped(json, false);
}

export function ShipmentReportFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentReportFilter {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    field: json['field'],
    value: json['value'],
  };
}

export function ShipmentReportFilterToJSON(value?: ShipmentReportFilter | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    field: value.field,
    value: value.value,
  };
}
