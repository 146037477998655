import { styled } from '@mui/material';

export const FieldSet = styled('fieldset')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.table.main}`,
  margin: '0.25rem',
  borderRadius: '0.25rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0 0 1rem 0',
  },
  '&>legend': {
    float: 'left',
    fontSize: '0.95rem',
    fontWeight: 'bold',
    padding: '0.25rem 0rem 0.25rem 0',
  },
}));

export const FieldSetWithSetWidth = styled(FieldSet)(() => ({
  width: '20rem',
}));
