/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AccessTokenResponseBody,
  AccessTokenResponseBodyFromJSON,
  AccessTokenResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  FirstLogInPostBody,
  FirstLogInPostBodyFromJSON,
  FirstLogInPostBodyToJSON,
  GetAccessTokenPostBody,
  GetAccessTokenPostBodyFromJSON,
  GetAccessTokenPostBodyToJSON,
  LogInPostBody,
  LogInPostBodyFromJSON,
  LogInPostBodyToJSON,
  RefreshTokenResponseBody,
  RefreshTokenResponseBodyFromJSON,
  RefreshTokenResponseBodyToJSON,
  User,
  UserFromJSON,
  UserToJSON,
} from '../models';

export interface AuthenticationApiGetAccessTokenRequest {
  getAccessTokenPostBody: GetAccessTokenPostBody;
}

export interface AuthenticationApiLogInRequest {
  logInPostBody: LogInPostBody;
}

export interface AuthenticationApiLogInFirstTimeRequest {
  firstLogInPostBody: FirstLogInPostBody;
}

/**
 *
 */
export class AuthenticationApi extends runtime.BaseAPI {
  /**
   * Use a RefreshToken to get an AccessToken.
   */
  async getAccessTokenRaw(
    requestParameters: AuthenticationApiGetAccessTokenRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AccessTokenResponseBody>> {
    if (requestParameters.getAccessTokenPostBody === null || requestParameters.getAccessTokenPostBody === undefined) {
      throw new runtime.RequiredError(
        'getAccessTokenPostBody',
        'Required parameter requestParameters.getAccessTokenPostBody was null or undefined when calling getAccessToken.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/token`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: GetAccessTokenPostBodyToJSON(requestParameters.getAccessTokenPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenResponseBodyFromJSON(jsonValue));
  }

  /**
   * Use a RefreshToken to get an AccessToken.
   */
  async getAccessToken(
    requestParameters: AuthenticationApiGetAccessTokenRequest,
    initOverrides?: RequestInit,
  ): Promise<AccessTokenResponseBody> {
    const response = await this.getAccessTokenRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the identity of the currently logged in user.
   */
  async getCurrentUserRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/identity/current_user`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
  }

  /**
   * Returns the identity of the currently logged in user.
   */
  async getCurrentUser(initOverrides?: RequestInit): Promise<User> {
    const response = await this.getCurrentUserRaw(initOverrides);
    return await response.value();
  }

  /**
   * Log in to Timeweb 2.
   */
  async logInRaw(
    requestParameters: AuthenticationApiLogInRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<RefreshTokenResponseBody>> {
    if (requestParameters.logInPostBody === null || requestParameters.logInPostBody === undefined) {
      throw new runtime.RequiredError(
        'logInPostBody',
        'Required parameter requestParameters.logInPostBody was null or undefined when calling logIn.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/login`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LogInPostBodyToJSON(requestParameters.logInPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => RefreshTokenResponseBodyFromJSON(jsonValue));
  }

  /**
   * Log in to Timeweb 2.
   */
  async logIn(
    requestParameters: AuthenticationApiLogInRequest,
    initOverrides?: RequestInit,
  ): Promise<RefreshTokenResponseBody> {
    const response = await this.logInRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Log Timeweb 1 user in for the first time to Timeweb 2 and create new safe credentials for him/her.
   */
  async logInFirstTimeRaw(
    requestParameters: AuthenticationApiLogInFirstTimeRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.firstLogInPostBody === null || requestParameters.firstLogInPostBody === undefined) {
      throw new runtime.RequiredError(
        'firstLogInPostBody',
        'Required parameter requestParameters.firstLogInPostBody was null or undefined when calling logInFirstTime.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/first_login`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FirstLogInPostBodyToJSON(requestParameters.firstLogInPostBody),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Log Timeweb 1 user in for the first time to Timeweb 2 and create new safe credentials for him/her.
   */
  async logInFirstTime(
    requestParameters: AuthenticationApiLogInFirstTimeRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.logInFirstTimeRaw(requestParameters, initOverrides);
  }
}
