import React from 'react';
import { OfficePutBody, PricingModelWithAreas } from '../../../api';
import { Checkbox, IconButton, Table, TableBody, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import { Delete } from '../../../components/icons';
import { Add } from '@mui/icons-material';
import {
  officeCity,
  officeContactPerson,
  officeNameAndAddress,
  officeOrganizationSpecificId,
  phoneNumber,
  postalCode,
} from '../../../validation';
import { DenseTableCell, EmptyTableCell, HeaderTableCell, StyledButton } from './StyledComponents';
import { PricingModelPicker } from '../../../components/PricingModelPicker';

interface OfficeTableProps {
  offices: OfficePutBody[];
  onOfficesUpdate?: (offices: OfficePutBody[]) => void;
  pricingModels: PricingModelWithAreas[];
}

export const validateOffices = (offices: OfficePutBody[]): boolean => {
  for (const office of offices) {
    const isDisabled = Boolean(
      officeOrganizationSpecificId.validate(office.organization_specific_office_id).error ||
        officeNameAndAddress.validate(office.name).error ||
        officeNameAndAddress.validate(office.address).error ||
        postalCode.validate(office.postal_code).error ||
        officeCity.validate(office.city).error ||
        phoneNumber.validate(office.phone_number).error ||
        officeContactPerson.validate(office.contact_person_name).error,
    );
    if (isDisabled) {
      return isDisabled;
    }
  }
  return false;
};

export const OfficesTable: React.FC<OfficeTableProps> = ({ offices, onOfficesUpdate, pricingModels }) => {
  return (
    <FieldSet>
      <legend>Toimipaikat</legend>
      <Table className="offices_table" style={{ minWidth: 'fit-content' }}>
        <TableHead>
          <TableRow>
            <HeaderTableCell>Tunnus</HeaderTableCell>
            <HeaderTableCell>Nimi</HeaderTableCell>
            <HeaderTableCell>Osoite</HeaderTableCell>
            <HeaderTableCell>Postinumero</HeaderTableCell>
            <HeaderTableCell>Kaupunki</HeaderTableCell>
            <HeaderTableCell>Yhteyshenkilö</HeaderTableCell>
            <HeaderTableCell>Puhelinnumero</HeaderTableCell>
            <HeaderTableCell>Aktiivinen</HeaderTableCell>
            <HeaderTableCell>Muu kuljetusliike</HeaderTableCell>
            <HeaderTableCell>Oletushinnoittelumalli</HeaderTableCell>
            {/* Empty cell for actionbuttons */}
            <HeaderTableCell></HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offices.length === 0 ? (
            <TableRow>
              <EmptyTableCell colSpan={11}>Ei toimipaikkoja</EmptyTableCell>
            </TableRow>
          ) : (
            offices
              .sort((a, b) => {
                return a.id === undefined ? 1 : b.id === undefined ? -1 : (a.id || -1) > (b.id || -1) ? 1 : -1;
              })
              .map((office, index) => {
                return (
                  <TableRow key={index}>
                    <DenseTableCell>
                      <TextField
                        error={Boolean(
                          officeOrganizationSpecificId.validate(office.organization_specific_office_id).error,
                        )}
                        helperText={
                          officeOrganizationSpecificId.validate(office.organization_specific_office_id).error?.message
                        }
                        type="text"
                        name="office_organization_specific_office_id"
                        required={true}
                        onChange={(e) => {
                          const newArr = [...offices];
                          newArr[index].organization_specific_office_id = e.target.value;
                          onOfficesUpdate && onOfficesUpdate(newArr);
                        }}
                        value={office.organization_specific_office_id ?? ''}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      <TextField
                        error={Boolean(officeNameAndAddress.validate(office.name).error)}
                        helperText={officeNameAndAddress.validate(office.name).error?.message}
                        type="text"
                        name="office_name"
                        required={true}
                        onChange={(e) => {
                          const newArr = [...offices];
                          newArr[index].name = e.target.value;
                          onOfficesUpdate && onOfficesUpdate(newArr);
                        }}
                        value={office.name ?? ''}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      <TextField
                        error={Boolean(officeNameAndAddress.validate(office.address).error)}
                        helperText={officeNameAndAddress.validate(office.address).error?.message}
                        type="text"
                        name="office_address"
                        required={true}
                        onChange={(e) => {
                          const newArr = [...offices];
                          newArr[index].address = e.target.value;
                          onOfficesUpdate && onOfficesUpdate(newArr);
                        }}
                        value={office.address ?? ''}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      <TextField
                        error={Boolean(postalCode.validate(office.postal_code).error)}
                        helperText={postalCode.validate(office.postal_code).error?.message}
                        type="text"
                        name="office_postal_code"
                        required={true}
                        onChange={(e) => {
                          const newArr = [...offices];
                          newArr[index].postal_code = e.target.value;
                          onOfficesUpdate && onOfficesUpdate(newArr);
                        }}
                        value={office.postal_code ?? ''}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      <TextField
                        error={Boolean(officeCity.validate(office.city).error)}
                        helperText={officeCity.validate(office.city).error?.message}
                        type="text"
                        name="office_city"
                        required={true}
                        onChange={(e) => {
                          const newArr = [...offices];
                          newArr[index].city = e.target.value;
                          onOfficesUpdate && onOfficesUpdate(newArr);
                        }}
                        value={office.city ?? ''}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      <TextField
                        error={Boolean(officeContactPerson.validate(office.contact_person_name).error)}
                        helperText={officeContactPerson.validate(office.contact_person_name).error?.message}
                        type="text"
                        name="office_contact_person_name"
                        required={true}
                        onChange={(e) => {
                          const newArr = [...offices];
                          newArr[index].contact_person_name = e.target.value;
                          onOfficesUpdate && onOfficesUpdate(newArr);
                        }}
                        value={office.contact_person_name ?? ''}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      <TextField
                        error={Boolean(phoneNumber.validate(office.phone_number).error)}
                        helperText={phoneNumber.validate(office.phone_number).error?.message}
                        type="text"
                        name="office_phone_number"
                        required={true}
                        onChange={(e) => {
                          const newArr = [...offices];
                          newArr[index].phone_number = e.target.value;
                          onOfficesUpdate && onOfficesUpdate(newArr);
                        }}
                        value={office.phone_number ?? ''}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      <Checkbox
                        className="office_billing_enabled_checkbox"
                        name="office_billing_enabled"
                        checked={office.billing_enabled}
                        onChange={() => {
                          const newArr = [...offices];
                          newArr[index].billing_enabled = !newArr[index].billing_enabled;
                          onOfficesUpdate && onOfficesUpdate(newArr);
                        }}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      <Tooltip
                        title={
                          'Muu kuljetusliike -toimipaikan toimituksia ei näytetä ajojärjestelyssä eikä raporteissa.'
                        }
                        aria-label={'Muu kuljetusliike'}
                      >
                        <Checkbox
                          className="is_other_carrier_checkbox"
                          name="is_other_carrier"
                          checked={office.is_other_carrier}
                          onChange={() => {
                            const newArr = [...offices];
                            newArr[index].is_other_carrier = !newArr[index].is_other_carrier;
                            onOfficesUpdate && onOfficesUpdate(newArr);
                          }}
                        />
                      </Tooltip>
                    </DenseTableCell>
                    <DenseTableCell>
                      <PricingModelPicker
                        disabled={false}
                        label={''}
                        pricingModels={pricingModels}
                        value={
                          pricingModels.find((pricingModel) => pricingModel.id === office.default_pricing_model) ?? null
                        }
                        onChange={(pricingModel) => {
                          const newArr = [...offices];
                          newArr[index].default_pricing_model = pricingModel?.id ?? null;
                          onOfficesUpdate && onOfficesUpdate(newArr);
                        }}
                      />
                    </DenseTableCell>
                    <DenseTableCell>
                      {!office.id ? (
                        <IconButton
                          className="delete-office-button"
                          onClick={() => {
                            const newArr = [...offices];
                            newArr.splice(index, 1);
                            onOfficesUpdate && onOfficesUpdate(newArr);
                          }}
                          size="large"
                        >
                          <Delete />
                        </IconButton>
                      ) : null}
                    </DenseTableCell>
                  </TableRow>
                );
              })
          )}
        </TableBody>
      </Table>
      <StyledButton
        className="add_office_button"
        disabled={validateOffices(offices)}
        onClick={() => {
          const newArr = [...offices];
          newArr.push({
            organization_specific_office_id: '',
            name: '',
            address: '',
            postal_code: '',
            city: '',
            billing_enabled: true,
            phone_number: '',
            contact_person_name: '',
            default_pricing_model: null,
            is_other_carrier: false,
          });
          onOfficesUpdate && onOfficesUpdate(newArr);
        }}
        startIcon={<Add />}
      >
        Lisää Toimipaikka
      </StyledButton>
    </FieldSet>
  );
};

export default OfficesTable;
