import React, { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import TimecapLogo from '../../static/img/timecaplogo.png';
import TimecapLogoSmall from '../../static/img/timecaplogo_small.png';
import { Close, KeyboardArrowDown, Menu as MenuIcon } from '@mui/icons-material';
import theme from '../theme';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { isPwaOrDriverOnly } from '../utils';
import { useCurrentUser } from '../hooks/useCurrentUser';

const StyledNav = styled('nav')({
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
  flexGrow: 1,
});

const StyledImg = styled('img')({
  height: 52,
  verticalAlign: 'middle',
});

const StyledDivider = styled(Divider)({
  marginTop: '0.75rem',
  marginBottom: '0.75rem',
  flexGrow: 1,
});

const StyledProfileDivider = styled(Divider)({
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
});

const StyledButton = styled(Button)({
  textAlign: 'center',
  marginLeft: '1rem',
  minHeight: 64,
  borderRadius: 0,
});

const StyledMenuCloseButton = styled(Button)({
  height: 64,
  zIndex: 1,
  position: 'absolute',
  right: '1rem',
  [theme.breakpoints.up('sm')]: {
    right: '1.5rem',
  },
});

const StyledList = styled(List)({
  width: 'auto',
});

const StyledAvatar = styled(Avatar)({
  textTransform: 'uppercase',
  backgroundColor: theme.palette.primary.main,
});

export interface NavigationItem {
  name: string;
  path: string;
  id: string;
  isResourceMenu?: boolean;
  isProfileMenu?: boolean;
  isDriverMenu?: boolean;
}

interface LogoProps {
  isPwaOrDriverOnly: boolean;
}

const Logo: FunctionComponent<LogoProps> = ({ isPwaOrDriverOnly }) => {
  const frontPageLink = isPwaOrDriverOnly ? '/driver/shipments' : '/';
  return (
    <Link to={frontPageLink}>
      <picture>
        <source srcSet={TimecapLogo} media="(min-width: 600px)" />
        <source srcSet={TimecapLogoSmall} media="(max-width: 600px)" />

        <StyledImg alt="Timecap Logo" src={TimecapLogo} />
      </picture>
    </Link>
  );
};

interface NavigationProps {
  items: NavigationItem[];
}
const Navigation: FunctionComponent<NavigationProps> = ({ items }) => {
  const [open, setOpen] = React.useState(false);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [resourceMenuAnchorEl, setResourceMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const currentUser = useCurrentUser();
  const pwaOrDriverOnly = useMemo(() => isPwaOrDriverOnly(currentUser), [currentUser]);

  const normalNavItems = pwaOrDriverOnly
    ? []
    : items.filter((item) => !item.isResourceMenu && !item.isProfileMenu && !item.isDriverMenu);
  const resourceNavItems = items.filter((item) => item.isResourceMenu);
  const profileMenuItems = items.filter((item) => item.isProfileMenu);
  const driverMenuItems = pwaOrDriverOnly ? items.filter((item) => item.isDriverMenu) : [];
  const mobileItems = pwaOrDriverOnly ? profileMenuItems : items;

  const handleMobileMenuOpen = () => {
    setOpen(true);
  };

  const handleMobileMenuClosed = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClosed = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleResourceMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResourceMenuAnchorEl(event.currentTarget);
  };

  const handleResourceMenuClosed = () => {
    setResourceMenuAnchorEl(null);
  };

  const handleLogout = () => {
    window.location.href = '/logout';
  };

  return (
    <StyledNav id="navigation">
      <AppBar color="transparent" position="sticky">
        <Toolbar>
          <Logo isPwaOrDriverOnly={pwaOrDriverOnly} />
          <StyledDivider orientation="vertical" />

          {/* Show driver menu items. These should be visible on mobile too. */}
          {driverMenuItems.map((menuItem) => (
            <Link key={menuItem.name} style={{ color: 'inherit', textDecoration: 'inherit' }} to={menuItem.path}>
              <StyledButton variant="text" key={menuItem.name} id={menuItem.id}>
                {menuItem.name}
              </StyledButton>
            </Link>
          ))}

          {/* Show desktop menu */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {normalNavItems.map((menuItem) => (
              <Link key={menuItem.name} style={{ color: 'inherit', textDecoration: 'inherit' }} to={menuItem.path}>
                <StyledButton variant="text" key={menuItem.name} id={menuItem.id}>
                  {menuItem.name}
                </StyledButton>
              </Link>
            ))}
            {resourceNavItems.length > 0 ? (
              <StyledButton
                variant="text"
                className="resource-nav "
                onClick={handleResourceMenuOpen}
                endIcon={<KeyboardArrowDown />}
              >
                Resurssit
              </StyledButton>
            ) : null}
            {currentUser?.username && (
              <StyledButton variant="text" className="profile-nav" onClick={handleProfileMenuOpen}>
                <StyledAvatar>{currentUser?.username?.charAt(0)}</StyledAvatar>
              </StyledButton>
            )}
          </Box>

          {/* Show mobile menu */}
          {currentUser && (
            <Box sx={{ display: { md: 'none', xs: 'block' } }}>
              <StyledButton variant="text" onClick={handleMobileMenuOpen}>
                <MenuIcon />
              </StyledButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Mobile drawer menu */}
      <Drawer variant="temporary" anchor="top" open={open} onClose={handleMobileMenuClosed}>
        <StyledMenuCloseButton variant="text" onClick={handleMobileMenuClosed}>
          <Close />
        </StyledMenuCloseButton>
        <StyledList>
          {mobileItems.map((menuItem) => (
            <ListItemButton key={menuItem.name} component={Link} to={menuItem.path} onClick={handleMobileMenuClosed}>
              <ListItemText primary={menuItem.name} />
            </ListItemButton>
          ))}
          <StyledDivider />
          <ListItemButton onClick={handleLogout}>
            <ListItemText primary="Kirjaudu ulos" />
          </ListItemButton>
        </StyledList>
      </Drawer>

      {/* Profile dropdown menu */}
      <Menu
        anchorEl={profileMenuAnchorEl}
        keepMounted
        open={Boolean(profileMenuAnchorEl)}
        onClose={handleProfileMenuClosed}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {profileMenuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.name}
            component={Link}
            to={menuItem.path}
            id={menuItem.id}
            onClick={handleProfileMenuClosed}
          >
            {menuItem.name}
          </MenuItem>
        ))}
        <StyledProfileDivider />
        <MenuItem onClick={handleLogout}>Kirjaudu ulos</MenuItem>
      </Menu>

      {/* Resource dropdown menu */}
      <Menu
        anchorEl={resourceMenuAnchorEl}
        keepMounted
        open={Boolean(resourceMenuAnchorEl)}
        onClose={handleResourceMenuClosed}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {resourceNavItems.map((menuItem) => (
          <MenuItem
            key={menuItem.name}
            component={Link}
            to={menuItem.path}
            id={menuItem.id}
            onClick={handleResourceMenuClosed}
          >
            {menuItem.name}
          </MenuItem>
        ))}
      </Menu>
    </StyledNav>
  );
};

export default Navigation;
