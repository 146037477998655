import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Client } from '../api';

export interface ClientPickerProps {
  className?: string;
  clients: Client[];
  value: Client | null;
  onChange: (client: Client | null) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  style?: any;
  variant?: 'standard' | 'filled' | 'outlined';
  dataCy?: string;
}

export const ClientPicker: React.FC<ClientPickerProps> = ({
  className,
  disabled,
  clients,
  value,
  onChange,
  required,
  error,
  helperText,
  size,
  style,
  variant,
  dataCy,
}) => {
  return (
    <Autocomplete
      data-cy={dataCy}
      disabled={disabled}
      style={style}
      fullWidth
      className={'client-picker ' + className}
      options={clients.sort((client1, client2) => (client1.name.toLowerCase() > client2.name.toLowerCase() ? 1 : -1))}
      autoHighlight
      getOptionLabel={(client) => client.name ?? String(client.id)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, client) => <li {...props}>{client.name}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          variant={variant}
          error={error}
          helperText={helperText}
          label="Asiakas"
          required={required}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      value={value}
      onChange={(_event, client) => onChange(client)}
    />
  );
};
