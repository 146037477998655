/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PostOrganizationUserPasswordRequestBody
 */
export interface PostOrganizationUserPasswordRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostOrganizationUserPasswordRequestBody
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof PostOrganizationUserPasswordRequestBody
   */
  old_password?: string;
  /**
   *
   * @type {string}
   * @memberof PostOrganizationUserPasswordRequestBody
   */
  own_password?: string;
}

export function PostOrganizationUserPasswordRequestBodyFromJSON(json: any): PostOrganizationUserPasswordRequestBody {
  return PostOrganizationUserPasswordRequestBodyFromJSONTyped(json, false);
}

export function PostOrganizationUserPasswordRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PostOrganizationUserPasswordRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    password: json['password'],
    old_password: !exists(json, 'old_password') ? undefined : json['old_password'],
    own_password: !exists(json, 'own_password') ? undefined : json['own_password'],
  };
}

export function PostOrganizationUserPasswordRequestBodyToJSON(
  value?: PostOrganizationUserPasswordRequestBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    password: value.password,
    old_password: value.old_password,
    own_password: value.own_password,
  };
}
