import pwComplexity from 'joi-password-complexity';

export const passwordComplexity = pwComplexity({
  min: 8,
  max: 128,
  lowerCase: 1,
  upperCase: 1,
  numeric: 1,
  symbol: 1,
  requirementCount: 4,
});
