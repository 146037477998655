/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum GetShipmentIncludeEnum {
  Load = 'load',
  LoadDriver = 'load.driver',
  LoadCar = 'load.car',
  Organization = 'organization',
  OrganizationAdditionalServices = 'organization.additional_services',
  ShipmentAdditionalServices = 'shipment_additional_services',
  OrganizationOffices = 'organization.offices',
  Rows = 'rows',
  Photos = 'photos',
}

export function GetShipmentIncludeEnumFromJSON(json: any): GetShipmentIncludeEnum {
  return GetShipmentIncludeEnumFromJSONTyped(json, false);
}

export function GetShipmentIncludeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetShipmentIncludeEnum {
  return json as GetShipmentIncludeEnum;
}

export function GetShipmentIncludeEnumToJSON(value?: GetShipmentIncludeEnum | null): any {
  return value as any;
}
