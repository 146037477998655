/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PostOrganizationUserRequestBody
 */
export interface PostOrganizationUserRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostOrganizationUserRequestBody
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof PostOrganizationUserRequestBody
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof PostOrganizationUserRequestBody
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof PostOrganizationUserRequestBody
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PostOrganizationUserRequestBody
   */
  gsm?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PostOrganizationUserRequestBody
   */
  is_admin: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PostOrganizationUserRequestBody
   */
  is_coordinator: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PostOrganizationUserRequestBody
   */
  is_multi_organization: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PostOrganizationUserRequestBody
   */
  is_superuser: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PostOrganizationUserRequestBody
   */
  is_workshop: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PostOrganizationUserRequestBody
   */
  is_driver: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PostOrganizationUserRequestBody
   */
  is_manager: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PostOrganizationUserRequestBody
   */
  can_access_customer_report: boolean;
  /**
   *
   * @type {string}
   * @memberof PostOrganizationUserRequestBody
   */
  password: string;
  /**
   *
   * @type {number}
   * @memberof PostOrganizationUserRequestBody
   */
  driver_id?: number | null;
}

export function PostOrganizationUserRequestBodyFromJSON(json: any): PostOrganizationUserRequestBody {
  return PostOrganizationUserRequestBodyFromJSONTyped(json, false);
}

export function PostOrganizationUserRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PostOrganizationUserRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: json['username'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    gsm: !exists(json, 'gsm') ? undefined : json['gsm'],
    is_admin: json['is_admin'],
    is_coordinator: json['is_coordinator'],
    is_multi_organization: json['is_multi_organization'],
    is_superuser: json['is_superuser'],
    is_workshop: json['is_workshop'],
    is_driver: json['is_driver'],
    is_manager: json['is_manager'],
    can_access_customer_report: json['can_access_customer_report'],
    password: json['password'],
    driver_id: !exists(json, 'driver_id') ? undefined : json['driver_id'],
  };
}

export function PostOrganizationUserRequestBodyToJSON(value?: PostOrganizationUserRequestBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    first_name: value.first_name,
    last_name: value.last_name,
    email: value.email,
    gsm: value.gsm,
    is_admin: value.is_admin,
    is_coordinator: value.is_coordinator,
    is_multi_organization: value.is_multi_organization,
    is_superuser: value.is_superuser,
    is_workshop: value.is_workshop,
    is_driver: value.is_driver,
    is_manager: value.is_manager,
    can_access_customer_report: value.can_access_customer_report,
    password: value.password,
    driver_id: value.driver_id,
  };
}
