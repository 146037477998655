import { debounce } from 'lodash';
import { Dispatch } from 'react';
import {
  AdditionalServicePutBody,
  Assignment,
  AssignmentPutBody,
  OfficePutBody,
  Organization,
  PricingModelWithAreas,
} from '../../api';
import { validateFields } from './organizationValidation';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';

export interface Field<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value: Value;
}

type ServerFieldNames = keyof Pick<
  Organization,
  | 'is_active'
  | 'id'
  | 'name'
  | 'business_id'
  | 'send_delivery_notification_sms'
  | 'default_agreed_delivery_window_time_starts_at'
  | 'default_agreed_delivery_window_time_ends_at'
  | 'default_pricing_model'
>;

export type FieldName = ServerFieldNames;

export interface State {
  fields: {
    [P in ServerFieldNames]: Field<Organization[P]>;
  };
  originalOrganization?: Organization;
  isLoading: boolean;
  loadingMessage?: string;
  notification: NotificationType;
  isValid: boolean;
  additionalServices: AdditionalServicePutBody[];
  assignments: AssignmentPutBody[];
  offices: OfficePutBody[];
  allAssignments: Assignment[];
  allOrganizations: Organization[];
  pricingModels: PricingModelWithAreas[];
}

export type Action =
  | {
      type: 'VALIDATE_FIELD';
      payload: {
        fieldName: keyof State['fields'];
      };
    }
  | {
      type: 'VALIDATE_FIELDS';
    }
  | {
      type: 'SET_FIELD_VALUE';
      payload: {
        fieldName: keyof State['fields'];
        value: FieldValue;
      };
    }
  | SetMessageAction
  | SetLoadingAction
  | {
      type: 'initializeOrganization';
      payload: {
        organization: Organization;
        isNewOrganization?: boolean;
      };
    }
  | {
      type: 'setAdditionalServices';
      payload: {
        additionalServices: AdditionalServicePutBody[];
      };
    }
  | {
      type: 'setAssignments';
      payload: {
        assignments: AssignmentPutBody[];
      };
    }
  | {
      type: 'setOffices';
      payload: {
        offices: OfficePutBody[];
      };
    }
  | {
      type: 'SET_ALL_ASSIGNMENTS';
      payload: {
        allAssignments: Assignment[];
      };
    }
  | {
      type: 'SET_ALL_ORGANIZATIONS';
      payload: {
        allOrganizations: Organization[];
      };
    }
  | {
      type: 'SET_PRICING_MODELS';
      payload: {
        pricingModels: PricingModelWithAreas[];
      };
    };

const areAllFieldsValid = (state: Pick<State, 'fields'>) => {
  return !Object.values(state.fields).some((field) => field.hasError);
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD_VALUE': {
      const fields = {
        ...state.fields,
      };
      fields[action.payload.fieldName].value = action.payload.value;
      return {
        ...state,
        fields,
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'VALIDATE_FIELDS': {
      const validatedState = validateFields(state);
      return {
        ...state,
        ...validatedState,
        isValid: areAllFieldsValid(validatedState),
      };
    }
    case 'initializeOrganization': {
      const fields = {
        ...state.fields,
      };
      fields.is_active.value = action.payload.organization.is_active ?? true;
      fields.id.value = action.payload.organization.id ?? '';
      fields.name.value = action.payload.organization.name ?? '';
      fields.business_id.value = action.payload.organization.business_id ?? '';
      fields.send_delivery_notification_sms.value = action.payload.organization.send_delivery_notification_sms ?? false;
      fields.default_agreed_delivery_window_time_starts_at.value =
        action.payload.organization.default_agreed_delivery_window_time_starts_at ?? null;
      fields.default_agreed_delivery_window_time_ends_at.value =
        action.payload.organization.default_agreed_delivery_window_time_ends_at ?? null;
      fields.default_pricing_model.value = action.payload.organization.default_pricing_model ?? null;
      return {
        ...state,
        fields,
        originalOrganization: action.payload.organization as Organization,
        isValid: false,
      };
    }
    case 'setAdditionalServices': {
      return {
        ...state,
        additionalServices: action.payload.additionalServices,
      };
    }
    case 'setAssignments': {
      return {
        ...state,
        assignments: action.payload.assignments,
      };
    }
    case 'setOffices': {
      return {
        ...state,
        offices: action.payload.offices,
      };
    }
    case 'SET_ALL_ASSIGNMENTS': {
      return {
        ...state,
        allAssignments: action.payload.allAssignments,
      };
    }
    case 'SET_ALL_ORGANIZATIONS': {
      return {
        ...state,
        allOrganizations: action.payload.allOrganizations,
      };
    }
    case 'SET_PRICING_MODELS': {
      return {
        ...state,
        pricingModels: action.payload.pricingModels,
      };
    }
    default: {
      throw new Error(`Unhandled action ${JSON.stringify(action)}`);
    }
  }
};

type FieldValue = string | boolean | number | null;

export const debouncedValidateFieldsDispatch = debounce((dispatch: Dispatch<Action>) => {
  dispatch({
    type: 'VALIDATE_FIELDS',
  });
}, 500);

export const updateFieldValue = (fieldName: FieldName, value: FieldValue, dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_FIELD_VALUE',
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
  debouncedValidateFieldsDispatch(dispatch);
};

export function updateAdditionalServices(
  additionalServices: AdditionalServicePutBody[],
  dispatch: Dispatch<Action>,
): void {
  dispatch({
    type: 'setAdditionalServices',
    payload: {
      additionalServices,
    },
  });
}

export function updateAssignments(assignments: AssignmentPutBody[], dispatch: Dispatch<Action>): void {
  dispatch({
    type: 'setAssignments',
    payload: {
      assignments,
    },
  });
}

export function updateOffices(offices: OfficePutBody[], dispatch: Dispatch<Action>): void {
  dispatch({
    type: 'setOffices',
    payload: {
      offices,
    },
  });
}

export const getInitialState = (): State => {
  return {
    fields: {
      is_active: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: true,
      },
      id: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      name: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      business_id: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      send_delivery_notification_sms: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: false,
      },
      default_agreed_delivery_window_time_starts_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      default_agreed_delivery_window_time_ends_at: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      default_pricing_model: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: null,
      },
    },
    isLoading: false,
    notification: {
      message: null,
    },
    isValid: false,
    additionalServices: [],
    offices: [],
    assignments: [],
    allAssignments: [],
    allOrganizations: [],
    pricingModels: [],
  };
};
