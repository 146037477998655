import { Dispatch } from 'react';
import {
  Assignment,
  Driver,
  DriverWithWorkHoursAndTimeOffsProfessionEnum,
  Organization,
  TimeOff,
  WorkHour,
} from '../../api';
import { validateFields } from './workHourValidation';
import { pick } from 'lodash';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { DateRange, SetDateRangeAction, getDefaultDateRange } from '../../utils/dateRangeUtils';

export interface Field<Value> {
  required: boolean;
  hasError: boolean;
  feedback?: string;
  value: Value;
}

export type WorkHourWithOrganization = WorkHour & {
  organization_id: string | null;
};

export type FieldName = keyof Pick<
  WorkHourWithOrganization,
  'starts_at' | 'ends_at' | 'date' | 'employee_number' | 'assignment_id' | 'note' | 'organization_id'
>;

export type Fields = {
  [P in FieldName]: Field<WorkHourWithOrganization[P] | ''>;
};

type FormattedDriver = {
  id: Driver['id'];
  employee_number: Driver['employee_number'];
  employee_name: string;
  profession: DriverWithWorkHoursAndTimeOffsProfessionEnum;
};

export type WorkHourOrTimeOff = WorkHour & {
  organizationName: string | null;
  assignmentName: string | null;
  time_off_type: number;
  employee: string;
  overlaps: boolean;
  lunchBreak: string | null;
  dateIndex: number;
  enableEditing: boolean;
};

export type DriverWithWorkHoursAndTimeOffs = FormattedDriver & {
  work_hours: WorkHourOrTimeOff[];
  time_offs: TimeOff[];
};

export interface State {
  fields: Fields;
  dateRange: DateRange;
  isLoading: boolean;
  notification: NotificationType;
  isValid: boolean;
  driverWithWorkHoursAndTimeOffs: DriverWithWorkHoursAndTimeOffs[];
  assignments: Assignment[];
  organizations: Organization[];
  hideEmpty: boolean;
  createNew: boolean;
}

export type FieldValue = Date | string | number | null;

export type Action =
  | {
      type: 'VALIDATE_FIELD';
      payload: {
        fieldName: keyof State['fields'];
      };
    }
  | {
      type: 'VALIDATE_FIELDS';
    }
  | {
      type: 'SET_FIELD_VALUE';
      payload: {
        fieldName: keyof State['fields'];
        value: FieldValue;
      };
    }
  | SetMessageAction
  | SetLoadingAction
  | {
      type: 'INITIALIZE';
      payload: {
        driverWithWorkHoursAndTimeOffs: DriverWithWorkHoursAndTimeOffs[];
        organizations: Organization[];
        assignments: Assignment[];
      };
    }
  | SetDateRangeAction
  | {
      type: 'SET_HIDE_EMPTY';
      payload: {
        hideEmpty: boolean;
      };
    }
  | {
      type: 'SET_CREATE_NEW';
      payload: {
        createNew: boolean;
      };
    }
  | {
      type: 'CLEAR_FIELDS';
    };

export const areAllFieldsValid = (state: Pick<State, 'fields'>, createNew: boolean) => {
  if (createNew) {
    return !Object.values(state.fields).some((field) => field.hasError);
  } else {
    return !Object.values(pick(state.fields, ['starts_at', 'ends_at'])).some((field) => field.hasError);
  }
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FIELD_VALUE': {
      const fields = {
        ...state.fields,
      };
      fields[action.payload.fieldName].value = action.payload.value;
      return {
        ...state,
        fields,
      };
    }
    case 'SET_MESSAGE': {
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'VALIDATE_FIELDS': {
      const validatedState = validateFields(state.fields);
      return {
        ...state,
        ...validatedState,
        isValid: areAllFieldsValid(validatedState, state.createNew),
      };
    }
    case 'INITIALIZE': {
      const fields = {
        ...state.fields,
      };
      return {
        ...state,
        fields,
        isValid: false,
        driverWithWorkHoursAndTimeOffs: action.payload.driverWithWorkHoursAndTimeOffs
          .filter(
            (driver) =>
              //drivers are filtered out based on original PHP code.
              (driver.profession === DriverWithWorkHoursAndTimeOffsProfessionEnum.Kuski ||
                driver.profession === DriverWithWorkHoursAndTimeOffsProfessionEnum.Appari ||
                driver.profession === DriverWithWorkHoursAndTimeOffsProfessionEnum.Asentaja) &&
              driver.employee_number,
          )
          .map((driver) => {
            return {
              ...driver,
              work_hours: driver.work_hours.map((w) => {
                const assignment = action.payload.assignments.find(
                  (assignment) => assignment.assignment_specific_id === w.assignment_id,
                );
                return {
                  ...w,
                  assignmentName: assignment?.description ?? '',
                  organizationName:
                    action.payload.organizations.find((o) => o.id === assignment?.organization_id)?.name ?? '',
                };
              }),
            };
          }),
        organizations: action.payload.organizations,
        assignments: action.payload.assignments,
      };
    }
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };

    case 'SET_HIDE_EMPTY':
      return {
        ...state,
        hideEmpty: action.payload.hideEmpty,
      };
    case 'SET_CREATE_NEW':
      return {
        ...state,
        createNew: action.payload.createNew,
      };
    case 'CLEAR_FIELDS': {
      const fields = {
        ...getInitialState().fields,
      };
      return {
        ...state,
        fields,
      };
    }

    default: {
      throw new Error(`Unhandled action ${JSON.stringify(action)}`);
    }
  }
};

export const updateFieldValue = (fieldName: FieldName, value: FieldValue, dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_FIELD_VALUE',
    payload: {
      fieldName: fieldName,
      value: value,
    },
  });
  dispatch({
    type: 'VALIDATE_FIELDS',
  });
};

export const getInitialState = (): State => {
  return {
    fields: {
      date: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      employee_number: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      assignment_id: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      note: {
        required: false,
        hasError: false,
        feedback: undefined,
        value: '',
      },
      starts_at: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      ends_at: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
      },
      organization_id: {
        required: true,
        hasError: false,
        feedback: undefined,
        value: null,
      },
    },
    dateRange: getDefaultDateRange(),
    isLoading: false,
    notification: {
      message: null,
    },
    isValid: false,
    driverWithWorkHoursAndTimeOffs: [],
    assignments: [],
    organizations: [],
    hideEmpty: false,
    createNew: false,
  };
};
