/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Shipment, ShipmentFromJSON, ShipmentFromJSONTyped, ShipmentToJSON } from './Shipment';
import {
  ShipmentRelated,
  ShipmentRelatedFromJSON,
  ShipmentRelatedFromJSONTyped,
  ShipmentRelatedToJSON,
} from './ShipmentRelated';

/**
 *
 * @export
 * @interface ShipmentResponseBody1
 */
export interface ShipmentResponseBody1 {
  /**
   *
   * @type {Shipment}
   * @memberof ShipmentResponseBody1
   */
  data: Shipment;
  /**
   *
   * @type {ShipmentRelated}
   * @memberof ShipmentResponseBody1
   */
  related?: ShipmentRelated;
}

export function ShipmentResponseBody1FromJSON(json: any): ShipmentResponseBody1 {
  return ShipmentResponseBody1FromJSONTyped(json, false);
}

export function ShipmentResponseBody1FromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentResponseBody1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: ShipmentFromJSON(json['data']),
    related: !exists(json, 'related') ? undefined : ShipmentRelatedFromJSON(json['related']),
  };
}

export function ShipmentResponseBody1ToJSON(value?: ShipmentResponseBody1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ShipmentToJSON(value.data),
    related: ShipmentRelatedToJSON(value.related),
  };
}
