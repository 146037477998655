/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  PricingModelResponseBody,
  PricingModelResponseBodyFromJSON,
  PricingModelResponseBodyToJSON,
  PricingModelWithAreasPostBody,
  PricingModelWithAreasPostBodyFromJSON,
  PricingModelWithAreasPostBodyToJSON,
  PricingModelWithAreasPutBody,
  PricingModelWithAreasPutBodyFromJSON,
  PricingModelWithAreasPutBodyToJSON,
  PricingModelsResponseBody,
  PricingModelsResponseBodyFromJSON,
  PricingModelsResponseBodyToJSON,
  ShipmentPricingPostBody,
  ShipmentPricingPostBodyFromJSON,
  ShipmentPricingPostBodyToJSON,
} from '../models';

export interface PricingApiCalculateShipmentPricingRequest {
  shipmentPricingPostBody: ShipmentPricingPostBody;
}

export interface PricingApiCreatePricingModelRequest {
  pricingModelWithAreasPostBody: PricingModelWithAreasPostBody;
}

export interface PricingApiGetPricingModelRequest {
  pricingModelId: number;
}

export interface PricingApiUpdatePricingModelRequest {
  pricingModelId: number;
  pricingModelWithAreasPutBody: PricingModelWithAreasPutBody;
}

/**
 *
 */
export class PricingApi extends runtime.BaseAPI {
  /**
   * Calculate shipment pricing
   */
  async calculateShipmentPricingRaw(
    requestParameters: PricingApiCalculateShipmentPricingRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.shipmentPricingPostBody === null || requestParameters.shipmentPricingPostBody === undefined) {
      throw new runtime.RequiredError(
        'shipmentPricingPostBody',
        'Required parameter requestParameters.shipmentPricingPostBody was null or undefined when calling calculateShipmentPricing.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/pricing/shipments`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ShipmentPricingPostBodyToJSON(requestParameters.shipmentPricingPostBody),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Calculate shipment pricing
   */
  async calculateShipmentPricing(
    requestParameters: PricingApiCalculateShipmentPricingRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.calculateShipmentPricingRaw(requestParameters, initOverrides);
  }

  /**
   * Create a new pricing model
   */
  async createPricingModelRaw(
    requestParameters: PricingApiCreatePricingModelRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PricingModelResponseBody>> {
    if (
      requestParameters.pricingModelWithAreasPostBody === null ||
      requestParameters.pricingModelWithAreasPostBody === undefined
    ) {
      throw new runtime.RequiredError(
        'pricingModelWithAreasPostBody',
        'Required parameter requestParameters.pricingModelWithAreasPostBody was null or undefined when calling createPricingModel.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/pricing/pricing_models`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PricingModelWithAreasPostBodyToJSON(requestParameters.pricingModelWithAreasPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PricingModelResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create a new pricing model
   */
  async createPricingModel(
    requestParameters: PricingApiCreatePricingModelRequest,
    initOverrides?: RequestInit,
  ): Promise<PricingModelResponseBody> {
    const response = await this.createPricingModelRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a pricing model by id.
   */
  async getPricingModelRaw(
    requestParameters: PricingApiGetPricingModelRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PricingModelResponseBody>> {
    if (requestParameters.pricingModelId === null || requestParameters.pricingModelId === undefined) {
      throw new runtime.RequiredError(
        'pricingModelId',
        'Required parameter requestParameters.pricingModelId was null or undefined when calling getPricingModel.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/pricing/pricing_models/{pricing_model_id}`.replace(
          `{${'pricing_model_id'}}`,
          encodeURIComponent(String(requestParameters.pricingModelId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PricingModelResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a pricing model by id.
   */
  async getPricingModel(
    requestParameters: PricingApiGetPricingModelRequest,
    initOverrides?: RequestInit,
  ): Promise<PricingModelResponseBody> {
    const response = await this.getPricingModelRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all pricing models
   */
  async getPricingModelsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PricingModelsResponseBody>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/pricing/pricing_models`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PricingModelsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all pricing models
   */
  async getPricingModels(initOverrides?: RequestInit): Promise<PricingModelsResponseBody> {
    const response = await this.getPricingModelsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Update a pricing model by id
   */
  async updatePricingModelRaw(
    requestParameters: PricingApiUpdatePricingModelRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PricingModelResponseBody>> {
    if (requestParameters.pricingModelId === null || requestParameters.pricingModelId === undefined) {
      throw new runtime.RequiredError(
        'pricingModelId',
        'Required parameter requestParameters.pricingModelId was null or undefined when calling updatePricingModel.',
      );
    }

    if (
      requestParameters.pricingModelWithAreasPutBody === null ||
      requestParameters.pricingModelWithAreasPutBody === undefined
    ) {
      throw new runtime.RequiredError(
        'pricingModelWithAreasPutBody',
        'Required parameter requestParameters.pricingModelWithAreasPutBody was null or undefined when calling updatePricingModel.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/pricing/pricing_models/{pricing_model_id}`.replace(
          `{${'pricing_model_id'}}`,
          encodeURIComponent(String(requestParameters.pricingModelId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PricingModelWithAreasPutBodyToJSON(requestParameters.pricingModelWithAreasPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PricingModelResponseBodyFromJSON(jsonValue));
  }

  /**
   * Update a pricing model by id
   */
  async updatePricingModel(
    requestParameters: PricingApiUpdatePricingModelRequest,
    initOverrides?: RequestInit,
  ): Promise<PricingModelResponseBody> {
    const response = await this.updatePricingModelRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
