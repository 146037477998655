/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PatchOrganizationScheduleShipmentPatchBody
 */
export interface PatchOrganizationScheduleShipmentPatchBody {
  /**
   *
   * @type {number}
   * @memberof PatchOrganizationScheduleShipmentPatchBody
   */
  planned_unload_hour?: number | null;
  /**
   *
   * @type {Date}
   * @memberof PatchOrganizationScheduleShipmentPatchBody
   */
  pickup_window_date?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof PatchOrganizationScheduleShipmentPatchBody
   */
  delivery_window_date?: Date | null;
}

export function PatchOrganizationScheduleShipmentPatchBodyFromJSON(
  json: any,
): PatchOrganizationScheduleShipmentPatchBody {
  return PatchOrganizationScheduleShipmentPatchBodyFromJSONTyped(json, false);
}

export function PatchOrganizationScheduleShipmentPatchBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchOrganizationScheduleShipmentPatchBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    planned_unload_hour: !exists(json, 'planned_unload_hour') ? undefined : json['planned_unload_hour'],
    pickup_window_date: !exists(json, 'pickup_window_date')
      ? undefined
      : json['pickup_window_date'] === null
        ? null
        : new Date(json['pickup_window_date']),
    delivery_window_date: !exists(json, 'delivery_window_date')
      ? undefined
      : json['delivery_window_date'] === null
        ? null
        : new Date(json['delivery_window_date']),
  };
}

export function PatchOrganizationScheduleShipmentPatchBodyToJSON(
  value?: PatchOrganizationScheduleShipmentPatchBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    planned_unload_hour: value.planned_unload_hour,
    pickup_window_date:
      value.pickup_window_date === undefined
        ? undefined
        : value.pickup_window_date === null
          ? null
          : value.pickup_window_date.toISOString().substr(0, 10),
    delivery_window_date:
      value.delivery_window_date === undefined
        ? undefined
        : value.delivery_window_date === null
          ? null
          : value.delivery_window_date.toISOString().substr(0, 10),
  };
}
