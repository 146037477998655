/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AdditionalServicePutBody
 */
export interface AdditionalServicePutBody {
  /**
   *
   * @type {number}
   * @memberof AdditionalServicePutBody
   */
  readonly id?: number;
  /**
   *
   * @type {string}
   * @memberof AdditionalServicePutBody
   */
  readonly organization_id?: string;
  /**
   *
   * @type {string}
   * @memberof AdditionalServicePutBody
   */
  name: string | null;
  /**
   *
   * @type {number}
   * @memberof AdditionalServicePutBody
   */
  price: number | null;
  /**
   *
   * @type {number}
   * @memberof AdditionalServicePutBody
   */
  work_price: number | null;
  /**
   *
   * @type {boolean}
   * @memberof AdditionalServicePutBody
   */
  archived: boolean;
}

export function AdditionalServicePutBodyFromJSON(json: any): AdditionalServicePutBody {
  return AdditionalServicePutBodyFromJSONTyped(json, false);
}

export function AdditionalServicePutBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AdditionalServicePutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    organization_id: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    name: json['name'],
    price: json['price'],
    work_price: json['work_price'],
    archived: json['archived'],
  };
}

export function AdditionalServicePutBodyToJSON(value?: AdditionalServicePutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    price: value.price,
    work_price: value.work_price,
    archived: value.archived,
  };
}
