import React, { Dispatch, FunctionComponent } from 'react';
import { Alert, AlertProps, Snackbar, SnackbarProps } from '@mui/material';

export type NotificationType = { message: string | null; severity?: AlertProps['severity'] };
export type SetMessageAction = {
  type: 'SET_MESSAGE';
  payload: { message: string | null; severity?: AlertProps['severity'] };
};

type SnackbarState = { notification: { message: string | null; severity?: AlertProps['severity'] } };
export type SnackbarPropsWithSeverity = SnackbarProps & { severity?: AlertProps['severity'] };

export const getSnackbarPropsFromState = (
  state: SnackbarState,
  dispatch: Dispatch<SetMessageAction>,
): SnackbarPropsWithSeverity => {
  return {
    onClose: (): void =>
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          message: null,
          severity: 'success',
        },
      }),
    open: state.notification.message !== null,
    message: state.notification.message,
    key: state.notification.message,
    severity: state.notification.severity,
  };
};

export const checkFieldsMessage: SetMessageAction = {
  type: 'SET_MESSAGE',
  payload: { message: 'Tallennus epäonnistui. Tarkista kentät.', severity: 'warning' },
};

export const tryAgainMessage: SetMessageAction = {
  type: 'SET_MESSAGE',
  payload: { message: 'Tallennus epäonnistui. Yritä uudelleen.', severity: 'warning' },
};

const Notification: FunctionComponent<SnackbarPropsWithSeverity> = (props: SnackbarPropsWithSeverity) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className="notification"
      open={props.open}
      autoHideDuration={5000}
      onClose={props.onClose}
      {...props}
    >
      <Alert severity={props.severity ? props.severity : 'success'} variant="filled">
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
