import { styled } from '@mui/material';
import theme from '../../theme';

export const StyledForm = styled('form')({
  width: '100%',
  display: 'flex',
  flexFlow: 'column nowrap',
});

export const StyledSearchForm = styled(StyledForm)({
  maxWidth: '20rem',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
});
