import { Box, Button, Divider, IconButton, List, Menu, MenuItem, styled, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { dateFormat } from '../../formatters';
import { ArrowForward, DateRange, Refresh } from '@mui/icons-material';
import { DateCalendar, DateField } from '@mui/x-date-pickers';
import { debounce } from 'lodash';
import { getSalaryPeriod } from '../../utils/dateRangeUtils';
import { DateRangePickerProps, DatePickerContainer, renderDay } from '../DateRangePicker';

const MainContainer = styled('div')({
  alignItems: 'right',
  display: 'flex',
  justifyContent: 'flex-start',
});

export interface MRTDateRangePickerProps extends DateRangePickerProps {
  salaryPeriodDateRange?: boolean;
  billingSeasonRange?: boolean;
}

export const DateRangePicker: React.FC<MRTDateRangePickerProps> = ({
  onValue,
  startDate,
  endDate,
  disabled,
  salaryPeriodDateRange,
  billingSeasonRange,
}) => {
  const [dateRangeMenuStartDate, setDateRangeMenuStartDate] = React.useState<DateTime | null>(startDate);
  const [dateRangeMenuEndDate, setDateRangeMenuEndDate] = React.useState<DateTime | null>(endDate);

  useEffect(() => {
    setDateRangeMenuStartDate(startDate);
    setDateRangeMenuEndDate(endDate);
  }, [startDate, endDate]);

  const handleDateRangeChange = (startDate: DateTime | null, endDate: DateTime | null) => {
    if (
      startDate === undefined ||
      startDate === null ||
      !startDate.isValid ||
      endDate === undefined ||
      endDate === null ||
      !endDate.isValid ||
      startDate > endDate
    ) {
      return;
    }
    setDateRangeMenuStartDate(startDate);
    setDateRangeMenuEndDate(endDate);
    onValue(startDate.startOf('day'), endDate.endOf('day'));
  };

  const debouncedHandleKeyboardDateRangeChange = debounce((startDate: DateTime | null, endDate: DateTime | null) => {
    handleDateRangeChange(startDate, endDate);
  }, 750);

  const handleDateRangeChangePreset = (preset: string) => {
    let startDate: DateTime | undefined;
    let endDate: DateTime | undefined;
    switch (preset) {
      case 'yesterday': {
        startDate = DateTime.local().minus({ days: 1 });
        endDate = startDate;
        break;
      }
      case 'today': {
        startDate = DateTime.local();
        endDate = startDate;
        break;
      }
      case 'tomorrow': {
        startDate = DateTime.local().plus({ days: 1 });
        endDate = startDate;
        break;
      }
      case 'last30Days': {
        startDate = DateTime.local().minus({ days: 29 });
        endDate = DateTime.local();
        break;
      }
      case 'lastMonth': {
        startDate = DateTime.local().minus({ months: 1 }).startOf('month');
        endDate = DateTime.local().minus({ months: 1 }).endOf('month');
        break;
      }
      case 'currentBillingSeason': {
        if (DateTime.local().day <= 15) {
          startDate = DateTime.local().startOf('month');
          endDate = DateTime.local().set({ day: 15 });
        } else {
          startDate = DateTime.local().set({ day: 16 });
          endDate = DateTime.local().endOf('month');
        }
        break;
      }
      case 'lastBillingSeason': {
        if (DateTime.local().day > 15) {
          startDate = DateTime.local().startOf('month');
          endDate = DateTime.local().set({ day: 15 });
        } else {
          startDate = DateTime.local().minus({ months: 1 }).set({ day: 16 });
          endDate = DateTime.local().minus({ months: 1 }).endOf('month');
        }
        break;
      }
      case 'currentSalaryPeriod': {
        [startDate, endDate] = getSalaryPeriod(DateTime.now());
        break;
      }
      case 'lastSalaryPeriod': {
        [startDate, endDate] = getSalaryPeriod(DateTime.now().minus({ days: 14 }));
        break;
      }
    }
    if (startDate && endDate) {
      setDateRangeMenuStartDate(startDate);
      setDateRangeMenuEndDate(endDate);
      onValue(startDate.startOf('day'), endDate.endOf('day'));
      handleQuickMenuClose();
    }
  };

  const handleDateRangeMenuChange = (startDate: DateTime | null, endDate: DateTime | null) => {
    setDateRangeMenuStartDate(startDate);
    setDateRangeMenuEndDate(endDate);
  };

  const handleDateRangeMenuOk = () => {
    if (dateRangeMenuStartDate !== null && dateRangeMenuEndDate !== null) {
      handleDateRangeMenuClose();
      onValue(dateRangeMenuStartDate.startOf('day'), dateRangeMenuEndDate.endOf('day'));
    }
  };

  const [quickMenuAnchorEl, setQuickMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const quickMenuOpen = Boolean(quickMenuAnchorEl);
  const handleQuickMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setQuickMenuAnchorEl(event.currentTarget);
  };
  const handleQuickMenuClose = () => {
    setQuickMenuAnchorEl(null);
  };

  const [dateRangeMenuAnchorEl, setDateRangeMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const dateRangeMenuOpen = Boolean(dateRangeMenuAnchorEl);
  const handleDateRangeMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setDateRangeMenuAnchorEl(event.currentTarget);
  };
  const handleDateRangeMenuClose = () => {
    setDateRangeMenuAnchorEl(null);
  };

  return (
    <MainContainer data-cy="date-range-picker">
      <Tooltip title="Päivitä taulukko">
        <span>
          <IconButton disabled={disabled} onClick={() => handleDateRangeChange(startDate, endDate)}>
            <Refresh />
          </IconButton>
        </span>
      </Tooltip>
      <DateField
        disabled={disabled}
        sx={{ width: '5.25rem', justifyContent: 'center' }}
        format={dateFormat}
        maxDate={endDate ?? undefined}
        value={startDate}
        onChange={(newStartDate) => debouncedHandleKeyboardDateRangeChange(newStartDate, endDate)}
        variant="standard"
        slotProps={{
          textField: {
            name: 'date_range_start_picker',
            InputProps: {
              disableUnderline: true,
            },
          },
        }}
      />
      <Tooltip title="Valitse aloitus- ja lopetuspäivämäärä">
        <span>
          <IconButton
            disabled={disabled}
            data-cy="date-range-menu-button"
            aria-controls={dateRangeMenuOpen ? 'date-range-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={dateRangeMenuOpen ? 'true' : undefined}
            onClick={handleDateRangeMenuClick}
          >
            <ArrowForward />
          </IconButton>
        </span>
      </Tooltip>
      <DateField
        disabled={disabled}
        sx={{ width: '5.25rem', justifyContent: 'center' }}
        format={dateFormat}
        minDate={startDate ?? undefined}
        value={endDate}
        onChange={(newEndDate) => debouncedHandleKeyboardDateRangeChange(startDate, newEndDate)}
        variant="standard"
        slotProps={{
          textField: {
            name: 'date_range_end_picker',
            InputProps: { disableUnderline: true },
          },
        }}
      />
      <Menu
        data-cy="date-range-menu"
        aria-labelledby="date-range-menu"
        anchorEl={dateRangeMenuAnchorEl}
        open={dateRangeMenuOpen}
        onClose={handleDateRangeMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <DatePickerContainer>
            <Typography>Aloituspvm</Typography>
            <DateCalendar
              data-cy="date-range-menu-start-date"
              disabled={disabled}
              maxDate={dateRangeMenuEndDate ?? undefined}
              value={dateRangeMenuStartDate}
              onChange={(newStartDate) => handleDateRangeMenuChange(newStartDate, dateRangeMenuEndDate)}
              slots={{
                day: renderDay,
              }}
              slotProps={{
                day: {
                  dialogstartdate: dateRangeMenuStartDate,
                  dialogenddate: dateRangeMenuEndDate,
                } as any,
              }}
            />
          </DatePickerContainer>
          <DatePickerContainer>
            <Typography>Lopetuspvm</Typography>
            <DateCalendar
              data-cy="date-range-menu-end-date"
              disabled={disabled}
              minDate={dateRangeMenuStartDate ?? undefined}
              value={dateRangeMenuEndDate}
              onChange={(newEndDate) => handleDateRangeMenuChange(dateRangeMenuStartDate, newEndDate)}
              slots={{
                day: renderDay,
              }}
              slotProps={{
                day: {
                  dialogstartdate: dateRangeMenuStartDate,
                  dialogenddate: dateRangeMenuEndDate,
                } as any,
              }}
            />
          </DatePickerContainer>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleDateRangeMenuClose} variant="text" data-cy="date-range-picker-cancel-button">
            Peruuta
          </Button>
          <Button onClick={handleDateRangeMenuOk} variant="text" data-cy="date-range-picker-ok-button">
            Ok
          </Button>
        </Box>
      </Menu>

      <Tooltip title="Pikavalinta">
        <span>
          <IconButton
            disabled={disabled}
            data-cy="date-range-quick-menu-button"
            aria-controls={quickMenuOpen ? 'date-range-quick-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={quickMenuOpen ? 'true' : undefined}
            onClick={handleQuickMenuClick}
          >
            <DateRange />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        data-cy="date-range-quick-menu"
        aria-labelledby="date-range-quick-menu"
        anchorEl={quickMenuAnchorEl}
        open={quickMenuOpen}
        onClose={handleQuickMenuClose}
      >
        <Box sx={{ display: 'flex' }}>
          <List dense data-cy="date-range-quick-menu-list">
            <MenuItem onClick={() => handleDateRangeChangePreset('yesterday')}>Eilen</MenuItem>
            <MenuItem onClick={() => handleDateRangeChangePreset('today')}>Tänään</MenuItem>
            <MenuItem onClick={() => handleDateRangeChangePreset('tomorrow')}>Huomenna</MenuItem>
            <Divider />
            <MenuItem onClick={() => handleDateRangeChangePreset('last30Days')}>Viimeiset 30 päivää</MenuItem>
            <MenuItem onClick={() => handleDateRangeChangePreset('lastMonth')}>Viime kuukausi</MenuItem>
            {billingSeasonRange && (
              <>
                <Divider />
                <MenuItem onClick={() => handleDateRangeChangePreset('currentBillingSeason')}>
                  Kuluva laskutuskausi
                </MenuItem>
                <MenuItem onClick={() => handleDateRangeChangePreset('lastBillingSeason')}>
                  Viime laskutuskausi
                </MenuItem>
              </>
            )}
            {salaryPeriodDateRange && (
              <>
                <Divider />
                <MenuItem onClick={() => handleDateRangeChangePreset('currentSalaryPeriod')}>
                  Kuluva palkkajakso
                </MenuItem>
                <MenuItem onClick={() => handleDateRangeChangePreset('lastSalaryPeriod')}>Viime palkkajakso</MenuItem>
              </>
            )}
          </List>

          <DateCalendar
            onChange={(newDate) => {
              handleDateRangeChange(DateTime.fromISO(newDate as string), DateTime.fromISO(newDate as string));
              handleQuickMenuClose();
            }}
          />
        </Box>
      </Menu>
    </MainContainer>
  );
};
