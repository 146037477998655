import React, { FunctionComponent, useEffect, useReducer } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { api } from '../api';
import TimecapLogo from '../../static/img/timecaplogo.png';
import { CardMedia, Link, styled, TextField } from '@mui/material';

export const LoginCard = styled(Card)(() => ({
  margin: 'auto',
  textAlign: 'center',
  maxWidth: 500,
  minWidth: 275,
}));

export const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: 'center',
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: 14,
  marginTop: 32,
}));

type State = {
  username: string;
  password: string;
  isButtonDisabled: boolean;
  helperText: string;
  isError: boolean;
};

const initialState: State = {
  username: '',
  password: '',
  isButtonDisabled: true,
  helperText: '',
  isError: false,
};

type Action =
  | { type: 'SET_USERNAME'; payload: string }
  | { type: 'SET_PASSWORD'; payload: string }
  | { type: 'SET_IS_BUTTON_DISABLED'; payload: boolean }
  | { type: 'LOGIN_SUCCESS' }
  | { type: 'LOGIN_FAILED'; payload: string }
  | { type: 'SET_IS_ERROR'; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_USERNAME':
      return {
        ...state,
        username: action.payload,
      };
    case 'SET_PASSWORD':
      return {
        ...state,
        password: action.payload,
      };
    case 'SET_IS_BUTTON_DISABLED':
      return {
        ...state,
        isButtonDisabled: action.payload,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isError: false,
      };
    case 'LOGIN_FAILED':
      return {
        ...state,
        helperText: action.payload,
        isError: true,
      };
    case 'SET_IS_ERROR':
      return {
        ...state,
        isError: action.payload,
      };
  }
};

const Login: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.username.trim() && state.password.trim()) {
      dispatch({
        type: 'SET_IS_BUTTON_DISABLED',
        payload: false,
      });
    } else {
      dispatch({
        type: 'SET_IS_BUTTON_DISABLED',
        payload: true,
      });
    }
  }, [state.username, state.password]);

  const handleLogin = async () => {
    try {
      dispatch({ type: 'SET_IS_BUTTON_DISABLED', payload: true });
      await api.auth.logIn({
        logInPostBody: {
          username: state.username,
          password: state.password,
        },
      });
      dispatch({
        type: 'LOGIN_SUCCESS',
      });
      location.href = '/';
    } catch (err) {
      console.error(err);
      const status = (err as any)?.status;
      if (status === 401) {
        dispatch({
          type: 'LOGIN_FAILED',
          payload: 'Väärä käyttäjätunnus tai salasana',
        });
      } else if (status === 429) {
        dispatch({
          type: 'LOGIN_FAILED',
          payload: 'Epäonnistuit kirjautumisessa liian monta kertaa. Ota yhteyttä asiakaspalveluun.',
        });
      }
      dispatch({ type: 'SET_IS_BUTTON_DISABLED', payload: false });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      state.isButtonDisabled || handleLogin();
    }
  };

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({
      type: 'SET_USERNAME',
      payload: event.target.value,
    });
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({
      type: 'SET_PASSWORD',
      payload: event.target.value,
    });
  };

  return (
    <form noValidate autoComplete="off" style={{ padding: '1rem' }}>
      <LoginCard>
        <CardContent>
          <CardMedia component="img" alt="Timecap Logo" image={TimecapLogo} />
          <CardHeader title="Kuljetusjärjestelmä" />
          <TextField
            autoComplete="new-password"
            variant="outlined"
            error={state.isError}
            fullWidth
            id="username"
            label="Käyttäjätunnus"
            placeholder="Käyttäjätunnus"
            margin="normal"
            onChange={handleUsernameChange}
            onKeyPress={handleKeyPress}
          />
          <TextField
            autoComplete="new-password"
            variant="outlined"
            error={state.isError}
            fullWidth
            id="password"
            type="password"
            label="Salasana"
            placeholder="Salasana"
            margin="normal"
            helperText={state.helperText}
            onChange={handlePasswordChange}
            onKeyPress={handleKeyPress}
          />
          <StyledCardActions>
            <Button
              className="login-button"
              size="large"
              color="primary"
              onClick={handleLogin}
              disabled={state.isButtonDisabled}
            >
              Kirjaudu
            </Button>
          </StyledCardActions>
          <StyledTypography>
            <Link component={RouterLink} to="/first_login">
              Oletko Timeweb 1.0 käyttäjä ja haluat ottaa Timeweb 2.0:n käyttöön?
            </Link>
          </StyledTypography>
        </CardContent>
      </LoginCard>
    </form>
  );
};

export default Login;
