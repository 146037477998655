/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AssignmentPostBody,
  AssignmentPostBodyFromJSON,
  AssignmentPostBodyToJSON,
  AssignmentPutBody,
  AssignmentPutBodyFromJSON,
  AssignmentPutBodyToJSON,
  AssignmentResponseBody,
  AssignmentResponseBodyFromJSON,
  AssignmentResponseBodyToJSON,
  AssignmentResponseBody1,
  AssignmentResponseBody1FromJSON,
  AssignmentResponseBody1ToJSON,
  AssignmentsResponseBody,
  AssignmentsResponseBodyFromJSON,
  AssignmentsResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

export interface OrganizationAssignmentsApiCreateAssignmentRequest {
  organizationId: string;
  assignmentPostBody: AssignmentPostBody;
}

export interface OrganizationAssignmentsApiDeleteOrganizationAssignmentRequest {
  organizationId: string;
  assignmentId: number;
}

export interface OrganizationAssignmentsApiGetOrganizationAssignmentsRequest {
  organizationId: string;
}

export interface OrganizationAssignmentsApiUpdateAssignmentRequest {
  organizationId: string;
  assignmentId: number;
  assignmentPutBody: AssignmentPutBody;
}

/**
 *
 */
export class OrganizationAssignmentsApi extends runtime.BaseAPI {
  /**
   * Create an assingment.
   */
  async createAssignmentRaw(
    requestParameters: OrganizationAssignmentsApiCreateAssignmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AssignmentResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createAssignment.',
      );
    }

    if (requestParameters.assignmentPostBody === null || requestParameters.assignmentPostBody === undefined) {
      throw new runtime.RequiredError(
        'assignmentPostBody',
        'Required parameter requestParameters.assignmentPostBody was null or undefined when calling createAssignment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/assignments`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AssignmentPostBodyToJSON(requestParameters.assignmentPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssignmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create an assingment.
   */
  async createAssignment(
    requestParameters: OrganizationAssignmentsApiCreateAssignmentRequest,
    initOverrides?: RequestInit,
  ): Promise<AssignmentResponseBody> {
    const response = await this.createAssignmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete an assignment by id.
   */
  async deleteOrganizationAssignmentRaw(
    requestParameters: OrganizationAssignmentsApiDeleteOrganizationAssignmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteOrganizationAssignment.',
      );
    }

    if (requestParameters.assignmentId === null || requestParameters.assignmentId === undefined) {
      throw new runtime.RequiredError(
        'assignmentId',
        'Required parameter requestParameters.assignmentId was null or undefined when calling deleteOrganizationAssignment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/assignments/{assignment_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'assignment_id'}}`, encodeURIComponent(String(requestParameters.assignmentId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete an assignment by id.
   */
  async deleteOrganizationAssignment(
    requestParameters: OrganizationAssignmentsApiDeleteOrganizationAssignmentRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteOrganizationAssignmentRaw(requestParameters, initOverrides);
  }

  /**
   * Get all assignments of organization.
   */
  async getOrganizationAssignmentsRaw(
    requestParameters: OrganizationAssignmentsApiGetOrganizationAssignmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AssignmentsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationAssignments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/assignments`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssignmentsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all assignments of organization.
   */
  async getOrganizationAssignments(
    requestParameters: OrganizationAssignmentsApiGetOrganizationAssignmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<AssignmentsResponseBody> {
    const response = await this.getOrganizationAssignmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update an assignment by id.
   */
  async updateAssignmentRaw(
    requestParameters: OrganizationAssignmentsApiUpdateAssignmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AssignmentResponseBody1>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateAssignment.',
      );
    }

    if (requestParameters.assignmentId === null || requestParameters.assignmentId === undefined) {
      throw new runtime.RequiredError(
        'assignmentId',
        'Required parameter requestParameters.assignmentId was null or undefined when calling updateAssignment.',
      );
    }

    if (requestParameters.assignmentPutBody === null || requestParameters.assignmentPutBody === undefined) {
      throw new runtime.RequiredError(
        'assignmentPutBody',
        'Required parameter requestParameters.assignmentPutBody was null or undefined when calling updateAssignment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/assignments/{assignment_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'assignment_id'}}`, encodeURIComponent(String(requestParameters.assignmentId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AssignmentPutBodyToJSON(requestParameters.assignmentPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssignmentResponseBody1FromJSON(jsonValue));
  }

  /**
   * Update an assignment by id.
   */
  async updateAssignment(
    requestParameters: OrganizationAssignmentsApiUpdateAssignmentRequest,
    initOverrides?: RequestInit,
  ): Promise<AssignmentResponseBody1> {
    const response = await this.updateAssignmentRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
