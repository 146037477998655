/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FutureWorkHourType {
  Sick = 'sick',
  Pekkanen = 'pekkanen',
  Vacation = 'vacation',
  Allowance = 'allowance',
  PartialAllowance = 'partial_allowance',
  OwnTimeOff = 'own_time_off',
  CompensatoryTimeOff = 'compensatory_time_off',
  Bank = 'bank',
  PartialSick = 'partial_sick',
  Break = 'break',
  OverlappingBreak = 'overlapping_break',
  Normal = 'normal',
  Waiting = 'waiting',
}

export function FutureWorkHourTypeFromJSON(json: any): FutureWorkHourType {
  return FutureWorkHourTypeFromJSONTyped(json, false);
}

export function FutureWorkHourTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FutureWorkHourType {
  return json as FutureWorkHourType;
}

export function FutureWorkHourTypeToJSON(value?: FutureWorkHourType | null): any {
  return value as any;
}
