/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A pricing row that belongs to a pricing model
 * @export
 * @interface PricingRow
 */
export interface PricingRow {
  /**
   *
   * @type {number}
   * @memberof PricingRow
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof PricingRow
   */
  weight_kg: number;
  /**
   *
   * @type {number}
   * @memberof PricingRow
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof PricingRow
   */
  pricing_table_id: number;
  /**
   *
   * @type {number}
   * @memberof PricingRow
   */
  pricing_area_id: number | null;
  /**
   *
   * @type {number}
   * @memberof PricingRow
   */
  distance_km: number | null;
  /**
   *
   * @type {number}
   * @memberof PricingRow
   */
  custom_pricing_category_id: number | null;
}

export function PricingRowFromJSON(json: any): PricingRow {
  return PricingRowFromJSONTyped(json, false);
}

export function PricingRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingRow {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    weight_kg: json['weight_kg'],
    price: json['price'],
    pricing_table_id: json['pricing_table_id'],
    pricing_area_id: json['pricing_area_id'],
    distance_km: json['distance_km'],
    custom_pricing_category_id: json['custom_pricing_category_id'],
  };
}

export function PricingRowToJSON(value?: PricingRow | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    weight_kg: value.weight_kg,
    price: value.price,
    pricing_table_id: value.pricing_table_id,
    pricing_area_id: value.pricing_area_id,
    distance_km: value.distance_km,
    custom_pricing_category_id: value.custom_pricing_category_id,
  };
}
