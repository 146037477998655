import {
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleGlobalFilterButton,
  MRT_RowData,
} from 'material-react-table';
import React from 'react';
import { DateRangePicker, MRTDateRangePickerProps } from './MRTDateRangePicker';
import { Badge, Box, Divider, IconButton, Tooltip } from '@mui/material';
import { GetApp, Tune } from '@mui/icons-material';
import { dateFormat, dateTimeFormat } from '../../formatters';
import { utils as xlsxUtils, writeFile as writeXlsxFile } from 'xlsx';
import { MRT_ShowHideColumnsMenu } from './MRTShowHideColumnsMenu';
import { DateRange } from '../../utils/dateRangeUtils';
import { MRT_Localization_FI } from 'material-react-table/locales/fi';
import { DateTime } from 'luxon';

export interface MRTToolbarProps<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
  dateRangePickerProps: MRTDateRangePickerProps;
  dateRange: DateRange;
  header: string;
  viewId: string;
  isLoading: boolean;
  showDateRange: boolean;
}

const exportAsXlsx = <TData extends MRT_RowData>(
  table: MRT_TableInstance<TData>,
  dateRange: DateRange,
  header: string,
) => {
  const visibleCells = table
    .getFilteredRowModel()
    .rows.map((x) => x.getVisibleCells())
    .map((y) => y.filter((i) => !i.id.includes('mrt')));

  const headers = visibleCells[0].map((cell) => cell.column.columnDef.header ?? '');
  const rowCellValues = visibleCells.map((cells) =>
    cells.map((cell) => {
      const idsToFormat = [
        'delivered_at',
        'picked_up_at',
        'arrived_to_delivery_location_at',
        'arrived_to_pickup_location_at',
      ];
      const format = idsToFormat.findIndex((x) => !x.includes(cell.id)) ? dateTimeFormat : dateFormat;
      const value = cell.getValue();
      return DateTime.isDateTime(value) ? value.toFormat(format) : value;
    }),
  );

  const worksheet = xlsxUtils.aoa_to_sheet([headers, ...rowCellValues]);
  const workbook = xlsxUtils.book_new();
  xlsxUtils.book_append_sheet(workbook, worksheet, 'Sheet 1');

  writeXlsxFile(
    workbook,
    `${header}_${dateRange.start.toFormat(dateFormat)}-${dateRange.end.toFormat(dateFormat)}.xlsx`,
  );
};

export const MRTToolbar = <TData extends MRT_RowData>({
  table,
  dateRangePickerProps,
  dateRange,
  header,
  viewId,
  isLoading,
  showDateRange,
}: MRTToolbarProps<TData>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const hiddenColumnsLength = Object.values(table.getState().columnVisibility).filter((id) => !id).length;
  const globalFilterValueExists = Boolean(table.getState().globalFilter);
  const filterValuesLength = table.getState().columnFilters && table.getState().columnFilters.length;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {showDateRange && <DateRangePicker {...dateRangePickerProps} />}
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderWidth: '0.1rem', marginLeft: '0.25rem', marginRight: '0.25rem' }}
      />
      <Box>
        <Badge badgeContent={globalFilterValueExists ? 1 : null} color="primary" overlap="circular">
          <MRT_ToggleGlobalFilterButton
            title={isLoading ? '' : MRT_Localization_FI.showHideSearch}
            disabled={isLoading}
            table={table}
            data-cy="global-filter-button"
          />
        </Badge>
        <Tooltip arrow={true} title={isLoading ? '' : 'Lataa'}>
          <IconButton disabled={isLoading} onClick={() => exportAsXlsx(table, dateRange, header)}>
            <GetApp />
          </IconButton>
        </Tooltip>
        <Tooltip arrow={true} title={isLoading ? '' : 'Muokkaa sarakkeita ja suodattimia'}>
          <Badge badgeContent={hiddenColumnsLength} color="primary" overlap="circular">
            <Badge
              badgeContent={filterValuesLength}
              color="warning"
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <IconButton
                disabled={isLoading}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => {
                  handleClick(event);
                }}
              >
                <Tune />
              </IconButton>
            </Badge>
          </Badge>
        </Tooltip>
        <MRT_ShowHideColumnsMenu table={table} anchorEl={anchorEl} setAnchorEl={setAnchorEl} viewId={viewId} />
        <MRT_ToggleDensePaddingButton
          title={isLoading ? '' : MRT_Localization_FI.toggleDensity}
          disabled={isLoading}
          table={table}
        />
      </Box>
    </Box>
  );
};
