/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ShipmentRowPostPutBody,
  ShipmentRowPostPutBodyFromJSON,
  ShipmentRowPostPutBodyFromJSONTyped,
  ShipmentRowPostPutBodyToJSON,
} from './ShipmentRowPostPutBody';
import {
  ShipmentStateEnum,
  ShipmentStateEnumFromJSON,
  ShipmentStateEnumFromJSONTyped,
  ShipmentStateEnumToJSON,
} from './ShipmentStateEnum';
import {
  ShipmentUpdateAdditionalServicePutBody,
  ShipmentUpdateAdditionalServicePutBodyFromJSON,
  ShipmentUpdateAdditionalServicePutBodyFromJSONTyped,
  ShipmentUpdateAdditionalServicePutBodyToJSON,
} from './ShipmentUpdateAdditionalServicePutBody';

/**
 *
 * @export
 * @interface ShipmentPutBody
 */
export interface ShipmentPutBody {
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  readonly shipment_id?: number;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  organization_id: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  billing_office_organization_specific_id?: string;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  load_id: number | null;
  /**
   *
   * @type {ShipmentStateEnum}
   * @memberof ShipmentPutBody
   */
  state: ShipmentStateEnum | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  wait_reason: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  orderer: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  orderer_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  job_number: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  other_contract_number: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  reference_number: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  pickup_office_organization_specific_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  pickup_name: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  pickup_address: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  pickup_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  pickup_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  pickup_phone_number_secondary: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  pickup_city: string | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  order_in_load: number | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  recipient: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  delivery_office_organization_specific_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  delivery_name: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  delivery_address: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  delivery_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  delivery_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  delivery_city: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  note: string | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  length_ldm: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  weight_kg: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  volume_m3: number;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  agreed_delivery_window_starts_at: Date;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  agreed_delivery_window_ends_at: Date;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  pickup_window_starts_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  pickup_window_ends_at: Date | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  hourly_work_hours: number;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  hourly_work_reason: string;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentPutBody
   */
  is_express_delivery: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentPutBody
   */
  requires_combination_vehicle: boolean;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  price?: number | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  legacy_price_basis?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  price_basis_str?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentPutBody
   */
  has_contract_price?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentPutBody
   */
  has_additional_hourly_pricing?: boolean;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  billed_at?: Date | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  legacy_etaisyys_field?: number | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  pricing_model: number | null;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentPutBody
   */
  pricing_failed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentPutBody
   */
  is_adr_delivery: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentPutBody
   */
  has_distance_been_fixed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  wait_hours: number;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  ordered_at?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  arrived_to_pickup_location_at?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  picked_up_at?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  arrived_to_delivery_location_at?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ShipmentPutBody
   */
  delivered_at?: Date | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  delivery_phone_number_secondary: string | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentPutBody
   */
  custom_pricing_category_id: number | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentPutBody
   */
  billing_reference_number: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ShipmentPutBody
   */
  requires_hoist: boolean;
  /**
   *
   * @type {Array<ShipmentRowPostPutBody>}
   * @memberof ShipmentPutBody
   */
  rows: Array<ShipmentRowPostPutBody>;
  /**
   *
   * @type {Array<ShipmentUpdateAdditionalServicePutBody>}
   * @memberof ShipmentPutBody
   */
  additional_services?: Array<ShipmentUpdateAdditionalServicePutBody>;
}

export function ShipmentPutBodyFromJSON(json: any): ShipmentPutBody {
  return ShipmentPutBodyFromJSONTyped(json, false);
}

export function ShipmentPutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentPutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    shipment_id: !exists(json, 'shipment_id') ? undefined : json['shipment_id'],
    organization_id: json['organization_id'],
    billing_office_organization_specific_id: !exists(json, 'billing_office_organization_specific_id')
      ? undefined
      : json['billing_office_organization_specific_id'],
    load_id: json['load_id'],
    state: ShipmentStateEnumFromJSON(json['state']),
    wait_reason: json['wait_reason'],
    orderer: json['orderer'],
    orderer_phone_number: json['orderer_phone_number'],
    job_number: json['job_number'],
    other_contract_number: json['other_contract_number'],
    reference_number: json['reference_number'],
    pickup_office_organization_specific_id: !exists(json, 'pickup_office_organization_specific_id')
      ? undefined
      : json['pickup_office_organization_specific_id'],
    pickup_name: json['pickup_name'],
    pickup_address: json['pickup_address'],
    pickup_postal_code: json['pickup_postal_code'],
    pickup_phone_number: json['pickup_phone_number'],
    pickup_phone_number_secondary: json['pickup_phone_number_secondary'],
    pickup_city: json['pickup_city'],
    order_in_load: json['order_in_load'],
    recipient: json['recipient'],
    delivery_office_organization_specific_id: !exists(json, 'delivery_office_organization_specific_id')
      ? undefined
      : json['delivery_office_organization_specific_id'],
    delivery_name: json['delivery_name'],
    delivery_address: json['delivery_address'],
    delivery_postal_code: json['delivery_postal_code'],
    delivery_phone_number: json['delivery_phone_number'],
    delivery_city: json['delivery_city'],
    note: json['note'],
    length_ldm: json['length_ldm'],
    weight_kg: json['weight_kg'],
    volume_m3: json['volume_m3'],
    agreed_delivery_window_starts_at: new Date(json['agreed_delivery_window_starts_at']),
    agreed_delivery_window_ends_at: new Date(json['agreed_delivery_window_ends_at']),
    pickup_window_starts_at:
      json['pickup_window_starts_at'] === null ? null : new Date(json['pickup_window_starts_at']),
    pickup_window_ends_at: json['pickup_window_ends_at'] === null ? null : new Date(json['pickup_window_ends_at']),
    hourly_work_hours: json['hourly_work_hours'],
    hourly_work_reason: json['hourly_work_reason'],
    is_express_delivery: json['is_express_delivery'],
    requires_combination_vehicle: json['requires_combination_vehicle'],
    price: !exists(json, 'price') ? undefined : json['price'],
    legacy_price_basis: !exists(json, 'legacy_price_basis') ? undefined : json['legacy_price_basis'],
    price_basis_str: !exists(json, 'price_basis_str') ? undefined : json['price_basis_str'],
    has_contract_price: !exists(json, 'has_contract_price') ? undefined : json['has_contract_price'],
    has_additional_hourly_pricing: !exists(json, 'has_additional_hourly_pricing')
      ? undefined
      : json['has_additional_hourly_pricing'],
    billed_at: !exists(json, 'billed_at') ? undefined : json['billed_at'] === null ? null : new Date(json['billed_at']),
    legacy_etaisyys_field: !exists(json, 'legacy_etaisyys_field') ? undefined : json['legacy_etaisyys_field'],
    pricing_model: json['pricing_model'],
    pricing_failed: !exists(json, 'pricing_failed') ? undefined : json['pricing_failed'],
    is_adr_delivery: json['is_adr_delivery'],
    has_distance_been_fixed: !exists(json, 'has_distance_been_fixed') ? undefined : json['has_distance_been_fixed'],
    wait_hours: json['wait_hours'],
    ordered_at: !exists(json, 'ordered_at')
      ? undefined
      : json['ordered_at'] === null
        ? null
        : new Date(json['ordered_at']),
    arrived_to_pickup_location_at: !exists(json, 'arrived_to_pickup_location_at')
      ? undefined
      : json['arrived_to_pickup_location_at'] === null
        ? null
        : new Date(json['arrived_to_pickup_location_at']),
    picked_up_at: !exists(json, 'picked_up_at')
      ? undefined
      : json['picked_up_at'] === null
        ? null
        : new Date(json['picked_up_at']),
    arrived_to_delivery_location_at: !exists(json, 'arrived_to_delivery_location_at')
      ? undefined
      : json['arrived_to_delivery_location_at'] === null
        ? null
        : new Date(json['arrived_to_delivery_location_at']),
    delivered_at: !exists(json, 'delivered_at')
      ? undefined
      : json['delivered_at'] === null
        ? null
        : new Date(json['delivered_at']),
    delivery_phone_number_secondary: json['delivery_phone_number_secondary'],
    custom_pricing_category_id: json['custom_pricing_category_id'],
    billing_reference_number: json['billing_reference_number'],
    requires_hoist: json['requires_hoist'],
    rows: (json['rows'] as Array<any>).map(ShipmentRowPostPutBodyFromJSON),
    additional_services: !exists(json, 'additional_services')
      ? undefined
      : (json['additional_services'] as Array<any>).map(ShipmentUpdateAdditionalServicePutBodyFromJSON),
  };
}

export function ShipmentPutBodyToJSON(value?: ShipmentPutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organization_id: value.organization_id,
    billing_office_organization_specific_id: value.billing_office_organization_specific_id,
    load_id: value.load_id,
    state: ShipmentStateEnumToJSON(value.state),
    wait_reason: value.wait_reason,
    orderer: value.orderer,
    orderer_phone_number: value.orderer_phone_number,
    job_number: value.job_number,
    other_contract_number: value.other_contract_number,
    reference_number: value.reference_number,
    pickup_office_organization_specific_id: value.pickup_office_organization_specific_id,
    pickup_name: value.pickup_name,
    pickup_address: value.pickup_address,
    pickup_postal_code: value.pickup_postal_code,
    pickup_phone_number: value.pickup_phone_number,
    pickup_phone_number_secondary: value.pickup_phone_number_secondary,
    pickup_city: value.pickup_city,
    order_in_load: value.order_in_load,
    recipient: value.recipient,
    delivery_office_organization_specific_id: value.delivery_office_organization_specific_id,
    delivery_name: value.delivery_name,
    delivery_address: value.delivery_address,
    delivery_postal_code: value.delivery_postal_code,
    delivery_phone_number: value.delivery_phone_number,
    delivery_city: value.delivery_city,
    note: value.note,
    length_ldm: value.length_ldm,
    weight_kg: value.weight_kg,
    volume_m3: value.volume_m3,
    agreed_delivery_window_starts_at: value.agreed_delivery_window_starts_at.toISOString(),
    agreed_delivery_window_ends_at: value.agreed_delivery_window_ends_at.toISOString(),
    pickup_window_starts_at:
      value.pickup_window_starts_at === null ? null : value.pickup_window_starts_at.toISOString(),
    pickup_window_ends_at: value.pickup_window_ends_at === null ? null : value.pickup_window_ends_at.toISOString(),
    hourly_work_hours: value.hourly_work_hours,
    hourly_work_reason: value.hourly_work_reason,
    is_express_delivery: value.is_express_delivery,
    requires_combination_vehicle: value.requires_combination_vehicle,
    price: value.price,
    legacy_price_basis: value.legacy_price_basis,
    price_basis_str: value.price_basis_str,
    has_contract_price: value.has_contract_price,
    has_additional_hourly_pricing: value.has_additional_hourly_pricing,
    billed_at:
      value.billed_at === undefined
        ? undefined
        : value.billed_at === null
          ? null
          : value.billed_at.toISOString().substr(0, 10),
    legacy_etaisyys_field: value.legacy_etaisyys_field,
    pricing_model: value.pricing_model,
    pricing_failed: value.pricing_failed,
    is_adr_delivery: value.is_adr_delivery,
    has_distance_been_fixed: value.has_distance_been_fixed,
    wait_hours: value.wait_hours,
    ordered_at:
      value.ordered_at === undefined ? undefined : value.ordered_at === null ? null : value.ordered_at.toISOString(),
    arrived_to_pickup_location_at:
      value.arrived_to_pickup_location_at === undefined
        ? undefined
        : value.arrived_to_pickup_location_at === null
          ? null
          : value.arrived_to_pickup_location_at.toISOString(),
    picked_up_at:
      value.picked_up_at === undefined
        ? undefined
        : value.picked_up_at === null
          ? null
          : value.picked_up_at.toISOString(),
    arrived_to_delivery_location_at:
      value.arrived_to_delivery_location_at === undefined
        ? undefined
        : value.arrived_to_delivery_location_at === null
          ? null
          : value.arrived_to_delivery_location_at.toISOString(),
    delivered_at:
      value.delivered_at === undefined
        ? undefined
        : value.delivered_at === null
          ? null
          : value.delivered_at.toISOString(),
    delivery_phone_number_secondary: value.delivery_phone_number_secondary,
    custom_pricing_category_id: value.custom_pricing_category_id,
    billing_reference_number: value.billing_reference_number,
    requires_hoist: value.requires_hoist,
    rows: (value.rows as Array<any>).map(ShipmentRowPostPutBodyToJSON),
    additional_services:
      value.additional_services === undefined
        ? undefined
        : (value.additional_services as Array<any>).map(ShipmentUpdateAdditionalServicePutBodyToJSON),
  };
}
