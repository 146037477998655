/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  PricingModelWithAreas,
  PricingModelWithAreasFromJSON,
  PricingModelWithAreasFromJSONTyped,
  PricingModelWithAreasToJSON,
} from './PricingModelWithAreas';

/**
 *
 * @export
 * @interface PricingModelResponseBody
 */
export interface PricingModelResponseBody {
  /**
   *
   * @type {PricingModelWithAreas}
   * @memberof PricingModelResponseBody
   */
  data: PricingModelWithAreas;
}

export function PricingModelResponseBodyFromJSON(json: any): PricingModelResponseBody {
  return PricingModelResponseBodyFromJSONTyped(json, false);
}

export function PricingModelResponseBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PricingModelResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: PricingModelWithAreasFromJSON(json['data']),
  };
}

export function PricingModelResponseBodyToJSON(value?: PricingModelResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: PricingModelWithAreasToJSON(value.data),
  };
}
