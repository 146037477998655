/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  TimeOffLegacy,
  TimeOffLegacyFromJSON,
  TimeOffLegacyToJSON,
  TimeOffLegacyResponseBody,
  TimeOffLegacyResponseBodyFromJSON,
  TimeOffLegacyResponseBodyToJSON,
  TimeOffLegacyResponseBody1,
  TimeOffLegacyResponseBody1FromJSON,
  TimeOffLegacyResponseBody1ToJSON,
  TimeOffLegacyResponseBody2,
  TimeOffLegacyResponseBody2FromJSON,
  TimeOffLegacyResponseBody2ToJSON,
  TimeOffPatchBody,
  TimeOffPatchBodyFromJSON,
  TimeOffPatchBodyToJSON,
} from '../models';

export interface TimeOffsApiCreateTimeOffRequest {
  timeOffLegacy: TimeOffLegacy;
}

export interface TimeOffsApiGetTimeOffsRequest {
  next?: number;
  year?: number;
}

export interface TimeOffsApiPatchTimeOffRequest {
  timeOffId: number;
  timeOffPatchBody: TimeOffPatchBody;
}

/**
 *
 */
export class TimeOffsApi extends runtime.BaseAPI {
  /**
   * Create new time off.
   */
  async createTimeOffRaw(
    requestParameters: TimeOffsApiCreateTimeOffRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<TimeOffLegacyResponseBody1>> {
    if (requestParameters.timeOffLegacy === null || requestParameters.timeOffLegacy === undefined) {
      throw new runtime.RequiredError(
        'timeOffLegacy',
        'Required parameter requestParameters.timeOffLegacy was null or undefined when calling createTimeOff.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/time_offs`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TimeOffLegacyToJSON(requestParameters.timeOffLegacy),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TimeOffLegacyResponseBody1FromJSON(jsonValue));
  }

  /**
   * Create new time off.
   */
  async createTimeOff(
    requestParameters: TimeOffsApiCreateTimeOffRequest,
    initOverrides?: RequestInit,
  ): Promise<TimeOffLegacyResponseBody1> {
    const response = await this.createTimeOffRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all time offs in legacy format.
   */
  async getTimeOffsRaw(
    requestParameters: TimeOffsApiGetTimeOffsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<TimeOffLegacyResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.year !== undefined) {
      queryParameters['year'] = requestParameters.year;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/time_offs`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TimeOffLegacyResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all time offs in legacy format.
   */
  async getTimeOffs(
    requestParameters: TimeOffsApiGetTimeOffsRequest,
    initOverrides?: RequestInit,
  ): Promise<TimeOffLegacyResponseBody> {
    const response = await this.getTimeOffsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Patch a time off.
   */
  async patchTimeOffRaw(
    requestParameters: TimeOffsApiPatchTimeOffRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<TimeOffLegacyResponseBody2>> {
    if (requestParameters.timeOffId === null || requestParameters.timeOffId === undefined) {
      throw new runtime.RequiredError(
        'timeOffId',
        'Required parameter requestParameters.timeOffId was null or undefined when calling patchTimeOff.',
      );
    }

    if (requestParameters.timeOffPatchBody === null || requestParameters.timeOffPatchBody === undefined) {
      throw new runtime.RequiredError(
        'timeOffPatchBody',
        'Required parameter requestParameters.timeOffPatchBody was null or undefined when calling patchTimeOff.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/time_offs/{time_off_id}`.replace(
          `{${'time_off_id'}}`,
          encodeURIComponent(String(requestParameters.timeOffId)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: TimeOffPatchBodyToJSON(requestParameters.timeOffPatchBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TimeOffLegacyResponseBody2FromJSON(jsonValue));
  }

  /**
   * Patch a time off.
   */
  async patchTimeOff(
    requestParameters: TimeOffsApiPatchTimeOffRequest,
    initOverrides?: RequestInit,
  ): Promise<TimeOffLegacyResponseBody2> {
    const response = await this.patchTimeOffRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
