import { passwordComplexity } from '../../../../backend/src/common/passwordComplexity';
import { userTextFields, userEmail, userUsername, userPassword, phoneNumber } from '../../validation';
import { Field, FieldName, State } from './createNewUser.state';

type ValidationResult = {
  hasError: boolean;
  feedback?: string;
};

const getValidationResult = (joiSchema: any, field: Field<any>): ValidationResult => {
  const result = joiSchema.validate(field.value);
  const validationResult: ValidationResult = {
    hasError: result.error !== undefined,
    feedback: result.error?.message,
  };
  return validationResult;
};

const validateField = (fieldName: FieldName, fields: State['fields'], state: State): ValidationResult => {
  const field = fields[fieldName];
  let validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  switch (fieldName) {
    case 'username': {
      validationResult = getValidationResult(userUsername, field);
      break;
    }
    case 'first_name':
    case 'last_name': {
      validationResult = getValidationResult(userTextFields, field);
      break;
    }
    case 'gsm': {
      validationResult = getValidationResult(phoneNumber, field);
      break;
    }
    case 'email': {
      validationResult = getValidationResult(userEmail, field);
      break;
    }
    case 'password': {
      validationResult = getValidationResult(
        passwordComplexity
          .allow(userPassword)
          .error(() => {
            return new Error(
              'Salasanan tulee olla vähintään 8 merkkiä pitkä ja sisältää vähintään yksi pieni kirjain, yksi suuri kirjain, yksi numero ja yksi erikoismerkki.',
            );
          })
          .allow(''),
        field,
      );
      break;
    }
    case 'password_again': {
      validationResult = getValidationResult(userPassword.valid(state.fields.password.value), field);
      break;
    }
  }
  if (field.required && (field.value === undefined || field.value === null || field.value === '')) {
    validationResult.hasError = true;
    validationResult.feedback = 'Kenttä on pakollinen';
  }
  return validationResult;
};

export const validateFields = (state: State): { fields: State['fields'] } => {
  const newFields = { ...state.fields } as any;
  for (const fieldName of Object.keys(state.fields)) {
    newFields[fieldName] = {
      ...newFields[fieldName],
      ...validateField(fieldName as FieldName, state.fields, state),
    };
  }
  return {
    fields: newFields,
  };
};
