import { Driver, DriverGroup, DriverProfessionEnum } from '../../../backend/src/common/api';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { sortBy } from 'lodash';
import { api, getAllPages } from '../api';
import { Box, Chip, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { FieldSet } from './StyledComponents/FieldSet';
import { StyledFormContainer } from './StyledComponents/StyledFormComponents';

interface DriverSelectorProps {
  onDriversSelected: (drivers: Array<Driver>) => void;
  displayGroups?: boolean;
  initialSelectedDrivers?: Array<Driver>;
}

const sortDrivers = (drivers: Array<Driver>) => {
  return sortBy(drivers, ['last_name', 'first_name']);
};
export const DriverSelector: React.FC<DriverSelectorProps> = ({
  onDriversSelected,
  displayGroups = false,
  initialSelectedDrivers = [],
}) => {
  const [driverGroups, setDriverGroups] = React.useState<Array<DriverGroup>>([]);
  const [selectedDrivers, setSelectedDrivers] = React.useState<Array<Driver>>(initialSelectedDrivers);
  const [otherDrivers, setOtherDriversOrig] = React.useState<Array<Driver>>([]);

  const setOtherDrivers: Dispatch<SetStateAction<Array<Driver>>> = (
    drivers: Array<Driver> | ((prevState: Array<Driver>) => Array<Driver>),
  ) => {
    if (typeof drivers === 'function') {
      setOtherDriversOrig((prev) => sortDrivers(drivers(prev)));
    } else {
      setOtherDriversOrig(sortDrivers(drivers));
    }
  };

  useEffect(() => {
    const fetchDriversAndGroups = async () => {
      const [driverGroups, allDrivers] = await Promise.all([
        api.driverGroups.getAllDriverGroups(),
        getAllPages(api.drivers.getDrivers.bind(api.drivers), {}),
      ]);
      setDriverGroups(driverGroups.data);
      const filteredOtherDrivers = allDrivers.filter(
        (driver) =>
          !initialSelectedDrivers.find((d) => d.id === driver.id) && driver.profession === DriverProfessionEnum.Kuski,
      );
      setOtherDrivers(filteredOtherDrivers);
    };
    fetchDriversAndGroups();
  }, []);

  return (
    <div>
      {displayGroups && (
        <Box
          sx={{
            width: 'auto',
            display: 'flex',
            flexFlow: 'row-wrap',
            justifyContent: 'space-between',
            paddingBottom: '1.25rem',
            '> button': {
              margin: '0.25rem',
            },
          }}
        >
          <Button
            key="all-drivers"
            sx={{ flex: 1 }}
            onClick={async () => {
              const newSelected = sortDrivers([...selectedDrivers, ...otherDrivers]);
              setSelectedDrivers(newSelected);
              setOtherDrivers([]);
              onDriversSelected(newSelected);
            }}
          >
            Valitse kaikki
          </Button>
          {driverGroups.map((group) => {
            return (
              <Button
                key={group.id}
                sx={{ flex: 1 }}
                onClick={async () => {
                  const response = await api.driverGroups.getDriverGroup({ driverGroupId: group.id });
                  if (response.data.drivers) {
                    const selected = selectedDrivers;
                    let others = otherDrivers;
                    response.data.drivers.forEach((driver) => {
                      if (selected.find((d) => d.id === driver.id)) {
                        return;
                      }
                      selected.push(driver);
                      others = others.filter((d) => d.id !== driver.id);
                    });
                    const newSelected = sortDrivers(selected);
                    setSelectedDrivers(newSelected);
                    setOtherDrivers(others);
                    onDriversSelected(newSelected);
                  }
                }}
              >
                {group.name}
              </Button>
            );
          })}
          <Button
            key="clear-drivers"
            color="warning"
            sx={{ flex: 1 }}
            onClick={async () => {
              const newSelected = sortDrivers([...selectedDrivers, ...otherDrivers]);
              setSelectedDrivers([]);
              setOtherDrivers(newSelected);
              onDriversSelected([]);
            }}
          >
            Tyhjennä valitut
          </Button>
        </Box>
      )}
      <StyledFormContainer>
        <FieldSet style={{ flex: 1 }}>
          <legend>Valitut kuljettajat</legend>
          {selectedDrivers.length === 0 ? (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Typography>Ei valittuja kuljettajia</Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {selectedDrivers.map((driver) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={driver.id}>
                    <Chip
                      sx={{ width: '100%', justifyContent: 'space-between' }}
                      label={`${driver.last_name} ${driver.first_name}`}
                      onDelete={() => {
                        const newSelected = selectedDrivers.filter((d) => d.id !== driver.id);
                        setSelectedDrivers(newSelected);
                        setOtherDrivers((value) => [...value, driver]);
                        onDriversSelected(newSelected);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </FieldSet>
        <FieldSet style={{ flex: 1 }}>
          <legend>Muut kuljettajat</legend>
          {otherDrivers.length === 0 && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Typography>Ei muita kuljettajia</Typography>
            </Box>
          )}
          <Grid container spacing={2}>
            {otherDrivers.map((driver) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={driver.id}>
                  <Chip
                    sx={{ width: '100%', justifyContent: 'space-between' }}
                    label={`${driver.last_name} ${driver.first_name}`}
                    onClick={() => {
                      const newSelected = sortDrivers([...selectedDrivers, driver]);
                      setSelectedDrivers(newSelected);
                      setOtherDrivers((value) => value.filter((d) => d.id !== driver.id));
                      onDriversSelected(newSelected);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FieldSet>
      </StyledFormContainer>
    </div>
  );
};
