/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CarrierCarPostBody,
  CarrierCarPostBodyFromJSON,
  CarrierCarPostBodyToJSON,
  CarrierCarPutBody,
  CarrierCarPutBodyFromJSON,
  CarrierCarPutBodyToJSON,
  CarrierCarServicePostBody,
  CarrierCarServicePostBodyFromJSON,
  CarrierCarServicePostBodyToJSON,
  CarrierCarServicePutBody,
  CarrierCarServicePutBodyFromJSON,
  CarrierCarServicePutBodyToJSON,
  CarrierClientPostBody,
  CarrierClientPostBodyFromJSON,
  CarrierClientPostBodyToJSON,
  CarrierEmployeePostBody,
  CarrierEmployeePostBodyFromJSON,
  CarrierEmployeePostBodyToJSON,
  CarrierEmployeePutBody,
  CarrierEmployeePutBodyFromJSON,
  CarrierEmployeePutBodyToJSON,
  CarrierLoadPostBody,
  CarrierLoadPostBodyFromJSON,
  CarrierLoadPostBodyToJSON,
  CarrierLoadPutBody,
  CarrierLoadPutBodyFromJSON,
  CarrierLoadPutBodyToJSON,
  CarrierShipmentLoadUpdate,
  CarrierShipmentLoadUpdateFromJSON,
  CarrierShipmentLoadUpdateToJSON,
  CarrierShipmentPostBody,
  CarrierShipmentPostBodyFromJSON,
  CarrierShipmentPostBodyToJSON,
  DriverFields,
  DriverFieldsFromJSON,
  DriverFieldsToJSON,
  EmployeeFields,
  EmployeeFieldsFromJSON,
  EmployeeFieldsToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  GetCarrierCarsResponse,
  GetCarrierCarsResponseFromJSON,
  GetCarrierCarsResponseToJSON,
  GetCarrierClientResult,
  GetCarrierClientResultFromJSON,
  GetCarrierClientResultToJSON,
  GetCarrierClientsResult,
  GetCarrierClientsResultFromJSON,
  GetCarrierClientsResultToJSON,
  GetCarrierDriverReponse,
  GetCarrierDriverReponseFromJSON,
  GetCarrierDriverReponseToJSON,
  GetCarrierDriversReponse,
  GetCarrierDriversReponseFromJSON,
  GetCarrierDriversReponseToJSON,
  GetCarrierEmployeeResponse,
  GetCarrierEmployeeResponseFromJSON,
  GetCarrierEmployeeResponseToJSON,
  GetCarrierEmployeesResponse,
  GetCarrierEmployeesResponseFromJSON,
  GetCarrierEmployeesResponseToJSON,
  GetCarrierLoadResponse,
  GetCarrierLoadResponseFromJSON,
  GetCarrierLoadResponseToJSON,
  GetCarrierLoadsResponse,
  GetCarrierLoadsResponseFromJSON,
  GetCarrierLoadsResponseToJSON,
  GetCarrierShipmentResponse,
  GetCarrierShipmentResponseFromJSON,
  GetCarrierShipmentResponseToJSON,
  GetCarrierShipmentsResponse,
  GetCarrierShipmentsResponseFromJSON,
  GetCarrierShipmentsResponseToJSON,
  GetCarrierUnhandledShipmentRowsResponse,
  GetCarrierUnhandledShipmentRowsResponseFromJSON,
  GetCarrierUnhandledShipmentRowsResponseToJSON,
  InlineResponse2001,
  InlineResponse2001FromJSON,
  InlineResponse2001ToJSON,
  InlineResponse201,
  InlineResponse201FromJSON,
  InlineResponse201ToJSON,
  InlineResponse2011,
  InlineResponse2011FromJSON,
  InlineResponse2011ToJSON,
  InlineResponse2012,
  InlineResponse2012FromJSON,
  InlineResponse2012ToJSON,
  InlineResponse2013,
  InlineResponse2013FromJSON,
  InlineResponse2013ToJSON,
  InlineResponse2014,
  InlineResponse2014FromJSON,
  InlineResponse2014ToJSON,
  InlineResponse2015,
  InlineResponse2015FromJSON,
  InlineResponse2015ToJSON,
  PutCarrierEmployeeResponse,
  PutCarrierEmployeeResponseFromJSON,
  PutCarrierEmployeeResponseToJSON,
} from '../models';

export interface CarriersApiDeleteCarrierCarRequest {
  carrierId: number;
  carId: number;
}

export interface CarriersApiGetCarrierCarRequest {
  carrierId: number;
  carId: number;
}

export interface CarriersApiGetCarrierCarByLicencePlateRequest {
  carrierId: number;
  carLicencePlate: string;
}

export interface CarriersApiGetCarrierCarCarServicesRequest {
  carrierId: number;
  carId: number;
}

export interface CarriersApiGetCarrierCarServiceRequest {
  carrierId: number;
  carId: number;
  carServiceId: number;
}

export interface CarriersApiGetCarrierCarServicesRequest {
  carrierId: number;
}

export interface CarriersApiGetCarrierCarsRequest {
  carrierId: number;
}

export interface CarriersApiGetCarrierClientRequest {
  carrierId: number;
  clientId: number;
}

export interface CarriersApiGetCarrierClientsRequest {
  carrierId: number;
}

export interface CarriersApiGetCarrierDriverRequest {
  carrierId: number;
  driverId: number;
  fields: Array<DriverFields>;
}

export interface CarriersApiGetCarrierDriversRequest {
  carrierId: number;
  fields: Array<DriverFields>;
}

export interface CarriersApiGetCarrierEmployeeRequest {
  carrierId: number;
  employeeId: number;
  fields: Array<EmployeeFields>;
}

export interface CarriersApiGetCarrierEmployeesRequest {
  carrierId: number;
  fields: Array<EmployeeFields>;
}

export interface CarriersApiGetCarrierLoadRequest {
  carrierId: number;
  loadId: number;
}

export interface CarriersApiGetCarrierLoadOptimizedRouteRequest {
  carrierId: number;
  loadId: number;
}

export interface CarriersApiGetCarrierLoadShipmentsRequest {
  carrierId: number;
  loadId: number;
}

export interface CarriersApiGetCarrierLoadsRequest {
  carrierId: number;
  next?: number;
  driveDateRangeStartsAt?: Date;
  driveDateRangeEndsAt?: Date;
}

export interface CarriersApiGetCarrierShipmentRequest {
  carrierId: number;
  shipmentId: number;
}

export interface CarriersApiGetCarrierShipmentsRequest {
  carrierId: number;
  next?: number;
  coordinationDateRangeStartsAt?: Date;
  coordinationDateRangeEndsAt?: Date;
}

export interface CarriersApiGetCarrierUnhandledShipmentRowsRequest {
  carrierId: number;
  coordinationDateRangeStartsAt?: Date;
  coordinationDateRangeEndsAt?: Date;
}

export interface CarriersApiPatchCarrierLoadShipmentsRequest {
  carrierId: number;
  carrierShipmentLoadUpdate: Array<CarrierShipmentLoadUpdate>;
}

export interface CarriersApiPostCarrierCarRequest {
  carrierId: number;
  carrierCarPostBody: CarrierCarPostBody;
}

export interface CarriersApiPostCarrierCarServiceRequest {
  carrierId: number;
  carId: number;
  carrierCarServicePostBody: CarrierCarServicePostBody;
}

export interface CarriersApiPostCarrierClientRequest {
  carrierId: number;
  carrierClientPostBody: CarrierClientPostBody;
}

export interface CarriersApiPostCarrierEmployeeRequest {
  carrierId: number;
  carrierEmployeePostBody: CarrierEmployeePostBody;
}

export interface CarriersApiPostCarrierLoadRequest {
  carrierId: number;
  carrierLoadPostBody: CarrierLoadPostBody;
}

export interface CarriersApiPostCarrierShipmentRequest {
  carrierId: number;
  carrierShipmentPostBody: CarrierShipmentPostBody;
}

export interface CarriersApiPutCarrierCarRequest {
  carrierId: number;
  carId: number;
  carrierCarPutBody: CarrierCarPutBody;
}

export interface CarriersApiPutCarrierCarServiceRequest {
  carrierId: number;
  carId: number;
  carServiceId: number;
  carrierCarServicePutBody: CarrierCarServicePutBody;
}

export interface CarriersApiPutCarrierEmployeeRequest {
  carrierId: number;
  employeeId: number;
  carrierEmployeePutBody: CarrierEmployeePutBody;
}

export interface CarriersApiPutCarrierLoadRequest {
  carrierId: number;
  loadId: number;
  carrierLoadPutBody: CarrierLoadPutBody;
}

/**
 *
 */
export class CarriersApi extends runtime.BaseAPI {
  /**
   */
  async deleteCarrierCarRaw(
    requestParameters: CarriersApiDeleteCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling deleteCarrierCar.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling deleteCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteCarrierCar(
    requestParameters: CarriersApiDeleteCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteCarrierCarRaw(requestParameters, initOverrides);
  }

  /**
   */
  async getCarrierCarRaw(
    requestParameters: CarriersApiGetCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCar.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling getCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCar(
    requestParameters: CarriersApiGetCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.getCarrierCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarByLicencePlateRaw(
    requestParameters: CarriersApiGetCarrierCarByLicencePlateRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCarByLicencePlate.',
      );
    }

    if (requestParameters.carLicencePlate === null || requestParameters.carLicencePlate === undefined) {
      throw new runtime.RequiredError(
        'carLicencePlate',
        'Required parameter requestParameters.carLicencePlate was null or undefined when calling getCarrierCarByLicencePlate.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/by_licence_plate/{car_licence_plate}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_licence_plate'}}`, encodeURIComponent(String(requestParameters.carLicencePlate))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCarByLicencePlate(
    requestParameters: CarriersApiGetCarrierCarByLicencePlateRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.getCarrierCarByLicencePlateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarCarServicesRaw(
    requestParameters: CarriersApiGetCarrierCarCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2001>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCarCarServices.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling getCarrierCarCarServices.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}/car_services`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCarCarServices(
    requestParameters: CarriersApiGetCarrierCarCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2001> {
    const response = await this.getCarrierCarCarServicesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarServiceRaw(
    requestParameters: CarriersApiGetCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2011>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCarService.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling getCarrierCarService.',
      );
    }

    if (requestParameters.carServiceId === null || requestParameters.carServiceId === undefined) {
      throw new runtime.RequiredError(
        'carServiceId',
        'Required parameter requestParameters.carServiceId was null or undefined when calling getCarrierCarService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}/car_services/{car_service_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId)))
          .replace(`{${'car_service_id'}}`, encodeURIComponent(String(requestParameters.carServiceId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2011FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCarService(
    requestParameters: CarriersApiGetCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2011> {
    const response = await this.getCarrierCarServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarServicesRaw(
    requestParameters: CarriersApiGetCarrierCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2001>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCarServices.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/car_services`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCarServices(
    requestParameters: CarriersApiGetCarrierCarServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2001> {
    const response = await this.getCarrierCarServicesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarsRaw(
    requestParameters: CarriersApiGetCarrierCarsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierCarsResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCars.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierCarsResponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCars(
    requestParameters: CarriersApiGetCarrierCarsRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierCarsResponse> {
    const response = await this.getCarrierCarsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierClientRaw(
    requestParameters: CarriersApiGetCarrierClientRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierClientResult>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierClient.',
      );
    }

    if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
      throw new runtime.RequiredError(
        'clientId',
        'Required parameter requestParameters.clientId was null or undefined when calling getCarrierClient.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/clients/{client_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'client_id'}}`, encodeURIComponent(String(requestParameters.clientId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierClientResultFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierClient(
    requestParameters: CarriersApiGetCarrierClientRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierClientResult> {
    const response = await this.getCarrierClientRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierClientsRaw(
    requestParameters: CarriersApiGetCarrierClientsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierClientsResult>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierClients.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/clients`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierClientsResultFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierClients(
    requestParameters: CarriersApiGetCarrierClientsRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierClientsResult> {
    const response = await this.getCarrierClientsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierDriverRaw(
    requestParameters: CarriersApiGetCarrierDriverRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierDriverReponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierDriver.',
      );
    }

    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
      throw new runtime.RequiredError(
        'driverId',
        'Required parameter requestParameters.driverId was null or undefined when calling getCarrierDriver.',
      );
    }

    if (requestParameters.fields === null || requestParameters.fields === undefined) {
      throw new runtime.RequiredError(
        'fields',
        'Required parameter requestParameters.fields was null or undefined when calling getCarrierDriver.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fields) {
      queryParameters['fields'] = requestParameters.fields.join(runtime.COLLECTION_FORMATS['csv']);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/drivers/{driver_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'driver_id'}}`, encodeURIComponent(String(requestParameters.driverId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierDriverReponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierDriver(
    requestParameters: CarriersApiGetCarrierDriverRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierDriverReponse> {
    const response = await this.getCarrierDriverRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierDriversRaw(
    requestParameters: CarriersApiGetCarrierDriversRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierDriversReponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierDrivers.',
      );
    }

    if (requestParameters.fields === null || requestParameters.fields === undefined) {
      throw new runtime.RequiredError(
        'fields',
        'Required parameter requestParameters.fields was null or undefined when calling getCarrierDrivers.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fields) {
      queryParameters['fields'] = requestParameters.fields.join(runtime.COLLECTION_FORMATS['csv']);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/drivers`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierDriversReponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierDrivers(
    requestParameters: CarriersApiGetCarrierDriversRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierDriversReponse> {
    const response = await this.getCarrierDriversRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierEmployeeRaw(
    requestParameters: CarriersApiGetCarrierEmployeeRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierEmployeeResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierEmployee.',
      );
    }

    if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
      throw new runtime.RequiredError(
        'employeeId',
        'Required parameter requestParameters.employeeId was null or undefined when calling getCarrierEmployee.',
      );
    }

    if (requestParameters.fields === null || requestParameters.fields === undefined) {
      throw new runtime.RequiredError(
        'fields',
        'Required parameter requestParameters.fields was null or undefined when calling getCarrierEmployee.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fields) {
      queryParameters['fields'] = requestParameters.fields.join(runtime.COLLECTION_FORMATS['csv']);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/employees/{employee_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'employee_id'}}`, encodeURIComponent(String(requestParameters.employeeId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierEmployeeResponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierEmployee(
    requestParameters: CarriersApiGetCarrierEmployeeRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierEmployeeResponse> {
    const response = await this.getCarrierEmployeeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierEmployeesRaw(
    requestParameters: CarriersApiGetCarrierEmployeesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierEmployeesResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierEmployees.',
      );
    }

    if (requestParameters.fields === null || requestParameters.fields === undefined) {
      throw new runtime.RequiredError(
        'fields',
        'Required parameter requestParameters.fields was null or undefined when calling getCarrierEmployees.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fields) {
      queryParameters['fields'] = requestParameters.fields.join(runtime.COLLECTION_FORMATS['csv']);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/employees`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierEmployeesResponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierEmployees(
    requestParameters: CarriersApiGetCarrierEmployeesRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierEmployeesResponse> {
    const response = await this.getCarrierEmployeesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierLoadRaw(
    requestParameters: CarriersApiGetCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierLoadResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierLoad.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getCarrierLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads/{load_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierLoadResponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierLoad(
    requestParameters: CarriersApiGetCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierLoadResponse> {
    const response = await this.getCarrierLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierLoadOptimizedRouteRaw(
    requestParameters: CarriersApiGetCarrierLoadOptimizedRouteRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierShipmentsResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierLoadOptimizedRoute.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getCarrierLoadOptimizedRoute.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads/{load_id}/route`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierShipmentsResponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierLoadOptimizedRoute(
    requestParameters: CarriersApiGetCarrierLoadOptimizedRouteRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierShipmentsResponse> {
    const response = await this.getCarrierLoadOptimizedRouteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierLoadShipmentsRaw(
    requestParameters: CarriersApiGetCarrierLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierShipmentsResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierLoadShipments.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getCarrierLoadShipments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads/{load_id}/shipments`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierShipmentsResponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierLoadShipments(
    requestParameters: CarriersApiGetCarrierLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierShipmentsResponse> {
    const response = await this.getCarrierLoadShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierLoadsRaw(
    requestParameters: CarriersApiGetCarrierLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierLoadsResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierLoads.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.driveDateRangeStartsAt !== undefined) {
      queryParameters['drive_date_range_starts_at'] = (requestParameters.driveDateRangeStartsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    if (requestParameters.driveDateRangeEndsAt !== undefined) {
      queryParameters['drive_date_range_ends_at'] = (requestParameters.driveDateRangeEndsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierLoadsResponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierLoads(
    requestParameters: CarriersApiGetCarrierLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierLoadsResponse> {
    const response = await this.getCarrierLoadsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierShipmentRaw(
    requestParameters: CarriersApiGetCarrierShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierShipmentResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierShipment.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getCarrierShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/shipments/{shipment_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierShipmentResponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierShipment(
    requestParameters: CarriersApiGetCarrierShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierShipmentResponse> {
    const response = await this.getCarrierShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierShipmentsRaw(
    requestParameters: CarriersApiGetCarrierShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierShipmentsResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierShipments.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.coordinationDateRangeStartsAt !== undefined) {
      queryParameters['coordination_date_range_starts_at'] = (requestParameters.coordinationDateRangeStartsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    if (requestParameters.coordinationDateRangeEndsAt !== undefined) {
      queryParameters['coordination_date_range_ends_at'] = (requestParameters.coordinationDateRangeEndsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/shipments`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCarrierShipmentsResponseFromJSON(jsonValue));
  }

  /**
   */
  async getCarrierShipments(
    requestParameters: CarriersApiGetCarrierShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierShipmentsResponse> {
    const response = await this.getCarrierShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierUnhandledShipmentRowsRaw(
    requestParameters: CarriersApiGetCarrierUnhandledShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<GetCarrierUnhandledShipmentRowsResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierUnhandledShipmentRows.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.coordinationDateRangeStartsAt !== undefined) {
      queryParameters['coordination_date_range_starts_at'] = (requestParameters.coordinationDateRangeStartsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    if (requestParameters.coordinationDateRangeEndsAt !== undefined) {
      queryParameters['coordination_date_range_ends_at'] = (requestParameters.coordinationDateRangeEndsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/shipments/unhandled_shipment_rows`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetCarrierUnhandledShipmentRowsResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async getCarrierUnhandledShipmentRows(
    requestParameters: CarriersApiGetCarrierUnhandledShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<GetCarrierUnhandledShipmentRowsResponse> {
    const response = await this.getCarrierUnhandledShipmentRowsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async patchCarrierLoadShipmentsRaw(
    requestParameters: CarriersApiPatchCarrierLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling patchCarrierLoadShipments.',
      );
    }

    if (
      requestParameters.carrierShipmentLoadUpdate === null ||
      requestParameters.carrierShipmentLoadUpdate === undefined
    ) {
      throw new runtime.RequiredError(
        'carrierShipmentLoadUpdate',
        'Required parameter requestParameters.carrierShipmentLoadUpdate was null or undefined when calling patchCarrierLoadShipments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads/shipments`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.carrierShipmentLoadUpdate.map(CarrierShipmentLoadUpdateToJSON),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async patchCarrierLoadShipments(
    requestParameters: CarriersApiPatchCarrierLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.patchCarrierLoadShipmentsRaw(requestParameters, initOverrides);
  }

  /**
   */
  async postCarrierCarRaw(
    requestParameters: CarriersApiPostCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierCar.',
      );
    }

    if (requestParameters.carrierCarPostBody === null || requestParameters.carrierCarPostBody === undefined) {
      throw new runtime.RequiredError(
        'carrierCarPostBody',
        'Required parameter requestParameters.carrierCarPostBody was null or undefined when calling postCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarPostBodyToJSON(requestParameters.carrierCarPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierCar(
    requestParameters: CarriersApiPostCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.postCarrierCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierCarServiceRaw(
    requestParameters: CarriersApiPostCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2011>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierCarService.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling postCarrierCarService.',
      );
    }

    if (
      requestParameters.carrierCarServicePostBody === null ||
      requestParameters.carrierCarServicePostBody === undefined
    ) {
      throw new runtime.RequiredError(
        'carrierCarServicePostBody',
        'Required parameter requestParameters.carrierCarServicePostBody was null or undefined when calling postCarrierCarService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}/car_services`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarServicePostBodyToJSON(requestParameters.carrierCarServicePostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2011FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierCarService(
    requestParameters: CarriersApiPostCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2011> {
    const response = await this.postCarrierCarServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierClientRaw(
    requestParameters: CarriersApiPostCarrierClientRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2012>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierClient.',
      );
    }

    if (requestParameters.carrierClientPostBody === null || requestParameters.carrierClientPostBody === undefined) {
      throw new runtime.RequiredError(
        'carrierClientPostBody',
        'Required parameter requestParameters.carrierClientPostBody was null or undefined when calling postCarrierClient.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/clients`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierClientPostBodyToJSON(requestParameters.carrierClientPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2012FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierClient(
    requestParameters: CarriersApiPostCarrierClientRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2012> {
    const response = await this.postCarrierClientRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierEmployeeRaw(
    requestParameters: CarriersApiPostCarrierEmployeeRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2013>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierEmployee.',
      );
    }

    if (requestParameters.carrierEmployeePostBody === null || requestParameters.carrierEmployeePostBody === undefined) {
      throw new runtime.RequiredError(
        'carrierEmployeePostBody',
        'Required parameter requestParameters.carrierEmployeePostBody was null or undefined when calling postCarrierEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/employees`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierEmployeePostBodyToJSON(requestParameters.carrierEmployeePostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2013FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierEmployee(
    requestParameters: CarriersApiPostCarrierEmployeeRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2013> {
    const response = await this.postCarrierEmployeeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierLoadRaw(
    requestParameters: CarriersApiPostCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2014>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierLoad.',
      );
    }

    if (requestParameters.carrierLoadPostBody === null || requestParameters.carrierLoadPostBody === undefined) {
      throw new runtime.RequiredError(
        'carrierLoadPostBody',
        'Required parameter requestParameters.carrierLoadPostBody was null or undefined when calling postCarrierLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierLoadPostBodyToJSON(requestParameters.carrierLoadPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2014FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierLoad(
    requestParameters: CarriersApiPostCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2014> {
    const response = await this.postCarrierLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierShipmentRaw(
    requestParameters: CarriersApiPostCarrierShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2015>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierShipment.',
      );
    }

    if (requestParameters.carrierShipmentPostBody === null || requestParameters.carrierShipmentPostBody === undefined) {
      throw new runtime.RequiredError(
        'carrierShipmentPostBody',
        'Required parameter requestParameters.carrierShipmentPostBody was null or undefined when calling postCarrierShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/shipments`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierShipmentPostBodyToJSON(requestParameters.carrierShipmentPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2015FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierShipment(
    requestParameters: CarriersApiPostCarrierShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2015> {
    const response = await this.postCarrierShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async putCarrierCarRaw(
    requestParameters: CarriersApiPutCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling putCarrierCar.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling putCarrierCar.',
      );
    }

    if (requestParameters.carrierCarPutBody === null || requestParameters.carrierCarPutBody === undefined) {
      throw new runtime.RequiredError(
        'carrierCarPutBody',
        'Required parameter requestParameters.carrierCarPutBody was null or undefined when calling putCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarPutBodyToJSON(requestParameters.carrierCarPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async putCarrierCar(
    requestParameters: CarriersApiPutCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.putCarrierCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async putCarrierCarServiceRaw(
    requestParameters: CarriersApiPutCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2011>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling putCarrierCarService.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling putCarrierCarService.',
      );
    }

    if (requestParameters.carServiceId === null || requestParameters.carServiceId === undefined) {
      throw new runtime.RequiredError(
        'carServiceId',
        'Required parameter requestParameters.carServiceId was null or undefined when calling putCarrierCarService.',
      );
    }

    if (
      requestParameters.carrierCarServicePutBody === null ||
      requestParameters.carrierCarServicePutBody === undefined
    ) {
      throw new runtime.RequiredError(
        'carrierCarServicePutBody',
        'Required parameter requestParameters.carrierCarServicePutBody was null or undefined when calling putCarrierCarService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}/car_services/{car_service_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId)))
          .replace(`{${'car_service_id'}}`, encodeURIComponent(String(requestParameters.carServiceId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarServicePutBodyToJSON(requestParameters.carrierCarServicePutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2011FromJSON(jsonValue));
  }

  /**
   */
  async putCarrierCarService(
    requestParameters: CarriersApiPutCarrierCarServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2011> {
    const response = await this.putCarrierCarServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async putCarrierEmployeeRaw(
    requestParameters: CarriersApiPutCarrierEmployeeRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PutCarrierEmployeeResponse>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling putCarrierEmployee.',
      );
    }

    if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
      throw new runtime.RequiredError(
        'employeeId',
        'Required parameter requestParameters.employeeId was null or undefined when calling putCarrierEmployee.',
      );
    }

    if (requestParameters.carrierEmployeePutBody === null || requestParameters.carrierEmployeePutBody === undefined) {
      throw new runtime.RequiredError(
        'carrierEmployeePutBody',
        'Required parameter requestParameters.carrierEmployeePutBody was null or undefined when calling putCarrierEmployee.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/employees/{employee_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'employee_id'}}`, encodeURIComponent(String(requestParameters.employeeId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierEmployeePutBodyToJSON(requestParameters.carrierEmployeePutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PutCarrierEmployeeResponseFromJSON(jsonValue));
  }

  /**
   */
  async putCarrierEmployee(
    requestParameters: CarriersApiPutCarrierEmployeeRequest,
    initOverrides?: RequestInit,
  ): Promise<PutCarrierEmployeeResponse> {
    const response = await this.putCarrierEmployeeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async putCarrierLoadRaw(
    requestParameters: CarriersApiPutCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2014>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling putCarrierLoad.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling putCarrierLoad.',
      );
    }

    if (requestParameters.carrierLoadPutBody === null || requestParameters.carrierLoadPutBody === undefined) {
      throw new runtime.RequiredError(
        'carrierLoadPutBody',
        'Required parameter requestParameters.carrierLoadPutBody was null or undefined when calling putCarrierLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads/{load_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierLoadPutBodyToJSON(requestParameters.carrierLoadPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2014FromJSON(jsonValue));
  }

  /**
   */
  async putCarrierLoad(
    requestParameters: CarriersApiPutCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2014> {
    const response = await this.putCarrierLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
