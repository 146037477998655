import { Action, State } from './reports.state';
import React, { Dispatch } from 'react';
import { Button, styled } from '@mui/material';
import { api, Client } from '../../api';
import { ClientPicker } from '../../components/ClientPicker';
import { DateRangePicker } from '../../components/DateRangePicker';
import { DateTime } from 'luxon';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Loading } from '../../components/Loading';
import { getDateRangePickerPropsFromState } from '../../utils/dateRangeUtils';

interface RecalculatePricesProps {
  state: State;
  dispatch: Dispatch<Action>;
}

const ActionContainer = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
});

export const RecalculatePrices: React.FC<RecalculatePricesProps> = ({ state, dispatch }) => {
  const [selectedOrganization, setSelectedOrganization] = React.useState<Client | null>(null);

  return (
    <>
      <div style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'center' }}>
        <Loading isLoading={state.isLoading} />
        <ActionContainer>
          <DateRangePicker {...getDateRangePickerPropsFromState(state, dispatch)} />
          <ClientPicker
            variant="outlined"
            size="small"
            style={{ marginTop: '0.5rem' }}
            disabled={state.isLoading}
            clients={state.clients}
            value={selectedOrganization}
            onChange={(client) => setSelectedOrganization(client)}
            dataCy={'client-picker'}
          />
          <Button
            sx={{ marginTop: '0.5rem' }}
            data-cy="recalculate-prices-button"
            disabled={state.isLoading || !state.dateRange?.start || !state.dateRange?.end || !selectedOrganization?.id}
            onClick={async () => {
              if (!selectedOrganization?.id || !state.dateRange?.start || !state.dateRange?.end) {
                return;
              }
              dispatch({ type: 'SET_LOADING', payload: true });
              const startDate = state.dateRange.start as DateTime;
              const endDate = state.dateRange.end as DateTime;
              const organization_id = selectedOrganization.id as string;
              let currentDate = startDate.startOf('day').plus({ hours: 12 });
              while (currentDate <= endDate) {
                try {
                  await api.pricing.calculateShipmentPricing({
                    shipmentPricingPostBody: { organization_id, date: currentDate.toJSDate() },
                  });
                } catch (err) {
                  dispatch({ type: 'SET_MESSAGE', payload: { message: 'Virhe hintojen laskennassa' } });
                  console.error(err);
                  dispatch({ type: 'SET_LOADING', payload: false });
                  return;
                }
                currentDate = currentDate.plus({ days: 1 });
              }
              dispatch({ type: 'SET_MESSAGE', payload: { message: 'Hinnat laskettu uudelleen' } });
              dispatch({ type: 'SET_LOADING', payload: false });
            }}
          >
            Laske hinnat uudelleen
          </Button>
        </ActionContainer>
      </div>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};
