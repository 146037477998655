import { TextField, TextFieldProps } from '@mui/material';
import { Fields } from '../SearchDistances';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import React from 'react';

type FormInputProps = TextFieldProps & {
  name: keyof Fields;
  defaultValue?: string;
  formMethods?: UseFormReturn<Fields>;
};

export const FormInput: React.FC<FormInputProps> = (props) => {
  const { control } = useFormContext<Fields>();
  return (
    <Controller<Fields>
      render={({ field }) => <TextField {...field} sx={{ marginBottom: '0.25rem' }} label={props.label} />}
      control={control}
      fullWidth={true}
      {...props}
    />
  );
};
