/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows
 */
export interface ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows {
  /**
   *
   * @type {number}
   * @memberof ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows
   */
  state: ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsStateEnum;
  /**
   *
   * @type {string}
   * @memberof ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows
   */
  joint_package_id?: string | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsStateEnum {
  ReadyForPickup = 'ready_for_pickup',
  PickedUp = 'picked_up',
  Delivered = 'delivered',
  Lost = 'lost',
  Failed = 'failed',
}

export function ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsFromJSON(
  json: any,
): ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows {
  return ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsFromJSONTyped(json, false);
}

export function ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    state: json['state'],
    joint_package_id: !exists(json, 'joint_package_id') ? undefined : json['joint_package_id'],
  };
}

export function ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRowsToJSON(
  value?: ApiV1DriversUsernameLoadsLoadIdShipmentsShipmentIdRowsRows | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    state: value.state,
    joint_package_id: value.joint_package_id,
  };
}
