/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface WorkHourPostBody
 */
export interface WorkHourPostBody {
  /**
   *
   * @type {string}
   * @memberof WorkHourPostBody
   */
  employee_number: string | null;
  /**
   *
   * @type {Date}
   * @memberof WorkHourPostBody
   */
  date: Date | null;
  /**
   *
   * @type {string}
   * @memberof WorkHourPostBody
   */
  starts_at: string | null;
  /**
   *
   * @type {string}
   * @memberof WorkHourPostBody
   */
  ends_at: string | null;
  /**
   *
   * @type {number}
   * @memberof WorkHourPostBody
   */
  assignment_id: number;
  /**
   *
   * @type {string}
   * @memberof WorkHourPostBody
   */
  note: string | null;
}

export function WorkHourPostBodyFromJSON(json: any): WorkHourPostBody {
  return WorkHourPostBodyFromJSONTyped(json, false);
}

export function WorkHourPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkHourPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    employee_number: json['employee_number'],
    date: json['date'] === null ? null : new Date(json['date']),
    starts_at: json['starts_at'],
    ends_at: json['ends_at'],
    assignment_id: json['assignment_id'],
    note: json['note'],
  };
}

export function WorkHourPostBodyToJSON(value?: WorkHourPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employee_number: value.employee_number,
    date: value.date === null ? null : value.date.toISOString(),
    starts_at: value.starts_at,
    ends_at: value.ends_at,
    assignment_id: value.assignment_id,
    note: value.note,
  };
}
