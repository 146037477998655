/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AssignmentsResponseBody,
  AssignmentsResponseBodyFromJSON,
  AssignmentsResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

/**
 *
 */
export class AssignmentsApi extends runtime.BaseAPI {
  /**
   * Get all assignments.
   */
  async getAssignmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AssignmentsResponseBody>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/assignments`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssignmentsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all assignments.
   */
  async getAssignments(initOverrides?: RequestInit): Promise<AssignmentsResponseBody> {
    const response = await this.getAssignmentsRaw(initOverrides);
    return await response.value();
  }
}
