/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  DriverGroupDriversEditBody,
  DriverGroupDriversEditBodyFromJSON,
  DriverGroupDriversEditBodyToJSON,
  DriverGroupGetResponseBody,
  DriverGroupGetResponseBodyFromJSON,
  DriverGroupGetResponseBodyToJSON,
  DriverGroupPostBody,
  DriverGroupPostBodyFromJSON,
  DriverGroupPostBodyToJSON,
  DriverGroupsPostBody,
  DriverGroupsPostBodyFromJSON,
  DriverGroupsPostBodyToJSON,
  DriverGroupsPostResponseBody,
  DriverGroupsPostResponseBodyFromJSON,
  DriverGroupsPostResponseBodyToJSON,
  DriverGroupsResponseBody1,
  DriverGroupsResponseBody1FromJSON,
  DriverGroupsResponseBody1ToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

export interface DriverGroupsApiAddDriverGroupRequest {
  driverGroupsPostBody: DriverGroupsPostBody;
}

export interface DriverGroupsApiDeleteDriverGroupRequest {
  driverGroupId: string;
}

export interface DriverGroupsApiDeleteDriverGroupDriversRequest {
  driverGroupId: string;
  driverGroupDriversEditBody: DriverGroupDriversEditBody;
}

export interface DriverGroupsApiGetDriverGroupRequest {
  driverGroupId: string;
}

export interface DriverGroupsApiUpdateDriverGroupRequest {
  driverGroupId: string;
  driverGroupPostBody: DriverGroupPostBody;
}

export interface DriverGroupsApiUpdateDriverGroupDriversRequest {
  driverGroupId: string;
  driverGroupDriversEditBody: DriverGroupDriversEditBody;
}

/**
 *
 */
export class DriverGroupsApi extends runtime.BaseAPI {
  /**
   * Creates new driver group.
   */
  async addDriverGroupRaw(
    requestParameters: DriverGroupsApiAddDriverGroupRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverGroupsPostResponseBody>> {
    if (requestParameters.driverGroupsPostBody === null || requestParameters.driverGroupsPostBody === undefined) {
      throw new runtime.RequiredError(
        'driverGroupsPostBody',
        'Required parameter requestParameters.driverGroupsPostBody was null or undefined when calling addDriverGroup.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/driver_groups/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DriverGroupsPostBodyToJSON(requestParameters.driverGroupsPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverGroupsPostResponseBodyFromJSON(jsonValue));
  }

  /**
   * Creates new driver group.
   */
  async addDriverGroup(
    requestParameters: DriverGroupsApiAddDriverGroupRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverGroupsPostResponseBody> {
    const response = await this.addDriverGroupRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Deletes driver group.
   */
  async deleteDriverGroupRaw(
    requestParameters: DriverGroupsApiDeleteDriverGroupRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.driverGroupId === null || requestParameters.driverGroupId === undefined) {
      throw new runtime.RequiredError(
        'driverGroupId',
        'Required parameter requestParameters.driverGroupId was null or undefined when calling deleteDriverGroup.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/driver_groups/{driver_group_id}`.replace(
          `{${'driver_group_id'}}`,
          encodeURIComponent(String(requestParameters.driverGroupId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes driver group.
   */
  async deleteDriverGroup(
    requestParameters: DriverGroupsApiDeleteDriverGroupRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteDriverGroupRaw(requestParameters, initOverrides);
  }

  /**
   * Delete drivers from group
   */
  async deleteDriverGroupDriversRaw(
    requestParameters: DriverGroupsApiDeleteDriverGroupDriversRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.driverGroupId === null || requestParameters.driverGroupId === undefined) {
      throw new runtime.RequiredError(
        'driverGroupId',
        'Required parameter requestParameters.driverGroupId was null or undefined when calling deleteDriverGroupDrivers.',
      );
    }

    if (
      requestParameters.driverGroupDriversEditBody === null ||
      requestParameters.driverGroupDriversEditBody === undefined
    ) {
      throw new runtime.RequiredError(
        'driverGroupDriversEditBody',
        'Required parameter requestParameters.driverGroupDriversEditBody was null or undefined when calling deleteDriverGroupDrivers.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/driver_groups/{driver_group_id}/drivers`.replace(
          `{${'driver_group_id'}}`,
          encodeURIComponent(String(requestParameters.driverGroupId)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: DriverGroupDriversEditBodyToJSON(requestParameters.driverGroupDriversEditBody),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete drivers from group
   */
  async deleteDriverGroupDrivers(
    requestParameters: DriverGroupsApiDeleteDriverGroupDriversRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteDriverGroupDriversRaw(requestParameters, initOverrides);
  }

  /**
   * Get all driver groups.
   */
  async getAllDriverGroupsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<DriverGroupsResponseBody1>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/driver_groups/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverGroupsResponseBody1FromJSON(jsonValue));
  }

  /**
   * Get all driver groups.
   */
  async getAllDriverGroups(initOverrides?: RequestInit): Promise<DriverGroupsResponseBody1> {
    const response = await this.getAllDriverGroupsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Gets driver group.
   */
  async getDriverGroupRaw(
    requestParameters: DriverGroupsApiGetDriverGroupRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverGroupGetResponseBody>> {
    if (requestParameters.driverGroupId === null || requestParameters.driverGroupId === undefined) {
      throw new runtime.RequiredError(
        'driverGroupId',
        'Required parameter requestParameters.driverGroupId was null or undefined when calling getDriverGroup.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/driver_groups/{driver_group_id}`.replace(
          `{${'driver_group_id'}}`,
          encodeURIComponent(String(requestParameters.driverGroupId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverGroupGetResponseBodyFromJSON(jsonValue));
  }

  /**
   * Gets driver group.
   */
  async getDriverGroup(
    requestParameters: DriverGroupsApiGetDriverGroupRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverGroupGetResponseBody> {
    const response = await this.getDriverGroupRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Updates driver group.
   */
  async updateDriverGroupRaw(
    requestParameters: DriverGroupsApiUpdateDriverGroupRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverGroupsPostResponseBody>> {
    if (requestParameters.driverGroupId === null || requestParameters.driverGroupId === undefined) {
      throw new runtime.RequiredError(
        'driverGroupId',
        'Required parameter requestParameters.driverGroupId was null or undefined when calling updateDriverGroup.',
      );
    }

    if (requestParameters.driverGroupPostBody === null || requestParameters.driverGroupPostBody === undefined) {
      throw new runtime.RequiredError(
        'driverGroupPostBody',
        'Required parameter requestParameters.driverGroupPostBody was null or undefined when calling updateDriverGroup.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/driver_groups/{driver_group_id}`.replace(
          `{${'driver_group_id'}}`,
          encodeURIComponent(String(requestParameters.driverGroupId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DriverGroupPostBodyToJSON(requestParameters.driverGroupPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverGroupsPostResponseBodyFromJSON(jsonValue));
  }

  /**
   * Updates driver group.
   */
  async updateDriverGroup(
    requestParameters: DriverGroupsApiUpdateDriverGroupRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverGroupsPostResponseBody> {
    const response = await this.updateDriverGroupRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Adds drivers to group
   */
  async updateDriverGroupDriversRaw(
    requestParameters: DriverGroupsApiUpdateDriverGroupDriversRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.driverGroupId === null || requestParameters.driverGroupId === undefined) {
      throw new runtime.RequiredError(
        'driverGroupId',
        'Required parameter requestParameters.driverGroupId was null or undefined when calling updateDriverGroupDrivers.',
      );
    }

    if (
      requestParameters.driverGroupDriversEditBody === null ||
      requestParameters.driverGroupDriversEditBody === undefined
    ) {
      throw new runtime.RequiredError(
        'driverGroupDriversEditBody',
        'Required parameter requestParameters.driverGroupDriversEditBody was null or undefined when calling updateDriverGroupDrivers.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/driver_groups/{driver_group_id}/drivers`.replace(
          `{${'driver_group_id'}}`,
          encodeURIComponent(String(requestParameters.driverGroupId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DriverGroupDriversEditBodyToJSON(requestParameters.driverGroupDriversEditBody),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Adds drivers to group
   */
  async updateDriverGroupDrivers(
    requestParameters: DriverGroupsApiUpdateDriverGroupDriversRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.updateDriverGroupDriversRaw(requestParameters, initOverrides);
  }
}
