import { InputLabel, styled, TextFieldProps } from '@mui/material';
import React from 'react';

export type ImmutableTextFieldProps = TextFieldProps;

const Container = styled('div')({
  marginBottom: '0.75rem',
});

const Label = styled(InputLabel)({
  lineHeight: '1rem',
});

export const ImmutableTextField: React.FC<ImmutableTextFieldProps> = ({ className, id, label, children }) => (
  <Container className={className} id={id}>
    <Label filled shrink>
      {label}
    </Label>
    {children ?? '-'}
  </Container>
);
