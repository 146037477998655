/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A resource that can be added into Shipments as ShipmentRows.
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {number}
   * @memberof Product
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  short_name: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  readonly organization_id: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  group: string | null;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  width_mm: number | null;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  length_mm: number | null;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  height_mm: number | null;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  weight_kg: number | null;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  order: number;
}

export function ProductFromJSON(json: any): Product {
  return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    short_name: json['short_name'],
    organization_id: json['organization_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    group: json['group'],
    width_mm: json['width_mm'],
    length_mm: json['length_mm'],
    height_mm: json['height_mm'],
    weight_kg: json['weight_kg'],
    order: json['order'],
  };
}

export function ProductToJSON(value?: Product | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    short_name: value.short_name,
    name: value.name,
    group: value.group,
    width_mm: value.width_mm,
    length_mm: value.length_mm,
    height_mm: value.height_mm,
    weight_kg: value.weight_kg,
    order: value.order,
  };
}
