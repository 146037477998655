/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Information about additional service sent with the shipment create or update request
 * @export
 * @interface ShipmentUpdateAdditionalServicePutBody
 */
export interface ShipmentUpdateAdditionalServicePutBody {
  /**
   *
   * @type {number}
   * @memberof ShipmentUpdateAdditionalServicePutBody
   */
  additional_service_id: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentUpdateAdditionalServicePutBody
   */
  shipment_additional_service_id?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentUpdateAdditionalServicePutBody
   */
  amount: number;
}

export function ShipmentUpdateAdditionalServicePutBodyFromJSON(json: any): ShipmentUpdateAdditionalServicePutBody {
  return ShipmentUpdateAdditionalServicePutBodyFromJSONTyped(json, false);
}

export function ShipmentUpdateAdditionalServicePutBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShipmentUpdateAdditionalServicePutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    additional_service_id: json['additional_service_id'],
    shipment_additional_service_id: !exists(json, 'shipment_additional_service_id')
      ? undefined
      : json['shipment_additional_service_id'],
    amount: json['amount'],
  };
}

export function ShipmentUpdateAdditionalServicePutBodyToJSON(
  value?: ShipmentUpdateAdditionalServicePutBody | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    additional_service_id: value.additional_service_id,
    shipment_additional_service_id: value.shipment_additional_service_id,
    amount: value.amount,
  };
}
