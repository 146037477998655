/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ShipmentStateEnum {
  EiVarastossa = 'ei varastossa',
  Noudettavissa = 'noudettavissa',
  Noutokohteessa = 'noutokohteessa',
  Noudettu = 'noudettu',
  Toimituskohteessa = 'toimituskohteessa',
  Toimitettu = 'toimitettu',
  Peruttu = 'peruttu',
  Odottaa = 'odottaa',
}

export function ShipmentStateEnumFromJSON(json: any): ShipmentStateEnum {
  return ShipmentStateEnumFromJSONTyped(json, false);
}

export function ShipmentStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentStateEnum {
  return json as ShipmentStateEnum;
}

export function ShipmentStateEnumToJSON(value?: ShipmentStateEnum | null): any {
  return value as any;
}
