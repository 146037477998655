import React, { BaseSyntheticEvent, MutableRefObject } from 'react';
import { styled } from '@mui/material';

export interface ExtraScrollbarProps {
  onExtraScrollbarScroll: any;
  scrollbarRef: any;
}

const Scrollbar = styled('div')(({ theme }) => ({
  zIndex: 1,
  overflowX: 'auto',
  width: 'calc(100% - 64px)',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 32px)',
  },
  position: 'fixed',
  bottom: 0,
}));

const getTableWidth = () => {
  const reactTableElement = document.getElementsByClassName('react-table')[0];
  const tableWidth = reactTableElement ? reactTableElement.clientWidth : 0;
  return tableWidth;
};

export const onTableScroll = (
  e: BaseSyntheticEvent,
  scrollbarRef: MutableRefObject<HTMLDivElement | null>,
  syncingTableScroll: MutableRefObject<boolean>,
  syncingExtraScrollbar: MutableRefObject<boolean>,
): void => {
  if (!syncingTableScroll.current && scrollbarRef.current) {
    syncingExtraScrollbar.current = true;
    scrollbarRef.current.scrollLeft = e.target.scrollLeft;
  }
  syncingTableScroll.current = false;
};

export const onExtraScrollbarScroll = (
  e: BaseSyntheticEvent,
  tableScrollRef: MutableRefObject<HTMLDivElement | null>,
  syncingTableScroll: MutableRefObject<boolean>,
  syncingExtraScrollbar: MutableRefObject<boolean>,
): void => {
  if (!syncingExtraScrollbar.current && tableScrollRef.current) {
    syncingTableScroll.current = true;
    tableScrollRef.current.scrollLeft = e.target.scrollLeft;
  }
  syncingExtraScrollbar.current = false;
};

export const ExtraScrollbar: React.FC<ExtraScrollbarProps> = ({ onExtraScrollbarScroll, scrollbarRef }) => {
  const scrollbarElement = document.getElementById('extra-scrollbar');
  const scrollbarHeight = 16;

  document.onscroll = () => {
    if (scrollbarElement) {
      if (window.innerHeight + scrollbarHeight + window.scrollY > document.body.clientHeight) {
        scrollbarElement.style.visibility = 'hidden';
      } else {
        scrollbarElement.style.visibility = 'visible';
      }
    }
  };

  return (
    <Scrollbar
      style={
        window.innerWidth <= document.body.clientWidth || window.innerWidth >= getTableWidth()
          ? { visibility: 'hidden' }
          : { visibility: 'visible' }
      }
      onScroll={onExtraScrollbarScroll}
      ref={scrollbarRef}
      id="extra-scrollbar"
    >
      <div style={{ width: getTableWidth(), height: '20px' }}></div>
    </Scrollbar>
  );
};
