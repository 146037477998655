/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PriceBasisAdditionalServices
 */
export interface PriceBasisAdditionalServices {
  /**
   *
   * @type {string}
   * @memberof PriceBasisAdditionalServices
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PriceBasisAdditionalServices
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof PriceBasisAdditionalServices
   */
  unit_price?: number;
}

export function PriceBasisAdditionalServicesFromJSON(json: any): PriceBasisAdditionalServices {
  return PriceBasisAdditionalServicesFromJSONTyped(json, false);
}

export function PriceBasisAdditionalServicesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PriceBasisAdditionalServices {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    amount: !exists(json, 'amount') ? undefined : json['amount'],
    unit_price: !exists(json, 'unit_price') ? undefined : json['unit_price'],
  };
}

export function PriceBasisAdditionalServicesToJSON(value?: PriceBasisAdditionalServices | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    amount: value.amount,
    unit_price: value.unit_price,
  };
}
