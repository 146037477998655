/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CreateNewUserPostBody,
  CreateNewUserPostBodyFromJSON,
  CreateNewUserPostBodyToJSON,
  CreateNewUserPostResponseBody,
  CreateNewUserPostResponseBodyFromJSON,
  CreateNewUserPostResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

export interface CreateNewUserApiCreateNewUserRequest {
  createNewUserToken: string;
  createNewUserPostBody: CreateNewUserPostBody;
}

/**
 *
 */
export class CreateNewUserApi extends runtime.BaseAPI {
  /**
   * New user was successfully created.
   */
  async createNewUserRaw(
    requestParameters: CreateNewUserApiCreateNewUserRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<CreateNewUserPostResponseBody>> {
    if (requestParameters.createNewUserToken === null || requestParameters.createNewUserToken === undefined) {
      throw new runtime.RequiredError(
        'createNewUserToken',
        'Required parameter requestParameters.createNewUserToken was null or undefined when calling createNewUser.',
      );
    }

    if (requestParameters.createNewUserPostBody === null || requestParameters.createNewUserPostBody === undefined) {
      throw new runtime.RequiredError(
        'createNewUserPostBody',
        'Required parameter requestParameters.createNewUserPostBody was null or undefined when calling createNewUser.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.createNewUserToken !== undefined) {
      queryParameters['create_new_user_token'] = requestParameters.createNewUserToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/create_user`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateNewUserPostBodyToJSON(requestParameters.createNewUserPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CreateNewUserPostResponseBodyFromJSON(jsonValue));
  }

  /**
   * New user was successfully created.
   */
  async createNewUser(
    requestParameters: CreateNewUserApiCreateNewUserRequest,
    initOverrides?: RequestInit,
  ): Promise<CreateNewUserPostResponseBody> {
    const response = await this.createNewUserRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
