/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  OrganizationPricingModelsResponseBody,
  OrganizationPricingModelsResponseBodyFromJSON,
  OrganizationPricingModelsResponseBodyToJSON,
} from '../models';

export interface OrganizationPricingApiGetOrganizationPricingModelsRequest {
  organizationId: string;
}

/**
 *
 */
export class OrganizationPricingApi extends runtime.BaseAPI {
  /**
   * Get all organization pricing models.
   */
  async getOrganizationPricingModelsRaw(
    requestParameters: OrganizationPricingApiGetOrganizationPricingModelsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationPricingModelsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationPricingModels.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/pricing/pricing_models`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrganizationPricingModelsResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get all organization pricing models.
   */
  async getOrganizationPricingModels(
    requestParameters: OrganizationPricingApiGetOrganizationPricingModelsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationPricingModelsResponseBody> {
    const response = await this.getOrganizationPricingModelsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
