/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  GeographicalArea,
  GeographicalAreaFromJSON,
  GeographicalAreaFromJSONTyped,
  GeographicalAreaToJSON,
} from './GeographicalArea';

/**
 * An area that is used for pricing purposes. For example, capital region might have different prices than rest of Finland. A pricing area consists of several geographical areas
 * @export
 * @interface PricingArea
 */
export interface PricingArea {
  /**
   *
   * @type {number}
   * @memberof PricingArea
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof PricingArea
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PricingArea
   */
  readonly pricing_model: number;
  /**
   *
   * @type {boolean}
   * @memberof PricingArea
   */
  is_default_area?: boolean;
  /**
   *
   * @type {Array<GeographicalArea>}
   * @memberof PricingArea
   */
  geographical_areas: Array<GeographicalArea>;
}

export function PricingAreaFromJSON(json: any): PricingArea {
  return PricingAreaFromJSONTyped(json, false);
}

export function PricingAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingArea {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    pricing_model: json['pricing_model'],
    is_default_area: !exists(json, 'is_default_area') ? undefined : json['is_default_area'],
    geographical_areas: (json['geographical_areas'] as Array<any>).map(GeographicalAreaFromJSON),
  };
}

export function PricingAreaToJSON(value?: PricingArea | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    is_default_area: value.is_default_area,
    geographical_areas: (value.geographical_areas as Array<any>).map(GeographicalAreaToJSON),
  };
}
