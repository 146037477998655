/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A geographical area that belongs to some larger PricingArea.
 * @export
 * @interface GeographicalArea
 */
export interface GeographicalArea {
  /**
   *
   * @type {number}
   * @memberof GeographicalArea
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof GeographicalArea
   */
  readonly pricing_area: number;
  /**
   *
   * @type {string}
   * @memberof GeographicalArea
   */
  area_type: GeographicalAreaAreaTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GeographicalArea
   */
  name: string;
}

/**
 * @export
 * @enum {string}
 */
export enum GeographicalAreaAreaTypeEnum {
  PostalCode = 'postal_code',
  City = 'city',
}

export function GeographicalAreaFromJSON(json: any): GeographicalArea {
  return GeographicalAreaFromJSONTyped(json, false);
}

export function GeographicalAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeographicalArea {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    pricing_area: json['pricing_area'],
    area_type: json['area_type'],
    name: json['name'],
  };
}

export function GeographicalAreaToJSON(value?: GeographicalArea | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    area_type: value.area_type,
    name: value.name,
  };
}
