/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  OrganizationCarsResponseBody,
  OrganizationCarsResponseBodyFromJSON,
  OrganizationCarsResponseBodyToJSON,
} from '../models';

export interface OrganizationCarsApiGetOrganizationCarsRequest {
  organizationId: string;
  loadIds?: Array<number>;
  next?: number;
  driveDateRangeStartsAt?: Date;
  driveDateRangeEndsAt?: Date;
}

/**
 *
 */
export class OrganizationCarsApi extends runtime.BaseAPI {
  /**
   * Get all cars with limited info.
   */
  async getOrganizationCarsRaw(
    requestParameters: OrganizationCarsApiGetOrganizationCarsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationCarsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationCars.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.loadIds) {
      queryParameters['load_ids'] = requestParameters.loadIds.join(runtime.COLLECTION_FORMATS['csv']);
    }

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.driveDateRangeStartsAt !== undefined) {
      queryParameters['drive_date_range_starts_at'] = (requestParameters.driveDateRangeStartsAt as any).toISOString();
    }

    if (requestParameters.driveDateRangeEndsAt !== undefined) {
      queryParameters['drive_date_range_ends_at'] = (requestParameters.driveDateRangeEndsAt as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/cars`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationCarsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all cars with limited info.
   */
  async getOrganizationCars(
    requestParameters: OrganizationCarsApiGetOrganizationCarsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationCarsResponseBody> {
    const response = await this.getOrganizationCarsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
