/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SummaryResponseBodyShipments
 */
export interface SummaryResponseBodyShipments {
  /**
   *
   * @type {string}
   * @memberof SummaryResponseBodyShipments
   */
  organizationName?: string;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBodyShipments
   */
  notInStorageCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBodyShipments
   */
  readyForPickupCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBodyShipments
   */
  atPickupLocationCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBodyShipments
   */
  pickedUpCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBodyShipments
   */
  atDeliveryLocationCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBodyShipments
   */
  deliveredCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBodyShipments
   */
  cancelledCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBodyShipments
   */
  waitingCount?: number;
  /**
   *
   * @type {number}
   * @memberof SummaryResponseBodyShipments
   */
  totalCount?: number;
}

export function SummaryResponseBodyShipmentsFromJSON(json: any): SummaryResponseBodyShipments {
  return SummaryResponseBodyShipmentsFromJSONTyped(json, false);
}

export function SummaryResponseBodyShipmentsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SummaryResponseBodyShipments {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    organizationName: !exists(json, 'organizationName') ? undefined : json['organizationName'],
    notInStorageCount: !exists(json, 'notInStorageCount') ? undefined : json['notInStorageCount'],
    readyForPickupCount: !exists(json, 'readyForPickupCount') ? undefined : json['readyForPickupCount'],
    atPickupLocationCount: !exists(json, 'atPickupLocationCount') ? undefined : json['atPickupLocationCount'],
    pickedUpCount: !exists(json, 'pickedUpCount') ? undefined : json['pickedUpCount'],
    atDeliveryLocationCount: !exists(json, 'atDeliveryLocationCount') ? undefined : json['atDeliveryLocationCount'],
    deliveredCount: !exists(json, 'deliveredCount') ? undefined : json['deliveredCount'],
    cancelledCount: !exists(json, 'cancelledCount') ? undefined : json['cancelledCount'],
    waitingCount: !exists(json, 'waitingCount') ? undefined : json['waitingCount'],
    totalCount: !exists(json, 'totalCount') ? undefined : json['totalCount'],
  };
}

export function SummaryResponseBodyShipmentsToJSON(value?: SummaryResponseBodyShipments | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organizationName: value.organizationName,
    notInStorageCount: value.notInStorageCount,
    readyForPickupCount: value.readyForPickupCount,
    atPickupLocationCount: value.atPickupLocationCount,
    pickedUpCount: value.pickedUpCount,
    atDeliveryLocationCount: value.atDeliveryLocationCount,
    deliveredCount: value.deliveredCount,
    cancelledCount: value.cancelledCount,
    waitingCount: value.waitingCount,
    totalCount: value.totalCount,
  };
}
