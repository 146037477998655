import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  api,
  Client,
  PatchDriverLoadShipmentsByIdRequestBodyStateEnum as StateEnum,
  Photo,
  Shipment,
  ShipmentRow,
  ShipmentStateEnum,
  User,
} from '../../api';
import {
  AppBar,
  BottomNavigationAction,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Link,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { HeaderTableCell } from '../summary/components/StyledComponents';
import { DeliveryWindow } from '../../components/DeliveryWindow';
import theme from '../../theme';
import {
  ArrowBack,
  CameraAlt,
  Done,
  Edit,
  LocalShipping,
  PhotoCameraOutlined,
  PrecisionManufacturing,
  RvHookup,
  Save,
  Speed,
  Warning,
} from '@mui/icons-material';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from '../../components/Notification';
import {
  AdditionalServices,
  areAllFieldsValid,
  getInitialState,
  getPatchDriverLoadShipmentsByIdRequestBody,
  Hours,
  isStateEmpty,
  Notes,
  OtherContractNumber,
  RecipientAndState,
  Sizes,
  State,
  States,
} from './components/inputs';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { AddressLink } from '../../components/map';
import { RecipientDialog } from './components/RecipientDialog';
import { isNumber } from 'lodash';
import DriverCamera from './components/Camera';
import { ShipmentAddFileDialog } from '../shipments/components/ShipmentAddFileDialog';
import { createAdditionalServiceFields } from '../shipments/state/shipment.reducer';
import { AdditionalServiceField } from '../shipments/types/shipment.field.types';
import { CurrentUser } from '../../reducers/authReducer';

const UpdateStateAndRecipientButton = (props: {
  shipment: Shipment;
  userName: string;
  setShipment: Dispatch<SetStateAction<Shipment | null>>;
}) => {
  const navigate = useNavigate();
  const [recipient, setRecipient] = useState(props.shipment.recipient ?? '');
  const [openRecipientDialog, setOpenRecipientDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const changeState = async (newState: StateEnum, newRecipient?: Shipment['recipient']) => {
    if (loading || !props.shipment.load_id) {
      return;
    }
    try {
      setLoading(true);
      await api.drivers.patchDriverLoadShipment({
        username: props.userName,
        shipmentId: props.shipment.id,
        loadId: props.shipment.load_id,
        patchDriverLoadShipmentsByIdRequestBody: {
          state: newState,
          ...(newRecipient && { recipient: newRecipient }),
        },
      });
      const responseBody = await api.drivers.getDriverLoadShipment({
        username: props.userName,
        shipmentId: props.shipment.id,
        loadId: props.shipment.load_id,
      });
      props.setShipment(responseBody.data);
      navigate(-1);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const state = props.shipment.state;
  switch (state) {
    case ShipmentStateEnum.Noudettavissa:
      return (
        <BottomNavigationAction
          sx={{ color: theme.palette.success.dark, opacity: 1 }}
          icon={<LocalShipping />}
          showLabel={true}
          label="Merkitse noudetuksi"
          onClick={() => changeState(StateEnum.Noudettu)}
        />
      );
    case ShipmentStateEnum.Noudettu:
      return (
        <>
          <BottomNavigationAction
            sx={{ color: theme.palette.success.dark, opacity: 1 }}
            icon={<Done />}
            showLabel={true}
            label="Merkitse toimitetuksi"
            onClick={() => setOpenRecipientDialog(true)}
          />
          <RecipientDialog
            open={openRecipientDialog}
            recipient={recipient}
            setRecipient={setRecipient}
            onSave={() => changeState(StateEnum.Toimitettu, recipient)}
            onClose={() => setOpenRecipientDialog(false)}
            isLoading={loading}
          />
        </>
      );
    case ShipmentStateEnum.EiVarastossa:
    case ShipmentStateEnum.Noutokohteessa:
    case ShipmentStateEnum.Toimituskohteessa:
    case ShipmentStateEnum.Peruttu:
    case ShipmentStateEnum.Odottaa:
    case ShipmentStateEnum.Toimitettu:
      return null;
  }
};

const OrdererInfo: React.FC<{ shipment: Shipment }> = ({ shipment }) => {
  if (!shipment.orderer || !shipment.orderer_phone_number) {
    return <></>;
  }
  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontWeight="bold" gutterBottom>
            Tilaaja
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{shipment.orderer}</Typography>
          {shipment.orderer_phone_number && (
            <Link href={`tel:${shipment.orderer_phone_number}`}>{shipment.orderer_phone_number}</Link>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const PickUpInfo: React.FC<{ shipment: Shipment }> = ({ shipment }) => {
  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontWeight="bold" gutterBottom>
            Nouto
          </Typography>
          <Typography fontWeight="bold" gutterBottom>
            {shipment.id}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{shipment.pickup_name}</Typography>
          {shipment.pickup_phone_number && (
            <Link href={`tel:${shipment.pickup_phone_number}`}>{shipment.pickup_phone_number}</Link>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{shipment.pickup_address}</Typography>
          {shipment.pickup_phone_number_secondary && (
            <Link href={`tel:${shipment.pickup_phone_number_secondary}`}>{shipment.pickup_phone_number_secondary}</Link>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>
            {shipment.pickup_postal_code} {shipment.pickup_city}
          </Typography>
          <AddressLink
            title="Kartta"
            showIcon={true}
            address={shipment.pickup_address}
            postalCode={shipment.pickup_postal_code}
            city={shipment.pickup_city}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

const DeliveryInfo: React.FC<{ shipment: Shipment; state: State; setState: any; expanded: boolean }> = ({
  shipment,
  state,
  setState,
  expanded,
}) => {
  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontWeight="bold" gutterBottom>
            Toimitus
          </Typography>
          <DeliveryWindow
            onlyTime={true}
            startsAt={shipment.agreed_delivery_window_starts_at}
            endsAt={shipment.agreed_delivery_window_ends_at}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{shipment.delivery_name}</Typography>
          {shipment.delivery_phone_number && (
            <Link href={`tel:${shipment.delivery_phone_number}`}>{shipment.delivery_phone_number}</Link>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{shipment.delivery_address}</Typography>
          {shipment.delivery_phone_number_secondary && (
            <Link href={`tel:${shipment.delivery_phone_number_secondary}`}>
              {shipment.delivery_phone_number_secondary}
            </Link>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>
            {shipment.delivery_postal_code} {shipment.delivery_city}
          </Typography>
          <AddressLink
            title="Kartta"
            showIcon={true}
            address={shipment.delivery_address}
            postalCode={shipment.delivery_postal_code}
            city={shipment.delivery_city}
          />
        </Box>
        <RecipientAndState state={state} setState={setState} disabled={!expanded} />
      </CardContent>
    </Card>
  );
};

export const RequirementCell = styled(HeaderTableCell)(() => ({
  textAlign: 'center',
  padding: '0.25rem',
}));

const AdditionalRequirements: React.FC<{ shipment: Shipment }> = ({ shipment }) => {
  const hasRequirements =
    shipment.is_adr_delivery ||
    shipment.requires_hoist ||
    shipment.is_express_delivery ||
    shipment.requires_combination_vehicle;
  if (!hasRequirements) return <></>;
  return (
    <TableContainer component={Card} sx={{ width: 'inherit' }}>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow sx={{ height: 33 }}>
            {shipment.is_adr_delivery && (
              <RequirementCell>
                <Warning />
                <Box>ADR</Box>
              </RequirementCell>
            )}
            {shipment.requires_hoist && (
              <RequirementCell>
                <PrecisionManufacturing />
                <Box>HIAB</Box>
              </RequirementCell>
            )}
            {shipment.is_express_delivery && (
              <RequirementCell>
                <Speed />
                <Box>PIKA</Box>
              </RequirementCell>
            )}
            {shipment.requires_combination_vehicle && (
              <RequirementCell>
                <RvHookup />
                <Box>PERÄVAUNU</Box>
              </RequirementCell>
            )}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

const Photos: React.FC<{
  photos: Photo[];
  isAttachmentsDialogOpen: boolean;
  setIsAttachmentsDialogOpen: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  username: User['username'];
  loadId: number | null;
}> = ({ photos, isAttachmentsDialogOpen, setIsAttachmentsDialogOpen, disabled, username, loadId }) => {
  const hasPhotos = photos.length > 0;
  if (!hasPhotos && disabled) return <></>;
  return (
    <Card>
      <CardContent>
        <Typography fontWeight="bold" gutterBottom>
          Liitteet
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }}>
          {photos.map((photo) => {
            const photoUrl = `/api/v1/drivers/${username}/loads/${loadId}/shipments/${photo.shipment_id}/photos/${photo.id}`;
            return (
              <Link key={photo.id} href={photoUrl} title={photo.description}>
                <Chip sx={{ width: '100%' }} icon={<PhotoCameraOutlined />} label={photo.description} clickable />
              </Link>
            );
          })}
          {!disabled && (
            <Button variant="text" onClick={() => setIsAttachmentsDialogOpen(!isAttachmentsDialogOpen)}>
              Lisää liite
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

const ShipmentRows = (props: { rows: ShipmentRow[] }) => {
  const hasShipmentRows = props.rows.length > 0;
  if (!hasShipmentRows) return <></>;
  return (
    <TableContainer component={Card} sx={{ width: 'inherit' }}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ height: 33 }}>
            <HeaderTableCell>Tuote</HeaderTableCell>
            <HeaderTableCell align="right">Määrä</HeaderTableCell>
            <HeaderTableCell align="right">Paino</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => {
            return (
              <TableRow key={row.id} sx={{ height: 33 }}>
                <TableCell>
                  {row.serial_number || row.parcel_id ? (
                    <>
                      <strong>{row.serial_number ? row.serial_number : row.parcel_id}</strong>
                      <br />
                    </>
                  ) : null}
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{row.total_weight_kg}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface ShowShipmentProps {
  shipment: Shipment;
  shipmentRows: ShipmentRow[];
  userName: string;
  setShipment: Dispatch<SetStateAction<Shipment | null>>;
  setNotification: Dispatch<SetStateAction<NotificationType>>;
  additionalServices: AdditionalServiceField[];
  setAdditionalServices: Dispatch<SetStateAction<AdditionalServiceField[]>>;
  photos: Photo[];
  setPhotos: Dispatch<SetStateAction<Photo[]>>;
  isAttachmentsDialogOpen: boolean;
  setIsAttachmentsDialogOpen: Dispatch<SetStateAction<boolean>>;
  currentUser: CurrentUser;
}
const ShowShipment: React.FC<ShowShipmentProps> = ({
  shipment,
  userName,
  shipmentRows,
  setShipment,
  setNotification,
  additionalServices,
  setAdditionalServices,
  photos,
  setPhotos,
  isAttachmentsDialogOpen,
  setIsAttachmentsDialogOpen,
  currentUser,
}) => {
  const navigate = useNavigate();
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [state, setState] = useState<State>(getInitialState(shipment, additionalServices));
  const isValid = areAllFieldsValid(state);
  const requestBody = getPatchDriverLoadShipmentsByIdRequestBody(state, shipment, additionalServices);

  const patchShipment = async () => {
    if (isStateEmpty(requestBody) || !isNumber(shipment.load_id)) {
      setNotification({ message: 'Ei muutoksia toimitukseen', severity: 'info' });
      return;
    }
    await api.drivers.patchDriverLoadShipment({
      username: userName,
      shipmentId: shipment.id,
      loadId: shipment.load_id,
      patchDriverLoadShipmentsByIdRequestBody: requestBody,
    });
    const [shipmentResponseBody, additionalServicesResponseBody, shipmentAdditionalServicesResponseBody] =
      await Promise.all([
        api.drivers.getDriverLoadShipment({
          username: userName,
          shipmentId: shipment.id,
          loadId: shipment.load_id,
        }),
        api.organizationAdditionalServices.getOrganizationAdditionalServices({
          organizationId: shipment.organization_id,
        }),
        api.driverShipments.getDriverLoadShipmentAdditionalServices({
          username: userName,
          loadId: shipment.load_id,
          shipmentId: shipment.id,
        }),
      ]);
    const additionalServiceFields = createAdditionalServiceFields(
      additionalServicesResponseBody.data,
      shipmentAdditionalServicesResponseBody.data,
    );
    setNotification({ message: 'Toimitus tallennettu onnistuneesti!', severity: 'success' });
    setAdditionalServices(additionalServiceFields);
    setShipment(shipmentResponseBody.data);
    setState(getInitialState(shipmentResponseBody.data, additionalServiceFields));
  };

  useEffect(() => {
    const loadClient = async () => {
      const shipmentOrganization = currentUser.clients.find((client) => client.id === shipment.organization_id);
      setClient(shipmentOrganization);
    };
    loadClient();
  }, []);

  return (
    <Box
      sx={{
        // Improve readibility of disabled input fields
        '& .MuiInputLabel-root.Mui-disabled': {
          WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.common.black,
        },
        marginBottom: '4rem',
        '> div': {
          margin: '0.5rem',
        },
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.common.white,
          top: 'auto',
          bottom: 0,
        }}
      >
        <Toolbar sx={{ justifyContent: 'center' }}>
          {!expanded ? (
            <>
              <BottomNavigationAction
                sx={{ color: theme.palette.primary.main, opacity: 1 }}
                icon={<ArrowBack />}
                showLabel={true}
                label="Takaisin"
                onClick={() => navigate(-1)}
              />
              <BottomNavigationAction
                sx={{ color: theme.palette.primary.main, opacity: 1 }}
                icon={<CameraAlt />}
                showLabel={true}
                label="Ota kuva"
                onClick={() => setOpenCamera(true)}
              />
              <BottomNavigationAction
                sx={{ color: theme.palette.primary.main, opacity: 1 }}
                icon={<Edit />}
                showLabel={true}
                label="Muokkaa"
                onClick={() => setExpanded(true)}
              />
              <UpdateStateAndRecipientButton shipment={shipment} userName={userName} setShipment={setShipment} />
            </>
          ) : (
            <>
              <BottomNavigationAction
                sx={{ color: theme.palette.primary.main, opacity: 1 }}
                icon={<ArrowBack />}
                showLabel={true}
                label="Peruuta"
                onClick={() => setExpanded(false)}
              />
              <Divider orientation="vertical" sx={{ flexGrow: 1 }} />
              <BottomNavigationAction
                disabled={!isValid || isStateEmpty(requestBody)}
                sx={{
                  color:
                    !isValid || isStateEmpty(requestBody) ? theme.palette.action.disabled : theme.palette.primary.main,
                  opacity: 1,
                }}
                icon={<Save />}
                showLabel={true}
                label="Tallenna"
                onClick={() => {
                  patchShipment();
                  setExpanded(false);
                }}
              />
            </>
          )}
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: theme.palette.primary.dark,
          padding: '0.5rem',
        }}
      >
        <Typography variant="h3" fontWeight={'bold'}>
          {client?.name ?? ''}
        </Typography>
        <Typography variant="h3" fontWeight={'bold'}>
          {shipment.reference_number ? shipment.reference_number : shipment.id}
        </Typography>
      </Box>
      <AdditionalRequirements shipment={shipment} />
      <OrdererInfo shipment={shipment} />
      <PickUpInfo shipment={shipment} />
      <DeliveryInfo shipment={shipment} state={state} setState={setState} expanded={expanded} />
      <Photos
        photos={photos}
        isAttachmentsDialogOpen={isAttachmentsDialogOpen}
        setIsAttachmentsDialogOpen={setIsAttachmentsDialogOpen}
        disabled={!expanded}
        username={userName}
        loadId={shipment.load_id}
      />
      <Sizes state={state} setState={setState} disabled={!expanded} />
      <Notes state={state} setState={setState} disabled={!expanded} />
      <OtherContractNumber state={state} setState={setState} disabled={!expanded} />
      <Hours state={state} setState={setState} disabled={!expanded} />
      <States state={state} setState={setState} disabled={!expanded} />
      <AdditionalServices state={state} setState={setState} disabled={!expanded} />
      <ShipmentRows rows={shipmentRows} />
      <DriverCamera
        shipment={shipment}
        username={userName}
        open={openCamera}
        setOpen={setOpenCamera}
        setNotification={setNotification}
        setPhotos={setPhotos}
      />
      <ShipmentAddFileDialog
        open={isAttachmentsDialogOpen}
        shipmentId={shipment.id}
        loadId={shipment.load_id}
        currentUser={currentUser}
        onSuccess={async () => {
          if (!shipment.load_id || !userName) {
            return;
          }
          const photosResponseBody = await api.drivers.getDriverLoadShipmentPhotos({
            username: userName,
            loadId: shipment.load_id,
            shipmentId: shipment.id,
          });
          setPhotos(photosResponseBody.data);
        }}
        onClose={() => setIsAttachmentsDialogOpen(false)}
        showMessage={(message) => setNotification({ message })}
      />
    </Box>
  );
};

type EditShipmentParams = {
  shipmentId?: string;
  loadId?: string;
  setShipment: any;
};

const DriverEditShipment: React.FC = () => {
  const params = useParams<EditShipmentParams>();
  const shipmentId = parseInt(params.shipmentId ?? '');
  const loadId = parseInt(params.loadId ?? '');
  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [shipmentRows, setShipmentRows] = useState<ShipmentRow[]>([]);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [notification, setNotification] = useState<NotificationType>({ message: null });
  const [additionalServices, setAdditionalServices] = useState<AdditionalServiceField[]>([]);
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [isAttachmentsDialogOpen, setIsAttachmentsDialogOpen] = useState<boolean>(false);

  const currentUser = useCurrentUser();

  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };

  useEffect(() => {
    if (isNaN(shipmentId) || isNaN(loadId)) {
      setIsValid(false);
      return;
    }
    if (!currentUser || !isValid) {
      return;
    }
    const loadShipment = async () => {
      const shipmentResponseBody = await api.drivers.getDriverLoadShipment({
        username: currentUser.username,
        shipmentId,
        loadId,
      });
      const [additionalServicesResponseBody, shipmentAdditionalServicesResponseBody, photosResponseBody] =
        await Promise.all([
          api.organizationAdditionalServices.getOrganizationAdditionalServices({
            organizationId: shipmentResponseBody.data.organization_id,
          }),
          api.driverShipments.getDriverLoadShipmentAdditionalServices({
            username: currentUser.username,
            loadId: loadId,
            shipmentId: shipmentId,
          }),
          api.drivers.getDriverLoadShipmentPhotos({
            username: currentUser.username,
            loadId: loadId,
            shipmentId: shipmentId,
          }),
        ]);
      setAdditionalServices(
        createAdditionalServiceFields(additionalServicesResponseBody.data, shipmentAdditionalServicesResponseBody.data),
      );
      setPhotos(photosResponseBody.data);
      setShipment(shipmentResponseBody.data);
    };
    loadShipment();
  }, [currentUser, shipmentId, loadId]);

  useEffect(() => {
    if (isNaN(shipmentId) || isNaN(loadId) || !currentUser) {
      return;
    }
    const loadShipmentRows = async () => {
      const responseBody = await api.drivers.getDriverLoadShipmentRows({
        username: currentUser.username,
        shipmentId,
        loadId,
      });
      setShipmentRows(responseBody.data);
    };
    loadShipmentRows();
  }, [currentUser, shipmentId, loadId]);
  if (!isValid) {
    return <div>Invalid shipment id</div>;
  }

  if (!shipment || !shipmentRows || !currentUser) {
    return <></>;
  }
  return (
    <>
      <ShowShipment
        shipment={shipment}
        userName={currentUser.username}
        shipmentRows={shipmentRows}
        setShipment={setShipment}
        setNotification={setNotification}
        additionalServices={additionalServices}
        setAdditionalServices={setAdditionalServices}
        photos={photos}
        setPhotos={setPhotos}
        isAttachmentsDialogOpen={isAttachmentsDialogOpen}
        setIsAttachmentsDialogOpen={setIsAttachmentsDialogOpen}
        currentUser={currentUser}
      />
      <Notification {...snackbarProps} />
    </>
  );
};

export default DriverEditShipment;
