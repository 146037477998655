import React from 'react';
import { MenuItem } from '@mui/material';
import { CarTypeEnum } from '../../../api';

export const typeMenuItems = [
  <MenuItem key={CarTypeEnum.Erikoispilkki} value={CarTypeEnum.Erikoispilkki}>
    Erikoispilkki
  </MenuItem>,
  <MenuItem key={CarTypeEnum.Kaappi} value={CarTypeEnum.Kaappi}>
    Kaappi
  </MenuItem>,
  <MenuItem key={CarTypeEnum.Lavetti} value={CarTypeEnum.Lavetti}>
    Lavetti
  </MenuItem>,
  <MenuItem key={CarTypeEnum.Pervaunu} value={CarTypeEnum.Pervaunu}>
    Perävaunu
  </MenuItem>,
  <MenuItem key={CarTypeEnum.Pikkuauto} value={CarTypeEnum.Pikkuauto}>
    Pikkuauto
  </MenuItem>,
  <MenuItem key={CarTypeEnum.Pikkupilkki} value={CarTypeEnum.Pikkupilkki}>
    Pikkupilkki
  </MenuItem>,
  <MenuItem key={CarTypeEnum._2AksPilkki} value={CarTypeEnum._2AksPilkki}>
    2-Akselinen Pilkki
  </MenuItem>,
  <MenuItem key={CarTypeEnum._3AksPilkki} value={CarTypeEnum._3AksPilkki}>
    3-Akselinen Pilkki
  </MenuItem>,
  <MenuItem key={CarTypeEnum._4AksPilkki} value={CarTypeEnum._4AksPilkki}>
    4-Akselinen Pilkki
  </MenuItem>,
];
