import React from 'react';
import { Chip, IconButton, ListItem, PopoverActions, Stack, TextField, Tooltip } from '@mui/material';
import { Cancel, Edit } from '@mui/icons-material';
import { api, ColumnLayout, getAllPages } from '../api';
import { ColumnInstance } from 'react-table';
import { FullWidthButton } from './StyledComponents/FullWidthButton';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { columnLayoutName } from '../validation';
import { FieldSet } from './StyledComponents/FieldSet';

interface ColumnLayoutsProps {
  columns: ColumnInstance[];
  viewId?: string;
  isEditing: boolean;
  popoverActions: React.MutableRefObject<PopoverActions | null>;
}

type EditColumnLayout = {
  name: string;
  edit: boolean;
  columnLayout: ColumnLayout | undefined;
};

const emptyColumnLayout = {
  name: '',
  edit: false,
  columnLayout: undefined,
};

export const ColumnLayouts: React.FC<ColumnLayoutsProps> = ({ columns, viewId, isEditing, popoverActions }) => {
  const currentUser = useCurrentUser();
  const [columnLayouts, setColumnLayouts] = React.useState<ColumnLayout[]>([]);
  const [edit, setEdit] = React.useState<EditColumnLayout>(emptyColumnLayout);
  const valid = columnLayoutName.validate(edit.name).error?.message;

  const load = async () => {
    if (!viewId || !currentUser) {
      return;
    }
    try {
      const columnLayouts = await getAllPages(api.columnLayouts.getColumnLayouts.bind(api.columnLayouts), {
        organizationId: currentUser.organization_id,
        viewId: viewId,
      });
      setColumnLayouts(columnLayouts);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    //conditional mui menu position helper
    if (popoverActions.current) {
      popoverActions.current.updatePosition();
    }
  }, [columnLayouts]);

  return (
    <div
      style={{
        marginRight: '1rem',
        minWidth: isEditing ? '16rem' : 'fit-content',
      }}
    >
      {isEditing && (
        <ListItem sx={{ padding: 0 }}>
          <FieldSet>
            <legend>{edit.edit ? 'Muokkaa' : 'Lisää uusi'}</legend>
            <Stack>
              <TextField
                sx={{ marginTop: '0.25rem', marginBottom: '0.25rem' }}
                variant="outlined"
                label="Nimi"
                error={Boolean(valid)}
                helperText={valid}
                value={edit.name}
                onChange={(e) => setEdit({ ...edit, name: e.target.value })}
              />
              <FullWidthButton
                sx={{ marginTop: '0.25rem' }}
                variant="contained"
                disabled={Boolean(valid)}
                onClick={async () => {
                  if (!viewId || !currentUser) {
                    return;
                  }
                  try {
                    const postPutBody = {
                      name: edit.name,
                      columns: columns.filter((x) => x.isVisible).map((x) => x.id),
                    };
                    const requestBody = {
                      organizationId: currentUser.organization_id,
                      viewId: viewId,
                    };
                    if (edit.edit && edit.columnLayout) {
                      await api.columnLayouts.putColumnLayout({
                        ...requestBody,
                        columnLayoutId: edit.columnLayout.id,
                        columnLayoutPutBody: postPutBody,
                      });
                    } else {
                      await api.columnLayouts.createColumnLayout({
                        ...requestBody,
                        columnLayoutPostBody: postPutBody,
                      });
                    }
                    await load();
                    setEdit(emptyColumnLayout);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {`${edit.edit ? `Tallenna ${edit.name}` : 'Lisää uusi'} valituilla sarakkeilla`}
              </FullWidthButton>
            </Stack>
          </FieldSet>
        </ListItem>
      )}
      {columnLayouts.map((columnLayout) => {
        return (
          <ListItem
            key={columnLayout.id}
            style={{
              margin: 0,
              marginRight: '0.25rem',
              padding: 0,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Chip
              sx={{ marginTop: '0.5rem' }}
              label={columnLayout.name}
              onClick={() => {
                for (const column of columns) {
                  if (column.isVisible) {
                    column.toggleHidden();
                  }
                }
                columns.filter((x) => columnLayout.columns.includes(x.id)).map((x) => x.toggleHidden());
              }}
            />
            {isEditing && (
              <div>
                <Tooltip title={edit.columnLayout?.id === columnLayout.id ? 'Peruuta' : 'Muokkaa'}>
                  <IconButton
                    disabled={edit.columnLayout && edit.columnLayout?.id !== columnLayout.id}
                    onClick={() => {
                      if (edit.edit) {
                        setEdit(emptyColumnLayout);
                      } else {
                        setEdit({ name: columnLayout.name, edit: true, columnLayout: columnLayout });
                        for (const column of columns) {
                          if (column.isVisible) {
                            column.toggleHidden();
                          }
                        }
                        columns.filter((x) => columnLayout.columns.includes(x.id)).map((x) => x.toggleHidden());
                      }
                    }}
                  >
                    {edit.columnLayout?.id === columnLayout.id && edit.edit ? <Cancel /> : <Edit />}
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </ListItem>
        );
      })}
    </div>
  );
};
