/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  AdditionalServicePostBody,
  AdditionalServicePostBodyFromJSON,
  AdditionalServicePostBodyToJSON,
  AdditionalServicePutBody,
  AdditionalServicePutBodyFromJSON,
  AdditionalServicePutBodyToJSON,
  AdditionalServiceResponseBody,
  AdditionalServiceResponseBodyFromJSON,
  AdditionalServiceResponseBodyToJSON,
  AdditionalServiceResponseBody1,
  AdditionalServiceResponseBody1FromJSON,
  AdditionalServiceResponseBody1ToJSON,
  AdditionalServicesResponseBody,
  AdditionalServicesResponseBodyFromJSON,
  AdditionalServicesResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
} from '../models';

export interface OrganizationAdditionalServicesApiCreateAdditionalServiceRequest {
  organizationId: string;
  additionalServicePostBody: AdditionalServicePostBody;
}

export interface OrganizationAdditionalServicesApiDeleteOrganizationAdditionalServiceRequest {
  organizationId: string;
  additionalServiceId: number;
}

export interface OrganizationAdditionalServicesApiGetOrganizationAdditionalServicesRequest {
  organizationId: string;
}

export interface OrganizationAdditionalServicesApiUpdateAdditionalServiceRequest {
  organizationId: string;
  additionalServiceId: number;
  additionalServicePutBody: AdditionalServicePutBody;
}

/**
 *
 */
export class OrganizationAdditionalServicesApi extends runtime.BaseAPI {
  /**
   * Create an additional service.
   */
  async createAdditionalServiceRaw(
    requestParameters: OrganizationAdditionalServicesApiCreateAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AdditionalServiceResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createAdditionalService.',
      );
    }

    if (
      requestParameters.additionalServicePostBody === null ||
      requestParameters.additionalServicePostBody === undefined
    ) {
      throw new runtime.RequiredError(
        'additionalServicePostBody',
        'Required parameter requestParameters.additionalServicePostBody was null or undefined when calling createAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/additional_services`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AdditionalServicePostBodyToJSON(requestParameters.additionalServicePostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalServiceResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create an additional service.
   */
  async createAdditionalService(
    requestParameters: OrganizationAdditionalServicesApiCreateAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<AdditionalServiceResponseBody> {
    const response = await this.createAdditionalServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete an additional service by id.
   */
  async deleteOrganizationAdditionalServiceRaw(
    requestParameters: OrganizationAdditionalServicesApiDeleteOrganizationAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteOrganizationAdditionalService.',
      );
    }

    if (requestParameters.additionalServiceId === null || requestParameters.additionalServiceId === undefined) {
      throw new runtime.RequiredError(
        'additionalServiceId',
        'Required parameter requestParameters.additionalServiceId was null or undefined when calling deleteOrganizationAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/additional_services/{additional_service_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'additional_service_id'}}`, encodeURIComponent(String(requestParameters.additionalServiceId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete an additional service by id.
   */
  async deleteOrganizationAdditionalService(
    requestParameters: OrganizationAdditionalServicesApiDeleteOrganizationAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteOrganizationAdditionalServiceRaw(requestParameters, initOverrides);
  }

  /**
   * Get all AdditionalServices of organization.
   */
  async getOrganizationAdditionalServicesRaw(
    requestParameters: OrganizationAdditionalServicesApiGetOrganizationAdditionalServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AdditionalServicesResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationAdditionalServices.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/additional_services`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalServicesResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all AdditionalServices of organization.
   */
  async getOrganizationAdditionalServices(
    requestParameters: OrganizationAdditionalServicesApiGetOrganizationAdditionalServicesRequest,
    initOverrides?: RequestInit,
  ): Promise<AdditionalServicesResponseBody> {
    const response = await this.getOrganizationAdditionalServicesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update an additional service by id.
   */
  async updateAdditionalServiceRaw(
    requestParameters: OrganizationAdditionalServicesApiUpdateAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<AdditionalServiceResponseBody1>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateAdditionalService.',
      );
    }

    if (requestParameters.additionalServiceId === null || requestParameters.additionalServiceId === undefined) {
      throw new runtime.RequiredError(
        'additionalServiceId',
        'Required parameter requestParameters.additionalServiceId was null or undefined when calling updateAdditionalService.',
      );
    }

    if (
      requestParameters.additionalServicePutBody === null ||
      requestParameters.additionalServicePutBody === undefined
    ) {
      throw new runtime.RequiredError(
        'additionalServicePutBody',
        'Required parameter requestParameters.additionalServicePutBody was null or undefined when calling updateAdditionalService.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/additional_services/{additional_service_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'additional_service_id'}}`, encodeURIComponent(String(requestParameters.additionalServiceId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AdditionalServicePutBodyToJSON(requestParameters.additionalServicePutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalServiceResponseBody1FromJSON(jsonValue));
  }

  /**
   * Update an additional service by id.
   */
  async updateAdditionalService(
    requestParameters: OrganizationAdditionalServicesApiUpdateAdditionalServiceRequest,
    initOverrides?: RequestInit,
  ): Promise<AdditionalServiceResponseBody1> {
    const response = await this.updateAdditionalServiceRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
