import React, { FunctionComponent, useEffect, useReducer } from 'react';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { AutoCompleteFilter, DateColumnFilter } from '../../components/TableFilters';
import { CellValue, Column, ColumnInstance, Row, TableRowProps } from 'react-table';
import { DateRangePicker } from '../../components/DateRangePicker';
import { getInitialState, load, LoadsViewSettings, reducer } from './loads.state';
import Main from '../../components/Main';
import { ReactTable } from '../../components/ReactTable';
import { formatDate } from '../../formatters';
import { getViewSettings } from '../settings';
import { ShipmentSubRowAsync } from '../../components/ShipmentSubRow';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { canAccessCoordination } from '../../utils';
import { getDateRangePickerPropsFromState } from '../../utils/dateRangeUtils';

const VIEW_ID = 'loads' as const;

const Loads: FunctionComponent = () => {
  const currentUser = useCurrentUser();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const viewSettings = React.useMemo(() => getViewSettings<LoadsViewSettings>(VIEW_ID), []);

  useEffect(() => {
    if (currentUser) {
      const abortController = new AbortController();
      load(currentUser, state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [state.dateRange, currentUser]);

  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        id: 'id',
        Header: 'Id',
        accessor: 'id',
        Cell: ({ row }) =>
          canAccessCoordination(currentUser) ? (
            <Link component={RouterLink} to={{ pathname: `/loads/${row.values.id}` }}>
              {row.values.id}
            </Link>
          ) : (
            row.values.id
          ),
      },
      {
        Header: 'Kuljettaja',
        accessor: (load: CellValue) => `${load.driver?.last_name ?? ''} ${load.driver?.first_name ?? ''}`,
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Auto',
        accessor: (load: CellValue) => `${load.car?.licence_plate ?? ''}`,
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Perävaunu',
        accessor: (shipment: CellValue) => `${shipment.trailerLicenceplate ?? ''}`,
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Ajopäivä',
        accessor: 'drive_date',
        Cell: (load: CellValue) => (load.value ? formatDate(load.value) : ''),
        Filter: DateColumnFilter,
        sortType: 'datetime',
      },
      {
        Header: 'Asiakas',
        accessor: 'organizationName',
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Paino (kg)',
        accessor: 'weight_kg',
        sortType: 'basic',
      },
      {
        Header: 'Tilavuus (m³)',
        accessor: 'volume_m3',
        sortType: 'basic',
      },
      {
        Header: 'Lavametrit',
        accessor: 'length_ldm',
        sortType: 'basic',
      },
    ],
    [currentUser],
  );

  const data = React.useMemo(() => state.loads, [state.loads]);

  const renderRowSubComponent = React.useCallback(
    (row: Row, rowProps: TableRowProps, visibleColumns: ColumnInstance[]) => (
      <ShipmentSubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} currentUser={currentUser} />
    ),
    [currentUser],
  );

  return (
    <Main>
      <ReactTable
        columns={columns}
        data={data}
        header="Kuormat"
        dateRangePicker={<DateRangePicker {...getDateRangePickerPropsFromState(state, dispatch)}></DateRangePicker>}
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä kuormia."
        renderRowSubComponent={renderRowSubComponent}
        initialState={{
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'id',
              desc: true,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        onStateChange={(state) => {
          dispatch({
            type: 'SET_VIEW_SETTINGS',
            payload: { filters: state.filters, sortBy: state.sortBy, hiddenColumns: state.hiddenColumns },
          });
        }}
        columnSelector={true}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default Loads;
