/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SalaryInfoPublicHolidays,
  SalaryInfoPublicHolidaysFromJSON,
  SalaryInfoPublicHolidaysFromJSONTyped,
  SalaryInfoPublicHolidaysToJSON,
} from './SalaryInfoPublicHolidays';
import {
  SalaryInfoRange,
  SalaryInfoRangeFromJSON,
  SalaryInfoRangeFromJSONTyped,
  SalaryInfoRangeToJSON,
} from './SalaryInfoRange';

/**
 * AviShipment
 * @export
 * @interface SalaryInfo
 */
export interface SalaryInfo {
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  employee_number: string;
  /**
   *
   * @type {SalaryInfoRange}
   * @memberof SalaryInfo
   */
  range: SalaryInfoRange;
  /**
   *
   * @type {Array<SalaryInfoPublicHolidays>}
   * @memberof SalaryInfo
   */
  public_holidays: Array<SalaryInfoPublicHolidays>;
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  normal_hours: string;
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  evening_hours: string;
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  night_hours: string;
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  overtime50: string;
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  overtime100: string;
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  sick_hours: string;
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  pekkas_hours: string;
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  wait_hours: string;
  /**
   *
   * @type {string}
   * @memberof SalaryInfo
   */
  guarantee_hours: string;
  /**
   *
   * @type {number}
   * @memberof SalaryInfo
   */
  available_working_days: number;
  /**
   *
   * @type {number}
   * @memberof SalaryInfo
   */
  working_days: number;
  /**
   *
   * @type {number}
   * @memberof SalaryInfo
   */
  pekkas_days: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SalaryInfo
   */
  pekkanen_days_dates: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SalaryInfo
   */
  sick_days: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SalaryInfo
   */
  sick_days_dates: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SalaryInfo
   */
  vacation_days: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SalaryInfo
   */
  vacation_days_dates: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SalaryInfo
   */
  partial_allowance_days: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SalaryInfo
   */
  partial_allowance_days_dates: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SalaryInfo
   */
  allowance_days: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SalaryInfo
   */
  allowance_days_dates: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SalaryInfo
   */
  productivity_extra: number;
}

export function SalaryInfoFromJSON(json: any): SalaryInfo {
  return SalaryInfoFromJSONTyped(json, false);
}

export function SalaryInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalaryInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    employee_number: json['employee_number'],
    range: SalaryInfoRangeFromJSON(json['range']),
    public_holidays: (json['public_holidays'] as Array<any>).map(SalaryInfoPublicHolidaysFromJSON),
    normal_hours: json['normal_hours'],
    evening_hours: json['evening_hours'],
    night_hours: json['night_hours'],
    overtime50: json['overtime50'],
    overtime100: json['overtime100'],
    sick_hours: json['sick_hours'],
    pekkas_hours: json['pekkas_hours'],
    wait_hours: json['wait_hours'],
    guarantee_hours: json['guarantee_hours'],
    available_working_days: json['available_working_days'],
    working_days: json['working_days'],
    pekkas_days: json['pekkas_days'],
    pekkanen_days_dates: json['pekkanen_days_dates'],
    sick_days: json['sick_days'],
    sick_days_dates: json['sick_days_dates'],
    vacation_days: json['vacation_days'],
    vacation_days_dates: json['vacation_days_dates'],
    partial_allowance_days: json['partial_allowance_days'],
    partial_allowance_days_dates: json['partial_allowance_days_dates'],
    allowance_days: json['allowance_days'],
    allowance_days_dates: json['allowance_days_dates'],
    productivity_extra: json['productivity_extra'],
  };
}

export function SalaryInfoToJSON(value?: SalaryInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    employee_number: value.employee_number,
    range: SalaryInfoRangeToJSON(value.range),
    public_holidays: (value.public_holidays as Array<any>).map(SalaryInfoPublicHolidaysToJSON),
    normal_hours: value.normal_hours,
    evening_hours: value.evening_hours,
    night_hours: value.night_hours,
    overtime50: value.overtime50,
    overtime100: value.overtime100,
    sick_hours: value.sick_hours,
    pekkas_hours: value.pekkas_hours,
    wait_hours: value.wait_hours,
    guarantee_hours: value.guarantee_hours,
    available_working_days: value.available_working_days,
    working_days: value.working_days,
    pekkas_days: value.pekkas_days,
    pekkanen_days_dates: value.pekkanen_days_dates,
    sick_days: value.sick_days,
    sick_days_dates: value.sick_days_dates,
    vacation_days: value.vacation_days,
    vacation_days_dates: value.vacation_days_dates,
    partial_allowance_days: value.partial_allowance_days,
    partial_allowance_days_dates: value.partial_allowance_days_dates,
    allowance_days: value.allowance_days,
    allowance_days_dates: value.allowance_days_dates,
    productivity_extra: value.productivity_extra,
  };
}
