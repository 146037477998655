import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Car } from '../api';

export interface CarPickerProps {
  className?: string;
  cars: Car[];
  value: Car | null;
  onChange: (car: Car | null) => void;
  disabled?: boolean;
}

export const CarPicker: React.FC<CarPickerProps> = ({ className, disabled, cars, value, onChange }) => {
  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      className={'car-picker ' + className}
      options={cars.sort((car1, car2) =>
        (car1.licence_plate ?? '').toLowerCase() > (car2.licence_plate ?? '').toLowerCase() ? 1 : -1,
      )}
      autoHighlight
      getOptionLabel={(car) => car.licence_plate ?? String(car.id)}
      renderOption={(props, car) => <li {...props}>{car.licence_plate}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Auto"
          required
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      value={value}
      onChange={(_event, car) => onChange(car)}
    />
  );
};
