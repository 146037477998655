/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  OrganizationScheduleShipmentPatchResponseBody,
  OrganizationScheduleShipmentPatchResponseBodyFromJSON,
  OrganizationScheduleShipmentPatchResponseBodyToJSON,
  OrganizationScheduleShipmentsResponseBody,
  OrganizationScheduleShipmentsResponseBodyFromJSON,
  OrganizationScheduleShipmentsResponseBodyToJSON,
  PatchOrganizationScheduleShipmentPatchBody,
  PatchOrganizationScheduleShipmentPatchBodyFromJSON,
  PatchOrganizationScheduleShipmentPatchBodyToJSON,
} from '../models';

export interface OrganizationScheduleShipmentsApiGetOrganizationScheduleShipmentsRequest {
  organizationId: string;
  next?: number;
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
}

export interface OrganizationScheduleShipmentsApiPatchOrganizationScheduleShipmentsRequest {
  organizationId: string;
  shipmentId: number;
  patchOrganizationScheduleShipmentPatchBody: PatchOrganizationScheduleShipmentPatchBody;
}

/**
 *
 */
export class OrganizationScheduleShipmentsApi extends runtime.BaseAPI {
  /**
   * Get all shipments of organization for schedule.
   */
  async getOrganizationScheduleShipmentsRaw(
    requestParameters: OrganizationScheduleShipmentsApiGetOrganizationScheduleShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationScheduleShipmentsResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationScheduleShipments.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/scheduled_shipments`.replace(
          `{${'organization_id'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrganizationScheduleShipmentsResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get all shipments of organization for schedule.
   */
  async getOrganizationScheduleShipments(
    requestParameters: OrganizationScheduleShipmentsApiGetOrganizationScheduleShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationScheduleShipmentsResponseBody> {
    const response = await this.getOrganizationScheduleShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Patch shipments schedule fields.
   */
  async patchOrganizationScheduleShipmentsRaw(
    requestParameters: OrganizationScheduleShipmentsApiPatchOrganizationScheduleShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<OrganizationScheduleShipmentPatchResponseBody>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling patchOrganizationScheduleShipments.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling patchOrganizationScheduleShipments.',
      );
    }

    if (
      requestParameters.patchOrganizationScheduleShipmentPatchBody === null ||
      requestParameters.patchOrganizationScheduleShipmentPatchBody === undefined
    ) {
      throw new runtime.RequiredError(
        'patchOrganizationScheduleShipmentPatchBody',
        'Required parameter requestParameters.patchOrganizationScheduleShipmentPatchBody was null or undefined when calling patchOrganizationScheduleShipments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/organizations/{organization_id}/scheduled_shipments/{shipment_id}`
          .replace(`{${'organization_id'}}`, encodeURIComponent(String(requestParameters.organizationId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchOrganizationScheduleShipmentPatchBodyToJSON(
          requestParameters.patchOrganizationScheduleShipmentPatchBody,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrganizationScheduleShipmentPatchResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Patch shipments schedule fields.
   */
  async patchOrganizationScheduleShipments(
    requestParameters: OrganizationScheduleShipmentsApiPatchOrganizationScheduleShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<OrganizationScheduleShipmentPatchResponseBody> {
    const response = await this.patchOrganizationScheduleShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
