/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ShipmentAdditionalServicePostBody
 */
export interface ShipmentAdditionalServicePostBody {
  /**
   *
   * @type {number}
   * @memberof ShipmentAdditionalServicePostBody
   */
  additional_service_id: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentAdditionalServicePostBody
   */
  quantity: number | null;
}

export function ShipmentAdditionalServicePostBodyFromJSON(json: any): ShipmentAdditionalServicePostBody {
  return ShipmentAdditionalServicePostBodyFromJSONTyped(json, false);
}

export function ShipmentAdditionalServicePostBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShipmentAdditionalServicePostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    additional_service_id: json['additional_service_id'],
    quantity: json['quantity'],
  };
}

export function ShipmentAdditionalServicePostBodyToJSON(value?: ShipmentAdditionalServicePostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    additional_service_id: value.additional_service_id,
    quantity: value.quantity,
  };
}
