import {
  TextFieldProps,
  TextField,
  InputBaseProps,
  InputLabel,
  Input,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React, { Dispatch, BaseSyntheticEvent } from 'react';
import { Action } from 'redux';
import theme from '../../../theme';
import { updateFieldValue } from '../shipment.state';
import { FieldWithAccess } from '../types/shipment.field.types';
import { State } from '../types/shipment.types';
import { StyledFormControl } from './inputs';

export interface DispatchableInputFieldProps<T> extends React.HTMLAttributes<HTMLElement> {
  dispatch: Dispatch<Action>;
  // As state can have these fields as null, this needs to accept nulls as well
  field: FieldWithAccess<T | null | ''>; // | null;
}

export const basicTextFieldWithLabel = <K extends keyof State['fields']>(
  fieldName: K,
  label: string,
  props?: TextFieldProps,
) => {
  const Component: React.FC<DispatchableInputFieldProps<string>> = ({ field, dispatch }) => {
    if (field.access === 'hidden') return null;

    return (
      <TextField
        disabled={field.access === 'readonly'}
        required={field.required}
        id={fieldName}
        name={fieldName}
        error={field.hasError}
        helperText={field.feedback}
        label={label}
        value={field.value}
        onChange={(e) => updateFieldValue(fieldName, e.target.value, dispatch)}
        {...props}
      />
    );
  };

  Component.displayName = `basicShipmentField(${String(fieldName)})`;
  return Component;
};

export const basicInputWithLabel = <K extends keyof State['fields']>(
  fieldName: K,
  label: string,
  props?: InputBaseProps,
) => {
  const Component: React.FC<DispatchableInputFieldProps<number>> = ({ field, dispatch }) => {
    if (field.access === 'hidden') return null;

    return (
      <StyledFormControl required={field.required} error={field.hasError}>
        <InputLabel htmlFor={fieldName}>{label}</InputLabel>
        <Input
          disabled={field.access === 'readonly'}
          id={fieldName}
          name={fieldName}
          type="number"
          value={field.value}
          //disable changing value with mouse scroll
          onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
          onChange={(event) =>
            updateFieldValue(fieldName, event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
          }
          {...props}
        />
        {field.feedback ? (
          <FormHelperText sx={{ color: theme.palette.error.main }}>{field.feedback}</FormHelperText>
        ) : null}
      </StyledFormControl>
    );
  };

  Component.displayName = `basicShipmentField(${String(fieldName)})`;
  return Component;
};

export const basicCheckboxWithLabel = <K extends keyof State['fields']>(fieldName: K, label: string) => {
  const Component: React.FC<DispatchableInputFieldProps<boolean>> = ({ field, dispatch }) => {
    if (field.access === 'hidden') return null;

    return (
      <FormControlLabel
        control={
          <Checkbox
            disabled={field.access === 'readonly'}
            className={`${fieldName}_checkbox`}
            name={fieldName}
            checked={field.value || false}
            onChange={() => updateFieldValue(fieldName, !field.value, dispatch)}
          />
        }
        label={label}
      />
    );
  };

  Component.displayName = `basicShipmentField(${String(fieldName)})`;
  return Component;
};
