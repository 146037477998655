/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A photo resource which describes a photo about a Shipment (and ShipmentRow). E.g. a photo of delivered Shipment parcels.
 * @export
 * @interface Photo
 */
export interface Photo {
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  readonly id: string;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  description: string;
  /**
   *
   * @type {Date}
   * @memberof Photo
   */
  timestamp: Date;
  /**
   *
   * @type {number}
   * @memberof Photo
   */
  shipment_id: number;
  /**
   *
   * @type {number}
   * @memberof Photo
   */
  shipment_row_id: number | null;
  /**
   *
   * @type {string}
   * @memberof Photo
   */
  file_name: string;
}

export function PhotoFromJSON(json: any): Photo {
  return PhotoFromJSONTyped(json, false);
}

export function PhotoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Photo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    description: json['description'],
    timestamp: new Date(json['timestamp']),
    shipment_id: json['shipment_id'],
    shipment_row_id: json['shipment_row_id'],
    file_name: json['file_name'],
  };
}

export function PhotoToJSON(value?: Photo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    description: value.description,
    timestamp: value.timestamp.toISOString(),
    shipment_id: value.shipment_id,
    shipment_row_id: value.shipment_row_id,
    file_name: value.file_name,
  };
}
