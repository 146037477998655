import { Dispatch } from 'react';
import { api, getAllPages, Organization, User } from '../../api';
import { TableState } from 'react-table';
import { updateViewSettings } from '../settings';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';
import { canAccessUsers } from '../../utils';

export interface EnrichedUser extends User {
  organizationName: string;
  // react-table needs this id to function properly
  id: User['username'];
}

export interface UsersViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
}

export interface State {
  organizations: Organization[];
  users: EnrichedUser[];
  notification: NotificationType;
  isLoading: boolean;
  viewSettings: UsersViewSettings;
}

export const initialState: State = {
  organizations: [],
  users: [],
  notification: {
    message: null,
  },
  isLoading: true,
  viewSettings: {
    filters: [],
    sortBy: [],
    hiddenColumns: [],
  },
};

export type Action =
  | { type: 'SET_ORGANIZATIONS'; payload: Organization[] }
  | { type: 'SET_USERS'; payload: User[] }
  | SetMessageAction
  | SetLoadingAction
  | { type: 'SET_VIEW_SETTINGS'; payload: UsersViewSettings };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_ORGANIZATIONS':
      return {
        ...state,
        organizations: action.payload,
      };
    case 'SET_USERS':
      return {
        ...state,
        users: action.payload.map((user) => {
          return {
            ...user,
            organizationName:
              state.organizations.find((organization) => organization.id === user.organization_id)?.name ?? '',
            id: user.username,
          };
        }),
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_VIEW_SETTINGS':
      updateViewSettings('users', action.payload);
      return {
        ...state,
        viewSettings: action.payload,
      };
  }
};

export const load = async (dispatch: Dispatch<Action>, currentUser: User): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [organizationsResponse, usersResponse] = await Promise.all([
      canAccessUsers(currentUser)
        ? (await api.organizations.getOrganizations({})).data
        : [(await api.organizations.getOrganization({ organizationId: currentUser.organization_id })).data],
      canAccessUsers(currentUser)
        ? getAllPages(api.users.getUsers.bind(api.users), {})
        : (
            await api.organizationUsers.getOrganizationUsers({
              organizationId: currentUser.organization_id,
            })
          ).data,
    ]);
    dispatch({
      type: 'SET_ORGANIZATIONS',
      payload: organizationsResponse,
    });
    dispatch({
      type: 'SET_USERS',
      payload: usersResponse,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa käyttäjiä',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};
