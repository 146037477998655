/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A vehicle used by drivers.
 * @export
 * @interface OrganizationCar
 */
export interface OrganizationCar {
  /**
   *
   * @type {number}
   * @memberof OrganizationCar
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationCar
   */
  licence_plate: string | null;
}

export function OrganizationCarFromJSON(json: any): OrganizationCar {
  return OrganizationCarFromJSONTyped(json, false);
}

export function OrganizationCarFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationCar {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    licence_plate: json['licence_plate'],
  };
}

export function OrganizationCarToJSON(value?: OrganizationCar | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    licence_plate: value.licence_plate,
  };
}
