/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface APIKeysResponseBody
 */
export interface APIKeysResponseBody {
  /**
   *
   * @type {string}
   * @memberof APIKeysResponseBody
   */
  readonly google_maps_api_key?: string;
}

export function APIKeysResponseBodyFromJSON(json: any): APIKeysResponseBody {
  return APIKeysResponseBodyFromJSONTyped(json, false);
}

export function APIKeysResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIKeysResponseBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    google_maps_api_key: !exists(json, 'google_maps_api_key') ? undefined : json['google_maps_api_key'],
  };
}

export function APIKeysResponseBodyToJSON(value?: APIKeysResponseBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {};
}
