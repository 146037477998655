import React, { FunctionComponent, useEffect, useMemo, useReducer } from 'react';
import { api, getAllPages, Organization, OrganizationShipment, Shipment, User } from '../../api';
import Notification, {
  getSnackbarPropsFromState,
  NotificationType,
  SetMessageAction,
} from '../../components/Notification';
import { TableState } from 'react-table';
import { updateViewSettings } from '../settings';
import { saveShipmentSet } from './components/ShipmentSetHistory';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { SetLoadingAction } from '../../components/Loading';
import { canAccessCoordination } from '../../utils';
import { MRT_TableOptions } from 'material-react-table';
import MRT from '../../components/MaterialReactTable/MRT';
import { ShipmentRowSubRowAsync } from '../../components/MaterialReactTable/MRTShipmentRowSubRow';
import { DateRange, getDefaultDateRange, SetDateRangeAction } from '../../utils/dateRangeUtils';
import { getShipmentColumns } from './components/shipmentColumns';

const VIEW_ID = 'shipments' as const;

interface ShipmentsViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
}

interface WithOrganizationName {
  organizationName?: string;
}

interface State {
  shipments: ((OrganizationShipment | Shipment) & WithOrganizationName)[];
  organizations: Organization[];
  notification: NotificationType;
  dateRange: DateRange;
  isLoading: boolean;
  viewSettings: ShipmentsViewSettings;
  shipmentSetId: string;
}

const getInitialState = (): State => {
  return {
    shipments: [],
    organizations: [],
    notification: {
      message: null,
    },
    dateRange: getDefaultDateRange(),
    isLoading: true,
    viewSettings: { filters: [], sortBy: [], hiddenColumns: [] },
    shipmentSetId: '',
  };
};

type Action =
  | {
      type: 'INITIALIZE';
      payload: { shipments: (OrganizationShipment | Shipment)[]; organizations: Organization[] };
    }
  | SetMessageAction
  | SetDateRangeAction
  | SetLoadingAction
  | { type: 'SET_VIEW_SETTINGS'; payload: ShipmentsViewSettings };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      const shipmentSetId = saveShipmentSet(action.payload.shipments);
      return {
        ...state,
        shipmentSetId,
        shipments: action.payload.shipments.map((shipment) => {
          return {
            ...shipment,
            organizationName: action.payload.organizations.find((org) => org.id === shipment.organization_id)?.name,
          };
        }),
        organizations: action.payload.organizations,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_VIEW_SETTINGS':
      updateViewSettings('shipments', action.payload);
      return {
        ...state,
        viewSettings: action.payload,
      };
  }
};

const fetchShipments = async (
  currentUser: User,
  state: State,
  dispatch: React.Dispatch<Action>,
  abortController: AbortController,
) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [shipments, organizationsResponse] = currentUser.is_multi_organization
      ? await Promise.all([
          getAllPages(
            api.shipments.getShipments.bind(api.shipments),
            {
              agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
              agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
            },
            abortController,
          ),
          api.organizations.getOrganizations({}),
        ])
      : await Promise.all([
          await getAllPages(
            api.organizationShipments.getOrganizationShipments.bind(api.organizationShipments),
            {
              organizationId: currentUser.organization_id,
              agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
              agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
            },
            abortController,
          ),
          Promise.resolve(undefined),
        ]);
    dispatch({
      type: 'INITIALIZE',
      payload: {
        shipments,
        organizations: organizationsResponse?.data ?? [],
      },
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa toimituksia',
        severity: 'error',
      },
    });
  } finally {
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  }
};

const Shipments: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser?.organization_id && state.dateRange) {
      const abortController = new AbortController();
      fetchShipments(currentUser, state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [currentUser, state.dateRange]);

  const data = React.useMemo(() => state.shipments, [state.shipments]);

  const hasCoordinationAccess = canAccessCoordination(currentUser);

  const shipmentColumns = getShipmentColumns({
    shipmentSetId: state.shipmentSetId,
    loadLink: hasCoordinationAccess,
  });

  const columns = useMemo(
    () =>
      shipmentColumns.filter(
        (column) =>
          hasCoordinationAccess || !['organizationName', 'delivery_phone_number'].includes(column.accessorKey ?? ''),
      ),
    [state.shipmentSetId, currentUser],
  );

  const tableOptions: MRT_TableOptions<(OrganizationShipment | Shipment) & WithOrganizationName> = {
    data,
    columns,
    renderDetailPanel: ({ row }) => <ShipmentRowSubRowAsync row={row} hideSizeInformation={true} />,
  };

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <MRT header={'Toimitukset'} viewId={VIEW_ID} isLoading={state.isLoading} dispatch={dispatch} {...tableOptions} />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default Shipments;
